import styled from 'styled-components';

import { StyledFieldSet } from '@hyperfish/fishfood/lib/components/Form/FormFieldSet';
import { getFontSize, spacing } from '@hyperfish/fishfood/lib/utils/ThemeUtil';

export const SortContainer = styled.div`
  display: flex;
  margin-bottom: ${spacing(1)};

  ${StyledFieldSet} {
    margin: 0;
  }

  > *:first-child {
    flex-grow: 1;
    margin-right: ${spacing(1)};
  }
`;

export const AdditionalLabel = styled.p`
  margin: ${spacing(2, 0, 1)};
  font-weight: 700;
  font-size: ${getFontSize(14)};
`;

export const RemoveContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 26px;
  margin-left: ${spacing(1)};
`;

export const EmptyMessage = styled.p`
  && {
    font-size: ${getFontSize(18)};
    margin: ${spacing(2, 0, 4)};
    font-style: italic;
  }
`;
