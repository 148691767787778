import Radium from 'radium';
import React from 'react';

import { Notification as INotification } from '../models/client';
import StyleUtil from '../utils/StyleUtil';
import { Icon } from './Icon';

interface Props {
  notification: INotification;
  dismiss: React.MouseEventHandler<HTMLElement>;
}

const iconMap: { [key in INotification['type']]: string } = {
  success: 'checkmark',
  error: 'warning',
  warning: 'warning',
  info: 'information',
};

const S = StyleUtil({
  container: {
    backgroundColor: '#fff',
    boxShadow: `0 1px 2px 0 #d4d4d4`,
    cursor: 'pointer',
    display: 'block',
    padding: '10px 10px 10px 47px',
    position: 'relative',
    width: 300,
    marginTop: 5,
  },
  iconContainer: {
    position: 'absolute',
    left: 0,
    top: 10,
    bottom: 10,
    width: 37,
    borderRight: `2px solid currentColor`,
  },
  // eslint-disable-next-line @typescript-eslint/camelcase
  iconContainer_error: { color: StyleUtil.colors.red },
  // eslint-disable-next-line @typescript-eslint/camelcase
  iconContainer_success: { color: StyleUtil.colors.green },
  // eslint-disable-next-line @typescript-eslint/camelcase
  iconContainer_info: { color: StyleUtil.colors.blue },
  // eslint-disable-next-line @typescript-eslint/camelcase
  iconContainer_warning: { color: StyleUtil.colors.red },
  icon: {
    left: '50%',
    lineHeight: 1,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
  },
  title: {
    margin: 0,
    fontSize: 14,
    fontWeight: 700,
  },
  message: {
    fontSize: 14,
    lineHeight: 1.2,
    marginBottom: 0,
    marginTop: 5,
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 3,
    color: 'rgb(127, 127, 127)',
    lineHeight: 1,
  },
});

export const Notification = Radium(({ notification: { type, title, message }, dismiss }: Props) => (
  <article style={[S.container, S[`container_${type}`]] as any} onClick={dismiss}>
    <div style={[S.iconContainer, S[`iconContainer_${type}`]] as any}>
      <span style={S.icon}>
        <Icon name={iconMap[type]} />
      </span>
    </div>
    {!!title && <h2 style={S.title}>{title}</h2>}
    {!!message && <p style={S.message}>{message}</p>}
    {!!dismiss && (
      <a style={S.close}>
        <Icon name="cross" />
      </a>
    )}
  </article>
));
