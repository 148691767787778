import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import { Button, LoadingSplash, FiEye } from '@hyperfish/fishfood';
import Color from 'color';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HyperFieldFactory as fields, HyperForm, SettingCard } from '../../../components/';
import { BRANDING_PREVIEW } from '../../../config';
import { SettingsProps } from '../../../layouts/SettingsLayout';
import { OrgSettingsKeys as keys } from '../../../models/api';
import {
  edit as editBranding,
  editSelfBranding,
  editSettings,
  load as loadBranding,
} from '../../../redux/modules/branding';
import classes from './styles.module.scss';

const connector = connect(
  state => ({
    branding: state.branding.data,
    defaultSelf: state.branding.defaultSelf,
  }),
  { editBranding, editSettings, editSelfBranding, loadBranding },
);

type Props = typeof connector['props'];

@connector
@(withRouter as any)
export default class Branding extends React.Component<Props & SettingsProps, {}> {
  componentDidMount() {
    const { currentAudienceId } = this.props;

    this.loadAudienceBranding(currentAudienceId);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props & SettingsProps) {
    if (this.props.currentAudienceId !== nextProps.currentAudienceId) {
      this.loadAudienceBranding(nextProps.currentAudienceId);
    }
  }

  componentWillUnmount() {
    this.props.handleCancel();
  }

  loadAudienceBranding(audienceId: string) {
    const { currentOrg, loadBranding } = this.props;

    loadBranding(audienceId === currentOrg.id ? [currentOrg.id] : [currentOrg.id, audienceId]);
  }

  validateColor(color: string) {
    if (!color) {
      return;
    }

    const el = document.createElement('div');
    el.style.color = color;
    if (
      !el.style.color
        .split(/\s+/)
        .join('')
        .toLowerCase()
    ) {
      return 'Must be a valid color.';
    }

    try {
      Color(color);
    } catch (e) {
      return 'Must be a valid color.';
    }
  }

  handleProfileOverride = (e: React.FormEvent<HTMLInputElement>) => {
    const { editBranding, editSettings, currentAudienceId, currentOrg, branding } = this.props;
    editSettings(currentAudienceId, keys.inheritance.profileBranding, !e.currentTarget.checked);
    if (e.currentTarget.checked) {
      editBranding(currentAudienceId, 'selfPageBranding', branding.audiences[currentOrg.id].selfPageBranding);
    }
  };

  handleEmailOverride = (e: React.FormEvent<HTMLInputElement>) => {
    const { editSettings, currentAudienceId } = this.props;
    editSettings(currentAudienceId, keys.inheritance.emailBranding, !e.currentTarget.checked);
    if (e.currentTarget.checked) {
      editSettings(currentAudienceId, keys.branding.emailHeaderImage, null);
    }
  };

  safelyWrapBranding(branding: Props['branding']['audiences']['foo']) {
    const newBranding = { ...branding };
    if (!newBranding.settings) {
      newBranding.settings = {} as Props['branding']['audiences']['foo']['settings'];
    }
    if (!newBranding.selfPageBranding) {
      newBranding.selfPageBranding = {} as Props['branding']['audiences']['foo']['selfPageBranding'];
    }
    return newBranding;
  }

  getMergedBranding() {
    const { branding, currentAudienceId, currentOrg, dirtyBranding } = this.props;

    const masterBranding = this.safelyWrapBranding(branding.audiences[currentOrg.id]);
    const cleanAudienceBranding = this.safelyWrapBranding(branding.audiences[currentAudienceId]);
    const dirtyAudienceBranding = this.safelyWrapBranding(dirtyBranding.audiences[currentAudienceId]);

    if (currentAudienceId === currentOrg.id) {
      // Viewing master
      return {
        ...masterBranding,
        selfPageBranding: {
          ...masterBranding.selfPageBranding,
          ...dirtyAudienceBranding.selfPageBranding,
        },
        settings: {
          ...masterBranding.settings,
          ...dirtyAudienceBranding.settings,
        },
      };
    }

    const mergedBranding: Props['branding']['audiences']['foo'] = {
      ...masterBranding,
      settings: {
        ...masterBranding.settings,
        inheritance_profileBranding:
          dirtyAudienceBranding.settings.inheritance_profileBranding != null
            ? dirtyAudienceBranding.settings.inheritance_profileBranding
            : cleanAudienceBranding.settings.inheritance_profileBranding,
        inheritance_emailBranding:
          dirtyAudienceBranding.settings.inheritance_emailBranding != null
            ? dirtyAudienceBranding.settings.inheritance_emailBranding
            : cleanAudienceBranding.settings.inheritance_emailBranding,
      },
    };

    if (dirtyAudienceBranding.settings.inheritance_profileBranding != null) {
      // Profile inheritance is dirty.
      if (dirtyAudienceBranding.settings.inheritance_profileBranding) {
        // Profile inheritance is dirty and turned on.
        // Render from master. Do nothing.
      } else {
        // Profile inheritance is dirty and turned off.
        mergedBranding.selfPageBranding = {
          ...cleanAudienceBranding.selfPageBranding,
          ...dirtyAudienceBranding.selfPageBranding,
        };
      }
    } else {
      // Profile inheritance is clean.
      if (cleanAudienceBranding.settings.inheritance_profileBranding) {
        // Profile inheritance is clean and turned on.
        // Render from master. Do nothing.
      } else {
        // Profile inheritance is clean and turned off.
        mergedBranding.selfPageBranding = {
          ...cleanAudienceBranding.selfPageBranding,
          ...dirtyAudienceBranding.selfPageBranding,
        };
      }
    }

    if (dirtyAudienceBranding.settings.inheritance_emailBranding != null) {
      // Email inheritance is dirty.
      if (dirtyAudienceBranding.settings.inheritance_emailBranding) {
        // Email inheritance is dirty and turned on.
        // Render from master. Do nothing.
      } else {
        // Email inheritance is dirty and turned off.
        mergedBranding.settings.branding_emailHeaderImage =
          typeof dirtyAudienceBranding.settings.branding_emailHeaderImage === 'undefined'
            ? cleanAudienceBranding.settings.branding_emailHeaderImage
            : dirtyAudienceBranding.settings.branding_emailHeaderImage;
      }
    } else {
      // Email inheritance is clean.
      if (cleanAudienceBranding.settings.inheritance_emailBranding) {
        // Email inheritance is clean and turned on.
        // Render from master. Do nothing.
      } else {
        // Email inheritance is clean and turned off.
        mergedBranding.settings.branding_emailHeaderImage =
          typeof dirtyAudienceBranding.settings.branding_emailHeaderImage === 'undefined'
            ? cleanAudienceBranding.settings.branding_emailHeaderImage
            : dirtyAudienceBranding.settings.branding_emailHeaderImage;
      }
    }

    return mergedBranding;
  }

  render() {
    const {
      branding,
      brandingLoading,
      currentAudienceId,
      defaultSelf,
      editSelfBranding,
      editSettings,
      isMaster,
    } = this.props;

    if (!branding || brandingLoading) {
      return <LoadingSplash />;
    }

    const mergedBranding = this.getMergedBranding();

    return (
      <div className="view-settings-branding">
        <SettingCard
          title="Profile Page"
          subTitle="Customize the branding of the profile page by changing the colors and logo."
          override={isMaster ? null : !mergedBranding.settings.inheritance_profileBranding}
          onChangeOverride={this.handleProfileOverride}
        >
          <HyperForm
            onInvalidChange={this.props.handleFormInvalidChange}
            onMount={this.props.handleFormMount}
            onUnmount={this.props.handleFormUnmount}
            fields={[
              fields.color({
                label: 'Header Color',
                value: getFrom(mergedBranding)('selfPageBranding')('primaryColor').defaultTo(defaultSelf.primaryColor),
                hintText: (
                  <span>
                    Color of the header. Enter a valid CSS color.{' '}
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                ),
                validationText: this.validateColor(mergedBranding.selfPageBranding.primaryColor),
                onChange: ({ currentTarget: { value } }) =>
                  editSelfBranding(currentAudienceId, 'primaryColor', value ? value.toLowerCase().trim() : value),
              }),
              fields.color({
                label: 'Header Text Color',
                value: getFrom(mergedBranding)('selfPageBranding')('headerTextColor').defaultTo(
                  defaultSelf.headerTextColor,
                ),
                hintText: (
                  <span>
                    Color of the header text. Enter a valid CSS color.{' '}
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                ),
                validationText: this.validateColor(mergedBranding.selfPageBranding.headerTextColor),
                onChange: ({ currentTarget: { value } }) =>
                  editSelfBranding(currentAudienceId, 'headerTextColor', value ? value.toLowerCase().trim() : value),
              }),
              fields.color({
                label: 'Secondary Color',
                value: getFrom(mergedBranding)('selfPageBranding')('secondaryColor').defaultTo(
                  defaultSelf.secondaryColor,
                ),
                hintText: (
                  <span>
                    Color of the submit buttons and pending badges. Enter a valid CSS color.{' '}
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                ),
                validationText: this.validateColor(mergedBranding.selfPageBranding.secondaryColor),
                onChange: ({ currentTarget: { value } }) =>
                  editSelfBranding(currentAudienceId, 'secondaryColor', value ? value.toLowerCase().trim() : value),
              }),
              fields.color({
                label: 'Accent Color',
                value: getFrom(mergedBranding)('selfPageBranding')('accentColor').defaultTo(defaultSelf.accentColor),
                hintText: (
                  <span>
                    Color of the edit buttons. Enter a valid CSS color.{' '}
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                ),
                validationText: this.validateColor(mergedBranding.selfPageBranding.accentColor),
                onChange: ({ currentTarget: { value } }) =>
                  editSelfBranding(currentAudienceId, 'accentColor', value ? value.toLowerCase().trim() : value),
              }),
              fields.image({
                label: 'Logo',
                value: mergedBranding.selfPageBranding.logo,
                hintText: 'Minimum image height 45 pixels.',
                onChange: imgString => editSelfBranding(currentAudienceId, 'logo', imgString),
                // dropZoneStyle: { backgroundImage: `url(${require('./assets/checker_box_8.png')})`, backgroundClip: 'padding-box' },
              }),
            ]}
            customButtons={[
              <Button
                key="resetBtn"
                size="medium"
                onClick={() => {
                  ['primaryColor', 'headerTextColor', 'secondaryColor', 'accentColor', 'logo'].forEach(key =>
                    editSelfBranding(currentAudienceId, key as any, null),
                  );
                }}
              >
                Reset
              </Button>,
              <Button
                key="previewBtn"
                size="medium"
                icon={<FiEye />}
                disabled={!sessionStorage}
                onClick={() => {
                  if (!sessionStorage) {
                    console.error('Branding preview will not function without local storage enabled.');
                    return;
                  }
                  sessionStorage.setItem(BRANDING_PREVIEW, JSON.stringify(mergedBranding.selfPageBranding));
                  window.open('/self?brandingPreview=true');
                }}
              >
                Preview
              </Button>,
            ]}
          />
          {!sessionStorage && (
            <p className={classes.warning}>
              The Preview feature requires the browser&apos;s local storage to be enabled.
            </p>
          )}
        </SettingCard>
        <SettingCard
          title="Completion Percentage"
          subTitle="Customize the colors of the ring around a users profile indicating how complete their profile is."
          override={isMaster ? null : !mergedBranding.settings.inheritance_profileBranding}
          onChangeOverride={this.handleProfileOverride}
        >
          <HyperForm
            onInvalidChange={this.props.handleFormInvalidChange}
            onMount={this.props.handleFormMount}
            onUnmount={this.props.handleFormUnmount}
            fields={[
              fields.color({
                label: 'Complete Color',
                value: getFrom(mergedBranding)('selfPageBranding')('completionRingSuccessColor').defaultTo(
                  defaultSelf.completionRingSuccessColor,
                ),
                hintText: (
                  <span>
                    Color of the ring when profile is &gt; 80% complete. Enter a valid CSS color.{' '}
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                ),
                validationText: this.validateColor(mergedBranding.selfPageBranding.completionRingSuccessColor),
                onChange: ({ currentTarget: { value } }) =>
                  editSelfBranding(
                    currentAudienceId,
                    'completionRingSuccessColor',
                    value ? value.toLowerCase().trim() : value,
                  ),
              }),
              fields.color({
                label: 'In Progress Color',
                value: getFrom(mergedBranding)('selfPageBranding')('completionRingWarnColor').defaultTo(
                  defaultSelf.completionRingWarnColor,
                ),
                hintText: (
                  <span>
                    Color of the ring when profile is &gt; 40% complete. Enter a valid CSS color.{' '}
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                ),
                validationText: this.validateColor(mergedBranding.selfPageBranding.completionRingWarnColor),
                onChange: ({ currentTarget: { value } }) =>
                  editSelfBranding(
                    currentAudienceId,
                    'completionRingWarnColor',
                    value ? value.toLowerCase().trim() : value,
                  ),
              }),
              fields.color({
                label: 'Incomplete Color',
                value: getFrom(mergedBranding)('selfPageBranding')('completionRingErrorColor').defaultTo(
                  defaultSelf.completionRingErrorColor,
                ),
                hintText: (
                  <span>
                    Color of the ring when profile is &lt; 40% complete. Enter a valid CSS color.{' '}
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                ),
                validationText: this.validateColor(mergedBranding.selfPageBranding.completionRingErrorColor),
                onChange: ({ currentTarget: { value } }) =>
                  editSelfBranding(
                    currentAudienceId,
                    'completionRingErrorColor',
                    value ? value.toLowerCase().trim() : value,
                  ),
              }),
            ]}
            customButtons={[
              <Button
                key="resetBtn"
                size="medium"
                onClick={() => {
                  ['completionRingSuccessColor', 'completionRingWarnColor', 'completionRingErrorColor'].forEach(key =>
                    editSelfBranding(currentAudienceId, key as any, null),
                  );
                }}
              >
                Reset
              </Button>,
              <Button
                key="previewBtn"
                size="medium"
                icon={<FiEye />}
                disabled={!sessionStorage}
                onClick={() => {
                  if (!sessionStorage) {
                    console.error('Branding preview will not function without local storage enabled.');
                    return;
                  }
                  sessionStorage.setItem(BRANDING_PREVIEW, JSON.stringify(mergedBranding.selfPageBranding));
                  window.open('/self?brandingPreview=true');
                }}
              >
                Preview
              </Button>,
            ]}
          />
        </SettingCard>
        <SettingCard
          title="Email Communications"
          subTitle="Brand the email templates being sent by Hyperbot"
          override={isMaster ? null : !mergedBranding.settings.inheritance_emailBranding}
          onChangeOverride={this.handleEmailOverride}
        >
          <HyperForm
            onInvalidChange={this.props.handleFormInvalidChange}
            onMount={this.props.handleFormMount}
            onUnmount={this.props.handleFormUnmount}
            fields={[
              fields.image({
                label: 'Email Header Image',
                value: mergedBranding.settings.branding_emailHeaderImage,
                hintText: 'Ideal image width 600px.',
                onChange: imgString => editSettings(currentAudienceId, 'branding_emailHeaderImage', imgString),
              }),
            ]}
          />
        </SettingCard>
      </div>
    );
  }
}
