import { AxiosResponse } from 'axios';
// import { Middleware } from 'redux';

import { ApiError } from '../../models/api';
import { Notification } from '../../models/client';
import { createNotification } from '../modules/notifications';
import { getFrom } from '@hyperfish/fishfood';

type NotificationFetcher = (error: ApiError) => string;
type NotificationSource = Notification | string | NotificationFetcher;
interface NotificationSourceAction {
  error?: ApiError;
  notifications?: {
    fail?: NotificationSource;
    success?: NotificationSource;
  };
}

function dispatchErrorNotification(dispatch, action: NotificationSourceAction) {
  const fail = (action.notifications || {}).fail;
  if (!fail) {
    return;
  }
  let notificationAction;
  let message;
  const notificationDefault = {
    type: 'error',
    duration: 0,
  } as Notification;

  if (typeof fail === 'object') {
    notificationAction = createNotification({
      ...notificationDefault,
      ...fail,
    });
  } else {
    message = typeof fail === 'function' ? fail(action.error) : fail;

    if (message) {
      notificationAction = createNotification({
        ...notificationDefault,
        message,
      });
    }
  }

  if (notificationAction) {
    dispatch(notificationAction);
  }
}

function getSuccessNotificationAction(action: NotificationSourceAction) {
  if (!(action.notifications || {}).success) {
    return;
  }
  const notification =
    typeof action.notifications.success === 'string'
      ? { message: action.notifications.success }
      : action.notifications.success;

  return {
    type: 'success',
    title: 'Success!',
    duration: 5,
    ...notification,
  } as Notification;
}

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action;

      if (!promise) {
        return next(action);
      }

      const REQUEST = types[0];
      const SUCCESS = types[1];
      const FAILURE = types[2];

      next({ ...rest, type: REQUEST });

      const actionPromise: Promise<AxiosResponse<any>> = promise(client);
      actionPromise
        .then(
          result => {
            if (!!getFrom(result.data)('forceNotification').defaultTo(null)) {
              dispatch(createNotification(result.data.forceNotification as Notification));
            } else if (getFrom(result.data)('forceNotifications').defaultTo([]).length > 0) {
              for (let notification of result.data.forceNotifications) {
                dispatch(createNotification(notification as Notification));
              }
            } else {
              if (getFrom(action.notifications)('success').value) {
                dispatch(createNotification(getSuccessNotificationAction(action)));
              }
            }

            next({ ...rest, result: result.data, type: SUCCESS });
          },
          error => {
            const { err, response, messageError, requestId } = error;
            const codesToNotReport = [401, 404];
            const codesToNotRetry = [401, 404];

            action.requestIds = (action.requestIds || []).concat([requestId]);

            if (codesToNotRetry.indexOf(err && err.status) === -1) {
              const retries = typeof action.retries === 'undefined' ? 2 : action.retries;
              let retryAttempts = action.retryAttempts || 0;

              if (retryAttempts < retries) {
                dispatch({
                  ...action,
                  retryAttempts: ++retryAttempts,
                });
                return;
              }
            }

            dispatchErrorNotification(dispatch, { ...rest, error: err });

            next({ ...rest, error: err || {}, type: FAILURE });
          },
        )
        .catch(error => {
          if ((action.notifications || {}).fail) {
            dispatch(
              createNotification({
                type: 'error',
                duration: 0,
                message: 'Something went wrong. Please refresh the page.',
              }),
            );
          }

          next({ ...rest, error, type: FAILURE });
          throw new Error(`Middleware Error: ${error}`);
        });

      return actionPromise;
    };
  };
}
