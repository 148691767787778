export const ACCESS_TOKEN = 'antrea/ACCESS_TOKEN';
export const BRANDING_PREVIEW = 'antrea/BRANDING_PREVIEW';

export const USER_FEATURE_ADMIN = 'AntreaAdmin';
export const USER_FEATURE_APPROVER = 'AntreaApprover';
export const USER_FEATURE_BASIC = 'AntreaMember';
export const USER_FEATURE_PILOT = 'AntreaPilot';
export const USER_FEATURE_EDITOR = 'AntreaEditor';

export const LICENSE_FREE = 'Hyperfish Free';
export const LICENSE_BAISC = 'Hyperfish Basic';

export const ORG_FEATURE_PAID = 'PaidLicense';
export const ORG_FEATURE_MODE_ANALYZE = 'AllowAnalyzeMode';
export const ORG_FEATURE_MODE_PILOT = 'AllowPilotMode';
export const ORG_FEATURE_MODE_RUN = 'AllowRunMode';
export const ORG_FEATURE_DIRECTORY_WEB_PARTS = 'AllowDirectory';
export const ORG_FEATURE_PROFILE_VALIDATION = 'AllowProfileValidation';
export const ORG_FEATURE_COLLECTIONS = 'AllowCollections';
export const ORG_FEATURE_READ_ONLY_MODE_SWITCH = 'AllowReadOnlyModeSwitch';

export const GRANT_TOKEN = 'antrea/GRANT_TOKEN';
export const ORG_NAME = 'antrea/ORG_DISPLAYNAME';
export const REFRESH_TOKEN = 'antrea/REFRESH_TOKEN';
export const TRIAL_AUDIT = 'antrea/TRIAL_AUDIT';
export const USER_FEATURES = 'antrea/USER_FEATURES';
export const META = 'antrea/META';

export const HYPERBOT_DEFAULT_EMAIL_ADDRESS = 'hyperbot@hyperfish.com';
