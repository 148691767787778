import React from 'react';

import { isGraphUser } from '../../../redux/modules/externalUsers';

// const EMAIL = '#/definitions/email';
const MANAGER = '#/definitions/manager';
const ON_PREM_MANAGER = '#/definitions/onpremManager';
// const PHONE = '#/definitions/phone';
const STRING = '#/definitions/string';
// const UUID = '#/definitions/uuid';

const ARRAY = 'antrea/propType/ARRAY';
// const UNKNOWN = 'antrea/propType/UNKNOWN';
const PICTURE = 'antrea/propType/PICTURE';

function getTypeStringFromValue(value: any) {
  // TODO: Replace duck typing nastiness with schema driven stuff
  if (!value) {
    return '';
  }

  if (typeof value === 'string') {
    return STRING;
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return ARRAY;
    }
    if (value.hasOwnProperty('content')) {
      return PICTURE;
    }
    if (isGraphUser(value)) {
      return MANAGER;
    }
    return ON_PREM_MANAGER;
  }
}

interface Props {
  value: any;
}

export class PropertyValue extends React.Component<Props, {}> {
  render() {
    const { value } = this.props;

    const typeString = getTypeStringFromValue(value);

    if (value === 'null' || !value) {
      return <span>[No Value]</span>;
    }

    switch (typeString) {
      case MANAGER:
      case ON_PREM_MANAGER:
        return (
          <span>
            {value.displayName || value.displayname} {value.mail && `(${value.mail})`}
          </span>
        );
      case STRING:
        return <span>&ldquo;{value}&rdquo;</span>;
      case ARRAY:
        return (
          <span>
            {value.map((v, i) => (
              <span key={i}>
                <PropertyValue value={v} />,
              </span>
            ))}
          </span>
        );
      case PICTURE:
        return !value || !value.content ? (
          <span>No picture</span>
        ) : (
          <img
            src={value.content}
            style={{
              width: 46,
              height: 46,
              verticalAlign: 'middle',
              margin: '0 8px',
            }}
            alt="User Photo"
          />
        );
      default:
        return <span>{JSON.stringify(value)}</span>;
    }
  }
}
