import React from 'react';
import {
  DirectorySearch,
  FiArrowLeft,
  FiArrowRight,
  FiFilter,
  FiMinusCircle,
  FiSearch,
  FiTrash,
  FiUsers,
  IconButton,
  LoadingSplash,
  Modal,
  Select,
  getFrom,
} from '@hyperfish/fishfood';
import PeoplePicker from '@hyperfish/fishfood/lib/components/PeoplePicker/LegacyPeoplePicker';
import classes from '../styles.module.scss';
import cx from 'classnames';
import { SettingCard } from '../../../../components/';
import { Button, FiPlusCircle } from '@hyperfish/fishfood';
import { StyleUtil } from '../../../../utils/StyleUtil';
import { Props } from '../index';
import EdgesTable, { Pager } from './edgesTable';
import {
  RelationshipEdgeRecodResponse,
  RelationshipEdgeRequest,
} from '@hyperfish/antrea-api-contracts/src/relationship';
import { ApiClient, ApiClientContext } from '@hyperfish/fishfood/lib/utils/ApiClient';

const styles = StyleUtil({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '7px',
  },
  formLabel: {
    flexBasis: '200px',
  },
  formInput: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  formFilterSelect: {
    paddingBottom: 25,
  },
  profilePicture: {
    marginBottom: 5,
  },
});

type RelationshipEdgeAdd = RelationshipEdgeRecodResponse & { entityNameFrom: string; entityNameTo: string };
interface State {
  newRelationshipEdges: RelationshipEdgeAdd[];
  selectedRelationshipEdges: RelationshipEdgeRecodResponse[];
  selectedFilterType?: string;
  selectedFilterEntityIdFrom?: string;
  selectedFilterEntityIdTo?: string;
  showFilterModal?: boolean;
  showAddModal?: boolean;
  showAdvancedSearchModalFrom?: boolean;
  showAdvancedSearchModalTo?: boolean;
  showUsersSelectedDetailModalFrom?: boolean;
  showUsersSelectedDetailModalTo?: boolean;
  selectedUsersFrom: { value: string; label: string }[];
  selectedUsersTo: { value: string; label: string }[];
  pagination: {
    page: number;
    pageSize: number;
  };
  newRelationshipsPagination: {
    page: number;
    pageSize: number;
  };
  internalSettings?: undefined | {};
  showDeleteRelationshipEdgesModal?: boolean;
  dataValidationMessage: string;
}

export default class RelationshipEdges extends React.Component<Props, State> {
  private static defaultPagination: {
    page: number;
    pageSize: number;
  } = {
    page: 1,
    pageSize: 10,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedUsersFrom: [],
      selectedUsersTo: [],
      newRelationshipEdges: [],
      selectedRelationshipEdges: [],
      pagination: RelationshipEdges.defaultPagination,
      newRelationshipsPagination: RelationshipEdges.defaultPagination,
      dataValidationMessage: '',
    };
  }

  componentDidMount() {
    const { reloadRelationshipEdges, loadFieldSchema } = this.props;
    loadFieldSchema();
    if (reloadRelationshipEdges) {
      this.loadEdges(this.state.selectedFilterType);
    }
  }

  componentDidUpdate() {
    const { reloadRelationshipEdges } = this.props;
    if (reloadRelationshipEdges) {
      this.loadEdges(this.state.selectedFilterType);
    }
  }

  loadEdges(relationshipTypeId: string) {
    const { loadRelationshipEdges } = this.props;
    loadRelationshipEdges(relationshipTypeId);
    this.setState({
      selectedFilterType: relationshipTypeId,
      pagination: RelationshipEdges.defaultPagination,
      selectedFilterEntityIdFrom: null,
      selectedFilterEntityIdTo: null,
      selectedRelationshipEdges: [],
    });
  }

  render() {
    const { loadingEdges, relationshipEdges, relationshipTypes, addingEdges } = this.props;

    const filteredEdges = this.state.showFilterModal
      ? getFrom(relationshipEdges)('relationshipEdges').defaultTo([])
      : getFrom(relationshipEdges)('relationshipEdges')
          .defaultTo([])
          .filter(
            e =>
              (!this.state.selectedFilterEntityIdFrom || e.entityIdFrom == this.state.selectedFilterEntityIdFrom) &&
              (!this.state.selectedFilterEntityIdTo || e.entityIdTo == this.state.selectedFilterEntityIdTo),
          );

    const isFiltered = () => {
      return this.state.selectedFilterEntityIdFrom || this.state.selectedFilterEntityIdTo;
    };

    const isRelationshipEdgeSelected = (edge: RelationshipEdgeRecodResponse): boolean => {
      return this.state.selectedRelationshipEdges.some(
        e =>
          e.relationshipTypeId == edge.relationshipTypeId &&
          e.entityIdFrom == edge.entityIdFrom &&
          e.entityIdTo == edge.entityIdTo,
      );
    };

    const isAllRelationshipEdgeSelected = (): boolean => {
      return filteredEdges.length > 0 && filteredEdges.length == this.state.selectedRelationshipEdges.length;
    };

    const toggleRelationshipEdge = (edge: RelationshipEdgeRecodResponse): void => {
      if (isRelationshipEdgeSelected(edge)) {
        this.setState({
          selectedRelationshipEdges: this.state.selectedRelationshipEdges.filter(
            e =>
              e.relationshipTypeId != edge.relationshipTypeId ||
              e.entityIdFrom != edge.entityIdFrom ||
              e.entityIdTo != edge.entityIdTo,
          ),
        });
      } else {
        this.setState({
          selectedRelationshipEdges: this.state.selectedRelationshipEdges.concat([edge]),
        });
      }
    };

    const toggleAllRelationshipEdges = (): void => {
      if (isAllRelationshipEdgeSelected()) {
        this.setState({
          selectedRelationshipEdges: [],
        });
      } else {
        this.setState({
          selectedRelationshipEdges: filteredEdges,
        });
      }
    };

    return (
      <>
        <SettingCard title="Relationships" subTitle="These are the actual relationships between users.">
          <>
            <div>
              <div>
                <div style={styles.formFilterSelect}>
                  <Select
                    options={getFrom(relationshipTypes)
                      .defaultTo([])
                      .map(t => {
                        return { label: t.name, value: t.id };
                      })}
                    value={this.state.selectedFilterType}
                    onChange={option => this.loadEdges(option.value)}
                    placeholder="Select a type"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '25px',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: '5px' }}>
                      <Button
                        icon={<FiPlusCircle />}
                        color="accent"
                        variant="link"
                        disabled={!this.state.selectedFilterType || loadingEdges}
                        onClick={() => this.setState({ ...this.state, showAddModal: true })}
                      >
                        Add
                      </Button>
                    </div>
                    <div>
                      <Button
                        icon={<FiMinusCircle />}
                        color="accent"
                        variant="link"
                        disabled={this.state.selectedRelationshipEdges.length == 0 || loadingEdges}
                        onClick={() => this.setState({ ...this.state, showDeleteRelationshipEdgesModal: true })}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                  <Button
                    icon={<FiFilter fill={isFiltered() ? 'rgb(81, 45, 168)' : 'none'} />}
                    color="accent"
                    variant="link"
                    disabled={loadingEdges || getFrom(relationshipEdges)('relationshipEdges').defaultTo([]).length == 0}
                    onClick={() => this.setState({ ...this.state, showFilterModal: true })}
                  >
                    Filter
                  </Button>
                </div>
              </div>
              {getFrom(relationshipTypes).defaultTo([]).length > 0 && (
                <EdgesTable
                  page={this.state.pagination.page}
                  pageSize={this.state.pagination.pageSize}
                  setPage={page => this.setState({ ...this.state, pagination: { ...this.state.pagination, page } })}
                  loadingEdges={loadingEdges}
                  relationshipEdges={filteredEdges}
                  relationshipNodes={getFrom(relationshipEdges)('relationshipNodes').defaultTo({})}
                  relationshipType={relationshipTypes.find(rt => rt.id == this.state.selectedFilterType)}
                  isRelationshipEdgeSelected={isRelationshipEdgeSelected}
                  toggleRelationshipEdge={toggleRelationshipEdge}
                  isAllRelationshipEdgeSelected={isAllRelationshipEdgeSelected}
                  toggleAllRelationshipEdges={toggleAllRelationshipEdges}
                  selectedCount={() => this.state.selectedRelationshipEdges.length}
                />
              )}
            </div>
          </>
        </SettingCard>
        {this.state.showFilterModal && this.renderFilterModal()}
        {this.state.showAddModal && this.renderAddModal()}
        {addingEdges && this.renderLoadAddingModal()}
        {this.state.showDeleteRelationshipEdgesModal && this.renderDeleteRelationshipEdgesModal()}
      </>
    );
  }

  renderAddModal() {
    const relationshipType = this.props.relationshipTypes.find(x => x.id == this.state.selectedFilterType);

    const removeEmailFromEntityName = entityName => {
      return entityName.substring(0, entityName.indexOf(' ('));
    };

    const formComplete = () => {
      return relationshipType && this.state.selectedUsersFrom.length > 0 && this.state.selectedUsersTo.length > 0;
    };

    const validateData = (client: ApiClient) => {
      this.setState({ ...this.state, dataValidationMessage: '' });
      client.api.relationships
        .validateTypeEdges(
          relationshipType.id,
          this.state.newRelationshipEdges.map(e => {
            return { entityIdFrom: e.entityIdFrom, entityIdTo: e.entityIdTo };
          }),
        )
        .then(validationResponse => {
          if (validationResponse.data.length > 0) {
            let firstMessage = validationResponse.data[0];
            this.state.newRelationshipEdges.forEach(n => {
              if (firstMessage.indexOf(n.entityIdFrom) >= 0)
                firstMessage = firstMessage.replace(n.entityIdFrom, removeEmailFromEntityName(n.entityNameFrom));
              if (firstMessage.indexOf(n.entityIdTo) >= 0)
                firstMessage = firstMessage.replace(n.entityIdTo, removeEmailFromEntityName(n.entityNameTo));
            });
            this.setState({ ...this.state, dataValidationMessage: firstMessage });
          }
        });
    };

    const validateForm = () => {
      if (formComplete()) {
        if (this.state.selectedUsersFrom.length > 1 && this.state.selectedUsersTo.length > 1) {
          return 'Cannot add multiple to multiple';
        }
        if (
          this.state.selectedUsersFrom.some(userFrom =>
            this.state.selectedUsersTo.some(userTo => userTo.value == userFrom.value),
          ) ||
          this.state.selectedUsersTo.some(userTo =>
            this.state.selectedUsersFrom.some(userFrom => userFrom.value == userTo.value),
          )
        ) {
          return 'Users from and to must be different';
        }
        if (
          this.state.newRelationshipEdges.some(
            e =>
              (this.state.selectedUsersFrom.some(userFrom => userFrom.value == e.entityIdFrom) &&
                this.state.selectedUsersTo.some(userTo => userTo.value == e.entityIdTo)) ||
              (this.state.selectedUsersFrom.some(userFrom => userFrom.value == e.entityIdTo) &&
                this.state.selectedUsersTo.some(userTo => userTo.value == e.entityIdFrom)),
          )
        ) {
          return 'There is already a relationship in the list with these values';
        }
        if (
          this.props.relationshipEdges.relationshipEdges.some(
            e =>
              (this.state.selectedUsersFrom.some(userFrom => userFrom.value == e.entityIdFrom) &&
                this.state.selectedUsersTo.some(userTo => userTo.value == e.entityIdTo)) ||
              (this.state.selectedUsersFrom.some(userFrom => userFrom.value == e.entityIdTo) &&
                this.state.selectedUsersTo.some(userTo => userTo.value == e.entityIdFrom)),
          )
        ) {
          return 'There is already a relationship stored with these values';
        }
      }
      return '';
    };

    const canAdd = () => {
      return formComplete() && !validateForm();
    };

    const canSave = () => {
      return this.state.newRelationshipEdges.length > 0 && !this.state.dataValidationMessage;
    };

    const clearForm = () => {
      this.setState({
        dataValidationMessage: '',
        selectedUsersFrom: [],
        selectedUsersTo: [],
        newRelationshipEdges: [],
        newRelationshipsPagination: RelationshipEdges.defaultPagination,
      });
    };

    return (
      <>
        <Modal
          modalWidth="1000px"
          onClose={() => {
            clearForm();
            this.setState({ showAddModal: false });
          }}
        >
          <Modal.Header>Adding new relationships</Modal.Header>
          <div style={styles.formRow}>
            <div style={{ width: 420, marginRight: '10px' }}>
              {this.state.selectedUsersFrom.length <= 1 && (
                <PeoplePicker
                  isLoading={this.props.usersLoading}
                  isMulti={false}
                  value={this.state.selectedUsersFrom || ([] as any)}
                  onChange={option => {
                    if (!option) {
                      this.setState({
                        ...this.state,
                        selectedUsersFrom: [],
                      });
                      return;
                    }
                    this.setState({
                      ...this.state,
                      selectedUsersFrom: this.state.selectedUsersFrom.concat([option]),
                    });
                  }}
                  clearUsers={this.props.clearUsers}
                  loadUsers={this.props.loadUsers}
                  users={this.props.users}
                />
              )}
              {this.state.selectedUsersFrom.length > 1 && (
                <div
                  style={{
                    padding: '12px',
                    borderColor: '#d4d4d4',
                    borderRadius: '3px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                  }}
                >
                  <Button
                    title="Click to change"
                    icon={<FiUsers />}
                    color="accent"
                    variant="link"
                    onClick={() => {
                      this.setState({ showUsersSelectedDetailModalFrom: true });
                    }}
                  >
                    {this.state.selectedUsersFrom.length} users selected
                  </Button>
                </div>
              )}
              <Button
                icon={<FiSearch />}
                disabled={this.props.loadingFieldSchema}
                color="accent"
                variant="link"
                onClick={() => {
                  this.setState({ showAdvancedSearchModalFrom: true });
                }}
              >
                Advanced search
              </Button>
            </div>
            <div style={{ width: 420, marginRight: '10px' }}>
              {this.state.selectedUsersTo.length <= 1 && (
                <PeoplePicker
                  isLoading={this.props.usersLoading}
                  isMulti={false}
                  value={this.state.selectedUsersTo || ([] as any)}
                  onChange={option => {
                    if (!option) {
                      this.setState({
                        ...this.state,
                        selectedUsersTo: [],
                      });
                      return;
                    }
                    this.setState({
                      ...this.state,
                      selectedUsersTo: this.state.selectedUsersTo.concat([option]),
                    });
                  }}
                  clearUsers={this.props.clearUsers}
                  loadUsers={this.props.loadUsers}
                  users={this.props.users}
                />
              )}
              {this.state.selectedUsersTo.length > 1 && (
                <div
                  style={{
                    padding: '12px',
                    borderColor: '#d4d4d4',
                    borderRadius: '3px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                  }}
                >
                  <Button
                    icon={<FiUsers />}
                    color="accent"
                    variant="link"
                    onClick={() => {
                      this.setState({ showUsersSelectedDetailModalTo: true });
                    }}
                  >
                    {this.state.selectedUsersTo.length} users selected
                  </Button>
                </div>
              )}
              <Button
                icon={<FiSearch />}
                disabled={this.props.loadingFieldSchema}
                color="accent"
                variant="link"
                onClick={() => {
                  this.setState({ showAdvancedSearchModalTo: true });
                }}
              >
                Advanced search
              </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', width: '50px' }}>
              <ApiClientContext.Consumer>
                {client => (
                  <Button
                    style={{ marginBottom: '20px' }}
                    icon={<FiPlusCircle size="large" />}
                    color="accent"
                    disabled={!canAdd()}
                    title="Add new relationship for selected users"
                    variant="link"
                    onClick={() => {
                      let newRelationshipEdges = [];
                      this.state.selectedUsersFrom.forEach(userFrom => {
                        this.state.selectedUsersTo.forEach(userTo => {
                          newRelationshipEdges.push({
                            entityIdFrom: userFrom.value,
                            entityNameFrom: userFrom.label,
                            entityIdTo: userTo.value,
                            entityNameTo: userTo.label,
                            metaData: {},
                            relationshipTypeId: this.state.selectedFilterType,
                          });
                        });
                      });
                      this.setState({
                        ...this.state,
                        selectedUsersFrom: [],
                        selectedUsersTo: [],
                        newRelationshipEdges: this.state.newRelationshipEdges.concat(newRelationshipEdges),
                      });
                      setTimeout(() => {
                        validateData(client);
                      }, 1);
                    }}
                  />
                )}
              </ApiClientContext.Consumer>
            </div>
          </div>
          <div
            style={{
              color: 'red',
              fontSize: 'small',
              display: 'flex',
              justifyContent: 'flex-end',
              paddingBottom: '10px',
            }}
          >
            {validateForm() ||
              (this.state.dataValidationMessage ? 'Cannot save, ' + this.state.dataValidationMessage : '')}
          </div>
          <div>
            <div className={classes.table}>
              <div className={classes.row}>
                <div className={cx(classes.tableHeader, classes['isCenter'], classes.userAddContent)}>
                  <p>User from</p>
                </div>
                <div className={cx(classes.tableHeader, classes['isCenter'], classes.relationshipAddContent)}>
                  <p>Relationship</p>
                </div>
                <div className={cx(classes.tableHeader, classes['isCenter'], classes.userAddContent)}>
                  <p>User to</p>
                </div>
                <div className={cx(classes.tableHeader, classes['isCenter'], classes.actions)}>
                  <p>Actions</p>
                </div>
              </div>
              {getFrom(this.state.newRelationshipEdges)
                .defaultTo([])
                .slice(
                  (this.state.newRelationshipsPagination.page - 1) * this.state.newRelationshipsPagination.pageSize,
                  this.state.newRelationshipsPagination.page * this.state.newRelationshipsPagination.pageSize,
                )
                .map((item, index) => (
                  <div
                    key={`${item.entityIdFrom}-${item.entityIdTo}`}
                    className={cx(classes.row, {
                      [classes['isAlt']]: index % 2 === 0,
                    })}
                  >
                    <div className={cx(classes.tableCell, classes['isCenter'], classes.userAddContent)}>
                      <div style={styles.form}>{item.entityNameFrom}</div>
                    </div>
                    <div className={cx(classes.tableCell, classes['isCenter'], classes.relationshipAddContent)}>
                      <div style={styles.form}>
                        <div>
                          <p>
                            <b>{getFrom(relationshipType)('metaData')('relationshipDescription').defaultTo('')}</b>
                          </p>
                        </div>
                        <div>
                          {!getFrom(relationshipType)('isHierarchical').defaultTo(false) && <FiArrowLeft />}
                          <FiArrowRight />
                        </div>
                      </div>
                    </div>
                    <div className={cx(classes.tableCell, classes['isCenter'], classes.userAddContent)}>
                      <div style={styles.form}>{item.entityNameTo}</div>
                    </div>
                    <div className={cx(classes.tableCell, classes['isCenter'], classes.actions)}>
                      <div style={StyleUtil.styles.tables.actions_container}>
                        <ApiClientContext.Consumer>
                          {client => (
                            <IconButton
                              css={null}
                              onClick={() => {
                                this.setState({
                                  newRelationshipEdges: this.state.newRelationshipEdges.filter(
                                    r =>
                                      r.relationshipTypeId != item.relationshipTypeId ||
                                      r.entityIdFrom != item.entityIdFrom ||
                                      r.entityIdTo != item.entityIdTo,
                                  ),
                                });
                                setTimeout(() => {
                                  validateData(client);
                                }, 1);
                              }}
                              title={'Remove'}
                              ariaLabel="Remove relationship"
                              icon={<FiTrash />}
                              color="error"
                              style={{ marginBottom: 0 }}
                            />
                          )}
                        </ApiClientContext.Consumer>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <Pager
              setPage={page =>
                this.setState({
                  ...this.state,
                  newRelationshipsPagination: { ...this.state.newRelationshipsPagination, page },
                })
              }
              pagination={{
                page: this.state.newRelationshipsPagination.page,
                pageSize: this.state.newRelationshipsPagination.pageSize,
                count: getFrom(this.state.newRelationshipEdges).defaultTo([]).length,
              }}
            />
          </div>
          <Modal.ButtonContainer>
            <Button
              size="small"
              onClick={() => {
                clearForm();
                this.setState({ showAddModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="solid"
              size="small"
              disabled={!canSave()}
              onClick={() => {
                this.props.addRelationshipEdges(
                  this.state.selectedFilterType,
                  this.state.newRelationshipEdges.map(e => {
                    return {
                      relationshipTypeId: e.relationshipTypeId,
                      entityIdFrom: e.entityIdFrom,
                      entityIdTo: e.entityIdTo,
                      metaData: {},
                    } as RelationshipEdgeRequest;
                  }),
                );
                clearForm();
                this.setState({ showAddModal: false });
              }}
            >
              Save
            </Button>
          </Modal.ButtonContainer>
        </Modal>
        {(this.state.showAdvancedSearchModalFrom || this.state.showAdvancedSearchModalTo) &&
          this.renderAdvancedSearch()}
        {(this.state.showUsersSelectedDetailModalFrom || this.state.showUsersSelectedDetailModalTo) &&
          this.renderUsersSelectedDetailModal()}
      </>
    );
  }

  private renderUsersSelectedDetailModal() {
    return (
      <Modal>
        {this.state.showUsersSelectedDetailModalFrom && (
          <PeoplePicker
            isLoading={this.props.usersLoading}
            isMulti={true}
            value={this.state.selectedUsersFrom || ([] as any)}
            onChange={options => {
              if (!options || options.length == 0) {
                this.setState({
                  ...this.state,
                  selectedUsersFrom: [],
                });
                return;
              }
              this.setState({
                ...this.state,
                selectedUsersFrom: options,
              });
            }}
            clearUsers={this.props.clearUsers}
            loadUsers={this.props.loadUsers}
            users={this.props.users}
          />
        )}
        {this.state.showUsersSelectedDetailModalTo && (
          <PeoplePicker
            isLoading={this.props.usersLoading}
            isMulti={true}
            value={this.state.selectedUsersTo || ([] as any)}
            onChange={options => {
              if (!options || options.length == 0) {
                this.setState({
                  ...this.state,
                  selectedUsersTo: [],
                });
                return;
              }
              this.setState({
                ...this.state,
                selectedUsersTo: options,
              });
            }}
            clearUsers={this.props.clearUsers}
            loadUsers={this.props.loadUsers}
            users={this.props.users}
          />
        )}
        <Modal.ButtonContainer>
          <Button
            size="small"
            onClick={() => {
              this.setState({ showUsersSelectedDetailModalFrom: false, showUsersSelectedDetailModalTo: false });
            }}
          >
            Close
          </Button>
          <Button
            icon={<FiTrash />}
            color="red"
            size="small"
            onClick={() => {
              if (this.state.showUsersSelectedDetailModalFrom) this.setState({ selectedUsersFrom: [] });
              if (this.state.showUsersSelectedDetailModalTo) this.setState({ selectedUsersTo: [] });
              this.setState({ showUsersSelectedDetailModalFrom: false, showUsersSelectedDetailModalTo: false });
            }}
          >
            Clear list
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  private renderAdvancedSearch() {
    return (
      <ApiClientContext.Consumer>
        {client => (
          <DirectorySearch
            displayMode={'userPickerModal'}
            defaultView={'table'}
            disableViewSelect={true}
            schema={this.props.fieldSchema}
            audienceId={this.props.orgId}
            masterAudienceId={this.props.orgId}
            currentUserId={this.props.currentUserId}
            isAdmin={this.props.isAdmin}
            isEditor={this.props.isEditor}
            fetchResults={request => client.api.directory.search(request).then(({ data }) => data)}
            fetchUsers={(q, limit) => client.api.externalUsers.search(q, limit).then(res => res.data)}
            fetchTree={request => client.api.directory.tree(request).then(({ data }) => data)}
            fetchDetail={id => client.api.externalUsers.get(id, { cacheok: 1 }).then(({ data }) => data)}
            onModalSubmit={(data: any[]) => {
              if (this.state.showAdvancedSearchModalFrom) {
                this.setState({
                  selectedUsersFrom: data.map(d => {
                    return {
                      value: d.id || d.objectguid,
                      label:
                        d.displayName || d.displayname
                          ? `${d.displayName || d.displayname} (${d.mail || 'No Email'})`
                          : d.mail,
                    };
                  }),
                });
              }
              if (this.state.showAdvancedSearchModalTo) {
                this.setState({
                  selectedUsersTo: data.map(d => {
                    return {
                      value: d.id || d.objectguid,
                      label:
                        d.displayName || d.displayname
                          ? `${d.displayName || d.displayname} (${d.mail || 'No Email'})`
                          : d.mail,
                    };
                  }),
                });
              }
              this.setState({ showAdvancedSearchModalFrom: false, showAdvancedSearchModalTo: false });
            }}
            onModalCancel={() => {
              this.setState({ showAdvancedSearchModalFrom: false, showAdvancedSearchModalTo: false });
            }}
          />
        )}
      </ApiClientContext.Consumer>
    );
  }

  private renderFilterModal() {
    const { relationshipEdges } = this.props;
    const { selectedFilterEntityIdFrom, selectedFilterEntityIdTo } = this.state;

    const usersFrom: Set<string> = new Set();
    getFrom(relationshipEdges)('relationshipEdges')
      .defaultTo([])
      .forEach(t => {
        if (!usersFrom.has(t.entityIdFrom)) {
          usersFrom.add(t.entityIdFrom);
        }
      });

    const usersTo: Set<string> = new Set();
    getFrom(relationshipEdges)('relationshipEdges')
      .defaultTo([])
      .forEach(t => {
        if (!usersTo.has(t.entityIdTo)) {
          usersTo.add(t.entityIdTo);
        }
      });

    return (
      <Modal
        onClose={() =>
          this.setState({
            ...this.state,
            showFilterModal: false,
            selectedFilterEntityIdFrom: null,
            selectedFilterEntityIdTo: null,
          })
        }
      >
        <Modal.Header>User filters</Modal.Header>
        <div style={styles.formRow}>
          <div style={{ flexGrow: 0.5, marginRight: '10px' }}>
            <Select
              options={Array.from(usersFrom).map(id => {
                return {
                  label:
                    relationshipEdges.relationshipNodes[id].displayName ||
                    relationshipEdges.relationshipNodes[id].givenName,
                  value: id,
                };
              })}
              value={selectedFilterEntityIdFrom}
              onChange={option => this.setState({ ...this.state, selectedFilterEntityIdFrom: option.value })}
              placeholder="Select a user from"
            />
          </div>
          <div style={{ flexGrow: 0.5 }}>
            <Select
              options={Array.from(usersTo).map(id => {
                return {
                  label:
                    relationshipEdges.relationshipNodes[id].displayName ||
                    relationshipEdges.relationshipNodes[id].givenName,
                  value: id,
                };
              })}
              value={selectedFilterEntityIdTo}
              onChange={option => this.setState({ ...this.state, selectedFilterEntityIdTo: option.value })}
              placeholder="Select a user to"
            />
          </div>
        </div>
        <Modal.ButtonContainer>
          <Button
            size="small"
            onClick={() => {
              this.setState({
                ...this.state,
                showFilterModal: false,
                selectedFilterEntityIdFrom: null,
                selectedFilterEntityIdTo: null,
              });
            }}
          >
            Clear Filter
          </Button>
          <Button
            color="primary"
            variant="solid"
            size="small"
            onClick={() => {
              this.setState({
                ...this.state,
                showFilterModal: false,
                pagination: RelationshipEdges.defaultPagination,
              });
            }}
            disabled={!selectedFilterEntityIdFrom && !selectedFilterEntityIdTo}
          >
            Apply Filter
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  private renderLoadAddingModal() {
    return (
      <Modal onClose={() => this.setState({ ...this.state, showFilterModal: false })}>
        <div style={{ textAlign: 'center' }}>
          <Modal.Header>Saving</Modal.Header>
        </div>
        <LoadingSplash />
      </Modal>
    );
  }

  renderDeleteRelationshipEdgesModal() {
    return (
      <Modal onClose={() => this.setState({ showDeleteRelationshipEdgesModal: false })}>
        <Modal.Header>Are you sure you want to delete the selected relationships?</Modal.Header>
        <Modal.ButtonContainer>
          <Button
            size="small"
            onClick={() => {
              this.setState({ showDeleteRelationshipEdgesModal: false });
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="solid"
            size="small"
            onClick={() => {
              this.props.deleteRelationshipEdges(this.state.selectedFilterType, this.state.selectedRelationshipEdges);
              this.setState({ ...this.state, selectedRelationshipEdges: [], showDeleteRelationshipEdgesModal: false });
            }}
          >
            Delete
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }
}
