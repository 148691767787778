import { AnalyticsReport } from '@hyperfish/antrea-api-contracts';
import moment from 'moment';
import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import classes from './styles.module.scss';

export interface TrendLineConfig {
  audienceId: string;
  properties: string[];
  displayName: string;
  color: string;
}

interface Props {
  reportsByAudience: { [audienceId: string]: AnalyticsReport };
  lines: TrendLineConfig[];
  removeLineAtIndex: (index: number) => void;
  isLoading?: boolean;
}

interface State {
  hoveredLine: number;
}

export class TrendChart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hoveredLine: null,
    };
  }

  private handleMouseEnter = ({ dataKey }) => {
    this.setState({ hoveredLine: dataKey });
  };
  private handleMouseLeave = () => {
    this.setState({ hoveredLine: null });
  };
  private handleClick = ({ dataKey }) => {
    if (!this.props.removeLineAtIndex) {
      return;
    }
    this.props.removeLineAtIndex(dataKey);
  };

  private getData() {
    const { reportsByAudience, lines, isLoading } = this.props;
    const dataByTime = {};
    const data = [];

    if (isLoading) {
      return data;
    }

    if (reportsByAudience) {
      Object.keys(reportsByAudience).forEach(audienceId => {
        const report = reportsByAudience[audienceId];
        if (!report) {
          return;
        }
        for (const a of report.averages) {
          const timepoint = moment(a.timepoint, moment.ISO_8601).valueOf();
          if (dataByTime[timepoint] == null) {
            dataByTime[timepoint] = { timepoint };
          }
          const statByPath = {};
          for (const stat of a.stats) {
            statByPath[stat.path.substr(1)] = stat;
            const total = stat.passed + stat.missing + stat.failed;
            dataByTime[timepoint][this.getDataKey(audienceId, stat.path.substr(stat.path.lastIndexOf('/') + 1))] =
              stat.passed / total;
          }
        }
      });

      Object.keys(dataByTime).forEach(key => {
        const dataPoint = dataByTime[key];
        const lineData = { timepoint: dataPoint.timepoint };

        lines.forEach((l, i) => {
          let total = 0;
          let count = 0;
          l.properties.forEach(p => {
            const data = dataPoint[this.getDataKey(l.audienceId, p)];
            if (data != null) {
              total += data;
            }
            count++;
          });
          const avg = total / count;
          lineData[i] = avg;
        });

        data.push(lineData);
      });
    }

    return data;
  }

  private getDataKey(audienceId: string, property: string): string {
    return `${audienceId}_${property}`;
  }

  render() {
    const { lines, isLoading } = this.props;
    const { hoveredLine } = this.state;

    const formatPercent = (value: number) => {
      if (isNaN(value)) {
        return '--%';
      }
      return `${Math.round(value * 100)}%`;
    };

    const formatTime = (value: number) => moment(value).format('l');

    return (
      <div className={classes.container}>
        <ResponsiveContainer>
          <LineChart data={this.getData()} margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <XAxis
              dataKey="timepoint"
              type="number"
              scale="time"
              domain={['dataMin', 'dataMax']}
              tickFormatter={formatTime}
              tickMargin={10}
            />
            <YAxis type="number" tickFormatter={formatPercent} domain={[0, 1]} />
            <Tooltip labelFormatter={(value: number) => moment(value).format('ll')} formatter={formatPercent} />
            <Legend
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onClick={this.handleClick}
              wrapperStyle={{
                cursor: 'pointer',
                paddingTop: 10,
              }}
            />
            {lines.map((l, i) => (
              <Line
                key={i}
                type="monotone"
                dataKey={i}
                name={l.displayName}
                strokeOpacity={hoveredLine != null && hoveredLine !== i ? 0.4 : 1}
                style={{ transition: 'stroke-opacity 250ms' }}
                stroke={l.color}
                legendType="square"
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
        <p className={classes.legendLabel}>Click line label in legend to remove</p>
        {!lines ||
          (lines.length === 0 && (
            <div className={classes.emptyMessage}>
              <p>Please add a trend line from the table below.</p>
            </div>
          ))}
        {isLoading && (
          <div className={classes.emptyMessage}>
            <p>Loading...</p>
          </div>
        )}
      </div>
    );
  }
}
