import { DevicesState as State } from '../../models/client';
import { ApiClient } from '@hyperfish/fishfood';

const LOAD = 'antrea/devices/LOAD';
const LOAD_SUCCESS = 'antrea/devices/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/devices/LOAD_FAIL';
const DELETE = 'antrea/devices/DELETE';
const DELETE_SUCCESS = 'antrea/devices/DELETE_SUCCESS';
const DELETE_FAIL = 'antrea/devices/DELETE_FAIL';
const CLEAR = 'antrea/devices/CLEAR';

const initialState: State = {
  loaded: false,
  deleting: {},
  deleteError: {},
};

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.devices,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case DELETE:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          [action.id]: true,
        },
      };
    case DELETE_SUCCESS: {
      const newDevices = state.data.slice();

      for (let i = 0; i < newDevices.length; i++) {
        if (newDevices[i].id === action.id) {
          newDevices.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        deleting: {
          ...state.deleting,
          [action.id]: false,
        },
        deleteError: {
          ...state.deleteError,
          [action.id]: null,
        },
        data: newDevices,
      };
    }
    case DELETE_FAIL:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          [action.id]: false,
        },
        deleteError: {
          ...state.deleteError,
          [action.id]: action.error,
        },
      };
    case CLEAR:
      return {
        ...state,
        data: null,
        loaded: false,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.devices && globalState.devices.loaded;
}

export function shouldLoad(globalState) {
  return globalState.devices && !globalState.devices.loading;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/devices'),
  };
}

export function remove(id: string) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    promise: (client: ApiClient) => client.del(`/devices/${id}`),
    id,
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}
