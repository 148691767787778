import React from 'react';

export const modalContent = {
  collections: (
    <div>
      <h1>Collections</h1>
      <img src={require('./assets/Collections.png')} alt="Screenshot of adding a new collection" />
      <p>
        Your organization may have different rules for collecting profile information that you would like to apply to
        different groups of users in your organization. These users may have the same office location, job title, or
        country. Collections allow you to create these groups using your directory’s organization units or an attribute
        value in users’ profiles.
      </p>
      <p>
        For example, you may want to collect the sales region for employees on the sales team but not have this
        information required for other employees in your organization.
      </p>
    </div>
  ),
  emailHistory: (
    <div>
      <h1>Email History</h1>
      <img src={require('./assets/EmailHistory.png')} alt="Screenshot of user's email history" />
      <p>
        Search for a user and review both their profile page and a full history of emails your Hyperbot has sent them.
        Email history includes, emails regarding missing or incorrect profile information, profile validation, and
        approvals.
      </p>
    </div>
  ),
  adminReport: (
    <div>
      <h1>Monthly Admin Report</h1>
      <p>
        LiveTiles Directory will send Administrators a monthly report with the latest directory health and engagement
        statistics.
      </p>
    </div>
  ),
  dailyFullScan: (
    <div>
      <h1>Daily Full Scan</h1>
      <p>
        Run a full scan when it works best for your organization. LiveTiles Directory runs two types of scans to detect
        any changes in profile information. Real time scans are run every 15 minutes. Full scans are run once daily.
      </p>
    </div>
  ),
  autoApproval: (
    <div>
      <h1>Auto Approval</h1>
      <img src={require('./assets/AutoApproval.png')} alt="Screenshot of Auto Approval settings" />
      <p>
        With Auto Approval for all attributes, you are in full control of what information goes into your directory.
        Attributes like Job Title, Department, and Manager may require approval, while changes to Mobile Phone can be
        saved to the directory without review.
      </p>
      <p>
        Additionally, different approvers can be assigned to profile attributes. For example, you can have your Human
        Resources Manager approve changes to Job Title and Department, while the user’s manager approves Profile Picture
        changes.
      </p>
    </div>
  ),
  attributes: (
    <div>
      <h1>Attributes</h1>
      <img src={require('./assets/Attributes.png')} alt="Screenshot of Attribute edit modal" />
      <p>
        All out of the box attributes, extension attributes, and settings are available when you upgrade to Premium.
        Each attribute can be customized to meet your specific needs by:
      </p>
      <ol>
        <li>Defining a friendly display name and hint text for users to see on their profile page</li>
        <li>Enforcing defined validation rules for the attribute</li>
        <li>Determining whether the attribute must contain a value or if it can be left blank</li>
        <li>
          Selecting whether the attribute will be hidden, read only, or editable on the profile page and whether the
          Hyperbot will contact users to populate the attribute
        </li>
      </ol>
    </div>
  ),
  photoValidation: (
    <div>
      <h1>Photo Validation</h1>
      <img src={require('./assets/PhotoValidation.png')} alt="Screenshot of photo validation settings" />
      <p>
        Ensure that profile photos adhere to your organization’s standards by determining whether you will allow racy
        content, adult content, no faces, or multiple faces. Photos are validated upon upload so you don’t have to worry
        about cartoons, family photos, or pictures of pets ending up in your directory.
      </p>
    </div>
  ),
  formats: (
    <div>
      <h1>Formats</h1>
      <img src={require('./assets/Formats.png')} alt="Screenshot of format edit modal" />
      <p>
        Profile information may be complete but still incorrect. Formats ensure that profile information is compliant
        with company standards and pre-defined values. For example, if a user has a job title that does not match the
        list of values you set, Hyperbot will contact the user to update their job title to one of the pre-defined
        values.
      </p>
      <p>The following formats can be defined and then applied to one or many attributes:</p>
      <ol>
        <li>Autocomplete</li>
        <li>Dropdown</li>
        <li>Number</li>
        <li>Text</li>
        <li>Long Text</li>
        <li>Toggle</li>
      </ol>
    </div>
  ),
  categories: (
    <div>
      <h1>Categories</h1>
      <img src={require('./assets/Categories.png')} alt="Screenshot of category edit modal" />
      <p>
        Make it easier for users to update their profile information by organizing the attributes on the profile page
        into groups. Changes to attributes in a category must be saved at the same time ensuring that all required
        information iscomplete. You can also determine the order that attributes are displayed in each category.
      </p>
    </div>
  ),
  emailAddress: (
    <div>
      <h1>Hyperbot Email Address Customization</h1>
      <p>
        Set a default from: email address for your Hyperbot that users in your organization will recognize. Give your
        users more comfort in providing their profile information to a known person within the organization.
      </p>
    </div>
  ),
  hyperbotPersonality: (
    <div>
      <h1>Hyperbot Personality</h1>
      <img src={require('./assets/HyperbotPersonality.png')} alt="Screenshot of Hyperbot personality setting" />
      <p>
        Increase your user engagement by setting the tone that is used when your Hyperbot communicates with your users
        to collect their profile information. There are three available personalities:
      </p>
      <ol>
        <li>Relaxed</li>
        <li>Standard</li>
        <li>Formal</li>
      </ol>
      <p>Additionally, you can create your own custom personality by editing any of the available email templates.</p>
    </div>
  ),
  emailCustomization: (
    <div>
      <h1>Email Customization</h1>
      <img src={require('./assets/EmailCustomization.png')} alt="Screenshot of email template editing modal" />
      <p>
        Modify the content of the email your Hyperbot sends to users, to align with email policies and your corporate
        culture. You may want to incorporate change management, policy, or just a bit of extra help for your users to
        encourage them to populate their profiles.
      </p>
      <p>Full Unicode character sets are supported, so you can also modify the language of each email.</p>
    </div>
  ),
  attempts: (
    <div>
      <h1>Attempts</h1>
      <img src={require('./assets/Attempts.png')} alt="Screenshot of attempts setting" />
      <p>
        Set how many times you would like to reach out to users with missing or incorrect profile information. For some
        organizations, once might be enough but others may require more outreach to get their profiles up to date.
      </p>
    </div>
  ),
  profileValidation: (
    <div>
      <h1>Profile Validation</h1>
      <img src={require('./assets/ProfileValidation.png')} alt="Screenshot of profile validation settings" />
      <p>
        Profile information may be complete and in the correct format but could still be out of date. Profile Validation
        allows you to periodically contact users and have them review their profile information and make any necessary
        updates or confirm that their information is correct.
      </p>
      <p>
        Choose a specific date and time you would like to contact users and determine how often you would like to
        contact them on a recurring basis.
      </p>
    </div>
  ),
};
