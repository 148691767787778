import { EventsState as State, GlobalState } from '../../models/client/';
import { ApiClient } from '@hyperfish/fishfood';

const LOAD = 'antrea/events/LOAD';
const LOAD_SUCCESS = 'antrea/events/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/events/LOAD_FAIL';
const CLEAR = 'antrea/events/CLEAR';

export type LoadEventsParams = State['dataParamsByKey']['foo'];

export interface LoadEventsApiCall {
  offset: number;
  limit: number;
  userIds: string;
  startDate?: Date;
  endDate?: Date;
  types: string;
  fields?: string;
}

const initialState: State = {
  eventsDataByKey: {},
  eventsErrorByKey: {},
  dataParamsByKey: {},
} as State;

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        eventsLoading: true,
        eventsError: null,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        dataParamsByKey: { ...state.dataParamsByKey, [action.key]: action.dataParams },
        eventsDataByKey: { ...state.eventsDataByKey, [action.key]: action.result },
        eventsError: null,
        eventsErrorByKey: { ...state.eventsErrorByKey, [action.key]: null },
        eventsLoading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        ...action.result,
        // eventsData: null,
        eventsError: action.error,
        eventsErrorByKey: { ...state.eventsErrorByKey, [action.key]: action.error },
        eventsLoading: false,
      };
    case CLEAR:
      return {
        ...state,
        // eventsData: null,
        // dataParams: null,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState: GlobalState, key: string) {
  return globalState.events && !!globalState.events.eventsDataByKey[key];
}

export function shouldLoad(globalState: GlobalState, key: string) {
  return globalState.events && !globalState.events.eventsLoading && !globalState.events.eventsDataByKey[key];
}

export function load(o = {} as LoadEventsParams, key: string) {
  const offset = (o.page || 0) * 25;
  const limit = 25;
  const params: LoadEventsApiCall = {
    offset,
    limit,
    userIds: (o.users || []).map(o => o.value).join(','),
    types: (o.types || []).join(','),
    startDate: o.startDate,
    endDate: o.endDate,
    fields: (o.fields || []).join(','),
  };
  if (!params.types) {
    delete params.types;
  }
  if (!params.userIds || (o.types || []).indexOf('audit') > -1) {
    delete params.userIds;
  }
  if (!params.startDate) {
    delete params.startDate;
  }
  if (!params.endDate) {
    delete params.endDate;
  }
  if (!params.fields) {
    delete params.fields;
  }
  const dataParams: LoadEventsParams = {
    page: o.page || 0,
    users: o.users,
    types: o.types,
    startDate: o.startDate,
    endDate: o.endDate,
    fields: o.fields,
  };
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/orgs/current/events', { params }),
    key,
    dataParams,
    notifications: {
      fail: 'Failed to load Events',
    },
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}
