/* eslint-disable @typescript-eslint/no-use-before-define */
import { Checkbox, LoadingSplash, ButtonContainer, IconButton, FiEdit2, FiTrash2 } from '@hyperfish/fishfood';
import moment from 'moment';
import Radium from 'radium';
import React from 'react';

import StyleUtil from '../utils/StyleUtil';
import { Icon } from './Icon';

type ColumnType = 'string' | 'number' | 'date' | 'datetime' | 'checkmark' | 'custom';

interface Column {
  center?: boolean;
  hidden?: boolean;
  label?: string;
  sortable?: boolean;
  type?: ColumnType;
  width?: number;
  nowrap?: boolean;
}

interface ColumnsDefinition {
  [property: string]: Column;
}

type MappedData<T extends ColumnsDefinition> = {
  id: string;
  groupKey?: string;
} & { [property in keyof T]: string | number | boolean | JSX.Element };

interface Props<T extends ColumnsDefinition> {
  actions?: {
    icon: string | JSX.Element;
    onAction: (item: MappedData<T>) => void;
    disabled?: boolean | ((item: MappedData<T>) => boolean);
    title?: string;
  }[];
  columns?: T;
  customDeleteIcon?: string | JSX.Element;
  customEditIcon?: string | JSX.Element;
  data: MappedData<T>[];
  defaultSortBy?: keyof T;
  defaultSortDir?: State<T>['sortDir'];
  getRowStyle?: (item: MappedData<T>) => React.CSSProperties;
  groupBy?: keyof T;
  isActionsLoading?: (item: MappedData<T>) => boolean;
  loading?: boolean;
  noDataMessage?: string;
  onDelete?: (item: MappedData<T>, index: number) => void;
  onDeselect?: (ids: string[]) => void;
  onEdit?: (item: MappedData<T>, index: number) => void;
  onExpandRow?: (item: MappedData<T>) => void;
  disableExpandAll?: boolean;
  onRowClick?: (item: MappedData<T>, e: React.MouseEvent<HTMLTableRowElement>) => void;
  onSelect?: (ids: string[]) => void;
  renderDetail?: (item: MappedData<T>) => JSX.Element;
  selected?: string[];
  shouldDisableDelete?: ((item: MappedData<T>) => boolean) | boolean;
  shouldDisableEdit?: ((item: MappedData<T>) => boolean) | boolean;
  tableBorder?: boolean;
  tableRef?: React.ClassAttributes<DataTableC<T>>['ref'];
  classes?: { tableContainer?: string };
  dateTimeFormat?: string | moment.MomentBuiltinFormat;
}

interface State<T extends ColumnsDefinition> {
  sortBy: keyof T;
  sortDir: 'ascending' | 'descending';
  expandedRowIds: string[];
}

const S = StyleUtil.styles.tables;

class DataTableC<T extends ColumnsDefinition> extends React.Component<Props<T>, Partial<State<T>>> {
  public static types: { [key in ColumnType]: ColumnType } = {
    custom: 'custom',
    date: 'date',
    datetime: 'datetime',
    number: 'number',
    string: 'string',
    checkmark: 'checkmark',
  };

  public static of<T extends ColumnsDefinition>(columns: T) {
    const DataTableWrapper = ({ tableRef, ...rest }: Props<T>) => (
      <DataTable columns={columns as T} ref={tableRef} {...rest} />
    );
    return DataTableWrapper;
  }

  public constructor(props: Props<T>) {
    super(props);
    this.state = {
      expandedRowIds: [],
      sortBy: props.defaultSortBy,
      sortDir: props.defaultSortDir || 'ascending',
    };
  }

  public collapseAll = () => {
    this.setState({ expandedRowIds: [] });
  };

  public expandAll = () => {
    const { onExpandRow } = this.props;
    const data = this.getUniqueData();
    if (onExpandRow) {
      data.forEach(d => onExpandRow(d));
    }
    this.setState({ expandedRowIds: data.map(({ id }) => id) });
  };

  get colCount() {
    const { actions, columns, onSelect, onEdit, onDelete, renderDetail } = this.props;
    return (
      Object.keys(columns).length +
      (!!onSelect ? 1 : 0) +
      (!!onEdit || !!onDelete || !!actions ? 1 : 0) +
      (!!renderDetail ? 1 : 0)
    );
  }

  get shouldSortByDate() {
    const { columns } = this.props;
    const { sortBy } = this.state;
    const type = columns[sortBy].type;
    return type === DataTable.types.date || type === DataTable.types.datetime;
  }

  get sort() {
    if (!this.state.sortBy) {
      return () => 0;
    }
    return this.shouldSortByDate ? this.sortByDate : this.sortByString;
  }

  setSort(sortBy: string) {
    if (this.state.sortBy === sortBy) {
      this.setState({
        sortDir: this.state.sortDir === 'ascending' ? 'descending' : 'ascending',
      });
    } else {
      this.setState({ sortBy, sortDir: 'ascending' });
    }
  }

  sortByDate = (a, b) => {
    const { dateTimeFormat } = this.props;
    const { sortBy, sortDir } = this.state;

    const dateA = moment(String(a[sortBy]), dateTimeFormat || moment.ISO_8601);
    const dateB = moment(String(b[sortBy]), dateTimeFormat || moment.ISO_8601);
    if (!dateB.isValid() || dateA.isAfter(dateB)) {
      return sortDir === 'ascending' ? 1 : -1;
    }
    if (!dateA.isValid() || dateA.isBefore(dateB)) {
      return sortDir === 'ascending' ? -1 : 1;
    }
    // Try to make this more reliable so things don't move on re-render
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
    return 0;
  };

  sortByString = (a, b) => {
    const { sortBy, sortDir } = this.state;

    if (a[sortBy] > b[sortBy]) {
      return sortDir === 'ascending' ? 1 : -1;
    }
    if (a[sortBy] < b[sortBy]) {
      return sortDir === 'ascending' ? -1 : 1;
    }
    // Try to make this more reliable so things don't move on re-render
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
    return 0;
  };

  getGroupedData() {
    const { groupBy } = this.props;
    const { sortBy, sortDir } = this.state;
    const data = this.getUniqueData();
    const groupMap = {};

    data.forEach(d => {
      const title = d[groupBy] ? String(d[groupBy]) : d[groupBy];
      const key = d.groupKey || (title as string);
      if (!groupMap[key]) {
        groupMap[key] = { title, data: [] };
      }
      groupMap[key].data.push(d);
      if (sortBy) {
        const groupSort = groupMap[key].highestSort;
        const itemSort = String(d[sortBy]);
        if (!groupSort) {
          groupMap[key].highestSort = itemSort;
        }
        if (this.shouldSortByDate) {
          if (
            sortDir === 'ascending' &&
            moment(String(itemSort), moment.ISO_8601).isBefore(moment(groupSort, moment.ISO_8601))
          ) {
            groupMap[key].highestSort = itemSort;
          } else if (
            sortDir === 'descending' &&
            moment(String(itemSort), moment.ISO_8601).isAfter(moment(groupSort, moment.ISO_8601))
          ) {
            groupMap[key].highestSort = itemSort;
          }
        } else {
          if (sortDir === 'ascending' && itemSort < groupSort) {
            groupMap[key].highestSort = itemSort;
          } else if (sortDir === 'descending' && itemSort > groupSort) {
            groupMap[key].highestSort = itemSort;
          }
        }
      }
    });

    const groups = Object.keys(groupMap)
      .map(key => ({ key, [sortBy]: groupMap[key].highestSort }))
      .sort(this.sort)
      .map(({ key }) => ({ key, ...groupMap[key] }))
      .map(g => ({
        ...g,
        data: !sortBy ? g.data : g.data.sort(this.sort),
      }));

    return groups;
  }

  getUniqueData(): MappedData<T>[] {
    const { data } = this.props;
    if (!data) {
      return [];
    }
    const dataMap = {};
    const idOrder = [];
    data.forEach(d => {
      if (idOrder.indexOf(d.id) === -1) {
        dataMap[d.id] = d;
        idOrder.push(d.id);
      }
    });
    return idOrder.map(id => dataMap[id]);
  }

  getSortedData() {
    const { sortBy } = this.state;
    const data = this.getUniqueData();

    if (!sortBy) {
      return data;
    }

    return data.sort(this.sort);
  }

  toggleExpandRow(d: MappedData<T>) {
    const { onExpandRow } = this.props;
    const expandedRowIds = this.state.expandedRowIds.slice();
    const indexOfId = expandedRowIds.indexOf(d.id);
    if (indexOfId === -1) {
      if (onExpandRow) {
        onExpandRow(d);
      }
      expandedRowIds.push(d.id);
    } else {
      expandedRowIds.splice(indexOfId, 1);
    }
    this.setState({ expandedRowIds });
  }

  render() {
    const { classes, tableBorder } = this.props;

    return (
      <div className={classes ? classes.tableContainer : undefined}>
        <table style={[S.table, tableBorder && S.table_bordered] as any}>
          <tbody>
            {this.renderHead()}
            {this.renderBody()}
          </tbody>
        </table>
      </div>
    );
  }

  renderHead() {
    const {
      actions,
      columns,
      disableExpandAll,
      groupBy,
      onDelete,
      onDeselect,
      onEdit,
      onSelect,
      renderDetail,
      selected,
    } = this.props;
    const data = this.getUniqueData();

    const allExpanded = data && data.filter(({ id }) => this.state.expandedRowIds.indexOf(id) === -1).length === 0;

    return (
      <tr>
        {!!renderDetail && (
          <th
            style={
              [
                StyleUtil.styles.tables.th,
                StyleUtil.styles.tables.td_chevron,
                !!disableExpandAll && { cursor: 'default' },
              ] as any
            }
            onClick={
              !!disableExpandAll
                ? null
                : () => {
                    if (allExpanded) {
                      this.collapseAll();
                    } else {
                      this.expandAll();
                    }
                  }
            }
          >
            {!disableExpandAll && <Icon name={allExpanded ? 'chevron-up' : 'chevron-down'} />}
          </th>
        )}
        {!!onSelect && (
          <th style={StyleUtil.styles.tables.td_check}>
            <Checkbox
              checked={!!data && data.filter(c => selected.indexOf(c.id) === -1).length === 0}
              onChange={e => {
                if (!data) {
                  return;
                }
                const ids = data.map(c => c.id);
                e.currentTarget.checked ? onSelect(ids) : onDeselect(ids);
              }}
            />
          </th>
        )}
        {!!groupBy &&
          [
            { key: groupBy, ...(columns[groupBy] as object) } as (T & {
              key: string;
            }),
          ].map(this.renderTh)}
        {Object.keys(columns)
          .filter(key => key !== groupBy && !columns[key].hidden)
          .map(key => ({ key, ...columns[key] }))
          .map(this.renderTh)}
        {(onEdit || onDelete || actions) && <th style={[S.th, S.td_actions] as any}>Actions</th>}
      </tr>
    );
  }

  renderTh = ({ key, label, sortable, center, width, type, nowrap }: T & { key: string }, i: number, a: any[]) => {
    const { actions, onEdit, onDelete, groupBy } = this.props;
    const { sortBy, sortDir } = this.state;

    return (
      <th
        key={key}
        style={
          [
            S.th,
            (center || type === DataTable.types.checkmark) && S.td_center,
            nowrap && S.td_nowrap,
            sortable && S.th_sortable,
            i + 1 === a.length && !onEdit && !onDelete && !actions && groupBy !== key && S.td_last,
            width != null && { width },
          ] as any
        }
        onClick={sortable ? () => this.setSort(key) : null}
      >
        <span style={sortable || sortBy === key ? S.thText_sortable : {}}>
          {label}
          {(sortable || sortBy === key) && (
            <span style={[S.thSortIcon, sortBy === key && S.thSortIcon_active] as any}>
              <Icon name={sortBy === key && sortDir === 'descending' ? 'caret-up' : 'caret-down'} />
            </span>
          )}
        </span>
      </th>
    );
  };

  renderBody() {
    const { groupBy, loading, noDataMessage } = this.props;
    const data = this.getUniqueData();
    const colCount = this.colCount;

    if (!!loading) {
      return (
        <tr>
          <td style={StyleUtil.styles.tables.td_msg} colSpan={colCount}>
            <LoadingSplash />
          </td>
        </tr>
      );
    }

    if (!data || data.length === 0) {
      return (
        <tr>
          <td style={[StyleUtil.styles.tables.td, StyleUtil.styles.tables.td_msg] as any} colSpan={colCount}>
            {noDataMessage != null ? noDataMessage : 'No Data'}
          </td>
        </tr>
      );
    }

    return !groupBy ? this.getSortedData().map(this.renderDataRow) : this.getGroupedData().map(this.renderGroup);
  }

  renderGroup = ({ key, title, data }) => {
    const { actions, columns, onSelect, onDeselect, selected, onEdit, onDelete } = this.props;
    const a = [];

    a.push(
      <tr style={StyleUtil.styles.tables.tr_group} key={key}>
        {!!onSelect && (
          <td style={StyleUtil.styles.tables.td_check}>
            <Checkbox
              checked={!!data && data.filter(c => selected.indexOf(c.id) === -1).length === 0}
              onChange={e => {
                if (!data) {
                  return;
                }
                const ids = data.map(c => c.id);
                e.currentTarget.checked ? onSelect(ids) : onDeselect(ids);
              }}
            />
          </td>
        )}
        <td
          style={StyleUtil.styles.tables.td}
          colSpan={Object.keys(columns).length + (!!onEdit || !!onDelete || !!actions ? 1 : 0)}
        >
          {title}&nbsp;
        </td>
      </tr>,
    );

    data.map(this.renderDataRow).forEach(row => a.push(row));

    return a;
  };

  renderDataRow = (d: MappedData<T>, i: number) => {
    const {
      actions,
      columns,
      customDeleteIcon,
      customEditIcon,
      getRowStyle,
      groupBy,
      isActionsLoading,
      onDelete,
      onEdit,
      onRowClick,
      onSelect,
      renderDetail,
      shouldDisableDelete,
      shouldDisableEdit,
    } = this.props;
    const { expandedRowIds } = this.state;

    const isAlt = (!groupBy && i % 2 === 0) || (!!groupBy && i % 2 !== 0);
    const isExpanded = !!renderDetail && expandedRowIds.indexOf(d.id) > -1;
    const disableDelete = typeof shouldDisableDelete === 'function' ? shouldDisableDelete(d) : shouldDisableDelete;
    const disableEdit = typeof shouldDisableEdit === 'function' ? shouldDisableEdit(d) : shouldDisableEdit;

    return [
      <tr
        key={d.id}
        onClick={!onRowClick ? null : e => onRowClick(d, e)}
        style={[S.tr, isAlt && S.tr_alt, !!onRowClick && S.tr_clickable, !!getRowStyle && getRowStyle(d)] as any}
      >
        {!!renderDetail && (
          <td
            onClick={() => {
              this.toggleExpandRow(d);
            }}
            style={[StyleUtil.styles.tables.td, StyleUtil.styles.tables.td_chevron] as any}
          >
            <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
          </td>
        )}
        {!!onSelect && <td style={[!!groupBy && StyleUtil.styles.tables.td_groupFirst] as any} />}
        {!!groupBy && <td style={[StyleUtil.styles.tables.td, StyleUtil.styles.tables.td_groupFirst] as any} />}
        {Object.keys(columns)
          .filter(key => groupBy !== key && !columns[key].hidden)
          .map((key, index, a) => (
            <td
              key={`${d.id}_${key}`}
              style={
                [
                  S.td,
                  columns[key].center && S.td_center,
                  columns[key].type === DataTable.types.checkmark && S.td_checkmark,
                  columns[key].nowrap && S.td_nowrap,
                  index + 1 === a.length && !onEdit && !onDelete && !actions && S.td_last,
                ] as any
              }
            >
              {this.renderValue(d, key)}
            </td>
          ))}
        {(!!onEdit || !!onDelete || !!actions) &&
          (isActionsLoading && isActionsLoading(d) ? (
            <td style={[S.td, S.td_actions] as any}>
              <span className="animate-spin" style={StyleUtil.styles.tables.action_edit} key={`${d.id}_loading`}>
                <Icon name="loading" />
              </span>
            </td>
          ) : (
            <td style={[S.td, S.td_actions] as any}>
              <ButtonContainer>
                {actions &&
                  actions.map((a, index) => {
                    const disabled = typeof a.disabled === 'function' ? a.disabled(d) : a.disabled;
                    return (
                      <IconButton
                        css={null}
                        key={`${d.id}_action_${index}`}
                        color="accent"
                        ariaLabel={a.title}
                        title={a.title}
                        disabled={disabled}
                        onClick={
                          disabled
                            ? null
                            : event => {
                                event.stopPropagation();
                                a.onAction(d);
                              }
                        }
                        icon={typeof a.icon === 'object' ? a.icon : <Icon name={a.icon} />}
                      />
                    );
                  })}
                {onEdit && (
                  <IconButton
                    css={null}
                    key={`${d.id}_edit`}
                    ariaLabel="Edit"
                    onClick={disableEdit ? null : () => onEdit(d, i)}
                    disabled={disableEdit}
                    color="accent"
                    icon={
                      customEditIcon ? (
                        typeof customEditIcon === 'string' ? (
                          <Icon name={customEditIcon} />
                        ) : (
                          customEditIcon
                        )
                      ) : (
                        <FiEdit2 />
                      )
                    }
                  />
                )}
                {onDelete && (
                  <IconButton
                    css={null}
                    key={`${d.id}_delete`}
                    ariaLabel="Delete"
                    onClick={disableDelete ? null : () => onDelete(d, i)}
                    disabled={disableDelete}
                    color="error"
                    icon={
                      customDeleteIcon ? (
                        typeof customDeleteIcon === 'string' ? (
                          <Icon name={customDeleteIcon} />
                        ) : (
                          customDeleteIcon
                        )
                      ) : (
                        <FiTrash2 />
                      )
                    }
                  />
                )}
              </ButtonContainer>
            </td>
          ))}
      </tr>,
      isExpanded && (
        <tr key={`${d.id}_detail`} style={[isAlt && S.tr_alt] as any}>
          <td style={StyleUtil.styles.tables.td_detail} colSpan={this.colCount}>
            {renderDetail(d)}
          </td>
        </tr>
      ),
    ];
  };

  renderValue(data: MappedData<T>, key: keyof T): React.ReactNode {
    const { columns, dateTimeFormat } = this.props;
    const column = columns[key];

    if (column.type === DataTable.types.custom) {
      return data[key] as React.ReactNode;
    }
    if (column.type === DataTable.types.checkmark) {
      const checked = (data[key] as boolean) === true;
      return checked ? <Icon name="checkmark" /> : null;
    }

    const val = data[key];
    if (column.type === DataTable.types.date || column.type === DataTable.types.datetime) {
      const date = moment(val as string, dateTimeFormat || moment.ISO_8601);
      return date.format(column.type === DataTable.types.date ? 'MM/DD/YYYY' : 'MM/DD/YYYY h:mma');
    }

    return (val ? String(val) : val) as React.ReactNode;
  }
}

export const DataTable = Radium(DataTableC);
export type DataTable<T extends ColumnsDefinition> = DataTableC<T>;
