import React from 'react';
import { connect } from 'react-redux';

import { DataTable, TemplatePreview } from '../../../components';
import { load as loadEmails, loadDetail } from '../../../redux/modules/emails';
import { Card, Modal, LoadingSplash } from '@hyperfish/fishfood';

const EmailTable = DataTable.of({
  createdAt: { label: 'TimeStamp', type: DataTable.types.datetime, sortable: true },
  to: { label: 'To', type: DataTable.types.string, sortable: true },
  subject: { label: 'Subject', type: DataTable.types.string, sortable: true },
});

const connector = connect(
  state => ({
    emails: state.emails.data,
    emailsLoading: state.emails.loading,
    detailLoading: state.emails.loadingDetailById,
    details: state.emails.detailById,
  }),
  {
    loadEmails,
    loadDetail,
  },
);

type Props = typeof connector['props'];

interface State {
  detailId: string;
}

@connector
export default class Emails extends React.Component<Props, Partial<State>> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { loadEmails } = this.props;
    loadEmails();
  }

  handleClick = ({ id: detailId }) => {
    this.setState({ detailId });
    this.props.loadDetail(detailId);
  };

  render() {
    const { emails, emailsLoading } = this.props;

    return (
      <>
        <Card noPadding={true}>
          <EmailTable
            defaultSortBy="createdAt"
            loading={emailsLoading}
            onRowClick={this.handleClick}
            data={
              emails &&
              emails.map(({ id, createdAt, headers }) => ({
                id,
                createdAt,
                to: headers['to'],
                subject: headers['subject'],
              }))
            }
          />
        </Card>
        {this.state.detailId != null && this.renderDetail()}
      </>
    );
  }

  renderDetail() {
    const { detailLoading, details } = this.props;
    const { detailId } = this.state;
    const loading = detailLoading[detailId];
    const detail = details[detailId];

    return (
      <Modal onClose={() => this.setState({ detailId: null })}>
        {loading ? <LoadingSplash /> : <TemplatePreview templatePreview={detail} />}
      </Modal>
    );
  }
}
