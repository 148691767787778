import { FiAlertTriangle, FiInfo, getFrom, LoadingSplash, UserDetail, ApiClientContext } from '@hyperfish/fishfood';
import cx from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { OrgSettingsKeys } from '../../models/api';
import { isFree, isAdmin, isEditor } from '../../redux/modules/auth';
import { getMergedUserSelfBranding } from '../../redux/modules/branding';
import { load as loadChanges } from '../../redux/modules/changes';
import { load as loadUsers, loadDetail, saveDetail, savePhoto } from '../../redux/modules/externalUsers';
import { loadCurrent } from '../../redux/modules/orgs';
import { clearValidation, validateFieldExternal, validateImage } from '../../redux/modules/validations';
import { loadWebpartSetting } from '../../redux/modules/settings';
import classes from './styles.module.scss';
import { getBasicSchema } from './userUtils';
import { PhotoValidationResponse } from '@hyperfish/fishfood/lib/components/UserDetail/UserDetailPhotoUpload';
import { AxiosResponse } from 'axios';
import { ApiUser } from '@hyperfish/fishfood/lib/utils/UserUtil';

const connector = connect(
  (state, props) => {
    const isSelf = props.location.pathname.indexOf('/self') === 0;
    const userId = isSelf ? 'me' : decodeURIComponent(props.match.params.userId);
    const orgId = getFrom(state)('orgs')('current')('id').value;
    const orgType = getFrom(state)('orgs')('current')('type').value;
    const orgSettings = getFrom(state)('orgs')('current')('settings').value;
    const basicProfile =
      isFree(state) &&
      getFrom(state)('orgs')('current')('settings')(orgId)('auth_providers_microsoftGraph_adminConsent');
    const userSchema = getFrom(state)('externalUsers')('detailById')(userId)('relatedEntities')('fieldSchema').value;
    const userAudience = getFrom(state)('externalUsers')('detailById')(userId)('relatedEntities')('audience').value;

    const fieldSchema = basicProfile ? getBasicSchema(userSchema, userAudience) : userSchema;

    const editable =
      (isSelf &&
        getFrom(state)('orgs')('current')('settings')(orgId)(OrgSettingsKeys.global.mode).value !== 'Analyze') ||
      isAdmin(state) ||
      isEditor(state);

    return {
      audienceId: getFrom(userAudience)('id').value,
      basicProfile,
      branding: getMergedUserSelfBranding(state.branding, userId),
      changes: getFrom(state)('changes')('changes').value,
      changesLoading: getFrom(state)('changes')('loading').defaultTo(false),
      orgId,
      orgType,
      orgSettings,
      currentOrgLoading: getFrom(state)('orgs')('loadingCurrent').defaultTo(false),
      editable,
      fieldSchema,
      isAnalyze: getFrom(state)('orgs')('current')('settings')(orgId)(OrgSettingsKeys.global.mode).value === 'Analyze',
      isDisconnected: orgId && !getFrom(state)('orgs')('currentConnected').value,
      isFree: isFree(state),
      isSelf,
      self: getFrom(state)('self')('user').value,
      userDetail: getFrom(state)('externalUsers')('detailById')(userId).value,
      userDetailError: getFrom(state)('externalUsers')('detailByIdError')(userId).value,
      userDetailLoading: getFrom(state)('externalUsers')('detailByIdLoading')(userId).value,
      userId,
      validating: getFrom(state)('validations')('validating').value,
      validatingByKey: getFrom(state)('validations')('validatingByKey').value,
      validation: getFrom(state)('validations')('result').value,
      validationByKey: getFrom(state)('validations')('resultByKey').value,
      validationError: getFrom(state)('validations')('error').value,
      validationErrorByKey: getFrom(state)('validations')('errorByKey').value,
      settings: getFrom(state)('settings')('data').value,
      settingsLoading: getFrom(state)('settings')('loading').defaultTo(false),
    };
  },
  {
    clearValidation,
    loadCurrent,
    loadChanges,
    loadDetail,
    saveDetail,
    validateImage,
    validateFieldExternal,
    loadUsers,
    savePhoto,
    loadWebpartSetting,
  },
);

type Props = typeof connector['props'];

const UserComponent: FunctionComponent<Props> = ({
  audienceId,
  editable,
  userId,
  userDetail,
  userDetailError,
  userDetailLoading,
  orgId,
  orgType,
  orgSettings,
  currentOrgLoading,
  fieldSchema,
  isAnalyze,
  isFree,
  isSelf,
  changes,
  changesLoading,
  settings,
  settingsLoading,
  loadDetail,
  loadCurrent,
  loadChanges,
  loadWebpartSetting,
  ...props
}) => {
  const [internalSettings, setInternalSettings] = useState<undefined | {}>();
  const onSaveInternal = (profileId: string, data: ApiUser) =>
    (props.saveDetail(userId === 'me' ? userId : profileId, data) as any).then(({ data }) => data);

  const onSavePhotoInternal = (profileId: string, photoFieldId: string, photoDataUri: string) =>
    (props.savePhoto(
      userId === 'me' ? userId : profileId,
      photoFieldId,
      photoDataUri.substring(photoDataUri.indexOf(',') + 1),
    ) as any).then(({ data }) => data);

  const onValidatePhotoInternal = (photoDataUri: string) =>
    ((props.validateImage(photoDataUri.substring(photoDataUri.indexOf(',') + 1)) as any) as Promise<
      AxiosResponse<PhotoValidationResponse>
    >).then(({ data }) => data);

  const onDeletePhotoInternal = (profileId: string, photoFieldId: string) =>
    (props.savePhoto(userId === 'me' ? userId : profileId, photoFieldId, null) as any).then(({ data }) => data);

  useEffect(() => {
    loadWebpartSetting();
  }, []);

  useEffect(() => {
    if (getFrom(settings)(orgId).value) {
      const _webpartSettings = getFrom(settings)(orgId)('webparts_config_profile').defaultTo({});
      setInternalSettings(_webpartSettings);
    }
  }, [settings, orgId]);

  useEffect(() => {
    if (!userDetailLoading) {
      loadDetail(userId);
    }
    if (!changesLoading && isSelf) {
      loadChanges({ statuses: 'pending,failed' });
    }
  }, [userId]);

  if (orgType !== 'Online' && props.isDisconnected) {
    return (
      <div className="container" style={{ textAlign: 'center', paddingTop: 40 }}>
        <h1>Well, that isn&apos;t great.</h1>
        <p>We are not able to communicate with your directory right now.</p>
        <p>Your administrator will need to reconnect your directory before you can update your profile.</p>
        <p>Please check back later!</p>
      </div>
    );
  }

  if (userDetailError) {
    const err = userDetailError;

    if (err.status === 404) {
      return (
        <div className="container" style={{ textAlign: 'center', paddingTop: 40 }}>
          <h1>Sorry...</h1>
          <p>We could not find this user in the directory.</p>
        </div>
      );
    }

    return (
      <div className="container" style={{ textAlign: 'center', paddingTop: 40 }}>
        <h1>Well, this is embarassing.</h1>
        <p>We are having trouble loading your profile information right now.</p>
        <p>Our engineers have already been notified of the problem.</p>
        <p>Please try again later.</p>
      </div>
    );
  }

  if (userDetailLoading || !userDetail || currentOrgLoading || !orgId || !fieldSchema || settingsLoading) {
    return <LoadingSplash />;
  }

  const supportEmail = getFrom(orgSettings)(orgId)('general_supportEmail').value;

  return (
    <div>
      <div>
        {isAnalyze && (
          <div className={classes.banner}>
            <FiAlertTriangle />
            <span>
              Your organization is currently in analyze mode. You will not be able to make changes to your profile.
            </span>
          </div>
        )}
        {isSelf && !isAnalyze && changes && changes.length > 0 && (
          <div className={classes.approvalBanner}>
            <span className={classes.approvalBannerContainer}>
              <span className={classes.approvalIcon}>
                <FiAlertTriangle />
              </span>
              <span className={classes.approvalBannerTextContainer}>
                There are {changes.length.toLocaleString()} directory updates waiting for your review.&nbsp;
                <Link to={{ pathname: '/self/approve', search: props.location.search }}>Go review them</Link>.
              </span>
            </span>
          </div>
        )}
      </div>
      <div className={cx('view-self container', classes.container)}>
        <ApiClientContext.Consumer>
          {client => (
            <UserDetail
              user={userDetail}
              masterAudienceId={orgId}
              canEdit={editable}
              onSave={!editable ? undefined : onSaveInternal}
              fetchUsers={(q, limit) => client.api.externalUsers.search(q, limit).then(res => res.data)}
              onSavePhoto={!editable ? undefined : onSavePhotoInternal}
              onValidatePhoto={!editable || isFree ? undefined : onValidatePhotoInternal}
              onDeletePhoto={!editable ? undefined : onDeletePhotoInternal}
              showCommIcons={getFrom(internalSettings as any)('showCommIcons').defaultTo(false)}
            />
          )}
        </ApiClientContext.Consumer>
        {props.basicProfile && (
          <div style={{ textAlign: 'center', margin: '40px 20px 0' }}>
            <h2>
              <FiInfo style={{ color: props.branding.secondaryColor }} />
              <span>But wait, there&apos;s more!</span>
            </h2>
            <p>LiveTiles Directory has not yet been given access to your full profile by your administrator.</p>
            <p>Check back later to see if your full profile information is correct.</p>
          </div>
        )}
        <div style={{ textAlign: 'center', margin: '40px 20px 0' }}>
          <p>
            {!!supportEmail
              ? 'Questions? Please contact your administrator:'
              : 'LiveTiles Directory enables organizations to quickly and easily collect missing and incorrect profile information.'}
            &nbsp;
            <a
              style={{ display: 'inline-block' }}
              href={!!supportEmail ? `mailto:${supportEmail}` : 'https://www.hyperfish.com'}
              target={!!supportEmail ? null : '_blank'}
            >
              {!!supportEmail ? supportEmail : 'Learn more.'}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export const User = connector(UserComponent);

export default User;
