import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';

import { DataTable, Icon } from '../../../components';
import { create as createAudit, load as loadAudits } from '../../../redux/modules/audits';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import StyleUtil from '../../../utils/StyleUtil';
import { Card } from '@hyperfish/fishfood';
import { RouteComponentProps } from 'react-router';

const connector = connect(
  state => ({
    audits: state.audits.data,
    creating: state.audits.creating,
    error: state.audits.error,
    loading: state.audits.loading,
    currentOrg: state.orgs.current,
  }),
  { createAudit, loadAudits },
);

@connector
@Radium
export default class Audits extends React.Component<typeof connector['props'] & RouteComponentProps, {}> {
  componentDidMount() {
    const { loadAudits, loading } = this.props;
    if (!loading) {
      loadAudits();
    }
  }

  render() {
    const { createAudit, currentOrg } = this.props;

    return (
      <div className="view-audits">
        <div style={StyleUtil.styles.tables.header}>
          <a
            style={StyleUtil.styles.tables.headerAction}
            onClick={() => createAudit(currentOrg.type === 'Online' ? 'online' : 'external')}
          >
            <Icon name="plus" />
            <span style={StyleUtil.styles.tables.headerActionText}>Create Audit</span>
          </a>
        </div>
        <Card noPadding>{this.renderAudits()}</Card>
      </div>
    );
  }

  renderAudits() {
    const { audits, loading, history } = this.props;

    const AuditTable = DataTable.of({
      id: { label: 'ID' },
      startTime: { label: 'Start Time', type: DataTable.types.datetime, sortable: true },
      type: { label: 'Type' },
      auditRecordCount: { label: 'Audit Record Count', type: DataTable.types.number, center: true },
      status: { label: 'Status' },
    });

    return (
      <AuditTable
        defaultSortBy="startTime"
        defaultSortDir="descending"
        loading={loading}
        onRowClick={({ id }) => history.push(`/home?auditId=${id}`)}
        data={
          audits &&
          audits.map(a => ({
            ...a,
            type: a.type,
            startTime: a.startedAt,
            auditRecordCount: getFrom(a.combinedStats)('auditRecordCount')
              .defaultTo(0)
              .toLocaleString(),
          }))
        }
      />
    );
  }
}
