import React from 'react';
import { connect } from 'react-redux';

import { Button } from '../../components/';
import StyleUtil from '../../utils/StyleUtil';

const connector = connect(
  () => ({}),
  {},
);

const styles = StyleUtil({
  h1: {
    fontSize: 30,
    color: StyleUtil.colors.blue,
    marginTop: 60,
    marginBottom: 20,
  },
  p: {
    fontSize: 18,
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
  },
  button: {
    marginBottom: 20,
  },
  help: {},
});

@connector
export default class Error extends React.Component<typeof connector['props'], {}> {
  render() {
    return (
      <div className="view-error container" style={{ textAlign: 'center' }}>
        <h1 style={styles.h1}>Sorry!</h1>
        <p style={styles.p}>We seem to have had trouble signing you in to LiveTiles Directory.</p>
        <p style={styles.p}>
          Please try signing in again, making sure to use a valid Office 365 or Azure AD login, and not a Microsoft
          Account.
        </p>
        <Button size="medium" type="primary" to="/login" style={styles.button}>
          TRY AGAIN
        </Button>
        <p style={styles.help}>
          If you continue to have trouble, please&nbsp;
          <a href={`mailto:support@hyperfish.com?subject=${encodeURIComponent("Help! I can't log in!")}`}>
            let us know!
          </a>
        </p>
      </div>
    );
  }
}
