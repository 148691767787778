import { GlobalState } from '../../models/client';

type Schema = GlobalState['externalUsers']['detailById']['foo']['relatedEntities']['fieldSchema'];
type Audience = GlobalState['externalUsers']['detailById']['foo']['relatedEntities']['audience'];

export const getBasicSchema = (schema: Schema, audience: Audience): Schema => {
  if (!schema || !audience) {
    return;
  }
  const getFieldIdByProperty = (property: string) => {
    for (const field in schema.fields) {
      if (schema.fields.hasOwnProperty(field) && schema.fields[field].property === property) {
        return schema.fields[field].id;
      }
    }
  };
  const basicFields = ['displayName', 'givenName', 'surname', 'mail'];
  const fields = basicFields.map(f => getFieldIdByProperty(f)).filter(id => id != null);

  return {
    ...schema,
    groups: {
      [audience.id]: [
        {
          audienceId: audience.id,
          component: 'default',
          createdAt: null,
          description: null,
          fields,
          id: audience.id,
          meta: {},
          title: 'Basic Information',
        },
      ],
    },
  };
};
