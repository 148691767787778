import Color from 'color';
import Radium from 'radium';
import React from 'react';

import StyleUtil from '../utils/StyleUtil';

interface Tab {
  label: string;
  key: string;
}

interface Props {
  tabs: Tab[];
  onChange: (key: string) => void;
  activeKey: string;
  className?: string;
}

const S = StyleUtil({
  container: {
    border: '1px solid #cfcfcf',
    display: 'inline-block',
  },
  tab: {
    backgroundColor: '#fff',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: '1px solid #bababa',
    borderTop: 'none',
    color: StyleUtil.colors.text.dark,
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 700,
    height: 30,
    minWidth: 120,
    transition: 'background-color 250ms',
    outlineOffset: -4,

    ':hover': {
      backgroundColor: Color(StyleUtil.colors.lightBlue)
        .alpha(0.3)
        .string(),
    },
  },
  tabActive: {
    backgroundColor: StyleUtil.colors.lightBlue,
    ':hover': { backgroundColor: StyleUtil.colors.lightBlue },
  },
  tabLast: { borderRight: 'none' },
});

export const Tabs = Radium(({ tabs, onChange, activeKey, className }: Props) => (
  <div style={S.container} className={className}>
    {tabs &&
      tabs.map(({ label, key }, i, a) => (
        <button
          key={key}
          style={[S.tab, i + 1 === a.length && S.tabLast, activeKey === key && S.tabActive] as any}
          onClick={() => onChange(key)}
        >
          {label}
        </button>
      ))}
  </div>
));
