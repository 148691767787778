import React from 'react';
import { connect } from 'react-redux';

import {
  addRelationshipType,
  editRelationshipType,
  deleteRelationshipType,
  loadRelationshipTypes,
  loadRelationshipEdges,
  addRelationshipEdges,
  deleteRelationshipEdges,
} from '../../../redux/modules/relationships';
import RelationshipType from './RelationshipType';
import RelationshipEdges from './RelationshipEdges';
import { LoadingSplash } from '@hyperfish/fishfood';
import { clear as clearUsers, load as loadUsers } from '../../../redux/modules/externalUsers';
import { isAdmin, isEditor } from '../../../redux/modules/auth';
import { hasOrgFeature, isLoadedCurrent } from '../../../redux/modules/orgs';
import { ORG_FEATURE_DIRECTORY_WEB_PARTS } from '../../../config';
import { loadWebpartSetting as loadSettings } from '../../../redux/modules/settings';
import { saveDetail, savePhoto } from '../../../redux/modules/externalUsers';
import { validateImage } from '../../../redux/modules/validations';
import { load as loadFieldSchema } from '../../../redux/modules/fieldSchema';

const connector = connect(
  state => ({
    currentUserId: state.auth.userId,
    isAdmin: isAdmin(state),
    isEditor: isEditor(state),
    orgId: state.orgs.current && state.orgs.current.id,
    settings: state.settings.data,
    settingsLoading: state.settings.loading,
    loaded: isLoadedCurrent(state),
    webpartsLicensed: hasOrgFeature(state, ORG_FEATURE_DIRECTORY_WEB_PARTS),
    relationshipTypes: state.relationships.types,
    relationshipEdges: state.relationships.edges,
    loadingTypes: state.relationships.loadingTypes,
    loadingEdges: state.relationships.loadingEdges,
    reloadRelationshipTypes: state.relationships.reloadRelationshipTypes,
    reloadRelationshipEdges: state.relationships.reloadRelationshipEdges,
    usersById: state.externalUsers.dataById,
    usersLoading: state.externalUsers.loading,
    users: state.externalUsers.data,
    fieldSchema: state.fieldSchema.data,
    loadingFieldSchema: state.fieldSchema.loading,
    addingEdges: state.relationships.addingEdges,
  }),
  {
    loadSettings,
    saveDetail,
    validateImage,
    savePhoto,
    loadRelationshipEdges,
    loadRelationshipTypes,
    deleteRelationshipType,
    addRelationshipType,
    editRelationshipType,
    clearUsers,
    loadUsers,
    loadFieldSchema,
    addRelationshipEdges,
    deleteRelationshipEdges,
  },
);

export type Props = typeof connector['props'];

@connector
export default class Relationship extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { loadRelationshipTypes } = this.props;
    loadRelationshipTypes();
  }

  render() {
    const { loadingTypes } = this.props;

    if (loadingTypes) {
      return <LoadingSplash />;
    }

    return (
      <div className="view-settings-relationships">
        <RelationshipType {...this.props} />
        <RelationshipEdges {...this.props} />
      </div>
    );
  }
}
