import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from '@hyperfish/fishfood/lib/components/Modal';
import { Button } from '@hyperfish/fishfood/lib/components/Button';
import { FiRefreshCcw } from '@hyperfish/fishfood/lib/components/Icon';

interface DirectoryFormClearModalProps {
  onApply: () => void;
  onClose: ModalProps['onClose'];
}

export const DirectoryFormClearModal: FunctionComponent<DirectoryFormClearModalProps> = ({ onApply, onClose }) => {
  const { t } = useTranslation('directorySearch');

  return (
    <Modal onClose={onClose}>
      <Modal.Header>{t('clearModalHeader')}</Modal.Header>
      <p>{t('clearModalConfirmationMessage')}</p>
      <p>{t('clearModalDescription')}</p>
      <Modal.ButtonContainer>
        <Button type="reset">{t('cancel')}</Button>
        <Button variant="solid" color="primary" icon={<FiRefreshCcw />} onClick={() => onApply()}>
          {t('clearModalConfirm')}
        </Button>
      </Modal.ButtonContainer>
    </Modal>
  );
};
