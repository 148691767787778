import { Card } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';

import { Button } from '../../components/';
import { OrgSettingsKeys } from '../../models/api';
import { getCode } from '../../redux/modules/auth';
import { loadConnections } from '../../redux/modules/orgs';
import StyleUtil from '../../utils/StyleUtil';
import { Props as ParentProps } from './';

const connector = connect(
  state => ({
    code: state.auth.code,
    connections: state.orgs.connections,
    gettingCode: state.auth.gettingCode,
    gettingCodeError: state.auth.gettingCodeError,
  }),
  { getCode, loadConnections },
);

type Props = typeof connector['props'];

const styles = StyleUtil({
  container: {
    position: 'relative',
  },
  table: {
    tableLayout: 'fixed',
  },
  tr: {},
  td: {
    paddingBottom: 5,
    paddingTop: 5,
  },
  label: {
    paddingRight: 20,
  },
});

@connector
@Radium
export class Download extends React.Component<Partial<Props> & ParentProps, {}> {
  private getConnectionInterval: number;
  private updateSent = false;

  UNSAFE_componentWillMount() {
    const { getCode, loadConnections } = this.props;

    getCode();
    loadConnections();

    this.getConnectionInterval = window.setInterval(() => {
      loadConnections();
    }, 10000);
  }

  componentWillUnmount() {
    if (this.getConnectionInterval) {
      window.clearInterval(this.getConnectionInterval);
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { currentOrg, connections, updateCurrent } = this.props;
    if (connections && connections.length > 0 && !this.updateSent) {
      this.updateSent = true;
      updateCurrent({
        settings: {
          [currentOrg.id]: {
            [OrgSettingsKeys.onboarding.step]: 'analyze',
          },
        },
      });
    }
  }

  render() {
    const { currentOrg, code, gettingCode, gettingCodeError, getCode, updateCurrent } = this.props;

    return (
      <Card style={styles['container']}>
        <a
          href="javascript:void(0)"
          onClick={() =>
            updateCurrent({
              settings: {
                [currentOrg.id]: {
                  [OrgSettingsKeys.onboarding.step]: 'type',
                },
              },
            })
          }
        >
          Back
        </a>
        <h2>On Premises Installation Instructions</h2>
        <p>
          In order to analyze your Active Directory, you will need to install LiveTiles Directory on a machine that is
          joined to the domain and meets the following system requirements:
        </p>
        <table style={styles['table']}>
          <tbody>
            <tr style={styles['tr']}>
              <td style={[styles['td'], styles['label']] as any}>Operating System</td>
              <td style={styles['td']}>Windows Server 2019 or above</td>
            </tr>
            <tr style={styles['tr']}>
              <td style={[styles['td'], styles['label']] as any}>.NET Framework</td>
              <td style={styles['td']}>4.8</td>
            </tr>
            <tr style={styles['tr']}>
              <td style={[styles['td'], styles['label']] as any}>Processor</td>
              <td style={styles['td']}>3GHz</td>
            </tr>
            <tr style={styles['tr']}>
              <td style={[styles['td'], styles['label']] as any}>Memory</td>
              <td style={styles['td']}>8GB</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>Enter the following code when prompted during set up:</p>
        <p>
          <em>
            {gettingCode && <span>Getting Code...</span>}

            {gettingCodeError && (
              <div>
                <span>Failed to get code!</span>
                <a href="javascript:void(0)" onClick={() => getCode()}>
                  Retry
                </a>
              </div>
            )}

            {code}
          </em>
        </p>
        <br />
        <Button
          type="primary"
          size="medium"
          onClick={() => (location.href = 'https://cdn.hyperfish.com/files/HyperfishAgent.msi')}
        >
          DOWNLOAD
        </Button>
        <br />
        <br />
        <p>
          LiveTiles Directory only captures statistics about the date in your Active Directory. User profile details
          will not be stored.
        </p>
        <p>Once you have activated the client with this code, you will be redirected from this page.</p>
      </Card>
    );
  }
}
