import React from 'react';
import cx from 'classnames';
import classes from '../styles.module.scss';
import {
  AnonymousAvatar,
  Checkbox,
  FiArrowLeft,
  FiArrowRight,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
  IconButton,
  getFrom,
} from '@hyperfish/fishfood';
import styled from 'styled-components';

import { LoadingSplash } from '@hyperfish/fishfood';
import StyleUtil from '../../../../utils/StyleUtil';
import {
  RelationshipEdgeRecodResponse,
  RelationshipEdgeResponse,
  RelationshipTypeResponse,
} from '@hyperfish/antrea-api-contracts/src/relationship';

const styles = StyleUtil({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '7px',
  },
  formLabel: {
    flexBasis: '200px',
  },
  formInput: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  formFilterSelect: {
    paddingBottom: 25,
  },
  profilePicture: {
    marginBottom: 5,
  },
});

const PhotoContainer = styled.div<{}>`
  font-size: 16px;
  margin: 5px auto 0;
  border: none;
  border-radius: 50%;
  width: 85px;
  height: 85px;
  display: block;
  position: relative;
  overflow: hidden;
`;

export interface EdgesTableProps {
  loadingEdges: boolean;
  relationshipEdges: RelationshipEdgeResponse['relationshipEdges'];
  relationshipNodes: RelationshipEdgeResponse['relationshipNodes'];
  relationshipType: RelationshipTypeResponse;
  isRelationshipEdgeSelected: (edge: RelationshipEdgeRecodResponse) => boolean;
  toggleRelationshipEdge: (edge: RelationshipEdgeRecodResponse) => void;
  isAllRelationshipEdgeSelected: () => boolean;
  toggleAllRelationshipEdges: () => void;
  selectedCount: () => number;
}

export default class EdgesTable extends React.Component<
  EdgesTableProps & {
    page: number;
    pageSize: number;
    setPage: (page: number) => void;
  }
> {
  constructor(
    props: EdgesTableProps & {
      page: number;
      pageSize: number;
      setPage: (page: number) => void;
    },
  ) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      loadingEdges,
      relationshipEdges,
      relationshipNodes,
      relationshipType,
      page,
      pageSize,
      setPage,
    } = this.props;

    if (loadingEdges) {
      return <LoadingSplash />;
    }

    const getUserProfilePicture = (nodeDatails: any) => {
      let profilePictureUrl = getFrom(nodeDatails)('profilePhotoUrl').defaultTo(null);
      return profilePictureUrl ? (
        <img src={profilePictureUrl} alt={'virtual user profile photo'} />
      ) : (
        <AnonymousAvatar />
      );
    };

    return (
      <div>
        <div className={classes.table}>
          <div className={classes.row}>
            <div className={cx(classes.tableHeader, classes['isCenter'], classes.checkBox)}>
              <div style={styles.formInput}>
                <Checkbox
                  checked={this.props.isAllRelationshipEdgeSelected()}
                  onChange={e => {
                    this.props.toggleAllRelationshipEdges();
                  }}
                />
              </div>
            </div>
            <div className={cx(classes.tableHeader, classes['isCenter'], classes.userContent)}>
              <p>User from</p>
            </div>
            <div className={cx(classes.tableHeader, classes['isCenter'], classes.required)}>
              <p>Relationship</p>
            </div>
            <div className={cx(classes.tableHeader, classes['isCenter'], classes.userContent)}>
              <p>User to</p>
            </div>
          </div>
          {getFrom(relationshipEdges)
            .defaultTo([])
            .slice((page - 1) * pageSize, page * pageSize)
            .map((item, index) => (
              <div
                key={`${item.entityIdFrom}-${item.entityIdTo}`}
                className={cx(classes.row, {
                  [classes['isAlt']]: index % 2 === 0,
                })}
              >
                <div className={cx(classes.tableCell, classes['isCenter'], classes.checkBox)}>
                  <div style={styles.formInput}>
                    <Checkbox
                      checked={this.props.isRelationshipEdgeSelected(item)}
                      onChange={e => {
                        this.props.toggleRelationshipEdge(item);
                      }}
                    />
                  </div>
                </div>
                <div className={cx(classes.tableCell, classes['isCenter'], classes.userContent)}>
                  <div style={styles.form}>
                    <div style={styles.profilePicture}>
                      <PhotoContainer>{getUserProfilePicture(relationshipNodes[item.entityIdFrom])}</PhotoContainer>
                    </div>
                    <div>
                      {relationshipNodes[item.entityIdFrom].displayName ||
                        relationshipNodes[item.entityIdFrom].givenName}
                    </div>
                  </div>
                </div>
                <div className={cx(classes.tableCell, classes['isCenter'], classes.required)}>
                  <div style={styles.form}>
                    <div>
                      <p>
                        <b>{getFrom(relationshipType)('metaData')('relationshipDescription').defaultTo('')}</b>
                      </p>
                    </div>
                    <div>
                      {!getFrom(relationshipType)('isHierarchical').defaultTo(false) && <FiArrowLeft />}
                      <FiArrowRight />
                    </div>
                  </div>
                </div>
                <div className={cx(classes.tableCell, classes['isCenter'], classes.userContent)}>
                  <div style={styles.form}>
                    <div style={styles.profilePicture}>
                      <PhotoContainer>{getUserProfilePicture(relationshipNodes[item.entityIdTo])}</PhotoContainer>
                    </div>
                    <div>
                      {relationshipNodes[item.entityIdTo].displayName || relationshipNodes[item.entityIdTo].givenName}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center' }}>
          <div>{this.props.selectedCount()} selected</div>
          <div>
            <Pager
              setPage={setPage}
              pagination={{
                page: page,
                pageSize: pageSize,
                count: getFrom(relationshipEdges).defaultTo([]).length,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export interface PagerProps {
  pagination: {
    page: number;
    pageSize: number;
    count: number;
  };
  setPage: (page: number) => void;
}

export const Pager = ({ pagination, setPage }: PagerProps) => {
  const isDisabled = getFrom(pagination)('count').defaultTo(0) === 0;
  const page = isDisabled ? 0 : getFrom(pagination)('page').defaultTo(1);
  const pageSize = getFrom(pagination)('pageSize').defaultTo(10);
  const pageCount = isDisabled ? 0 : Math.ceil(getFrom(pagination)('count').defaultTo(0) / pageSize);

  const getItemCount = () => {
    if (isDisabled) return 0;
    if (page === pageCount) return pagination.count - (page - 1) * pageSize;
    return pageSize;
  };
  const itemCount = getItemCount();
  const displayString = `${page} of ${pageCount} (${itemCount} items)`;

  const firstEnabled = !isDisabled && page > 1;
  const prevEnabled = !isDisabled && page > 1;
  const nextEnabled = !isDisabled && page < pageCount;
  const lastEnabled = !isDisabled && page < pageCount;

  return (
    <div className={classes.container}>
      {setPage && (
        <IconButton
          css={null}
          ariaLabel="First page"
          onClick={firstEnabled ? () => setPage(1) : undefined}
          disabled={!firstEnabled}
          icon={<FiChevronsLeft />}
        />
      )}
      {setPage && (
        <IconButton
          css={null}
          ariaLabel="Previous page"
          onClick={prevEnabled ? () => setPage(page - 1) : undefined}
          disabled={!prevEnabled}
          icon={<FiChevronLeft />}
        />
      )}
      <span>{displayString}</span>
      {setPage && (
        <IconButton
          css={null}
          ariaLabel="Next page"
          onClick={nextEnabled ? () => setPage(page + 1) : undefined}
          disabled={!nextEnabled}
          icon={<FiChevronRight />}
        />
      )}
      {setPage && (
        <IconButton
          css={null}
          ariaLabel="Last page"
          onClick={lastEnabled ? () => setPage(pageCount) : undefined}
          disabled={!lastEnabled}
          icon={<FiChevronsRight />}
        />
      )}
    </div>
  );
};
