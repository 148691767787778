import { CloudConnection, DeviceDetails } from '@hyperfish/antrea-api-contracts';
import {
  Button,
  ButtonContainer,
  FiCheckCircle,
  FiMinusCircle,
  FiPlayCircle,
  FiPlusCircle,
  FiShare2,
  getFrom,
  LoadingSplash,
  Modal,
  Option,
  Select,
  Tooltip,
} from '@hyperfish/fishfood';
import moment from 'moment';
import React from 'react';
import { Props } from '../..';
import { DataTable } from '../../../../../components/DataTable';
import { DirectoryTree } from '../../../../../components/DirectoryTree';
import { HyperFieldFactory as hFields, HyperForm } from '../../../../../components/HyperForm';
import { Icon } from '../../../../../components/Icon';
import { PermissionsTable } from '../../../../../components/PermissionsTable';
import { ModalKeys } from '../../../../../components/PremiumModal';
import { SettingCard } from '../../../../../components/SettingCard';
import { Slider } from '../../../../../components/Slider';
import { OrgSettingsKeys as keys } from '../../../../../models/api';
import { AZURE_APP_NAME } from '../../../../../redux/modules/providers';
import OUValidator from '../../../../../utils/OUValidationUtil';
import AtLeastVersion from '../../../../../utils/VersionUtil';
import { DirectoryCard } from '../DirectoryCard';
import StyleUtil from '../../../../../utils/StyleUtil';
import { RFC5646_LANGUAGE_TAGS } from '../../../../../utils/rfc5646-language-tags';
import { applyDSTforSchedules, applyDSTforSchedulesReverse } from '../../../../../utils/momentUtil';
import { cond } from 'lodash';
import { debug } from 'console';

const SERVICE_URI = 'https://cdn.hyperfish.com/files/HyperfishAgent.msi';

interface State {
  excludeOUs?: Props['settings']['audit_exclusions'];
  includeOUs?: Props['settings']['audit_scopes'];
  auditBasicFilterEditProperty?: string;
  auditBasicFilterProperty?: string;
  auditBasicFilterOperator?: string;
  auditBasicFilterValue?: string[];
  showTree?: boolean;
  pilotOpen?: boolean;
  showReadOnlyAlertModal?: boolean;
  showReadOnlyAdviceModal?: boolean;
}

const DirectoryScopesTable = DataTable.of({
  distinguishedName: { label: 'Name', sortable: true },
  reversedDistinguishedName: { hidden: true },
});

const AuditScopesTable = DataTable.of({
  property: { label: 'Property' },
  op: { label: 'Operator' },
  value: { label: 'Value' },
});

const styles = StyleUtil({
  deleteAction: {
    cursor: 'pointer',
    color: StyleUtil.colors.red,
    marginLeft: 30,
  },
  trAlt: StyleUtil.styles.tables.tr_alt,
  auditScopeTableHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
  conditionsContainer: {
    display: 'flex',
    gap: '8px',
  },
  conditionsControlProperty: {
    flexGrow: '1',
  },
  conditionsControlOperator: {
    flexGrow: '0.8',
  },
  conditionsControlValue: {
    flexGrow: '2',
  },
  scopesContainer: {
    marginTop: 15,
  },
  testFilterButton: {
    paddingTop: 20,
    textAlign: 'right',
  },
  table: StyleUtil.styles.tables.table,
  th: StyleUtil.styles.tables.th,
  td: StyleUtil.styles.tables.td,
  td_msg: StyleUtil.styles.tables.td_msg,
  tdLast: StyleUtil.styles.tables.td_actions,
  tr: StyleUtil.styles.tables.tr_alt,
  td_dn: {
    wordBreak: 'break-all',
  },
  actionLink: StyleUtil.styles.tables.action_edit,
  ouValidationText: {
    color: StyleUtil.colors.red,
    fontSize: 12,
    fontStyle: 'italic',
    lineHeight: 1.2,
  },
});

export default class MasterGeneral extends React.Component<Props & { getValidator: () => OUValidator }, State> {
  private loadScopesTimeout: number;

  private get validator() {
    return this.props.getValidator();
  }

  private getAuditScopeSubTitle(scopeAll: boolean): React.ReactNode {
    if (scopeAll) {
      return <>Run LiveTiles Directory on your whole directory or select specific organizational units.</>;
    }

    return (
      <>
        Run LiveTiles Directory on your whole directory or select specific organizational units.
        <br />
        <br />
        <strong>Audit Scope:</strong>
        <br />
        If empty, all profiles will be loaded.
        <br />
        Otherwise, only these OU&apos;s and their children will be used to load profiles.
        <br />
        <br />
        <strong>Violation restrictions:</strong>
        <br />
        Add OU&apos;s to be skipped from generating violations.
      </>
    );
  }

  private getTimezoneFormatted() {
    const offset = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60);
    const offsetMinutes = Math.abs(offset) % 60;
    const sign = offset < 0 ? '+' : '-';
    const formattedOffset = `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
    return `UTC ${formattedOffset}`;
  }

  private getScopeAll() {
    const { settings, dirtySettings, currentAudienceId } = this.props;
    const dirty = dirtySettings[currentAudienceId] || ({} as typeof dirtySettings['foo']);

    if (dirty.audit_scopes === null && dirty.audit_exclusions === null) {
      // The user just selected all, and set both to null.
      return true;
    }

    if (dirty.audit_scopes != null || dirty.audit_exclusions != null) {
      // audit_scopes or audit_exclusions has been set to something truthy
      return false;
    }

    if ((settings.audit_scopes || []).length > 0 || (settings.audit_exclusions || []).length > 0) {
      // audit_scopes or audit_exclusions has a clean value with a scope
      // The user has NOT set them both to null (We know from above)
      return false;
    }

    // Neither audit_scopes, nor audit_exclusions, has a truthy dirty value
    // Neither audit_scopes, nor audit_exclusions, has a clean value with a scope
    return true;
  }

  private loadScopes = (search?: string, limit = 300) => {
    if (this.loadScopesTimeout) {
      window.clearTimeout(this.loadScopesTimeout);
    }
    if (!search) {
      this.props.clearScopes();
      return;
    }

    this.loadScopesTimeout = window.setTimeout(() => {
      this.props.loadScopes({ search, limit });
    }, 500);
  };

  private canTestAuditFilter() {
    const { settings, auditFiltersTestLoading } = this.props;

    let basicFilter = getFrom(settings)('audit_filters')('basicFilter').defaultTo([]);
    let isAdvanced = getFrom(settings)('audit_filters')('isAdvanced').defaultTo(false);
    let advancedFilter = getFrom(settings)('audit_filters')('advancedFilter').defaultTo('');

    return ((isAdvanced && advancedFilter) || (!isAdvanced && basicFilter.length > 0)) && !auditFiltersTestLoading;
  }

  private getOuValidationText() {
    const { collections, settings } = this.props;

    if (
      !collections ||
      !settings ||
      (!settings.audit_scopes && !settings.audit_exclusions) ||
      this.getScopeAll() ||
      !this.validator
    ) {
      return '';
    }
    // if ((settings.audit_scopes || []).length === 0 && (settings.audit_exclusions || []).length === 0) { return 'Must have at least one Organizational Unit'; }

    const invalidCollections = collections.filter(collection => {
      if (collection.type !== 'OU') {
        return false;
      }
      return !this.validator.isArrayIncluded(collection.definition.DNStrings);
    });

    if (invalidCollections.length > 0) {
      return (
        <span>
          The following collections contain Organizational Units outside of the selected scope:
          <ul>
            {invalidCollections.map((c, i, a) => (
              <li key={c.id}>
                <span style={{ display: 'inline-block', margin: '0 5px 0 10px' }}>•</span>
                <a href={`/settings/general?audienceId=${c.id}`} target="_blank" rel="noopener noreferrer">
                  {c.definition['name']}
                </a>
              </li>
            ))}
          </ul>
        </span>
      );
    }

    return '';
  }

  private getLocaleOptions = (): { label: string; value: string }[] => {
    let options: { label: string; value: string }[] = [];

    for (const value in RFC5646_LANGUAGE_TAGS) {
      if (Object.prototype.hasOwnProperty.call(RFC5646_LANGUAGE_TAGS, value)) {
        const label = RFC5646_LANGUAGE_TAGS[value];
        options.push({ value, label });
      }
    }

    return options;
  };

  private handleAuditFilterTest() {
    const { testAuditFilters, settings, setAuditFilterTestClicked } = this.props;
    setAuditFilterTestClicked(true);
    testAuditFilters(settings.audit_filters);
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      cleanSettings,
      clearUsers,
      connections,
      currentAudienceId,
      currentOrg,
      disconnected,
      editPermissions,
      isFree,
      loadUsers,
      permissionsByAudience,
      permissionsByAudienceDirty,
      providers,
      settings,
      settingsLoading,
      update,
      users,
      usersById,
      usersLoading,
      licenseAnalyze,
      licensePilot,
      licenseRun,
      setAuditFilterTestClicked,
      licenseReadOnlyModeSwitch,
    } = this.props;

    const scopeAll = this.getScopeAll();
    const timeZone = this.getTimezoneFormatted();

    const isAuditSourceProfileApi = getFrom(settings)('audit_source').defaultTo('MsGraph') == 'ProfileApi';
    const auditScopeAll = !getFrom(settings)('audit_filters')('enabled').defaultTo(false);
    const isAuditScopeAdvanced =
      isAuditSourceProfileApi || getFrom(settings)('audit_filters')('isAdvanced').defaultTo(false);
    const cleanSettingsAud = cleanSettings[currentAudienceId] || ({} as typeof cleanSettings['foo']);
    const permissions = permissionsByAudienceDirty[currentOrg.id] || permissionsByAudience[currentOrg.id];

    return (
      <div className="view-settings-general">
        <SettingCard
          title="LiveTiles Directory Mode"
          subTitle="Select which mode you would like LiveTiles Directory to operate in."
          groupedCardContent={this.renderPilotUsers()}
        >
          <Slider
            value={settings.global_mode}
            onChange={val => update(currentAudienceId, keys.global.mode, val)}
            options={[
              {
                value: 'Analyze',
                label: 'Analyze',
                icon: <img src={require('./icons/analyze-icon.svg')} />,
                disabled: !licenseAnalyze,
              },
              {
                value: 'Pilot',
                label: 'Pilot',
                icon: <img src={require('./icons/pilot-icon.svg')} />,
                disabled: !licensePilot,
              },
              {
                value: 'Run',
                label: 'Run',
                disabled: !licenseRun || disconnected,
                icon: (
                  <div>
                    <img src={require('./icons/run-icon.svg')} />
                    {disconnected && (
                      <Icon
                        name="link-broken"
                        className={Slider.lockClass}
                        style={{ color: StyleUtil.colors.red }}
                        data-tip-left="LiveTiles Directory is not connected to your directory, and cannot write any collected changes. Connect your directory before setting mode to Run."
                      />
                    )}
                  </div>
                ),
              },
            ]}
          />
        </SettingCard>
        {licenseReadOnlyModeSwitch && (
          <SettingCard
            title="Read-only"
            subTitle="Live Tiles Directory will stop writing in profiles, it will set all attributes to read-only and stop validating them."
          >
            <HyperForm
              fields={[
                hFields.toggle({
                  label: 'Off/On',
                  value: !!settings.read_only_mode,
                  onChange: e => {
                    const checked = e.currentTarget.checked;

                    if (checked) {
                      this.setState({ showReadOnlyAlertModal: true });
                    } else {
                      update(currentAudienceId, keys.global.readOnlyMode, false);
                    }
                  },
                }),
              ]}
            />
          </SettingCard>
        )}
        <SettingCard
          title="Monthly Report"
          subTitle="LiveTiles Directory will send monthly reports to Administrators with the latest directory health and engagement statistics."
        >
          <HyperForm
            showPremiumModal={this.props.showPremiumModal}
            fields={[
              hFields.toggle({
                label: 'Off/On',
                value: !!settings.broadcast_adminReport_enabled,
                premiumLock: isFree ? ModalKeys.adminReport : null,
                disabled: !!settings.read_only_mode,
                onChange: e => {
                  const checked = e.currentTarget.checked;
                  update(currentAudienceId, keys.broadcast.adminReport.enabled, checked);
                },
              }),
            ]}
          />
        </SettingCard>
        <SettingCard
          title="Daily Full Scan"
          subTitle="LiveTiles Directory constantly monitors your directory for changes, but once a day we perform a full scan to make sure everything is in sync."
        >
          <HyperForm
            onInvalidChange={this.props.handleFormInvalidChange}
            onMount={this.props.handleFormMount}
            onUnmount={this.props.handleFormUnmount}
            showPremiumModal={this.props.showPremiumModal}
            fields={[
              hFields.time({
                label: 'Scheduled daily at',
                name: 'dailyFullAuditTime',
                id: 'dailyFullAuditTime',
                value: !settings.audit_schedule_dailyFullAuditTime
                  ? null
                  : applyDSTforSchedules(moment(settings.audit_schedule_dailyFullAuditTime, moment.ISO_8601)),
                onChange: value => {
                  if (!moment.isMoment(value)) {
                    return;
                  }
                  update(
                    currentAudienceId,
                    keys.audit.schedule.dailyFullAuditTime,
                    applyDSTforSchedulesReverse(value).toISOString(),
                  );
                },
                premiumLock: isFree ? ModalKeys.dailyFullScan : null,
                suggestionText: timeZone,
              }),
            ]}
          />
        </SettingCard>
        {!cleanSettingsAud.audit_storeProfileData && (
          <SettingCard
            title="Advanced Profile Features"
            subTitle="If on, LiveTiles Directory caches scanned data to allow advanced features such as faster searches, profile validation, and web parts."
          >
            <HyperForm
              showPremiumModal={this.props.showPremiumModal}
              fields={[
                hFields.toggle({
                  label: 'Turn On',
                  value: settings.audit_storeProfileData,
                  premiumLock: isFree ? ModalKeys.profileValidation : null,
                  onChange: () => {
                    update(currentAudienceId, keys.audit.storeProfileData, true);
                  },
                }),
              ]}
            />
          </SettingCard>
        )}
        <SettingCard
          title="Language"
          subTitle="Set your organizations default language. If no translations are available for a users language this language will be used"
        >
          <HyperForm
            fields={[
              hFields.autocomplete({
                label: 'Default language',
                name: 'locale_default',
                props: {
                  options: this.getLocaleOptions(),
                  value: settings.locale_default || RFC5646_LANGUAGE_TAGS['en-US'],
                  isLoading: settingsLoading,
                  noOptionsMessage: () => 'TODO: here come the noOptionsMessage',
                },
                onChange: option => update(currentAudienceId, keys.locale.default, option.value),
              }),
            ]}
          />
        </SettingCard>

        <SettingCard
          title="Directory Details"
          subTitle={`The details for the Active Directory you are connected to. ${
            currentOrg.type === 'Online'
              ? `Select "${
                  disconnected ? 'Authorize' : 'Reauthorize'
                }" to update the authorization for your Active Directory.`
              : 'Select "Get Latest" to get the most recent version of LiveTiles Directory.'
          }`}
          cardStyle={
            !!disconnected && {
              boxShadow: `0 0 5px 1px ${StyleUtil.colors.red}`,
            }
          }
        >
          <em>
            {disconnected && (
              <Icon
                name="link-broken"
                style={{
                  color: StyleUtil.colors.red,
                  fontSize: '.8em',
                }}
              />
            )}
            <span>
              {currentOrg.name}
              {disconnected && ' (Disconnected)'}
            </span>
          </em>
          {disconnected ? (
            this.renderAddDirectory()
          ) : currentOrg.type === 'Online' ? (
            <DirectoryCard
              key={'ONLINE_CARD'}
              connection={{}}
              type={currentOrg.type}
              serviceUrl={SERVICE_URI}
              refreshUrl={
                providers &&
                `${providers
                  .filter(p => p.name === AZURE_APP_NAME)[0]
                  .href.replace('/common/', `/${currentOrg.remoteTenant}/`)}&prompt=consent`
              }
              {...(this.props as any)}
            />
          ) : (
            connections &&
            connections.map(d => (
              <DirectoryCard
                key={(d as DeviceDetails).id || (d as CloudConnection).admin.id}
                connection={d}
                type={currentOrg.type}
                serviceUrl={SERVICE_URI}
                refreshUrl={
                  providers &&
                  `${providers
                    .filter(p => p.name === AZURE_APP_NAME)[0]
                    .href.replace('/common/', `/${currentOrg.remoteTenant}/`)}&prompt=consent`
                }
                {...(this.props as any)}
              />
            ))
          )}
        </SettingCard>
        <SettingCard
          title="Support Contact"
          subTitle="Enter the email address for the person users should contact for support. This email address will appear at the bottom of email communications and the profile update page."
        >
          <HyperForm
            fields={[
              hFields.text({
                label: 'Email Address',
                inputType: 'email',
                value: settings.general_supportEmail || '',
                onChange: ({ currentTarget }) =>
                  update(currentAudienceId, keys.general.supportEmail, currentTarget.value),
              }),
            ]}
          />
        </SettingCard>
        {currentOrg.type !== 'Online' && (
          <SettingCard title="Directory Scope" subTitle={this.getAuditScopeSubTitle(scopeAll)}>
            <HyperForm
              onInvalidChange={this.props.handleFormInvalidChange}
              onMount={this.props.handleFormMount}
              onUnmount={this.props.handleFormUnmount}
              invalid={!!this.getOuValidationText()}
              fields={[
                hFields.radio({
                  label: 'Entire Active Directory',
                  name: 'scope',
                  value: scopeAll,
                  onChange: () => {
                    update(currentAudienceId, keys.audit.scopes, null);
                    update(currentAudienceId, keys.audit.exclusions, null);
                  },
                }),
                hFields.radio({
                  label: 'Specific Organizational Units',
                  name: 'scope',
                  value: !scopeAll,
                  onChange: () => {
                    update(currentAudienceId, keys.audit.scopes, [], true);
                    update(currentAudienceId, keys.audit.exclusions, [], true);
                  },
                }),
              ]}
            />
            {!scopeAll && (
              <div style={styles.scopesContainer}>
                {!!this.getOuValidationText() && (
                  <div style={styles.ouValidationText}>{this.getOuValidationText()}</div>
                )}

                <div>
                  <Button
                    icon={<FiShare2 style={{ transform: 'rotate(90deg)' }} />}
                    color="accent"
                    variant="link"
                    onClick={() => this.setState({ showTree: true })}
                  >
                    Diagram
                  </Button>
                </div>

                <div>
                  <div style={styles.auditScopeTableHeader}>
                    <ButtonContainer>
                      <Button
                        icon={<FiPlusCircle />}
                        color="accent"
                        variant="link"
                        onClick={() => this.setState({ includeOUs: [] })}
                      >
                        ADD TO AUDIT SCOPE
                      </Button>
                    </ButtonContainer>
                  </div>
                  <DirectoryScopesTable
                    defaultSortBy="reversedDistinguishedName"
                    noDataMessage="No Organizational Units Selected"
                    loading={!settings || settingsLoading}
                    onDelete={({ distinguishedName }) => {
                      const newScopes = settings[keys.audit.scopes].slice();
                      newScopes.splice(newScopes.findIndex(s => s.distinguishedName === distinguishedName), 1);
                      update(currentAudienceId, keys.audit.scopes, newScopes);
                    }}
                    // tslint:disable:no-unused-variable
                    data={(settings.audit_scopes || []).map(({ name, ...rest }) => ({
                      ...rest,
                      id: rest.distinguishedName,
                      reversedDistinguishedName: rest.distinguishedName
                        .split(',')
                        .reverse()
                        .join(','),
                    }))}
                  />
                </div>
                <div>
                  <div style={styles.auditScopeTableHeader}>
                    <ButtonContainer>
                      {(connections || []).filter(c =>
                        AtLeastVersion('2.0.0.0', ((c as DeviceDetails).lastHeartbeat || {})['version']),
                      ).length > 0 && (
                        <Button
                          icon={<FiPlusCircle />}
                          color="accent"
                          variant="link"
                          onClick={() => this.setState({ excludeOUs: [] })}
                        >
                          ADD TO VIOLATION RESTRICTIONS
                        </Button>
                      )}
                    </ButtonContainer>
                  </div>
                  <DirectoryScopesTable
                    defaultSortBy="reversedDistinguishedName"
                    noDataMessage="No Organizational Units Selected"
                    loading={!settings || settingsLoading}
                    onDelete={({ distinguishedName }) => {
                      const newScopes = settings[keys.audit.exclusions].slice();
                      newScopes.splice(newScopes.findIndex(s => s.distinguishedName === distinguishedName), 1);
                      update(currentAudienceId, keys.audit.exclusions, newScopes);
                    }}
                    // tslint:disable:no-unused-variable
                    data={(settings.audit_exclusions || []).map(({ name, ...rest }) => ({
                      ...rest,
                      id: rest.distinguishedName,
                      reversedDistinguishedName: rest.distinguishedName
                        .split(',')
                        .reverse()
                        .join(','),
                    }))}
                  />
                </div>
              </div>
            )}
          </SettingCard>
        )}
        {currentOrg.type === 'Online' && (
          <SettingCard
            title="Audit Scope Filter"
            subTitle="Use Specifc Profile Attributes Filter in order to customize fetched profiles for Azure Active Directory."
          >
            <HyperForm
              onInvalidChange={this.props.handleFormInvalidChange}
              onMount={this.props.handleFormMount}
              onUnmount={this.props.handleFormUnmount}
              invalid={!!this.getOuValidationText()}
              fields={[
                hFields.radio({
                  label: 'Entire Active Directory',
                  name: 'scope',
                  value: auditScopeAll,
                  onChange: () => {
                    update(currentAudienceId, keys.audit.filters, {
                      ...settings.audit_filters,
                      enabled: false,
                    });
                    setAuditFilterTestClicked(false);
                  },
                }),
                hFields.radio({
                  label: 'Specific Profile Attributes Filter',
                  name: 'scope',
                  value: !auditScopeAll,
                  onChange: () => {
                    update(currentAudienceId, keys.audit.filters, {
                      ...settings.audit_filters,
                      enabled: true,
                      isAdvanced:
                        isAuditSourceProfileApi || getFrom(settings)('audit_filters')('isAdvanced').defaultTo(false),
                    });
                    setAuditFilterTestClicked(false);
                  },
                }),
              ]}
            />
            {!auditScopeAll && (
              <>
                {!isAuditSourceProfileApi && (
                  <HyperForm
                    onInvalidChange={this.props.handleFormInvalidChange}
                    onMount={this.props.handleFormMount}
                    onUnmount={this.props.handleFormUnmount}
                    invalid={!!this.getOuValidationText()}
                    fields={[
                      hFields.toggle({
                        label: 'Advanced',
                        name: 'advancedAuditScope',
                        value: isAuditScopeAdvanced,
                        onChange: e => {
                          const checked = e.currentTarget.checked;
                          update(currentAudienceId, keys.audit.filters, {
                            ...settings.audit_filters,
                            isAdvanced: checked,
                          });
                          setAuditFilterTestClicked(false);
                        },
                      }),
                    ]}
                  />
                )}
                {!isAuditScopeAdvanced && (
                  <div style={styles.scopesContainer}>
                    <div style={StyleUtil.styles.tables.header}>
                      <ButtonContainer>
                        <Button
                          icon={<FiPlusCircle />}
                          color="accent"
                          variant="link"
                          onClick={() =>
                            this.setState({
                              auditBasicFilterProperty: '',
                              auditBasicFilterOperator: '',
                              auditBasicFilterValue: [],
                            })
                          }
                        >
                          Include condition
                        </Button>
                      </ButtonContainer>
                    </div>
                    <AuditScopesTable
                      noDataMessage="No conditions added"
                      loading={!settings || settingsLoading}
                      onEdit={({ property }) => {
                        var conditionToEdit = getFrom(settings)('audit_filters')('basicFilter')
                          .defaultTo([])
                          .find(i => i.property === property);
                        this.setState({
                          auditBasicFilterEditProperty: property.toString(),
                          auditBasicFilterOperator: conditionToEdit ? conditionToEdit.op : null,
                          auditBasicFilterValue: conditionToEdit ? conditionToEdit.value : null,
                        });
                      }}
                      onDelete={({ property }) => {
                        const newFilters = settings.audit_filters.basicFilter.slice();
                        newFilters.splice(newFilters.findIndex(s => s.property === property), 1);
                        update(currentAudienceId, keys.audit.filters, {
                          ...settings.audit_filters,
                          basicFilter: newFilters,
                        });

                        setAuditFilterTestClicked(false);
                      }}
                      data={(settings.audit_filters.basicFilter || []).map(({ ...rest }) => ({
                        id: rest.property,
                        property: rest.property,
                        op: rest.op === 'eq' ? 'equals' : 'not equals',
                        value: rest.value.join(' OR '),
                      }))}
                    />
                  </div>
                )}
                {isAuditScopeAdvanced && (
                  <div style={styles.scopesContainer}>
                    <HyperForm
                      fields={[
                        hFields.longText({
                          label: '$filter=',
                          hintText: (
                            <p>
                              This filter text should follow{' '}
                              <a target="blank" href="https://learn.microsoft.com/en-us/graph/filter-query-parameter">
                                OData
                              </a>
                              {' rules.'}
                            </p>
                          ),
                          lines: 2,
                          props: { style: { fontSize: 14 } },
                          value: settings.audit_filters.advancedFilter || '',
                          onChange: ({ currentTarget }) => {
                            update(currentAudienceId, keys.audit.filters, {
                              ...settings.audit_filters,
                              advancedFilter: currentTarget.value,
                            });
                            setAuditFilterTestClicked(false);
                          },
                        }),
                      ]}
                    />
                  </div>
                )}
                <div style={styles.testFilterButton}>
                  <Button
                    icon={<FiPlayCircle />}
                    color="accent"
                    variant="link"
                    disabled={!this.canTestAuditFilter()}
                    onClick={() => this.handleAuditFilterTest()}
                  >
                    Test filter
                  </Button>
                </div>
              </>
            )}
          </SettingCard>
        )}
        <SettingCard
          title="LiveTiles Directory Administrators"
          subTitle="Administrators are users who can review metrics, manage settings, edit other user's profiles, and approve changes."
        >
          <PermissionsTable
            orgId={currentOrg && currentOrg.id}
            onEdit={editPermissions}
            audiencePayload={permissions}
            role="Admin"
            loadUsers={loadUsers}
            clearUsers={clearUsers}
            users={users}
            usersLoading={usersLoading}
            usersById={usersById}
          />
        </SettingCard>
        <SettingCard
          title="LiveTiles Directory Editors"
          subTitle="Editors are users who can edit other user's profiles."
        >
          <PermissionsTable
            orgId={currentOrg && currentOrg.id}
            onEdit={editPermissions}
            audiencePayload={permissions}
            role="Editor"
            loadUsers={loadUsers}
            clearUsers={clearUsers}
            users={users}
            usersLoading={usersLoading}
            usersById={usersById}
          />
        </SettingCard>
        {this.props.confirmRun && this.renderConfirmRun()}
        {!!this.state.showTree && this.renderDiagramModal()}
        {!!this.state.excludeOUs && this.renderScopeModal('excludeOUs')}
        {!!this.state.includeOUs && this.renderScopeModal('includeOUs')}
        {(getFrom(this.state)('auditBasicFilterProperty').defaultTo(null) != null ||
          getFrom(this.state)('auditBasicFilterEditProperty').defaultTo(null) != null) &&
          this.renderBasicAuditFilterModal()}
        {!!this.state.showReadOnlyAlertModal && this.renderReadOnlyModeAlertModal()}
        {!!this.state.showReadOnlyAdviceModal && this.renderReadOnlyModeAdviceModal()}
      </div>
    );
  }

  renderPilotUsers() {
    const {
      currentOrg,
      editPermissions,
      permissionsByAudience,
      permissionsByAudienceError,
      permissionsByAudienceDirty,
      permissionsByAudienceLoading,
      loadUsers,
      clearUsers,
      users,
      usersLoading,
      usersById,
    } = this.props;
    const { pilotOpen } = this.state;

    if (!currentOrg) {
      return;
    }

    const loading = permissionsByAudienceLoading[currentOrg.id];
    const permissions = permissionsByAudienceDirty[currentOrg.id] || permissionsByAudience[currentOrg.id];
    const error = permissionsByAudienceError[currentOrg.id];

    let content;
    if (!pilotOpen) {
      content = null;
    } else if (loading || !permissions) {
      content = <LoadingSplash />;
    } else if (error) {
      content = <p>We weren&apos;t able to load your pilot participants. Please try again later.</p>;
    } else {
      content = (
        <div>
          <PermissionsTable
            onEdit={editPermissions}
            audiencePayload={permissions}
            role="Pilot"
            loadUsers={loadUsers}
            clearUsers={clearUsers}
            users={users}
            usersLoading={usersLoading}
            usersById={usersById}
          />
        </div>
      );
    }

    return (
      <section style={StyleUtil.styles.settings.section}>
        <Tooltip
          content="Pilot Participants are users who will receive notifications regarding missing or inaccurate information while LiveTiles Directory is in Pilot Mode."
          placement="left"
        >
          <button
            style={StyleUtil.styles.settings.sectionHeader}
            onClick={() => this.setState(state => ({ pilotOpen: !state.pilotOpen }))}
          >
            <h2 style={StyleUtil.styles.settings.sectionHeaderText}>Pilot Participants</h2>
            <span style={StyleUtil.styles.settings.sectionHeaderChevron}>
              <Icon name={pilotOpen ? 'chevron-up' : 'chevron-down'} />
            </span>
          </button>
        </Tooltip>
        {content}
      </section>
    );
  }

  renderAddDirectory() {
    const { currentOrg, providers, getCode, code, clearCode, gettingCode, connections } = this.props;

    const S = StyleUtil({
      message: {
        margin: '20px 0 30px',
      },
      reconnect: {
        backgroundColor: 'rgb(245, 245, 245)',
        borderRadius: 3,
        boxShadow: 'inset 0 0 5px rgb(180, 180, 180)',
        padding: 10,
        minHeight: 42,
      },
      reconnectHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
      },
      code: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '32px',
        marginLeft: 5,
      },
      reconnectMessage: {
        borderTop: '1px solid rgb(180, 180, 180)',
      },
      actionLink: {
        color: '#215fac',
        cursor: 'pointer',
        marginLeft: 5,
        textDecoration: 'underline',
      },
    });

    if (currentOrg.type === 'Online') {
      const refreshUrl =
        providers &&
        `${providers
          .filter(p => p.name === AZURE_APP_NAME)[0]
          .href.replace('/common/', `/${currentOrg.remoteTenant}/`)}&prompt=consent`;
      return (
        <div>
          <p style={S['message']}>
            LiveTiles Directory is not currently authorized to write to your directory or view full profiles.
            <br />
            Please authorize LiveTiles Directory to write approved updates and view full profiles in your directory.
          </p>
          <Button size="small" variant="solid" color="primary" onClick={() => (location.href = refreshUrl)}>
            AUTHORIZE
          </Button>
        </div>
      );
    }

    const connection = connections && connections[0];
    let lastHeartbeat;
    if (connection) {
      const connection = connections[0] as DeviceDetails;
      const timestamp = getFrom(connection)('lastHeartbeat')('timestamp').value;
      if (timestamp) {
        lastHeartbeat = moment(timestamp, moment.ISO_8601).format('on MMM Do [at] h:mma');
      }
    }

    return (
      <div>
        {connection ? (
          <p style={S['message']}>
            LiveTiles Directory is not able to communicate with your directory agent.
            {lastHeartbeat && (
              <>
                <br />
                We last heard from your agent {lastHeartbeat}
              </>
            )}
            <br />
            <br />
            Please ensure your agent host is available. If it is, please restart the LiveTiles Directory Service.
            <br />
            <br />
            If restarting the LiveTiles Directory Service does not restore connectivity, please reach out to our support
            team to troubleshoot. If instructed by our support team, you may need to use the button below to generate a
            new device code.
          </p>
        ) : (
          <p style={S['message']}>LiveTiles Directory is not connected to your directory.</p>
        )}
        {!code && !gettingCode ? (
          <Button size="small" onClick={() => getCode()} variant="link">
            Get new device code
          </Button>
        ) : (
          <div style={S['reconnect']}>
            <div style={S['reconnectHeader']}>
              <div>
                <span>Code:</span>
                <span style={S['code']}>{code || 'LOADING...'}</span>
              </div>
              <ButtonContainer>
                <Button size="small" variant="link" color="accent" onClick={() => clearCode()}>
                  Cancel
                </Button>
                <Button size="small" variant="solid" color="primary" onClick={() => (location.href = SERVICE_URI)}>
                  DOWNLOAD
                </Button>
              </ButtonContainer>
            </div>
            <p style={S['reconnectMessage']}>
              Install the service with the above code. Once the service has been registered this box will disappear.
            </p>
          </div>
        )}
      </div>
    );
  }

  renderConfirmRun() {
    const { currentOrg } = this.props;
    const S = {
      ...styles,
      confirmModal: {
        textAlign: 'center',
      },
      confirmP: {
        marginBottom: 20,
      },
      confirmBtn: {
        margin: '5px 10px',
      },
    };

    return (
      <Modal style={S.confirmModal}>
        <Modal.Header>Are you sure about that?</Modal.Header>
        <p style={S.confirmP}>
          As soon as LiveTiles Directory is set to run, it will start collecting information from your users.
        </p>
        <p style={S.confirmP}>
          LiveTiles Directory could potentially contact <em>all</em> users in your directory not excluded by the <br />{' '}
          Directory Scopes or Do Not Disturb settings.
        </p>
        <p style={S.confirmP}>
          That means that as soon as LiveTiles Directory is set to run,
          <br />
          <em>WE WILL BEGIN EMAILING USERS IN YOUR DIRECTORY.</em>
        </p>
        <p style={S.confirmP}>Please confirm that this is what you want!</p>
        <div>
          <Button
            style={S.confirmBtn}
            size="medium"
            onClick={() => {
              this.props.rejectConfirmRun();
            }}
          >
            Nevermind
          </Button>
          <Button
            size="medium"
            style={S.confirmBtn}
            color="primary"
            variant="solid"
            onClick={() => this.props.approveConfirmRun(currentOrg.id)}
          >
            START GATHERING DATA
          </Button>
        </div>
      </Modal>
    );
  }

  renderDiagramModal(collection = false) {
    const {
      allScopes,
      allScopesLoading,
      cleanSettings,
      currentAudienceId,
      currentOrg,
      dirtyCollection,
      settings,
      settingsLoading,
      update,
    } = this.props;

    const removeFrom = (array: Props['settings']['audit_scopes'], name) => {
      const newArray = array.slice();
      let index;
      const getIndex = () => {
        for (let i = 0; i < newArray.length; i++) {
          if (newArray[i].distinguishedName === name) {
            return i;
          }
        }
        return null;
      };

      index = getIndex();

      while (index !== null) {
        newArray.splice(index, 1);
        index = getIndex();
      }

      return newArray;
    };

    return (
      <Modal fillScreen={true} onClose={() => this.setState({ showTree: false })}>
        {!settings || settingsLoading || (!allScopes || allScopes.length === 0) || allScopesLoading ? (
          <LoadingSplash />
        ) : (
          <DirectoryTree
            settings={collection ? cleanSettings[currentOrg.id] : settings}
            scopes={
              collection && dirtyCollection.type === 'OU'
                ? dirtyCollection.definition.DNStrings.map(s => ({ name: '', distinguishedName: s, createdAt: '' }))
                : allScopes
            }
            onInclude={name => {
              const newScopes = removeFrom(this.props.settings.audit_scopes, name);
              const newExclusions = removeFrom(this.props.settings.audit_exclusions, name);
              newScopes.push({ name, distinguishedName: name });
              update(currentAudienceId, keys.audit.exclusions, newExclusions);
              update(currentAudienceId, keys.audit.scopes, newScopes);
            }}
            onExclude={name => {
              const newScopes = removeFrom(this.props.settings.audit_scopes, name);
              const newExclusions = removeFrom(this.props.settings.audit_exclusions, name);
              newExclusions.push({ name, distinguishedName: name });
              update(currentAudienceId, keys.audit.scopes, newScopes);
              update(currentAudienceId, keys.audit.exclusions, newExclusions);
            }}
            onClear={name => {
              const newScopes = removeFrom(this.props.settings.audit_scopes, name);
              const newExclusions = removeFrom(this.props.settings.audit_exclusions, name);
              update(currentAudienceId, keys.audit.scopes, newScopes);
              update(currentAudienceId, keys.audit.exclusions, newExclusions);
            }}
          />
        )}
      </Modal>
    );
  }

  renderScopeModal(action: 'excludeOUs' | 'includeOUs') {
    const { loadingScopes, scopes, settings, update, currentAudienceId } = this.props;

    const actionText = {
      excludeOUs: 'Violation restrictions',
      includeOUs: 'Audit Scope',
    }[action];

    const mapToValue = ({ distinguishedName }) => ({
      value: distinguishedName,
      label: distinguishedName,
    });

    const value = this.state[action].map(mapToValue);

    const options = value.concat(
      loadingScopes
        ? []
        : (scopes || [])
            .filter(({ distinguishedName }) => {
              if (settings.audit_scopes.filter(s => s.distinguishedName === distinguishedName).length > 0) {
                return false;
              } else if (settings.audit_exclusions.filter(s => s.distinguishedName === distinguishedName).length > 0) {
                return false;
              }
              return true;
            })
            .map(mapToValue),
    );

    return (
      <Modal onClose={() => this.setState({ [action]: null })}>
        <Modal.Header>Select Organizational Units to add to {actionText}</Modal.Header>
        <Select
          isLoading={loadingScopes}
          onInputChange={newString => {
            this.loadScopes(newString);
            return newString;
          }}
          value={value}
          options={options}
          isMulti={true}
          onChange={(vals: Option[]) => {
            this.setState({
              [action]: vals.map(({ value: distinguishedName }) => ({ distinguishedName })),
            });
          }}
          placeholder="Search for OU"
          noOptionsMessage={() => {
            if (loadingScopes) {
              return 'Searching...';
            }
            if (Array.isArray(scopes)) {
              return 'No results found';
            }
            return null;
          }}
          filterOption={o => value.filter(v => v.value === o.value).length === 0}
          maxMenuHeight={200}
        />
        <Modal.ButtonContainer>
          <Button
            color="primary"
            variant="solid"
            size="medium"
            disabled={!value || value.length === 0}
            onClick={() => {
              const settingKey = action === 'excludeOUs' ? keys.audit.exclusions : keys.audit.scopes;
              const newScopes = settings[settingKey].slice().concat(this.state[action]);

              update(currentAudienceId, settingKey, newScopes);
              this.setState({ [action]: null });
            }}
          >
            {'ADD'}
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  validateBasicAuditFilterModalValues() {
    return (
      (this.state.auditBasicFilterProperty || this.state.auditBasicFilterEditProperty) &&
      this.state.auditBasicFilterOperator &&
      this.state.auditBasicFilterValue &&
      this.state.auditBasicFilterValue.length > 0
    );
  }

  renderBasicAuditFilterModal() {
    const { fieldSchema, settings, currentAudienceId, update, setAuditFilterTestClicked } = this.props;

    const mapToValue = ({ property }) => ({
      value: property,
      label: property,
    });

    const operatorOptions = function() {
      return [
        {
          value: 'eq',
          label: 'equals',
        },
        {
          value: 'ne',
          label: 'not equals',
        },
      ];
    };

    const isFieldsLoading = getFrom(fieldSchema)('fieldsLoading').defaultTo(true);
    const options = isFieldsLoading
      ? []
      : (Object.values(getFrom(fieldSchema)('fields').defaultTo({})) || [])
          .sort((x1, x2) => x1.property.localeCompare(x2.property))
          .filter(({ property, type }) => {
            if (
              getFrom(settings)('audit_filters')('basicFilter')
                .defaultTo([])
                .filter(s => s.property === property && s.property !== this.state.auditBasicFilterEditProperty).length >
              0
            ) {
              return false;
            }

            if (type !== 'string' || property === 'id' || property === 'aboutMe') {
              return false;
            }

            return true;
          })
          .map(mapToValue);

    return (
      <Modal
        onClose={() =>
          this.setState({
            auditBasicFilterEditProperty: null,
            auditBasicFilterProperty: null,
            auditBasicFilterOperator: null,
            auditBasicFilterValue: null,
          })
        }
      >
        <Modal.Header>Select filter condition</Modal.Header>
        <div style={styles.conditionsContainer}>
          <div style={styles.conditionsControlProperty}>
            <Select
              isLoading={isFieldsLoading}
              options={options}
              isMulti={false}
              onChange={(val: Option) => {
                this.setState({
                  ...this.state,
                  auditBasicFilterProperty: val.value,
                });
              }}
              placeholder="Property"
              noOptionsMessage={() => {
                if (fieldSchema.loading) {
                  return 'Searching...';
                }
                if (Array.isArray(fieldSchema)) {
                  return 'No results found';
                }
                return null;
              }}
              value={
                this.state.auditBasicFilterProperty
                  ? this.state.auditBasicFilterProperty
                  : this.state.auditBasicFilterEditProperty
              }
              maxMenuHeight={200}
            />
          </div>
          <div style={styles.conditionsControlOperator}>
            <Select
              options={operatorOptions()}
              isMulti={false}
              onChange={(val: Option) => {
                this.setState({
                  ...this.state,
                  auditBasicFilterOperator: val.value,
                });
              }}
              value={this.state.auditBasicFilterOperator ? this.state.auditBasicFilterOperator : null}
              placeholder="Operator"
              maxMenuHeight={200}
            />
          </div>
          <div style={styles.conditionsControlValue}>
            <Select
              isMulti={true}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              creatable={true}
              onChange={(val: Option[]) => {
                this.setState({
                  ...this.state,
                  auditBasicFilterValue: val ? val.map(item => item.value) : null,
                });
              }}
              value={
                getFrom(this.state)('auditBasicFilterValue').defaultTo([]).length > 0
                  ? this.state.auditBasicFilterValue
                  : null
              }
              required
              placeholder="Value"
              maxMenuHeight={200}
            />
          </div>
        </div>
        <Modal.ButtonContainer>
          <Button
            color="primary"
            variant="solid"
            size="medium"
            disabled={!this.validateBasicAuditFilterModalValues()}
            onClick={() => {
              let basicFilter = getFrom(settings)('audit_filters')('basicFilter').defaultTo([]);

              let insertIndex = basicFilter.findIndex(i => i.property == this.state.auditBasicFilterEditProperty);
              let newCondition = {
                property: this.state.auditBasicFilterProperty
                  ? this.state.auditBasicFilterProperty
                  : this.state.auditBasicFilterEditProperty,
                op: this.state.auditBasicFilterOperator,
                value: this.state.auditBasicFilterValue,
              };
              let newConditions = getFrom(settings)('audit_filters')('basicFilter')
                .defaultTo([])
                .slice();

              if (insertIndex > -1) {
                newConditions.splice(insertIndex, 1, newCondition);
              } else {
                newConditions = newConditions.concat(newCondition);
              }

              update(currentAudienceId, keys.audit.filters, {
                ...settings.audit_filters,
                basicFilter: newConditions,
              });
              this.setState({
                auditBasicFilterEditProperty: null,
                auditBasicFilterProperty: null,
                auditBasicFilterOperator: null,
                auditBasicFilterValue: null,
              });
              setAuditFilterTestClicked(false);
            }}
          >
            {this.state.auditBasicFilterEditProperty ? 'Update' : 'Add'}
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  renderReadOnlyModeAlertModal() {
    const { currentOrg, currentAudienceId, update } = this.props;

    return (
      <Modal onClose={() => this.setState({ showReadOnlyAlertModal: null })}>
        <Modal.Header>Read-only mode</Modal.Header>
        <div>
          <p>
            By switching to read-only mode and saving settings, all attributes will be configured to read-only and Live
            Tiles Directory will no longer generate validations alerts.
          </p>
          <p>Are you sure?</p>
        </div>
        <Modal.ButtonContainer>
          <Button
            size="small"
            onClick={() => {
              this.setState({ showReadOnlyAlertModal: null });
            }}
          >
            {'CANCEL'}
          </Button>
          <Button
            color="primary"
            variant="solid"
            size="small"
            onClick={() => {
              const showModal = currentOrg.type !== 'Online';

              update(currentAudienceId, keys.global.readOnlyMode, true);
              update(currentAudienceId, keys.broadcast.adminReport.enabled, false);
              this.setState({ showReadOnlyAlertModal: null, showReadOnlyAdviceModal: showModal });
            }}
          >
            {'YES, SWITCH IT'}
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  renderReadOnlyModeAdviceModal() {
    return (
      <Modal onClose={() => this.setState({ showReadOnlyAdviceModal: null })}>
        <Modal.Header>Read-only mode</Modal.Header>
        <div>
          <p>
            Since read-only mode is on the Live Tiles Directory will not send any write request.
            <br />
            You can now remove the write permission from the agent service identity account on your environment.
          </p>
        </div>
        <Modal.ButtonContainer>
          <Button
            color="primary"
            variant="solid"
            size="small"
            onClick={() => {
              this.setState({ showReadOnlyAdviceModal: null });
            }}
          >
            {'GOT IT'}
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }
}
