import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';

import { Icon } from '../../components/';
import { OrgSettingsKeys } from '../../models/api';
import { create as createAudit } from '../../redux/modules/audits';
import { isAdmin, isFree } from '../../redux/modules/auth';
import { clear as clearUsers, load as loadUsers } from '../../redux/modules/externalUsers';
import { updateCurrent } from '../../redux/modules/orgs';
import {
  edit as editPermissions,
  load as loadPermissions,
  save as savePermissions,
} from '../../redux/modules/permissions';
import { AZURE_APP_NAME, clear as clearProviders, loadConsent as loadProviders } from '../../redux/modules/providers';
import { load as loadSettings, save as saveSettings } from '../../redux/modules/settings';
import StyleUtil from '../../utils/StyleUtil';
import { Analyze } from './Analyze';
import { Configure } from './Configure';
import { Connect } from './Connect';
import { Download } from './Download';
import { NemoRun } from './Nemo/NemoRun';
import { Select } from './Nemo/Select';
import { Run } from './Run';
import { SetMode } from './SetMode';
import { SetType } from './SetType';
import { Card, LoadingSplash, getFrom } from '@hyperfish/fishfood';

const connector = connect(
  state => ({
    connected: state.orgs.currentConnected,
    currentOrg: state.orgs.current,
    currentOrgLoading: state.orgs.loadingCurrent,
    isAdmin: isAdmin(state),
    isFree: isFree(state),
    permissionsByAudience: state.permissions.dataByAudience,
    permissionsByAudienceDirty: state.permissions.dirtyByAudience,
    permissionsByAudienceError: state.permissions.errorByAudience,
    permissionsByAudienceLoading: state.permissions.loadingByAudience,
    providers: state.providers.data,
    providersLoading: state.providers.loading,
    settings: state.settings.data && state.orgs.current && state.settings.data[state.orgs.current.id],
    settingsLoading: state.settings.loading,
    users: state.externalUsers.data,
    usersById: state.externalUsers.dataById,
    usersLoading: state.externalUsers.loading,
  }),
  {
    clearProviders,
    clearUsers,
    createAudit,
    editPermissions,
    loadPermissions,
    loadProviders,
    loadSettings,
    loadUsers,
    saveSettings,
    savePermissions,
    updateCurrent,
  },
);

type BaseProps = typeof connector['props'];

export type Props = BaseProps & {
  nemoRun: State['nemoRun'];
  setNemoRun: GettingStarted['setNemoRun'];
  setUserSelection: GettingStarted['setUserSelection'];
  userSelection: State['userSelection'];
};

export enum UserSelection {
  'all',
  'specific',
}

interface State {
  userSelection: UserSelection;
  nemoRun: boolean;
}

const styles = StyleUtil({
  wrapper: {
    margin: '0 auto',
  },
});

@connector
@Radium
export default class GettingStarted extends React.Component<BaseProps, State> {
  private altStates = [null, 'mode', 'type', 'download', 'complete'];
  private isFrozen = false;

  componentDidMount() {
    const { loadSettings, providers, providersLoading, loadProviders, currentOrg } = this.props;

    const settingsKeys = [
      OrgSettingsKeys.audit.schedule.realtime,
      OrgSettingsKeys.broadcast.enabled,
      OrgSettingsKeys.onboarding.step,
    ];

    if (currentOrg) {
      loadSettings([currentOrg.id], settingsKeys);
    }
    // if (!settings && !settingsLoading) { loadSettings(); }
    if (!providers && !providersLoading) {
      loadProviders(getFrom(currentOrg)('id').defaultTo(null));
    }
  }

  setUserSelection = (userSelection: UserSelection) => {
    this.setState({ userSelection });
  };

  setNemoRun = (nemoRun = true) => {
    this.setState({ nemoRun });
  };

  shouldComponentUpdate(nextProps: BaseProps) {
    const { currentOrg } = this.props;

    if (
      currentOrg &&
      nextProps.currentOrg &&
      currentOrg.settings[currentOrg.id][OrgSettingsKeys.onboarding.step] === 'type' &&
      nextProps.currentOrg.settings[nextProps.currentOrg.id][OrgSettingsKeys.onboarding.step] === 'connect'
    ) {
      this.isFrozen = true;
    }

    return !this.isFrozen;
  }

  UNSAFE_componentWillReceiveProps(nextProps: BaseProps) {
    const { currentOrg, providers, history } = this.props;

    if (
      nextProps.currentOrg &&
      nextProps.currentOrg.settings[nextProps.currentOrg.id][OrgSettingsKeys.onboarding.step] === 'complete'
    ) {
      // Onboarding just completed
      history.push('/home');
      const script = document.createElement('script');
      script.src = '//www.googleadservices.com/pagead/conversion.js';
      document.body.appendChild(script);
    }

    if (
      currentOrg &&
      nextProps.currentOrg &&
      currentOrg.settings[currentOrg.id][OrgSettingsKeys.onboarding.step] === 'type' &&
      nextProps.currentOrg.settings[currentOrg.id][OrgSettingsKeys.onboarding.step] === 'connect'
    ) {
      location.href = `${providers
        .filter(p => p.name === AZURE_APP_NAME)[0]
        .href.replace('/common/', `/${currentOrg.remoteTenant}/`)}&prompt=consent`;
    }
  }

  get wrapperStyle() {
    const { currentOrg, isFree } = this.props;
    return {
      ...styles['wrapper'],
      ...(isFree ? { maxWidth: 960 } : {}),
      ...(!isFree &&
      currentOrg &&
      this.altStates.indexOf(currentOrg.settings[currentOrg.id][OrgSettingsKeys.onboarding.step]) === -1
        ? { maxWidth: 640 }
        : {}),
    };
  }

  render() {
    const { currentOrg, isAdmin, isFree } = this.props;
    const { userSelection, nemoRun } = this.state;

    if (!isAdmin) {
      return (
        <div className="view-getting-started">
          <Card>
            <h2>Your org hasn&apos;t been set up!</h2>
            <p>Please get an admin to log in and complete the onboarding process for your org.</p>
          </Card>
        </div>
      );
    }

    const childProps = {
      ...this.props,
      nemoRun,
      setNemoRun: this.setNemoRun,
      setUserSelection: this.setUserSelection,
      userSelection,
    };

    const step = currentOrg && currentOrg.settings[currentOrg.id][OrgSettingsKeys.onboarding.step];
    // const mode = currentOrg && currentOrg.settings[currentOrg.id][OrgSettingsKeys.global.mode];

    return (
      <div className="view-getting-started">
        <div style={this.wrapperStyle}>
          {!currentOrg && <LoadingSplash />}
          {isFree && <Steps steps={['LOG IN', 'SELECT', 'CONFIRM']} active={nemoRun ? 2 : 1} />}
          {!isFree &&
            currentOrg &&
            this.altStates.indexOf(currentOrg.settings[currentOrg.id][OrgSettingsKeys.onboarding.step]) === -1 && (
              <Steps
                steps={['CONNECT', 'ANALYZE']}
                active={(() => {
                  switch (currentOrg.settings[currentOrg.id][OrgSettingsKeys.onboarding.step]) {
                    case 'connect':
                      return 0;
                    case 'analyze':
                      return 1;
                    case 'configure':
                      return 2;
                    case 'run':
                      return 3;
                    default:
                      return -1;
                  }
                })()}
              />
            )}
          {currentOrg && isFree ? (
            nemoRun ? (
              <NemoRun {...childProps} />
            ) : (
              <Select {...childProps} />
            )
          ) : (
            {
              null: <SetMode {...childProps} />,
              mode: <SetMode {...childProps} />,
              type: <SetType {...childProps} />,
              download: <Download {...childProps} />,
              connect: <Connect {...childProps} />,
              analyze: <Analyze {...childProps} />,
              configure: <Configure {...childProps} />,
              run: <Run {...childProps} />,
              complete: (
                <Card>
                  <h2>Taking you to your dashboard...</h2>
                </Card>
              ),
            }[step]
          )}
        </div>
      </div>
    );
  }
}

interface StepsProps {
  active: number;
  steps: string[];
}
const stepWidth = 72,
  arrowWidth = 15;
const stepStyles = StyleUtil({
  container: {
    marginBottom: 36,
    position: 'relative',
    // TEMP
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  stepContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  step: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
  },
  textStep: {
    color: StyleUtil.colors.text.dark,
    fontSize: 24,
    fontWeight: 300,
  },
  textNumber: {
    border: '2px solid #215fac',
    borderRadius: '50%',
    color: '#215fac',
    display: 'block',
    fontSize: 42,
    fontWeight: 300,
    height: stepWidth,
    lineHeight: '66px',
    margin: '8px 0',
    position: 'relative',
    textAlign: 'center',
    width: stepWidth,
  },
  textNumberActive: {
    backgroundColor: '#215fac',
    color: StyleUtil.colors.text.light,
  },
  check: {
    backgroundColor: '#215fac',
    borderRadius: '50%',
    color: StyleUtil.colors.text.light,
    display: 'block',
    fontSize: 12,
    height: 21,
    lineHeight: '20px',
    position: 'absolute',
    right: -7,
    textAlign: 'center',
    top: 4,
    width: 21,
  },
  textConnect: {
    color: StyleUtil.colors.text.dark,
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: '1px',
  },
  arrowContainer: {
    left: stepWidth / 2,
    position: 'absolute',
    right: stepWidth / 2,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  arrow: {
    marginLeft: stepWidth / 2 + 16,
    position: 'absolute',
    // width: `calc(${100/3}% - ${stepWidth + (16*2)}px)`,
    // TEMP
    // width: `calc(${100 / 1}% - ${stepWidth + (16 * 2)}px)`,
  },
  arrowShaft: {
    backgroundColor: StyleUtil.colors.gray,
    display: 'block',
    height: 2,
    width: `calc(100% - ${arrowWidth}px)`,
  },
  arrowHead: {
    borderBottom: `${arrowWidth / 3}px solid transparent`,
    borderLeft: `${arrowWidth}px solid #9b9b9b`,
    borderRight: `${arrowWidth}px solid transparent`,
    borderTop: `${arrowWidth / 3}px solid transparent`,
    height: 0,
    position: 'absolute',
    right: -arrowWidth,
    width: 0,
    top: '50%',
    marginTop: -arrowWidth / 3,
  },
});
const Steps = (props: StepsProps) => (
  <div style={stepStyles['container']}>
    <div style={stepStyles['stepContainer']}>
      {/*{['CONNECT', 'ANALYZE', 'CONFIGURE', 'RUN'].map((n,i,a) => (*/}
      {props.steps.map((n, i) => (
        <div key={n} style={stepStyles['step']}>
          <span style={stepStyles['textStep']}>Step</span>
          <span
            style={
              {
                ...stepStyles['textNumber'],
                ...(props.active === i ? stepStyles['textNumberActive'] : {}),
              } as any
            }
          >
            {i + 1}
            {props.active > i && (
              <span style={stepStyles['check']}>
                <Icon name="checkmark" />
              </span>
            )}
          </span>
          <span style={stepStyles['textConnect']}>{n}</span>
        </div>
      ))}
    </div>
    <div style={stepStyles['arrowContainer']}>
      {props.steps.map(
        (n, i, a) =>
          props.active > i &&
          i < a.length - 1 && (
            <div
              style={
                {
                  ...stepStyles['arrow'],
                  left: `${(100 / (a.length - 1)) * i}%`,
                  width: `calc(${100 / (a.length - 1)}% - ${stepWidth + 16 * 2}px)`,
                } as any
              }
              key={n}
            >
              <span style={stepStyles['arrowShaft']} />
              <span style={stepStyles['arrowHead']} />
            </div>
          ),
      )}
    </div>
  </div>
);
