import { ApiResult as FieldSchema } from '@hyperfish/antrea-api-contracts/src/fields';
import { PermissionResponse, Role } from '@hyperfish/antrea-api-contracts/src/permissions';
import { LoadingSplash, Button, ButtonContainer, FiUserPlus, FiUserMinus, setAtPath } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';

import { DataTable } from '../';
import { PermissionModal, Props as PermissionModalProps } from './PermissionModal';
import StyleUtil from '../../utils/StyleUtil';

// import {isGraphUser} from '../../redux/modules/externalUsers';
type Permission = PermissionResponse['permissions'][0];

interface Props {
  audienceId?: string;
  audiencePayload: PermissionResponse;
  clearUsers: PermissionModalProps['clearUsers'];
  dirtyPayload?: PermissionResponse;
  fieldSchema?: FieldSchema;
  isFree?: boolean;
  loading?: boolean;
  loadUsers: PermissionModalProps['loadUsers'];
  managerDisabled?: boolean;
  onEdit?: (audiencePayload: PermissionResponse) => void;
  orgId?: string;
  role: Role;
  users: PermissionModalProps['users'];
  usersById: PermissionModalProps['usersById'];
  usersLoading: boolean;
  tableClasses?: DataTable<{}>['props']['classes'];
}

interface State {
  dirtyPermission: Permission;
  dirtyIndex: number;
  forceSelected: boolean;
}

@Radium
export class PermissionsTable extends React.Component<Props, Partial<State>> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  get fieldNameMap() {
    const { fieldSchema, audienceId, orgId } = this.props;
    if (!fieldSchema || (!audienceId && !orgId)) {
      return {};
    }

    const fieldNameMap = {};
    Object.keys(fieldSchema.fields).forEach(id => {
      const field = fieldSchema.fields[id];
      const property = field.property;
      let title;
      if (field.audienceConfigs[audienceId]) {
        title = field.audienceConfigs[audienceId].ui.title;
      }
      if (!title && field.audienceConfigs[orgId]) {
        title = field.audienceConfigs[orgId].ui.title;
      }
      fieldNameMap[property] = title || property;
    });

    return fieldNameMap;
  }

  getClonedPayload(): Props['audiencePayload'] {
    const { audiencePayload, dirtyPayload } = this.props;
    return JSON.parse(JSON.stringify(dirtyPayload || audiencePayload));
  }

  handleDelete = (index: number) => {
    const { onEdit } = this.props;
    const newPayload = this.getClonedPayload();
    newPayload.permissions.splice(index, 1);
    onEdit(newPayload);
  };

  handleSave = () => {
    const { onEdit } = this.props;
    const { dirtyPermission, dirtyIndex } = this.state;
    const newPayload = this.getClonedPayload();

    if (dirtyIndex == null) {
      newPayload.permissions.push(dirtyPermission);
    } else {
      newPayload.permissions[dirtyIndex] = dirtyPermission;
    }

    onEdit(newPayload);
    this.hideModal();
  };

  handlePermissionEdit = (path: string, val: any) => {
    this.setState(state => {
      const o = { ...state };

      if (path === 'configuration.fieldNames') {
        if (val === true) {
          o.forceSelected = true;
          val = [];
        } else {
          o.forceSelected = false;
        }
      }

      o.dirtyPermission = setAtPath(state.dirtyPermission, path, val);

      return o;
    });
  };

  showEditModal = (permission: State['dirtyPermission'], dirtyIndex?: number) => {
    this.setState({
      dirtyPermission: JSON.parse(JSON.stringify(permission)),
      dirtyIndex,
      forceSelected: false,
    });
  };

  showCreateModal = (isManager = false) => {
    this.showEditModal((isManager
      ? {
          role: this.props.role as any,
          type: 'ProfileBased',
          definition: { strategy: 'Manager' },
          configuration: this.props.role === 'Approver' ? { fieldNames: [] } : {},
        }
      : {
          role: this.props.role as any,
          type: 'SpecificEntity',
          definition: { entityId: null, entityType: 'onPrem' },
          configuration: this.props.role === 'Approver' ? { fieldNames: [] } : {},
        }) as Permission);
  };

  hideModal = () => {
    this.setState({ dirtyPermission: null, dirtyIndex: null, forceSelected: false });
  };

  render() {
    if (!this.props.audiencePayload) {
      return <LoadingSplash />;
    }

    return (
      <>
        {this.renderHeader()}
        {this.renderTable()}
        {!!this.state.dirtyPermission && this.renderModal()}
      </>
    );
  }

  renderHeader() {
    const { onEdit, role, managerDisabled, audiencePayload } = this.props;

    if (!onEdit) {
      return;
    }

    const showManager = role === 'Approver';
    let managerIndex;
    if (!!audiencePayload && !!audiencePayload.permissions) {
      for (let i = 0; i < audiencePayload.permissions.length; i++) {
        const p = audiencePayload.permissions[i];
        if (p.role === 'Approver' && p.type === 'ProfileBased') {
          managerIndex = i;
          break;
        }
      }
    }

    return (
      <div style={StyleUtil.styles.tables.header}>
        <ButtonContainer>
          {showManager && (
            <Button
              icon={managerIndex != null ? <FiUserMinus /> : <FiUserPlus />}
              onClick={
                !managerDisabled &&
                (managerIndex != null ? () => this.handleDelete(managerIndex) : () => this.showCreateModal(true))
              }
              variant="link"
              size="small"
              color="accent"
              data-tip={managerDisabled ? 'This feature requires service version 2.13.0 or greater.' : null}
            >
              {managerIndex != null ? 'Remove' : 'Add'} Manager
            </Button>
          )}
          <Button
            icon={<FiUserPlus />}
            onClick={() => this.showCreateModal()}
            variant="link"
            size="small"
            color="accent"
          >
            Add User
          </Button>
        </ButtonContainer>
      </div>
    );
  }

  renderTable() {
    const { audiencePayload, loading, role, tableClasses } = this.props;

    return (
      <DataTable
        classes={tableClasses}
        onEdit={role === 'Approver' ? ({ id }) => this.showEditModal(audiencePayload.permissions[+id], +id) : null}
        defaultSortBy={'name' as never}
        onDelete={({ id }) => this.handleDelete(+id)}
        columns={
          role === 'Approver'
            ? {
                name: { label: 'Approver', sortable: true },
                attributes: { label: 'Attributes', type: DataTable.types.custom },
              }
            : {
                name: { label: 'Name', sortable: true },
              }
        }
        loading={loading}
        noDataMessage="No Users"
        data={
          audiencePayload &&
          audiencePayload.permissions &&
          audiencePayload.permissions
            .map((p, index) => ({ p, index }))
            .filter(({ p }) => p.role === role)
            .map(({ p, index }) => {
              const id = '' + index;
              const name =
                p.type === 'ProfileBased'
                  ? `[ ${p.definition.strategy} ]`
                  : p.definition.displayName || p.definition.email || p.definition.entityId;
              const attributes =
                p.role === 'Approver' &&
                (!!p.configuration['fieldNames'] && p.configuration['fieldNames'].length > 0 ? (
                  <ul>
                    {p.configuration['fieldNames'].map(n => (
                      <li key={n}>- {this.fieldNameMap[n] || n}</li>
                    ))}
                  </ul>
                ) : (
                  'All Attributes'
                ));

              return {
                id,
                attributes,
                name,
              };
            })
        }
      />
    );
  }

  renderModal() {
    const { audiencePayload, clearUsers, isFree, loadUsers, role, users, usersById, usersLoading } = this.props;
    const { dirtyPermission, forceSelected } = this.state;

    return (
      <PermissionModal
        onClose={this.hideModal}
        loadUsers={loadUsers}
        clearUsers={clearUsers}
        users={users}
        usersLoading={usersLoading}
        usersById={usersById}
        forceSelected={forceSelected}
        permission={dirtyPermission}
        onEdit={this.handlePermissionEdit}
        onSave={this.handleSave}
        fieldNameMap={this.fieldNameMap || {}}
        isFree={isFree}
        disabledUserIds={
          !!audiencePayload &&
          !!role &&
          audiencePayload.permissions
            .filter(p => p.role === role && p.type === 'SpecificEntity')
            .map(p => (p.type === 'SpecificEntity' ? p.definition.entityId : null))
            .filter(id => !!id)
        }
      />
    );
  }
}
