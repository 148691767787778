import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ApiResult, DirectorySearchRequest } from '@hyperfish/antrea-api-contracts';
import { Option } from '@hyperfish/fishfood/lib/components/Select';
import { LoadingSplash } from '@hyperfish/fishfood/lib/components/LoadingSplash';
import UserUtil from '@hyperfish/fishfood/lib/utils/UserUtil';
import { getFrom } from '@hyperfish/fishfood/lib/utils/GetUtil';
import { OrgChart as FFOrgChart, VirtualRoot } from '@hyperfish/fishfood/lib/components/OrgChart';
import { ApiClientContext } from '@hyperfish/fishfood/lib/utils/ApiClient';
import { loadWebpartSetting as loadSettings } from '../../redux/modules/settings';
import classes from './styles.module.scss';
import { isLoadedCurrent, hasOrgFeature } from '../../redux/modules/orgs';
import { ORG_FEATURE_DIRECTORY_WEB_PARTS } from '../../config';

const connector = connect(
  state => ({
    orgId: getFrom(state.orgs.current)('id').value,
    selfDetail: getFrom(state.externalUsers.detailById)('me').value,
    selfDetailError: getFrom(state.externalUsers.detailByIdError)('me').value,
    settings: state.settings.data,
    loaded: isLoadedCurrent(state),
    webpartsLicensed: hasOrgFeature(state, ORG_FEATURE_DIRECTORY_WEB_PARTS),
  }),
  {
    loadSettings,
  },
);

type Props = typeof connector['props'] & {
  onRequest?: (request: DirectorySearchRequest) => void;
};

const OrgChartC: React.FunctionComponent<Props> = ({
  orgId,
  selfDetail,
  selfDetailError,
  settings,
  webpartsLicensed,
  loaded,
  loadSettings,
}) => {
  const client = React.useContext(ApiClientContext);
  const [managerId, setManagerId] = React.useState<string>(null);
  const [rootUserId, setRootUserId] = React.useState<string>(null);
  const [error, setError] = React.useState<any>(false);
  const [internalSettings, setInternalSettings] = React.useState<undefined | {}>();
  const [schema, setSchema] = React.useState<ApiResult>(null);
  const [audienceId, setAudienceId] = React.useState<string>(null);
  const [useVirtualRoot, setUseVirtualRoot] = React.useState<boolean>(false);
  const [disableDetailModal, setDisableDetailModal] = React.useState<boolean>(false);
  const [virtualRoot, setVirtualRoot] = React.useState<VirtualRoot>(null);
  const [rootUserIds, setRootUserIds] = React.useState<string[]>([]);
  const [showLoadingSplash, setShowLoadingSplash] = React.useState<boolean>(true);

  const getRootUserIds = (rootUser: {} | Option[]): string[] => {
    if (rootUser == null) {
      return null;
    }

    if (Array.isArray(rootUser) && rootUser.length > 0) {
      return rootUser.map(option => option.value).filter(Boolean);
    }

    if (typeof rootUser === 'object') {
      return [getFrom(rootUser as Option)('value').value].filter(Boolean);
    }

    return null;
  };

  React.useEffect(() => {
    const getRootUserId = (): string => {
      // set rootId to null in case we use virtual root
      if (!!useVirtualRoot) {
        return null;
      }

      // set rootId to configured root user (if available)
      if (Array.isArray(rootUserIds) && rootUserIds.filter(Boolean).length > 0) {
        return rootUserIds[0];
      }

      // no root user configured, use highest line manager available
      if (!!managerId) {
        return managerId;
      }

      return rootUserId;
    };

    setRootUserId(getRootUserId());
  }, [useVirtualRoot, rootUserIds, managerId]);

  React.useEffect(() => {
    if (settings && settings[audienceId]) {
      const _audienceSettings = getFrom(settings[audienceId])('webparts_config_orgchart').defaultTo(
        getFrom(settings[orgId])('webparts_config_orgchart').defaultTo({}),
      );
      const _useVirtualRoot = getFrom(_audienceSettings as any)('useVirtualRoot').defaultTo(false);
      const _virtualRoot = getFrom(_audienceSettings as any)('virtualRoot').defaultTo(null);
      const _rootUserIds = getRootUserIds(getFrom(_audienceSettings as any)('rootUser').defaultTo([])).filter(Boolean);

      if (_useVirtualRoot && _virtualRoot && getFrom(_virtualRoot)('directReports').defaultTo([]).length === 0) {
        _virtualRoot.directReports = _rootUserIds;
      }

      if (!!_useVirtualRoot) {
        setRootUserId(getFrom(_virtualRoot)('profileId').defaultTo(null));
      }

      setInternalSettings(_audienceSettings);
      setUseVirtualRoot(_useVirtualRoot);
      setVirtualRoot(_virtualRoot);
      setDisableDetailModal(getFrom(_audienceSettings as any)('disableDetailModal').defaultTo(false));
      setRootUserIds(_rootUserIds);
    }
  }, [settings, audienceId]);

  React.useEffect(() => {
    if (!!useVirtualRoot && internalSettings) {
      setShowLoadingSplash(false);
      return;
    }

    if (rootUserId && internalSettings) {
      setShowLoadingSplash(false);
    }
  }, [useVirtualRoot, rootUserId, internalSettings]);

  React.useEffect(() => {
    if (!!useVirtualRoot || !selfDetail) {
      return;
    }

    const entityId = UserUtil.GetUserId(selfDetail.profile);

    client.api.directory
      .tree({ entityId, outputFields: ['id', 'objectguid'] })
      .then(({ data }) =>
        setManagerId(
          data.managers && data.managers.length > 0
            ? UserUtil.GetUserId(data.managers[data.managers.length - 1])
            : entityId,
        ),
      )
      .catch(err => setError(err || true));
  }, [selfDetail, useVirtualRoot]);

  React.useEffect(() => {
    loadSettings();
  }, []);

  React.useEffect(() => {
    client.api.fieldSchemas
      .get()
      .then(({ data }) => setSchema(data))
      .catch(err => setError(err || true));
    client.api.externalUsers
      .getAudience('me')
      .then(({ data }) => setAudienceId(data.audienceId))
      .catch(err => setError(err || true));
  }, []);

  if (loaded && !webpartsLicensed) {
    return <Redirect to={'/self'} />;
  }

  if (selfDetailError || error) {
    return (
      <div className="container">
        <h2>There was a problem loading the org chart</h2>
        <p>We were unable to load the data for the org chart. Please try again later.</p>
      </div>
    );
  }

  if (showLoadingSplash) {
    return <LoadingSplash />;
  }

  if (getFrom(internalSettings as any)('enabled').defaultTo(true) === false) {
    return <Redirect to={'/self'} />;
  }

  return (
    <div className={`container ${classes.container}`}>
      <FFOrgChart
        height={600}
        {...internalSettings}
        schema={schema}
        audienceId={audienceId}
        masterAudienceId={orgId}
        rootId={rootUserId}
        fetchDetail={
          disableDetailModal
            ? undefined
            : id => client.api.externalUsers.get(id, { cacheok: 1 }).then(({ data }) => data)
        }
        fetchTree={request => client.api.directory.tree(request).then(({ data }) => data)}
        useVirtualRoot={useVirtualRoot}
        virtualRoot={!!useVirtualRoot ? virtualRoot : undefined}
        showCommIcons={getFrom(internalSettings as any)('showCommIcons').defaultTo(false)}
      />
    </div>
  );
};

export const OrgChart = connector(OrgChartC);
