import React from 'react';
import { connect } from 'react-redux';
import { parse } from 'query-string';

import { isApprover } from '../../redux/modules/auth';
import { getFrom } from '@hyperfish/fishfood';

const connector = connect(state => ({
  isApprover: isApprover(state),
}));

@connector
export default class LoginSuccess extends React.Component<typeof connector['props'], {}> {
  defaultRet = '/';

  componentDidMount() {
    const {
      isApprover,
      location: { search },
      history,
    } = this.props;

    const query = parse(search);
    const ret = getFrom(query)('ret').value as string;

    if (ret === '/approve' && !isApprover) {
      history.push('/self/approve');
      return;
    }

    history.push(ret || this.defaultRet);
  }

  render() {
    return (
      <div className="view-login-success container">
        <h1>Logged in successfully!</h1>
        <p>Give us a moment to redirect you&hellip;</p>
      </div>
    );
  }
}
