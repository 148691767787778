import { RelationshipState as State, Notification as ClientNotification } from '../../models/client/';
import { ApiClient } from '@hyperfish/fishfood';
import {
  AddRelationshipEdgeResponse,
  RelationshipEdgeRequest,
  RelationshipEdgeResponse,
  RelationshipTypeRequest,
  RelationshipTypeResponse,
  DeleteRelationshipEdgesRequest,
} from '@hyperfish/antrea-api-contracts/src/relationship';
import {
  DirectorySearchQueryFieldItem,
  DirectorySearchRequest,
  DirectorySearchResponse,
} from '@hyperfish/antrea-api-contracts';

const LOAD = 'antrea/relationshipTypes/LOAD';
const LOAD_SUCCESS = 'antrea/relationshipTypes/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/relationshipTypes/LOAD_FAIL';

const DELETE = 'antrea/relationshipTypes/DELETE';
const DELETE_SUCCESS = 'antrea/relationshipTypes/DELETE_SUCCESS';
const DELETE_FAIL = 'antrea/relationshipTypes/DELETE_FAIL';

const ADD = 'antrea/relationshipTypes/ADD';
const ADD_SUCCESS = 'antrea/relationshipTypes/ADD_SUCCESS';
const ADD_FAIL = 'antrea/relationshipTypes/ADD_FAIL';

const EDIT = 'antrea/relationshipTypes/EDIT';
const EDIT_SUCCESS = 'antrea/relationshipTypes/EDIT_SUCCESS';
const EDIT_FAIL = 'antrea/relationshipTypes/EDIT_FAIL';

const LOADEDGES = 'antrea/relationshipTypes/LOADEDGES';
const LOADEDGES_SUCCESS = 'antrea/relationshipTypes/LOADEDGES_SUCCESS';
const LOADEDGES_FAIL = 'antrea/relationshipTypes/LOADEDGES_FAIL';

const ADDEDGES = 'antrea/relationshipTypes/ADDEDGES';
const ADDEDGES_SUCCESS = 'antrea/relationshipTypes/ADDEDGES_SUCCESS';
const ADDEDGES_FAIL = 'antrea/relationshipTypes/ADDEDGES_FAIL';

const DELETEEDGES = 'antrea/relationshipTypes/DELETEEDGES';
const DELETEEDGES_SUCCESS = 'antrea/relationshipTypes/DELETEEDGES_SUCCESS';
const DELETEEDGES_FAIL = 'antrea/relationshipTypes/DELETEEDGES_FAIL';

const initialState: State = {
  loadingTypes: true,
  loadingEdges: false,
} as State;

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loadingTypes: true,
        reloadRelationshipTypes: false,
      };
    case LOAD_SUCCESS:
      const types: RelationshipTypeResponse[] = action.result || [];
      return {
        ...state,
        loadingTypes: false,
        reloadRelationshipTypes: false,
        types: types,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loadingTypes: false,
        reloadRelationshipTypes: false,
        types: null,
        error: action.error,
      };
    case DELETE:
      return {
        ...state,
        loadingTypes: true,
        reloadRelationshipTypes: false,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        loadingTypes: false,
        reloadRelationshipTypes: true,
        edges: null,
      };
    case DELETE_FAIL:
      return {
        ...state,
        loadingTypes: false,
        error: action.error,
        reloadRelationshipTypes: false,
      };
    case ADD:
      return {
        ...state,
        loadingTypes: true,
        reloadRelationshipTypes: false,
      };
    case ADD_SUCCESS:
      return {
        ...state,
        loadingTypes: false,
        reloadRelationshipTypes: true,
        edges: null,
      };
    case ADD_FAIL:
      return {
        ...state,
        loadingTypes: false,
        error: action.error,
        reloadRelationshipTypes: false,
      };
    case EDIT:
      return {
        ...state,
        loadingTypes: true,
        reloadRelationshipTypes: false,
      };
    case EDIT_SUCCESS:
      return {
        ...state,
        loadingTypes: false,
        reloadRelationshipTypes: true,
        edges: null,
      };
    case EDIT_FAIL:
      return {
        ...state,
        loadingTypes: false,
        error: action.error,
        reloadRelationshipTypes: false,
      };
    case LOADEDGES:
      return {
        ...state,
        loadingEdges: true,
        reloadRelationshipEdges: false,
      };
    case LOADEDGES_SUCCESS:
      const edges: RelationshipEdgeResponse = action.result || {};
      return {
        ...state,
        loadingEdges: false,
        reloadRelationshipEdges: false,
        edges: edges,
        error: null,
      };
    case LOADEDGES_FAIL:
      return {
        ...state,
        loadingEdges: false,
        reloadRelationshipEdges: false,
        edges: null,
        error: action.error,
      };
    case ADDEDGES:
      return {
        ...state,
        loadingEdges: false,
        reloadRelationshipEdges: false,
        addingEdges: true,
      };
    case ADDEDGES_SUCCESS:
      return {
        ...state,
        loadingEdges: false,
        reloadRelationshipEdges: true,
        addingEdges: false,
        error: null,
      };
    case ADDEDGES_FAIL:
      return {
        ...state,
        loadingEdges: false,
        error: action.error,
        reloadRelationshipEdges: false,
        addingEdges: false,
      };
    case DELETEEDGES:
      return {
        ...state,
        loadingEdges: true,
        reloadRelationshipEdges: false,
      };
    case DELETEEDGES_SUCCESS:
      return {
        ...state,
        loadingEdges: false,
        reloadRelationshipEdges: true,
      };
    case DELETEEDGES_FAIL:
      return {
        ...state,
        loadingEdges: false,
        error: action.error,
        reloadRelationshipEdges: false,
      };
    default:
      return state;
  }
}

export function loadRelationshipTypes() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/relationship-types'),
  };
}

export function deleteRelationshipType(id: string) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    promise: (client: ApiClient) => client.del(`/relationship-types/${id}`),
  };
}

export function addRelationshipType(data: RelationshipTypeRequest) {
  return {
    types: [ADD, ADD_SUCCESS, ADD_FAIL],
    promise: (client: ApiClient) =>
      client.post(`/relationship-types/`, {
        data: data,
      }),
  };
}

export function editRelationshipType(id: string, data: RelationshipTypeRequest) {
  return {
    types: [EDIT, EDIT_SUCCESS, EDIT_FAIL],
    promise: (client: ApiClient) =>
      client.put(`/relationship-types/${id}`, {
        data: data,
      }),
  };
}

export function loadRelationshipEdges(relationshipTypeId: string) {
  return {
    types: [LOADEDGES, LOADEDGES_SUCCESS, LOADEDGES_FAIL],
    promise: (client: ApiClient) => client.get('/relationship-types/' + relationshipTypeId + '/edges'),
  };
}

const getRequest = (entityIds: string[]): DirectorySearchRequest => {
  const queryObj: DirectorySearchQueryFieldItem = {
    field: ['objectguid', 'id'],
    value: entityIds,
  };

  return {
    query: [queryObj],
    outputFields: ['mail', 'id', 'displayName', 'objectguid', 'displayname'],
  };
};

export function addRelationshipEdges(relationshipTypeId: string, data: RelationshipEdgeRequest[]) {
  return {
    types: [ADDEDGES, ADDEDGES_SUCCESS, ADDEDGES_FAIL],
    promise: (client: ApiClient) => {
      return new Promise((resolve, reject) => {
        client
          .post('/relationship-types/' + relationshipTypeId + '/edges', {
            data: { edges: data },
          })
          .then(async (result: any) => {
            const notifications: ClientNotification[] = [];
            const response: AddRelationshipEdgeResponse[] = result.data || [];
            const failedCases: AddRelationshipEdgeResponse[] = response.filter(f => f.messageType != 'success');
            console.log(result.data);

            if (failedCases.length == 0) {
              notifications.push({
                type: 'success',
                message: '',
                title: 'Success!',
                duration: 5,
              } as ClientNotification);
            } else {
              const idsToSearch: string[] = [];
              failedCases.forEach(f => {
                if (!idsToSearch.some(id => id == f.entityIdFrom)) idsToSearch.push(f.entityIdFrom);
                if (!idsToSearch.some(id => id == f.entityIdTo)) idsToSearch.push(f.entityIdTo);
              });
              const users = await client
                .post<DirectorySearchResponse>('/directory/people/search', { data: getRequest(idsToSearch) })
                .then(r => r.data.results);

              failedCases.forEach(f => {
                const userFrom = users.find(u => u['objectguid'] == f.entityIdFrom || u['id'] == f.entityIdFrom);
                const userTo = users.find(u => u['objectguid'] == f.entityIdTo || u['id'] == f.entityIdTo);

                const userLabelFrom =
                  userFrom['displayName'] || userFrom['displayname']
                    ? `${userFrom['displayName'] || userFrom['displayname']}`
                    : userFrom['mail'];
                const userLabelTo =
                  userTo['displayName'] || userTo['displayname']
                    ? `${userTo['displayName'] || userTo['displayname']}`
                    : userTo['mail'];

                const message = `${userLabelFrom} - ${userLabelTo}: ${f.message}`;
                notifications.push({
                  type: 'error',
                  message: message,
                  title: 'Error!',
                  duration: 60,
                } as ClientNotification);
              });
            }

            result.data.forceNotifications = notifications;
            resolve(result);
          });
      });
    },
  };
}

export function deleteRelationshipEdges(relationshipTypeId: string, data: DeleteRelationshipEdgesRequest[]) {
  return {
    types: [DELETEEDGES, DELETEEDGES_SUCCESS, DELETEEDGES_FAIL],
    promise: (client: ApiClient) =>
      client.del(`/relationship-types/${relationshipTypeId}/edges`, { data: { edges: data } }),
  };
}
