import styled from 'styled-components';
import { Form } from '@hyperfish/fishfood/lib/components/Form';
import { FiDelete, FiSliders, FiBarChart } from '@hyperfish/fishfood/lib/components/Icon';
import { themeColor, spacing } from '@hyperfish/fishfood/lib/utils/ThemeUtil';

export interface ButtonContainerProps {
  padding?: [number] | [number, number] | [number, number, number] | [number, number, number, number];
  margin?: [number] | [number, number] | [number, number, number] | [number, number, number, number];
  justify: string;
}

export const InternalFieldSet = styled(Form.FieldSet)`
  && {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const InternalFormContainer = styled(Form.FieldContainer)`
  && {
    margin: 0px;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FlexItem = styled.div<{ grow: boolean; maxWidth: number }>`
  flex-grow: ${p => (p.grow ? 1 : 0)};
  max-width: calc(${p => (p.maxWidth ? p.maxWidth : 50)}% - 5px);

  > .Form_field.Form_field {
    padding: 0;
  }

  > button {
    transform: translateY(${spacing(4)});
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > .Form_field {
    width: 40%;
  }
`;

export const FieldSpacer = styled.div`
  width: 40%;
  flex-grow: 1;
`;

export const DeleteIcon = styled(FiDelete)`
  color: ${themeColor('error')};
  align-self: flex-end;
`;

export const Divide = styled.hr`
  border: none;
  border-bottom: 1px solid ${themeColor('lightGray')};
  margin: ${spacing(2)};
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  width: 100%;
  display: flex;
  justify-content: ${(p: ButtonContainerProps) => p.justify || 'initial'};

  padding: ${(p: ButtonContainerProps) => (p.padding ? spacing(...p.padding) : 0)};
  margin: ${(p: ButtonContainerProps) => (p.margin ? spacing(...p.margin) : 0)};

  > button {
    margin-right: ${spacing(2)};
  }

  > button:last-of-type {
    margin-right: 0;
  }
`;

export const SliderIcon = styled(FiSliders)`
  transform: rotate(90deg);
`;

export const SortIcon = styled(FiBarChart)`
  transform: rotate(-90deg) scaleY(-1);
`;

export const UserDetailPhotoContainer = styled.div`
  margin-top: 42px;
  margin-bottom: 5px;
`;

export const CustomLabelItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${spacing(1)};

  > button {
    transform: translateY(${spacing(4)});
  }
`;

export const CustomLabelsContainer = styled.div`
  padding-bottom: -8;
`;
