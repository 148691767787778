import { ApiMsGraphUser, ApiProfileTypes, OnpremSearchResult, OrgDetails } from '@hyperfish/antrea-api-contracts';
import { ApiClient } from '@hyperfish/fishfood';
import { ApiUser } from '@hyperfish/fishfood/lib/utils/UserUtil';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { GlobalState } from '../../../models/client/';
import { SAVE_SECTION_SUCCESS } from '../self';
import { ExternalUsersAction, LoadAction, LoadSuccessAction } from './actions';
import * as types from './types';

type State = GlobalState['externalUsers'];

// UTILITY FUNCTIONS
export function isGraphUser(user: ApiMsGraphUser | OnpremSearchResult): user is ApiMsGraphUser {
  return user && !!user['id'];
}
export function isPremUser(user: ApiMsGraphUser | OnpremSearchResult): user is OnpremSearchResult {
  return user && !!user['objectguid'];
}
export function getProviderFromOrg(org: OrgDetails): keyof ApiProfileTypes {
  return org && org.type === 'Online' ? 'msGraph' : 'onPrem';
}

function deepSet(obj: object, { id }: { id: string }, value: any) {
  return {
    ...obj,
    [id]: value,
  };
}

function mapProfilesToObject({ result: { profiles } }: LoadSuccessAction, obj = {}) {
  if (!profiles || !Array.isArray(profiles)) {
    return obj;
  }
  const o = { ...obj };
  for (const profile of profiles) {
    const id = isGraphUser(profile) ? profile.id : profile.objectguid;
    o[id] = profile;
  }
  return o;
}

const initialState = {
  dataById: {},
  detailById: {},
  detailByIdLoading: {},
  detailByIdError: {},
} as State;

export default function reducer(state: State = initialState, action: ExternalUsersAction): State {
  switch (action.type) {
    // Collection Actions
    case types.LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.LOAD_SUCCESS:
      return {
        ...state,
        data: action.result,
        dataById: mapProfilesToObject(action, state.dataById),
        loading: false,
        q: action.q,
      };
    case types.LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.CLEAR:
      return {
        ...state,
        data: null,
        q: null,
      };

    // Detail Actions
    case types.LOAD_DETAIL:
      return {
        ...state,
        detailByIdLoading: deepSet(state.detailByIdLoading, action, true),
        detailByIdError: deepSet(state.detailByIdError, action, null),
      };
    case types.LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        detailByIdLoading: deepSet(state.detailByIdLoading, action, false),
        detailById: deepSet(state.detailById, action, action.result),
      };
    case types.SAVE_DETAIL_SUCCESS:
      return {
        ...state,
        detailById: deepSet(state.detailById, action, action.result),
      };
    case SAVE_SECTION_SUCCESS:
      return {
        ...state,
        detailById: deepSet(state.detailById, { id: 'me' }, action.result),
      };
    case types.SAVE_PHOTO_SUCCESS:
      return {
        ...state,
        detailById: {
          ...state.detailById,
          [action.id]: {
            ...state.detailById[action.id],
            relatedEntities: {
              ...state.detailById[action.id].relatedEntities,
              profilePicture: action.result,
              changes: {
                ...state.detailById[action.id].relatedEntities.changes,
                [action.field]: action.result.relatedEntities.change,
              },
            },
          },
        },
      } as any;
    case types.LOAD_DETAIL_FAIL:
      return {
        ...state,
        detailByIdLoading: deepSet(state.detailByIdLoading, action, false),
        detailByIdError: deepSet(state.detailByIdError, action, action.error),
      };
    default:
      return state;
  }
}

// Action Creators
export function load(q: string, limit?: number) {
  return {
    type: types.LOAD,
    q,
    limit,
  };
}

export function clear() {
  return {
    type: types.CLEAR,
  };
}

export function loadDetail(id: string) {
  return {
    types: [types.LOAD_DETAIL, types.LOAD_DETAIL_SUCCESS, types.LOAD_DETAIL_FAIL],
    promise: (client: ApiClient) => client.api.externalUsers.get(id),
    id,
    notifications: {
      fail: 'Failed to load user detail',
    },
  };
}

export function saveDetail(id: string, profile: Partial<ApiUser>) {
  return {
    types: [types.SAVE_DETAIL, types.SAVE_DETAIL_SUCCESS, types.SAVE_DETAIL_FAIL],
    id,
    promise: (client: ApiClient) =>
      client.patch(`/external-users/current/${encodeURIComponent(id)}`, {
        data: { profile },
      }),
    notifications: {
      fail: 'Failed to update profile.',
      success: 'Successfully updated profile.',
    },
  };
}

export function savePhoto(id: string, field: string, photoDataUri: string) {
  return {
    types: [types.SAVE_PHOTO, types.SAVE_PHOTO_SUCCESS, types.SAVE_PHOTO_FAIL],
    id,
    field,
    promise: (client: ApiClient) =>
      client.patch(`/external-users/current/${encodeURIComponent(id)}/profile-picture`, {
        data: { content: photoDataUri },
      }),
    notifications: {
      fail: 'Failed to update profile picture.',
      success: 'Successfully updated profile picture.',
    },
  };
}

// SAGAS
function* loadUsersSaga(client: ApiClient, action: LoadAction) {
  // Debounce by 500ms
  yield delay(500);

  // tslint:disable-next-line:no-unused-variable
  const { q, limit } = action;
  try {
    const result = yield call([client, client.get], `/external-users/current`, {
      params: { q, limit },
    });
    yield put({ ...action, type: types.LOAD_SUCCESS, result: result.data });
  } catch (error) {
    yield put({ ...action, type: types.LOAD_FAIL, error });
  }
}

export function* loadExternalUsersWatch(client) {
  yield takeLatest(types.LOAD, loadUsersSaga, client);
}
