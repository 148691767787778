import { getFrom, DateTimePicker, Select, TextField, Toggle } from '@hyperfish/fishfood';
import PeoplePicker from '@hyperfish/fishfood/lib/components/PeoplePicker/LegacyPeoplePicker';
import React from 'react';
import Dropzone from 'react-dropzone';

import { HyperField } from './HyperFields';
import classes from './HyperFormField.module.scss';

interface Props {
  field: HyperField;
  validationText: string;
  onChange: (...args: any[]) => void;
}

export class HyperFormField extends React.PureComponent<Props> {
  render() {
    const { field, validationText, onChange } = this.props;
    const f = field;

    switch (f.type) {
      case 'text':
      case 'number':
      case 'longtext':
        return f.readOnly ? (
          <span>{String(f.value) || '\u00a0'}</span>
        ) : (
          <TextField
            // inputStyle={!!f.premiumLock ? { paddingRight: 25 } : undefined} // TODO
            value={f.value}
            onChange={onChange}
            autoFocus={f.autofocus}
            type={f.type === 'longtext' ? undefined : f.type === 'number' ? 'number' : f.inputType}
            disabled={f.readOnly || f.disabled || !!f.premiumLock}
            invalid={!!validationText}
            id={`${f.id}_input`}
            placeholder={f.placeholder}
            long={f.type === 'longtext'}
            longLines={f.type === 'longtext' ? f.lines : undefined}
            {...f.props}
          />
        );
      case 'color':
        return (
          <div className={classes.colorWrapper}>
            <TextField
              value={f.value}
              onChange={onChange}
              autoFocus={f.autofocus}
              type={f.inputType}
              disabled={f.readOnly || f.disabled || !!f.premiumLock}
              invalid={!!validationText}
              id={`${f.id}_input`}
              placeholder={f.placeholder}
              {...f.props}
            />
            <span className={classes.colorPreview} style={{ backgroundColor: f.value as string }} />
          </div>
        );
      case 'toggle':
        return (
          <div className={classes.inputPadder}>
            <Toggle
              checked={f.value}
              onChange={onChange}
              disabled={f.disabled || f.readOnly || !!f.premiumLock}
              id={`${f.id}_input`}
            />
          </div>
        );
      case 'radio':
        return (
          <div className={classes.inputPadder}>
            <input type="radio" checked={f.value} onChange={onChange} name={f.name} id={`${f.id}_input`} />
          </div>
        );
      case 'autocomplete':
      case 'dropdown':
        return (
          <Select
            autofocus={f.autofocus}
            isClearable={!f.required}
            isDisabled={f.disabled || f.readOnly || !!f.premiumLock}
            inputId={`${f.id}_input`}
            isMulti={f.dropdownMulti}
            onChange={onChange}
            options={f.dropdownOptions}
            placeholder={f.placeholder}
            isSearchable={f.type === 'dropdown' ? false : true}
            value={f.value}
            creatable={f.dropdownCreatable}
            // {...(!!f.premiumLock ? { arrowRenderer: () => null } : {})}
            {...(f.props as any)}
          />
        );
      case 'person':
        return (
          <PeoplePicker
            autofocus={f.autofocus}
            clearUsers={f.clearUsers}
            loadUsers={f.loadUsers}
            onChange={onChange}
            users={f.users}
            value={f.value as any}
            isMulti={f.isMulti as any}
            isDisabled={f.disabled || f.readOnly || !!f.premiumLock}
            isLoading={f.isLoading}
            {...f.props}
          />
        );
      case 'datetime':
        return (
          <DateTimePicker
            value={f.value}
            onChange={onChange}
            {...f.props}
            inputProps={{
              id: `${f.id}_input`,
              placeholder: f.placeholder,
              disabled: f.disabled || f.readOnly || !!f.premiumLock,
              required: f.required,
              onKeyDown: e => {
                e.preventDefault();
                return false;
              },
              ...getFrom(f)('props')('inputProps').value,
            }}
          />
        );
      case 'date':
        return (
          <DateTimePicker
            timeFormat={false}
            value={f.value}
            onChange={onChange}
            {...f.props}
            inputProps={{
              id: `${f.id}_input`,
              placeholder: f.placeholder,
              disabled: f.disabled || f.readOnly || !!f.premiumLock,
              required: f.required,
              onKeyDown: e => {
                e.preventDefault();
                return false;
              },
              ...getFrom(f)('props')('inputProps').value,
            }}
          />
        );
      case 'time':
        return (
          <DateTimePicker
            dateFormat={false}
            value={f.value}
            onChange={onChange}
            {...f.props}
            inputProps={{
              id: `${f.id}_input`,
              placeholder: f.placeholder,
              disabled: f.disabled || f.readOnly || !!f.premiumLock,
              required: f.required,
              onKeyDown: e => {
                e.preventDefault();
                return false;
              },
              ...getFrom(f)('props')('inputProps').value,
            }}
          />
        );
      case 'image':
        return (
          <div className={classes.dropWrapper}>
            <Dropzone
              onDrop={files => {
                const file = files && files[0];
                if (files && file) {
                  const reader = new FileReader();
                  reader.onload = () => onChange(reader.result);
                  reader.readAsDataURL(file);
                }
              }}
              multiple={false}
              accept="image/jpeg,image/jpg,image/png"
              {...f.dropzoneOptions}
              {...f.props}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: classes.dropZone })}>
                  <input {...getInputProps()} />
                  {f.value ? (
                    <img src={f.value} alt={f.alt} className={classes.dropImage} />
                  ) : (
                    <div className={classes.dropPrompt}>
                      <span>Drop image or click here to upload</span>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
            <a className={classes.dropClear} onClick={() => onChange(null)}>
              Clear
            </a>
          </div>
        );
      default:
        return <span>{String(f['value']) || '\u00a0'}</span>;
    }
  }
}
