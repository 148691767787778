import { ApiField, ApiGroup } from '@hyperfish/antrea-api-contracts/src/fields';
import setToValue from '@hyperfish/fishfood/lib/utils/SetUtil';

import { FieldSchemaState, GlobalState } from '../../../models/client';
import * as types from './types';
import { ApiClient } from '@hyperfish/fishfood';

const ENDPOINT_BASE = '/field-schemas/default';

const initialState: FieldSchemaState = {
  fieldUpdating: {},
  fieldUpdatingError: {},
  fieldDeleting: {},
  fieldDeletingError: {},
  dirtyGroups: { groups: {} },
} as FieldSchemaState;

export default function reducer(state: FieldSchemaState = initialState, action): FieldSchemaState {
  switch (action.type) {
    case types.LOAD:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
        error: null,
      };
    case types.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error,
      };

    case types.LOAD_FIELDS:
      return {
        ...state,
        fieldsLoading: true,
      };
    case types.LOAD_FIELDS_SUCCESS:
      return {
        ...state,
        fieldsLoading: false,
        fields: action.result.fields,
        fieldsError: null,
      };
    case types.LOAD_FIELDS_FAIL:
      return {
        ...state,
        fieldsLoading: false,
        fields: null,
        fieldsError: action.error,
      };

    case types.FIELD_EDIT_START:
      return {
        ...state,
        dirtyField:
          action.id && state.data && state.data.fields && state.data.fields[action.id]
            ? JSON.parse(JSON.stringify(state.data.fields[action.id]))
            : null,
        fieldDirty: false,
      };
    case types.FIELD_CREATE_START:
      const initialHyperBootSlider = action.readOnlyMode
        ? {
            hidden: false,
            readonly: true,
            notify: false,
          }
        : {
            hidden: false,
            readonly: false,
            notify: true,
          };

      return {
        ...state,
        dirtyField: {
          internal: false,
          isCustom: true,
          property: '',
          type: 'string',
          audienceConfigs: {
            [action.orgId]: {
              audienceId: action.orgId,
              approvalRequired: undefined,
              notify: initialHyperBootSlider.notify,
              shouldAudit: true,
              format: {
                constraints: [],
                meta: { ui: { component: 'text' } },
                type: 'string',
              },
              required: false,
              ui: {
                title: '',
                hintText: '',
                component: 'default',
                readonly: initialHyperBootSlider.readonly,
                description: null,
                hidden: initialHyperBootSlider.hidden,
                hideFromPublic: false,
              },
            },
          },
        },
      };
    case types.FIELD_EDIT:
      return {
        ...state,
        dirtyField: setToValue(state.dirtyField, action.path, action.val),
        fieldDirty: true,
      };
    case types.FIELD_EDIT_CONFIG:
      return {
        ...state,
        fieldDirty: true,
        dirtyField: {
          ...state.dirtyField,
          audienceConfigs: {
            ...state.dirtyField.audienceConfigs,
            [action.audienceId]: setToValue(
              state.dirtyField.audienceConfigs[action.audienceId],
              action.path,
              action.val,
            ),
          },
        },
      };
    case types.FIELD_EDIT_OVERRIDE:
      return {
        ...state,
        fieldDirty: true,
        dirtyField: {
          ...state.dirtyField,
          audienceConfigs: {
            ...state.dirtyField.audienceConfigs,
            [action.audienceId]: action.override
              ? {
                  ...state.dirtyField.audienceConfigs[action.orgId],
                  audienceId: action.audienceId,
                }
              : null,
          },
        },
      };
    case types.FIELD_CREATE:
      return {
        ...state,
        fieldCreating: true,
        fieldCreatingError: null,
      };
    case types.FIELD_CREATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          fields: {
            ...state.data.fields,
            [action.result.id]: action.result,
          },
        },
        dirtyField: null,
        fieldCreating: false,
        fieldDirty: false,
      };
    case types.FIELD_CREATE_FAIL:
      return {
        ...state,
        fieldCreating: false,
        fieldCreatingError: action.error,
      };
    case types.FIELD_UPDATE:
      return {
        ...state,
        fieldUpdating: { ...state.fieldUpdating, [action.id]: true },
        fieldUpdatingError: { ...state.fieldUpdatingError, [action.id]: null },
      };
    case types.FIELD_UPDATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          fields: {
            ...state.data.fields,
            [action.id]: {
              ...action.result,
              audienceConfigs: {
                ...state.data.fields[action.id].audienceConfigs,
                [action.audienceId]: action.result.audienceConfigs[action.audienceId],
              },
            },
          },
        },
        dirtyField: null,
        fieldDirty: false,
        fieldUpdating: { ...state.fieldUpdating, [action.id]: false },
      };
    case types.FIELD_UPDATE_FAIL:
      return {
        ...state,
        fieldUpdating: { ...state.fieldUpdating, [action.id]: false },
        fieldUpdatingError: { ...state.fieldUpdatingError, [action.id]: action.error },
      };
    case types.FIELD_DELETE_START:
      return {
        ...state,
        fieldDeletePromptId: action.id,
      };
    case types.FIELD_DELETE_CANCEL:
      return {
        ...state,
        fieldDeletePromptId: null,
      };
    case types.FIELD_DELETE:
      return {
        ...state,
        fieldDeleting: { ...state.fieldDeleting, [action.id]: true },
        fieldDeletingError: { ...state.fieldDeletingError, [action.id]: null },
      };
    case types.FIELD_DELETE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          fields: {
            ...state.data.fields,
            [action.id]: null,
          },
        },
        fieldDeleting: { ...state.fieldDeleting, [action.id]: false },
      };
    case types.FIELD_DELETE_FAIL:
      return {
        ...state,
        fieldDeleting: { ...state.fieldDeleting, [action.id]: false },
        fieldDeletingError: { ...state.fieldDeletingError, [action.id]: action.error },
      };

    case types.GROUP_EDIT_START:
      return {
        ...state,
        dirtyGroup: action.group ? JSON.parse(JSON.stringify(action.group)) : null,
      };
    case types.GROUP_CREATE_START:
      return {
        ...state,
        dirtyGroup: {
          component: 'default',
          description: null,
          fields: [],
          meta: {},
          title: '',
        },
      };
    case types.GROUP_EDIT:
      return {
        ...state,
        dirtyGroup: setToValue(state.dirtyGroup, action.path, action.val),
        groupsDirty: true,
      };
    case types.GROUP_DELETE_START:
      return {
        ...state,
        groupDeletePromptIndex: action.index,
      };
    case types.GROUP_DELETE_CANCEL:
      return {
        ...state,
        groupDeletePromptIndex: null,
      };
    case types.GROUPS_UPDATE:
      return {
        ...state,
        groupsUpdating: true,
        groupsUpdatingError: null,
        dirtyGroups: {
          groups: {
            [action.audienceId]: action.groups,
          },
        },
      };
    case types.GROUPS_UPDATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          groups: {
            ...state.data.groups,
            ...action.result.groups,
            [action.audienceId]: action.result.groups[action.audienceId],
          },
        },
        dirtyGroups: initialState.dirtyGroups,
        dirtyGroup: null,
        groupsDirty: false,
        groupsUpdating: false,
      };
    case types.GROUPS_UPDATE_FAIL:
      return {
        ...state,
        dirtyGroups: initialState.dirtyGroups,
        groupsUpdating: false,
        groupsUpdatingError: action.error,
      };

    case types.SEARCH_FIELDS:
      return {
        ...state,
        fieldNamesError: null,
        fieldNamesLoading: true,
      };
    case types.SEARCH_FIELDS_SUCCESS:
      return {
        ...state,
        fieldNames: action.result.results,
        requiresAdminReconsent: action.result.requiresAdminReconsent,
        fieldNamesLoading: false,
      };
    case types.SEARCH_FIELDS_FAIL:
      return {
        ...state,
        fieldNamesError: action.error,
        fieldNamesLoading: false,
      };
    case types.SEARCH_FIELDS_CLEAR:
      return {
        ...state,
        fieldNames: null,
      };

    default:
      return state;
  }
}

// UTILITY FUNCTIONS
export function isLoaded({ fieldSchema }: GlobalState) {
  return fieldSchema && fieldSchema.data;
}

export function shouldLoad({ fieldSchema }: GlobalState) {
  return fieldSchema && !fieldSchema.loading;
}

// FULL SCHEMA OPS
export function load() {
  return {
    types: [types.LOAD, types.LOAD_SUCCESS, types.LOAD_FAIL],
    promise: (client: ApiClient) => client.get(`${ENDPOINT_BASE}/combined`),
  };
}

// FULL SCHEMA OPS
export function loadFields() {
  return {
    types: [types.LOAD_FIELDS, types.LOAD_FIELDS_SUCCESS, types.LOAD_FIELDS_FAIL],
    promise: (client: ApiClient) => client.get(`${ENDPOINT_BASE}/field-names`),
  };
}

// FIELDS CRUD
export function editFieldStart(id: string) {
  return {
    type: types.FIELD_EDIT_START,
    id,
  };
}

export function createFieldStart(orgId: string, readOnlyMode: boolean) {
  return { type: types.FIELD_CREATE_START, orgId, readOnlyMode };
}

export function editField(path: string, val: any) {
  return {
    type: types.FIELD_EDIT,
    path,
    val,
  };
}

export function editFieldConfig(audienceId: string, path: string, val: any) {
  return {
    type: types.FIELD_EDIT_CONFIG,
    audienceId,
    path,
    val,
  };
}

export function editFieldOverride(audienceId: string, orgId: string, override: boolean) {
  return {
    type: types.FIELD_EDIT_OVERRIDE,
    audienceId,
    orgId,
    override,
  };
}

export function createField(data: Partial<ApiField>) {
  return {
    types: [types.FIELD_CREATE, types.FIELD_CREATE_SUCCESS, types.FIELD_CREATE_FAIL],
    promise: (client: ApiClient) => client.post(`${ENDPOINT_BASE}/fields`, { data }),
    notifications: {
      success: 'Field created successfully.',
      fail: 'Failed to create field.',
    },
  };
}

export function updateField(audienceId: string, data: Partial<ApiField>) {
  return {
    types: [types.FIELD_UPDATE, types.FIELD_UPDATE_SUCCESS, types.FIELD_UPDATE_FAIL],
    promise: (client: ApiClient) => client.patch(`${ENDPOINT_BASE}/fields/${data.id}`, { data }),
    id: data.id,
    audienceId,
    notifications: {
      success: 'Field updated successfuly.',
      fail: 'Failed to update field.',
    },
  };
}

export function deleteFieldPrompt(id: string) {
  return {
    type: types.FIELD_DELETE_START,
    id,
  };
}

export function deleteFieldCancel() {
  return {
    type: types.FIELD_DELETE_CANCEL,
  };
}

export function deleteField(field: ApiField | string) {
  const id = typeof field === 'string' ? field : field.id;
  return {
    types: [types.FIELD_DELETE, types.FIELD_DELETE_SUCCESS, types.FIELD_DELETE_FAIL],
    promise: (client: ApiClient) => client.del(`${ENDPOINT_BASE}/fields/${id}`),
    id,
    notifications: {
      success: 'Field deleted successfully.',
      fail: 'Failed to delete field.',
    },
  };
}

// GROUPS CRUD
export function editGroupStart(group: ApiGroup) {
  return {
    type: types.GROUP_EDIT_START,
    group,
  };
}

export function createGroupStart() {
  return { type: types.GROUP_CREATE_START };
}

export function deleteGroupPrompt(index: number) {
  return {
    type: types.GROUP_DELETE_START,
    index,
  };
}

export function deleteGroupCancel() {
  return {
    type: types.GROUP_DELETE_CANCEL,
  };
}

export function editGroup(path: string, val: any) {
  return {
    type: types.GROUP_EDIT,
    path,
    val,
  };
}

export function updateGroups(audienceId: string, groups: Partial<ApiGroup>[]) {
  return {
    types: [types.GROUPS_UPDATE, types.GROUPS_UPDATE_SUCCESS, types.GROUPS_UPDATE_FAIL],
    audienceId,
    groups,
    promise: (client: ApiClient) =>
      client.patch(`${ENDPOINT_BASE}/groups/in-order`, {
        data: {
          groups: {
            [audienceId]: groups,
          },
        },
      }),
    notifications: {
      success: 'Groups updated successfully.',
      fail: 'Failed to update groups.',
    },
  };
}

// FIELDS SEARCH
export function searchFields(params?: { q: string }) {
  return {
    types: [types.SEARCH_FIELDS, types.SEARCH_FIELDS_SUCCESS, types.SEARCH_FIELDS_FAIL],
    promise: (client: ApiClient) => client.get(`${ENDPOINT_BASE}/searchable-field-names`, { params }),
  };
}

export function clearFields() {
  return {
    type: types.SEARCH_FIELDS_CLEAR,
  };
}
