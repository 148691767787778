import { PremiumModalsState } from '../../../models/client/';
import { Action } from './actions';
import * as types from './types';

const initialState = ({} as any) as PremiumModalsState;

export default function reducer(state: PremiumModalsState = initialState, action: Action): PremiumModalsState {
  switch (action.type) {
    case types.SHOW:
      return {
        ...state,
        activeModal: action.activeModal,
      };
    case types.HIDE:
      return {
        ...state,
        activeModal: null,
      };
    default:
      return state;
  }
}

// ACTION CREATORS
export function show(activeModal: PremiumModalsState['activeModal']) {
  return {
    type: types.SHOW,
    activeModal,
  };
}

export function hide() {
  return {
    type: types.HIDE,
  };
}
