import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../../../../components';
import classes from './styles.module.scss';
import { Card } from '@hyperfish/fishfood';

interface Props {
  bg: string;
  children?: any;
  color: string;
  icon: string;
  style?: React.CSSProperties;
  to?: string;
  width?: string;
}

export const NotificationCard = (props: Props) => {
  const card = (
    <Card
      className={cx(classes.card, { [classes.container]: !props.to })}
      style={{
        backgroundImage: `url(${props.bg})`,
        width: props.width,
        ...props.style,
      }}
    >
      <span className={classes.icon} style={{ backgroundColor: props.color }}>
        <Icon name={props.icon} />
      </span>
      {props.children}
    </Card>
  );

  if (props.to) {
    return (
      <Link to={props.to} className={classes.container}>
        {card}
      </Link>
    );
  }

  return card;
};
