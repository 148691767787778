import { Modal } from '@hyperfish/fishfood';
import React from 'react';

import { hide } from '../../redux/modules/premiumModals';
import { Button } from '../Button';
import { modalContent } from './modalContent';
import classes from './styles.module.scss';

type keyObject<T> = { [P in keyof T]: P };

export type ModalKeys = keyof typeof modalContent;
export const ModalKeys = Object.keys(modalContent).reduce((p, c) => ({ ...p, [c]: c }), {}) as keyObject<
  typeof modalContent
>;

interface Props {
  activeModal: ModalKeys;
  hideModal: typeof hide;
}

export const PremiumModal = ({ activeModal, hideModal }: Props) =>
  !modalContent[activeModal] ? null : (
    <Modal onClose={hideModal} style={{ paddingTop: 60 }}>
      <div className={classes.premiumModal}>{modalContent[activeModal]}</div>
      <Modal.ButtonContainer>
        <a
          className={classes.learnMoreLink}
          href="https://www.hyperfish.com/product"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about additional features
        </a>
        <Button size="medium" to="/upgrade" type="primary" onClick={hideModal}>
          UPGRADE
        </Button>
      </Modal.ButtonContainer>
    </Modal>
  );
