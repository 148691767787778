/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { LoadingSplash } from '@hyperfish/fishfood/lib/components/LoadingSplash';
import { Toggle } from '@hyperfish/fishfood/lib/components/Toggle';
import { themeColor } from '@hyperfish/fishfood/lib/utils/ThemeUtil';
import { getFrom } from '@hyperfish/fishfood/lib/utils/GetUtil';
import { SettingCard } from '../../../components';
import { SettingsProps } from '../../../layouts/SettingsLayout';
import { OrgSettingsKeys as keys } from '../../../models/api';
import { createNotification } from '../../../redux/modules/notifications';
import { DirectoryForm } from './Directory/DirectoryForm';
import { OrgForm } from './OrgChart/OrgForm';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: flex;

  > span {
    align-self: center;
    margin-right: 10px;
    margin-top: 10px;
    color: ${themeColor('textMedium')};
  }
`;

const connector = connect(
  state => ({}),
  {
    createNotification,
  },
);

type Props = typeof connector['props'] & SettingsProps;
type WebpartsConfigDirectory = Partial<{ enabled: boolean }>;
type WebpartsConfigOrgChart = Partial<{
  enabled: boolean;
  rootUser: { label: string; value: string };
  useVirtualRoot: boolean;
}>;

@connector
export class OrgDirectory extends React.Component<Props> {
  componentDidMount() {
    const { loadScopedSettings } = this.props;

    loadScopedSettings([keys.webparts.config.directory, keys.webparts.config.orgchart]);
  }

  componentWillUnmount() {
    this.props.handleCancel();
  }

  handleCopy(token: string) {
    const { createNotification } = this.props;
    if (document.queryCommandSupported('copy')) {
      const dummyNode = document.createElement('input');
      document.body.appendChild(dummyNode);
      dummyNode.setAttribute('value', token);
      dummyNode.select();
      document.execCommand('copy');
      document.body.removeChild(dummyNode);
      createNotification({ message: 'Token copied to clipboard!' });
    }
  }

  render() {
    const { currentOrg, currentOrgLoading, settings, settingsLoading, fieldSchema, licenseWebparts, save } = this.props;

    if (!licenseWebparts) {
      return <Redirect to={'/settings/general'} />;
    }

    const directoryConfig: WebpartsConfigDirectory = getFrom(settings)('webparts_config_directory').value;
    const orgchartConfig: WebpartsConfigOrgChart = getFrom(settings)('webparts_config_orgchart').value;

    const directoryDisabled = getFrom(directoryConfig)('enabled').value === false;
    const orgchartDisabled = getFrom(orgchartConfig)('enabled').value === false;
    const useVirtualRoot = getFrom(orgchartConfig)('useVirtualRoot').defaultTo(false);
    const rootUserId = !useVirtualRoot ? getFrom(orgchartConfig)('rootUser')('value').value : null;

    return (
      <div className="view-settings-Hyperbot">
        {!settings || settingsLoading || currentOrgLoading ? (
          <LoadingSplash />
        ) : (
          <div>
            <SettingCard
              title="Directory Settings"
              subTitle={
                !directoryDisabled ? (
                  <>
                    Customize your Directory page look and feel. See the results{' '}
                    <a onClick={() => this.props.history.push('/directory')}>here</a>.
                  </>
                ) : (
                  <>Customize your Directory page look and feel.</>
                )
              }
            >
              <span>Enable/disable access to the Directory page for your org</span>
              <StyledLabel>
                <Toggle
                  onChange={event =>
                    save(currentOrg.id, {
                      webparts_config_directory: {
                        ...settings.webparts_config_directory,
                        enabled: event.target.checked,
                      },
                    })
                  }
                  checked={!directoryDisabled}
                />
                <span>{directoryDisabled ? 'disabled' : 'enabled'}</span>
              </StyledLabel>
              {!directoryDisabled && (
                <DirectoryForm
                  collapsable={true}
                  settings={settings}
                  schema={fieldSchema}
                  currentOrgId={currentOrg.id}
                  save={this.props.save}
                />
              )}
            </SettingCard>
            <SettingCard
              title="Charts Settings"
              subTitle={
                !orgchartDisabled ? (
                  <>
                    Customize your Charts page look and feel.
                    <br />
                    See the results on: <br />
                    <a target="_blank" onClick={() => this.props.history.push('/orgchart')}>
                      Org Chart
                    </a>{' '}
                    <br />
                    <a target="_blank" onClick={() => this.props.history.push('/relationshipchart')}>
                      Relationship Chart
                    </a>
                  </>
                ) : (
                  <>Customize your Org Chart page look and feel.</>
                )
              }
            >
              <StyledLabel>
                <Toggle
                  onChange={event =>
                    save(currentOrg.id, {
                      webparts_config_orgchart: { ...settings.webparts_config_orgchart, enabled: event.target.checked },
                    })
                  }
                  checked={!orgchartDisabled}
                />
                <span>Enable access to the Charts page for your org</span>
              </StyledLabel>
              <br />
              {!orgchartDisabled && (
                <div>
                  <OrgForm
                    collapsable={true}
                    settings={settings}
                    schema={fieldSchema}
                    currentOrgId={currentOrg.id}
                    save={this.props.save}
                    settingsFor={'chart'}
                  />
                </div>
              )}
            </SettingCard>
          </div>
        )}
      </div>
    );
  }
}

export default OrgDirectory;
