import {
  CreateLocalizationRequest,
  LocalizationValueResponse,
  UpdateLocalizationRequest,
} from '@hyperfish/antrea-api-contracts/src/localization';
import { ApiClient } from '@hyperfish/fishfood/lib/utils/ApiClient';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import { AxiosResponse } from 'axios';
import { GlobalState } from '../../models/client/';

const LOAD = 'antrea/localizations/LOAD';
const LOAD_SUCCESS = 'antrea/localizations/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/localizations/LOAD_FAIL';
const UPDATE = 'antrea/localizations/UPDATE';
const UPDATE_SUCCESS = 'antrea/localizations/UPDATE_SUCCESS';
const UPDATE_FAIL = 'antrea/localizations/UPDATE_FAIL';
const EDIT = 'antrea/localizations/EDIT';

type State = GlobalState['localizations'];

const initialState: State = {
  dataByTargetKey: {},
  dirty: false,
  dirtyData: null,
  error: null,
  loaded: false,
  loading: false,
  updating: false,
  updated: false,
  updateError: null,
} as State;

export enum Action {
  create,
  update,
  delete,
}
export type LocalizationValue = Partial<LocalizationValueResponse> & { action?: Action };

export default (state: State = initialState, action): State => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        updated: false,
      };
    case LOAD_SUCCESS:
      let dict = {};
      const result: LocalizationValueResponse[] = action.result || [];
      const dataByAudienceId: LocalizationValueResponse[] = result.filter(
        item => item.audienceId === action.audienceId,
      );
      dataByAudienceId.forEach(r => {
        const targetKey = r.targetKey;
        let localizations = dict[targetKey] || [];
        localizations.push(r);
        dict[targetKey] = localizations;
      });

      return {
        ...state,
        dirty: false,
        loading: false,
        loaded: true,
        dataByTargetKey: dict,
      };
    case LOAD_FAIL:
      return {
        ...state,
        dataByTargetKey: null,
        error: action.error,
        loaded: false,
        loading: false,
      };
    case UPDATE:
      return {
        ...state,
        updating: true,
        loading: false,
        updated: false,
        loaded: false,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        updated: true,
        loaded: false,
        updating: false,
        loading: false,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    case EDIT:
      const { index, targetKey, value } = action;
      let values: LocalizationValue[] = state.dataByTargetKey[targetKey] || [];
      switch (value.action) {
        case Action.create:
          if (index === null) {
            values.push(value);
          } else {
            values[index] = value;
          }
          return {
            ...state,
            dataByTargetKey: {
              ...state.dataByTargetKey,
              [targetKey]: values,
            },
            dirty: values.some(v => v.hasOwnProperty('action')),
          };
        case Action.update:
          values[index] = value;
          return {
            ...state,
            dataByTargetKey: {
              ...state.dataByTargetKey,
              [targetKey]: values,
            },
            dirty: values.some(v => v.hasOwnProperty('action')),
          };
        case Action.delete:
          if (index === null) {
            values.push(value);
          } else if (!!value.id) {
            values[index] = value;
          } else {
            values.splice(index, 1);
          }
          return {
            ...state,
            dataByTargetKey: {
              ...state.dataByTargetKey,
              [targetKey]: values,
            },
            dirty: values.some(v => v.hasOwnProperty('action')),
          };
        default:
          return {
            ...state,
          };
      }
    default:
      return state;
  }
};

const bulkUpdate = (client: ApiClient, values: Partial<LocalizationValue>[]) => {
  const isValid = (change: LocalizationValue): boolean => {
    const valid =
      change.hasOwnProperty('action') && !!getFrom(change)('locale').value && !!getFrom(change)('value').value;
    if (!valid) {
      console.warn('invalid localization data, skipping...', change);
    }
    return valid;
  };

  let promises: Promise<AxiosResponse<any>>[] = [];

  const changes = values.filter(v => v.hasOwnProperty('action'));
  changes.forEach((change: LocalizationValue) => {
    if (!isValid(change)) {
      return;
    }
    switch (change.action) {
      case Action.create:
        const createLocalizationRequest: CreateLocalizationRequest = {
          audienceId: change.audienceId,
          category: change.category,
          locale: change.locale,
          targetKey: change.targetKey,
          value: change.value,
        };
        promises.push(client.post(`/localization`, { data: createLocalizationRequest }));
        break;
      case Action.update:
        const updateLocalizationRequest: UpdateLocalizationRequest = {
          value: change.value,
        };
        !!change.id && promises.push(client.patch(`/localization/${change.id}`, { data: updateLocalizationRequest }));
        break;
      case Action.delete:
        !!change.id && promises.push(client.del(`/localization/${change.id}`));
        break;
      default:
      /* no-op */
    }
  });

  return Promise.all(promises);
};

export const load = (audienceId: string) => {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/localization'),
    audienceId,
    notifications: {
      fail: 'Failed to load languages.',
    },
  };
};

export const edit = (targetKey: string, value: Partial<LocalizationValue>, index: number) => {
  return {
    type: EDIT,
    targetKey,
    value,
    index,
  };
};

export const update = (targetKey: string, values: Partial<LocalizationValue>[]) => {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client: ApiClient) => bulkUpdate(client, values),
    targetKey,
    notifications: {
      success: 'Languages successfully updated.',
      fail: 'Failed to update languages.',
    },
  };
};

export const getDataByTargetKey = (dataByTargetKey: State['dataByTargetKey'], targetKey: string) => {
  return dataByTargetKey ? dataByTargetKey[targetKey] || [] : [];
};

export const getDefaultLocale = ({ orgs, settings }) => {
  return (settings.data && orgs.current && settings.data[orgs.current.id].locale_default) || 'en-US';
};
