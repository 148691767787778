import {
  Button,
  ButtonContainer,
  Card,
  FiThumbsDown,
  FiThumbsUp,
  themeColor,
  Tooltip,
  FiUserCheck,
} from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { DataTable } from '../../components/DataTable';
import { Icon } from '../../components/Icon';
import { Tabs } from '../../components/Tabs';
import { OrgSettingsKeys } from '../../models/api';
import { loadUserBranding } from '../../redux/modules/branding';
import { bulkUpdate, CHANGE_STATUS, load as loadChanges } from '../../redux/modules/changes';
import { createNotification } from '../../redux/modules/notifications';
import { approveActions as uiActions } from '../../redux/modules/ui';
import { ApproveWizard } from './components/ApproveWizard';
import StyleUtil from '../../utils/StyleUtil';
import { load as loadSettings } from '../../redux/modules/settings';

const ChangeTable = DataTable.of({
  user: { label: 'User' },
  attribute: { label: 'Attribute' },
  oldValue: { label: 'Old Value', type: DataTable.types.custom },
  newValue: { label: 'New Value', type: DataTable.types.custom },
  createdAt: { label: 'Date & Time', sortable: true, type: DataTable.types.datetime },
  status: { hidden: true },
});

const ApproveIcon = styled(FiThumbsUp)`
  color: ${themeColor('success')};
`;

const RejectIcon = styled(FiThumbsDown)`
  color: ${themeColor('error')};
`;

const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditedByIcon = styled(FiUserCheck)`
  color: ${themeColor('accent')};
`;

const connector = connect(
  state => ({
    changes: state.changes.changes,
    changeSaving: state.changes.saving,
    changesById: state.changes.changesById,
    currentOrg: state.orgs.current,
    error: state.changes.error,
    filter: state.changes.filter,
    group: state.ui.approve.group,
    isAnalyze:
      state.orgs.current &&
      state.orgs.current.settings[state.orgs.current.id][OrgSettingsKeys.global.mode] === 'Analyze',
    isDisconnected: state.orgs.current && !state.orgs.current.connected,
    isNotAuthed:
      state.orgs.current &&
      state.orgs.current.type === 'Online' &&
      state.orgs.current.settings[state.orgs.current.id].auth_providers_microsoftGraph_adminConsent === false,
    loading: state.changes.loading,
    relatedEntities: state.changes.relatedEntities,
    shouldHideFailed: state.ui.approve.hideFailed,
    userName:
      !!state.self.externalUsers &&
      !state.self.loadingExternal &&
      ((state.self.externalUsers.profileType === 'msGraph'
        ? state.self.externalUsers.profile.givenName
        : state.self.externalUsers.profile.givenname) ||
        (state.self.user && state.self.user.displayName)),
  }),
  {
    ...uiActions,
    bulkUpdate,
    createNotification,
    loadChanges,
    loadUserBranding,
    loadSettings,
  },
);

interface State {
  windowWidth: number;
  selected: string[];
}

const styles = StyleUtil({
  header: StyleUtil.styles.tables.header,
  trFailed: StyleUtil.styles.tables.tr_invalid,
  actionButtonGroup: {
    float: 'right',
  },
  //
  failedText: {
    color: '#f82a2a',
    cursor: 'pointer',
    // textDecoration: 'underline',
    display: 'inline-block',
    marginBottom: 10,
    fontSize: 14,
  },
  actionLink: {
    color: StyleUtil.colors.blue,
    cursor: 'pointer',
    fontSize: 14,
    marginLeft: 10,
    textDecoration: 'underline',
    verticalAlign: 'middle',
  },
  valueImg: {
    width: 75,
    height: 75,
    verticalAlign: 'middle',
  },
});

@connector
@Radium
export default class Approve extends React.Component<typeof connector['props'], State> {
  private select = (ids: string | string[]) => {
    const newSelected = this.state.selected.slice();
    ids = Array.isArray(ids) ? ids : [ids];

    for (const id of ids) {
      if (newSelected.indexOf(id) === -1) {
        newSelected.push(id);
      }
    }

    this.setState({ selected: newSelected });
  };

  private deselect = (ids: string | string[]) => {
    const newSelected = this.state.selected.slice();
    ids = Array.isArray(ids) ? ids : [ids];

    for (const id of ids) {
      if (newSelected.indexOf(id) > -1) {
        newSelected.splice(newSelected.indexOf(id), 1);
      }
    }

    this.setState({ selected: newSelected });
  };

  constructor(props) {
    super(props);
    this.state = { windowWidth: 0, selected: [] };
  }

  UNSAFE_componentWillMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    const { loadChanges, loading, loadUserBranding } = this.props;
    if (!loading) {
      loadChanges({ statuses: 'pending,failed' });
    }
    loadUserBranding('me');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const windowWidth = window.innerWidth;
    window.requestAnimationFrame(() => this.setState({ windowWidth }));
  };

  UNSAFE_componentWillReceiveProps(nextProps: typeof connector['props']) {
    const changesToRender = this.getChangesToRender(nextProps);

    // deselect any unrendered changes to avoid unexpected bulk results
    if (changesToRender) {
      const unrenderedSelectedChanges = this.state.selected.filter(
        id => changesToRender.filter(c => c.id === id).length === 0,
      );
      if (unrenderedSelectedChanges.length > 0) {
        this.deselect(unrenderedSelectedChanges);
      }
    }

    if (this.props.changes && nextProps.changes) {
      this.props.changes.forEach(id => {
        if (!this.props.changesById[id] || !nextProps.changesById[id]) {
          return;
        }
        if (!this.props.changesById[id].error && !!nextProps.changesById[id].error) {
          nextProps.createNotification({
            type: 'error',
            message: 'Failed to update change status.',
          });
        }
      });
    }
  }

  getChangesToRender(props?: typeof connector['props']) {
    const { changes, changesById, shouldHideFailed } = props || this.props;

    const statusesToRender = shouldHideFailed ? [CHANGE_STATUS.PENDING] : [CHANGE_STATUS.PENDING, CHANGE_STATUS.FAILED];

    return changes && changes.map(c => changesById[c]).filter(c => statusesToRender.indexOf(c.status) > -1);
  }

  getFailedChanges() {
    const { changes, changesById } = this.props;

    return changes ? changes.filter(c => changesById[c].status === CHANGE_STATUS.FAILED) : [];
  }

  getUserName = (c: typeof connector['props']['changesById']['foo']) => {
    const { relatedEntities } = this.props;
    return relatedEntities && relatedEntities.externalEntities && relatedEntities.externalEntities[c.entityId]
      ? relatedEntities.externalEntities[c.entityId].displayName || relatedEntities.externalEntities[c.entityId].mail
      : c.entityId || '[Unknown]';
  };

  getDelegateName = (c: typeof connector['props']['changesById']['foo']) => {
    const { relatedEntities } = this.props;
    return relatedEntities && relatedEntities.users && relatedEntities.users[c.userId]
      ? relatedEntities.users[c.userId].displayName || relatedEntities.users[c.userId].mail
      : c.userId || '[Unknown]';
  };

  getAttributeName = (c: typeof connector['props']['changesById']['foo']) => {
    const { relatedEntities, currentOrg } = this.props;
    return (
      relatedEntities.audiences[c.audienceId].fields[c.fieldId].title ||
      relatedEntities.audiences[currentOrg && currentOrg.id].fields[c.fieldId].title ||
      relatedEntities.audiences[currentOrg && currentOrg.id].fields[c.fieldId].fieldName
    );
  };

  getDisplayValues = (c: typeof connector['props']['changesById']['foo']) => {
    const { relatedEntities } = this.props;
    let oldValue, newValue;

    switch (c.type) {
      case 'person':
        oldValue = c.oldValue
          ? relatedEntities.managers[c.oldValue]
            ? relatedEntities.managers[c.oldValue].displayName || relatedEntities.managers[c.oldValue].mail
            : '[Unknown User]'
          : 'No Manager';
        newValue = c.newValue
          ? relatedEntities.managers[c.newValue]
            ? relatedEntities.managers[c.newValue].displayName || relatedEntities.managers[c.newValue].mail
            : '[Unknown User]'
          : 'No Manager';
        break;
      case 'image':
        oldValue = !c.oldValue.content ? (
          'No Picture'
        ) : (
          <img src={c.oldValue.content} style={styles.valueImg} alt="Old Profile Picture" />
        );
        newValue = !c.newValue.content ? (
          'No Picture'
        ) : (
          <img src={c.newValue.content} style={styles.valueImg} alt="New Profile Picture" />
        );
        break;
      default:
        oldValue = c.oldValue ? String(c.oldValue) : c.oldValue;
        newValue = c.newValue ? String(c.newValue) : c.newValue;
        break;
    }

    return { oldValue, newValue };
  };

  render() {
    const { isAnalyze, isDisconnected, location, userName, isNotAuthed } = this.props;

    if (isAnalyze) {
      return (
        <div className="view-approve">
          <Card>
            <h2>I&apos;m sorry, {userName || 'Dave'}. I&apos;m afraid I can&apos;t do that.</h2>
            <p>Your organization is currently in analyze mode.</p>
            <p>
              While in analyze mode, LiveTiles Directory does not make any updates to your directory, so change approval
              functionality is disabled.
            </p>
          </Card>
        </div>
      );
    }

    if (isDisconnected) {
      return (
        <div className="view-approve">
          <Card>
            <h2>I&apos;m sorry, {userName || 'Dave'}. I&apos;m afraid I can&apos;t do that.</h2>
            <p>LiveTiles Directory is currently disconnected from your directory.</p>
            <p>Profile update approval is disabled until the directory connection is reestablished.</p>
            <p>
              Your LiveTiles Directory administrator will need to reconnect your directory before you can approve
              profile updates.
            </p>
          </Card>
        </div>
      );
    }

    if (this.state.windowWidth < 800 && location.pathname.indexOf('/self') === 0) {
      return (
        <div className="view-approve">
          <ApproveWizard
            bulkUpdate={this.props.bulkUpdate}
            changes={this.props.changes}
            changesById={this.props.changesById}
            saving={this.props.changeSaving}
            getUserName={this.getUserName}
            getAttributeName={this.getAttributeName}
            getDisplayValues={this.getDisplayValues}
            // pushRoute={this.props.history.push}
            search={this.props.location.search}
          />
        </div>
      );
    }

    return (
      <div className="view-approve">
        {this.renderFailedMessage()}
        {this.renderHeader()}
        <Card noPadding={true}>{this.renderChanges()}</Card>
        {isNotAuthed && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <h2>
              <Icon name="link-broken" style={{ color: StyleUtil.colors.red }} />
              <span>LiveTiles Directory needs authorization</span>
            </h2>
            <p style={{ maxWidth: 400, display: 'inline-block' }}>
              Profile updates cannot be approved until LiveTiles Directory is authorized to write updates back to your
              directory.
            </p>
          </div>
        )}
      </div>
    );
  }

  renderFailedMessage() {
    const { shouldHideFailed, hideFailed } = this.props;
    const failedChanges = this.getFailedChanges();

    if (failedChanges.length === 0) {
      return;
    }

    return (
      <div>
        <span style={styles.failedText}>
          <Icon name="warning" />
          <span>
            {`${failedChanges.length} change${failedChanges.length > 1 ? 's have' : ' has'} failed to be applied.`}
          </span>
        </span>
        {shouldHideFailed ? (
          <a style={styles.actionLink} onClick={() => hideFailed(false)}>
            Show
          </a>
        ) : (
          <a style={styles.actionLink} onClick={() => hideFailed(true)}>
            Hide
          </a>
        )}
      </div>
    );
  }

  renderHeader() {
    const { bulkUpdate, setGroup, changesById, group, isNotAuthed } = this.props;
    const { selected } = this.state;

    return (
      <div style={styles.header}>
        <ButtonContainer style={styles.actionButtonGroup}>
          <Button
            disabled={selected.length === 0 || isNotAuthed}
            icon={<RejectIcon />}
            size="small"
            onClick={() =>
              bulkUpdate(
                selected.map(id => ({
                  id,
                  status:
                    changesById[id].status === CHANGE_STATUS.FAILED ? CHANGE_STATUS.CANCELED : CHANGE_STATUS.REJECTED,
                })),
              )
            }
          >
            Reject
          </Button>
          <Button
            disabled={selected.length === 0 || isNotAuthed}
            icon={<ApproveIcon />}
            size="small"
            onClick={() =>
              bulkUpdate(
                selected.map(id => ({
                  id,
                  status:
                    changesById[id].status === CHANGE_STATUS.FAILED ? CHANGE_STATUS.PROCESSING : CHANGE_STATUS.APPROVED,
                })),
              )
            }
          >
            Approve
          </Button>
        </ButtonContainer>
        <Tabs
          tabs={[
            { key: 'attribute', label: 'By Attribute' },
            { key: 'user', label: 'By User' },
            { key: 'editor', label: 'By Editor' },
          ]}
          activeKey={group}
          onChange={newGroup => setGroup(newGroup)}
        />
      </div>
    );
  }

  renderChanges() {
    const { bulkUpdate, changeSaving, changesById, error, group, loading, isNotAuthed } = this.props;
    const changesToRender = this.getChangesToRender();

    return (
      <ChangeTable
        customDeleteIcon={<RejectIcon />}
        customEditIcon={<ApproveIcon />}
        defaultSortBy={'createdAt'}
        groupBy={group}
        isActionsLoading={c => changeSaving[c.id]}
        loading={loading}
        noDataMessage={error ? 'Failed to Load Changes' : 'No Pending Changes'}
        onDeselect={this.deselect}
        onDelete={({ id }) =>
          bulkUpdate([
            {
              id,
              status: changesById[id].status === CHANGE_STATUS.FAILED ? CHANGE_STATUS.CANCELED : CHANGE_STATUS.REJECTED,
            },
          ])
        }
        onEdit={({ id }) =>
          bulkUpdate([
            {
              id,
              status:
                changesById[id].status === CHANGE_STATUS.FAILED ? CHANGE_STATUS.PROCESSING : CHANGE_STATUS.APPROVED,
            },
          ])
        }
        shouldDisableDelete={isNotAuthed}
        shouldDisableEdit={isNotAuthed}
        onSelect={this.select}
        selected={this.state.selected}
        getRowStyle={item => (item.status === 'failed' ? styles.trFailed : {})}
        data={
          changesToRender &&
          changesToRender.map(c => {
            const id = c.id;
            const createdAt = c.createdAt;
            const status = c.status;
            const user = this.getUserName(c);
            const groupKey = group === 'attribute' ? c.fieldId : group === 'user' ? this.getUserName(c) : c.userId;
            const attribute = this.getAttributeName(c);
            const { oldValue, newValue: rawNew } = this.getDisplayValues(c);
            const newValue = (
              <ValueWrapper>
                {rawNew}
                {c.delegated && (
                  <Tooltip content={`Edited by ${this.getDelegateName(c)}`}>
                    <span>
                      <EditedByIcon />
                    </span>
                  </Tooltip>
                )}
              </ValueWrapper>
            );

            return {
              id,
              user,
              groupKey,
              attribute,
              oldValue,
              newValue,
              createdAt,
              status,
            };
          })
        }
      />
    );
  }
}
