import { ResponseOuApiAudience } from '@hyperfish/antrea-api-contracts/src/audience';
import { Card, Modal, Button, stringToOption, FiPlus } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  DataTable,
  DropdownButton,
  HyperFieldFactory as hFields,
  HyperForm,
  Icon,
  ModalKeys,
  PremiumLock,
} from '../../components/';
import { OrgSettingsKeys as keys } from '../../models/api';
import { isFree } from '../../redux/modules/auth';
import {
  create as createCollection,
  createStart as createCollectionStart,
  edit as editCollection,
  editCloneFrom as editCollectionCloneFrom,
  editComplete as editCollectionComplete,
  load as loadCollections,
  loadScopes,
  remove as deleteCollection,
} from '../../redux/modules/collections';
import { load as loadFieldSchema } from '../../redux/modules/fieldSchema';
import { show as showModal } from '../../redux/modules/premiumModals';
import { load as loadSettings } from '../../redux/modules/settings';
import OUValidator from '../../utils/OUValidationUtil';
import StyleUtil from '../../utils/StyleUtil';
import { dummyCollections, dummyCollectionsById, dummyRelatedEntities } from './dummyData';

const CollectionsTable = DataTable.of({
  name: { label: 'Name', sortable: true },
  accounts: { label: 'Accounts', sortable: true },
  modified: { label: 'Modified Date', sortable: true, type: DataTable.types.date },
  modifiedBy: { label: 'Modified By' },
});

const OuTable = DataTable.of({
  name: { label: 'Name' },
});

interface State {
  confirmDeleteCollection: ResponseOuApiAudience;
}

const connector = connect(
  state => ({
    collectionCreating: state.collections.creating,
    collectionDirty: state.collections.collectionDirty,
    collectionFieldname:
      !!state.orgs.current &&
      !!state.orgs.current.settings[state.orgs.current.id].collections_attributes &&
      state.orgs.current.settings[state.orgs.current.id].collections_attributes.fieldName,
    collections: isFree(state) ? dummyCollections : state.collections.dataIds.map(id => state.collections.dataById[id]),
    collectionsById: isFree(state) ? dummyCollectionsById : state.collections.dataById,
    collectionsLoading: state.collections.loading,
    currentOrg: state.orgs.current,
    deletingById: state.collections.deletingById,
    dirtyCloneFrom: state.collections.dirtyCloneFrom,
    dirtyCollection: state.collections.dirtyCollection,
    fieldSchema: state.fieldSchema.data,
    fieldSchemaLoading: state.fieldSchema.loading,
    isFree: isFree(state),
    relatedEntities: isFree(state) ? dummyRelatedEntities : state.collections.relatedEntities,
    scopeById: state.collections.scopeById,
    scopes: state.collections.scopes,
    scopesLoading: state.collections.loadingScopes,
    settings: !!state.orgs.current && !!state.settings.data && state.settings.data[state.orgs.current.id],
    settingsLoading: state.settings.loading,
  }),
  {
    createCollection,
    createCollectionStart,
    deleteCollection,
    editCollection,
    editCollectionCloneFrom,
    editCollectionComplete,
    loadCollections,
    loadFieldSchema,
    loadScopes,
    loadSettings,
    showModal,
  },
);

@connector
@Radium
export default class Collections extends React.Component<typeof connector['props'], Partial<State>> {
  private ouValidator: OUValidator;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      collectionsLoading,
      currentOrg,
      fieldSchemaLoading,
      loadCollections,
      loadFieldSchema,
      loadScopes,
      loadSettings,
      scopesLoading,
    } = this.props;

    loadSettings([this.props.currentOrg.id], [keys.audit.scopes, keys.audit.exclusions]);

    if (currentOrg && currentOrg.type !== 'Online' && !scopesLoading) {
      loadScopes();
    }
    if (!collectionsLoading) {
      loadCollections();
    }
    if (!fieldSchemaLoading) {
      loadFieldSchema();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: typeof connector['props']) {
    if (!this.props.currentOrg && !!nextProps.currentOrg && nextProps.currentOrg.type !== 'Online') {
      this.props.loadScopes();
    }
  }

  get validator() {
    const { settings } = this.props;

    if (!settings) {
      return;
    }

    if (!this.ouValidator && !!settings) {
      this.ouValidator = new OUValidator(
        (settings.audit_scopes || []).map(({ distinguishedName }) => distinguishedName),
        (settings.audit_exclusions || []).map(({ distinguishedName }) => distinguishedName),
      );
    }

    return this.ouValidator;
  }

  closeDeleteModal() {
    this.setState({ confirmDeleteCollection: null });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { createCollection, dirtyCloneFrom, dirtyCollection } = this.props;
    createCollection(dirtyCollection, dirtyCloneFrom);
  };

  handleSubmitAndConfigure = () => {
    const { createCollection, dirtyCollection, history } = this.props;
    (createCollection(dirtyCollection) as any).then(result =>
      history.push({ pathname: '/settings/general', search: `?audienceId=${result.audience.id}` }),
    );
  };

  getSettingsDisplay = (audienceId: string, property: string): boolean => {
    if (property === null || !this.props.relatedEntities) {
      return false;
    }
    if (property === 'attributes') {
      return !this.props.relatedEntities.audienceDisplay[audienceId].allAttributesInherit;
    }
    if (property === 'groups') {
      return !this.props.relatedEntities.audienceDisplay[audienceId].categoriesInherit;
    }
    return !this.props.relatedEntities.audienceDisplay[audienceId].settings[`inheritance_${property}`];
  };

  getCollections() {
    const { collections } = this.props;
    return collections.filter(v => v.type !== 'Global') as ResponseOuApiAudience[];
  }

  getFieldByProperty(property: string) {
    const { fieldSchema } = this.props;
    if (!fieldSchema) {
      return;
    }
    Object.keys(fieldSchema.fields).forEach(key => {
      if (fieldSchema.fields[key].property === property) {
        return fieldSchema.fields[key];
      }
    });
  }

  render() {
    const { collectionDirty, createCollectionStart, collectionFieldname, isFree, showModal } = this.props;
    const { confirmDeleteCollection } = this.state;

    return (
      <div className="view-collections">
        <div className="tableHeader" style={StyleUtil.styles.tables.header}>
          <Button
            onClick={isFree ? null : () => createCollectionStart(collectionFieldname)}
            disabled={isFree}
            icon={
              isFree ? <PremiumLock onClick={() => showModal(ModalKeys.collections)} tooltipDir="left" /> : <FiPlus />
            }
            color="accent"
            variant="link"
            size="small"
          >
            Add Collection
          </Button>
          {/* <a
            style={
              [StyleUtil.styles.tables.headerAction, isFree && StyleUtil.styles.tables.headerAction_disabled] as any
            }
            onClick={isFree ? null : () => createCollectionStart(collectionFieldname)}
          >
            {isFree ? (
              <PremiumLock onClick={() => showModal(ModalKeys.collections)} tooltipDir="left" />
            ) : (
              <Icon name="plus" />
            )}
            <span
              style={
                [
                  StyleUtil.styles.tables.headerActionText,
                  isFree && StyleUtil.styles.tables.headerActionText_disabled,
                ] as any
              }
            >
              Add Collection
            </span> 
          </a>*/}
        </div>

        <Card noPadding={true}>{this.renderCollections()}</Card>
        {isFree && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <h2>
              <PremiumLock onClick={() => showModal(ModalKeys.collections)} tooltipDir="left" />
              <span>Did you know?</span>
            </h2>
            <p>
              When you upgrade to premium, you can apply different settings
              <br />
              to groups, or &ldquo;Collections&rdquo;, of users that you define.
            </p>
          </div>
        )}

        {collectionDirty && this.renderAddModal()}
        {confirmDeleteCollection && this.renderConfirmDeleteModal()}
      </div>
    );
  }

  renderCollections() {
    const {
      collectionsById,
      collectionsLoading,
      relatedEntities,
      settingsLoading,
      deletingById,
      isFree,
      history,
    } = this.props;

    return (
      <CollectionsTable
        defaultSortBy="name"
        onEdit={collection => history.push({ pathname: '/settings/general', search: `?audienceId=${collection.id}` })}
        onDelete={({ id }) => this.setState({ confirmDeleteCollection: collectionsById[id] as ResponseOuApiAudience })}
        shouldDisableDelete={isFree}
        shouldDisableEdit={isFree}
        isActionsLoading={({ id }) => deletingById[id]}
        customEditIcon="gear"
        renderDetail={this.renderDetail}
        loading={collectionsLoading || settingsLoading}
        noDataMessage="No collections found"
        data={this.getCollections().map(collection => {
          const id = collection.id;
          const name = collection.definition.name;
          const accounts = relatedEntities.audienceDisplay[collection.id].lastAudit
            ? relatedEntities.audienceDisplay[collection.id].lastAudit.recordCount
            : '';
          const modified = relatedEntities.audienceDisplay[collection.id].lastModified;
          const modifiedBy =
            relatedEntities.audienceDisplay[collection.id].lastModifiedBy &&
            relatedEntities.users[relatedEntities.audienceDisplay[collection.id].lastModifiedBy.userId] &&
            relatedEntities.users[relatedEntities.audienceDisplay[collection.id].lastModifiedBy.userId].displayName;

          return {
            id,
            name,
            accounts,
            modified,
            modifiedBy,
          };
        })}
      />
    );
  }

  renderDetail = ({ id }) => {
    const { collectionsById, isFree } = this.props;
    const collection = collectionsById[id];

    if (collection.type === 'Global') {
      return;
    }

    const settings = {
      approval: [
        {
          label: 'auto approval',
          property: 'autoApprove',
          overridden: this.getSettingsDisplay,
        },
        {
          label: 'approvers',
          property: 'approverPermissions',
          overridden: this.getSettingsDisplay,
        },
      ],
      attributes: [
        {
          label: 'attributes',
          property: 'attributes',
          overridden: this.getSettingsDisplay,
        },
        {
          label: 'categories',
          property: 'groups',
          overridden: this.getSettingsDisplay,
          path: '/categories',
        },
      ],
      branding: [
        {
          label: 'profile page',
          property: 'profileBranding',
          overridden: this.getSettingsDisplay,
        },
        {
          label: 'email communications',
          property: 'emailBranding',
          overridden: this.getSettingsDisplay,
        },
      ],
    };

    return (
      <div>
        <p style={{ marginBottom: '25px' }}>{collection.definition.description}</p>
        <div className="settings" style={{ display: 'flex', marginBottom: '25px' }}>
          <div style={{ fontWeight: 600, marginRight: '60px' }}>
            <p>Settings:</p>
            <p style={{ fontSize: '12px', color: '#4a4a4a', fontWeight: 400 }}>
              <span style={{ fontSize: '9px', verticalAlign: 'super', color: '#6ae6c6', marginTop: '-3px' }}>
                <Icon name="asterisk" />
              </span>
              Master Settings Overridden
            </p>
          </div>
          {Object.keys(settings).map(settingsCategory => (
            <ul key={settingsCategory} style={{ flex: 1, textTransform: 'capitalize' }}>
              <li key={settingsCategory} style={{ fontWeight: 700 }}>
                <Link
                  to={
                    isFree
                      ? '/collections'
                      : {
                          pathname: `/settings/${settingsCategory}`,
                          search: `?audienceId=${collection.id}`,
                        }
                  }
                >
                  <Icon name="gear" />
                  <span style={{ marginLeft: '7px' }}>{settingsCategory}</span>
                </Link>
              </li>
              {settings[settingsCategory].map(setting => (
                <li key={setting.label} style={{ paddingLeft: '22px' }}>
                  <Link
                    to={
                      isFree
                        ? '/collections'
                        : {
                            pathname: `/settings/${settingsCategory}${setting.path || ''}`,
                            search: `?audienceId=${collection.id}`,
                          }
                    }
                  >
                    {setting.label}
                    {setting.overridden(collection.id, setting.property) && (
                      <span style={{ verticalAlign: 'super', fontSize: '6px', color: '#6ae6c6' }}>
                        <Icon name="asterisk" />
                      </span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className="OUs" style={{ display: 'flex' }}>
          <div style={{ marginRight: '13px' }}>
            {collection.type === 'OU' ? (
              <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
                Includes OU{(collection.definition.DNStrings || []).length > 1 ? 's' : ''}:
              </span>
            ) : (
              <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
                Includes &ldquo;{collection.definition.fieldName}&rdquo; values:
              </span>
            )}
          </div>
          <ul style={{ flex: 1 }}>
            {(collection.type === 'OU' ? collection.definition.DNStrings : collection.definition.values).map(s => (
              <li key={s}>{s}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  renderConfirmDeleteModal() {
    const { confirmDeleteCollection: collection } = this.state;
    const { deleteCollection } = this.props;

    return (
      <Modal onClose={() => this.closeDeleteModal()}>
        <Modal.Header>Are you sure you want to delete {collection.definition.name}?</Modal.Header>
        <p>Once deleted, master settings will be applied to the users in this collection.</p>
        <Modal.ButtonContainer>
          <Button variant="link" color="accent" onClick={() => this.closeDeleteModal()}>
            Cancel
          </Button>
          <Button
            size="medium"
            variant="solid"
            color="primary"
            onClick={() => {
              deleteCollection(collection.id);
              this.closeDeleteModal();
            }}
          >
            YES
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  renderAddModal() {
    const {
      collectionFieldname,
      collections,
      collectionCreating,
      currentOrg,
      dirtyCollection,
      editCollection,
      editCollectionCloneFrom,
      editCollectionComplete,
      fieldSchema,
      fieldSchemaLoading,
      scopes,
      scopesLoading,
      settingsLoading,
    } = this.props;

    const byAttribute =
      !!currentOrg &&
      !!currentOrg.settings[currentOrg.id].collections_attributes &&
      !!currentOrg.settings[currentOrg.id].collections_attributes.enabled &&
      currentOrg.settings[currentOrg.id].collections_attributes.fieldName;

    let byAttributeValues = [];
    if (!!byAttribute) {
      let attribute: typeof fieldSchema['fields']['foo'];
      for (const fieldId in fieldSchema.fields) {
        if (!fieldSchema.fields.hasOwnProperty(fieldId)) {
          continue;
        }
        const field = fieldSchema.fields[fieldId];
        if (field.property === byAttribute) {
          attribute = field;
          break;
        }
      }

      if (!!attribute && attribute.audienceConfigs[currentOrg.id].format) {
        const format = attribute.audienceConfigs[currentOrg.id].format;
        byAttributeValues = ((format.meta || {}).selectValue || {})['values'] || [];
      }
    }

    if (currentOrg.type === 'Online' && !collectionFieldname) {
      return (
        <Modal onClose={editCollectionComplete}>
          <Modal.Header>Contact us to enable collections</Modal.Header>
          <p>There is some final setup necesary for your organization to use collections.</p>
          <p>Please contact us to finish setting this feature up!</p>
          <Modal.ButtonContainer>
            <Button variant="solid" color="primary" onClick={editCollectionComplete}>
              OK
            </Button>
          </Modal.ButtonContainer>
        </Modal>
      );
    }

    const collectionCopyOptions = collections
      .filter(collection => collection.type !== 'Global')
      .map(collection => ({
        label: (collection.definition['name'] as string) || '',
        value: collection.id,
      }))
      .sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 0,
      );

    const organizationUnitsOptions =
      dirtyCollection.type === 'OU'
        ? (scopes || [])
            .filter(({ distinguishedName }) => dirtyCollection.definition.DNStrings.indexOf(distinguishedName) === -1)
            .map(({ distinguishedName, name }) => {
              const isDisabled =
                (!!this.validator && !this.validator.isIncluded(distinguishedName)) ||
                collections.filter(c => {
                  return (
                    c.type === 'OU' && c.definition.DNStrings.filter(s => distinguishedName.indexOf(s) > -1).length > 0
                  );
                }).length > 0;

              return {
                value: distinguishedName,
                label: `${name} (${distinguishedName})`,
                isDisabled,
              };
            })
        : (byAttributeValues || [])
            .filter(v => dirtyCollection.definition.values.indexOf(v) === -1)
            .map(value => {
              const isDisabled =
                collections.filter(c => c.type === 'Attribute' && c.definition.values.indexOf(value) > -1).length > 0;

              return {
                value,
                label: value,
                isDisabled,
              };
            });

    return (
      <Modal onClose={editCollectionComplete}>
        <Modal.Header>Add New Collection</Modal.Header>
        <HyperForm
          saving={collectionCreating}
          disabled={collectionCreating || null}
          submitBtn={
            <DropdownButton
              key="saveBtn"
              items={[
                <Button size="small" key="save_and_configure" onClick={this.handleSubmitAndConfigure}>
                  SAVE &amp; CONFIGURE
                </Button>,
              ]}
            >
              SAVE
            </DropdownButton>
          }
          fields={[
            hFields.text({
              label: 'Name',
              placeholder: 'Name',
              autofocus: true,
              required: true,
              name: 'name',
              value: dirtyCollection.definition.name,
              validationText:
                collections
                  .filter(collection => collection.type !== 'Global')
                  .map(c => (c as ResponseOuApiAudience).definition.name)
                  .indexOf(dirtyCollection.definition.name) > -1
                  ? 'This collection name is already taken'
                  : null,
              onChange: e => editCollection('name', e.currentTarget.value),
            }),
            hFields.longText({
              label: 'Description',
              placeholder: 'Description',
              name: 'description',
              value: dirtyCollection.definition.description,
              onChange: e => editCollection('description', e.currentTarget.value),
            }),
            hFields.dropdown({
              label: 'Copy settings from',
              placeholder: 'Select a collection',
              name: 'srcSettings',
              value: collectionCopyOptions.filter(c => c.value === this.props.dirtyCloneFrom),
              onChange: option => editCollectionCloneFrom(option ? (option.value as string) : null),
              dropdownOptions: collectionCopyOptions,
            }),
            hFields.autocomplete({
              label: dirtyCollection.type === 'OU' ? 'Organization Units' : 'Attribute Values',
              name: 'organizationUnits',
              required: true,
              dropdownOptions: organizationUnitsOptions,
              // We are doing a cute hack where we tell the form we have a value, but set props.value to nothing
              // We can type it to any because it won't actually be used to render
              value: (dirtyCollection.type === 'OU'
                ? dirtyCollection.definition.DNStrings.map(stringToOption)
                : dirtyCollection.definition.values.map(stringToOption)) as any,
              hintText:
                dirtyCollection.type === 'OU'
                  ? ''
                  : `If the user has the attribute "${collectionFieldname}" set to these values, add the user to this collection:`,
              props: {
                value: [],
                isLoading: settingsLoading || scopesLoading || fieldSchemaLoading,
                noOptionsMessage: () =>
                  !!byAttribute
                    ? fieldSchemaLoading
                      ? 'Loading attributes schema...'
                      : 'No values found in format configuration'
                    : settingsLoading || scopesLoading
                    ? 'Loading organizational units...'
                    : 'No Organizational units found',
              },
              onChange: val => {
                const prop = dirtyCollection.type === 'OU' ? 'DNStrings' : 'values';
                editCollection(prop, dirtyCollection.definition[prop].slice().concat([val.value as string]));
              },
            }),
            hFields.custom({
              label: '',
              customInput: this.renderModalScopesTable(),
            }),
          ]}
          onCancel={editCollectionComplete}
          onSubmit={this.handleSubmit}
        />
      </Modal>
    );
  }

  renderModalScopesTable() {
    const { dirtyCollection, editCollection, scopeById } = this.props;

    const prop = dirtyCollection.type === 'OU' ? 'DNStrings' : 'values';

    return (
      <OuTable
        tableBorder={true}
        noDataMessage={dirtyCollection.type === 'OU' ? 'No OUs selected.' : 'No values selected.'}
        onDelete={(d, i) => {
          const newValue = dirtyCollection.definition[prop].slice();
          newValue.splice(i, 1);
          editCollection(prop, newValue);
        }}
        data={
          dirtyCollection.definition[prop] &&
          dirtyCollection.definition[prop].map(name => ({
            id: name,
            name: dirtyCollection.type === 'OU' ? scopeById[name].distinguishedName : name,
          }))
        }
      />
    );
  }
}
