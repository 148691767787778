import { Code } from '@hyperfish/antrea-api-contracts/src/auth';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import Cookies from 'universal-cookie';
import {
  ACCESS_TOKEN,
  USER_FEATURE_ADMIN,
  USER_FEATURE_APPROVER,
  GRANT_TOKEN,
  LICENSE_FREE,
  ORG_NAME,
  USER_FEATURES,
  USER_FEATURE_EDITOR,
  META,
  ORG_FEATURE_PAID,
} from '../../config';
import { AuthState as State, GlobalState } from '../../models/client/';
import { ApiClient } from '@hyperfish/fishfood';

const cookie = new Cookies();

const LOGOUT = 'antrea/auth/LOGOUT';
export const LOGOUT_SUCCESS = 'antrea/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'antrea/auth/LOGOUT_FAIL';
const REGISTER = 'antrea/auth/REGISTER';
const REGISTER_SUCCESS = 'antrea/auth/REGISTER_SUCCESS';
const REGISTER_FAIL = 'antrea/auth/REGISTER_FAIL';
const DISMISS_REGISTER_FAIL = 'antrea/auth/DISMISS_REGISTER_FAIL';
const RESEND_EMAIL = 'antrea/auth/RESEND_EMAIL';
const RESEND_EMAIL_SUCCESS = 'antrea/auth/RESEND_EMAIL_SUCCESS';
const RESEND_EMAIL_FAIL = 'antrea/auth/RESEND_EMAIL_FAIL';
const GET_CODE = 'antrea/auth/GET_CODE';
const GET_CODE_SUCCESS = 'antrea/auth/GET_CODE_SUCCESS';
const GET_CODE_FAIL = 'antrea/auth/GET_CODE_FAIL';
const CLEAR_CODE = 'antrea/auth/CLEAR_CODE';
const CLEAR_BACKEND = 'antrea/auth/CLEAR_BACKEND';
const SET_LICENSED = 'antrea/auth/SET_LICENSED';
const SET_VERIFIED = 'antrea/auth/SET_VERIFIED';
const DISMISS_VERIFIED = 'antrea/auth/DISMISS_VERIFIED';
const DISMISS_LICENSED = 'antrea/auth/DISMISS_LICENSED';
const GET_AUTH_CODES = 'antrea/auth/GET_AUTH_CODES';
const GET_AUTH_CODES_SUCCESS = 'antrea/auth/GET_AUTH_CODES_SUCCESS';
const GET_AUTH_CODES_FAILURE = 'antrea/auth/GET_AUTH_CODES_FAILURE';
const GENERATE_AUTH_CODE = 'GENERATE_AUTH_CODE';
const GENERATE_AUTH_CODE_SUCCESS = 'GENERATE_AUTH_CODE_SUCCESS';
const GENERATE_AUTH_CODE_FAILURE = 'GENERATE_AUTH_CODE_FAILURE';
const REVOKE_AUTH_CODE = 'REVOKE_AUTH_CODE';
const REVOKE_AUTH_CODE_SUCCESS = 'REVOKE_AUTH_CODE_SUCCESS';
const REVOKE_AUTH_CODE_FAILURE = 'REVOKE_AUTH_CODE_FAILURE';

const customBackend =
  cookie.get('backend') && cookie.get('customBackend') && cookie.get('backend') !== cookie.get('customBackend')
    ? cookie.get('customBackend')
    : null;

const token = cookie.get(ACCESS_TOKEN);
const meta = cookie.get(META);

const userId = getFrom(meta)('providerIdentifier').value;

const initialState: State = {
  customBackend,
  userFeatures: (cookie.get(USER_FEATURES) || '').split(','),
  orgName: cookie.get(ORG_NAME),
  token,
  grantToken: cookie.get(GRANT_TOKEN),
  userId,
};

export default function reducer(state: State = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        loggingOut: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        logoutError: null,
        token: null,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error,
      };
    case REGISTER:
      return {
        ...state,
        registering: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        emailResent: false,
        registerError: null,
        registering: false,
        trialLicensed: action.result.meta && action.result.meta.licensed,
        trialToken: action.result.token,
        trialVerified: action.result.meta && action.result.meta.emailVerified,
        userId: action.result.user,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        registerError: action.error,
        registering: false,
      };
    case DISMISS_REGISTER_FAIL:
      return {
        ...state,
        registerError: null,
      };
    case GET_CODE:
      return {
        ...state,
        code: null,
        codeDeviceId: action.deviceId,
        gettingCode: true,
        gettingCodeError: null,
      };
    case GET_CODE_SUCCESS:
      return {
        ...state,
        code: (action.result as Code).deviceCode,
        gettingCode: false,
      };
    case GET_CODE_FAIL:
      return {
        ...state,
        gettingCode: false,
        gettingCodeError: action.error,
      };
    case CLEAR_CODE:
      return {
        ...state,
        code: null,
        codeDeviceId: null,
      };
    case SET_VERIFIED:
      return {
        ...state,
        emailResent: false,
        trialVerified: true,
      };
    case DISMISS_VERIFIED:
      return {
        ...state,
        trialVerified: false,
      };
    case SET_LICENSED:
      return {
        ...state,
        trialLicensed: true,
      };
    case DISMISS_LICENSED:
      return {
        ...state,
        trialLicensed: false,
      };
    case RESEND_EMAIL:
      return {
        ...state,
        resendingEmail: true,
      };
    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        resendingEmail: false,
        resendEmailError: null,
        emailResent: true,
      };
    case RESEND_EMAIL_FAIL:
      return {
        ...state,
        resendingEmail: false,
        resendEmailError: action.error,
        emailResent: false,
      };
    case CLEAR_BACKEND:
      cookie.remove('customBackend');
      location.search = '';
      return {
        ...state,
        customBackend: null,
      };
    case GET_AUTH_CODES_SUCCESS:
      return {
        ...state,
        authCodes: action.result.tokens,
        availablePurposes: action.result.purposes.map(x => x.name),
      };
    case GENERATE_AUTH_CODE_SUCCESS:
      return {
        ...state,
        authCodes: [...state.authCodes, action.result.token],
      };
    case REVOKE_AUTH_CODE:
      return {
        ...state,
        authCodes: state.authCodes && state.authCodes.filter(x => x.id !== action.id),
      };
    default:
      return state;
  }
}

export function isAuthed(globalState: GlobalState, type?: 'grant') {
  const key =
    {
      grant: 'grantToken',
    }[type] || 'token';

  return globalState.auth && globalState.auth[key];
}

export function hasFeature({ auth }: GlobalState, feature: string) {
  return auth && auth.userFeatures.indexOf(feature) > -1;
}

export function isFree(globalState: GlobalState) {
  return getFrom(globalState)('orgs')('current')('license').value === LICENSE_FREE;
}

export function isLite(globalState: GlobalState) {
  return (
    getFrom(globalState)('orgs')('current')('license').value === LICENSE_FREE &&
    getFrom(globalState)('orgs')('current')('licenseInfo')('features')
      .defaultTo([])
      .includes(ORG_FEATURE_PAID)
  );
}

export function isAdmin(globalState: GlobalState) {
  return isAuthed(globalState) && hasFeature(globalState, USER_FEATURE_ADMIN);
}

export function isEditor(globalState: GlobalState) {
  return isAuthed(globalState) && hasFeature(globalState, USER_FEATURE_EDITOR);
}

export function isApprover(globalState: GlobalState) {
  return (
    isAuthed(globalState) &&
    globalState.auth &&
    (hasFeature(globalState, USER_FEATURE_APPROVER) || hasFeature(globalState, USER_FEATURE_ADMIN))
  );
}

export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: (client: ApiClient) => client.request('GET', '/auth/logout'),
  };
}

export function dismissRegisterFail() {
  return {
    type: DISMISS_REGISTER_FAIL,
  };
}

export function shouldGetCode(globalState: GlobalState) {
  return globalState.auth && !globalState.auth.gettingCode && !globalState.auth.code;
}

export function getCode(deviceId?: string) {
  return {
    types: [GET_CODE, GET_CODE_SUCCESS, GET_CODE_FAIL],
    deviceId,
    promise: (client: ApiClient) =>
      client.get(`/auth/code`, {
        params: { replaceDeviceId: deviceId },
      }),
  };
}

export function clearCode() {
  return {
    type: CLEAR_CODE,
  };
}

export function getAuthCodes() {
  return {
    types: [GET_AUTH_CODES, GET_AUTH_CODES_SUCCESS, GET_AUTH_CODES_FAILURE],
    promise: (client: ApiClient) => client.get('/auth/clients/tokens'),
  };
}

export function generateNewAuthCode(name: string, purpose: string) {
  return {
    types: [GENERATE_AUTH_CODE, GENERATE_AUTH_CODE_SUCCESS, GENERATE_AUTH_CODE_FAILURE],
    promise: (client: ApiClient) => client.post('/auth/clients/tokens', { data: { name, purpose } }),
  };
}

export function revokeAuthCode(id: string) {
  return {
    types: [REVOKE_AUTH_CODE, REVOKE_AUTH_CODE_SUCCESS, REVOKE_AUTH_CODE_FAILURE],
    id,
    promise: (client: ApiClient) => client.del(`/auth/clients/tokens/${id}`),
  };
}

export function setVerified() {
  return {
    type: SET_VERIFIED,
  };
}

export function dismissVerified() {
  return {
    type: DISMISS_VERIFIED,
  };
}

export function setLicensed() {
  return {
    type: SET_LICENSED,
  };
}

export function dismissLicensed() {
  return {
    type: DISMISS_LICENSED,
  };
}

export function resendEmail() {
  return {
    types: [RESEND_EMAIL, RESEND_EMAIL_SUCCESS, RESEND_EMAIL_FAIL],
    promise: (client: ApiClient) => client.post('/users/me/trial-emails'),
  };
}

export function clearBackend() {
  return {
    type: CLEAR_BACKEND,
  };
}
