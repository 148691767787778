import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, ButtonContainer } from '@hyperfish/fishfood/lib/components/Button';
import { DefaultOptionType, stringToOption } from '@hyperfish/fishfood/lib/components/Select/shared';
import { FiMinusCircle, FiPlus } from '@hyperfish/fishfood/lib/components/Icon';
import { Form } from '@hyperfish/fishfood/lib/components/Form';
import { Modal, ModalProps } from '@hyperfish/fishfood/lib/components/Modal';
import { Tooltip } from '@hyperfish/fishfood/lib/components/Tooltip';
import { AdditionalLabel, EmptyMessage, RemoveContainer, SortContainer } from './DirectoryFormModalComponents';
import { DirectorySearchQueryFieldItem } from '@hyperfish/antrea-api-contracts';
import SchemaUtil from '@hyperfish/fishfood/lib/utils/SchemaUtil';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import { array, object, string } from 'yup';

const blankItem: DirectorySearchQueryFieldItem = {
  field: '',
  value: [],
  exactMatch: true,
};

interface DirectoryFormFilterModalProps {
  onApply: (newFilters: DirectorySearchQueryFieldItem[]) => void;
  onClose: ModalProps['onClose'];
  schemaUtil: SchemaUtil;
  initialFilters: DirectorySearchQueryFieldItem[];
  filterableFields: string[];
}

export const DirectoryFormFilterModal: FunctionComponent<DirectoryFormFilterModalProps> = ({
  onApply,
  onClose,
  schemaUtil,
  initialFilters,
  filterableFields,
}) => {
  const [filterOptions, setFilterOptions] = useState<DefaultOptionType[]>([]);

  const { t } = useTranslation('directorySearch');

  useEffect(() => {
    setFilterOptions(
      schemaUtil
        .getFilterableFields()
        .filter(f => !(filterableFields && filterableFields.indexOf(f.property) === -1))
        .map(f => ({ value: f.property, label: schemaUtil.getFieldTitle(f) || f.property }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  }, [schemaUtil, filterableFields]);

  const defaultFilters = initialFilters && initialFilters.length > 0 ? initialFilters : [blankItem];

  return (
    <Modal onClose={() => onClose()} modalWidth="900px">
      <Modal.Header>{t('filtersResults')}:</Modal.Header>
      <Form
        initialValues={{ defaultFilters }}
        onReset={onClose}
        validationSchema={object().shape({
          defaultFilters: array().of(
            object().shape({
              field: string().required(t('filtersErrorAttribute')),
              value: array()
                .ensure()
                .required()
                .min(1, t('filtersErrorOneof'))
                .of(string().required()),
            }),
          ),
        })}
        onSubmit={() => {}}
        render={({ values }: { values: any }) => (
          <Form.FormikForm>
            <FieldArray name="defaultFilters">
              {arrayHelpers => (
                <div>
                  {values.defaultFilters && values.defaultFilters.length > 0 ? (
                    values.defaultFilters.map((filter, index) => (
                      <Fragment key={index}>
                        {index !== 0 ? (
                          <AdditionalLabel>{t('filtersAnd')}</AdditionalLabel>
                        ) : (
                          <AdditionalLabel>{t('filtersWhere')}</AdditionalLabel>
                        )}
                        <SortContainer>
                          <Form.FieldSet>
                            <Form.FieldContainer>
                              <Form.Fields.Select
                                label={t('filtersOneof')}
                                name={`defaultFilters[${index}].field`}
                                required
                                inputProps={{ options: filterOptions }}
                              />
                              <Form.Fields.Select
                                label={t('filtersSelect')}
                                name={`defaultFilters[${index}].value`}
                                required
                                inputProps={{
                                  creatable: true,
                                  isMulti: true,
                                  options: getFrom(values)('defaultFilters')(index)('field').value
                                    ? schemaUtil
                                        .getFieldChoiceOptions(
                                          schemaUtil.getFieldByProperty(getFrom(values)('defaultFilters')(index)(
                                            'field',
                                          ).value as string),
                                        )
                                        .map(stringToOption)
                                    : [],
                                }}
                              />
                            </Form.FieldContainer>
                          </Form.FieldSet>
                          <RemoveContainer>
                            <Tooltip content={`${t('remove')}`} delay={[300, 0]}>
                              <IconButton
                                css={null}
                                ariaLabel="Remove"
                                color="error"
                                size="small"
                                icon={<FiMinusCircle />}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </Tooltip>
                          </RemoveContainer>
                        </SortContainer>
                      </Fragment>
                    ))
                  ) : (
                    <EmptyMessage>{t('filtersNoneSelected')}</EmptyMessage>
                  )}
                  <Button variant="link" color="accent" icon={<FiPlus />} onClick={() => arrayHelpers.push(blankItem)}>
                    {t('filtersAdd')}
                  </Button>
                </div>
              )}
            </FieldArray>
            <ButtonContainer align="right">
              <Button onClick={() => onClose()}>{t('cancel').toUpperCase()}</Button>
              <Button
                color="primary"
                variant="solid"
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  onApply(values.defaultFilters);
                }}
              >
                {t('apply').toUpperCase()}
              </Button>
            </ButtonContainer>
          </Form.FormikForm>
        )}
      />
    </Modal>
  );
};
