import React from 'react';

interface Props {
  children?: any;
}

interface State {
  affixed: boolean;
}

export class Affix extends React.Component<Props, State> {
  private it: HTMLDivElement;
  private lastKnownScrollPosition = 0;
  private ticking = false;
  private offsetTop: number;

  constructor(props: Props) {
    super(props);
    this.state = { affixed: false };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidMount() {
    const el = this.it as HTMLElement;
    this.offsetTop = el.offsetTop;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.lastKnownScrollPosition = window.scrollY;
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.scrollUpdate(this.lastKnownScrollPosition);
        this.ticking = false;
      });
    }
    this.ticking = true;
  };

  scrollUpdate(scrollPos: number) {
    if (isNaN(this.offsetTop)) {
      return;
    }

    if (scrollPos >= this.offsetTop) {
      if (!this.state.affixed) {
        this.setState({ affixed: true });
      }
    } else if (this.state.affixed) {
      this.setState({ affixed: false });
    }
  }

  render() {
    const style: React.CSSProperties = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 100,
    };

    return (
      <div
        ref={el => (this.it = el)}
        style={this.state.affixed ? style : {}}
        className={this.state.affixed ? 'affixed' : ''}
      >
        {this.props.children}
      </div>
    );
  }
}
