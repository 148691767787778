import React, { FunctionComponent } from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { merge as _merge, concat as _concat } from 'lodash';

import { Button } from '@hyperfish/fishfood/lib/components/Button';
import { FiDownload } from '@hyperfish/fishfood/lib/components/Icon';
import { LoadingSplash } from '@hyperfish/fishfood/lib/components/LoadingSplash';
import { Modal, ModalProps } from '@hyperfish/fishfood/lib/components/Modal';
import { getFrom } from '@hyperfish/fishfood/lib/utils';
import { EventDetails, EventListDetails, EventReferences } from '@hyperfish/antrea-api-contracts/src/events';
import { getEventChanges } from '../../../utils/EventUtil';

export interface LogExportModalProps {
  onClose: ModalProps['onClose'];
  pageCount: number;
  pages: { [page: number]: EventListDetails };
  pagesLoading: { [page: number]: boolean };
  pagesError: { [page: number]: any };
}

export const LogExportModal: FunctionComponent<LogExportModalProps> = ({
  onClose,
  pageCount,
  pages,
  pagesLoading,
  pagesError,
}) => {
  const { t } = useTranslation('directorySearch');

  let loadedPages = 0;
  let failed;
  for (let page = 1; page <= pageCount; page++) {
    if (pagesError[page]) {
      failed = true;
      break;
    }
    if (!pagesLoading[page] && !!pages[page]) {
      loadedPages++;
    }
  }

  if (failed) {
    return (
      <Modal onClose={onClose}>
        <Modal.Header>{t('exportModalFailedHeader')}</Modal.Header>
        <p>{t('exportModalFailedDesc')}</p>
        <Modal.ButtonContainer>
          <Button onClick={onClose}>{t('close')}</Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  const onExport = () => {
    const fields = [
      'id',
      'event',
      'timestamp',
      'datetime',
      'status',
      'description',
      'user',
      'target',
      'propertyName',
      'oldValue',
      'newValue',
    ];

    let eventsData: Partial<EventListDetails> = {};
    for (let i = 1; i <= pageCount; i++) {
      const eventListDetail = getFrom(pages)(i).value as EventListDetails;
      // prettier-ignore
      eventsData = {
        events: _concat(
          getFrom(eventsData)('events').defaultTo([] as EventDetails[]),
          getFrom(eventListDetail)('events').defaultTo([] as EventDetails[]),
        ),
        references: _merge(
          getFrom(eventsData)('references').defaultTo({} as EventReferences),
          getFrom(eventListDetail)('references').defaultTo({} as EventReferences),
        ),
      } as Partial<EventListDetails>;
    }

    const changes = getEventChanges(eventsData as EventListDetails);
    const data = changes.map(c => [...fields.map(f => getFrom(c)(f).value)]);

    const body = `sep=,\n${Papa.unparse({ fields, data })}`;
    const blob = new Blob([body], { type: 'application/octet-stream' });
    const fileName = 'DirectoryEventLog.csv';

    saveAs(blob, fileName);

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      {loadedPages < pageCount ? (
        <>
          <Modal.Header>{t('exportModalLoadingHeader', { page: loadedPages, total: pageCount })}</Modal.Header>
          <LoadingSplash />
        </>
      ) : (
        <>
          <Modal.Header>{t('exportModalSuccessHeader')}</Modal.Header>
          <Modal.ButtonContainer>
            <Button onClick={onClose}>{t('cancel')}</Button>
            <Button variant="solid" color="primary" icon={<FiDownload />} onClick={onExport}>
              {t('exportModalSuccessDownloadButton')}
            </Button>
          </Modal.ButtonContainer>
        </>
      )}
    </Modal>
  );
};
