import moment from 'moment';
import Radium from 'radium';
import React from 'react';

import { GlobalState } from '../models/client';
import StyleUtil from '../utils/StyleUtil';
import { Affix } from './Affix';
import { BarTable } from './BarTable';
import { Button } from './Button';
import { CompletionHealthChart } from './CompletionHealthChart';
import { GaugeChart } from './GaugeChart';

interface Props {
  audit: GlobalState['audits']['details']['foo'];
  viewModel: any;
  currentOrg: GlobalState['orgs']['current'];
}

const summaryBreak = '@media screen and (max-width: 560px)';
const styles = StyleUtil({
  headerSpacer: {
    display: 'inline-block',
    width: 32,

    [summaryBreak]: {
      display: 'block',
      width: 1,
    },
  },
  summaryContainer: {
    color: StyleUtil.colors.text.dark,
    padding: '10px 0',
    position: 'relative',
    textAlign: 'left',
    width: 'auto',

    [summaryBreak]: {
      marginTop: 35,
      paddingTop: 130 + 20,
    },
  },
  summaryTop: {
    alignItems: 'stretch',
    display: 'flex',

    [summaryBreak]: {
      flexFlow: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  summaryAccountCount: {
    color: '#0096cd',
    fontSize: 84,
    fontWeight: 300,
    lineHeight: 0.8,
  },
  summaryTopText: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    marginLeft: 10,
  },
  summaryTextAccounts: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: 1.4,
  },
  summaryTextInDirectory: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1,
  },
  summaryTextCouldSave: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: 2.1,

    [summaryBreak]: {
      display: 'block',
      lineHeight: 1.4,
      marginBottom: 5,
      marginTop: 30,
      textAlign: 'center',
    },
  },
  summaryIconDesktop: {
    display: 'inline-block',

    [summaryBreak]: {
      display: 'none',
    },
  },
  summaryIconMobile: {
    display: 'none',

    [summaryBreak]: {
      display: 'block',
      left: '50%',
      position: 'absolute',
      top: 0,
      transform: 'translate(-50%)',
    },
  },
  summaryHoursCount: {
    color: '#0096cd',
    fontSize: 84,
    fontWeight: 300,
    lineHeight: 0.75,

    [summaryBreak]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  summaryTextPerYear: {
    fontSize: 22,
    fontWeight: 700,

    [summaryBreak]: {
      display: 'block',
      marginTop: 5,
      textAlign: 'center',
    },
  },
  mobileBreak: {
    display: 'none',

    [summaryBreak]: {
      display: 'block',
    },
  },
});

function getColor(value: number) {
  const colors = {
    bad: '#e35c5d',
    neutral: StyleUtil.colors.orange,
    good: StyleUtil.colors.green,
  };
  if (value > 80) {
    return colors.good;
  } else if (value > 40) {
    return colors.neutral;
  } else {
    return colors.bad;
  }
}

export const ReportC = ({ currentOrg, audit, viewModel: { sections, completionValue } }: Props) => {
  return (
    <div className="view-report">
      <h1 style={{ padding: '0 20px' }}>Your LiveTiles Directory Report</h1>
      <p>
        {currentOrg && currentOrg.name && currentOrg.name.replace('Trial for ', '')}
        <span style={styles.headerSpacer} />
        {audit.finishedAt && moment(new Date(audit.finishedAt)).format('MMMM D, YYYY h:mma')}
      </p>
      <section className="summary container">
        <CompletionHealthChart completionValue={completionValue} />
        <div style={styles.summaryContainer}>
          <div style={styles.summaryTop}>
            <span style={styles.summaryAccountCount}>
              {audit.combinedStats.totalRecordCount && audit.combinedStats.totalRecordCount.toLocaleString()}
            </span>
            <div style={styles.summaryTopText}>
              <span style={styles.summaryTextAccounts}>accounts</span>
              <span style={styles.summaryTextInDirectory}>in your active directory.</span>
            </div>
          </div>
          <div>
            <span style={styles.summaryTextCouldSave}>
              LiveTiles Directory could save <br style={styles.mobileBreak} />
              your organization
            </span>
          </div>
          <img style={styles.summaryIconDesktop} src={require('../assets/images/hours_icon_60.png')} alt="Hours Icon" />
          <img style={styles.summaryIconMobile} src={require('../assets/images/hours_icon_130.png')} alt="Hours Icon" />
          <span style={styles.summaryHoursCount}>
            {audit.combinedStats.totalRecordCount &&
              Math.round(audit.combinedStats.totalRecordCount * 6).toLocaleString()}
          </span>
          <span style={styles.summaryTextPerYear}>
            hours or more per year.<a href="https://app.hyperfish.com/trial/ROI">*</a>
          </span>
        </div>
      </section>
      <section className="legend">
        <div className="container">
          <h1>Report overview, scroll to learn more about what these numbers mean&hellip;</h1>
        </div>
        <Affix>
          <div className="gauge-container">
            <div className="container">
              <div className="gauges">
                {sections.map(s => (
                  <a key={s.label} href={`#${s.label.toLowerCase().replace(/\s/g, '-')}`}>
                    <GaugeChart value={s.value} width="100%" />
                    <span>{s.label}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Affix>
      </section>
      {sections.map(s => (
        <section
          key={s.label}
          className="detail"
          style={{ textAlign: 'center' }}
          id={s.label.toLowerCase().replace(/\s/g, '-')}
        >
          <div className="container">
            <h1 className="label">{s.label}</h1>
            <h1 className="summary">
              {100 - s.value}% of your users are missing {s.label.toLowerCase()}
            </h1>
            {s.details && s.copy}
            <div className="chart-container">
              <div className="gauge-container">
                <GaugeChart value={s.value} label={s.label} width="100%" height="100%" />
              </div>
              {s.details ? (
                <div className="table-container">
                  <h1>COMPLETION BREAKDOWN</h1>
                  <BarTable properties={s.details} color={getColor(s.value)} />
                </div>
              ) : (
                s.copy
              )}
            </div>
          </div>
        </section>
      ))}
      <section className="cta">
        <Button type="primary" style={{ minWidth: 238 }} to="https://www.hyperfish.com/inquiry" size="medium">
          LEARN MORE
        </Button>
        <p>
          <a href="https://app.hyperfish.com/trial/ROI">*Based on industry data.</a>
        </p>
      </section>
    </div>
  );
};

export const Report = Radium(ReportC);
