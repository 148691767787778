import React, { FunctionComponent } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

export interface AuthedRouteProps extends RouteProps {
  isAllowed: boolean;
}

export const AuthedRoute: FunctionComponent<AuthedRouteProps> = ({ isAllowed, ...rest }) =>
  isAllowed ? <Route {...rest} /> : <Redirect to={{ pathname: '/login', state: { ret: rest.location } }} />;

export default AuthedRoute;
