import Radium from 'radium';
import React from 'react';

import StyleUtil from '../utils/StyleUtil';

interface Props {
  completionValue: number;
  showComplete?: boolean;
}

const breakPoint = '@media screen and (max-width: 560px)';
const styles = StyleUtil({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: 200,
    width: 438,

    [breakPoint]: {
      flexFlow: 'column',
      textAlign: 'center',
      width: '100%',
    },
  },
  userChart: {
    margin: '10px 7px',
    width: 190,
  },
  textContainer: {
    color: StyleUtil.colors.text.dark,
    display: 'flex',
    flexFlow: 'column',
    height: 120,
    justifyContent: 'space-between',
    margin: '10px 7px',

    [breakPoint]: {
      width: '100%',
      height: 'auto',
    },
  },
  percent: {
    color: '#0096cd',
    fontSize: 84,
    fontWeight: 300,
    lineHeight: 0.8,
    display: 'block',
  },
  text1: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 2,
    paddingTop: 5,
    display: 'block',
  },
  text2: {
    fontSize: 22,
    fontWeight: 700,
    display: 'block',
  },
});

export const CompletionHealthChart = Radium(({ completionValue, showComplete }: Props) => (
  <div style={styles.container}>
    <UserChart value={completionValue} style={styles.userChart} />
    <div style={styles.textContainer}>
      <span style={styles.percent}>
        {isNaN(completionValue) ? '--' : showComplete ? Math.round(completionValue) : 100 - Math.round(completionValue)}
        %
      </span>
      <span style={styles.text1}>of your basic account information</span>
      <span style={styles.text2}>is {showComplete ? 'complete' : 'missing'}</span>
    </div>
  </div>
));

const UserChart = (props: { value: number; style?: React.CSSProperties }) => (
  <div style={props.style}>
    {[0, 1, 2, 3, 4].map(r => (
      <div key={r}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(c => (
          <SvgUser style={{ margin: '.5px 2px' }} active={r * 10 + c < props.value / 2} key={c} />
        ))}
      </div>
    ))}
  </div>
);

const SvgUser = (props: { style?: React.CSSProperties; active?: boolean }) => (
  <svg width="15px" height="30px" viewBox="0 0 15 32" style={{ opacity: props.active ? 1 : 0.3, ...props.style }}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none">
      <g id="5.-Trial-Report-Lockups" transform="translate(-288.000000, -300.000000)">
        <g id="field-completion-health" transform="translate(288.000000, 284.000000)">
          <g id="Group-10">
            <g id="row" transform="translate(0.000000, 23.000000)">
              <g id="icon-user">
                <mask id="mask-2" fill="white">
                  <polygon
                    id="path-1"
                    points="6.97257037e-05 24.6611264 14.6683265 24.6611264 14.6683265 0.108351362 6.97257037e-05 0.108351362 6.97257037e-05 24.6611264"
                  />
                </mask>
                <g id="Clip-2" />
                <path
                  d="M14.6236505, 9.71107478 L12.1679622, 0.976325544 C12.0193707, 0.446611259 11.5339481, 0.106565407 11.0085201, 0.113352036 L11.0085201, 0.108351362 L9.48152851, 0.108351362 C8.94359883, 0.679856996 8.180996, 1.03811959 7.33409609, 1.03811959 C6.48683898, 1.03811959 5.72530773, 0.679856996 5.18630648, 0.108351362 L3.62288137, 0.108351362 L3.62288137, 0.114423609 C3.11174102, 0.123710576 2.64524955, 0.459470136 2.49915842, 0.976325544 L0.0448989114, 9.71107478 C-0.133339408, 10.3436601 0.235996108, 11.0008916 0.867509834, 11.1787727 C1.50080952, 11.3559394 2.15732661, 10.9876755 2.33592212, 10.3550902 L3.62288137, 5.77482973 L3.62288137, 10.8062225 L3.62288137, 12.4128677 L3.62288137, 23.0543026 C3.62288137, 23.9412079 4.3419069, 24.661305 5.2291694, 24.661305 C6.1164319, 24.661305 6.83581461, 23.9412079 6.83581461, 23.0543026 L6.83581461, 12.4128677 L7.79558689, 12.4128677 L7.79558689, 23.0543026 C7.79558689, 23.9412079 8.5149696, 24.661305 9.40187491, 24.661305 C10.2891374, 24.661305 11.0085201, 23.9412079 11.0085201, 23.0543026 L11.0085201, 12.4128677 L11.0085201, 10.8062225 L11.0085201, 5.644455 L12.3326272, 10.3550902 C12.5108656, 10.9876755 13.1670255, 11.3559394 13.8006823, 11.1787727 C14.4321961, 11.0008916 14.8015316, 10.3436601 14.6236505, 9.71107478"
                  id="Fill-1"
                  fill="#677379"
                />
                <mask id="mask-4" fill="white">
                  <polygon
                    id="path-3"
                    points="10.2885302 -6.64273754 4.37987631 -6.64273754 4.37987631 -0.734297977 10.2885302 -0.734297977 10.2885302 -6.64273754"
                  />
                </mask>
                <g id="Clip-5" />
                <path
                  d="M10.2885302, -3.68898211 C10.2885302, -2.05697633 8.96585183, -0.734297977 7.33420324, -0.734297977 C5.70255466, -0.734297977 4.37987631, -2.05697633 4.37987631, -3.68898211 C4.37987631, -5.3202735 5.70255466, -6.64295185 7.33420324, -6.64295185 C8.96585183, -6.64295185 10.2885302, -5.3202735 10.2885302, -3.68898211"
                  id="Fill-4"
                  fill="#677379"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
