import { Button, ButtonContainer, FiLoader, FiAlertTriangle } from '@hyperfish/fishfood';
import cx from 'classnames';
import moment from 'moment';
import Radium from 'radium';
import React, { cloneElement, Component, CSSProperties, EventHandler, FormEvent } from 'react';

import { show as showPremiumModal } from '../../redux/modules/premiumModals';
import StyleUtil from '../../utils/StyleUtil';
import { Icon } from '../Icon';
import { PremiumLock } from '../PremiumLock';
import { HyperField } from './HyperFields';
import { HyperFormField } from './HyperFormField';
import classes from './styles.module.scss';

// Components imported separately since Hyperform is used in profile bundle -TedA
interface Props {
  // breakpoint?: string;
  buttonContainerClass?: string;
  customButtons?: JSX.Element[];
  dirty?: boolean;
  disableSave?: boolean;
  disabled?: boolean;
  error?: boolean;
  fields: HyperField[];
  invalid?: boolean;
  onCancel?: () => void;
  onInvalidChange?: (valid: boolean) => void;
  onMount?: (form: HyperForm) => void;
  onSubmit?: EventHandler<FormEvent<HTMLFormElement>>;
  onUnmount?: (form: HyperForm) => void;
  saveText?: string;
  saving?: boolean;
  showPremiumModal?: typeof showPremiumModal;
  submitBtn?: JSX.Element;
  submitButtonStyle?: CSSProperties;
  style?: {
    container?: CSSProperties;
  };
}

interface State {
  key: string;
  invalid: boolean;
  dirty: boolean;
}

@Radium
export class HyperForm extends Component<Props, Partial<State>> {
  static Field = HyperFormField;

  static instanceCount = 0;

  constructor(props: Props) {
    super(props);
    const invalid = props.invalid || (props.fields && props.fields.filter(f => !!this.getValidationText(f)).length > 0);
    this.state = {
      key: `HyperForm_${++this.constructor['instanceCount']}`,
      invalid,
    };
  }

  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount(this);
    }
    if (this.props.onInvalidChange) {
      this.props.onInvalidChange(this.state.invalid);
    }
  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount(this);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.fields) {
      return;
    }

    const labelMap = {};
    let invalid = nextProps.invalid || false;

    for (const field of nextProps.fields) {
      if (!field) {
        continue;
      }

      if (this.getValidationText(field) || field.validating) {
        invalid = true;
      }

      if (field.id) {
        continue;
      }
      if (labelMap[field.label]) {
        console.error(
          `HyperForm - Invalid Fields. The fields array cannot contain two fields with no id and identical labels. Check fields with label: ${field.label}`,
        );
      }
      labelMap[field.label] = true;
    }

    if (invalid !== this.state.invalid) {
      this.setState({ invalid }, () => {
        if (this.props.onInvalidChange) {
          this.props.onInvalidChange(invalid);
        }
      });
    }
  }

  get invalid() {
    return this.state.invalid || this.props.invalid;
  }

  get dirty() {
    return this.state.dirty || this.props.dirty;
  }

  set dirty(dirty) {
    this.setState({ dirty });
  }

  get fields() {
    return this.props.fields && this.props.fields.filter(f => !!f);
  }

  getValidationText(field: HyperField) {
    if (!field || field.type === 'custom' || field.readOnly) {
      return;
    }
    if (field.validationText) {
      return field.validationText;
    }

    const isChoice = ['autocomplete', 'dropdown'].indexOf(field.type) > -1;
    const isDate = ['date', 'time', 'datetime'].indexOf(field.type) > -1;

    if (
      field.required &&
      (field.value == null ||
        field.value === '' ||
        (isChoice && Array.isArray(field.value) && field.value.length === 0))
    ) {
      // Required field with no value.
      return `Please ${isChoice ? 'select' : 'enter'} a valid ${field.label}`;
    }

    if (field.type === 'number' && field.value && isNaN(field.value as number)) {
      return 'Must be a number';
    }

    if (isDate && field.value != null && !moment(field.value as string).isValid()) {
      return 'Must be a valid date';
    }
  }

  getHandleChange = ({ onChange }: { onChange?: any }) => (...args) => {
    if (!this.state.dirty) {
      this.setState({ dirty: true });
    }
    if (onChange) {
      (onChange as any)(...args);
    }
  };

  render() {
    const {
      buttonContainerClass,
      customButtons,
      disabled,
      disableSave,
      error,
      onCancel,
      onSubmit,
      saveText,
      saving,
      style,
      submitBtn,
      submitButtonStyle,
    } = this.props;
    const dirty = this.dirty;
    const invalid = this.invalid;
    const S = StyleUtil({
      container: {
        color: StyleUtil.colors.text.dark,
        fontSize: 18,
      },
      table: {
        tableLayout: 'fixed',
      },
      buttonContainer: {
        textAlign: 'right',
        marginTop: 45 - 11,
      },
      fieldsContainer: {
        position: 'relative',
      },
      fieldsDisabledOverlay: {
        backgroundColor: 'rgba(255, 255, 255, .6)',
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      },
    });

    return (
      <div className={classes.container} style={[S.container, (style || {}).container] as any}>
        <form
          onSubmit={
            onSubmit ||
            (e => {
              e.preventDefault();
              return false;
            })
          }
        >
          <div style={S.fieldsContainer}>
            <table style={S['table']} className={classes.table}>
              <tbody>{this.renderFields()}</tbody>
            </table>
            {disabled && <div style={S.fieldsDisabledOverlay} />}
          </div>
          {(onCancel || onSubmit || customButtons) && (
            <ButtonContainer style={S['buttonContainer']} className={cx(classes.buttonContainer, buttonContainerClass)}>
              {onCancel && (
                <Button
                  style={S.button}
                  onClick={onCancel}
                  className={`${classes.button} ${classes.spacedBtn}`}
                  disabled={saving}
                  size="medium"
                >
                  Cancel
                </Button>
              )}
              {customButtons &&
                customButtons.map(btn => cloneElement(btn, { style: { ...S.button, ...btn['style'] } }))}
              {onSubmit &&
                submitBtn &&
                cloneElement(
                  submitBtn,
                  {
                    style: { ...submitButtonStyle, ...submitBtn['style'] },
                    color: 'primary',
                    variant: 'solid',
                    disabled: disableSave != null ? disableSave : disabled || saving || invalid || !dirty,
                    type: 'submit',
                    className: classes.button + ' ' + classes.spacedBtn,
                    icon: saving ? <FiLoader className="animate-spin" /> : error ? <FiAlertTriangle /> : null,
                    size: 'medium',
                  },
                  saving ? 'SAVING' : 'SAVE',
                )}
              {onSubmit && !submitBtn && (
                <Button
                  color="primary"
                  variant="solid"
                  type="submit"
                  disabled={disableSave != null ? disableSave : disabled || saving || invalid || !dirty}
                  style={{ ...submitButtonStyle }}
                  className={classes.button}
                  icon={saving ? <FiLoader className="animate-spin" /> : error ? <FiAlertTriangle /> : null}
                  size="medium"
                >
                  {saving ? 'SAVING' : saveText ? saveText : 'SAVE'}
                </Button>
              )}
            </ButtonContainer>
          )}
        </form>
      </div>
    );
  }

  renderFields() {
    const { showPremiumModal, disabled } = this.props;
    let fields = this.fields;
    if (disabled) {
      fields = fields.map(f => ({ ...f, disabled: true }));
    }

    const rowArray = [];
    const S = StyleUtil({
      tr: {},
      td: {},
      mobileShow: {
        display: 'none',
      },
      mobileHide: {},
      mobileBlock: {},
      inputRow: {},
      inputLabel: {
        display: 'block',
        paddingRight: 40,
      },
      inputLabelInvalid: {
        color: StyleUtil.colors.red,
      },
      label: {
        color: '#7a7a7a',
        lineHeight: 1,
        position: 'relative',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
      },
      value: {
        width: 460,
        maxWidth: 460,
        wordBreak: 'break-word',
        verticalAlign: 'middle',
      },
      valueReadOnly: {},
      badges: {
        paddingLeft: 10,
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        width: 145,
      },
      readOnlyIcon: {
        color: StyleUtil.colors.blue,
      },
      readOnlyText: {
        color: StyleUtil.colors.text.dark,
        fontSize: 12,
        fontStyle: 'italic',
      },
      hintRow: {
        paddingTop: 11 - 5,
        paddingBottom: 5,
      },
      validationRow: {
        paddingTop: 5,
        paddingBottom: 11 - 5,
      },
      textBase: {
        display: 'block',
        fontSize: 12,
        fontStyle: 'italic',
        lineHeight: 1,
      },
      hintText: { color: StyleUtil.colors.gray },
      validationText: { color: StyleUtil.colors.red },
      validatingText: { color: StyleUtil.colors.blue },
      lock: {
        position: 'absolute',
        right: 10,
        top: '50%',
        transform: 'translateY(-50%)',
      },
    });

    for (const f of fields) {
      const validationText = this.getValidationText(f);

      f.id = f.id || `${this.state.key}_${f.label.replace(/ /g, '_')}`;

      rowArray.push(
        <tr key={`${f.id}_hint`} style={S.mobileHide} className={classes.mobileHide}>
          {!f.hideLabel && <td style={S.mobileHide} className={classes.mobileHide} />}
          <td style={S.hintRow} colSpan={f.hideLabel || f.hideValue ? 2 : 1}>
            <span style={[S.textBase, S.hintText] as any}>{f.suggestionText || f.hintText}</span>
          </td>
          <td style={S.mobileHide} className={classes.mobileHide} />
        </tr>,
      );
      rowArray.push(
        <tr
          key={f.id}
          style={[S.mobileBlock, S.inputRow] as any}
          className={`${classes.mobileBlock} ${classes.inputRow}`}
        >
          {!f.hideLabel && (
            <td
              style={[S.td, S.mobileBlock, S.label] as any}
              className={`${classes.mobileBlock} ${classes.label}`}
              colSpan={f.hideValue ? 2 : 1}
            >
              <label style={[S.inputLabel, !!validationText && S.inputLabelInvalid] as any} htmlFor={`${f.id}_input`}>
                {f.label + (f.required ? '*' : '')}
              </label>
              <span className={`${classes.mobileShow} ${classes.hintText}`}>{f.suggestionText || f.hintText}</span>
            </td>
          )}
          {!f.hideValue && (
            <td
              style={[S.td, S.mobileBlock, S.value, f.readOnly && S.valueReadOnly] as any}
              className={`${classes.mobileBlock} ${classes.value} ${f.readOnly ? classes.valueReadOnly : ''}`}
              colSpan={f.hideLabel ? 3 : 1}
            >
              <div style={{ position: 'relative' }}>
                {this.renderValue(f)}
                {!!f.premiumLock && (
                  <PremiumLock
                    style={S.lock}
                    tooltipDir="left"
                    onClick={!showPremiumModal ? null : () => showPremiumModal(f.premiumLock)}
                  />
                )}
              </div>
              {f.readOnly && (
                <span
                  style={[S.readOnlyIcon] as any}
                  title="Read Only"
                  className={`${classes.mobileShow} ${classes.readOnlyIcon}`}
                >
                  <Icon name="preview" />
                </span>
              )}
            </td>
          )}
          {!f.hideLabel && (
            <td style={f.readOnly ? ([S.td, S.mobileHide, S.badges] as any) : {}} className={classes.mobileHide}>
              {f.readOnly && (
                <span style={S.readOnlyIcon} title="Read Only" className={classes.readOnlyIcon}>
                  <Icon name="preview" />
                  <span style={[S.mobileHide, S.readOnlyText] as any} className={classes.mobileHide}>
                    This field is read only.
                  </span>
                </span>
              )}
            </td>
          )}
        </tr>,
      );
      rowArray.push(
        <tr key={`${f.id}_validation`} style={S.mobileBlock} className={classes.mobileBlock}>
          {!f.hideLabel && <td style={S.mobileHide} className={classes.mobileHide} />}
          <td
            style={[S.mobileBlock, S.validationRow] as any}
            className={`${classes.mobileBlock} ${classes.validationRow}`}
            colSpan={f.hideLabel || f.hideValue ? 2 : 1}
          >
            <span style={[S.textBase, f.validating ? S.validatingText : S.validationText] as any}>
              {f.validating ? 'Validating...' : validationText}
            </span>
          </td>
          <td style={S.mobileHide} className={classes.mobileHide} />
        </tr>,
      );
    }

    return rowArray;
  }

  renderValue(f: HyperField) {
    return f.type === 'custom' ? (
      f.customInput
    ) : (
      <HyperForm.Field field={f} validationText={this.getValidationText(f)} onChange={this.getHandleChange(f)} />
    );
  }
}
