import { GetExternalUserResponse } from '@hyperfish/antrea-api-contracts';
import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import classes from './styles.module.scss';
import { Card, Button } from '@hyperfish/fishfood';

const connector = connect(
  state => ({
    currentOrg: state.orgs.current,
    user: state.self.user,
    externalUser: state.self.externalUsers as GetExternalUserResponse<'msGraph'>,
  }),
  {
    /* No Methods */
  },
);

type Props = typeof connector['props'];

@connector
export default class Upgrade extends React.Component<Props> {
  render() {
    const { user, currentOrg, externalUser } = this.props;

    let inquiryURI = 'https://www.hyperfish.com/hyperfish-upgrade';
    const params = {
      firstname: getFrom(externalUser)('profile')('givenName').value,
      lastname: getFrom(externalUser)('profile')('surname').value,
      company: getFrom(currentOrg)('name').value,
      email: getFrom(user)('email').value,
      phone: getFrom(externalUser)('profile')('mobilePhone').value,
      title: getFrom(externalUser)('profile')('jobTitle').value,
    };

    let paramString = '';
    for (const key in params) {
      if (!params.hasOwnProperty(key) || params[key] == null) {
        continue;
      }
      paramString += paramString === '' ? '?' : '&';
      paramString += `${key}=${encodeURIComponent(params[key])}`;
    }
    if (paramString) {
      inquiryURI += paramString;
    }

    return (
      <div>
        <Card>
          <div className={classes.upgradeCopy}>
            <h1>Upgrade Today</h1>
            <p>
              You are currently using LiveTiles Directory Lite. Check out the table below to see all the features
              waiting for you when you upgrade to the full Premium product!
            </p>
            <Button variant="solid" color="primary" size="medium" onClick={() => window.open(inquiryURI)}>
              REQUEST A QUOTE
            </Button>
          </div>
        </Card>
        <Card noPadding={true}>
          <table className={cx(classes.tg)}>
            <tbody>
              <tr>
                <th className={classes.tgtopheader}>Features</th>
                <th className={classes.tgtopheader}>Lite</th>
                <th className={classes.tgtopheader}>Standard</th>
                <th className={classes.tgtopheader}>Premium</th>
              </tr>
              <tr>
                <td className={cx(classes.tgsecondheader, classes.tglabel)}>Attributes Managed</td>
                <td className={cx(classes.tgsecondheader, classes.tgchecklist)}>Profile Picture</td>
                <td className={cx(classes.tgsecondheader, classes.tgchecklist)}>
                  <span>All Attributes</span>
                </td>
                <td className={cx(classes.tgsecondheader, classes.tgchecklist)}>All Attributes</td>
              </tr>
              <tr>
                <th className={classes.tgsubheader} colSpan={4}>
                  Automated
                </th>
              </tr>
              <tr>
                <td className={classes.tglabel}>Modes (Analyze, Pilot, Run)</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Hyperbot custom display name</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Hyperbot channels (Email, Skype for Business)</td>
                <td className={classes.tgchecklist}>Email</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>
                  <span>Hyperbot personalities (Relaxed Standard, Formal, Custom)</span>
                </td>
                <td className={classes.tgchecklist}>Standard</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Hyperbot custom from: email address</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>
                  <span>Organizational Unit (OU) directory scoping</span>
                </td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>
                  <span>Scheduled import using Integration Framework</span>
                </td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>
                  Collections – collect and manage specific information from defined groups of employees
                </td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <th className={classes.tgsubheader} colSpan={4}>
                  Easy for Users
                </th>
              </tr>
              <tr>
                <td className={classes.tglabel}>Profile page customizations</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Admin event log</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Email customization</td>
                <td className={classes.tgchecklist}>Branding</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>
                  <span>Admin user log - view profile and email history</span>
                </td>
                <td className={classes.tgchecklist}>Last email sent&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Profile page category customization</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Photo validation</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>SharePoint web part</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Attribute connectors using Integration Framework</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>Office 365</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Employee Self Service Teams Bot</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <th className={classes.tgsubheader} colSpan={4}>
                  Real-time compliance
                </th>
              </tr>
              <tr>
                <td className={classes.tglabel}>Dashboard reporting</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Auto approval &amp; Delegated attribute approval</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Attribute customization &amp; Format validation</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>
                  <span>Profile validation</span>
                </td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <th className={classes.tgsubheader} colSpan={4}>
                  Your Environment
                </th>
              </tr>
              <tr>
                <td className={classes.tglabel}>Office 365 only</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>Hybrid</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
              <tr>
                <td className={classes.tglabel}>On Premises Only</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>Price on Application</td>
              </tr>
              <tr>
                <th className={classes.tgsubheader} colSpan={4}>
                  Support
                </th>
              </tr>
              <tr>
                <td className={classes.tglabel}>
                  <a href="https://www.hyperfish.com/support" target="_blank" rel="noopener noreferrer">
                    Standard Support
                  </a>
                </td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
                <td className={classes.tgchecklist}>&nbsp;</td>
              </tr>
              <tr>
                <td className={classes.tglabel}>
                  <a href="https://www.hyperfish.com/support" target="_blank" rel="noopener noreferrer">
                    Premium Support
                  </a>
                </td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>&nbsp;</td>
                <td className={classes.tgchecklist}>
                  <Dot />
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
    );
  }
}

const Dot = () => (
  <svg width="14" height="14">
    <circle cx="7" cy="7" r="7" fill="#512da8" />
  </svg>
);
