import React from 'react';

import classes from './styles.module.scss';

interface Props {
  tipText: string;
  tipDir?: 'left' | 'right' | 'top' | 'bottom';
}

export const HelpIcon = ({ tipText, tipDir }: Props) => (
  <span
    className={classes.iconContainer}
    {...{
      ['data-tip-' + (tipDir || 'top')]: tipText,
    }}
  >
    <svg viewBox="0 0 14 14" width="14" height="14">
      <path
        fill="#BABABA"
        fillRule="evenodd"
        d="M14 7c0-3.86-3.14-7-7-7S0 3.14 0 7s3.14 7 7 7 7-3.14 7-7M6.952 9.951c-.243 0-.479.1-.652.27a.927.927 0 0 0 0 1.303c.173.172.409.271.652.271.243 0 .48-.1.652-.27a.927.927 0 0 0 0-1.302.93.93 0 0 0-.652-.272M9.75 3.223c.582.657.858 1.573.745 2.515-.213 1.77-1.507 2.4-2.657 2.4a.86.86 0 0 0-.1.002v.448a.737.737 0 0 1-1.474 0v-.594c0-.607.253-1.33 1.574-1.33.712 0 1.094-.37 1.181-1.101.03-.248.053-.877-.378-1.362-.35-.395-.918-.595-1.688-.595-1.667 0-1.715 1.078-1.715 1.2a.737.737 0 0 1-1.474 0c0-.743.573-2.674 3.19-2.674 1.506 0 2.354.593 2.796 1.09"
      />
    </svg>
  </span>
);
