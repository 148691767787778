import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@hyperfish/fishfood';

const Window = styled.div<{ width?: number; height?: number }>`
  width: ${p => (p.width ? `${p.width}px` : '720px')};
  overflow: hidden;
`;

const Wrapper = styled.div<{ offset: number }>`
  display: flex;
  transform: translateX(${p => `-${p.offset * 730}px`});
  transition: transform 0.5s ease-in-out;
`;

const Part = styled.div`
  width: 720px;
  min-width: 720px;
  margin-right: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  & button:first-of-type {
    margin-right: 10px;
  }
`;

interface Props {
  className?: string;
  children: React.ReactNode[];
  next?: string;
  back?: string;
  buttons?: React.HTMLProps<HTMLButtonElement>[];
}

export const MultiPart: React.FC<Props> = ({ children, next, back, className }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className={className}>
      <ButtonWrapper>
        <Button
          onClick={() => setCurrentIndex(currentIndex - 1)}
          disabled={currentIndex === 0}
          variant="outlined"
          color="primary"
          size="small"
        >
          {back || 'Back'}
        </Button>
        <Button
          onClick={() => setCurrentIndex(currentIndex + 1)}
          disabled={children.length - 1 === currentIndex}
          variant="outlined"
          color="primary"
          size="small"
        >
          {next || 'Next'}
        </Button>
      </ButtonWrapper>
      <Window>
        <Wrapper offset={currentIndex}>
          {React.Children.map(children, (x, index) => (
            <Part key={index}>{x}</Part>
          ))}
        </Wrapper>
      </Window>
    </div>
  );
};
