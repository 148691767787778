import {
  AuditEvent,
  ChangeActionEvent,
  ChangeEvent,
  EventListDetails,
  PermissionEvent,
  UpdateOrgSettingEvent,
} from '@hyperfish/antrea-api-contracts/src/events';
import {
  Card,
  getFrom,
  Modal,
  Button,
  FiFilter,
  FiChevronLeft,
  FiChevronRight,
  Form,
  PeoplePicker,
  ApiClientContext,
  FiDownload,
  ApiClient,
} from '@hyperfish/fishfood';
import moment from 'moment';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';

import { DataTable, Tabs } from '../../components';
import { OrgSettingsKeys as keys } from '../../models/api';
import { load as loadEvents, LoadEventsApiCall, LoadEventsParams } from '../../redux/modules/events';
import { clear as clearUsers, load as loadUsers } from '../../redux/modules/externalUsers';
import { load as loadFieldSchema } from '../../redux/modules/fieldSchema';
import { getProviderFromOrg } from '../../redux/modules/self';
import mapArrayToObjectById from '../../utils/mapArrayToObject';
import DisplayNames from './SettingDisplayNames';
import StyleUtil from '../../utils/StyleUtil';
import classes from './styles.module.scss';
import { PropertyValue } from './components/PropertyValue';
import { GetSearchExternalUsersResponse } from '@hyperfish/antrea-api-contracts';
import { getEventSummary } from '../../utils/EventUtil';
import styled from 'styled-components';
import { LogExportModal } from './components/LogExportModal';

const LogTable = DataTable.of({
  timestamp: { label: 'Time', type: DataTable.types.datetime, width: 180 },
  event: { label: 'Event', type: DataTable.types.string },
});

const eventKey = 'Events';
const ADMIN_ID = 'A1000000-0000-0000-0000-000000000000';

const connector = connect(
  state => ({
    currentOrg: state.orgs.current,
    eventsById:
      state.events.eventsDataByKey[eventKey] && mapArrayToObjectById(state.events.eventsDataByKey[eventKey].events),
    eventsData: state.events.eventsDataByKey[eventKey],
    eventsError: state.events.eventsError,
    eventsLoading: state.events.eventsLoading,
    eventsParams: state.events.dataParamsByKey[eventKey],
    fieldSchema: state.fieldSchema.data,
    fieldSchemaLoading: state.fieldSchema.loading,
    provider: getProviderFromOrg(state.orgs.current),
    searchUsers: state.externalUsers.data,
    searchUsersLoading: state.externalUsers.loading,
    totalEvents: getFrom(state.events.eventsDataByKey[eventKey])('total').defaultTo(0),
  }),
  {
    loadEvents,
    loadUsers,
    clearUsers,
    loadFieldSchema,
  },
);

type Props = typeof connector['props'];

interface State {
  dirtyFilters: Props['eventsParams'];
  selectedUsers: { label: string; value: string }[];
  filterOptions: { label: string; value: string }[];
  showExportModal: boolean;
  pageCount: number;
  pages: { [page: number]: EventListDetails };
  pagesLoading: { [page: number]: boolean };
  pagesError: { [page: number]: any };
}

const S = StyleUtil({
  label: {
    fontWeight: 700,
    lineHeight: 1.2,
    paddingRight: 10,
    textAlign: 'right',
    verticalAlign: 'middle',
  },
  th: {
    fontWeight: 700,
    textAlign: 'left',
    paddingRight: 10,
  },
  value: {
    padding: '2.5px 20px 2.5px 0',
    verticalAlign: 'middle',
  },
});

const FlexWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > .Form_field {
    width: 40%;
  }
`;

@connector
@Radium
export default class Log extends React.Component<Props, Partial<State>> {
  private logTable: DataTable<any>;
  private queryByKey = {};
  private queryTimeoutByKey = {};

  constructor(props) {
    super(props);
    this.state = {
      selectedUsers: [],
      showExportModal: false,
      pages: {},
      pagesLoading: {},
      pagesError: {},
    };
  }

  static get maxPages() {
    return 100;
  }

  componentDidMount() {
    const { eventsLoading, eventsParams, fieldSchemaLoading, loadEvents, loadFieldSchema } = this.props;

    if (!eventsLoading) {
      loadEvents(eventsParams, eventKey);
    }
    if (!fieldSchemaLoading) {
      loadFieldSchema();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.eventsData !== nextProps.eventsData) {
      if (!!this.logTable) {
        this.logTable.collapseAll();
      }
      const total = getFrom(nextProps.eventsData)('total').defaultTo(0);
      const pageCount = Math.ceil(total / 25);
      this.setState({ pageCount });
    }

    if (this.props.fieldSchema !== nextProps.fieldSchema) {
      const getFields = (schema, org) => {
        const schemaFields = Object.keys(schema.fields).map(field => ({
          label: schema.fields[field].audienceConfigs[org].ui.title,
          value: schema.fields[field].property,
        }));

        return schemaFields.sort((a, b) => a.label.localeCompare(b.label));
      };

      this.setState({ filterOptions: getFields(nextProps.fieldSchema, nextProps.currentOrg.id) });
    }
  }

  clampNumber = (num, a, b) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

  onUserSearchChange = (newQuery: string) => {
    const { clearUsers, loadUsers } = this.props;

    this.queryByKey[eventKey] = newQuery;

    window.clearTimeout(this.queryTimeoutByKey[eventKey]);

    if (!newQuery) {
      this.queryTimeoutByKey[eventKey] = window.setTimeout(() => {
        clearUsers();
      }, 250);
      return;
    } else {
      this.queryTimeoutByKey[eventKey] = window.setTimeout(() => {
        loadUsers(newQuery, 4);
      }, 250);
    }

    return newQuery;
  };

  onPaginationClick(which: 'prev' | 'next') {
    const { eventsParams, loadEvents } = this.props;
    return () => {
      let newPage = getFrom(eventsParams)('page').defaultTo(0) + (which === 'prev' ? -1 : 1);
      if (newPage < 0) {
        newPage = 0;
      }
      loadEvents({ ...eventsParams, page: newPage }, eventKey);
    };
  }

  fetchPage = (client: ApiClient, options = {} as LoadEventsParams, pageSize: number = 25) => {
    const page = options.page || 0;
    this.setState({
      pagesLoading: { ...this.state.pagesLoading, [page]: true },
      pagesError: { ...this.state.pagesError, [page]: null },
    });

    const params: LoadEventsApiCall = {
      offset: this.clampNumber(page - 1, Log.maxPages, 0) * pageSize,
      limit: pageSize,
      userIds: (options.users || []).map(o => o.value).join(','),
      types: (options.types || []).join(','),
      startDate: options.startDate,
      endDate: options.endDate,
      fields: (options.fields || []).join(','),
    };

    if (!params.types) {
      delete params.types;
    }
    if (!params.userIds || (options.types || []).indexOf('audit') > -1) {
      delete params.userIds;
    }
    if (!params.startDate) {
      delete params.startDate;
    }
    if (!params.endDate) {
      delete params.endDate;
    }
    if (!params.fields) {
      delete params.fields;
    }

    client
      .get('/orgs/current/events', { params })
      .then(res => {
        this.setState({
          pages: { ...this.state.pages, [page]: res.data },
          pagesLoading: { ...this.state.pagesLoading, [page]: false },
        });
      })
      .catch(error => this.setState({ pagesError: { ...this.state.pagesError, [page]: error } }));
  };

  fetchPageIfNeeded = (client: ApiClient, options = {} as LoadEventsParams, pageSize: number = 25) => {
    if (!this.state.pages[options.page] && !this.state.pagesLoading[options.page]) {
      this.fetchPage(client, options, pageSize);
    }
  };

  render() {
    return (
      <ApiClientContext.Consumer>
        {client => (
          <>
            {this.renderHeader(client)}
            <Card noPadding={true}>{this.renderEvents()}</Card>
            <div style={StyleUtil.styles.tables.footer}>{this.renderPagination()}</div>
            {this.state.dirtyFilters && this.renderFilterModal(client)}
            {this.state.showExportModal && this.renderExportModal()}
          </>
        )}
      </ApiClientContext.Consumer>
    );
  }

  renderHeader(client: ApiClient) {
    const { eventsParams, totalEvents, loadEvents } = this.props;
    const _pageSize = 1000;
    const _pages = this.clampNumber((totalEvents / _pageSize + 1) | 0, Log.maxPages, 1);
    const _maxExportableEvents = this.clampNumber(totalEvents, Log.maxPages * _pageSize, 0);
    const activeKey = getFrom(eventsParams)('types')(0).defaultTo('' as any);

    return (
      <div style={StyleUtil.styles.tables.header}>
        <div>
          {activeKey === 'change' && totalEvents > 0 && (
            <Button
              icon={<FiDownload />}
              iconColor="accent"
              size="small"
              variant="link"
              onClick={() => {
                this.setState({ showExportModal: true });
                for (let page = 1; page <= _pages; page++) {
                  this.fetchPage(client, { ...eventsParams, page }, _pageSize);
                }
              }}
            >
              {`export ${_maxExportableEvents} results`}
            </Button>
          )}
          <Button
            icon={<FiFilter />}
            onClick={() => this.setState({ dirtyFilters: JSON.parse(JSON.stringify({ ...eventsParams, page: 0 })) })}
            size="small"
            color="accent"
            variant="text"
            autoWidth
          >
            Filter
          </Button>
          {this.renderPagination()}
        </div>
        <Tabs
          activeKey={activeKey}
          onChange={(key: 'audit' | 'change') =>
            loadEvents({ ...eventsParams, page: 0, types: key ? [key] : null }, eventKey)
          }
          tabs={[
            { label: 'All', key: '' },
            { label: 'Directory Scans', key: 'audit' },
            { label: 'Profile Updates', key: 'change' },
            { label: 'Admin Actions', key: 'permisionsUpdateByAdmin,settingsUpdateByAdmin' },
          ]}
        />
      </div>
    );
  }

  renderEvents() {
    const { eventsData, eventsLoading, eventsError } = this.props;

    return (
      <LogTable
        tableRef={logTable => (this.logTable = logTable)}
        loading={eventsLoading}
        noDataMessage={eventsError ? 'Failed to Load Events' : 'No Events'}
        defaultSortBy="timestamp"
        defaultSortDir="descending"
        renderDetail={({ id }) => this.renderDetail(id)}
        data={
          eventsData &&
          eventsData.events.map(e => ({
            id: e.id,
            timestamp: e.timestamp,
            event: getEventSummary(e),
          }))
        }
      />
    );
  }

  renderDetail = id => {
    const { eventsById } = this.props;
    const event = eventsById[id];
    if (!event) {
      return;
    }

    switch (event.type) {
      case 'audit':
        return this.renderAuditDetail(event);
      case 'change':
      case 'changeAction':
        return this.renderChangeDetail(event);
      case 'permisionsUpdateByAdmin':
        return this.renderPermissionDetail(event);
      case 'settingsUpdateByAdmin':
        return this.renderSettingsDetail(event);
      default:
        return;
    }
  };

  renderAuditDetail(event: AuditEvent) {
    const isFull = ['external', 'online'].indexOf(getFrom(event)('details')('type').value) > -1;
    const auditRecordCount = getFrom(event)('details')('auditRecordCount')
      .defaultTo(0)
      .toLocaleString();

    return (
      <div>
        <p>
          {isFull ? 'Scanned full directory.' : 'Scanned users changed since last scan.'} Found{' '}
          <em>{(auditRecordCount || 0).toLocaleString()}</em> users.
        </p>
      </div>
    );
  }

  renderChangeDetail(event: ChangeEvent | ChangeActionEvent) {
    const { eventsData } = this.props;
    const changeDetail =
      event.type === 'change'
        ? event.details
        : getFrom(eventsData)('references')('changes')(event.details.change.id).value;

    if (!changeDetail) {
      return <p>Could not find details about this Profile Update.</p>;
    }

    return changeDetail.target.delegated ? (
      <p>
        <em>{getFrom(changeDetail)('user')('displayName').value}</em> changed{' '}
        <em>
          {getFrom(changeDetail)('target')('displayName').value}
          {`'s `}
        </em>
        <em>{getFrom(changeDetail)('title').value}</em>
        {['ms-graph-user-manager', 'aduser', 'person'].indexOf(changeDetail.type) > -1 ? (
          '.'
        ) : (
          <>
            {' '}
            from <PropertyValue value={changeDetail.oldValue} /> to <PropertyValue value={changeDetail.newValue} />
          </>
        )}
      </p>
    ) : (
      <p>
        <em>{getFrom(changeDetail)('user')('displayName').value}</em> changed their{' '}
        <em>{getFrom(changeDetail)('title').value}</em>
        {['ms-graph-user-manager', 'aduser', 'person'].indexOf(changeDetail.type) > -1 ? (
          '.'
        ) : (
          <>
            {' '}
            from <PropertyValue value={changeDetail.oldValue} /> to <PropertyValue value={changeDetail.newValue} />
          </>
        )}
      </p>
    );
  }

  renderPermissionDetail(event: PermissionEvent) {
    const { eventsData } = this.props;
    const admin = getFrom(eventsData)('references')('admins')(event.details.adminId).value;
    const audience = getFrom(eventsData)('references')('audiences')(event.details.audienceId).value;
    const role = getFrom(eventsData)('references')('roles')(event.details.roleId).value;

    return (
      <table>
        <tbody>
          <tr>
            <td style={S.label}>Action:</td>
            <td style={S.value}>{getFrom(event)('details')('action').value}</td>
          </tr>
          <tr>
            <td style={S.label}>Role:</td>
            <td style={S.value}>{getFrom(event)('details')('role').value}</td>
          </tr>
          <tr>
            <td style={S.label}>
              {{
                Add: 'To',
                Remove: 'From',
                Update: 'On',
              }[getFrom(event)('details')('action').value] || ''}
              &nbsp;User:
            </td>
            <td style={S.value}>{role ? role.displayName || role.email : 'Unknown'}</td>
          </tr>
          <tr>
            <td style={S.label}>For Collection:</td>
            <td style={S.value}>{this.renderAudienceLink(audience)}</td>
          </tr>
          <tr>
            <td style={S.label}>Action Performed By:</td>
            <td style={S.value}>{admin ? admin.displayName || admin.email : 'Unknown'}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderSettingsDetail(event: UpdateOrgSettingEvent) {
    if (!event) {
      return <p>Could not find details about this Settings Update.</p>;
    }
    const { eventsData } = this.props;
    const adminId = getFrom(event)('details')('adminId').value;
    const audienceId = getFrom(event)('details')('audienceId').value;
    const admin = getFrom(eventsData)('references')('admins')(adminId).value;
    const audience = getFrom(eventsData)('references')('audiences')(audienceId).value;
    const displayName =
      adminId === ADMIN_ID ? '[LiveTiles Directory Support]' : admin ? admin.displayName || admin.email : 'Unknown';

    return (
      <div>
        <p>
          <em>{displayName}</em> modified the following settings for the {this.renderAudienceLink(audience)} collection:
        </p>
        <br />
        <table>
          <thead>
            <tr>
              <th />
              <th style={S.th}>Old Value</th>
              <th style={S.th}>New Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(event.details.settings)
              .map(key => ({
                key,
                old: event.details.settings[key].old,
                new: event.details.settings[key].new,
              }))
              .map(r => {
                if (r.key === 'branding_selfPage') {
                  const keyObject = {};
                  if (!!r.old) {
                    Object.keys(r.old).forEach(key => (keyObject[key] = true));
                  }
                  if (!!r.new) {
                    Object.keys(r.new).forEach(key => (keyObject[key] = true));
                  }
                  return Object.keys(keyObject).map(key => (
                    <tr key={`${event.id}_${r.key}_${key}`}>
                      <td style={S.label}>
                        {this.renderSettingsDetailLabel(`${r.key}_${key}`, event.details.audienceId)}
                      </td>
                      <td style={S.value}>{this.renderSettingsDetailValue(`${r.key}_${key}`, (r.old || {})[key])}</td>
                      <td style={S.value}>{this.renderSettingsDetailValue(`${r.key}_${key}`, (r.new || {})[key])}</td>
                    </tr>
                  ));
                }

                return (
                  <tr key={`${event.id}_${r.key}`}>
                    <td style={S.label}>{this.renderSettingsDetailLabel(r.key, event.details.audienceId)}</td>
                    <td style={S.value}>{this.renderSettingsDetailValue(r.key, r.old)}</td>
                    <td style={S.value}>{this.renderSettingsDetailValue(r.key, r.new)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }

  renderSettingsDetailLabel(key, audienceId) {
    const { currentOrg, fieldSchema } = this.props;
    const manualApprovalRegex = new RegExp(keys.attributes.approval.manualApprovalWildcard.replace('*', '(.*)'), 'i');

    if (manualApprovalRegex.test(key)) {
      const matches = manualApprovalRegex.exec(key);
      const fieldId = matches && matches.length > 1 && matches[1];
      let fieldName = '';

      if (currentOrg && fieldSchema && fieldId && fieldSchema.fields[fieldId]) {
        const field = fieldSchema.fields[fieldId];
        const masterConfig = field.audienceConfigs[currentOrg.id];
        const audienceConfig = currentOrg.id !== audienceId && field.audienceConfigs[audienceId];
        fieldName =
          '"' + ((audienceConfig && audienceConfig.ui.title) || ((masterConfig || {})['ui'] || {}).title) + '"';
      } else {
        fieldName = 'Unknown Field (Field removed from LiveTiles Directory)';
      }

      return `Manually Approve ${fieldName}`;
    }

    return DisplayNames[key] || key;
  }

  renderSettingsDetailValue(key, value) {
    const S = StyleUtil({
      ul: {},
      li: {
        padding: '5px 0',
        lineHeight: 1.2,
      },
      img: {
        height: 45,
        width: 'auto',
      },
    });
    const toneMap = {
      fun: 'Relaxed',
      casual: 'Standard',
      professional: 'Formal',
      custom: 'Custom',
    };

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return 'None';
      }
      return (
        <ul style={S.ul}>
          {value.map((v, i) => (
            <li style={S.li} key={`${key}_${i}`}>
              {this.renderSettingsDetailValue(key, v)}
            </li>
          ))}
        </ul>
      );
    }

    if ([keys.audit.scopes, keys.audit.exclusions].indexOf(key) > -1 && typeof value === 'object') {
      return value.distinguishedName;
    }

    if ([keys.broadcast.exclusions].indexOf(key) > -1 && typeof value === 'object') {
      return value.address;
    }

    if ([keys.broadcast.tone].indexOf(key) > -1) {
      return toneMap[value] || value;
    }

    const valueAsDate = moment(value, moment.ISO_8601);
    if (valueAsDate.isValid()) {
      return valueAsDate.format('MM/DD/YYYY h:mma');
    }

    if (typeof value === 'string') {
      if (value.indexOf('data:image') === 0) {
        return <img src={value} style={S.img} />;
      }
      return value;
    }
    return JSON.stringify(value);
  }

  renderAudienceLink(audience: Props['eventsData']['references']['audiences']['foo']) {
    if (!audience) {
      return '';
    }
    return audience.type === 'Global' ? (
      <a href={`/settings/general`} target="_blank" rel="noopener noreferrer">
        Master
      </a>
    ) : (
      <a href={`/settings/general?audienceId=${audience.id}`} target="_blank" rel="noopener noreferrer">
        {audience.name}
      </a>
    );
  }

  renderPagination() {
    const { eventsParams, eventsData, eventsLoading } = this.props;

    const prevDisabled = !eventsParams || eventsLoading || !eventsData || eventsParams.page === 0;
    const nextDisabled = eventsLoading || !eventsData || !eventsData.events || eventsData.events.length !== 25;

    return (
      <div className={classes.pager}>
        <Button
          onClick={!prevDisabled ? this.onPaginationClick('prev') : undefined}
          disabled={prevDisabled}
          size="small"
          autoWidth
          className={classes.pagerButton}
          // style={[S.item, S.button, prevDisabled && S.disabled] as any}
        >
          <FiChevronLeft />
        </Button>
        <span>{getFrom(eventsParams)('page').defaultTo(0) + 1}</span>
        <Button
          onClick={!nextDisabled ? this.onPaginationClick('next') : undefined}
          disabled={nextDisabled}
          size="small"
          autoWidth
          className={classes.pagerButton}
          // style={[S.item, S.button, nextDisabled && S.disabled] as any}
        >
          <FiChevronRight />
        </Button>
      </div>
    );
  }

  renderFilterModal(client: ApiClient) {
    const { loadEvents } = this.props;
    const { dirtyFilters, filterOptions } = this.state;

    return (
      <Modal
        onClose={() => {
          this.setState({ dirtyFilters: null });
        }}
      >
        <Modal.Header>Event Log Filters</Modal.Header>
        <Form
          onReset={() => this.setState({ dirtyFilters: null })}
          onSubmit={async (values: typeof dirtyFilters, actions) => {
            await loadEvents(values, eventKey);
            actions.setSubmitting(false);
            this.setState({ dirtyFilters: null });
          }}
          initialValues={dirtyFilters}
        >
          <Form.FieldContainer>
            <Form.FieldSet>
              <FlexWrapper>
                <Form.Fields.Date
                  label="Start Date"
                  name="startDate"
                  inputProps={{
                    dateFormat: 'YYYY-MM-DD',
                    closeOnSelect: true,
                  }}
                />
                <Form.Fields.Date
                  label="End Date"
                  name="endDate"
                  inputProps={{
                    dateFormat: 'YYYY-MM-DD',
                    closeOnSelect: true,
                  }}
                />
              </FlexWrapper>
              <Form.Field label="Users" name="users" badAtTips={true}>
                {({ form, invalid, required, disabled, field, inputProps }) => (
                  <PeoplePicker
                    {...field}
                    {...inputProps}
                    isMulti={true}
                    fetchUsers={(q, limit) =>
                      client
                        .get<GetSearchExternalUsersResponse<'msGraph'> | GetSearchExternalUsersResponse<'onPrem'>>(
                          `/external-users/current`,
                          {
                            params: { q, limit },
                          },
                        )
                        .then(res => res.data)
                    }
                    onChange={options => {
                      form.setFieldValue(field.name, options);
                    }}
                    invalid={invalid}
                    required={required}
                    isDisabled={disabled}
                  />
                )}
              </Form.Field>
              <Form.Fields.Select
                label="Attribute"
                name="fields"
                inputProps={{
                  options: filterOptions,
                  isMulti: true,
                }}
              />
            </Form.FieldSet>
          </Form.FieldContainer>
        </Form>
      </Modal>
    );
  }

  renderExportModal() {
    const { pages, pagesLoading, pagesError } = this.state;
    const { totalEvents } = this.props;
    const _pageSize = 1000;
    const _pages = this.clampNumber((totalEvents / _pageSize + 1) | 0, Log.maxPages, 1);
    const onClose = () => {
      this.setState({
        pages: {},
        pagesLoading: {},
        pagesError: {},
        showExportModal: false,
      });
    };

    return (
      <LogExportModal
        onClose={onClose}
        pageCount={_pages}
        pages={pages}
        pagesError={pagesError}
        pagesLoading={pagesLoading}
      />
    );
  }
}
