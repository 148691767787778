import { Modal, PeoplePickerProps, getFrom } from '@hyperfish/fishfood';
import PeoplePicker from '@hyperfish/fishfood/lib/components/PeoplePicker/LegacyPeoplePicker';
import { ApiMsGraphUser } from '@hyperfish/antrea-api-contracts';
import { PermissionResponse } from '@hyperfish/antrea-api-contracts/src/permissions';
import React from 'react';

import { Button, HyperFieldFactory as fields, HyperForm } from '../';
import { ExternalUsersState } from '../../models/client';
import { isGraphUser } from '../../redux/modules/externalUsers';
import StyleUtil from '../../utils/StyleUtil';

type Permission = PermissionResponse['permissions'][0];

export interface Props {
  clearUsers: PeoplePickerProps['clearUsers'];
  disabledUserIds?: PeoplePickerProps['disabledUserIds'];
  fieldNameMap: { [fieldName: string]: string };
  forceSelected: boolean;
  isFree?: boolean;
  loadUsers: PeoplePickerProps['loadUsers'];
  onClose: Modal['props']['onClose'];
  onEdit: (path: string, val: any) => void;
  onSave: () => void;
  permission: Permission;
  users: PeoplePickerProps['users'];
  usersById: ExternalUsersState['dataById'];
  usersLoading: boolean;
}

const roleNameMap = {
  Pilot: 'Pilot Participant',
  Admin: 'Hyperfish Administrator',
};

export const PermissionModal = (props: Props) => {
  const {
    clearUsers,
    disabledUserIds,
    fieldNameMap,
    forceSelected,
    isFree,
    loadUsers,
    onClose,
    onEdit,
    onSave,
    permission,
    users,
    usersById,
    usersLoading,
  } = props;

  const isSelectedAttributes =
    permission.role === 'Approver' &&
    (!!forceSelected ||
      (!!permission.configuration['fieldNames'] && permission.configuration['fieldNames'].length > 0));

  const userToDefinition = (user: Props['usersById']['foo']): Permission['definition'] => {
    return {
      displayName: isGraphUser(user) ? user.displayName : user.displayname,
      email: user.mail,
      entityId: isGraphUser(user) ? user.id : user.objectguid,
      entityType: isGraphUser(user) ? 'msGraph' : 'onPrem',
    };
  };

  const permissionToUser = (permission: Permission): ApiMsGraphUser => {
    if (permission.type !== 'SpecificEntity') {
      return;
    }
    const id = getFrom(permission)('definition')('entityId').value;
    return id == null
      ? ([] as any)
      : ({
          id,
          displayName: getFrom(permission.definition)('displayName').defaultTo(''),
          mail: getFrom(permission.definition)('email').defaultTo(''),
        } as ApiMsGraphUser);
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Header>
        {!!permission.id ? 'Edit' : 'Add'} {roleNameMap[permission.role] || permission.role}
      </Modal.Header>
      {permission.type === 'SpecificEntity' ? (
        !permission.id ? (
          <PeoplePicker
            autofocus={true}
            loadUsers={loadUsers}
            clearUsers={clearUsers}
            users={users}
            disabledUserIds={disabledUserIds}
            value={permissionToUser(permission)}
            onChange={o => onEdit('definition', o == null ? {} : userToDefinition(usersById[o.value]))}
            isMulti={false}
            isLoading={usersLoading}
          />
        ) : (
          <p>{permission.definition.displayName || permission.definition.email} can approve:</p>
        )
      ) : (
        <p>The user in the Manager field can approve{isFree ? ' all attributes.' : ':'}</p>
      )}
      {permission.role === 'Approver' && !isFree ? (
        <HyperForm
          style={{
            container: { marginTop: 20 },
          }}
          fields={[
            fields.radio({
              label: 'All Attributes',
              value: !isSelectedAttributes,
              onChange: () => onEdit('configuration.fieldNames', []),
            }),
            fields.radio({
              label: 'Selected Attributes',
              value: isSelectedAttributes,
              onChange: () => onEdit('configuration.fieldNames', true),
            }),
            isSelectedAttributes &&
              fields.autocompleteMulti({
                label: '',
                value: (permission.configuration['fieldNames'] || []).map(value => ({
                  label: fieldNameMap[value] || value,
                  value,
                })),
                dropdownOptions: Object.keys(fieldNameMap)
                  .concat(
                    (permission.configuration['fieldNames'] || []).filter(
                      n => Object.keys(fieldNameMap).indexOf(n) === -1,
                    ),
                  )
                  .map(value => ({
                    value,
                    label: fieldNameMap[value] || value,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label)),
                onChange: options =>
                  onEdit('configuration.fieldNames', options && options.length > 0 ? options.map(o => o.value) : true),
                props: {
                  maxMenuHeight: 200,
                },
              }),
          ]}
        />
      ) : null}
      <Modal.ButtonContainer>
        <Button size="medium" style={StyleUtil.styles.modals.spacedBtn} onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="medium"
          type="primary"
          onClick={onSave}
          disabled={permission.type === 'SpecificEntity' && !permission.definition.entityId}
        >
          OK
        </Button>
      </Modal.ButtonContainer>
    </Modal>
  );
};
