export default function AtLeastVersion(minVersion, versionToCheck) {
  const minVersionFrags = (minVersion || '').split('.');
  const versionToCheckFrags = (versionToCheck || '').split('.');
  for (let i = 0; i < minVersionFrags.length; ++i) {
    const minVersionFragValue = Number.parseInt(minVersionFrags[i], 10);
    const versionToCheckFragValue = Number.parseInt(versionToCheckFrags[i], 10);
    if (minVersionFragValue > versionToCheckFragValue) {
      return false;
    }
    if (minVersionFragValue < versionToCheckFragValue) {
      return true;
    }
  }
  return true;
}
