export const LOAD = 'antrea/branding/LOAD';
export const LOAD_SUCCESS = 'antrea/branding/LOAD_SUCCESS';
export const LOAD_FAIL = 'antrea/branding/LOAD_FAIL';

export const LOAD_USER = 'antrea/branding/LOAD_USER';
export const LOAD_USER_SUCCESS = 'antrea/branding/LOAD_USER_SUCCESS';
export const LOAD_USER_FAIL = 'antrea/branding/LOAD_CURRENT_FAIL';

export const EDIT = 'antrea/branding/EDIT';
export const EDIT_SETTINGS = 'antrea/branding/EDIT_SETTINGS';
export const EDIT_SELF = 'antrea/branding/EDIT_SELF';

export const SAVE = 'antrea/branding/SAVE';
export const SAVE_SUCCESS = 'antrea/branding/SAVE_SUCCESS';
export const SAVE_FAIL = 'antrea/branding/SAVE_FAIL';

export const CANCEL = 'antrea/branding/CANCEL';
