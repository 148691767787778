import { AnalyticsReportRequest, ApiField, PropertyNotes } from '@hyperfish/antrea-api-contracts';
import {
  getFrom,
  Card,
  LoadingSplash,
  Modal,
  Button,
  FiRotateCcw,
  Table,
  FiPlusCircle,
  FiMinusCircle,
  Select,
  TextField,
  FiCalendar,
  FiPackage,
  Tooltip,
} from '@hyperfish/fishfood';
import moment, { Moment } from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { HyperFieldFactory as hFields, HyperForm, Icon } from '../../components';
import { OrgSettingsKeys } from '../../models/api';
import { load as loadAnalytics, loadDemo as loadDemoAnalytics } from '../../redux/modules/analytics';
import { create as createAudit, loadDetail as loadAudit } from '../../redux/modules/audits';
import { load as loadCollections } from '../../redux/modules/collections';
import { load as loadSchema } from '../../redux/modules/fieldSchema';
import StyleUtil from '../../utils/StyleUtil';
import { HealthGauge } from './components/HealthGauge';
import { NotificationCard } from './components/NotificationCard';
import { TrendChart, TrendLineConfig } from './components/TrendChart';
import classes from './styles.module.scss';
import { ResponseApiAudience } from '@hyperfish/antrea-api-contracts/src/audience';
import { parse } from 'query-string';

const StatTip = styled.span`
  p {
    margin: 8px 0;
  }

  em,
  span {
    vertical-align: middle;
    display: inline-block;
  }

  em {
    font-size: 16px;
    margin-right: 3px;
  }
`;

const connector = connect(
  (state, props) => {
    let { auditId } = parse(props.location.search);
    auditId = (auditId as string) || 'latest';

    return {
      analyticsByKey: state.analytics.data,
      analyticsLoadingByKey: state.analytics.loading,
      auditId,
      audit: state.audits.details[auditId],
      auditError: state.audits.errorDetails[auditId],
      auditLoading: state.audits.loadingDetails[auditId],
      changes: state.changes.changes,
      changesById: state.changes.changesById,
      collections: state.collections.dataIds && state.collections.dataIds.map(id => state.collections.dataById[id]),
      collectionsById: state.collections.dataById,
      collectionsLoading: state.collections.loading,
      org: state.orgs.current,
      memberAuth:
        state.orgs.current &&
        state.orgs.current.type === 'Online' &&
        !state.orgs.current.settings[state.orgs.current.id].auth_providers_microsoftGraph_adminConsent,
      schema: state.fieldSchema.data,
      schemaLoading: state.fieldSchema.loading,
    };
  },
  {
    createAudit,
    loadAnalytics,
    loadCollections,
    loadDemoAnalytics,
    loadAudit,
    loadSchema,
  },
);

interface CollectionOption {
  label: string;
  value: string;
}
type MomentOrSubtraction = Moment | [moment.DurationInputArg1, moment.DurationInputArg2];
interface ZoomOption {
  label: string;
  value: [MomentOrSubtraction, MomentOrSubtraction];
}

type Props = typeof connector['props'];

interface State {
  selectedCollection: CollectionOption;
  selectedZoom: ZoomOption;
  showSelectRangeModal: boolean;
  selectRangeFrom: Moment;
  selectRangeFromValidation: string;
  selectRangeTo: Moment;
  selectRangeToValidation: string;
  selectedFieldIds: string[];
  attributeFilterText: string;
  trendLines: TrendLineConfig[];
}

type CachedState = Pick<State, 'trendLines'> & Pick<State, 'selectedZoom'>;

@connector
export default class Home extends React.Component<Props, State> {
  private COMBINED_COLLECTION = 'COMBINED_COLLECTION';
  private CACHED_STATE_KEY = 'hyperfish/antrea/home/CACHED_FILTER_STATE';
  private auditPingInterval: number;
  private lineColors = [
    '#5b9bd5',
    '#ed7d31',
    '#f2cb61',
    '#4b5cc4',
    '#6ae6c6',
    '#ed8800',
    '#6AE6C6',
    '#8a76c2',
    '#01a1df',
    '#B5E2FA',
    '#F82A2A',
    '#003494',
  ];
  private trendZoomOptions: ZoomOption[] = [
    {
      label: 'The Past Week',
      value: [[1, 'weeks'], null],
    },
    {
      label: 'The Past Month',
      value: [[1, 'months'], null],
    },
    {
      label: 'The Past 3 Months',
      value: [[3, 'months'], null],
    },
    {
      label: 'The Past 6 Months',
      value: [[6, 'months'], null],
    },
    {
      label: 'The Past Year',
      value: [[1, 'years'], null],
    },
    {
      label: 'Select Range',
      value: null,
    },
  ];
  private combinedCollection: CollectionOption = { label: 'Full Directory', value: this.COMBINED_COLLECTION };

  private get defaultSelectedCollection() {
    return this.combinedCollection;
  }
  private get defaultSelectedZoom() {
    return this.allTimeOption;
  }
  private get defaultTrendLines() {
    return [
      {
        audienceId: this.COMBINED_COLLECTION,
        color: this.lineColors[0],
        displayName: 'Full Directory: Average',
        properties: [],
      },
    ];
  }
  // tslint:disable-next-line:variable-name
  private _allTimeOption: ZoomOption;
  private get allTimeOption(): ZoomOption {
    if (!this._allTimeOption) {
      this._allTimeOption = {
        label: 'All Time',
        value: [null, null],
      };
    }
    return this._allTimeOption;
  }

  constructor(props: Props) {
    super(props);
    if (props.org) {
      this.trendZoomOptions.unshift(this.allTimeOption);
    }
    this.state = {
      selectedCollection: this.defaultSelectedCollection,
      selectedZoom: this.defaultSelectedZoom,
      showSelectRangeModal: false,
      selectRangeFrom: null,
      selectRangeFromValidation: null,
      selectRangeTo: null,
      selectRangeToValidation: null,
      selectedFieldIds: [],
      attributeFilterText: '',
      trendLines: this.defaultTrendLines,
      ...(this.getConfig() || {}),
    };
  }

  componentDidMount() {
    const { auditLoading, loadAudit, auditId, loadCollections, loadSchema } = this.props;

    loadCollections();
    loadSchema();
    if (!auditLoading) {
      loadAudit(auditId);
    }
    this.getTrendsIfNeeded(this.state.selectedZoom, this.state.selectedCollection);
  }

  componentWillUnmount() {
    if (this.auditPingInterval) {
      window.clearInterval(this.auditPingInterval);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.auditError !== this.props.auditError) {
      if (this.props.auditError && this.props.auditError.status === 404) {
        if (!this.auditPingInterval) {
          this.auditPingInterval = window.setInterval(() => this.props.loadAudit(this.props.auditId), 5000);
        }
      }
    }

    if (!!prevProps.auditError && !this.props.auditError && this.auditPingInterval) {
      window.clearInterval(this.auditPingInterval);
      this.auditPingInterval = null;
    }

    if (this.state.trendLines !== prevState.trendLines || this.state.selectedZoom !== prevState.selectedZoom) {
      this.storeConfig(this.state);
    }
  }

  render() {
    const { memberAuth, org, audit } = this.props;
    const { selectedCollection } = this.state;

    if (!org) {
      return <LoadingSplash />;
    }

    let healthTimeString = '';
    if (audit && audit.finishedAt) {
      const time = moment(audit.finishedAt, moment.ISO_8601);
      if (time.isValid) {
        healthTimeString = ` AS OF ${time.format('ll')}`;
      }
    }

    const accountCountString = audit ? (
      <>
        <br />
        {audit.combinedStats.totalRecordCount.toLocaleString()} ACCOUNTS
      </>
    ) : null;

    const collectionCountString =
      audit && selectedCollection && selectedCollection.value !== this.COMBINED_COLLECTION ? (
        <>
          <br />
          {getFrom(audit)('audienceStats')(selectedCollection.value)('auditRecordCount')
            .defaultTo(0)
            .toLocaleString()}{' '}
          ACCOUNTS IN {selectedCollection.label.toLocaleUpperCase()}
        </>
      ) : null;

    return (
      <>
        {this.renderNotifications()}
        <Card>
          <Card.Title>DIRECTORY HEALTH TRENDS</Card.Title>
          {this.renderTrendFilters()}
          {this.renderTrendChart()}
        </Card>
        <Card>
          <Card.Title>
            DIRECTORY HEALTH{healthTimeString}
            {accountCountString}
            {collectionCountString}
          </Card.Title>
          {this.renderTableFilters()}
          {this.renderFieldTable()}
        </Card>
        {memberAuth && (
          <div style={{ textAlign: 'center', margin: '40px 20px' }}>
            <h2>
              <Icon name="link-broken" style={{ color: StyleUtil.colors.red }} />
              <span>There&apos;s more to see.</span>
            </h2>
            <p>LiveTiles Directory has not yet been given authorized to access full profiles in your directory.</p>
            <p>
              To see the full report go <Link to="/settings/general">authorize LiveTiles Directory now.</Link>
            </p>
          </div>
        )}
        {this.state.showSelectRangeModal && this.renderSelectRangeModal()}
      </>
    );
  }

  private renderTrendFilters() {
    return (
      <div className={classes.filterContainer}>
        <Select
          options={this.trendZoomOptions}
          value={this.state.selectedZoom}
          onChange={this.setZoom}
          icon={<FiCalendar />}
          isClearable={false}
        />
        <div className={classes.resetContainer}>
          <Button
            onClick={() =>
              this.setState(
                {
                  selectedZoom: this.defaultSelectedZoom,
                  selectedCollection: this.defaultSelectedCollection,
                  trendLines: this.defaultTrendLines,
                },
                () => {
                  this.getTrendsIfNeeded(this.state.selectedZoom, this.state.selectedCollection);
                },
              )
            }
            icon={<FiRotateCcw />}
            className={classes.resetButton}
            size="small"
            autoWidth
          >
            Reset
          </Button>
        </div>
      </div>
    );
  }

  private renderTableFilters() {
    const { collections, collectionsLoading, org } = this.props;

    const getLabel = (collection: ResponseApiAudience) => {
      if (collection == null) {
        return '';
      }

      if (collection.type === 'Global' || collection.id === org.id) {
        return 'Master Collection';
      } else {
        return getFrom(collection)('definition')('name').defaultTo('');
      }
    };

    let options: { label: string; value: string }[] = [
      this.combinedCollection,
      ...collections.filter(c => c.type === 'Global').map(c => ({ label: getLabel(c), value: c.id })),
    ];
    if (collections) {
      const otherCollections = collections
        .filter(c => c.type !== 'Global')
        .map(c => ({
          label: getLabel(c),
          value: c.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      options = options.concat(otherCollections);
    }

    return (
      <div className={classes.filterContainer}>
        <TextField
          placeholder="Filter by attribute..."
          type="search"
          value={this.state.attributeFilterText}
          onChange={e => this.setState({ attributeFilterText: e.currentTarget.value })}
        />
        <Select
          isLoading={collectionsLoading}
          options={options}
          value={this.state.selectedCollection}
          onChange={this.setCollection}
          isClearable={false}
          icon={<FiPackage />}
        />
      </div>
    );
  }

  private renderNotifications() {
    const { changes, changesById, org } = this.props;

    const approveNotifications =
      !!changes && !!changesById
        ? changes.filter(c => changesById[c] && changesById[c].status === 'pending').length
        : null;

    const mode = org && org.settings[org.id][OrgSettingsKeys.global.mode];

    return (
      <div className={classes.notificationContainer}>
        <NotificationCard
          icon="bell"
          color="#f82a2a"
          bg={require('../../assets/images/changes-icon.png')}
          to="/approve"
        >
          <span
            style={{
              fontWeight: 300,
              fontSize: 60,
              lineHeight: 1,
              color: StyleUtil.colors.blue,
              display: 'block',
            }}
          >
            {approveNotifications == null ? '-' : approveNotifications > 99 ? '100+' : approveNotifications}
          </span>
          <span
            style={{
              display: 'block',
              color: StyleUtil.colors.text.dark,
              fontSize: 30,
              lineHeight: 1.3,
            }}
          >
            {`change${approveNotifications !== 1 ? 's' : ''}`}
          </span>
          <span
            style={{
              display: 'block',
              fontWeight: 700,
              fontSize: 14,
              color: '#3a3a3a',
            }}
          >
            {`${approveNotifications !== 1 ? 'are' : 'is'} awaiting review for approval.`}
          </span>
        </NotificationCard>
        <NotificationCard
          icon="information"
          color="#6AE6C6"
          bg={require('../../assets/images/pilot-icon.png')}
          style={{
            paddingTop: 70,
            paddingLeft: 50,
            marginRight: 0,
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 14,
              color: '#3a3a3a',
              display: 'block',
              lineHeight: 1,
            }}
          >
            LiveTiles Directory is {mode !== 'Run' ? 'still ' : ''}in
          </span>
          <span
            style={{
              color: {
                Pilot: '#6AE6C6',
                Analyze: '#0095ce',
                Run: '#8a76c2',
              }[mode],
              display: mode !== 'Analyze' ? 'inline-block' : 'block',
              fontSize: 60,
              fontWeight: 300,
              letterSpacing: -1,
              lineHeight: 1,
              marginLeft: mode !== 'Analyze' ? -6 : 0,
              marginRight: 5,
            }}
          >
            {mode}
          </span>
          <span
            style={{
              fontSize: 30,
              color: '#3a3a3a',
              display: 'inline-block',
            }}
          >
            mode.
          </span>
        </NotificationCard>
      </div>
    );
  }

  private renderTrendChart() {
    const { analyticsByKey, analyticsLoadingByKey } = this.props;
    const { selectedZoom, trendLines } = this.state;

    const lines = trendLines.map(l => ({
      ...l,
      properties: l.properties && l.properties.length > 0 ? l.properties : this.getAllFields().map(f => f.property),
    }));

    const reports = {};
    let isLoading = false;
    lines.forEach(l => {
      const key = this.getKeyFromZoom(selectedZoom, { value: l.audienceId });
      reports[l.audienceId] = analyticsByKey[key];
      if (analyticsLoadingByKey[key]) {
        isLoading = true;
      }
    });

    return (
      <TrendChart
        reportsByAudience={reports}
        lines={lines}
        removeLineAtIndex={this.removeLineAtIndex}
        isLoading={isLoading}
      />
    );
  }

  private renderFieldTable() {
    const { schema, schemaLoading, auditLoading, org, audit } = this.props;
    const { attributeFilterText, selectedCollection } = this.state;
    const { label: collectionName, value: collectionId } = selectedCollection;

    if (schemaLoading || auditLoading || !audit || !schema) {
      return <LoadingSplash />;
    }

    const getLabel = (field: ApiField): string => {
      const config = field.audienceConfigs[collectionId] || field.audienceConfigs[org.id];
      return config.ui.title;
    };

    const getStat = (field: ApiField) => {
      if (!audit || auditLoading) {
        return null;
      }
      const statCollection =
        collectionId === this.COMBINED_COLLECTION
          ? getFrom(audit)('combinedStats').value
          : getFrom(audit)('audienceStats')(collectionId).value;

      const stats = getFrom(statCollection)('stats').defaultTo([]);

      for (const prop of stats) {
        if (prop.propertyName === field.property) {
          return prop;
        }
      }
    };

    const getTrendAction = (properties: string[], label: string) => {
      const notIncluded = this.getIndexOfLine({ audienceId: collectionId, properties }) === -1;
      return {
        key: 'CHART_ACTION',
        disabled: notIncluded && this.state.trendLines.length >= this.lineColors.length,
        onClick: this.getHandleToggleLine(collectionId, properties, collectionName, label),
        icon: notIncluded ? <FiPlusCircle /> : <FiMinusCircle />,
        color: notIncluded ? 'accent' : 'error',
        ariaLabel: notIncluded ? 'Add to chart' : 'Remove from chart',
        size: 'large' as 'large',
      };
    };

    const fieldToRow = (f: ApiField) => {
      const stat = getStat(f);
      const passed = getFrom(stat)('passed').value;
      const failed = getFrom(stat)('failed').value;
      const missing = getFrom(stat)('missing').value;
      const percent = !stat ? '-' : Math.round((passed / (passed + failed + missing)) * 100);

      return (
        <Table.Row key={`${collectionId}_${f.id}`}>
          <Table.Cell>
            <Highlighter textToHighlight={getLabel(f)} searchWords={[attributeFilterText]} />
          </Table.Cell>
          <Table.Cell width={200} align="center">
            <Tooltip
              a11y={false}
              placement="left"
              content={
                <StatTip>
                  <p>
                    <em>{passed == null ? '-' : passed.toLocaleString()}</em>
                    <span>valid values</span>
                  </p>
                  <p>
                    <em>{failed == null ? '-' : failed.toLocaleString()}</em>
                    <span>do not pass attribute rules</span>
                  </p>
                  <p>
                    <em>{missing == null ? '-' : missing.toLocaleString()}</em>
                    <span>missing values</span>
                  </p>
                </StatTip>
              }
            >
              <span>
                <HealthGauge stat={stat} />
                <span className={classes.percent}>{percent}%</span>
              </span>
            </Tooltip>
          </Table.Cell>
          <Table.Actions actions={[getTrendAction([f.property], getLabel(f))]} />
        </Table.Row>
      );
    };

    const rows = [];
    const allFields = this.getAllFields();

    if (attributeFilterText) {
      const lowerText = attributeFilterText.toLowerCase();
      rows.push(
        ...allFields
          .filter(f => {
            if (f.internal) {
              return false;
            }
            if (
              getLabel(f)
                .toLowerCase()
                .indexOf(lowerText) !== -1
            ) {
              return true;
            }
            return false;
          })
          .map(fieldToRow),
      );
    } else {
      const photoProps = ['profilePicture', 'thumbnailphoto'];
      const tableGroups: { id: string; groupName: string; fields: ApiField[] }[] = [];
      const groups = schema.groups[collectionId] || schema.groups[org.id];
      for (const group of groups) {
        const fields = [];
        for (const fieldId of group.fields) {
          const field = schema.fields[fieldId];
          if (!field) {
            continue;
          }
          fields.push(field);
        }
        tableGroups.push({ id: group.id, groupName: group.title, fields });
      }
      const allFields = this.getAllFields();
      const otherFields = [];
      for (const field of allFields) {
        if (groups.filter(g => g.fields.indexOf(field.id) !== -1).length > 0) {
          continue;
        }
        if (field.internal) {
          continue;
        }
        if (photoProps.indexOf(field.property) !== -1) {
          rows.push(fieldToRow(field));
          continue;
        }
        otherFields.push(field);
      }
      if (otherFields.length > 0) {
        tableGroups.push({ id: 'OTHER', groupName: 'Other', fields: otherFields });
      }

      rows.push(
        ...tableGroups.map(r => (
          <Table.Group
            key={r.id}
            title={r.groupName}
            actions={[getTrendAction(r.fields.map(f => f.property), r.groupName)]}
          >
            {r.fields.map(fieldToRow)}
          </Table.Group>
        )),
      );

      // rows.push(
      //   ...tableGroups.map(r => (
      //     <React.Fragment key={r.id}>
      //       <Table.Row isGroupHead={true}>
      //         <Table.Cell colSpan={2}>{r.groupName}</Table.Cell>
      //         <Table.Actions actions={[getTrendAction(r.fields.map(f => f.property), r.groupName)]} />
      //       </Table.Row>
      //       {r.fields.map(fieldToRow)}
      //     </React.Fragment>
      //   )),
      // );

      let totalStat: PropertyNotes;
      allFields.forEach(field => {
        if (field.internal) {
          return;
        }
        const stat = getStat(field);
        if (!stat) {
          return;
        }
        if (totalStat == null) {
          totalStat = { propertyName: 'Total', passed: 0, failed: 0, missing: 0 };
        }
        totalStat.passed += stat.passed;
        totalStat.failed += stat.failed;
        totalStat.missing += stat.missing;
      });
      const totalPercent = !totalStat
        ? '-'
        : Math.round((totalStat.passed / (totalStat.passed + totalStat.failed + totalStat.missing)) * 100);

      rows.unshift(
        <Table.Row key={collectionId}>
          <Table.Cell>Average Health</Table.Cell>
          <Table.Cell width={200} align="center">
            <Tooltip
              a11y={false}
              placement="left"
              content={
                <StatTip>
                  <p>
                    <em>{totalStat.passed == null ? '-' : totalStat.passed.toLocaleString()}</em>
                    <span>valid values</span>
                  </p>
                  <p>
                    <em>{totalStat.failed == null ? '-' : totalStat.failed.toLocaleString()}</em>
                    <span>do not pass attribute rules</span>
                  </p>
                  <p>
                    <em>{totalStat.missing == null ? '-' : totalStat.missing.toLocaleString()}</em>
                    <span>missing values</span>
                  </p>
                </StatTip>
              }
            >
              <span>
                <HealthGauge stat={totalStat} />
                <span className={classes.percent}>{totalPercent}%</span>
              </span>
            </Tooltip>
          </Table.Cell>
          <Table.Actions actions={[getTrendAction([], 'Average')]} />
        </Table.Row>,
      );
    }

    return (
      <Table className={classes.table}>
        <Table.Head>
          <Table.Row>
            <Table.Header>Attribute</Table.Header>
            <Table.Header width={200} align="center">
              Health
            </Table.Header>
            <Table.Header align="center" width={100}>
              Chart
            </Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  }

  private renderSelectRangeModal() {
    const onClose = () => this.setState({ showSelectRangeModal: false });
    const onSave = () => {
      this.setZoom({
        label: `${this.state.selectRangeFrom.format('ll')} - ${this.state.selectRangeTo.format('ll')}`,
        value: [this.state.selectRangeFrom, this.state.selectRangeTo],
      });
      this.setState({ showSelectRangeModal: false });
    };

    return (
      <Modal onClose={onClose}>
        <Modal.Header>Select date range</Modal.Header>
        <HyperForm
          fields={[
            hFields.date({
              label: 'From',
              value: this.state.selectRangeFrom,
              validationText: this.state.selectRangeFromValidation,
              onChange: val => {
                if (moment.isMoment(val)) {
                  this.setState({ selectRangeFrom: val });
                } else {
                  this.setState({ selectRangeFromValidation: 'Please input a valid date' });
                }
              },
              props: {
                closeOnSelect: true,
              },
            }),
            hFields.date({
              label: 'To',
              value: this.state.selectRangeTo,
              validationText: this.state.selectRangeToValidation,
              onChange: val => {
                if (moment.isMoment(val)) {
                  this.setState({ selectRangeTo: val, selectRangeToValidation: null });
                } else {
                  this.setState({ selectRangeToValidation: 'Please input a valid date' });
                }
              },
              props: {
                closeOnSelect: true,
              },
            }),
          ]}
        />
        <Modal.ButtonContainer style={{ marginTop: 155 }}>
          <Button size="medium" onClick={onClose} style={StyleUtil.styles.modals.spacedBtn}>
            Cancel
          </Button>
          <Button
            size="medium"
            variant="solid"
            color="primary"
            disabled={this.state.selectRangeFromValidation != null || this.state.selectRangeToValidation != null}
            onClick={onSave}
          >
            OK
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  private getKeyFromZoom = (zoom: ZoomOption, audience: Pick<CollectionOption, 'value'>): string => {
    const from = zoom.value[0] || moment().startOf('day');
    const to = zoom.value[1] || moment().endOf('day');
    return `${audience.value}_${from.valueOf()}_${to.valueOf()}`;
  };

  private getTrendsIfNeeded = (zoom: ZoomOption, audience: Pick<CollectionOption, 'value'>) => {
    const { loadAnalytics, analyticsByKey, analyticsLoadingByKey } = this.props;
    zoom = zoom || this.state.selectedZoom;
    const key = this.getKeyFromZoom(zoom, audience);
    if (analyticsByKey[key] || analyticsLoadingByKey[key]) {
      return;
    }

    const [from, to] = this.getZoomMoments(zoom);

    const body: AnalyticsReportRequest = {
      start: from.toISOString(),
      end: to.toISOString(),
      interval: 'week',
    };

    if (audience && audience.value !== this.COMBINED_COLLECTION) {
      body.audienceId = audience.value;
    }

    if (to.diff(from, 'months') <= 1) {
      body.interval = 'day';
      body.width = 1;
    } else if (to.diff(from, 'months') <= 6) {
      body.interval = 'week';
      body.width = 1;
    } else if (to.diff(from, 'years') <= 1) {
      body.interval = 'week';
      body.width = 2;
    } else {
      body.interval = 'month';
      body.width = 1;
    }

    loadAnalytics(key, body);
  };

  private setCollection = (selectedCollection: CollectionOption) => {
    this.setState({ selectedCollection });
  };

  private setZoom = (selectedZoom: ZoomOption) => {
    if (selectedZoom.value == null) {
      this.setState({
        showSelectRangeModal: true,
        selectRangeFrom: moment()
          .subtract(6, 'months')
          .startOf('day'),
        selectRangeFromValidation: null,
        selectRangeTo: moment().endOf('day'),
        selectRangeToValidation: null,
      });
      return;
    }

    const { trendLines } = this.state;
    const trendAudiences = [this.state.selectedCollection.value];
    trendLines.forEach(({ audienceId }) => {
      if (trendAudiences.indexOf(audienceId) === -1) {
        trendAudiences.push(audienceId);
      }
    });
    trendAudiences.forEach(value => this.getTrendsIfNeeded(selectedZoom, { value }));

    this.setState({ selectedZoom });
  };

  private getHandleToggleLine = (
    audienceId: string,
    properties: string[],
    collectionName: string,
    displayName: string,
  ) => {
    return () => {
      const index = this.getIndexOfLine({ audienceId, properties });

      if (index !== -1) {
        this.removeLineAtIndex(index);
        return;
      }

      if (this.state.trendLines.length >= this.lineColors.length) {
        return;
      }
      this.getTrendsIfNeeded(this.state.selectedZoom, this.state.selectedCollection);
      this.setState(state => ({
        trendLines: [
          ...state.trendLines,
          {
            audienceId,
            properties,
            displayName: `${collectionName}: ${displayName}`,
            color: this.lineColors[state.trendLines.length],
          },
        ],
      }));
    };
  };

  private getIndexOfLine = (
    line: Pick<TrendLineConfig, 'audienceId'> & Pick<TrendLineConfig, 'properties'>,
  ): number => {
    const { trendLines } = this.state;

    for (let i = 0; i < trendLines.length; i++) {
      const l = trendLines[i];
      if (l.audienceId !== line.audienceId) {
        continue;
      }
      if (l.properties.length !== line.properties.length) {
        continue;
      }
      if (l.properties.length === 0 && line.properties.length === 0) {
        return i;
      }
      if (l.properties.filter(p => line.properties.indexOf(p) === -1).length > 0) {
        continue;
      }
      return i;
    }

    return -1;
  };

  private removeLineAtIndex = (index: number) => {
    const newTrendLines = [...this.state.trendLines];
    newTrendLines.splice(index, 1);
    this.setState({ trendLines: newTrendLines });
  };

  private getAllFields(): ApiField[] {
    const { schema } = this.props;
    if (!schema) {
      return [];
    }
    return Object.keys(schema.fields)
      .map(id => schema.fields[id])
      .filter(field => !!field && !field.internal);
  }

  private storeConfig(state: CachedState) {
    if (!localStorage) {
      return;
    }

    const [from, to] = state.selectedZoom.value;

    localStorage.setItem(
      this.CACHED_STATE_KEY,
      JSON.stringify({
        trendLines: state.trendLines,
        selectedZoom: {
          ...state.selectedZoom,
          value: [from ? from.valueOf() : null, to ? to.valueOf() : null],
        },
      }),
    );
  }

  private getConfig(): CachedState {
    if (!localStorage) {
      return;
    }

    const rawString = localStorage.getItem(this.CACHED_STATE_KEY);
    if (!rawString) {
      return;
    }
    const raw = JSON.parse(rawString) as CachedState;
    if (!raw) {
      return;
    }

    const [from, to] = getFrom(raw)('selectedZoom')('value').defaultTo([null, null]);

    return {
      ...raw,
      selectedZoom: {
        ...getFrom(raw)('selectedZoom').value,
        value: [typeof from === 'number' ? moment(from) : from, typeof to === 'number' ? moment(to) : to],
      },
    };
  }

  private getZoomMoments(zoom: ZoomOption): [Moment, Moment] {
    const [rawFrom, rawTo] = zoom.value;

    const getMoment = (m: MomentOrSubtraction, d: Moment): Moment => {
      if (m == null || (moment.isMoment(m) && !m.isValid())) {
        return d;
      }
      if (moment.isMoment(m)) {
        return m;
      }
      return moment()
        .subtract(...m)
        .startOf('day');
    };

    const from = getMoment(
      rawFrom,
      moment.min(
        moment(getFrom(this.props.org)('createdAt').value, moment.ISO_8601).startOf('day'),
        moment()
          .subtract(1, 'weeks')
          .startOf('day'),
      ),
    );

    const to = getMoment(rawTo, moment().endOf('day'));

    return [from, to];
  }
}
