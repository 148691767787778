import React from 'react';

import { Radio } from '../Radio';
import classes from './styles.module.scss';

interface Props {
  id?: string;
  value: string;
  label?: string;
  onChange: (value: string) => void;
  options: {
    value: string;
    label: string;
  }[];
}

interface State {
  id: string;
}

export class RadioGroup extends React.Component<Props, State> {
  static instanceCount = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.id || `RadioGroup-${++this.constructor['instanceCount']}`,
    };
  }

  render() {
    const { options, value, onChange, label } = this.props;
    const { id } = this.state;

    return (
      <fieldset>
        {label && <legend className={classes.legend}>{label}</legend>}
        {options.map(o => (
          <Radio
            name={id}
            key={o.value}
            checked={value === o.value}
            label={o.label}
            onChange={e => (e.target.checked ? onChange(o.value) : null)}
            style={{ marginBottom: 20 }}
          />
        ))}
      </fieldset>
    );
  }
}
