import { CSSProperties } from 'react';

export type ExtendedStyles = CSSProperties & {
  ':hover'?: CSSProperties;
  ':active'?: CSSProperties;
};

type StylesObject<T> = { [P in keyof T]: ExtendedStyles };

const typerFunction = <T>(object: StylesObject<T> & T): { [key in keyof T]: ExtendedStyles } => {
  return object as any;
};

export default typerFunction;
