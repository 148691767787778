import { Button as FFButton } from '@hyperfish/fishfood/lib/components/Button';
import { Card } from '@hyperfish/fishfood/lib/components/Card';
import { LoadingSplash } from '@hyperfish/fishfood/lib/components/LoadingSplash';
import { Modal } from '@hyperfish/fishfood/lib/components/Modal';
import { TextField } from '@hyperfish/fishfood/lib/components/TextField';
import { Select } from '@hyperfish/fishfood/lib/components/Select';
import { Tooltip } from '@hyperfish/fishfood/lib/components/Tooltip';
import { FiDownload, FiMail, FiUser } from '@hyperfish/fishfood/lib/components/Icon';
import { UserUtil, SchemaUtil } from '@hyperfish/fishfood/lib/utils';
import { ApiClientContext, ApiClient } from '@hyperfish/fishfood/lib/utils/ApiClient';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, DataTable, Icon, ModalKeys, PremiumLock, Tabs, TemplatePreview, UserSummary } from '../../components';
import { isFree, isEditor } from '../../redux/modules/auth';
import { load as loadEmails, loadDetail } from '../../redux/modules/emails';
import {
  clear as clearUsers,
  load as loadUsers,
  loadDetail as loadUserDetail,
} from '../../redux/modules/externalUsers';
import { load as loadSchema } from '../../redux/modules/fieldSchema';
import { show as showPremiumModal } from '../../redux/modules/premiumModals';
import { clear as clearViolationUsers, load as loadViolationUsers } from '../../redux/modules/violationUsers';
import classes from './styles.module.scss';
import StyleUtil from '../../utils/StyleUtil';
import { ViolationUsersExportModal } from './components/ViolationUsersExportModal';
import { EmailsResponse } from '../../models/api';

interface Option {
  label: string;
  value: string;
}

const tableColumns = {
  displayName: { label: 'Display Name', type: DataTable.types.string, hidden: false },
  mail: { label: 'Mail', type: DataTable.types.string },
};

const UserTable = DataTable.of(tableColumns);

const EmailTable = DataTable.of({
  createdAt: { label: 'TimeStamp', type: DataTable.types.datetime, sortable: true },
  subject: { label: 'Subject', type: DataTable.types.string, sortable: true },
});

const connector = connect(
  state => ({
    currentOrg: state.orgs.current,
    currentQuery: state.externalUsers.q,
    detailLoading: state.emails.loadingDetailById,
    details: state.emails.detailById,
    disconnected:
      !state.orgs.current ||
      !state.orgs.current.connected ||
      !state.orgs.current.devices ||
      state.orgs.current.devices.length === 0,
    emails: state.emails.data,
    emailsLoading: state.emails.loading,
    fieldSchema: state.fieldSchema.data,
    isFree: isFree(state),
    userDetails: state.externalUsers.detailById,
    userDetailsError: state.externalUsers.detailByIdError,
    userDetailsLoading: state.externalUsers.detailByIdLoading,
    users: state.externalUsers.data,
    usersLoading: state.externalUsers.loading,
    violationUsers: state.violationUsers.data,
    violationUsersLoading: state.violationUsers.loading,
    violationUsersRequest: state.violationUsers.request,
    editor: isEditor(state),
  }),
  {
    clearUsers,
    loadDetail,
    loadEmails,
    loadSchema,
    loadUserDetail,
    loadUsers,
    showPremiumModal,
    loadViolationUsers,
    clearViolationUsers,
  },
);

type Props = typeof connector['props'];

interface State {
  mailUser: string;
  detailId: string;
  tab: 'name' | 'field';
  showViolatingUsersExportModal: boolean;
  emails: { [email: string]: EmailsResponse[] };
  emailsLoading: { [email: string]: boolean };
  emailsError: { [email: string]: any };
}

@connector
@Radium
export default class User extends React.Component<Props, Partial<State>> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: 'name',
      emails: {},
      emailsLoading: {},
      emailsError: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.props.clearUsers();
  }

  componentDidMount() {
    this.props.loadSchema();
  }

  viewEmails = (mailUser: string) => {
    this.setState({ mailUser });
    this.props.loadEmails({ email: mailUser, limit: this.props.isFree ? 1 : 100 });
  };

  viewDetail = ({ id: detailId }) => {
    this.setState({ detailId });
    this.props.loadDetail(detailId);
  };

  getFields() {
    const { fieldSchema, currentOrg } = this.props;

    if (!fieldSchema || !fieldSchema.fields || !currentOrg) {
      return [];
    }

    const getProperty = (key: string) => getFrom(fieldSchema.fields)(key)('property').value;
    const getTitle = (key: string) =>
      `${getFrom(fieldSchema.fields)(key)('audienceConfigs')(currentOrg.id)('ui')('title').value} (${getProperty(
        key,
      )})`;

    return Object.keys(fieldSchema.fields)
      .filter(key => !fieldSchema.fields[key].internal)
      .map(key => ({
        label: getTitle(key),
        value: getProperty(key),
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  getUsers() {
    const { users, violationUsers } = this.props;
    const { tab } = this.state;

    if (tab === 'name') {
      return (
        users &&
        ((users.profileType === 'msGraph' &&
          users.profiles.map(u => ({
            id: u.id,
            displayName: u.displayName,
            mail: u.mail,
          }))) ||
          (users.profileType === 'onPrem' &&
            users.profiles.map(u => ({
              id: u.objectguid,
              displayName: u.displayname,
              mail: u.mail,
            }))))
      );
    } else if (tab === 'field') {
      if (violationUsers == null) {
        return null;
      }
      return violationUsers.map(u => ({
        id: u.userId,
        mail: u.email,
        displayName: '',
      }));
    }
  }

  handleFieldChange = (option: Option) => {
    const { clearViolationUsers, loadViolationUsers } = this.props;

    if (option == null) {
      return clearViolationUsers();
    }

    loadViolationUsers({
      properties: {
        [('/' + option.value) as string]: 'invalid',
      },
    });
  };

  render() {
    const {
      clearUsers,
      clearViolationUsers,
      currentOrg,
      currentQuery,
      disconnected,
      loadUserDetail,
      loadUsers,
      usersLoading,
      violationUsers,
      violationUsersRequest,
      violationUsersLoading,
      editor,
    } = this.props;
    const { tab } = this.state;

    if (disconnected && currentOrg && currentOrg.type !== 'Online') {
      return (
        <Card style={{ textAlign: 'center' }}>
          <h2>
            <Icon
              name="link-broken"
              style={{
                color: StyleUtil.colors.red,
                fontSize: '.8em',
              }}
            />
            <span>LiveTiles Directory is not connected to your directory</span>
          </h2>
          <p>
            <Link to="/settings/general">Connect your directory</Link>
            &nbsp; to view your users.
          </p>
        </Card>
      );
    }

    const fieldOptions = this.getFields();
    const fieldValue =
      violationUsersRequest &&
      violationUsersRequest.properties &&
      (Object.keys(violationUsersRequest.properties)[0] || '').substr(1);

    return (
      <ApiClientContext.Consumer>
        {client => (
          <div style={editor ? { maxWidth: '965px', margin: '10px auto' } : {}}>
            <div style={StyleUtil.styles.tables.header}>
              <div>
                {tab === 'field' && Array.isArray(violationUsers) && violationUsers.length > 0 && (
                  <FFButton
                    icon={<FiDownload />}
                    onClick={() => {
                      for (let index = 0; index < violationUsers.length; index++) {
                        const email = getFrom(violationUsers[index])('email').value;
                        this.fetchEmailsIfNeeded(client, { email, limit: 1 });
                      }
                      this.setState({ showViolatingUsersExportModal: true });
                    }}
                    size="small"
                    color="accent"
                    variant="text"
                    autoWidth
                  >
                    {`Export (${violationUsers.length})`}
                  </FFButton>
                )}
              </div>
              <Tabs
                onChange={(tab: State['tab']) => {
                  this.setState({ tab });
                  if (tab === 'field') {
                    clearViolationUsers();
                  }
                  if (tab === 'name') {
                    clearUsers();
                  }
                }}
                activeKey={tab}
                tabs={[{ label: 'By Name', key: 'name' }, { label: 'By Missing / Incorrect Attribute', key: 'field' }]}
              />
            </div>
            <div style={StyleUtil.styles.tables.header_noFlex}>
              {tab === 'field' ? (
                <Select
                  options={fieldOptions}
                  value={fieldOptions.filter(o => o.value === fieldValue)}
                  placeholder="Select an attribute..."
                  onChange={this.handleFieldChange}
                  isClearable={true}
                />
              ) : (
                <TextField
                  placeholder="Search for users..."
                  onChange={({ currentTarget: { value } }) => (value ? loadUsers(value, 50) : clearUsers())}
                />
              )}
            </div>
            <Card noPadding={true}>
              <UserTable
                columns={
                  tab === 'name'
                    ? tableColumns
                    : { ...tableColumns, displayName: { ...tableColumns.displayName, hidden: true } }
                }
                disableExpandAll={true}
                noDataMessage={
                  tab === 'field'
                    ? !!fieldValue
                      ? 'No users found.'
                      : 'Select an attribute to get started.'
                    : !!currentQuery
                    ? 'No users found. Try searching for something else.'
                    : 'Search for something to get started.'
                }
                loading={tab === 'field' ? violationUsersLoading : usersLoading}
                renderDetail={this.renderDetail}
                onExpandRow={({ id }) => loadUserDetail(id)}
                actions={[
                  {
                    icon: <FiUser />,
                    title: 'View User Profile',
                    onAction: ({ id }) => window.open(`/users/${encodeURIComponent(id)}`),
                  },
                  !editor && {
                    icon: <FiMail />,
                    title: 'View User Emails',
                    onAction: ({ mail }) => this.viewEmails(mail as string),
                  },
                ].filter(Boolean)}
                data={this.getUsers()}
              />
            </Card>
            {this.state.detailId == null && this.state.mailUser != null && this.renderMailModal()}
            {this.state.detailId != null && this.renderDetailModal()}
            {!!this.state.showViolatingUsersExportModal && this.renderViolationUsersExportModal()}
          </div>
        )}
      </ApiClientContext.Consumer>
    );
  }

  fetchEmails(client: ApiClient, params: { email: string; limit: number }) {
    const { email } = params;
    this.setState({
      emailsLoading: { ...this.state.emailsLoading, [email]: true },
      emailsError: { ...this.state.emailsError, [email]: null },
    });

    client
      .get('/orgs/current/emails', { params })
      .then(res => {
        this.setState({
          emails: { ...this.state.emails, [email]: res.data },
          emailsLoading: { ...this.state.emailsLoading, [email]: false },
        });
      })
      .catch(error => {
        this.setState({ emailsError: { ...this.state.emailsError, [email]: error } });
      });
  }

  fetchEmailsIfNeeded(client: ApiClient, params: { email: string; limit: number }) {
    const { emails, emailsLoading } = this.state;
    if (!emails[params.email] && !emailsLoading[params.email]) {
      this.fetchEmails(client, params);
    }
  }

  renderDetail = ({ id }) => {
    const { userDetails, userDetailsLoading, userDetailsError, currentOrg } = this.props;
    const detail = userDetails[id];
    const userUtil =
      detail &&
      detail.relatedEntities &&
      new UserUtil(
        detail,
        new SchemaUtil(detail.relatedEntities.fieldSchema, detail.relatedEntities.audience.id, currentOrg.id),
      );

    if (userDetailsLoading[id]) {
      return <LoadingSplash />;
    }
    if (userDetailsError[id] || !detail) {
      return <p>Failed to load user detail.</p>;
    }

    const schema = detail.relatedEntities.fieldSchema;

    const photo =
      detail.relatedEntities.profilePicture &&
      detail.relatedEntities.profilePicture.content &&
      `data:${detail.relatedEntities.profilePicture.mimeType || 'image/jpeg'};base64,${
        detail.relatedEntities.profilePicture.content
      }`;

    let longestTitle = '';
    const getField = (property: string) => {
      if (!schema) {
        return;
      }
      const fields = Object.keys(schema.fields)
        .map(f => schema.fields[f])
        .filter(f => f.property === property);
      if (fields.length === 0) {
        console.error('Users - renderDetail() - getField() - No field found with property ' + property, detail);
      }
      if (fields.length > 1) {
        console.error('Users - renderDetail() - getField() - Multiple fields found with property ' + property, detail);
      }
      const field = fields[0];
      const config = field.audienceConfigs[detail.relatedEntities.audience.id] || field.audienceConfigs[currentOrg.id];
      const label = config.ui.title;
      if (label.length > longestTitle.length) {
        longestTitle = label;
      }

      return {
        id: field.id,
        label,
        value: detail.profile[property],
      };
    };

    const fields = [
      {
        id: 'ID',
        label: 'User ID',
        value: (
          <Tooltip content="This can be used for configuring the Org Chart code snippet" a11y={false}>
            <span style={{ fontSize: '0.8em' }}>{id}</span>
          </Tooltip>
        ),
      },
      ...(detail.profileType === 'onPrem'
        ? [getField('givenname'), getField('sn')]
        : [getField('givenName'), getField('surname')]),
      {
        id: 'collection',
        label: 'Collection',
        value: detail.excluded ? (
          'Excluded from Directory Scope'
        ) : (
          <Link
            to={`/settings/general${
              detail.relatedEntities.audience.id !== currentOrg.id
                ? `?audienceId=${detail.relatedEntities.audience.id}`
                : ''
            }`}
          >
            {detail.relatedEntities.audience.id !== currentOrg.id
              ? detail.relatedEntities.audience.definition.name || detail.relatedEntities.audience.id
              : 'Master'}
          </Link>
        ),
      },
    ];

    return (
      <UserSummary
        noBottomPadding={true}
        completionName="This"
        completionPercent={userUtil && userUtil.getCompletionPercent(detail.profile)}
        photo={photo}
        longestTitle={longestTitle}
        fields={fields}
      />
    );
  };

  renderMailModal() {
    const { emailsLoading, emails, isFree, showPremiumModal } = this.props;
    const { mailUser } = this.state;

    const emailData =
      emails &&
      (isFree && emails.length > 0 ? emails.slice(0, 1) : emails).map(({ id, createdAt, headers }) => ({
        id,
        createdAt,
        subject: headers['subject'],
      }));

    return (
      <Modal className={classes.modal} onClose={() => this.setState({ mailUser: null })}>
        <div style={StyleUtil.styles.tables.header_noFlex}>
          <Modal.Header>Emails sent to {mailUser}</Modal.Header>
        </div>
        <div className={classes.tableContainer}>
          <EmailTable
            loading={emailsLoading}
            onRowClick={this.viewDetail}
            noDataMessage="No emails have been sent to this user."
            data={emailData}
          />
          {isFree && (
            <div style={{ textAlign: 'center', marginTop: 20, padding: '0 100px' }}>
              <h2>
                <PremiumLock tooltipDir="left" onClick={() => showPremiumModal(ModalKeys.emailHistory)} />
                <span>But wait, there&apos;s more!</span>
              </h2>
              <p>In premium mode, you can see all emails sent to this user.</p>
            </div>
          )}
        </div>
      </Modal>
    );
  }

  renderDetailModal() {
    const { detailLoading, details } = this.props;
    const { detailId } = this.state;
    const loading = detailLoading[detailId];
    const detail = details[detailId];

    return (
      <Modal className={classes.modal} onClose={() => this.setState({ detailId: null, mailUser: null })}>
        {loading ? <LoadingSplash /> : <TemplatePreview templatePreview={detail} />}
        <Modal.ButtonContainer>
          <Button size="medium" type="primary" onClick={() => this.setState({ detailId: null })}>
            BACK TO LIST
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  renderViolationUsersExportModal() {
    const { violationUsers, fieldSchema, currentOrg } = this.props;
    const { emails, emailsLoading, emailsError } = this.state;

    const onClose = () => {
      this.setState({
        showViolatingUsersExportModal: false,
        emails: {},
        emailsLoading: {},
        emailsError: {},
      });
    };

    return (
      <ViolationUsersExportModal
        onClose={onClose}
        violations={violationUsers}
        emails={emails}
        emailsLoading={emailsLoading}
        emailsError={emailsError}
        schema={fieldSchema}
        audienceId={currentOrg.id}
      />
    );
  }
}
