import { GetBrandingResponse } from '@hyperfish/antrea-api-contracts/src/branding';
import { ApiClient } from '@hyperfish/fishfood';

import { BrandingState } from '../../../models/client/';
import StyleUtil from '../../../utils/StyleUtil';
import {
  Action,
  EditBrandingAction,
  EditSelfAction,
  EditSettingsAction,
  SimpleActions as SimpleAction,
} from './actions';
import * as types from './types';

const defaultDirtySelf = {
  accentColor: null,
  headerTextColor: null,
  logo: null,
  primaryColor: null,
  secondaryColor: null,
  completionRingSuccessColor: null,
  completionRingWarnColor: null,
  completionRingErrorColor: null,
};

const initialState = ({
  brandingById: {
    me: window['branding'],
  },
  brandingByIdError: {},
  brandingByIdLoading: {},
  defaultSelf: {
    accentColor: StyleUtil.colors.blue,
    headerTextColor: StyleUtil.colors.text.light,
    logo: null,
    primaryColor: StyleUtil.colors.blue,
    secondaryColor: StyleUtil.colors.orange,
    completionRingSuccessColor: StyleUtil.colors.green,
    completionRingWarnColor: StyleUtil.colors.blue,
    completionRingErrorColor: StyleUtil.colors.red,
  },
  dirtyData: { audiences: {} },
} as any) as BrandingState;

export default function reducer(state: BrandingState = initialState, action: Action): BrandingState {
  switch (action.type) {
    case types.LOAD:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
        error: null,
      };
    case types.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error,
      };
    case types.LOAD_USER:
      return {
        ...state,
        brandingByIdLoading: {
          ...state.brandingByIdLoading,
          [action.userId]: true,
        },
        brandingByIdError: {
          ...state.brandingByIdError,
          [action.userId]: null,
        },
      };
    case types.LOAD_USER_SUCCESS:
      return {
        ...state,
        brandingByIdLoading: {
          ...state.brandingByIdLoading,
          [action.userId]: false,
        },
        brandingById: {
          ...state.brandingById,
          [action.userId]: action.result,
        },
      };
    case types.LOAD_USER_FAIL:
      return {
        ...state,
        brandingByIdLoading: {
          ...state.brandingByIdLoading,
          [action.userId]: false,
        },
        brandingByIdError: {
          ...state.brandingByIdError,
          [action.userId]: action.error,
        },
      };
    case types.SAVE:
      return {
        ...state,
        saving: true,
        error: null,
      };
    case types.SAVE_SUCCESS:
      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          audiences: {
            ...state.data.audiences,
            ...action.result.audiences,
            [action.audienceId]: {
              ...state.data.audiences[action.audienceId],
              ...action.result.audiences[action.audienceId],
              selfPageBranding: {
                ...state.data.audiences[action.audienceId].selfPageBranding,
                ...action.result.audiences[action.audienceId].selfPageBranding,
              },
              settings: {
                ...state.data.audiences[action.audienceId].settings,
                ...action.result.audiences[action.audienceId].settings,
              },
            },
          },
        },
        isDirty: false,
      };
    case types.SAVE_FAIL:
      return {
        ...state,
        saving: false,
        error: action.error,
      };
    case types.EDIT:
      return {
        ...state,
        isDirty: true,
        dirtyData: {
          ...state.dirtyData,
          audiences: {
            ...state.dirtyData.audiences,
            [action.audienceId]: {
              ...state.dirtyData.audiences[action.audienceId],
              [action.prop]: action.val,
            },
          },
        },
      };
    case types.EDIT_SETTINGS:
      return {
        ...state,
        isDirty: true,
        dirtyData: {
          ...state.dirtyData,
          audiences: {
            ...state.dirtyData.audiences,
            [action.audienceId]: {
              ...state.dirtyData.audiences[action.audienceId],
              settings: {
                ...(state.dirtyData.audiences[action.audienceId] || {})['settings'],
                [action.prop]: action.val,
              },
            },
          },
        },
      };
    case types.EDIT_SELF:
      return {
        ...state,
        isDirty: true,
        dirtyData: {
          ...state.dirtyData,
          audiences: {
            ...state.dirtyData.audiences,
            [action.audienceId]: {
              ...state.dirtyData.audiences[action.audienceId],
              selfPageBranding: {
                ...defaultDirtySelf,
                ...(state.data.audiences[action.audienceId] || {})['selfPageBranding'],
                ...(state.dirtyData.audiences[action.audienceId] || {})['selfPageBranding'],
                [action.prop]: action.val,
              },
            },
          },
        },
      };
    case types.CANCEL:
      return {
        ...state,
        isDirty: false,
        dirtyData: initialState.dirtyData,
      };
    default:
      return state;
  }
}

// REDUCERS
export function load(audiences: string[]) {
  return {
    types: [types.LOAD, types.LOAD_SUCCESS, types.LOAD_FAIL],
    promise: (client: ApiClient) =>
      client.get('/branding', {
        params: {
          audiences: audiences.join(','),
        },
      }),
    notifications: {
      fail: 'Failed to retrieve branding settings.',
    },
  };
}

export function loadUserBranding(userId: string) {
  return {
    types: [types.LOAD_USER, types.LOAD_USER_SUCCESS, types.LOAD_USER_FAIL],
    userId,
    promise: (client: ApiClient) => client.get(`/external-users/current/${encodeURIComponent(userId)}/branding`),
  };
}

export function edit(
  audienceId: string,
  prop: EditBrandingAction['prop'],
  val: EditBrandingAction['val'],
): EditBrandingAction {
  return {
    type: types.EDIT,
    audienceId,
    prop,
    val,
  };
}

export function editSettings(
  audienceId: string,
  prop: EditSettingsAction['prop'],
  val: EditSettingsAction['val'],
): EditSettingsAction {
  return {
    type: types.EDIT_SETTINGS,
    audienceId,
    prop,
    val,
  };
}

export function editSelfBranding(
  audienceId: string,
  prop: EditSelfAction['prop'],
  val: EditSelfAction['val'],
): EditSelfAction {
  return {
    type: types.EDIT_SELF,
    audienceId,
    prop,
    val,
  };
}

export function save(audienceId: string, brandingObject: GetBrandingResponse['audiences']['foo']) {
  return {
    types: [types.SAVE, types.SAVE_SUCCESS, types.SAVE_FAIL],
    audienceId,
    promise: (client: ApiClient) =>
      client.patch('/branding', {
        data: {
          audiences: {
            [audienceId]: brandingObject,
          },
        },
      }),
    notifications: {
      success: 'Successfully updated branding settings!',
      fail: 'Failed to update branding settings.',
    },
  };
}

export function cancel(): SimpleAction {
  return { type: types.CANCEL };
}

// UTILITY FUNCTIONS
export function getMergedUserSelfBranding({ brandingById, defaultSelf }: BrandingState, userId: string) {
  const current = brandingById[userId];
  if (!defaultSelf) {
    defaultSelf = initialState.defaultSelf;
  }
  if (!current || !current.selfPageBranding) {
    return defaultSelf;
  }
  return {
    ...defaultSelf,
    ...current.selfPageBranding,
  };
}
