import Radium from 'radium';
import React from 'react';

import StyleUtil from '../utils/StyleUtil';

interface GaugeChartConfig {
  colors?: { bad: string; neutral: string; good: string };
  height?: number | string;
  label?: string;
  radius?: number;
  sections?: number;
  spaceLength?: number;
  strokeWidth?: number;
  value: number;
  whitespace?: number;
  width?: number | string;
  style?: React.CSSProperties;
}
export const GaugeChart = Radium((props: GaugeChartConfig) => {
  props = {
    colors: {
      bad: '#e35c5d',
      neutral: StyleUtil.colors.orange,
      good: StyleUtil.colors.green,
    },
    height: 75,
    radius: 45,
    sections: 10,
    spaceLength: 0.005,
    strokeWidth: 8,
    whitespace: 0.35,
    width: 100,
    ...props,
  };

  const { colors, height, radius, sections, spaceLength, strokeWidth, whitespace, width } = props;

  const circumference = Math.PI * (radius * 2);
  const sectionLength = (1 - whitespace) / sections;
  const transform = `rotate(90, 50, 50)`;
  const fill = props.value > 80 ? colors.good : props.value > 40 ? colors.neutral : colors.bad;

  let fillDashArray;
  let dashArray = (fillDashArray = `0 ${circumference * (whitespace / 2)}`);

  for (let i = 0; i < sections; i++) {
    const section = ` ${circumference * (sectionLength - spaceLength)} ${circumference * spaceLength}`;
    dashArray += section;
    if (((i + 1) / sections) * 100 <= Math.round(props.value / 10) * 10) {
      fillDashArray += section;
    }
  }
  fillDashArray += ` 0 ${circumference}`;

  return (
    <svg width={width} height={height} viewBox="0 0 100 75" style={props.style || {}}>
      <circle
        cx="50"
        cy="50"
        r={radius}
        fill="none"
        stroke="#eaeaea"
        strokeWidth={strokeWidth}
        transform={transform}
        strokeDasharray={dashArray}
      />
      <circle
        cx="50"
        cy="50"
        r={radius}
        fill="none"
        stroke={fill}
        strokeWidth={strokeWidth}
        transform={transform}
        strokeDasharray={fillDashArray}
      />
      <text x="50" y="55" fill={fill} textAnchor="middle" fontSize="26" style={{ fontWeight: 700 }}>
        {isNaN(props.value) ? '--' : props.value}%
      </text>
      {props.label && (
        <text x="50" y="70" fill="#666666" textAnchor="middle" fontSize="7" style={{ fontWeight: 700 }}>
          {props.label}
        </text>
      )}
    </svg>
  );
});
