import React from 'react';

interface Props extends React.HTMLProps<HTMLIFrameElement> {
  content: string;
}

export class ContentFrame extends React.Component<Props, {}> {
  private frame: HTMLIFrameElement;

  componentDidMount() {
    this.drawContent(this.props);
  }
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.content !== nextProps.content) {
      this.drawContent(nextProps);
    }
  }

  drawContent({ content }: Props) {
    if (!content || !this.frame) {
      return;
    }
    const { contentDocument } = this.frame;
    contentDocument.open();
    contentDocument.write(content);
    contentDocument.close();
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { content, ...rest } = this.props;
    return <iframe ref={frame => (this.frame = frame)} {...rest} />;
  }
}
