import { ApiGroup } from '@hyperfish/antrea-api-contracts/src/fields';
import { Card, LoadingSplash, Modal, Toggle, SchemaUtil } from '@hyperfish/fishfood';
import cx from 'classnames';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';
import { OrgSettingsKeys as keys } from '../../../models/api';
import { Button, HyperField, HyperFieldFactory as hFields, HyperForm, ModalKeys, Tabs } from '../../../components';
import { SettingsProps } from '../../../layouts/SettingsLayout';
import {
  clearFields,
  createField,
  createFieldStart,
  createGroupStart,
  deleteField,
  deleteFieldCancel,
  deleteFieldPrompt,
  deleteGroupCancel,
  deleteGroupPrompt,
  editField,
  editFieldConfig,
  editFieldOverride,
  editFieldStart,
  editGroup,
  editGroupStart,
  searchFields,
  updateField,
  updateGroups,
} from '../../../redux/modules/fieldSchema';
import { loadConnections } from '../../../redux/modules/orgs';
import { AttributeTable } from './components/AttributeTable';
import { FieldModal } from './components/FieldModal';
import classes from './styles.module.scss';
import StyleUtil from '../../../utils/StyleUtil';
import {
  getDefaultLocale,
  load as loadLocalizations,
  LocalizationValue,
  update as updateLocalizations,
} from '../../../redux/modules/localizations';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import { Localizations } from './components/Localizations';

const connector = connect(
  state => ({
    audienceId: state.orgs.current.id,
    connections: state.orgs.connections,
    connectionsLoading: state.orgs.loadingConnections,
    defaultLocale: getDefaultLocale(state),
    dirtyField: state.fieldSchema.dirtyField,
    dirtyGroup: state.fieldSchema.dirtyGroup,
    dirtyGroups: state.fieldSchema.dirtyGroups,
    fieldCreating: state.fieldSchema.fieldCreating,
    fieldDeletePromptId: state.fieldSchema.fieldDeletePromptId,
    fieldDeleting: state.fieldSchema.fieldDeleting,
    fieldDirty: state.fieldSchema.fieldDirty,
    fieldNames: state.fieldSchema.fieldNames,
    fieldNamesError: state.fieldSchema.fieldNamesError,
    fieldNamesLoading: state.fieldSchema.fieldNamesLoading,
    fieldUpdating: state.fieldSchema.fieldUpdating,
    fieldUpdatingError: state.fieldSchema.fieldUpdatingError,
    groupDeletePromptIndex: state.fieldSchema.groupDeletePromptIndex,
    groupsDirty: state.fieldSchema.groupsDirty,
    groupsUpdating: state.fieldSchema.groupsUpdating,
    groupsUpdatingError: state.fieldSchema.groupsUpdatingError,
    localizations: state.localizations.dataByTargetKey,
    localizationsDirty: state.localizations.dirty,
    localizationsLoading: state.localizations.loading,
    localizationsUpdating: state.localizations.updating,
    localizationsUpdated: state.localizations.updated,
    providers: state.providers.data,
    requiresAdminReconsent: state.fieldSchema.requiresAdminReconsent,
  }),
  {
    clearFields,
    createField,
    createFieldStart,
    createGroupStart,
    deleteField,
    deleteFieldCancel,
    deleteFieldPrompt,
    deleteGroupCancel,
    deleteGroupPrompt,
    editField,
    editFieldConfig,
    editFieldOverride,
    editFieldStart,
    editGroup,
    editGroupStart,
    loadConnections,
    loadLocalizations,
    searchFields,
    updateField,
    updateGroups,
    updateLocalizations,
  },
);

export type Props = typeof connector['props'] & SettingsProps;

interface State {
  activeTab: 'group' | 'language';
}

@connector
@Radium
export default class Attributes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: 'group',
    };
  }

  private getLocalizations = (
    localizations: { [key: string]: LocalizationValue[] },
    targetKey: string,
  ): Partial<LocalizationValue>[] => getFrom(localizations)(targetKey).value || [];

  componentDidMount() {
    const {
      connectionsLoading,
      loadConnections,
      fieldSchemaLoading,
      loadFieldSchema,
      localizationsLoading,
      loadLocalizations,
      loadScopedSettings,
    } = this.props;

    if (!connectionsLoading) {
      loadConnections();
    }
    if (!fieldSchemaLoading) {
      loadFieldSchema();
    }

    if (!localizationsLoading) {
      loadLocalizations(this.props.currentAudienceId);
    }

    loadScopedSettings([keys.locale.default, keys.global.readOnlyMode]);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.currentAudienceId !== nextProps.currentAudienceId ||
      (!!nextProps.localizationsUpdated && !nextProps.localizationsLoading)
    ) {
      nextProps.loadLocalizations(nextProps.currentAudienceId);
    }
  }

  render() {
    const { fieldSchema, fieldSchemaLoading } = this.props;

    if (!fieldSchema || fieldSchemaLoading) {
      return <LoadingSplash />;
    }

    return (
      <div className="view-settings-attributes">
        {this.renderHeader()}
        <Card noPadding={true}>
          <AttributeTable {...this.props} />
        </Card>
        {this.props.dirtyField && <FieldModal {...this.props} />}
        {this.props.dirtyGroup && this.renderGroupEdit()}
        {this.props.fieldDeletePromptId && this.renderDeleteField()}
        {this.props.groupDeletePromptIndex != null && this.renderDeleteGroup()}
      </div>
    );
  }

  private renderDeleteField() {
    const {
      currentAudienceId,
      deleteField,
      deleteFieldCancel,
      fieldDeleting,
      fieldDeletePromptId,
      fieldSchema,
      loadFieldSchema,
    } = this.props;

    const fieldToDelete = getFrom(fieldSchema)('fields')(fieldDeletePromptId).value;
    if (!fieldToDelete) {
      return;
    }

    const isFieldDeleting = fieldDeleting[fieldDeletePromptId];
    const fieldTitle = getFrom(fieldToDelete)('audienceConfigs')(currentAudienceId)('ui')('title').value;
    const dependentFields = SchemaUtil.GetDependentFields(
      fieldSchema,
      getFrom(fieldToDelete)('id').value,
      currentAudienceId,
      this.props.currentOrg.id,
    );

    let ModalHeaderText;
    if (dependentFields.length > 0) {
      ModalHeaderText =
        'Cannot delete because ' +
        dependentFields
          .map(field => getFrom(field)('property').value)
          .filter(Boolean)
          .join(', ') +
        (dependentFields.length == 1 ? ' has' : ' have') +
        ' cascaded options from ' +
        fieldTitle +
        '. Please remove these dependencies first.';
    } else {
      ModalHeaderText = 'Are you sure you want to delete ' + fieldTitle + '?';
    }

    return (
      <Modal onClose={() => deleteFieldCancel()}>
        <Modal.Header>{ModalHeaderText}</Modal.Header>
        <Modal.ButtonContainer>
          <Button size="medium" onClick={() => deleteFieldCancel()} style={StyleUtil.styles.modals.spacedBtn}>
            Cancel
          </Button>
          <Button
            size="medium"
            type="primary"
            disabled={isFieldDeleting || dependentFields.length > 0}
            onClick={() => {
              (deleteField(fieldDeletePromptId) as any).then(() => {
                loadFieldSchema();
                deleteFieldCancel();
              });
            }}
          >
            {isFieldDeleting ? 'DELETING' : 'YES'}
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  private renderDeleteGroup() {
    const {
      currentAudienceId,
      deleteGroupCancel,
      fieldSchema,
      groupDeletePromptIndex,
      groupsUpdating,
      updateGroups,
    } = this.props;

    const groupToDelete = fieldSchema.groups[currentAudienceId][groupDeletePromptIndex] || ({} as ApiGroup);
    const isGroupDeleting = groupsUpdating;

    return (
      <Modal onClose={() => deleteGroupCancel()}>
        <Modal.Header>Are you sure you want to delete {groupToDelete.title}?</Modal.Header>
        <Modal.ButtonContainer>
          <Button size="medium" onClick={() => deleteGroupCancel()} style={StyleUtil.styles.modals.spacedBtn}>
            Cancel
          </Button>
          <Button
            size="medium"
            type="primary"
            disabled={isGroupDeleting}
            onClick={() => {
              const newGroups = fieldSchema.groups[currentAudienceId].slice();
              newGroups.splice(groupDeletePromptIndex, 1);
              (updateGroups(currentAudienceId, newGroups) as any).then(() => deleteGroupCancel());
            }}
          >
            {isGroupDeleting ? 'DELETING' : 'YES'}
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  private renderHeader() {
    const { isMaster, currentAudienceId, currentOrg, fieldSchema, updateGroups } = this.props;

    const groupOverride = !!fieldSchema.groups[currentAudienceId];

    return (
      <div style={{ ...StyleUtil.styles.tables.header, alignItems: 'center' }}>
        {!isMaster && (
          <div className={cx(classes.fieldOverrideContainer, classes.fieldOverrideContainer_header)}>
            <label className={classes.fieldOverrideLabel} htmlFor="groupOverrideToggle">
              Override Master Group and Order Settings
            </label>
            <Toggle
              id="groupOverrideToggle"
              checked={groupOverride}
              onChange={e => {
                const willOverride = e.currentTarget.checked;
                if (!willOverride) {
                  updateGroups(currentAudienceId, null);
                  return;
                }
                const newGroups = fieldSchema.groups[currentOrg.id].map(g => ({
                  ...g,
                  audienceId: currentAudienceId,
                  id: undefined,
                  createdAt: undefined,
                }));
                updateGroups(currentAudienceId, newGroups);
              }}
            />
          </div>
        )}
        <p className={classes.hintText}>
          {isMaster || groupOverride
            ? 'Drag and drop the attributes and groups to reorder them on the profile page'
            : 'Group titles and ordering are being inherited from master'}
        </p>
      </div>
    );
  }

  private renderGroupEdit() {
    const {
      currentAudienceId,
      dirtyGroup,
      groupsUpdating,
      isFree,
      localizationsDirty,
      editGroup,
      editGroupStart,
    } = this.props;

    const getGroupFields = (): HyperField[] => {
      return [
        hFields.text({
          label: 'Display Name',
          type: 'text',
          autofocus: true,
          value: dirtyGroup.title,
          onChange: e => editGroup('title', e.currentTarget.value),
          premiumLock: isFree ? ModalKeys.categories : null,
          required: true,
        }),
      ];
    };

    const getLocalizationFields = (): HyperField[] => {
      const { dirtyGroup } = this.props;

      const currentAudienceId = getFrom(dirtyGroup)('audienceId').value;
      const targetKey = getFrom(dirtyGroup)('id').value;
      const defaultTitle = getFrom(dirtyGroup)('title').value;
      const category = 'group_title';

      return [
        hFields.custom({
          label: 'Languages',
          hideLabel: true,
          customInput: (
            <Localizations
              audienceId={currentAudienceId}
              category={category}
              defaultTitle={defaultTitle}
              targetKey={targetKey}
            />
          ),
        }),
      ];
    };

    const getFields = (activeTab: string): HyperField[] => {
      switch (activeTab) {
        case 'group':
          return getGroupFields();
        case 'language':
          return getLocalizationFields();
        default:
          return [];
      }
    };

    let title;
    if (dirtyGroup.id) {
      const cleanGroupArray = this.getGroups().filter(g => g.id === dirtyGroup.id);
      title = `Edit ${cleanGroupArray.length > 0 ? cleanGroupArray[0].title : dirtyGroup.title}`;
    } else {
      title = 'Add New Group';
    }

    const isDirty = groupsUpdating || localizationsDirty;

    return (
      <Modal onClose={() => editGroupStart(null)}>
        <div className={classes.headerContainer}>
          <Modal.Header>{title}</Modal.Header>
        </div>
        <Tabs
          tabs={[{ label: 'Group', key: 'group' }, { label: 'Languages', key: 'language' }]}
          activeKey={this.state.activeTab}
          onChange={(activeTab: State['activeTab']) => this.setState({ activeTab })}
          className={classes.tabs}
        />
        <HyperForm
          showPremiumModal={this.props.showPremiumModal}
          saving={groupsUpdating}
          dirty={isDirty}
          onCancel={() => editGroupStart(null)}
          onSubmit={e => {
            e.preventDefault();
            const { dirtyGroup, localizations, updateGroups, updateLocalizations } = this.props;
            const dataByTargetKey = this.getLocalizations(localizations, dirtyGroup.id);

            const newGroups = this.getGroups().map(({ id }) =>
              id === dirtyGroup.id ? dirtyGroup : ({ id } as ApiGroup),
            );
            if (!dirtyGroup.id) {
              newGroups.push(dirtyGroup);
            }

            updateGroups(currentAudienceId, newGroups);

            if (localizationsDirty) {
              updateLocalizations(dirtyGroup.id, dataByTargetKey);
            }
          }}
          fields={getFields(this.state.activeTab)}
        />
      </Modal>
    );
  }

  private getGroups() {
    const { currentAudienceId, currentOrg, fieldSchema } = this.props;
    if (!currentOrg || !fieldSchema) {
      return [];
    }
    return fieldSchema.groups[currentAudienceId] || fieldSchema.groups[currentOrg.id] || [];
  }
}
