import styled from 'styled-components';
import { FiAlertCircle } from '@hyperfish/fishfood/lib/components/Icon';
import { getFontSize, spacing, themeColor } from '@hyperfish/fishfood/lib/utils/ThemeUtil';

export const WarningContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export const Warning = styled(FiAlertCircle)`
  margin-right: 10px;
  color: ${themeColor('error')};
`;

export const FlexContainer = styled.div`
  min-width: 720px;
  display: flex;
  gap: 1em;
  margin-bottom: ${spacing(1)};

  > * {
    width: 100%;
  }
`;

export const RemoveContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 26px;
`;

export const EmptyMessage = styled.p`
  && {
    font-size: ${getFontSize(18)};
    margin: ${spacing(2, 0, 4)};
    font-style: italic;
  }
`;
