/* eslint-disable @typescript-eslint/camelcase */
import { OrgSettingsKeys as keys } from '../../models/api';

const names = {
  [keys.global.mode]: 'Hyperfish Mode',
  [keys.audit.scopes]: 'Included OUs',
  [keys.audit.exclusions]: 'Excluded OUs',
  [keys.audit.storeProfileData]: 'Store Profile Data',
  [keys.audit.schedule.daily]: 'Daily Full Scan Enabled',
  [keys.audit.schedule.realtime]: 'Incremental Scans Enabled',
  [keys.audit.schedule.dailyFullAuditTime]: 'Daily Full Scan Time',
  [keys.branding.emailHeaderImage]: 'Email Header Image',
  [keys.broadcast.tone]: 'Hyperbot Personality',
  [keys.broadcast.email]: 'Hyperbot Email Address',
  [keys.broadcast.botName]: 'Hyperbot Name',
  [keys.broadcast.enabled]: 'Hyperbot Enabled',
  [keys.broadcast.exclusions]: 'Hyperbot Do Not Disturb',
  [keys.broadcast.magicLinks.enabled]: 'Magic Links Enabled',
  [keys.broadcast.adminReport.enabled]: 'Monthly Admin Report Enabled',
  [keys.broadcast.channels.email]: 'Email Channel Enabled',
  [keys.broadcast.channels.skypeForBusiness]: 'Skype for Business Channel Enabled',
  [keys.broadcast.reminders.limit]: 'Hyperbot Attempts',
  [keys.broadcast.reminders.frequency]: 'Hyperbot Frequency',
  [keys.broadcast.profile.runAt]: 'Profile Validation Scheduled Time',
  [keys.broadcast.profile.enabled]: 'Profile Validation Enabled',
  [keys.broadcast.profile.frequency]: 'Profile Validation Frequency',
  [keys.broadcast.approval.dailyTimeToNotify]: 'Approval Daily Notification Time',
  [keys.broadcast.approval.whenToNotifyApprovers]: 'Approval Notification Timing',
  [keys.auth.impersonation.microsoftGraph.providerId]: 'Microsoft Graph Token',
  [keys.inheritance.emailBranding]: 'Inherit Email Branding from Master',
  [keys.inheritance.profileBranding]: 'Inherit Profile Branding from Master',
  [keys.inheritance.autoApprove]: 'Inherit Auto Approve from Master',
  [keys.inheritance.approverPermissions]: 'Inherit Approvers from Master',
  [keys.onboarding.step]: 'Onboarding Step',
  [keys.locale.default]: 'Default Locale',
  [keys.locale.attributeMapping]: 'Localization Attribute Mapping',

  branding_selfPage_logo: 'Profile Page Logo',
  branding_selfPage_accentColor: 'Profile Page Accent Color',
  branding_selfPage_primaryColor: 'Profile Page Header Color',
  branding_selfPage_secondaryColor: 'Profile Page Secondary Color',
  branding_selfPage_headerTextColor: 'Profile Page Header Text Color',
};

export default names;
