import React, { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';

import { Form } from '@hyperfish/fishfood/lib/components/Form';
import { Button } from '@hyperfish/fishfood/lib/components/Button';
import { OrgChartProps } from '@hyperfish/fishfood/lib/components/OrgChart';
import { stringToOption, Option } from '@hyperfish/fishfood/lib/components/Select';
import { PeoplePicker } from '@hyperfish/fishfood/lib/components/PeoplePicker';
import { UserDetailPhoto } from '@hyperfish/fishfood/lib/components/UserDetail/UserDetailPhoto';
import { AnonymousAvatar } from '@hyperfish/fishfood/lib/components/AnonymousAvatar';
import { ApiClientContext } from '@hyperfish/fishfood/lib/utils/ApiClient';
import {
  FlexWrapper,
  InternalFieldSet,
  InternalFormContainer,
  ButtonContainer,
  UserDetailPhotoContainer,
} from '../FormComponents';
import { OrgSettingTypes, ApiResult, GetSearchExternalUsersResponse } from '@hyperfish/antrea-api-contracts';
import { SettingsProps } from '../../../../layouts/SettingsLayout';
import { getFrom } from '@hyperfish/fishfood';
import UserDetailPhotoUpload from '@hyperfish/fishfood/lib/components/UserDetail/UserDetailPhotoUpload';

interface FormProps {
  values: any;
  dirty: boolean;
  errors: {};
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

interface Props {
  collapsable?: boolean;
  settings: OrgSettingTypes;
  schema: ApiResult;
  currentOrgId: string;
  onChange?: ({}) => void;
  settingsFor: 'snippet' | 'chart';
}

export const OrgForm: React.FC<Props & Pick<SettingsProps, 'save'>> = ({
  settings,
  save,
  currentOrgId,
  schema,
  collapsable,
  settingsFor,
  onChange,
}) => {
  const orgSettings = (settings.webparts_config_orgchart || {}) as Omit<
    OrgChartProps,
    'masterAudienceId' | 'schema' | 'audienceId' | 'fetchDetail' | 'fetchTree'
  >;
  const [orgValues, setOrgValues] = useState(orgSettings);
  const [fields, setFields] = useState<{ label: any; value: any }[]>();
  const [showPhotoUploadModal, setShowPhotoUploadModal] = useState<boolean>(false);
  const client = useContext(ApiClientContext);

  useEffect(() => {
    const getFields = () => {
      const schemaFields = Object.keys(schema.fields).map(field => ({
        label: schema.fields[field].audienceConfigs[currentOrgId].ui.title,
        value: schema.fields[field].property,
      }));

      return schemaFields.sort((a, b) => a.label.localeCompare(b.label));
    };

    if (!!schema && !!currentOrgId) {
      setFields(getFields());
    }
  }, [schema, currentOrgId]);

  const validationSchema = Yup.object().shape({
    defaultExpandedNodes: Yup.number()
      .min(1)
      .max(3),
    cardFields: Yup.array().of(
      Yup.object().shape({
        property: Yup.string().required('Required'),
        icon: Yup.string().required('Required'),
      }),
    ),
  });

  const onSubmit = (values, actions) => {
    const prepareValues = (formValues: any) => {
      if (!getFrom(formValues)('useVirtualRoot').defaultTo(false)) {
        formValues.virtualRoot = null;
        return formValues;
      }

      const virtualRoot = {
        profileId: currentOrgId,
        displayName: getFrom(formValues)('virtualRoot')('displayName').value,
        directReports: Array.from(getFrom(formValues)('rootUser').defaultTo([]))
          .map((option: any) => getFrom(option)('value').defaultTo(null))
          .filter(Boolean),
      };

      const profilePictureUrl = getFrom(formValues)('virtualRoot')('profilePictureUrl').defaultTo(null);
      if (profilePictureUrl) {
        const matches = profilePictureUrl.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
        if (getFrom(matches)('length').defaultTo(0) !== 3) {
          virtualRoot['profilePictureUrl'] = profilePictureUrl;
        } else {
          const mimeType = matches[1];
          const content = matches[2];
          virtualRoot['profilePicture'] = {
            content,
            mimeType,
          };
        }
      }

      const profilePicture = getFrom(formValues)('virtualRoot')('profilePicture').defaultTo(null);
      if (profilePicture) {
        virtualRoot['profilePicture'] = {
          content: profilePicture.content,
          mimeType: profilePicture.mimeType,
        };
      }

      formValues.virtualRoot = virtualRoot;

      return formValues;
    };

    const preparedValues = prepareValues({ ...values });

    if (onChange) {
      onChange(preparedValues);
    } else {
      save(currentOrgId, { webparts_config_orgchart: preparedValues });
    }

    setOrgValues(preparedValues as typeof orgSettings);
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const renderForm = (props: FormProps) => {
    const { values, dirty, errors, setFieldValue } = props;
    const useVirtualRoot = getFrom(values)('useVirtualRoot').defaultTo(false);

    const getVirtualUserProfilePicture = (formValues: any) => {
      let profilePictureUrl = getFrom(formValues)('virtualRoot')('profilePictureUrl').defaultTo(null);
      if (!profilePictureUrl) {
        const profilePicture = getFrom(formValues)('virtualRoot')('profilePicture').defaultTo(null);
        if (profilePicture && profilePicture.content && profilePicture.mimeType) {
          profilePictureUrl = `data:${profilePicture.mimeType || 'image/jpeg'};base64,${profilePicture.content}`;
        }
      }

      return profilePictureUrl ? (
        <img src={profilePictureUrl} alt={'virtual user profile photo'} />
      ) : (
        <AnonymousAvatar />
      );
    };

    return (
      <Form.FormikForm>
        <InternalFormContainer>
          {settingsFor == 'snippet' ? (
            <>
              <InternalFieldSet legend="Chart Options" collapsable={collapsable}>
                <Form.Fields.Toggle name="useVirtualRoot" label="Use Virtual Root User" />
                {useVirtualRoot && (
                  <>
                    <Form.Fields.Text
                      name="virtualRoot.displayName"
                      label="Virtual Root: Display Name"
                      inputProps={{ type: 'string', placeholder: 'Display Name' }}
                    />
                    <Form.Field
                      label="Virtual Root: Profile Photo"
                      name="virtualRoot.profilePictureUrl"
                      badAtTips={true}
                    >
                      {() => (
                        <UserDetailPhotoContainer>
                          <UserDetailPhoto
                            canDeletePhoto={false}
                            canEdit={false}
                            pending={false}
                            percent={100}
                            onEdit={undefined}
                            onDeletePhoto={undefined}
                          >
                            {getVirtualUserProfilePicture(values)}
                          </UserDetailPhoto>

                          {/* <ButtonContainer justify="flex-end" margin={[2.5, 0, 0, 0]}> */}
                          <ButtonContainer padding={[1]}>
                            <Button size="small" onClick={() => setShowPhotoUploadModal(true)}>
                              {'Change Photo'}
                            </Button>
                            <Button size="small" onClick={() => setFieldValue('virtualRoot.profilePictureUrl', null)}>
                              {'Reset'}
                            </Button>
                          </ButtonContainer>
                        </UserDetailPhotoContainer>
                      )}
                    </Form.Field>
                  </>
                )}
                <Form.Field label="Root user ID" name="rootUser" badAtTips={true}>
                  {({ invalid, required, disabled, field, inputProps }) => (
                    <PeoplePicker
                      {...field}
                      {...inputProps}
                      isMulti={useVirtualRoot}
                      fetchUsers={(q, limit) =>
                        client
                          .get<GetSearchExternalUsersResponse<'msGraph'> | GetSearchExternalUsersResponse<'onPrem'>>(
                            `/external-users/current`,
                            {
                              params: { q, limit },
                            },
                          )
                          .then(res => res.data)
                      }
                      onChange={options => {
                        const getFieldValue = (options): Option[] => {
                          const getOptionsArray = (options): Option[] => {
                            const _useVirtualRoot = getFrom(values)('useVirtualRoot').defaultTo(false);
                            if (_useVirtualRoot && Array.isArray(options)) {
                              return options;
                            }

                            if (!!options) {
                              return [options];
                            }

                            return [];
                          };

                          return getOptionsArray(options).filter(Boolean);
                        };

                        setFieldValue(field.name, getFieldValue(options));
                      }}
                      invalid={invalid}
                      required={required}
                      isDisabled={disabled}
                    />
                  )}
                </Form.Field>
                <FlexWrapper>
                  <Form.Fields.Text
                    name="height"
                    label="Chart height (in px)"
                    inputProps={{ type: 'number', placeholder: '600' }}
                  />
                  <Form.Fields.Select
                    name="nodeType"
                    label="Node type"
                    inputProps={{ options: ['card', 'photo'].map(x => stringToOption(x)) }}
                  />
                  <Form.Fields.Toggle name="hideZoomControls" label="Hide zoom controls" />
                  <Form.Fields.Toggle name="disableScrollZoom" label="Disable scroll zoom" />
                </FlexWrapper>
              </InternalFieldSet>
              <InternalFieldSet legend="Card Options" collapsable={collapsable}>
                <Form.Fields.Select
                  name="cardProperties"
                  label="Card properties"
                  inputProps={{
                    options: fields,
                    isMulti: true,
                    closeMenuOnSelect: false,
                  }}
                />
              </InternalFieldSet>
              <InternalFieldSet legend="Detail Modal Options" collapsable={collapsable}>
                <FlexWrapper>
                  <Form.Fields.Toggle name="disableDetailModal" label="Disable detail modal" />
                  <Form.Fields.Toggle name="showCommIcons" label="Show Communication Icons" />
                  <Form.Fields.Select
                    name="detailModalHiddenProperties"
                    label="Hidden attributes"
                    inputProps={{
                      options: fields,
                      isMulti: true,
                      closeMenuOnSelect: false,
                    }}
                  />
                </FlexWrapper>
              </InternalFieldSet>
              <InternalFieldSet legend="Advanced Options" collapsable={collapsable}>
                <FlexWrapper>
                  <Form.Fields.Text
                    name="defaultExpandedNodes"
                    label="Expanded Nodes"
                    inputProps={{ type: 'number', placeholder: '1' }}
                  />
                  <Form.Fields.Text
                    name="initialZoomScale"
                    label="Initial zoom scale"
                    inputProps={{ type: 'number', placeholder: '1' }}
                  />
                  <Form.Fields.Text name="minZoomScale" label="Min zoom scale" inputProps={{ type: 'number' }} />
                  <Form.Fields.Text name="maxZoomScale" label="Max zoom scale" inputProps={{ type: 'number' }} />
                  <Form.Fields.Text name="cardMinScale" label="Card minimum scale" inputProps={{ type: 'number' }} />
                  <Form.Fields.Text
                    name="transitionDuration"
                    label="Transition duration (in ms)"
                    inputProps={{ type: 'number' }}
                  />
                </FlexWrapper>
              </InternalFieldSet>
              <InternalFieldSet legend="MS Teams only" collapsable={collapsable}>
                <FlexWrapper>
                  <Form.Fields.Toggle name="showPresenceIndicator" label="Show Presence Indicator" />
                </FlexWrapper>
              </InternalFieldSet>
            </>
          ) : (
            <>
              {settingsFor == 'chart' && (
                <>
                  <h6>Organizational Chart</h6>
                  <InternalFieldSet legend="Root User Options" collapsable={collapsable}>
                    <Form.Fields.Toggle name="useVirtualRoot" label="Use Virtual Root User" />
                    {useVirtualRoot && (
                      <>
                        <Form.Fields.Text
                          name="virtualRoot.displayName"
                          label="Virtual Root: Display Name"
                          inputProps={{ type: 'string', placeholder: 'Display Name' }}
                        />
                        <Form.Field
                          label="Virtual Root: Profile Photo"
                          name="virtualRoot.profilePictureUrl"
                          badAtTips={true}
                        >
                          {() => (
                            <UserDetailPhotoContainer>
                              <UserDetailPhoto
                                canDeletePhoto={false}
                                canEdit={false}
                                pending={false}
                                percent={100}
                                onEdit={undefined}
                                onDeletePhoto={undefined}
                              >
                                {getVirtualUserProfilePicture(values)}
                              </UserDetailPhoto>

                              {/* <ButtonContainer justify="flex-end" margin={[2.5, 0, 0, 0]}> */}
                              <ButtonContainer padding={[1]}>
                                <Button size="small" onClick={() => setShowPhotoUploadModal(true)}>
                                  {'Change Photo'}
                                </Button>
                                <Button
                                  size="small"
                                  onClick={() => setFieldValue('virtualRoot.profilePictureUrl', null)}
                                >
                                  {'Reset'}
                                </Button>
                              </ButtonContainer>
                            </UserDetailPhotoContainer>
                          )}
                        </Form.Field>
                      </>
                    )}
                    <Form.Field label="Root user ID" name="rootUser" badAtTips={true}>
                      {({ invalid, required, disabled, field, inputProps }) => (
                        <PeoplePicker
                          {...field}
                          {...inputProps}
                          isMulti={useVirtualRoot}
                          fetchUsers={(q, limit) =>
                            client
                              .get<
                                GetSearchExternalUsersResponse<'msGraph'> | GetSearchExternalUsersResponse<'onPrem'>
                              >(`/external-users/current`, {
                                params: { q, limit },
                              })
                              .then(res => res.data)
                          }
                          onChange={options => {
                            const getFieldValue = (options): Option[] => {
                              const getOptionsArray = (options): Option[] => {
                                const _useVirtualRoot = getFrom(values)('useVirtualRoot').defaultTo(false);
                                if (_useVirtualRoot && Array.isArray(options)) {
                                  return options;
                                }

                                if (!!options) {
                                  return [options];
                                }

                                return [];
                              };

                              return getOptionsArray(options).filter(Boolean);
                            };

                            setFieldValue(field.name, getFieldValue(options));
                          }}
                          invalid={invalid}
                          required={required}
                          isDisabled={disabled}
                        />
                      )}
                    </Form.Field>
                  </InternalFieldSet>
                  <InternalFieldSet legend="MS Teams only" collapsable={collapsable}>
                    <FlexWrapper>
                      <Form.Fields.Toggle name="showPresenceIndicator" label="Show Presence Indicator" />
                    </FlexWrapper>
                  </InternalFieldSet>
                  <h6 style={{ marginTop: '10px' }}>Relationship Chart</h6>
                  <InternalFieldSet legend="Behavior Options" collapsable={collapsable}>
                    <FlexWrapper>
                      <Form.Fields.Toggle name="mergeRelationshipTrees" label="Merge relationship trees" />
                    </FlexWrapper>
                  </InternalFieldSet>
                  <h6 style={{ marginTop: '10px' }}>Both Charts</h6>
                  <InternalFieldSet legend="Card Options" collapsable={collapsable}>
                    <Form.Fields.Select
                      name="cardProperties"
                      label="Card properties"
                      inputProps={{
                        options: fields,
                        isMulti: true,
                        closeMenuOnSelect: false,
                      }}
                    />
                  </InternalFieldSet>
                  <InternalFieldSet legend="Detail Modal Options" collapsable={collapsable}>
                    <FlexWrapper>
                      <Form.Fields.Toggle name="disableDetailModal" label="Disable detail modal" />
                      <Form.Fields.Toggle name="showCommIcons" label="Show Communication Icons" />
                      <Form.Fields.Select
                        name="detailModalHiddenProperties"
                        label="Hidden attributes"
                        inputProps={{
                          options: fields,
                          isMulti: true,
                          closeMenuOnSelect: false,
                        }}
                      />
                    </FlexWrapper>
                  </InternalFieldSet>
                  <InternalFieldSet legend="Advanced Options" collapsable={collapsable}>
                    <FlexWrapper>
                      <Form.Fields.Text
                        name="defaultExpandedNodes"
                        label="Expanded Nodes"
                        inputProps={{ type: 'number', placeholder: '1' }}
                      />
                      <Form.Fields.Text
                        name="initialZoomScale"
                        label="Initial zoom scale"
                        inputProps={{ type: 'number', placeholder: '1' }}
                      />
                      <Form.Fields.Text name="minZoomScale" label="Min zoom scale" inputProps={{ type: 'number' }} />
                      <Form.Fields.Text name="maxZoomScale" label="Max zoom scale" inputProps={{ type: 'number' }} />
                      <Form.Fields.Text
                        name="cardMinScale"
                        label="Card minimum scale"
                        inputProps={{ type: 'number' }}
                      />
                      <Form.Fields.Text
                        name="transitionDuration"
                        label="Transition duration (in ms)"
                        inputProps={{ type: 'number' }}
                      />
                    </FlexWrapper>
                  </InternalFieldSet>
                </>
              )}
            </>
          )}

          <ButtonContainer justify="flex-end" margin={[2.5, 0, 0, 0]}>
            {onChange ? (
              <Button type="submit" size="small" disabled={!dirty || Object.keys(errors).length > 0}>
                Update
              </Button>
            ) : (
              <>
                <Button type="reset" size="small" disabled={!dirty}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="solid"
                  color="warn"
                  disabled={!dirty || Object.keys(errors).length > 0}
                >
                  Save
                </Button>
              </>
            )}
          </ButtonContainer>
        </InternalFormContainer>
        {showPhotoUploadModal && (
          <UserDetailPhotoUpload
            field={undefined}
            onClose={() => {
              setShowPhotoUploadModal(false);
            }}
            onSave={imageDataUrl => {
              setShowPhotoUploadModal(false);
              setFieldValue('virtualRoot.profilePictureUrl', imageDataUrl);
              return Promise.resolve();
            }}
            onValidate={undefined}
          />
        )}
      </Form.FormikForm>
    );
  };

  return (
    <Form
      enableReinitialize
      initialValues={orgValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={renderForm}
    />
  );
};
