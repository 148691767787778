import { Checkbox } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';

import StyleUtil from '../utils/StyleUtil';

interface Props {
  properties: {
    checked?: boolean;
    handleChange?: React.EventHandler<React.FormEvent<HTMLInputElement>>;
    label: string;
    property: string;
    value: number;
  }[];
  selectable?: boolean;
  color?: string;
  colors?: string[];
  styles?: {
    container?: React.CSSProperties;
    tdLabel?: React.CSSProperties;
    tdBar?: React.CSSProperties;
    barSpan?: React.CSSProperties;
  };
}

const S = StyleUtil({
  label: {
    whiteSpace: 'nowrap',
  },
});

export const BarTable = Radium((props: Props) => (
  <table className="bar-table" style={props.styles && props.styles.container}>
    <tbody>
      {props.properties &&
        props.properties.map((p, i) => (
          <tr key={p.label}>
            <td style={[S.label, props.styles && props.styles.tdLabel] as any}>
              <label htmlFor={`${p.property}_checkbox`}>{p.label}</label>
            </td>
            <td>
              <label
                htmlFor={`${p.property}_checkbox`}
                className="bar-container"
                style={props.styles && props.styles.tdBar}
              >
                <div
                  className="bar"
                  style={
                    [
                      {
                        width: `${p.value}%`,
                        backgroundColor: (props.colors && props.colors[i]) || props.color || '#0096cd',
                      },
                      props.styles && props.styles.barSpan,
                    ] as any
                  }
                >
                  <span
                    className="num"
                    style={
                      [
                        p.value >= 80 ? { right: '10px', color: 'white' } : { left: 'calc(100% + 10px)' },
                        props.styles && props.styles.barSpan,
                      ] as any
                    }
                  >
                    {p.value}%
                  </span>
                </div>
              </label>
            </td>
            {props.selectable && (
              <td className="check">
                <Checkbox id={`${p.property}_checkbox`} checked={!!p.checked} onChange={p.handleChange} />
              </td>
            )}
          </tr>
        ))}
    </tbody>
  </table>
));
