import { Notification, NotificationsState } from '../../models/client/';

const CREATE = 'antrea/notifications/CREATE';
const DISMISS = 'antrea/notifications/DISMISS';

let notificationCount = 0;

// UTILITY FUNCTIONS
function getFriendlyErrorTitle() {
  const funTitles = ['Uh oh...', 'Well, this is embarassing.', 'Oops...'];

  return funTitles[Math.floor(Math.random() * funTitles.length)];
}

function getNotificationsWithoutNotification(notifications: Notification[], notification: Notification) {
  const arr = notifications.slice();
  const index = arr.indexOf(notification);

  if (index === -1) {
    console.error('Notification not found in notifications array.', notification);
  }

  arr.splice(index, 1);

  return arr;
}

const initialState: NotificationsState = {
  notifications: [],
};

export default function reducer(state: NotificationsState = initialState, action): NotificationsState {
  switch (action.type) {
    case CREATE:
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };
    case DISMISS:
      return {
        ...state,
        notifications: getNotificationsWithoutNotification(state.notifications, action.notification),
      };
    default:
      return state;
  }
}

export function createNotification(notification: Partial<Notification>) {
  const notificationDefaults: Partial<Notification> = {
    id: `NOTIFICATION_${++notificationCount}`,
    type: 'info',
    duration: 5,
    title: notification.type === 'error' ? getFriendlyErrorTitle() : null,
  };

  return {
    type: CREATE,
    notification: {
      ...notificationDefaults,
      ...notification,
    },
  };
}

export function dismissNotification(notification: Notification) {
  return {
    type: DISMISS,
    notification,
  };
}
