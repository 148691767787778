import Radium from 'radium';
import React from 'react';

import StyleUtil from '../../utils/StyleUtil';

interface Props {
  branding: { secondaryColor: string };
  breakPoint: string;
  sectionId: string;
  longestTitle: string;
  field: {
    id: string;
    label: string;
    pending?: boolean;
    value: string;
    invalid?: boolean;
    hasHint?: boolean;
  };
}

export const UserRow = Radium(({ breakPoint, sectionId, field, branding, longestTitle }: Props) => {
  // if (!field) { console.error(`Section ${sectionId} contains an undefined field.`); return; }
  // const isPending = this.getIsPending(field.property);
  // const isInvalid = !!this.getValidation(field.id, this.getVal(user, sectionId, field.property));
  // const hasHint = !!this.getHint(field.id) || !!this.getSuggestion(sectionId, field.property);
  const S = StyleUtil({
    pendingBadge: {
      backgroundColor: (branding && branding.secondaryColor) || StyleUtil.colors.orange,
      borderRadius: 7,
      color: StyleUtil.colors.text.light,
      display: 'inline-block',
      fontSize: 10,
      fontWeight: 700,
      height: 15,
      lineHeight: '14px',
      marginLeft: 5,
      padding: '0 6px',
      textAlign: 'center',
    },
    tr: {
      [breakPoint]: {
        display: 'block',
      },
    },
    td: {
      paddingBottom: 11,
      paddingTop: 11,

      [breakPoint]: {
        display: 'block',
      },
    },
    label: {
      color: '#7a7a7a',
      paddingRight: 40,
      position: 'relative',
      verticalAlign: field.invalid && !field.hasHint ? 'top' : !field.invalid && field.hasHint ? 'bottom' : 'middle',

      [breakPoint]: {
        fontSize: 14,
        paddingBottom: 0,
      },
    },
    labelLength: {
      visibility: 'hidden',
      whiteSpace: 'nowrap',
    },
    labelText: {
      left: 0,
      position: 'absolute',
    },
    value: {
      fontWeight: 700,
      width: 460,
      wordBreak: 'break-word',
      // verticalAlign: 'middle',

      [breakPoint]: {
        width: '100%',
      },
    },
    badges: {
      paddingLeft: 10,
      whiteSpace: 'nowrap',
      width: 145,

      [breakPoint]: {
        display: 'none',
      },
    },
  });

  // if (isInvalid) { this.sectionValid[sectionId] = false; }

  return (
    <tr style={S.tr}>
      <td ref={`${sectionId}_${field.id}_label`} style={[S.td, S.label] as any}>
        <span style={S.labelLength}>{longestTitle}</span>
        <label style={[S.labelText] as any} htmlFor={`${sectionId}_${field.id}_input`}>
          {field.label}
        </label>
      </td>
      <td ref={`${sectionId}_${field.id}_val`} style={[S.td, S.value] as any}>
        {field.value}
      </td>
      <td ref={`${sectionId}_${field}_badges`} style={[S.td, S.badges] as any}>
        {field.pending && <span style={S.pendingBadge}>PENDING</span>}
      </td>
    </tr>
  );
});
