import React, { FunctionComponent } from 'react';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';

import { Modal, ModalProps } from '@hyperfish/fishfood/lib/components/Modal';
import { Button } from '@hyperfish/fishfood/lib/components/Button';
import { FiDownload } from '@hyperfish/fishfood/lib/components/Icon';
import { ApiResult, UserSearchResponse } from '@hyperfish/antrea-api-contracts';
import { getFrom } from '@hyperfish/fishfood/lib/utils/GetUtil';
import { LoadingSplash } from '@hyperfish/fishfood/lib/components/LoadingSplash';
import SchemaUtil from '@hyperfish/fishfood/lib/utils/SchemaUtil';
import saveAs from 'file-saver';

export interface ViolationUsersExportModalProps {
  onClose: ModalProps['onClose'];
  violations: UserSearchResponse[];
  emails: { [email: string]: any };
  emailsLoading: { [email: string]: boolean };
  emailsError: { [email: string]: any };
  schema: ApiResult;
  audienceId: string;
}

export const ViolationUsersExportModal: FunctionComponent<ViolationUsersExportModalProps> = ({
  onClose,
  violations,
  emails,
  emailsLoading,
  emailsError,
  schema,
  audienceId,
}) => {
  const { t } = useTranslation('directorySearch');

  const onExport = () => {
    const trimChar = (string, charToRemove) => {
      while (string.charAt(0) == charToRemove) {
        string = string.substring(1);
      }

      while (string.charAt(string.length - 1) == charToRemove) {
        string = string.substring(0, string.length - 1);
      }

      return string;
    };

    const fields = ['email', 'property', 'displayName', 'violation', 'reminders'];

    const data = violations.map(v => {
      const email = getFrom(v)('email').value;
      const property = trimChar(Object.keys(v.violations)[0], '/');
      const field = SchemaUtil.GetFieldByProperty(schema, property);
      const displayName = getFrom(field)('audienceConfigs')(audienceId)('ui')('title').value;
      const violation = Object.values(v.violations)[0];
      const reminders = getFrom(emails[email])('total').value;

      return [email, property, displayName, violation, reminders];
    });
    const body = `sep=,\n${Papa.unparse({ fields, data: data })}`;
    const blob = new Blob([body], { type: 'application/octet-stream' });
    const fileName = 'DirectoryViolationLog.csv';

    saveAs(blob, fileName);

    onClose();
  };

  let loadedEmails = 0;
  let failed;
  for (let index = 0; index < violations.length; index++) {
    const email = getFrom(violations[index])('email').value;
    if (!!emailsError[email]) {
      failed = true;
      break;
    }
    if (!emailsLoading[email] && !!emails[email]) {
      loadedEmails++;
    }
  }

  if (failed) {
    return (
      <Modal onClose={onClose}>
        <Modal.Header>{t('exportModalFailedHeader')}</Modal.Header>
        <p>{t('exportModalFailedDesc')}</p>
        <Modal.ButtonContainer>
          <Button onClick={onClose}>{t('close')}</Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  return (
    <Modal onClose={onClose}>
      {loadedEmails < violations.length ? (
        <>
          <Modal.Header>{t('exportModalLoadingHeader', { page: loadedEmails, total: violations.length })}</Modal.Header>
          <LoadingSplash />
        </>
      ) : (
        <>
          <Modal.Header>{t('exportModalSuccessHeader')}</Modal.Header>
          <Modal.ButtonContainer>
            <Button onClick={onClose}>{t('cancel')}</Button>
            <Button variant="solid" color="primary" icon={<FiDownload />} onClick={onExport}>
              {t('exportModalSuccessDownloadButton')}
            </Button>
          </Modal.ButtonContainer>
        </>
      )}
    </Modal>
  );
};
