/** Org Settings are stored as { value: <actual value> }, these types represent the actual value structures */
export interface OrgSettingTypes {
  branding_emailHeaderImage: string;
  /** these keys actually represent an enumeration of field names for each of these settings  */
  'attributes_approval_manualApproval_*': boolean;
  'broadcast_template_*': { templateId: string };
  global_mode: 'Analyze' | 'Run' | 'Pilot';
  read_only_mode: boolean;
  audit_exclusions: {
    name: string;
    distinguishedName: string;
  }[];
  audit_scopes: {
    name: string;
    distinguishedName: string;
  }[];
  collections_attributes: { enabled: boolean; fieldName: string };
  audit_storeProfileData: boolean;
  audit_includeGuestAccounts: boolean;
  audit_schedule_daily: boolean;
  audit_schedule_realtime: boolean;
  audit_schedule_dailyFullAuditTime: string;
  broadcast_tone: string;
  broadcast_email: string;
  broadcast_botName: string;
  broadcast_enabled: boolean;
  broadcast_exclusions: { type: string; address: string }[];
  broadcast_magicLinksEnabled: boolean;
  broadcast_magicLinksExpiryTime: number;
  broadcast_channels_email: 'Active' | 'Disabled';
  broadcast_channels_skypeForBusiness: 'Active' | 'Disabled';
  broadcast_reminders_limit: number;
  broadcast_reminders_frequency: string;
  broadcast_profile_runAt: string;
  broadcast_profile_enabled: boolean;
  broadcast_profile_frequency: string;
  broadcast_approval_forceTemplateUsage: boolean;
  broadcast_approval_dailyTimeToNotify: string;
  broadcast_approval_whenToNotifyApprovers: string;
  broadcast_adminReport_enabled: boolean;
  auth_impersonation_microsoftGraph_providerId: string;
  auth_providers_microsoftGraph_adminConsent: boolean;
  inheritance_profileBranding: boolean;
  inheritance_emailBranding: boolean;
  inheritance_approverPermissions: boolean;
  inheritance_autoApprove: boolean;
  inheritance_groups: boolean;
  inheritance_broadcast_tone: boolean;
  onboarding_step: 'mode' | 'type' | 'download' | 'connect' | 'analyze' | 'configure' | 'run' | 'complete';
  locale_default: string;
  locale_attributeMapping: {
    fieldName: string;
    mappings: { value: string; locale: string }[];
  };
  auth_app_only_admin_consent_given: boolean;
  general_supportEmail: string;
  general_whitelabel_frontendUrl: string;
  email_provider: 'Sendgrid' | 'MsGraph';
  email_msGraph_adminConsent: boolean;
  email_msGraph_fromUser: string;
  email_sendGrid_fromUser: string;
  webparts_config_profile: object;
  webparts_config_directory: object;
  webparts_config_orgchart: object;
  audit_scopes_advanced_filter: string;
  audit_filters: OrgAuditFilter;
  audit_source: 'MsGraph' | 'ProfileApi';
}

export interface OrgAuditFilter {
  enabled: boolean;
  isAdvanced: boolean;
  advancedFilter: string;
  basicFilter: {
    property: string;
    op: string;
    value: string[];
  }[];
}

/** Org Settings are stored as { value: <actual value> }, these types represent the actual value structures */
// tslint:disable-next-line:interface-over-type-literal
export type OrgSettings = OrgSettingTypes;

/** forces string keys to be typed explicitly */
const Strictly = <T extends { [k: string]: R }, R extends keyof OrgSettingTypes, Y>(obj: T, x?: Y) =>
  (x ? Object.assign(obj, x) : obj) as T & Y;
export const OrgSettingKeyDelim = '_';
export const OrgSettingKeyWildcard = '_*';
/**
 * This will probably suck to maintain
 */
export const OrgSettingsKeys = {
  global: Strictly({
    mode: 'global_mode',
    readOnlyMode: 'read_only_mode',
  }),
  general: Strictly(
    {
      supportEmail: 'general_supportEmail',
    },
    {
      whitelabel: Strictly({
        frontend_url: 'general_whitelabel_frontendUrl',
      }),
    },
  ),
  collections: Strictly({
    attributes: 'collections_attributes',
  }),
  audit: Strictly(
    {
      scopes: 'audit_scopes',
      includeGuestAccounts: 'audit_includeGuestAccounts',
      exclusions: 'audit_exclusions',
      storeProfileData: 'audit_storeProfileData',
      filters: 'audit_filters',
      source: 'audit_source',
    },
    {
      schedule: Strictly({
        daily: 'audit_schedule_daily',
        realtime: 'audit_schedule_realtime',
        dailyFullAuditTime: 'audit_schedule_dailyFullAuditTime',
      }),
    },
  ),
  branding: Strictly({
    emailHeaderImage: 'branding_emailHeaderImage',
  }),
  broadcast: Strictly(
    {
      tone: 'broadcast_tone',
      email: 'broadcast_email',
      botName: 'broadcast_botName',
      enabled: 'broadcast_enabled',
      exclusions: 'broadcast_exclusions',
    },
    {
      magicLinks: Strictly({
        enabled: 'broadcast_magicLinksEnabled',
        expiryTime: 'broadcast_magicLinksExpiryTime',
      }),
      template: Strictly({
        broadcastTemplateWildcard: 'broadcast_template_*',
      }),
      channels: Strictly({
        email: 'broadcast_channels_email',
        skypeForBusiness: 'broadcast_channels_skypeForBusiness',
      }),
      reminders: Strictly({
        limit: 'broadcast_reminders_limit',
        frequency: 'broadcast_reminders_frequency',
      }),
      profile: Strictly({
        runAt: 'broadcast_profile_runAt',
        enabled: 'broadcast_profile_enabled',
        frequency: 'broadcast_profile_frequency',
      }),
      approval: Strictly({
        forceTemplateUsage: 'broadcast_approval_forceTemplateUsage',
        dailyTimeToNotify: 'broadcast_approval_dailyTimeToNotify',
        whenToNotifyApprovers: 'broadcast_approval_whenToNotifyApprovers',
      }),
      adminReport: Strictly({
        enabled: 'broadcast_adminReport_enabled',
      }),
    },
  ),
  auth: {
    providers: {
      microsoftGraph: Strictly({
        adminConsent: 'auth_providers_microsoftGraph_adminConsent',
      }),
    },
    impersonation: {
      microsoftGraph: Strictly({
        providerId: 'auth_impersonation_microsoftGraph_providerId',
      }),
    },
  },
  inheritance: Strictly({
    profileBranding: 'inheritance_profileBranding',
    emailBranding: 'inheritance_emailBranding',
    autoApprove: 'inheritance_autoApprove',
    approverPermissions: 'inheritance_approverPermissions',
    groups: 'inheritance_groups',
    broadcastTone: 'inheritance_broadcast_tone',
  }),
  onboarding: Strictly({
    step: 'onboarding_step',
  }),
  locale: Strictly({
    default: 'locale_default',
    attributeMapping: 'locale_attributeMapping',
  }),
  attributes: Strictly(
    {},
    {
      approval: Strictly({
        /** Do not use this for concatenation */
        manualApprovalWildcard: 'attributes_approval_manualApproval_*',
      }),
    },
  ),
  email: Strictly(
    {
      provider: 'email_provider',
    },
    {
      msGraph: Strictly({
        adminConsent: 'email_msGraph_adminConsent',
        fromUser: 'email_msGraph_fromUser',
      }),
      sendGrid: Strictly({
        fromUser: 'email_sendGrid_fromUser',
      }),
    },
  ),
  webparts: Strictly(
    {},
    {
      config: Strictly({
        directory: 'webparts_config_directory',
        orgchart: 'webparts_config_orgchart',
        profile: 'webparts_config_profile',
      }),
    },
  ),
};
