import { ResponseOuApiAudience } from '@hyperfish/antrea-api-contracts/src/audience';
import cx from 'classnames';
import Radium from 'radium';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { show as showModal } from '../redux/modules/premiumModals';
import { HyperfishLogo } from './HyperfishLogo';
import { PremiumLock } from './PremiumLock';
import { ModalKeys } from './PremiumModal';
import { Select, FiChevronRight } from '@hyperfish/fishfood';

export interface NavItem {
  hide?: boolean;
  disabled?: boolean;
  title?: string;
  icon?: ReactNode;
  label: string;
  notifications?: number;
  path?: string;
  subNav?: NavItem[];
  showAudiencePicker?: boolean;
  premiumLock?: boolean;
}

interface Props {
  activeAudience: string;
  audiences: ResponseOuApiAudience[];
  onAudienceChange: (audienceId: string) => void;
  disabled?: boolean;
  navList: NavItem[];
  path: string;
  showModal: typeof showModal;
}

interface State {
  activeSub?: NavItem;
  justClicked?: boolean;
}

@Radium
export class SideNav extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps({ navList, path }: Props) {
    for (const navItem of navList) {
      if (!navItem.subNav || !navItem.subNav.length) {
        continue;
      }

      for (const subNavItem of navItem.subNav) {
        if (!subNavItem) {
          continue;
        }

        if (path.indexOf(subNavItem.path) === 0) {
          this.setState({ activeSub: navItem });
          return;
        }
      }
    }
  }

  render() {
    const { activeAudience, audiences, navList, path, disabled, onAudienceChange, showModal } = this.props;
    const { activeSub, justClicked } = this.state;

    let activeSubIndex;
    if (!!activeSub) {
      for (let i = 0; i < activeSub.subNav.length; i++) {
        if (path.indexOf(activeSub.subNav[i].path) === 0) {
          activeSubIndex = i;
          break;
        }
      }
    }

    return (
      <aside
        className={cx('side-nav', {
          'sub-active': !!activeSub,
          disabled,
          'nav-hover': Radium.getState(this.state, 'nav', ':hover'),
        })}
      >
        <a href="/" className="logo-container">
          <HyperfishLogo />
        </a>
        <ul className="nav" style={{ ':hover': {} } as any} onMouseOut={() => this.setState({ justClicked: false })}>
          {navList
            .filter(n => !n.hide)
            .map(n => (
              <li
                key={n.path}
                className={cx(`nav-item${n.disabled ? ' disabled' : ''}`, {
                  active: path.indexOf(n.path) === 0,
                  'sub-active': activeSub === n,
                  upgrade: n.path === '/upgrade',
                })}
              >
                {!n.subNav ? (
                  <Link
                    to={!n.disabled ? n.path : '#'}
                    title={n.title}
                    onClick={e => (!disabled && !n.disabled ? this.setState({ activeSub: null }) : e.preventDefault())}
                  >
                    {n.icon}
                    <span>
                      {n.label}
                      {(n.notifications || 0) > 0 && ` (${n.notifications})`}
                    </span>
                    {n.premiumLock && <PremiumLock onClick={() => showModal(ModalKeys.collections)} />}
                  </Link>
                ) : (
                  <Link
                    to={n.path}
                    title={n.title}
                    onClick={e =>
                      !disabled && !n.disabled ? this.setState({ activeSub: n, justClicked: true }) : e.preventDefault()
                    }
                  >
                    {n.icon}
                    <span>
                      {n.label}
                      {(n.notifications || 0) > 0 && ` (${n.notifications})`}
                    </span>
                    <FiChevronRight />
                  </Link>
                )}
              </li>
            ))}
        </ul>
        {activeSub && (
          <ul className="sub-nav" style={justClicked ? { left: 48 } : {}}>
            <li className="nav-item sub-nav-header">{activeSub.label}</li>
            {activeSub.showAudiencePicker && (
              <li className="nav-item audience-picker">
                <Select
                  value={
                    audiences &&
                    audiences.filter(a => a.id === activeAudience).map(a => ({ label: a.definition.name, value: a.id }))
                  }
                  placeholder="Master"
                  isClearable={true}
                  options={
                    audiences &&
                    audiences
                      .map(a => ({ label: a.definition.name, value: a.id }))
                      .sort(({ label: aLabel }, { label: bLabel }) => (aLabel > bLabel ? 1 : aLabel < bLabel ? -1 : 0))
                  }
                  onChange={val => onAudienceChange && onAudienceChange(val ? ((val as any).value as string) : null)}
                  styles={{
                    control: base => ({
                      ...base,
                      height: 34,
                      minHeight: 34,
                      borderRadius: 0,
                    }),
                    menu: base => ({
                      ...base,
                      borderRadius: 0,
                    }),
                  }}
                  // arrowRenderer={() => (
                  //   <span className="audience-picker-icon"><Icon name="search" /></span>
                  // )} TODO: Fix this
                />
              </li>
            )}
            {activeSub.subNav
              .filter(n => !n.hide)
              .map((n, i) => (
                <li key={n.path} className={cx('nav-item', { active: i === activeSubIndex })}>
                  <Link to={{ pathname: n.path }}>
                    <span>{n.label}</span>
                  </Link>
                </li>
              ))}
            {activeSubIndex != null && (
              <li
                className="nav-item bar"
                style={{
                  transform: `translateY(${(activeSubIndex - (activeSub.showAudiencePicker ? 0 : 1)) * 50}px)`,
                }}
              />
            )}
          </ul>
        )}
      </aside>
    );
  }
}
