import { GlobalState } from '../../models/client/index';

export const dummyCollections = [
  {
    id: '1',
    type: 'Attribute',
    definition: {
      fieldName: 'officeLocation',
      values: ['Seattle'],
      name: 'Example Collection 1',
      description: 'Example Collection 1',
    },
  },
  {
    id: '2',
    type: 'Attribute',
    definition: {
      fieldName: 'officeLocation',
      values: ['Portland'],
      name: 'Example Collection 2',
      description: 'Example Collection 2',
    },
  },
  {
    id: '3',
    type: 'Attribute',
    definition: {
      fieldName: 'officeLocation',
      values: ['Denver'],
      name: 'Example Collection 3',
      description: 'Example Collection 3',
    },
  },
  {
    id: '4',
    type: 'Attribute',
    definition: {
      fieldName: 'officeLocation',
      values: ['Miami'],
      name: 'Example Collection 4',
      description: 'Example Collection 4',
    },
  },
  {
    id: '5',
    type: 'Attribute',
    definition: {
      fieldName: 'officeLocation',
      values: ['New York'],
      name: 'Example Collection 5',
      description: 'Example Collection 5',
    },
  },
] as (GlobalState['collections']['dataById']['foo'])[];

export const dummyCollectionsById = {};
dummyCollections.forEach(c => (dummyCollectionsById[c.id] = c));

export const dummyRelatedEntities: GlobalState['collections']['relatedEntities'] = {
  audienceDisplay: {
    1: {
      lastModified: new Date().toISOString(),
      lastModifiedBy: { userId: 'dummy' },
      lastAudit: { recordCount: 50 },
      settings: {},
    },
    2: {
      lastModified: new Date().toISOString(),
      lastModifiedBy: { userId: 'dummy' },
      lastAudit: { recordCount: 100 },
      settings: {},
    },
    3: {
      lastModified: new Date().toISOString(),
      lastModifiedBy: { userId: 'dummy' },
      lastAudit: { recordCount: 75 },
      settings: {},
    },
    4: {
      lastModified: new Date().toISOString(),
      lastModifiedBy: { userId: 'dummy' },
      lastAudit: { recordCount: 20 },
      settings: {},
    },
    5: {
      lastModified: new Date().toISOString(),
      lastModifiedBy: { userId: 'dummy' },
      lastAudit: { recordCount: 150 },
      settings: {},
    },
  },
  users: {
    dummy: { displayName: 'John Doe' },
  },
};
