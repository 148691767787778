import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.StatelessComponent = () => (
  <div className="view-404 container">
    <h1>404</h1>
    <h2>There&apos;s nothing here</h2>
    <p>If you followed a link and ended up here, please let us know!</p>
    <p>
      <Link to="/">Click here to go back to the known universe</Link>
    </p>
  </div>
);

export default NotFound;
