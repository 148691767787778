import React, { FunctionComponent, useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DirectorySearch } from '@hyperfish/fishfood/lib/components/DirectorySearch';
import { LoadingSplash } from '@hyperfish/fishfood/lib/components/LoadingSplash';
import { PhotoValidationResponse } from '@hyperfish/fishfood/lib/components/UserDetail/UserDetailPhotoUpload';
import { ApiClientContext } from '@hyperfish/fishfood/lib/utils/ApiClient';
import { ApiResult } from '@hyperfish/antrea-api-contracts';
import { isAdmin, isEditor } from '../../redux/modules/auth';
import { loadWebpartSetting as loadSettings } from '../../redux/modules/settings';
import { saveDetail, savePhoto } from '../../redux/modules/externalUsers';
import { validateImage } from '../../redux/modules/validations';
import { hasOrgFeature, isLoadedCurrent } from '../../redux/modules/orgs';
import { ORG_FEATURE_DIRECTORY_WEB_PARTS } from '../../config';
import { Redirect } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ApiUser } from '@hyperfish/fishfood/lib/utils/UserUtil';
import classes from './styles.module.scss';
import { getFrom } from '@hyperfish/fishfood';
import WebpartUtil, { CustomLabels } from '@hyperfish/fishfood/lib/utils/WebpartUtil';

const connector = connect(
  state => ({
    currentUserId: state.auth.userId,
    isAdmin: isAdmin(state),
    isEditor: isEditor(state),
    orgId: state.orgs.current && state.orgs.current.id,
    settings: state.settings.data,
    settingsLoading: state.settings.loading,
    loaded: isLoadedCurrent(state),
    webpartsLicensed: hasOrgFeature(state, ORG_FEATURE_DIRECTORY_WEB_PARTS),
  }),
  {
    loadSettings,
    saveDetail,
    validateImage,
    savePhoto,
  },
);

type Props = typeof connector['props'];

const DirectoryC: FunctionComponent<Props> = ({
  currentUserId,
  isAdmin,
  isEditor,
  orgId,
  settings,
  settingsLoading,
  loaded,
  webpartsLicensed,
  loadSettings,
  saveDetail,
  validateImage,
  savePhoto,
}) => {
  const client = useContext(ApiClientContext);
  const [error, setError] = useState<any>(false);
  const [schema, setSchema] = useState<ApiResult>(null);
  const [audienceId, setAudienceId] = useState<string>(null);
  const [internalSettings, setInternalSettings] = useState<undefined | {}>();

  const onSaveInternal = (profileId: string, data: ApiUser) =>
    (saveDetail(currentUserId === profileId ? 'me' : profileId, data) as any).then(({ data }) => data);

  const onSavePhotoInternal = (profileId: string, photoFieldId: string, photoDataUri) =>
    (savePhoto(
      currentUserId === profileId ? 'me' : profileId,
      photoFieldId,
      photoDataUri.substring(photoDataUri.indexOf(',') + 1),
    ) as any).then(({ data }) => data);

  const onValidatePhotoInternal = (photoDataUri: string) =>
    ((validateImage(photoDataUri.substring(photoDataUri.indexOf(',') + 1)) as any) as Promise<
      AxiosResponse<PhotoValidationResponse>
    >).then(({ data }) => data);

  const onDeletePhotoInternal = (profileId: string, photoFieldId: string) =>
    (savePhoto(currentUserId === profileId ? 'me' : profileId, photoFieldId, null) as any).then(({ data }) => data);

  useEffect(() => {
    const config = getFrom(settings)(orgId)('webparts_config_directory').defaultTo({});
    const _customLabels: CustomLabels = WebpartUtil.GetCustomLabels(config);
    if (_customLabels) {
      config['customLabels'] = _customLabels;
    }

    const _disableFilters = getFrom(config as any)('disableFilters').defaultTo(false);
    if (_disableFilters) {
      config['showSuggestions'] = false;
    }

    setInternalSettings(config);
  }, [settings, orgId]);

  useEffect(() => {
    loadSettings();
  }, []);

  useEffect(() => {
    client.api.fieldSchemas
      .get()
      .then(({ data }) => setSchema(data))
      .catch(err => setError(err || true));
    client.api.externalUsers
      .getAudience('me')
      .then(({ data }) => setAudienceId(data.audienceId))
      .catch(err => setError(err || true));
  }, []);

  if (error) {
    return (
      <div className="container">
        <h2>There was a problem loading the directory</h2>
        <p>We were unable to load the data for the directory. Please try again later.</p>
      </div>
    );
  }

  if (loaded && !webpartsLicensed) {
    return <Redirect to={'/self'} />;
  }

  if (settingsLoading || !internalSettings) {
    return <LoadingSplash />;
  }

  if (internalSettings && internalSettings['enabled'] === false) {
    return <Redirect to={'/self'} />;
  }

  return (
    <div className={`container ${classes.container}`}>
      <DirectorySearch
        {...internalSettings}
        schema={schema}
        audienceId={audienceId}
        masterAudienceId={orgId}
        currentUserId={currentUserId}
        isAdmin={isAdmin}
        isEditor={isEditor}
        fetchResults={request => client.api.directory.search(request).then(({ data }) => data)}
        fetchUsers={(q, limit) => client.api.externalUsers.search(q, limit).then(res => res.data)}
        fetchTree={request => client.api.directory.tree(request).then(({ data }) => data)}
        fetchDetail={id => client.api.externalUsers.get(id, { cacheok: 1 }).then(({ data }) => data)}
        onDeletePhoto={onDeletePhotoInternal}
        onSave={onSaveInternal}
        onSavePhoto={onSavePhotoInternal}
        onValidatePhoto={onValidatePhotoInternal}
        showCommIcons={getFrom(internalSettings as any)('showCommIcons').defaultTo(false)}
        customLabels={getFrom(internalSettings as any)('customLabels').value}
      />
    </div>
  );
};

export const Directory = connector(DirectoryC);

export default Directory;
