import colors from './colors';
import * as styles from './styles';
import typerFunction from './typerFunction';

type StyleUtil = typeof typerFunction & {
  styles: typeof styles;
  colors: typeof colors;
};

// export default class StyleUtil {
//   static colors = colors;
//   static styles = styles;
//   static type = typerFunction;
// }

export const StyleUtil = typerFunction as StyleUtil;
StyleUtil.styles = styles;
StyleUtil.colors = colors;

export default StyleUtil;
