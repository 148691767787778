import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { FieldArray } from 'formik';
import { Form } from '@hyperfish/fishfood/lib/components/Form';
import { Button, IconButton } from '@hyperfish/fishfood/lib/components/Button';
import { Select, stringToOption } from '@hyperfish/fishfood/lib/components/Select';
import { FiRefreshCcw } from '@hyperfish/fishfood/lib/components/Icon';
import SchemaUtil from '@hyperfish/fishfood/lib/utils/SchemaUtil';
import { SettingsProps } from '../../../../layouts/SettingsLayout';
import { icons } from './components/icons';
import { DirectoryFormFilterModal } from './components/DirectoryFormFilterModal';
import { DirectoryFormSortModal } from './components/DirectoryFormSortModal';
import { DirectoryFormClearModal } from './components/DirectoryFormClearModal';

import {
  ButtonContainer,
  CustomLabelItemContainer,
  DeleteIcon,
  Divide,
  FieldSpacer,
  FlexColumn,
  FlexItem,
  FlexRow,
  FlexWrapper,
  InternalFieldSet,
  InternalFormContainer,
  SliderIcon,
  SortIcon,
} from '../FormComponents';
import { DirectorySearchSortField } from '@hyperfish/antrea-api-contracts/src/directory';
import { OrgSettingTypes } from '@hyperfish/antrea-api-contracts/src/org';
import { ApiResult } from '@hyperfish/antrea-api-contracts/src/fields';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import { RFC5646_LANGUAGE_TAGS } from '../../../../utils/rfc5646-language-tags';

interface Option {
  label: string;
  value: string;
}

interface Props {
  collapsable?: boolean;
  settings: OrgSettingTypes;
  schema: ApiResult;
  currentOrgId: string;
  onChange?: (object) => void;
  forSnippet?: boolean;
}

interface FormProps {
  values: any;
  dirty: boolean;
  errors: {};
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const DEFAULT_SORT: DirectorySearchSortField = { displayname: 'asc', displayName: 'asc' };
const DEFAULT_LIMIT = '100';

export const DirectoryForm: React.FC<Props & Pick<SettingsProps, 'save'>> = ({
  save,
  currentOrgId,
  settings,
  schema,
  collapsable,
  onChange,
  forSnippet,
}) => {
  const [directoryValues, setDirectoryValues] = useState<object>();
  const [fields, setFields] = useState<Option[]>();
  const [selectedIcon, setSelectedIcon] = useState<string>();
  const [selectedProperty, setSelectedProperty] = useState<string>();
  const [selectedLabel, setSelectedLabel] = useState<string>();
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [customLabelValue, setCustomLabelValue] = useState<string>(null);
  const [schemaUtil, setSchemaUtil] = useState<SchemaUtil>(null);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showSortModal, setShowSortModal] = useState<boolean>(false);
  const [showClearModal, setShowClearModal] = useState<boolean>(false);

  const getIconOptions = () => {
    return icons.map(icon => ({
      name: icon,
      label: (
        <>
          <img
            src={`https://hyperfish.azureedge.net/webpart/legacyicons/${icon}.svg`}
            style={{
              display: 'inline-block',
              width: '1.1em',
              height: 'auto',
              verticalAlign: 'middle',
              marginRight: '5px',
            }}
          />
          {icon}
        </>
      ),
    }));
  };

  const CustomizableLabels = [{ name: 'placeholder', label: 'Search Query Placeholder' }];

  const LanguageOptions = [
    { name: 'en-US', label: 'English (United States)' },
    { name: 'fr-CA', label: 'French (Canada)' },
    { name: 'fr', label: 'French' },
    { name: 'nl', label: 'Dutch' },
  ];

  const shallowEqual = (object1, object2) => {
    if (!object1 || !object2) {
      return false;
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  };

  const haveSameContent = (a: any[], b: any[]) => {
    if (!(Array.isArray(a) && Array.isArray(b))) {
      return false;
    }

    for (const v of Array.from(new Set([...a, ...b]))) {
      if (a.filter(e => e === v).length !== b.filter(e => e === v).length) {
        return false;
      }
    }

    return true;
  };

  const OptionValueComparer = (otherArray: string[]) => {
    if (!Array.isArray(otherArray)) {
      return () => {};
    }

    return (current: { label: any; value: any }): boolean => {
      return (
        otherArray.filter(other => {
          return other === current.value;
        }).length == 0
      );
    };
  };

  useEffect(() => {
    const getFields = () => {
      const schemaFields = Object.keys(schema.fields).map(field => ({
        label: schema.fields[field].audienceConfigs[currentOrgId].ui.title,
        value: schema.fields[field].property,
      }));

      return schemaFields.sort((a, b) => a.label.localeCompare(b.label));
    };

    if (schema && currentOrgId) {
      setSchemaUtil(new SchemaUtil(schema, currentOrgId, currentOrgId));
      setFields(getFields());
    }
  }, [schema, currentOrgId]);

  useEffect(() => {
    const webpartConfig: any = getFrom(settings)('webparts_config_directory').defaultTo({});

    if (Array.isArray(fields)) {
      const config = {
        ...webpartConfig,
        defaultSort: getFrom(webpartConfig)('defaultSort').defaultTo(DEFAULT_SORT),
        limit: getFrom(webpartConfig)('pageSize')
          .defaultTo(DEFAULT_LIMIT)
          .toString(),
        unfilterableFields: fields.filter(
          OptionValueComparer(getFrom(webpartConfig)('filterableFields').defaultTo([])),
        ),
        directorySearch: getFrom(webpartConfig)('customLabels')('directorySearch').defaultTo([]),
        showSuggestions: getFrom(webpartConfig)('showSuggestions').defaultTo(true),
        expandSuggestions: getFrom(webpartConfig)('expandSuggestions').defaultTo(true),
        maxSuggestions: getFrom(webpartConfig)('maxSuggestions').defaultTo(3),
      };

      setDirectoryValues(config);
    }
  }, [settings.webparts_config_directory, fields]);

  const onSubmit = (values, actions) => {
    const processCommonAttributes = (val: any) => {
      // invert unfilterableFields to filterableFields
      const unfilterableFields: string[] | Option[] = getFrom(val)('unfilterableFields').defaultTo([]);
      if (unfilterableFields.length > 0) {
        const itemType = typeof unfilterableFields[0];
        val.filterableFields =
          itemType === 'string'
            ? fields.filter(f => !(unfilterableFields as string[]).includes(f.value)).map(f => f.value)
            : fields
                .filter(f => !(unfilterableFields as Option[]).map(option => option.value).includes(f.value))
                .map(f => f.value);
        delete val['unfilterableFields'];
      }

      // transform limit to pageSize (as number)
      val['pageSize'] = parseInt(getFrom(val)('limit').defaultTo(DEFAULT_LIMIT));

      const customLabels = getFrom(val)('customLabels').defaultTo({});
      const directorySearch = getFrom(val)('directorySearch').defaultTo([]);
      if (directorySearch.length > 0) {
        customLabels['directorySearch'] = directorySearch;
      }

      val['customLabels'] = customLabels;

      // remove unused properties
      delete val['customLabelValue'];

      return val;
    };

    const processSnippetAttributes = (val: any) => {
      let attributes = processCommonAttributes({ ...val });

      // set defaultSearch object for snippets
      if (!!attributes['defaultQuery'] || Array.isArray(attributes['defaultFilters']) || !!attributes['defaultSort']) {
        attributes['defaultSearch'] = {};
        if (!!attributes['defaultQuery']) {
          attributes['defaultSearch']['query'] = attributes['defaultQuery'];
          delete attributes['defaultQuery'];
        }
        if (!!attributes['defaultFilters']) {
          attributes['defaultSearch']['filters'] = attributes['defaultFilters'];
          delete attributes['defaultFilters'];
        }
        if (!!attributes['defaultSort']) {
          attributes['defaultSearch']['sort'] = attributes['defaultSort'];
          delete attributes['defaultSort'];
        }
      }

      return attributes;
    };

    const processWebpartAttributes = (val: any) => {
      const attributes = processCommonAttributes({ ...val });

      // delete unused attributes
      delete attributes.directorySearch;

      return attributes;
    };

    const processValues = (val: object, processor: Function) => {
      const attributes = processor(val);

      return Object.keys(attributes)
        .map(x =>
          Array.isArray(attributes[x]) && attributes[x].length === 0 ? { [x]: undefined } : { [x]: attributes[x] },
        )
        .reduce((prev, cur) => ({ ...prev, ...cur }), {});
    };

    const cleanedValues = processValues(
      { ...values },
      forSnippet ? processSnippetAttributes : processWebpartAttributes,
    );

    if (onChange) {
      onChange(cleanedValues);
    } else {
      save(currentOrgId, { webparts_config_directory: cleanedValues });
    }

    setDirectoryValues(cleanedValues);
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const validationSchema = Yup.object().shape({
    limit: Yup.string().required('Required'),
    cardFields: Yup.array().of(
      Yup.object().shape({
        property: Yup.string().required('Required'),
        icon: Yup.string().required('Required'),
      }),
    ),
  });

  const renderForm = (props: FormProps) => {
    const { values, dirty, errors, setFieldValue } = props;
    const hideSearchUi = !values.disableFilters;

    return (
      <Form.FormikForm>
        <InternalFormContainer>
          <InternalFieldSet legend="Search Options" collapsable={collapsable}>
            <FlexWrapper>
              <Form.Fields.Text name="defaultQuery" label="Default search query" />
              <ButtonContainer padding={[1]}>
                <Button size="small" icon={<SliderIcon />} iconColor="accent" onClick={() => setShowFilterModal(true)}>
                  {`Filters (${getFrom(values)('defaultFilters').defaultTo([]).length})`}
                </Button>
                <Button size="small" icon={<SortIcon />} iconColor="accent" onClick={() => setShowSortModal(true)}>
                  {'Sort by'}
                </Button>
                <Button size="small" icon={<FiRefreshCcw />} iconColor="accent" onClick={() => setShowClearModal(true)}>
                  {'reset'}
                </Button>
              </ButtonContainer>

              <Form.Fields.Toggle name="disableFilters" label="Hide Search UI" />
              {hideSearchUi ? (
                <Form.Fields.Toggle name="showSuggestions" label="Show Search Suggestions" />
              ) : (
                <FieldSpacer />
              )}

              {hideSearchUi && values.showSuggestions && (
                <Form.Fields.Toggle name="expandSuggestions" label="Expand Search Suggestions" />
              )}
              {hideSearchUi && values.showSuggestions && (
                <Form.Fields.Text
                  name="maxSuggestions"
                  label="Max Search Suggestions"
                  inputProps={{ type: 'number' }}
                />
              )}
              <Form.Fields.Select
                name="unfilterableFields"
                label="Unfilterable attributes"
                inputProps={{
                  options: fields,
                  isMulti: true,
                  closeMenuOnSelect: false,
                }}
              />
            </FlexWrapper>
          </InternalFieldSet>

          <InternalFieldSet legend="Results View Options" collapsable={collapsable}>
            <FlexWrapper>
              <Form.Fields.Toggle name="disableChartModal" label="Disable chart modal" />
              <Form.Fields.Toggle name="disableExport" label="Disable export" />
              <Form.Fields.Toggle name="disableViewSelect" label="Disable view select" />
              <Form.Fields.Text
                name="resultsMaxHeight"
                label="Results max height (in px)"
                inputProps={{ type: 'number' }}
              />
              <Form.Fields.Select
                name="defaultView"
                label="Default result view"
                inputProps={{ options: ['cards', 'table'].map(x => stringToOption(x)) }}
              />
              <Form.Fields.Select
                name="limit"
                label="Results per page"
                required
                inputProps={{ options: ['25', '50', '100'].map(x => stringToOption(x)) }}
              />
            </FlexWrapper>
          </InternalFieldSet>

          <InternalFieldSet legend="Card fields" collapsable>
            <FieldArray
              name="cardFields"
              render={arrayHelper => {
                return (
                  <FlexColumn>
                    {getFrom(values)('cardFields')
                      .defaultTo([])
                      .map((_, index) => (
                        <FlexRow key={`cardFields.${index}.icon`}>
                          <FlexItem grow maxWidth={46}>
                            <Form.Fields.Select
                              inputProps={{ options: icons.map(y => stringToOption(y)) }}
                              name={`cardFields.${index}.icon`}
                              label="Icon"
                            />
                          </FlexItem>
                          <FlexItem grow maxWidth={46}>
                            <Form.Fields.Select
                              inputProps={{ options: fields }}
                              name={`cardFields.${index}.property`}
                              label="Property"
                            />
                          </FlexItem>
                          <FlexItem>
                            <IconButton
                              size="small"
                              icon={<DeleteIcon />}
                              css
                              ariaLabel="Remove row"
                              onClick={() => arrayHelper.remove(index)}
                            />
                          </FlexItem>
                        </FlexRow>
                      ))}
                    <Divide />
                    <FlexRow>
                      <FlexItem grow>
                        <Select
                          name="selectIcon"
                          label="Select icon"
                          value={selectedIcon}
                          onChange={option => setSelectedIcon(getFrom(option)('name').defaultTo(undefined))}
                          getOptionValue={option => option.name}
                          options={getIconOptions()}
                        />
                      </FlexItem>
                      <FlexItem grow>
                        <Select
                          name="selectProperty"
                          label="Select property"
                          value={selectedProperty}
                          onChange={option => setSelectedProperty(getFrom(option)('value').defaultTo(undefined))}
                          options={fields}
                        />
                      </FlexItem>
                    </FlexRow>
                    <Button
                      onClick={() => {
                        arrayHelper.push({ icon: selectedIcon, property: selectedProperty });
                        setSelectedIcon('');
                        setSelectedProperty('');
                      }}
                      disabled={!selectedIcon || !selectedProperty}
                      style={{ marginTop: '10px' }}
                    >
                      Add card field
                    </Button>
                  </FlexColumn>
                );
              }}
            />
          </InternalFieldSet>

          <InternalFieldSet legend="Table fields" collapsable>
            <Form.Fields.Select
              name="tableFields"
              label="Table fields"
              inputProps={{
                options: fields,
                isMulti: true,
                closeMenuOnSelect: false,
              }}
            />
          </InternalFieldSet>

          <InternalFieldSet legend="Detail Modal Options" collapsable={collapsable}>
            <FlexWrapper>
              <Form.Fields.Toggle name="disableDetailModal" label="Disable detail modal" />
              <Form.Fields.Toggle name="showCommIcons" label="Show Communication Icons" />
              <Form.Fields.Select
                name="detailModalHiddenProperties"
                label="Hidden attributes"
                inputProps={{
                  options: fields,
                  isMulti: true,
                  closeMenuOnSelect: false,
                }}
              />
            </FlexWrapper>
          </InternalFieldSet>

          <InternalFieldSet legend="Custom Labels" collapsable>
            <FieldArray
              name="directorySearch"
              render={directorySearchArrayHelper => {
                return (
                  <FlexColumn>
                    {getFrom(values)('directorySearch')
                      .defaultTo([])
                      .map((_, index) => (
                        <CustomLabelItemContainer key={`directorySearch.${index}.label`}>
                          <FlexItem grow maxWidth={100}>
                            <FlexRow key={`directorySearch.${index}.label`}>
                              <FlexItem grow>
                                <Form.Fields.Select
                                  inputProps={{ options: CustomizableLabels }}
                                  name={`directorySearch.${index}.label`}
                                  label=""
                                  disabled={true}
                                />
                              </FlexItem>

                              <FlexItem grow>
                                <Form.Fields.Select
                                  inputProps={{ options: LanguageOptions }}
                                  name={`directorySearch.${index}.language`}
                                  label=""
                                  disabled={true}
                                />
                              </FlexItem>
                            </FlexRow>

                            <Form.Fields.Text name={`directorySearch.${index}.value`} label="" />
                          </FlexItem>

                          <FlexItem>
                            <IconButton
                              size="small"
                              icon={<DeleteIcon />}
                              css
                              ariaLabel="Remove row"
                              onClick={() => directorySearchArrayHelper.remove(index)}
                            />
                          </FlexItem>
                        </CustomLabelItemContainer>
                      ))}

                    <Divide />

                    <CustomLabelItemContainer>
                      <FlexItem grow maxWidth={100}>
                        <FlexRow>
                          <FlexItem grow maxWidth={50}>
                            <Select
                              name="selectedLabel"
                              label="Select Label"
                              value={selectedLabel ? CustomizableLabels[selectedLabel] : null}
                              onChange={option => setSelectedLabel(option)}
                              getOptionLabel={option => option.label}
                              getOptionValue={option => option.name}
                              options={CustomizableLabels}
                            />
                          </FlexItem>

                          <FlexItem grow maxWidth={50}>
                            <Select
                              name="selectedLanguage"
                              label="Select Language"
                              value={selectedLanguage ? LanguageOptions[selectedLanguage] : null}
                              onChange={option => setSelectedLanguage(option)}
                              getOptionLabel={option => option.label}
                              getOptionValue={option => option.name}
                              options={LanguageOptions}
                            />
                          </FlexItem>
                        </FlexRow>

                        <Form.Fields.Text
                          name="customLabelValue"
                          label=""
                          inputProps={{
                            onChange: evt => {
                              evt.preventDefault();
                              setCustomLabelValue(evt.currentTarget.value);
                              setFieldValue('customLabelValue', evt.currentTarget.value);
                            },
                          }}
                        />
                      </FlexItem>
                    </CustomLabelItemContainer>

                    <Button
                      onClick={() => {
                        directorySearchArrayHelper.push({
                          label: selectedLabel,
                          language: selectedLanguage,
                          value: customLabelValue,
                        });
                        setFieldValue('customLabelValue', []);
                        setCustomLabelValue('');
                        setSelectedLanguage('');
                        setSelectedLabel('');
                      }}
                      disabled={!selectedLabel || !selectedLanguage || !customLabelValue}
                      style={{ marginTop: '10px' }}
                    >
                      Add custom label
                    </Button>
                  </FlexColumn>
                );
              }}
            />
          </InternalFieldSet>

          <InternalFieldSet legend="MS Teams only" collapsable={collapsable}>
            <FlexWrapper>
              <Form.Fields.Toggle name="showPresenceIndicator" label="Show Presence Indicator" />
            </FlexWrapper>
          </InternalFieldSet>

          <ButtonContainer justify="flex-end" margin={[2.5, 0, 0, 0]}>
            {onChange ? (
              <Button type="submit" size="small" disabled={!dirty || Object.keys(errors).length > 0}>
                Update
              </Button>
            ) : (
              <>
                <Button type="reset" size="small" disabled={!dirty}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="solid"
                  color="warn"
                  disabled={!dirty || Object.keys(errors).length > 0}
                >
                  Save
                </Button>
              </>
            )}
          </ButtonContainer>
        </InternalFormContainer>
        {showFilterModal && (
          <DirectoryFormFilterModal
            initialFilters={getFrom(values)('defaultFilters').defaultTo([])}
            schemaUtil={schemaUtil}
            onClose={() => setShowFilterModal(false)}
            filterableFields={fields.map(f => f.value)}
            onApply={newFilters => {
              const isDirty = !haveSameContent(getFrom(values)('defaultFilters').defaultTo([]), newFilters);
              if (isDirty) {
                setFieldValue('defaultFilters', newFilters);
              }
              setShowFilterModal(false);
            }}
          />
        )}
        {showSortModal && (
          <DirectoryFormSortModal
            initialSort={getFrom(values)('defaultSort').defaultTo(DEFAULT_SORT)}
            schemaUtil={schemaUtil}
            onClose={() => setShowSortModal(false)}
            onApply={newSort => {
              const isDirty = !shallowEqual(getFrom(values)('defaultSort').value, newSort);
              if (isDirty) {
                setFieldValue('defaultSort', newSort);
              }
              setShowSortModal(false);
            }}
          />
        )}
        {showClearModal && (
          <DirectoryFormClearModal
            onApply={() => {
              // setFieldValue('defaultQuery', '') does not reset the text field
              setFieldValue('defaultQuery', []);
              setFieldValue('defaultFilters', []);
              setFieldValue('defaultSort', DEFAULT_SORT);
              setShowClearModal(false);
            }}
            onClose={() => setShowClearModal(false)}
          />
        )}
      </Form.FormikForm>
    );
  };

  return (
    <Form
      enableReinitialize
      initialValues={{ limit: DEFAULT_LIMIT, ...directoryValues }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={renderForm}
    />
  );
};
