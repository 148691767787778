import { Card, Modal } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';

import { Props, UserSelection } from '../../';
import { Button, Icon } from '../../../../components/';
import { OrgSettingsKeys } from '../../../../models/api';
import StyleUtil from '../../../../utils/StyleUtil';
import classes from './styles.module.scss';

const styles = StyleUtil({
  container: {
    textAlign: 'center',
  },
  header: {
    color: StyleUtil.colors.blue,
    fontSize: 30,
    marginBottom: 20,
    marginTop: 60 - 36,
  },
  skipLink: {
    display: 'inline-block',
    marginRight: 10,
  },
});

@Radium
export class NemoRun extends React.Component<Props, { promptSkip: boolean }> {
  handleNext = async () => {
    const { currentOrg, saveSettings, userSelection, createAudit } = this.props;

    if (userSelection == null) {
      return;
    }
    const step = OrgSettingsKeys.onboarding.step;
    const daily = OrgSettingsKeys.audit.schedule.daily;
    const realtime = OrgSettingsKeys.audit.schedule.realtime;
    const mode = OrgSettingsKeys.global.mode;

    const completeSettings = {
      [step]: 'complete',
      [daily]: true,
      [realtime]: true,
    };

    if (userSelection === UserSelection.all) {
      await saveSettings(currentOrg.id, {
        ...completeSettings,
        [mode]: 'Run',
      } as any);
      createAudit(currentOrg.type === 'Online' ? 'online' : 'external');
    } else if (userSelection === UserSelection.specific) {
      await saveSettings(currentOrg.id, {
        ...completeSettings,
        [mode]: 'Pilot',
      } as any);
      createAudit(currentOrg.type === 'Online' ? 'online' : 'external');
    }
  };

  handleSkip = () => {
    const { currentOrg, updateCurrent, createAudit } = this.props;

    updateCurrent({
      settings: {
        [currentOrg.id]: {
          [OrgSettingsKeys.onboarding.step]: 'complete',
        },
      },
    });
    createAudit(currentOrg.type === 'Online' ? 'online' : 'external');
  };

  render() {
    if (!this.props.settings) {
      return (
        <Card>
          <p>Loading your org settings...</p>
        </Card>
      );
    }

    return (
      <div style={styles.container}>
        <h1 style={styles.header}>You’re all set—Select Confirm to start</h1>
        <Card>
          <section className={classes.process}>
            <article className={classes.item}>
              <span>Monitor</span>
              <span>
                <img src={require('./assets/search-aad-icon.svg')} />
              </span>
              <span>LiveTiles Directory monitors Office 365 daily to identify users with missing photos.</span>
            </article>
            <article className={classes.item}>
              <span>
                <figure>
                  <img src={require('./assets/email-icon.svg')} />
                  <figcaption>Email</figcaption>
                </figure>
              </span>
              <img className={classes.arrow} src={require('./assets/line.svg')} />
              <span>
                <figure>
                  <img src={require('./assets/skype-icon.svg')} />
                  <figcaption>Chat bot</figcaption>
                </figure>
              </span>
            </article>
            <article className={classes.item}>
              <span>Request</span>
              <span>
                <img src={require('./assets/user-icon.svg')} />
              </span>
              <span>Users are requested to provide a photo via email or Skype every other day.</span>
            </article>
            <article className={classes.item}>
              <img className={classes.arrow} src={require('./assets/line.svg')} />
            </article>
            <article className={classes.item}>
              <span>Update</span>
              <span className={classes.dualFigure}>
                <figure>
                  <img src={require('./assets/desktop-icon.svg')} />
                  <figcaption>Web</figcaption>
                </figure>
                <figure>
                  <img src={require('./assets/phone-icon.svg')} />
                  <figcaption>Mobile</figcaption>
                </figure>
              </span>
              <span>Users update their photo from a desktop or mobile device.</span>
            </article>
          </section>
        </Card>
        <Modal.ButtonContainer>
          <a style={styles.skipLink} onClick={() => this.setState({ promptSkip: true })}>
            I want to configure my own settings
          </a>
          <Button size="medium" type="primary" onClick={this.handleNext}>
            CONFIRM
          </Button>
        </Modal.ButtonContainer>
        {this.state.promptSkip && this.renderSkipModal()}
      </div>
    );
  }

  renderSkipModal() {
    const closeModal = () => this.setState({ promptSkip: false });

    return (
      <Modal onClose={closeModal}>
        <div className={classes.skipModalCopy}>
          <h2>You have indicated that you would like to configure your own settings.</h2>
          <ol>
            <li>
              <Icon name="chevron-right" />
              We will take you to the General Settings page of LiveTiles Directory in Analyze Mode.
            </li>
            <li>
              <Icon name="chevron-right" />
              Users will not be contacted until after you configure settings and move into either Pilot or Run Mode.
            </li>
          </ol>
          <Modal.ButtonContainer>
            <Button size="medium" onClick={closeModal} style={StyleUtil.styles.modals.spacedBtn}>
              NO
            </Button>
            <Button size="medium" type="primary" onClick={this.handleSkip}>
              YES
            </Button>
          </Modal.ButtonContainer>
        </div>
      </Modal>
    );
  }
}
