import { OrgDetails } from '@hyperfish/antrea-api-contracts/src/org';

import { OrgSettingsKeys } from '../../models/api';
import { GlobalState, OrgsState as State } from '../../models/client/';
import { SAVE_SUCCESS as SAVE_SETTINGS_SUCCESS } from './settings';
import { ApiClient } from '@hyperfish/fishfood';

import {
  ORG_FEATURE_PAID,
  ORG_FEATURE_MODE_ANALYZE,
  ORG_FEATURE_MODE_PILOT,
  ORG_FEATURE_MODE_RUN,
  ORG_FEATURE_PROFILE_VALIDATION,
  ORG_FEATURE_DIRECTORY_WEB_PARTS,
  ORG_FEATURE_COLLECTIONS,
  ORG_FEATURE_READ_ONLY_MODE_SWITCH,
} from '../../config';

const LOAD = 'antrea/orgs/LOAD';
const LOAD_SUCCESS = 'antrea/orgs/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/orgs/LOAD_FAIL';
const LOAD_CURRENT = 'antrea/orgs/LOAD_CURRENT';
const LOAD_CURRENT_SUCCESS = 'antrea/orgs/LOAD_CURRENT_SUCCESS';
const LOAD_CURRENT_FAIL = 'antrea/orgs/LOAD_CURRENT_FAIL';
const LOAD_CONNECTIONS = 'antrea/orgs/LOAD_CONNECTIONS';
const LOAD_CONNECTIONS_SUCCESS = 'antrea/orgs/LOAD_CONNECTIONS_SUCCESS';
const LOAD_CONNECTIONS_FAIL = 'antrea/orgs/LOAD_CONNECTIONS_FAIL';
const LOAD_DIRECTORY_SCOPES = 'antrea/orgs/LOAD_DIRECTORY_SCOPES';
const LOAD_DIRECTORY_SCOPES_SUCCESS = 'antrea/orgs/LOAD_DIRECTORY_SCOPES_SUCCESS';
const LOAD_DIRECTORY_SCOPES_FAIL = 'antrea/orgs/LOAD_DIRECTORY_SCOPES_FAIL';
const CLEAR_DIRECTORY_SCOPES = 'antrea/orgs/CLEAR_DIRECTORY_SCOPES';
const UPDATE_CURRENT = 'antrea/orgs/UPDATE_CURRENT';
const UPDATE_CURRENT_SUCCESS = 'antrea/orgs/UPDATE_CURRENT_SUCCESS';
const UPDATE_CURRENT_FAIL = 'antrea/orgs/UPDATE_CURRENT_FAIL';
const CLEAR = 'antrea/orgs/CLEAR';

type OrgFeatures =
  | typeof ORG_FEATURE_PAID
  | typeof ORG_FEATURE_MODE_ANALYZE
  | typeof ORG_FEATURE_MODE_PILOT
  | typeof ORG_FEATURE_MODE_RUN
  | typeof ORG_FEATURE_PROFILE_VALIDATION
  | typeof ORG_FEATURE_DIRECTORY_WEB_PARTS
  | typeof ORG_FEATURE_COLLECTIONS
  | typeof ORG_FEATURE_READ_ONLY_MODE_SWITCH;

const initialState: State = {
  loaded: false,
} as State;

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case LOAD_CURRENT:
      return {
        ...state,
        loadingCurrent: true,
      };
    case LOAD_CURRENT_SUCCESS:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        current: action.result,
        currentConnected:
          action.result.connected &&
          (action.result.type === 'Online'
            ? action.result.settings[action.result.id].auth_providers_microsoftGraph_adminConsent === true
            : action.result.devices && action.result.devices.length > 0),
        errorCurrent: null,
      };
    case SAVE_SETTINGS_SUCCESS:
      // If a settings save affected the current org.
      return {
        ...state,
        current: (action.result.affectedEntities || {}).org || state.current,
      };
    case LOAD_CURRENT_FAIL:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        current: null,
        errorCurrent: action.error,
      };
    case UPDATE_CURRENT:
      return {
        ...state,
        updatingCurrent: true,
      };
    case UPDATE_CURRENT_SUCCESS:
      return {
        ...state,
        updatingCurrent: false,
        updateCurrentError: null,
        current: action.result,
      };
    case UPDATE_CURRENT_FAIL:
      return {
        ...state,
        updatingCurrent: false,
        updateCurrentError: action.error,
      };
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.orgs,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case LOAD_CONNECTIONS:
      return {
        ...state,
        loadingConnections: true,
      };
    case LOAD_CONNECTIONS_SUCCESS:
      return {
        ...state,
        loadingConnections: false,
        loadingConnectionsError: null,
        connectionsLoadedAt: new Date(),
        connections: action.result.connections,
      };
    case LOAD_CONNECTIONS_FAIL:
      return {
        ...state,
        loadingConnections: false,
        loadingConnectionsError: action.error,
      };
    case LOAD_DIRECTORY_SCOPES:
      return {
        ...state,
        loadingScopes: true,
        loadingScopesError: null,
      };
    case LOAD_DIRECTORY_SCOPES_SUCCESS:
      return {
        ...state,
        loadingScopes: false,
        scopes: action.result.results,
      };
    case LOAD_DIRECTORY_SCOPES_FAIL:
      return {
        ...state,
        loadingScopes: false,
        loadingScopesError: action.error,
      };
    case CLEAR_DIRECTORY_SCOPES:
      return {
        ...state,
        scopes: null,
      };
    case CLEAR:
      return {
        ...state,
        data: null,
        loaded: false,
      };
    default:
      return state;
  }
}

export function hasOrgFeature({ orgs }: GlobalState, feature: OrgFeatures) {
  return orgs && orgs.current && orgs.current.licenseInfo.features.includes(feature);
}

export function isLoaded({ orgs }: GlobalState) {
  return orgs && orgs.loaded;
}

export function isLoadedCurrent({ orgs }: GlobalState) {
  return orgs && orgs.loadedCurrent;
}

export function isOnboarded({ orgs }: GlobalState) {
  return orgs && orgs.current && orgs.current.settings[orgs.current.id][OrgSettingsKeys.onboarding.step] === 'complete';
}

export function shouldLoad({ orgs }: GlobalState) {
  return orgs && !orgs.loading;
}

export function shouldLoadCurrent({ orgs }: GlobalState) {
  return orgs && !orgs.current && !orgs.loadingCurrent;
}

export function shouldLoadConnections({ orgs }: GlobalState) {
  return orgs && !orgs.loadingConnections;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/orgs'),
  };
}

export function loadCurrent() {
  return {
    types: [LOAD_CURRENT, LOAD_CURRENT_SUCCESS, LOAD_CURRENT_FAIL],
    promise: (client: ApiClient) => client.get('/orgs/current'),
    notifications: {
      fail: 'Failed to load current organization details.',
    },
  };
}

export function loadConnections() {
  return {
    types: [LOAD_CONNECTIONS, LOAD_CONNECTIONS_SUCCESS, LOAD_CONNECTIONS_FAIL],
    promise: (client: ApiClient) => client.get('/orgs/current/connections'),
  };
}

export function loadScopes(params: { search?: string; limit?: number } = {}) {
  const paramDefaults = { limit: 4 };
  params = { ...paramDefaults, ...params };

  return {
    types: [LOAD_DIRECTORY_SCOPES, LOAD_DIRECTORY_SCOPES_SUCCESS, LOAD_DIRECTORY_SCOPES_FAIL],
    promise: (client: ApiClient) => client.get('/orgs/current/directory-scopes', { params }),
  };
}

export function clearScopes() {
  return {
    type: CLEAR_DIRECTORY_SCOPES,
  };
}

export function updateCurrent(data: RecursivePartial<OrgDetails>) {
  return {
    types: [UPDATE_CURRENT, UPDATE_CURRENT_SUCCESS, UPDATE_CURRENT_FAIL],
    promise: (client: ApiClient) => client.patch('/orgs/current', { data }),
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}

type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };
