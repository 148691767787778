import moment from 'moment';

// Keep local time correct for schedules, even when Daylight Saving Time has changed since scheduling.
// Uses DST of now, and applies it to a date of the past.
// Unfortunatly this forces us to unwillingly change the utc value of the date, but it's used in a way such that this doesn't matter.
function daylightSavingTimeforSchedules(value: moment.Moment, reverse: boolean) {
  // First we need to get the standard offset for the current time zone.
  // Since summer and winter are at opposite times in northern and summer hemispheres,
  // we will take a Jan 1st and Jul 1st offset. The standard offset is the smaller value
  // If DST is applicable, these will differ and the daylight offset is the larger value.
  // Few locations shift their time by a different amount of minutes.
  var janOffset: number = moment({ M: 0, d: 1 }).utcOffset();
  var julOffset: number = moment({ M: 6, d: 1 }).utcOffset();
  var stdOffset: number = Math.min(janOffset, julOffset);
  var withDSTOffset: number = Math.max(janOffset, julOffset);
  var diffMinutes: number = withDSTOffset - stdOffset;

  if (reverse) {
    diffMinutes = -diffMinutes;
  }
  if (value.isDST() && !moment().isDST()) {
    value = value.add(-diffMinutes, 'minutes');
  }
  if (!value.isDST() && moment().isDST()) {
    value = value.add(diffMinutes, 'minutes');
  }
  return value;
}

export function applyDSTforSchedules(value: moment.Moment) {
  return daylightSavingTimeforSchedules(value, false);
}

export function applyDSTforSchedulesReverse(value: moment.Moment) {
  return daylightSavingTimeforSchedules(value, true);
}
