import { Card, LoadingSplash } from '@hyperfish/fishfood';
import cx from 'classnames';
import React from 'react';

import { Icon } from '../../../../components/Icon';
import { ChangesState } from '../../../../models/client';
import { bulkUpdate, CHANGE_STATUS } from '../../../../redux/modules/changes';
import classes from './style.module.scss';

type Change = ChangesState['changesById']['foo'];

interface Props {
  bulkUpdate: typeof bulkUpdate;
  changes: ChangesState['changes'];
  changesById: ChangesState['changesById'];
  saving: ChangesState['saving'];
  getUserName: (change: Change) => string;
  getAttributeName: (change: Change) => string;
  getDisplayValues: (change: Change) => { newValue: string; oldValue: string };
  search: string;
}

interface State {
  activeIndex: number;
  savingStatusById: { [changeId: string]: string };
  activeChanges: ChangesState['changes'];
  skippedChanges: ChangesState['changes'];
}

export class ApproveWizard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeIndex: 0,
      savingStatusById: {},
      activeChanges: props.changes
        ? props.changes.filter(
            id => [CHANGE_STATUS.PENDING, CHANGE_STATUS.FAILED].indexOf(props.changesById[id].status) !== -1,
          )
        : [],
      skippedChanges: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.changes !== nextProps.changes) {
      this.setState({
        activeChanges: nextProps.changes
          ? nextProps.changes.filter(
              id => [CHANGE_STATUS.PENDING, CHANGE_STATUS.FAILED].indexOf(nextProps.changesById[id].status) !== -1,
            )
          : [],
      });
    }
  }

  handleSkip = () => {
    this.setState(
      state => ({
        skippedChanges: [...state.skippedChanges, state.activeChanges[state.activeIndex]],
      }),
      this.handleNext,
    );
  };

  handleNext = () => {
    this.setState(({ activeIndex }) => ({ activeIndex: activeIndex + 1 }));
  };

  handleReject = () => {
    const { bulkUpdate, changesById } = this.props;
    const { activeIndex, activeChanges } = this.state;
    const id = activeChanges[activeIndex];
    const change = changesById[id];
    const status = change.status === CHANGE_STATUS.FAILED ? CHANGE_STATUS.CANCELED : CHANGE_STATUS.REJECTED;

    this.setState(
      ({ savingStatusById }) => ({
        savingStatusById: {
          ...savingStatusById,
          [id]: status,
        },
      }),
      () => {
        ((bulkUpdate([{ id, status }]) as any) as Promise<any>).then(() => {
          window.setTimeout(this.handleNext, 750);
        });
      },
    );
  };

  handleApprove = () => {
    const { bulkUpdate, changesById } = this.props;
    const { activeIndex, activeChanges } = this.state;
    const id = activeChanges[activeIndex];
    const change = changesById[id];
    const status = change.status === CHANGE_STATUS.FAILED ? CHANGE_STATUS.PROCESSING : CHANGE_STATUS.APPROVED;

    this.setState(
      ({ savingStatusById }) => ({
        savingStatusById: {
          ...savingStatusById,
          [id]: status,
        },
      }),
      () => {
        ((bulkUpdate([{ id, status }]) as any) as Promise<any>).then(() => {
          window.setTimeout(this.handleNext, 750);
        });
      },
    );
  };

  render() {
    const { changes } = this.props;
    const { activeIndex, activeChanges } = this.state;

    if (!changes) {
      return <LoadingSplash />;
    }

    const isDone = activeChanges.length === 0 || activeIndex > activeChanges.length - 1;

    return (
      <div className={classes.approveWizard}>
        <h1 className={classes.title}>
          <Icon name="checkbox" />
          <span>Approve</span>
        </h1>
        <Card
          style={{
            height: '100%',
            paddingBottom: 140,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 15,
            position: 'relative',
            marginBottom: 0,
          }}
        >
          {isDone && this.renderDone()}
          {!isDone && this.renderChange()}
          {!isDone && this.renderFooter()}
        </Card>
      </div>
    );
  }

  renderDone() {
    const { changes } = this.props;
    const { skippedChanges } = this.state;

    if (!!skippedChanges && skippedChanges.length > 0) {
      return (
        <div>
          <div className={classes.doneContainer}>
            <h2>You skipped {skippedChanges.length.toLocaleString()} changes.</h2>
            <p>Would you like to go back and review these changes?</p>
          </div>
          <div className={classes.footerWrapper}>
            <div>
              <button className={classes.buttonReject} onClick={() => this.setState({ skippedChanges: [] })}>
                NO
              </button>
              <button
                className={classes.buttonApprove}
                onClick={() =>
                  this.setState(state => ({ activeChanges: state.skippedChanges, activeIndex: 0, skippedChanges: [] }))
                }
              >
                YES
              </button>
            </div>
          </div>
        </div>
      );
    }

    const noChanges = !changes || changes.length === 0;
    return (
      <div>
        <div className={classes.doneContainer}>
          {!noChanges && (
            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
              <g fill="#f2cb61" fillRule="evenodd">
                <path d="M49.06 48.133a1.338 1.338 0 0 0-1.566.94l-1.859 6.494-8.144-12.738a22.158 22.158 0 0 0 9.293-6.39l9.042 13.051-6.766-1.357zM30.078 41.43C19.36 41.43 10.7 32.77 10.7 22.05c0-10.718 8.66-19.378 19.38-19.378 10.718 0 19.378 8.66 19.378 19.379 0 10.718-8.66 19.379-19.379 19.379zM14.52 55.568l-1.858-6.495a1.337 1.337 0 0 0-1.566-.94L4.33 49.492l9.042-13.072a22.096 22.096 0 0 0 9.293 6.41L14.52 55.568zM59.92 50.68L48.496 34.184a21.911 21.911 0 0 0 3.634-12.132C52.13 9.889 42.24 0 30.078 0S8.027 9.89 8.027 22.052c0 4.474 1.356 8.631 3.653 12.112L.237 50.68a1.337 1.337 0 0 0 1.379 2.067l8.79-1.754 2.34 8.165a1.336 1.336 0 0 0 2.422.355l10.19-15.912a21.97 21.97 0 0 0 4.72.501c1.617 0 3.2-.17 4.72-.5l10.19 15.911a1.337 1.337 0 0 0 2.422-.355l2.339-8.165 8.792 1.754a1.337 1.337 0 0 0 1.378-2.067z" />
                <path d="M21.86 29.785v-10.36c0-.932-.759-1.687-1.696-1.687-.937 0-1.696.755-1.696 1.687v10.36c0 .932.76 1.687 1.696 1.687.937 0 1.697-.755 1.697-1.687zm4.106-.706c1.981.832 8.042 1.698 9.193 1.24.608-.55 2.137-4.01 3.203-6.95.076-.207.216-.386.4-.509.863-.576 1.31-1.236 1.226-1.81-.067-.463-.485-.857-1.118-1.055-1.31-.41-4.525-1.12-4.558-1.127a1.035 1.035 0 1 1-.54-1.72c2.345-2.512 2.76-5.1 2.307-6.135-.14-.318-.284-.318-.362-.318-.514 0-.755.358-1.286 1.915-.623 1.822-2.771 3.995-5.347 5.41-1.25.685-2.5 1.58-3.118 2.04v9.02zm7.882 3.417c-3.221 0-8.28-1.066-9.531-1.954a1.037 1.037 0 0 1-.438-.846v-10.15c0-.317.146-.617.396-.814.077-.06 1.899-1.488 3.8-2.531 2.363-1.297 3.991-3.124 4.379-4.259.479-1.403 1.135-3.324 3.263-3.324 1.01 0 1.84.57 2.276 1.565.883 2.015-.047 4.815-1.6 7.029 1.045.245 2.342.563 3.103.801 1.418.444 2.374 1.468 2.559 2.74.19 1.307-.478 2.614-1.84 3.628-1 2.723-2.75 7.038-4.019 7.744-.469.261-1.314.371-2.348.371z" />
              </g>
            </svg>
          )}
          <h2>{noChanges ? 'There are no pending changes.' : 'Great Job!'}</h2>
          <p>{noChanges ? 'What would you like to do now?' : 'You are all caught up.'}</p>
        </div>
        <div className={classes.footerWrapper}>
          <div>
            <button className={classes.buttonReject} onClick={() => (location.href = '/auth/logout')}>
              SIGN OUT
            </button>
            <button className={classes.buttonApprove} onClick={() => (location.href = '/self')}>
              GO TO PROFILE
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderChange() {
    const { changesById, getUserName, getAttributeName, getDisplayValues } = this.props;
    const { activeIndex, activeChanges } = this.state;
    const change = changesById[activeChanges[activeIndex]];
    if (!change) {
      return;
    }
    const displayName = getUserName(change);
    const { newValue, oldValue } = getDisplayValues(change);
    const attribute = getAttributeName(change);

    return (
      <div className={classes.changeWrapper}>
        <span className={classes.userName}>{displayName}</span>
        <div className={classes.changeContainer}>
          <span className={classes.attributeName}>{attribute}</span>
          {change.type === 'image' ? (
            <div className={classes.imageChangeContainer}>
              <figure>
                <figcaption>From:</figcaption>
                {oldValue}
              </figure>
              <figure>
                <figcaption>To:</figcaption>
                {newValue}
              </figure>
            </div>
          ) : (
            <table>
              <tbody>
                <tr>
                  <td className={classes.changeValueLabel}>From:</td>
                  <td className={classes.changeValueValue}>{oldValue}</td>
                </tr>
                <tr>
                  <td className={classes.changeValueLabel}>To:</td>
                  <td className={classes.changeValueValue}>{newValue}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }

  renderFooter() {
    const { activeIndex, activeChanges } = this.state;

    return (
      <div className={classes.footerWrapper}>
        {this.renderFooterButtons()}
        <div className={classes.footerContainer}>
          <div>
            <span className={classes.progressContainer}>
              <span
                className={classes.progressBar}
                style={{
                  width: `${((activeIndex + 1) / activeChanges.length) * 100}%`,
                }}
              />
            </span>
            {activeIndex + 1} of {!!activeChanges && activeChanges.length}
          </div>
          <a className={classes.skipLink} onClick={this.handleSkip}>
            Skip
          </a>
        </div>
      </div>
    );
  }

  renderFooterButtons() {
    const { changesById, saving } = this.props;
    const { activeIndex, savingStatusById, activeChanges } = this.state;
    const change = changesById[activeChanges[activeIndex]];
    if (!change) {
      return;
    }

    const rejectedStatuses = [CHANGE_STATUS.REJECTED, CHANGE_STATUS.CANCELED];
    const approvedStatuses = [CHANGE_STATUS.APPROVED, CHANGE_STATUS.PROCESSING];

    const isSaving = saving[change.id];
    const savingStatus = isSaving && savingStatusById[change.id];
    const isSavingRejected = rejectedStatuses.indexOf(savingStatus) > -1;
    const isSavingApproved = approvedStatuses.indexOf(savingStatus) > -1;

    const isRejected = change && rejectedStatuses.indexOf(change.status) > -1;
    const isApproved = change && approvedStatuses.indexOf(change.status) > -1;

    return (
      <div>
        <button
          className={cx(classes.buttonReject, { [classes.active]: isRejected || isSavingRejected })}
          onClick={!isSaving && this.handleReject}
          disabled={isSaving || isApproved}
        >
          {isSavingRejected ? (
            <span className="animate-spin">
              <Icon name="loading" />
            </span>
          ) : isRejected ? (
            <Icon name="checkmark" />
          ) : (
            'REJECT'
          )}
        </button>
        <button
          className={cx(classes.buttonApprove, { [classes.active]: isApproved || isSavingApproved })}
          onClick={!isSaving && this.handleApprove}
          disabled={isSaving || isRejected}
        >
          {isSavingApproved ? (
            <span className="animate-spin">
              <Icon name="loading" />
            </span>
          ) : isApproved ? (
            <Icon name="checkmark" />
          ) : (
            'APPROVE'
          )}
        </button>
      </div>
    );
  }
}
