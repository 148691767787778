import { Button, dateFormatter as formatDate, FiTrash, ButtonContainer } from '@hyperfish/fishfood';
import { CloudConnection, DeviceDetails } from '@hyperfish/antrea-api-contracts';
import cx from 'classnames';
import React from 'react';

import { Props } from '../..';
import classes from './styles.module.scss';

interface DirectoryCardProps extends Props {
  connection: DeviceDetails | CloudConnection;
  type: 'Online' | 'Hybrid';
  refreshUrl: string;
  serviceUrl: string;
}

export const DirectoryCard = (props: DirectoryCardProps & Props) => {
  function isOnline(arg: any, type: string): arg is CloudConnection {
    return type === 'Online';
  }

  const c = props.connection;

  return (
    <div>
      {isOnline(c, props.type) ? (
        <div>
          <p>LiveTiles Directory is connected to your directory.</p>
          {props.refreshUrl && (
            <Button size="small" className={classes.button} onClick={() => (location.href = props.refreshUrl)}>
              Reauthorize
            </Button>
          )}
        </div>
      ) : (
        <div>
          <table className={classes.table}>
            <tbody>
              <tr className={classes.tr}>
                <td className={cx(classes.td, classes.label)}>Domain controller:</td>
                <td className={classes.td}>{c.domainController || 'Unknown'}</td>
              </tr>
              <tr className={classes.tr}>
                <td className={cx(classes.td, classes.label)}>Status:</td>
                <td className={classes.td}>
                  {c.lastHeartbeat &&
                  c.lastHeartbeat.timestamp &&
                  (props.connectionsLoadedAt.valueOf() - new Date(c.lastHeartbeat.timestamp).valueOf()) / 60000 < 15 ? (
                    <span className={cx(classes.status, classes.statusConnected)}>CONNECTED</span>
                  ) : (
                    <span className={cx(classes.status, classes.statusDisconnected)}>DISCONNECTED</span>
                  )}
                  {props.codeDeviceId !== c.id && (
                    <a
                      className={cx(classes.actionLink)}
                      style={{ verticalAlign: 'middle' }}
                      onClick={() => props.getCode(c.id)}
                    >
                      Get a new code
                    </a>
                  )}
                </td>
              </tr>
              {props.codeDeviceId === c.id && (
                <tr className={classes.tr}>
                  <td className={classes.reconnect} colSpan={2}>
                    <div className={classes.reconnectHeader}>
                      <div>
                        <span>Code:</span>
                        <span className={classes.code}>{props.code || 'LOADING...'}</span>
                      </div>
                      <ButtonContainer>
                        <Button size="small" variant="link" color="accent" onClick={() => props.clearCode()}>
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          variant="solid"
                          color="primary"
                          onClick={() => (location.href = props.serviceUrl)}
                        >
                          DOWNLOAD
                        </Button>
                      </ButtonContainer>
                    </div>
                    <p className={classes.reconnectMessage}>
                      Install the service with the above code. Once the service has been registered this box will
                      disappear.
                    </p>
                  </td>
                </tr>
              )}
              <tr className={classes.tr}>
                <td className={cx(classes.td, classes.label)}>Last heard from:</td>
                <td className={classes.td}>
                  {c.lastHeartbeat && c.lastHeartbeat.timestamp ? (
                    formatDate(c.lastHeartbeat.timestamp)
                  ) : (
                    <span>Unknown</span>
                  )}
                </td>
              </tr>
              <tr className={classes.tr}>
                <td className={cx(classes.td, classes.label)}>Version:</td>
                <td className={classes.td}>
                  {(c.lastHeartbeat && c.lastHeartbeat.version) || 'Unknown'}
                  {/* <a className={classes.actionLink} href={props.serviceUrl}>
                    Get Latest
                  </a> */}
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            size="small"
            className={classes.button}
            onClick={() => props.removeDevice(c.id)}
            icon={<FiTrash />}
            iconColor="error"
          >
            {(() => {
              if (props.devicesDeleting[c.id]) {
                return 'Removing...';
              }
              if (props.devicesDeletingError[c.id]) {
                return 'Failed to remove. Retry';
              }
              return 'Remove';
            })()}
          </Button>
        </div>
      )}
    </div>
  );
};
