interface Scope {
  name: string;
  distinguishedName: string;
}

type ValidationAction = 'include' | 'exclude';

interface ValidationNodeDictionary {
  [branch: string]: ValidationNode;
}

interface ValidationNode {
  children: ValidationNodeDictionary;
  action?: ValidationAction;
}

export default class OUValidator {
  private validationTree: ValidationNode;
  private noIncludes: boolean;
  private noExcludes: boolean;

  constructor(includeDNs?: string[], excludeDNs?: string[]) {
    this.noIncludes = !includeDNs || includeDNs.length === 0;
    this.noExcludes = !excludeDNs || excludeDNs.length === 0;
    this.validationTree = this.generateValidationTree(
      includeDNs == null ? [] : includeDNs,
      excludeDNs == null ? [] : excludeDNs,
    );
  }

  public isArrayIncluded(dnStrings: string[]) {
    for (const dnString of dnStrings) {
      if (!this.isIncluded(dnString)) {
        return false;
      }
    }
    return true;
  }

  public isIncluded(dnString: string) {
    const branches = dnString.split(',');

    let activeNode = this.validationTree;
    let included;

    if (this.noIncludes && this.noExcludes) {
      return true;
    }

    while (branches.length > 0) {
      const branch = branches.pop();
      if (activeNode.children[branch] == null) {
        break;
      }
      if (activeNode.children[branch].action === 'include') {
        included = true;
        if (this.noExcludes) {
          break;
        }
      } else if (activeNode.children[branch].action === 'exclude') {
        included = false;
        if (this.noIncludes) {
          break;
        }
      }
      activeNode = activeNode.children[branch];
    }

    if (!this.noIncludes && included == null) {
      // There are includes specified, and there were no settings for this tree
      included = false;
    } else if (this.noIncludes && included == null) {
      // There are no includes specified, and there were no settings for this tree
      included = true;
    }

    return included;
  }

  private generateValidationTree(includeDNs?: string[], excludeDNs?: string[]) {
    const tree = {} as ValidationNode;

    for (const dn of includeDNs) {
      this.setNode(tree, dn, 'include');
    }
    for (const dn of excludeDNs) {
      this.setNode(tree, dn, 'exclude');
    }

    return tree;
  }

  private setNode(tree: ValidationNode, dn: string, action: ValidationAction) {
    const branches = dn.split(',');

    if (tree.children == null) {
      tree.children = {};
    }

    let activeNode = tree;

    while (branches.length > 0) {
      const branch = branches.pop();
      if (activeNode.children[branch] == null) {
        activeNode.children[branch] = { children: {} };
      }
      activeNode = activeNode.children[branch];
    }

    // Set action on node at tip of this set
    activeNode.action = action;
  }
}
