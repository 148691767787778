import React from 'react';

import StyleUtil from '../utils/StyleUtil';
import { Icon } from './Icon';

interface Props extends React.HTMLProps<HTMLElement> {
  onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;
  // pushRoute?: typeof routerActions.push;
  style?: React.CSSProperties;
  toolTipText?: string;
  tooltipDir?: 'top' | 'right' | 'bottom' | 'left';
}

export const PremiumLock = (props: Props) => {
  const defaultProps: Partial<Props> = {
    tooltipDir: 'right',
    toolTipText: 'This is a premium feature. Click to learn more and upgrade.',
    onClick: e => {
      e.stopPropagation();
      e.preventDefault();
      // if (!!props.pushRoute) {
      //   props.pushRoute('/upgrade');
      // } else {
      location.href = '/upgrade';
      // }
    },
  };

  const mergedProps = {
    ...defaultProps,
    ...props,
  };

  const { style, onClick, toolTipText, tooltipDir, ...rest } = mergedProps;

  return (
    <Icon
      name="lock"
      style={{
        color: StyleUtil.colors.orange,
        cursor: 'pointer',
        ...style,
      }}
      onClick={onClick}
      {...{ [`data-tip-${tooltipDir}`]: toolTipText }}
      {...rest}
    />
  );
};
