import cx from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

type Props = JSX.IntrinsicElements['table'];

export const Table: React.StatelessComponent<Props> = ({ children, ...rest }) => {
  let autoAlt = true;
  React.Children.forEach(children, child => {
    if (!React.isValidElement(child)) {
      return;
    }
    if (child.props['isAlt'] === true) {
      autoAlt = false;
    }
  });

  return (
    <table
      {...rest}
      className={cx(classes.Table, rest.className, {
        [classes.autoAlt]: autoAlt,
      })}
    >
      <tbody>{children}</tbody>
    </table>
  );
};
