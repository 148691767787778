import { GlobalState } from '../../../models/client/';
import { PermissionsAction } from './actions';
import * as types from './types';
import { ApiClient } from '@hyperfish/fishfood';

type PermissionsPayload = GlobalState['permissions']['dataByAudience']['foo'];

type State = GlobalState['permissions'];

const initialState = {
  dataByAudience: {},
  dirtyByAudience: {},
  loadingByAudience: {},
  errorByAudience: {},
  savingByAudience: {},
  savingByAudienceError: {},
} as State;

export default function reducer(state: State = initialState, action: PermissionsAction): State {
  switch (action.type) {
    case types.LOAD:
      return {
        ...state,
        errorByAudience: { ...state.errorByAudience, [action.audienceId]: null },
        loadingByAudience: { ...state.loadingByAudience, [action.audienceId]: true },
      };
    case types.LOAD_SUCCESS:
      return {
        ...state,
        dataByAudience: { ...state.dataByAudience, [action.audienceId]: action.result },
        loadingByAudience: { ...state.loadingByAudience, [action.audienceId]: false },
      };
    case types.LOAD_FAIL:
      return {
        ...state,
        errorByAudience: { ...state.errorByAudience, [action.audienceId]: action.error },
        loadingByAudience: { ...state.loadingByAudience, [action.audienceId]: false },
      };
    case types.EDIT:
      return {
        ...state,
        dirtyByAudience: { ...state.dirtyByAudience, [action.data.audienceId]: action.data },
      };
    case types.SAVE:
      return {
        ...state,
        savingByAudience: { ...state.savingByAudience, [action.audienceId]: true },
        savingByAudienceError: { ...state.savingByAudienceError, [action.audienceId]: null },
      };
    case types.SAVE_SUCCESS:
      return {
        ...state,
        savingByAudience: { ...state.savingByAudience, [action.audienceId]: false },
        dataByAudience: { ...state.dataByAudience, [action.audienceId]: action.result },
        dirtyByAudience: { ...state.dirtyByAudience, [action.audienceId]: null },
      };
    case types.SAVE_FAIL:
      return {
        ...state,
        savingByAudience: { ...state.savingByAudience, [action.audienceId]: false },
        savingByAudienceError: { ...state.savingByAudienceError, [action.audienceId]: action.error },
      };
    case types.CANCEL:
      return {
        ...state,
        dirtyByAudience: !!action.audienceId
          ? {
              ...state.dirtyByAudience,
              [action.audienceId]: null,
            }
          : {
              /* EMPTY OBJECT */
            },
      };
    default:
      return state;
  }
}

// Action Creators
export function load(audienceId: string) {
  return {
    types: [types.LOAD, types.LOAD_SUCCESS, types.LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/permissions', { params: { audienceId } }),
    audienceId,
    notifications: {
      fail: 'Failed to load permissions',
    },
  };
}

export function save(data: PermissionsPayload) {
  const audienceId = data.audienceId;
  return {
    types: [types.SAVE, types.SAVE_SUCCESS, types.SAVE_FAIL],
    promise: (client: ApiClient) => client.post('/permissions', { params: { audienceId }, data }),
    audienceId,
    notifications: {
      success: 'Permissions updated successfully',
      fail: 'Failed to update permissions',
    },
  };
}

export const edit = (data: PermissionsPayload) => ({ type: types.EDIT, data });
export const cancel = (audienceId?: string) => ({ type: types.CANCEL, audienceId });

// UTILITY FUNCTIONS
export function getDirty(state: GlobalState): PermissionsPayload[];
export function getDirty(state: GlobalState, audienceId: string): PermissionsPayload;
export function getDirty({ permissions: { dirtyByAudience } }: GlobalState, audienceId?: string) {
  if (audienceId != null) {
    return dirtyByAudience[audienceId];
  }
  const dirtyArray = Object.keys(dirtyByAudience)
    .map(key => dirtyByAudience[key])
    .filter(x => !!x);
  return dirtyArray.length > 0 ? dirtyArray : null;
}
