import { ApiField, ApiFieldAudienceConfig, ApiGroup } from '@hyperfish/antrea-api-contracts';
import cx from 'classnames';
import React from 'react';
import { DragDropContext, Draggable, DraggableLocation, Droppable, DropResult } from 'react-beautiful-dnd';

import { Props } from '../..';
import { Icon, ModalKeys, PremiumLock } from '../../../../../components';
import StyleUtil from '../../../../../utils/StyleUtil';
import classes from './styles.module.scss';
import SchemaUtil from '@hyperfish/fishfood/lib/utils/SchemaUtil';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import { FiEdit2, FiTrash, IconButton, FiCheck, Tooltip, FiLock, FiHelpCircle } from '@hyperfish/fishfood';

type TableProps = Props & {};

export class AttributeTable extends React.Component<TableProps> {
  private photoKeys = ['profilePicture', 'thumbnailphoto'];
  private lockedProperties = this.photoKeys.concat(['profilePicture']); // Temporarily adding online org photos.

  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      currentAudienceId,
      currentOrg,
      deleteGroupPrompt,
      editGroupStart,
      fieldSchema,
      groupsUpdating,
      isFree,
      isMaster,
    } = this.props;

    const orphanFields = this.getOrphanFields();

    const headerGroup = this.getGroups().find(g => g.id === this.headerGroupId);
    const headerGroupIndex =
      headerGroup &&
      this.getGroups()
        .map(g => g.id)
        .indexOf(headerGroup.id);

    const photoFields = Object.keys(fieldSchema.fields)
      .map(key => fieldSchema.fields[key])
      .filter(field => !!field)
      .filter(({ property }) => this.photoKeys.indexOf(property) > -1);

    const groupOverride = !!fieldSchema.groups[currentAudienceId];

    const canAddGroup = groupOverride;
    const canAddAttribute = !isFree && isMaster && currentOrg;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className={classes.table}>
          <div className={classes.row}>
            <div className={cx(classes.tableHeader, classes['name'])}>
              <p>Display Name</p>
            </div>
            <div className={cx(classes.tableHeader, classes['isCheck'], classes.required)}>
              <p>Must Contain a Value</p>
            </div>
            <div className={cx(classes.tableHeader, classes['isCenter'], classes.options)}>
              <p>Form and Hyperbot Options</p>
            </div>
            {!isMaster && (
              <div className={cx(classes.tableHeader, classes['isCheck'], classes.override)}>
                <p>
                  Override
                  <br />
                  Master Settings
                </p>
              </div>
            )}
            <div className={cx(classes.tableHeader, classes['isCenter'], classes.actions)}>
              <p>Actions</p>
            </div>
          </div>
          {photoFields.length > 0 &&
            [
              <div className={cx(classes.row, classes['isGroupHead'])} key="PROFILE_PICTURE">
                <div className={cx(classes.tableCell)}>
                  Profile Picture
                  <Tooltip
                    content="Photo renders at the top of the page and cannot be added to a group."
                    placement="right"
                  >
                    <span className={classes.sectionIcon}>
                      <FiLock />
                    </span>
                  </Tooltip>
                </div>
              </div>,
            ].concat(photoFields.map((f, i) => this.renderAttribute('PROFILE_PICTURE', f, !!(i % 2), i)))}
          {this.headerGroupId && (
            <>
              <div className={cx(classes.row, classes['isGroupHead'])} key="headerFields">
                <div className={cx(classes.tableCell)}>
                  <span style={{ verticalAlign: 'middle' }}>
                    Header (promote important fields to header)
                    <Tooltip
                      content="These attributes, if not hidden, will show at the top of the profile page within the header section."
                      placement="right"
                    >
                      <span className={classes.sectionIcon}>
                        <FiHelpCircle />
                      </span>
                    </Tooltip>
                  </span>
                </div>
                <div className={cx(classes.tableCell)}></div>
                <div className={cx(classes.tableCell, classes['isCenter'], classes.actions)}>
                  <div style={StyleUtil.styles.tables.actions_container}>
                    {groupOverride && (
                      <IconButton
                        css={null}
                        ariaLabel="Edit Group"
                        color="accent"
                        onClick={null}
                        icon={<FiEdit2 />}
                        style={{ marginBottom: 0 }}
                        disabled={true}
                      />
                    )}
                    {isMaster && (
                      <IconButton
                        css={null}
                        onClick={
                          groupOverride && headerGroupIndex >= 0 ? () => deleteGroupPrompt(headerGroupIndex) : null
                        }
                        title={groupOverride ? 'Delete' : null}
                        ariaLabel="Delete Attribute"
                        // data-tip-left={canDelete ? null : 'Attribute cannot be deleted. Select Edit to hide the attribute.'}
                        disabled={isFree}
                        icon={<FiTrash />}
                        color="error"
                        style={{ marginBottom: 0 }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Droppable droppableId={this.headerGroupId} type="FIELD">
                {provided => (
                  <div ref={provided.innerRef} className={classes.droppableContainer} {...provided.droppableProps}>
                    {getFrom(headerGroup)('fields')
                      .defaultTo([])
                      .map(key => getFrom(fieldSchema)('fields')(key).value)
                      .filter(field => !!field)
                      .map((f, i) => this.renderAttribute(headerGroup.id, f, !!(i % 2), i))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </>
          )}
          <Droppable droppableId="groups" type="GROUP">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.getGroups()
                  .filter(g => g.id !== this.headerGroupId)
                  .map((g, i) => (
                    <Draggable
                      key={g.id || String(i)}
                      draggableId={g.id || String(i)}
                      index={i}
                      type="GROUP"
                      isDragDisabled={groupsUpdating || !groupOverride}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={cx({
                            [classes.isDragging]: snapshot.isDragging,
                          })}
                        >
                          <div
                            className={cx(classes.row, classes['isGroupHead'], classes['name'])}
                            {...provided.dragHandleProps}
                          >
                            <div className={cx(classes.tableCell)}>
                              <span style={{ verticalAlign: 'middle' }}>{g.title}</span>
                            </div>
                            <div className={cx(classes.tableCell, classes['isCenter'], classes.actions)}>
                              <div style={StyleUtil.styles.tables.actions_container}>
                                {groupOverride && (
                                  <IconButton
                                    css={null}
                                    ariaLabel="Edit Attribute"
                                    color="accent"
                                    onClick={groupOverride ? () => editGroupStart(g) : null}
                                    icon={<FiEdit2 />}
                                    style={{ marginBottom: 0 }}
                                  />
                                )}
                                {isMaster && (
                                  <IconButton
                                    css={null}
                                    onClick={groupOverride ? () => deleteGroupPrompt(i) : null}
                                    title={groupOverride ? 'Delete' : null}
                                    ariaLabel="Delete Attribute"
                                    // data-tip-left={canDelete ? null : 'Attribute cannot be deleted. Select Edit to hide the attribute.'}
                                    disabled={isFree}
                                    icon={<FiTrash />}
                                    color="error"
                                    style={{ marginBottom: 0 }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <Droppable droppableId={g.id || String(i)} type="FIELD">
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                className={classes.droppableContainer}
                                {...provided.droppableProps}
                              >
                                {g.fields
                                  .map(key => fieldSchema.fields[key])
                                  .filter(field => !!field)
                                  .map((f, i) => this.renderAttribute(g.id, f, !!(i % 2), i))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {canAddGroup && this.renderAddGroup()}
          <div className={cx(classes.row, classes['isGroupHead'])} key="orphanFields">
            <div className={cx(classes.tableCell)}>
              Other (Not in a group, cannot be re-ordered)
              <Tooltip
                content="These attributes, if not hidden, will show at the bottom of the profile page in a group titled 'Other'."
                placement="right"
              >
                <span className={classes.sectionIcon}>
                  <FiHelpCircle />
                </span>
              </Tooltip>
            </div>
          </div>
          <Droppable droppableId="OTHER" type="FIELD">
            {provided => (
              <div ref={provided.innerRef} className={classes.droppableContainer} {...provided.droppableProps}>
                {orphanFields.map((f, i) => this.renderAttribute('OTHER', f, !!(i % 2), i))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {canAddAttribute && this.renderAddAttribute()}
          {groupsUpdating && (
            <div className={classes.savingOverlay}>
              <p>
                <Icon name="loading" className="animate-spin" />
                <span>Saving...</span>
              </p>
            </div>
          )}
        </div>
      </DragDropContext>
    );
  }

  private get headerGroupId(): string | null {
    const { fieldSchema, currentAudienceId } = this.props;

    const headerGroup = getFrom(fieldSchema)('groups')(currentAudienceId)
      .defaultTo([])
      .find(g => g.title === 'Header');

    return getFrom(headerGroup)('id').defaultTo(null);
  }

  private renderAttribute(groupKey: string, f: ApiField, altRow: boolean, index: number) {
    const {
      currentOrg,
      deleteFieldPrompt,
      editFieldStart,
      groupsUpdating,
      isMaster,
      fieldSchema,
      currentAudienceId,
    } = this.props;

    const groupOverride = !!fieldSchema.groups[currentAudienceId];

    const inherit = this.getFieldIsInherit(f.id);
    const config = this.getConfig(f.id);

    const canDelete =
      f.isCustom &&
      !(
        !!currentOrg.settings[currentOrg.id].collections_attributes &&
        currentOrg.settings[currentOrg.id].collections_attributes.enabled &&
        currentOrg.settings[currentOrg.id].collections_attributes.fieldName === f.property
      );

    const contents = (
      <>
        <div className={cx(classes.tableCell, classes['name'])}>{getFrom(config)('ui')('title').defaultTo(' ')}</div>
        <div className={cx(classes.tableCell, classes['isCheck'], classes['isCenter'], classes.required)}>
          {config.required && <FiCheck />}
        </div>
        <div className={cx(classes.tableCell, classes['isCenter'], classes.options)}>
          {
            {
              true_true_false: 'Hidden',
              false_true_false: 'Read Only',
              false_false_false: 'Editable',
              false_false_true: 'Hyperbot and Editable',
            }[`${String(!!config.ui.hidden)}_${String(!!config.ui.readonly)}_${String(!!config.notify)}`]
          }
        </div>
        {!isMaster && (
          <div className={cx(classes.tableCell, classes['isCheck'], classes.override)}>
            {!inherit && <Icon name="checkmark" />}
          </div>
        )}
        <div className={cx(classes.tableCell, classes['isCenter'], classes.actions)}>
          <div style={StyleUtil.styles.tables.actions_container}>
            <IconButton
              css={null}
              ariaLabel="Edit Attribute"
              color="accent"
              onClick={() => editFieldStart(f.id)}
              key={`${f.id}_edit`}
              icon={<FiEdit2 />}
              style={{ marginBottom: 0 }}
            />
            {isMaster && (
              <IconButton
                css={null}
                onClick={canDelete ? () => deleteFieldPrompt(f.id) : null}
                title={canDelete ? 'Delete' : null}
                ariaLabel="Delete Attribute"
                // data-tip-left={canDelete ? null : 'Attribute cannot be deleted. Select Edit to hide the attribute.'}
                disabled={!canDelete}
                icon={<FiTrash />}
                color="error"
                style={{ marginBottom: 0 }}
              />
            )}
          </div>
        </div>
      </>
    );

    if (groupKey === 'PROFILE_PICTURE') {
      return (
        <div
          key={f.id}
          className={cx(classes.row, {
            [classes['isAlt']]: altRow,
            [classes.attributeInherited]: !isMaster && inherit,
          })}
        >
          {contents}
        </div>
      );
    }

    return (
      <Draggable
        key={f.id}
        draggableId={`${groupKey}_${f.id}`}
        index={index}
        type="FIELD"
        isDragDisabled={groupsUpdating || !groupOverride || groupKey === 'PROFILE_PICTURE'}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={cx(classes.row, {
              [classes['isAlt']]: altRow,
              [classes.attributeInherited]: !isMaster && inherit,
              [classes.isDragging]: snapshot.isDragging,
            })}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {contents}
          </div>
        )}
      </Draggable>
    );
  }

  private renderAddGroup() {
    const { isFree, showPremiumModal, createGroupStart } = this.props;

    return (
      <div className={cx(classes.row, classes.addRow)}>
        <div className={cx(classes.tableCell)}>
          <button
            className={classes.addButton}
            onClick={isFree ? () => showPremiumModal(ModalKeys.categories) : createGroupStart}
          >
            {isFree ? <PremiumLock tooltipDir="right" /> : <Icon name="plus" />}
            <span>Add a group</span>
          </button>
        </div>
      </div>
    );
  }

  private renderAddAttribute() {
    const { isFree, showPremiumModal, createFieldStart, currentOrg, settings } = this.props;

    return (
      <div className={cx(classes.row, classes.addRow)}>
        <div className={cx(classes.tableCell)}>
          <button
            className={classes.addButton}
            onClick={
              isFree
                ? () => showPremiumModal(ModalKeys.attributes)
                : () => createFieldStart(currentOrg.id, settings.read_only_mode)
            }
          >
            {isFree ? <PremiumLock tooltipDir="right" /> : <Icon name="plus" />}
            <span>Add an attribute</span>
          </button>
        </div>
      </div>
    );
  }

  private getField(fieldId: string): ApiField {
    return SchemaUtil.GetFieldById(this.props.fieldSchema, fieldId);
  }

  private getFieldIsInherit(fieldId: string): boolean {
    const field = this.getField(fieldId);
    return !this.props.isMaster && !field.audienceConfigs[this.props.currentAudienceId];
  }

  private getConfig(fieldId: string): ApiFieldAudienceConfig {
    const { currentOrg, currentAudienceId } = this.props;
    const field = this.getField(fieldId);
    return SchemaUtil.GetConfigFromField(field, currentAudienceId, currentOrg.id);
  }

  private getGroups(): ApiGroup[] {
    const { currentAudienceId, currentOrg, fieldSchema, dirtyGroups } = this.props;
    if (!currentOrg || !fieldSchema) {
      return [];
    }

    const cleanGroups = fieldSchema.groups[currentAudienceId] || fieldSchema.groups[currentOrg.id] || [];

    if (dirtyGroups.groups[currentAudienceId] == null) {
      return cleanGroups;
    }

    // Unmodified groups are saved as { id: string } just to assert their order to the API. We need to rehydrate those for rendering

    let newCount = 0;
    return dirtyGroups.groups[currentAudienceId].map(g => {
      if (!g.hasOwnProperty('id')) {
        return { ...g, id: `NEW_GROUP_${newCount++}` };
      }
      if (g.hasOwnProperty('fields')) {
        return g;
      }
      for (const group of cleanGroups) {
        if (group.id === g.id) {
          return group;
        }
      }
      console.error(`Unable to find a clean version of group ${g.id} to hydrate the dirtyGroups`, g);
      return { id: g.id, fields: [] } as ApiGroup;
    });
  }

  private getOrphanFields(): ApiField[] {
    const { fieldSchema } = this.props;

    const groups = this.getGroups();
    const groupFieldIds = groups.reduce((all, { fields }) => all.concat(fields), []);

    return Object.keys(fieldSchema.fields)
      .filter(id => groupFieldIds.indexOf(id) === -1)
      .map(id => fieldSchema.fields[id])
      .filter(f => !!f && !f.internal && this.lockedProperties.indexOf(f.property) === -1);
  }

  // source: https://github.com/atlassian/react-beautiful-dnd/issues/2171 && https://codesandbox.io/s/react-beautiful-dnd-copy-and-drag-5trm0?file=/index.js
  private copy(
    source: ArrayLike<string>,
    destination: ArrayLike<string>,
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation,
  ): string[] {
    console.log('==> dest', destination);

    const sourceClone: string[] = Array.from(source);
    const destClone: string[] = Array.from(destination);
    const item = sourceClone[droppableSource.index];

    destClone.splice(droppableDestination.index, 0, item);
    return destClone;
  }

  private move(
    source: ArrayLike<string>,
    destination: ArrayLike<string>,
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation,
  ): { [key: string]: string[] } {
    const sourceClone: string[] = Array.from(source);
    const destClone: string[] = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  }

  private reorder(list: string[], startIndex: number, endIndex: number): string[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  private onDragEnd = (result: DropResult) => {
    const { updateGroups, currentAudienceId } = this.props;
    const { source, destination } = result;
    const groups = this.getGroups();
    const orphanFields = this.getOrphanFields().map(f => f.id);

    const handleDropOnOther = (currentAudienceId: string, groups: ApiGroup[]) => {
      // remove from source group only
      const sourceGroup: ApiGroup = groups.find(g => g.id === source.droppableId) || ({} as ApiGroup);
      const sourceFields = getFrom(sourceGroup)('fields').defaultTo([]);
      const moved = this.move(sourceFields, [], source, destination);
      sourceGroup.fields = getFrom(moved)(source.droppableId).defaultTo([]);
      console.log(groups);
      updateGroups(currentAudienceId, groups);
    };

    const handleDragFromOther = (currentAudienceId: string, groups: ApiGroup[]) => {
      const destinationGroup: ApiGroup = groups.find(g => g.id === destination.droppableId) || ({} as ApiGroup);
      const destinationFields = getFrom(destinationGroup)('fields').defaultTo([]);
      const moved = this.move(orphanFields, destinationFields, source, destination);
      destinationGroup.fields = getFrom(moved)(destination.droppableId).defaultTo([]);
      console.log(groups);
      updateGroups(currentAudienceId, groups);
    };

    const handleDropOnHeader = (currentAudienceId: string, groups: ApiGroup[]) => {
      const sourceGroup: ApiGroup = groups.find(g => g.id === source.droppableId) || ({} as ApiGroup);
      const destinationGroup: ApiGroup = groups.find(g => g.id === destination.droppableId) || ({} as ApiGroup);
      const sourceFields = getFrom(sourceGroup)('fields').defaultTo([]);
      const destinationFields = getFrom(destinationGroup)('fields').defaultTo([]);
      destinationGroup.fields = this.copy(sourceFields, destinationFields, source, destination);
      console.log(groups);
      updateGroups(currentAudienceId, groups);
    };

    const handleDragFromHeader = (currentAudienceId: string, groups: ApiGroup[]) => {
      const sourceGroup: ApiGroup = groups.find(g => g.id === source.droppableId) || ({} as ApiGroup);
      const sourceFields = getFrom(sourceGroup)('fields').defaultTo([]);
      const moved = this.move(sourceFields, [], source, destination);
      sourceGroup.fields = getFrom(moved)(source.droppableId).defaultTo([]);
      console.log(groups);
      updateGroups(currentAudienceId, groups);
    };

    const handleMoveWithinGroup = (currentAudienceId: string, groups: ApiGroup[]) => {
      if (source.index === destination.index) {
        return;
      }
      // move attribute within same group
      const group: ApiGroup = groups.find(g => g.id === destination.droppableId) || ({} as ApiGroup);
      const fields: string[] = getFrom(group)('fields').defaultTo([]);
      group.fields = this.reorder(fields, source.index, destination.index);
      updateGroups(currentAudienceId, groups);
    };

    const handleMoveBetweenGroups = (currentAudienceId: string, groups: ApiGroup[]) => {
      const sourceGroup: ApiGroup = groups.find(g => g.id === source.droppableId) || ({} as ApiGroup);
      const destinationGroup: ApiGroup = groups.find(g => g.id === destination.droppableId) || ({} as ApiGroup);
      const sourceFields = getFrom(sourceGroup)('fields').defaultTo([]);
      const destinationFields = getFrom(destinationGroup)('fields').defaultTo([]);
      const moved = this.move(sourceFields, destinationFields, source, destination);
      sourceGroup.fields = getFrom(moved)(source.droppableId).defaultTo([]);
      destinationGroup.fields = getFrom(moved)(destination.droppableId).defaultTo([]);
      console.log(groups);
      updateGroups(currentAudienceId, groups);
    };

    // no movement ('OTHER' is a dynamically generated group  containing fields which are not in another group)
    if (
      !destination ||
      (source.droppableId === 'OTHER' && destination.droppableId === 'OTHER') ||
      (destination.droppableId === source.droppableId && destination.index === source.index)
    ) {
      return;
    }

    if (result.type === 'FIELD') {
      if (destination.droppableId === 'OTHER') {
        // remove from source group only
        handleDropOnOther(currentAudienceId, groups);
        return;
      }

      if (source.droppableId === 'OTHER') {
        // move to destination group only
        handleDragFromOther(currentAudienceId, groups);
        return;
      }

      if (destination.droppableId === this.headerGroupId && source.droppableId !== this.headerGroupId) {
        // copy attribute to header, remains in source group
        handleDropOnHeader(currentAudienceId, groups);
        return;
      }

      if (source.droppableId === this.headerGroupId && destination.droppableId !== this.headerGroupId) {
        // remove attribute from header only
        handleDragFromHeader(currentAudienceId, groups);
        return;
      }

      if (source.droppableId === destination.droppableId) {
        // move attribute within same group
        handleMoveWithinGroup(currentAudienceId, groups);
        return;
      }

      if (source.droppableId !== destination.droppableId) {
        // move attribute between groups
        handleMoveBetweenGroups(currentAudienceId, groups);
        return;
      }

      console.error('unhandled drag and drop type', result.type, source, destination);
      return;
    }

    if (result.type === 'GROUP') {
      const orderArray = this.getGroups().map(({ id }) => ({ id }));
      const [removed] = orderArray.splice(source.index, 1);
      orderArray.splice(destination.index, 0, removed);

      updateGroups(currentAudienceId, orderArray);
      return;
    }

    console.error('Unexpected drag and drop type', result.type);
  };
}
