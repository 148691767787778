import { UiState as State } from '../../models/client/';

const OPEN_USER_MENU = 'antrea/ui/OPEN_USER_MENU';
const CLOSE_USER_MENU = 'antrea/ui/CLOSE_USER_MENU';

const TRIAL_START_EMAIL_EDIT = 'antrea/ui/trial/START_EMAIL_EDIT';
const TRIAL_END_EMAIL_EDIT = 'antrea/ui/trial/END_EMAIL_EDIT';
const TRIAL_UPDATE_EMAIL = 'antrea/ui/trial/UPDATE_EMAIL';

const TRIAL_EMAIL_INVALID = 'antrea/ui/trial/get_started/EMAIL_INVALID';
const TRIAL_EMAIL_INVALID_CLEAR = 'antrea/ui/trial/get_started/EMAIL_VALID';
const TRIAL_EMAIL_PERSONAL = 'antrea/ui/trial/get_started/PERSONAL';
const TRIAL_EMAIL_PERSONAL_CLEAR = 'antrea/ui/trial/get_started/IMPERSONAL';

const APPROVE_HIDE_FAILED = 'antrea/ui/approve/APPROVE_HIDE_FAILED';
const APPROVE_SET_GROUP = 'antrea/ui/approve/APPROVE_SET_GROUP';
const APPROVE_SHOW_FAILED = 'antrea/ui/approve/APPROVE_SHOW_FAILED';

const ATTRIBUTES_SET_TAB = 'antrea/ui/attributes/SET_TAB';

const GETTING_STARTED_EDIT_SETTINGS = 'antrea/ui/gettingStarted/EDIT_SETTINGS';
const GETTING_STARTED_EDIT_SETTINGS_CANCEL = 'antrea/ui/gettingStarted/EDIT_SETTINGS_CANCEL';

const initialState: State = {
  userMenuOpen: false,
  trialGetStarted: {},
  trialConfirmDirectory: {},
  approve: {
    group: 'user',
    hideFailed: true,
  },
  attributes: {
    tab: 'attributes',
  },
  gettingStarted: {},
};

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case OPEN_USER_MENU:
      return {
        ...state,
        userMenuOpen: true,
      };
    case CLOSE_USER_MENU:
      return {
        ...state,
        userMenuOpen: false,
      };
    case TRIAL_START_EMAIL_EDIT:
      return {
        ...state,
        trialConfirmDirectory: {
          ...state.trialConfirmDirectory,
          editEmail: true,
        },
      };
    case TRIAL_END_EMAIL_EDIT:
      return {
        ...state,
        trialConfirmDirectory: {
          ...state.trialConfirmDirectory,
          editEmail: false,
        },
      };
    case TRIAL_UPDATE_EMAIL:
      return {
        ...state,
        trialConfirmDirectory: {
          ...state.trialConfirmDirectory,
          email: action.data,
        },
      };
    case TRIAL_EMAIL_INVALID:
      return {
        ...state,
        trialGetStarted: {
          ...state.trialGetStarted,
          emailInvalid: true,
        },
      };
    case TRIAL_EMAIL_INVALID_CLEAR:
      return {
        ...state,
        trialGetStarted: {
          ...state.trialGetStarted,
          emailInvalid: false,
        },
      };
    case TRIAL_EMAIL_PERSONAL:
      return {
        ...state,
        trialGetStarted: {
          ...state.trialGetStarted,
          emailPersonal: true,
        },
      };
    case TRIAL_EMAIL_PERSONAL_CLEAR:
      return {
        ...state,
        trialGetStarted: {
          ...state.trialGetStarted,
          emailPersonal: false,
        },
      };
    case APPROVE_SET_GROUP:
      return {
        ...state,
        approve: {
          ...state.approve,
          group: action.group,
        },
      };
    case APPROVE_HIDE_FAILED:
    case APPROVE_SHOW_FAILED:
      return {
        ...state,
        approve: {
          ...state.approve,
          hideFailed: action.type === APPROVE_HIDE_FAILED,
        },
      };
    case ATTRIBUTES_SET_TAB:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          tab: action.tab,
        },
      };
    case GETTING_STARTED_EDIT_SETTINGS:
      return {
        ...state,
        gettingStarted: {
          ...state.gettingStarted,
          edittingSettings: true,
        },
      };
    case GETTING_STARTED_EDIT_SETTINGS_CANCEL:
      return {
        ...state,
        gettingStarted: {
          ...state.gettingStarted,
          edittingSettings: false,
        },
      };
    default:
      return state;
  }
}

export function openMenu() {
  return {
    type: OPEN_USER_MENU,
  };
}

export function closeMenu() {
  return {
    type: CLOSE_USER_MENU,
  };
}

export const trialActions = {
  startEdit: () => {
    return {
      type: TRIAL_START_EMAIL_EDIT,
    };
  },
  endEdit: () => {
    return {
      type: TRIAL_END_EMAIL_EDIT,
    };
  },
  updateEmail: (data: string) => {
    return {
      type: TRIAL_UPDATE_EMAIL,
      data,
    };
  },
};

export const trialGetStartedActions = {
  markPersonal: (personal = true) => {
    return {
      type: personal ? TRIAL_EMAIL_PERSONAL : TRIAL_EMAIL_PERSONAL_CLEAR,
    };
  },
  markInvalid: (invalid = true) => {
    return {
      type: invalid ? TRIAL_EMAIL_INVALID : TRIAL_EMAIL_INVALID_CLEAR,
    };
  },
};

export const approveActions = {
  setGroup: (group: string) => {
    return {
      type: APPROVE_SET_GROUP,
      group,
    };
  },
  hideFailed: (hide: boolean) => {
    return {
      type: hide ? APPROVE_HIDE_FAILED : APPROVE_SHOW_FAILED,
    };
  },
};

export const attributesActions = {
  setTab: (tab: string) => {
    return {
      type: ATTRIBUTES_SET_TAB,
      tab,
    };
  },
};

export const gettingStartedActions = {
  editSettings: (edit: boolean) => {
    return {
      type: edit ? GETTING_STARTED_EDIT_SETTINGS : GETTING_STARTED_EDIT_SETTINGS_CANCEL,
    };
  },
};
