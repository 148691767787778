import { ApiField } from '@hyperfish/antrea-api-contracts/src/fields';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { ValidationsState as State } from '../../models/client/';
import { ApiClient } from '@hyperfish/fishfood';

const VALIDATE_IMAGE = 'antrea/validations/VALIDATE_IMAGE';
const VALIDATE_IMAGE_SUCCESS = 'antrea/validations/VALIDATE_IMAGE_SUCCESS';
const VALIDATE_IMAGE_FAIL = 'antrea/validations/VALIDATE_IMAGE_FAIL';
const VALIDATE_FIELD_EXTERNAL = 'antrea/validations/VALIDATE_FIELD_EXTERNAL';
const VALIDATE_FIELD_EXTERNAL_SUCCESS = 'antrea/validations/VALIDATE_FIELD_EXTERNAL_SUCCESS';
const VALIDATE_FIELD_EXTERNAL_FAIL = 'antrea/validations/VALIDATE_FIELD_EXTERNAL_FAIL';
const VALIDATE_EMAIL = 'antrea/validations/VALIDATE_EMAIL';
const VALIDATE_EMAIL_SUCCESS = 'antrea/validations/VALIDATE_EMAIL_SUCCESS';
const VALIDATE_EMAIL_FAIL = 'antrea/validations/VALIDATE_EMAIL_FAIL';
const VALIDATE_EMAIL_CLEAR = 'antrea/validations/VALIDATE_EMAIL_CLEAR';
// const VALIDATE_FIELD_EXTERNAL_COMPLETE = 'antrea/validations/VALIDATE_FIELD_EXTERNAL_COMPLETE';
const CLEAR_VALIDATION = 'antrea/validations/CLEAR_VALIDATION';

const initialState: State = {
  validatingByKey: {},
  errorByKey: {},
  resultByKey: {},
};

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case VALIDATE_IMAGE:
      return {
        ...state,
        validating: true,
        error: null,
      };
    case VALIDATE_IMAGE_SUCCESS:
      return {
        ...state,
        validating: false,
        result: action.result.flags,
      };
    case VALIDATE_IMAGE_FAIL:
      return {
        ...state,
        validating: false,
        error: action.error,
      };
    case CLEAR_VALIDATION:
      return {
        ...state,
        result: null,
        error: null,
      };
    case VALIDATE_FIELD_EXTERNAL:
      return {
        ...state,
        validatingByKey: {
          ...state.validatingByKey,
          [action.field.id]: true,
        },
        errorByKey: {
          ...state.errorByKey,
          [action.field.id]: null,
        },
      };
    case VALIDATE_FIELD_EXTERNAL_SUCCESS:
      return {
        ...state,
        validatingByKey: {
          ...state.validatingByKey,
          [action.field.id]: false,
        },
        resultByKey: {
          ...state.resultByKey,
          [action.field.id]: action.result,
        },
      };
    case VALIDATE_FIELD_EXTERNAL_FAIL:
      return {
        ...state,
        validatingByKey: {
          ...state.validatingByKey,
          [action.field.id]: false,
        },
        errorByKey: {
          ...state.errorByKey,
          [action.field.id]: action.error,
        },
      };
    case VALIDATE_EMAIL:
      return {
        ...state,
        emailValidating: true,
        emailResult: null,
        emailError: null,
      };
    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        emailResult: action.result,
        emailValidating: false,
      };
    case VALIDATE_EMAIL_FAIL:
      return {
        ...state,
        emailValidating: false,
        emailError: action.error,
      };
    case VALIDATE_EMAIL_CLEAR:
      return {
        ...state,
        emailResult: null,
        emailError: null,
      };
    default:
      return state;
  }
}

// ACTION CREATORS
export function validateImage(content: string) {
  return {
    types: [VALIDATE_IMAGE, VALIDATE_IMAGE_SUCCESS, VALIDATE_IMAGE_FAIL],
    promise: (client: ApiClient) => {
      return client.post('/validations/images', { data: { content } });
    },
  };
}

export function validateEmail(from: string) {
  return {
    types: [VALIDATE_EMAIL, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAIL],
    promise: (client: ApiClient) => {
      return client.post('/orgs/current/test-msemail', {
        data: {
          to: 'antreaemail@hyperfish.com',
          from,
          subject: 'MS Email Test',
          body: 'MS Email Test',
        },
      });
    },
  };
}

export function clearEmailValidation() {
  return {
    type: VALIDATE_EMAIL_CLEAR,
  };
}

export function validateFieldExternal(field: ApiField, value: string, audienceId: string) {
  return {
    type: VALIDATE_FIELD_EXTERNAL,
    field,
    value,
    audienceId,
  };
}

export function clearValidation() {
  return {
    type: CLEAR_VALIDATION,
  };
}

// SAGAS
export function* validateFieldExternalSaga(client: ApiClient, action) {
  // Debounce by 500ms
  yield delay(500);

  // tslint:disable-next-line:no-unused-variable
  const { type, ...rest } = action;
  try {
    const result = yield call([client, client.post], `/field-schemas/default/fields/${action.field.id}/validations`, {
      data: { value: action.value },
      params: { audienceId: action.audienceId },
    });
    yield put({ ...rest, type: VALIDATE_FIELD_EXTERNAL_SUCCESS, result: result.data });
  } catch (error) {
    yield put({ ...rest, type: VALIDATE_FIELD_EXTERNAL_FAIL, error });
  }
}

export function* validateFieldExternalWatch(client) {
  yield takeLatest(VALIDATE_FIELD_EXTERNAL, validateFieldExternalSaga, client);
}
