export function mapArrayToObject<T>(a: T[], getKey: (o: T) => string): { [key: string]: T } {
  const o = {};
  for (const i of a) {
    o[getKey(i)] = i;
  }
  return o;
}

export default function mapArrayToObjectById<T extends { id: string }>(a: T[]): { [id: string]: T } {
  return mapArrayToObject(a, o => o.id);
}
