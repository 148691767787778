import { BrandingBlob } from '@hyperfish/antrea-api-contracts';
import { FishFoodTheme } from '@hyperfish/fishfood';
import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AuthedRoute } from '../../components';
import { MarketingHeader } from '../../components/MarketingHeader';
import { NotificationContainer } from '../../components/NotificationContainer';
import { BRANDING_PREVIEW } from '../../config';
import { Approve, Login, User, ValidationSuccess, Users } from '../../containers';
import { Directory } from '../../containers/Directory';
import { OrgChart } from '../../containers/OrgChart';
import { RelationshipChart } from '../../containers/RelationshipChart';
import { clearBackend, isAuthed, isEditor, isFree } from '../../redux/modules/auth';
import { getMergedUserSelfBranding, loadUserBranding as loadBranding } from '../../redux/modules/branding';
import { loadDetail } from '../../redux/modules/externalUsers';
import { dismissNotification } from '../../redux/modules/notifications';
import { loadCurrent as loadCurrentOrg, hasOrgFeature } from '../../redux/modules/orgs';
import { load as loadSelf } from '../../redux/modules/self';
import { closeMenu, openMenu } from '../../redux/modules/ui';
import classes from './styles.module.scss';
import { webpartsEnabled } from '../../redux/modules/settings';

import cx from 'classnames';
// import DiscontinuationWarning from '../DiscontinuationWarning';

const brandedUrls = ['/self', '/directory', '/orgchart', '/org-chart', '/relationshipchart'];

const connector = connect(
  (state, props) => {
    const isSelf = brandedUrls.some(url => props.location.pathname.indexOf(url) === 0);
    const userId = isSelf ? 'me' : decodeURIComponent(props.match.params.userId);

    return {
      userId: userId,
      branding: state.branding,
      currentOrg: state.orgs.current,
      customBackend: state.auth.customBackend,
      // externalUsers: state.self.externalUsers,
      isAuthed: isAuthed(state) || isAuthed(state, 'grant'),
      loadingCurrentOrg: state.orgs.loadingCurrent,
      // loadingExternal: state.self.loadingExternal['current'],
      userDetail: state.externalUsers.detailById.me,
      userDetailError: state.externalUsers.detailByIdError.me,
      userDetailLoading: state.externalUsers.detailByIdLoading.me,
      loadingSelf: state.self.loading,
      menuOpen: state.ui.userMenuOpen,
      notifications: state.notifications.notifications,
      token: state.auth.token,
      user: state.self.user,
      editor: isEditor(state),
      enabledWebparts: state.orgs.current ? webpartsEnabled(state.orgs.current.id, state) : [],
      webpartsLicensed: hasOrgFeature(state, 'AllowDirectory'),
      isFree: isFree(state),
    };
  },
  {
    clearBackend,
    closeMenu,
    dismissNotification,
    loadCurrentOrg,
    loadDetail,
    loadSelf,
    openMenu,
    loadBranding,
  },
);

export type Props = typeof connector['props'];

export class MarketingLayoutC extends React.Component<Props, {}> {
  get branding(): BrandingBlob {
    const pathname = this.props.location.pathname;

    if (
      pathname.indexOf('/self') !== 0 &&
      pathname.indexOf('/users/') !== 0 &&
      pathname.indexOf('/directory') !== 0 &&
      pathname.indexOf('/orgchart') !== 0 &&
      pathname.indexOf('/relationshipchart') !== 0 &&
      pathname.indexOf('/org-chart') !== 0
    ) {
      // Only brand the self pages.
      return {} as BrandingBlob;
    }

    const brandingPreview = parse(this.props.location.search).brandingPreview;
    if (brandingPreview) {
      const previewString = sessionStorage && sessionStorage.getItem(BRANDING_PREVIEW);
      return previewString ? JSON.parse(previewString) : {};
    }
    return getMergedUserSelfBranding(
      this.props.branding,
      (this.props.location.pathname.indexOf('/users/') === 0 && this.props.match.params['userId']) || 'me',
    );
  }

  componentDidMount() {
    const {
      currentOrg,
      isAuthed,
      loadCurrentOrg,
      loadingCurrentOrg,
      loadingSelf,
      loadSelf,
      user,
      userDetailLoading,
      loadDetail,
      loadBranding,
      userId,
    } = this.props;

    if (!isAuthed) {
      return;
    }

    if (!user && !loadingSelf) {
      loadSelf();
    }
    if (!currentOrg && !loadingCurrentOrg) {
      loadCurrentOrg();
    }
    if (!userDetailLoading) {
      loadDetail('me');
    }

    if (userId) {
      loadBranding(userId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.token && !nextProps.token) {
      // logout
      window.location.href = '/login'; // TODO: Router redirect
    }
  }

  clickHandler = () => {
    this.handleMenuClose();
  };

  handleMenuOpen = () => {
    document.addEventListener('click', this.clickHandler);
    this.props.openMenu();
  };

  handleMenuClose = () => {
    document.removeEventListener('click', this.clickHandler);
    this.props.closeMenu();
  };

  render() {
    const { dismissNotification, notifications, isAuthed, editor } = this.props;

    const isApprove = this.props.location.pathname.indexOf('/self/approve') === 0;

    const approveLayoutStyle: React.CSSProperties = {
      backgroundColor: '#eeeff0',
      minHeight: '100vh',
    };

    // accentColor: string;
    // headerTextColor: string;
    // logo: string;
    // primaryColor: string;
    // secondaryColor: string;
    const branding = this.branding;
    const theme: Partial<FishFoodTheme> = { colors: {} };

    if (branding.accentColor) {
      theme.colors.accent = branding.accentColor;
    }
    if (branding.headerTextColor) {
      // No Op
    }
    if (branding.primaryColor) {
      theme.colors.secondary = branding.primaryColor;
    }
    if (branding.secondaryColor) {
      theme.colors.primary = branding.secondaryColor;
    }
    if (branding.completionRingSuccessColor) {
      theme.colors.completionRingSuccessColor = branding.completionRingSuccessColor;
    }
    if (branding.completionRingWarnColor) {
      theme.colors.completionRingWarnColor = branding.completionRingWarnColor;
    }
    if (branding.completionRingErrorColor) {
      theme.colors.completionRingErrorColor = branding.completionRingErrorColor;
    }

    return (
      <ThemeProvider theme={({ ff }) => ({ ff: { ...ff, colors: { ...ff.colors, ...theme.colors } } })}>
        <div className={cx('marketingLayout', classes.MarketingLayout)} style={isApprove ? approveLayoutStyle : {}}>
          <MarketingHeader
            {...(this.props as any)}
            branding={branding}
            handleMenuClose={this.handleMenuClose}
            handleMenuOpen={this.handleMenuOpen}
            showSignIn={['/validationsuccess', '/'].indexOf(this.props.location.pathname) > -1}
          />
          {/* <DiscontinuationWarning /> */}
          <main>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/validationsuccess" component={ValidationSuccess} />
              <AuthedRoute isAllowed={isAuthed} exact path="/self/approve" component={Approve} />
              <AuthedRoute isAllowed={isAuthed} exact path="/directory" component={Directory} />
              <AuthedRoute isAllowed={isAuthed} exact path={['/orgchart', '/org-chart']} component={OrgChart} />
              <AuthedRoute isAllowed={isAuthed} exact path={['/relationshipchart']} component={RelationshipChart} />
              <AuthedRoute isAllowed={isAuthed} path={['/self', '/users/:userId']} component={User} />
              <AuthedRoute isAllowed={isAuthed && editor} path={'/userslist'} component={Users} />
            </Switch>
          </main>
          <NotificationContainer notifications={notifications} dismiss={dismissNotification} />
        </div>
      </ThemeProvider>
    );
  }
}

export const MarketingLayout = connector(MarketingLayoutC);
