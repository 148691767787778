import cx from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

type Props = JSX.IntrinsicElements['td'] & {
  isCenter?: boolean;
  isCheck?: boolean;
};

export const TableCell: React.StatelessComponent<Props> = ({ isCenter, isCheck, children, ...rest }) => (
  <td
    {...rest}
    className={cx(classes.TableCell, rest.className, {
      [classes.isCenter]: isCenter,
      [classes.isCheck]: isCheck,
    })}
  >
    {children}
  </td>
);
