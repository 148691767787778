import { OrgAuditFilter, OrgSettings, OrgSettingsKeys } from '../../models/api';
import { SettingsState as State, GlobalState, Notification as ClientNotification } from '../../models/client/';

import { PATCH_TEMPLATE_SUCCESS } from './broadcasts/types';
import { getFrom, ApiClient } from '@hyperfish/fishfood';
import { TestAuditFiltersResponse } from '@hyperfish/antrea-api-contracts';

const LOAD = 'antrea/settings/LOAD';
const LOAD_SUCCESS = 'antrea/settings/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/settings/LOAD_FAIL';
const SAVE = 'antrea/settings/SAVE';
export const SAVE_SUCCESS = 'antrea/settings/SAVE_SUCCESS';
const SAVE_FAIL = 'antrea/settings/SAVE_FAIL';
const CANCEL = 'antrea/settings/CANCEL';
const EDIT = 'antrea/settings/EDIT';
const DISMISS_CONFIRM_RUN = 'antrea/settings/DISMISS_CONFIRM_RUN';
const CLEAR = 'antrea/settings/CLEAR';
const AUDIT_FILTER_TEST = 'antrea/settings/AUDIT_FILTER_TEST';
const AUDIT_FILTER_TEST_SUCCESS = 'antrea/settings/AUDIT_FILTER_TEST_SUCCESS';
const AUDIT_FILTER_TEST_FAIL = 'antrea/settings/AUDIT_FILTER_TEST_FAIL';
const SET_AUDIT_FILTER_TEST_CLICKED = 'antrea/settings/SET_AUDIT_FILTER_TEST_CLICKED';

const initialState: State = {
  dirtyData: {},
  loaded: false,
  relatedEntities: { audienceSpecific: {} },
} as State;

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case PATCH_TEMPLATE_SUCCESS: {
      const newState = { ...state };
      const settings = getFrom(action)('result')('relatedEntities')('settings').value;
      if (!settings) {
        return newState;
      }
      const audiences = Object.keys(settings);
      if (audiences.length === 0) {
        return newState;
      }
      for (const audience of audiences) {
        newState.data[audience] = {
          ...newState.data[audience],
          ...settings[audience],
        };
      }
      return newState;
    }
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      /* ANT-4961: remove (pre-existing) empty emails (if any) */
      let data: { [audienceId: string]: OrgSettings } = action.result.settings;

      for (let i in data) {
        const settings: OrgSettings = data[i];

        if (Array.isArray(settings.broadcast_exclusions)) {
          settings.broadcast_exclusions = settings.broadcast_exclusions.filter(e => e.address);
        }
      }

      return {
        ...state,
        data: data,
        dirty: false,
        error: null,
        loaded: true,
        loading: false,
        relatedEntities: action.result.relatedEntities,
      };
    case LOAD_FAIL:
      return {
        ...state,
        data: null,
        error: action.error,
        loaded: false,
        loading: false,
      };
    case SAVE:
      return {
        ...state,
        saving: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.audienceId]: {
            ...state.data[action.audienceId],
            ...action.result.settings[action.audienceId],
          },
        },
        dirty: false,
        dirtyData: {
          ...state.dirtyData,
          [action.audienceId]: {},
        },
        saving: false,
      };
    case SAVE_FAIL:
      return {
        ...state,
        saving: false,
        saveError: action.error,
      };
    case CANCEL:
      return {
        ...state,
        dirty: false,
        dirtyData: initialState.dirtyData,
      };
    case EDIT: {
      if (action.key === OrgSettingsKeys.global.mode && action.data === 'Run' && !action.confirm) {
        return {
          ...state,
          // confirmRun: (state.dirtyData || {})[OrgSettingsKeys.global.mode] || state.data[OrgSettingsKeys.global.mode],
          // TODO: Set confirmRun to prior mode for good revert
          confirmRun: 'Pilot',
        };
      }

      const newDirtyAudience = {
        ...state.dirtyData[action.audienceId],
        [action.key]: action.data,
      };

      if (
        action.key === OrgSettingsKeys.global.mode &&
        action.data === 'Run' &&
        ((state.dirty && !getFrom(state.dirtyData[action.audienceId])('read_only_mode').defaultTo(true)) ||
          !state.data[action.audienceId][OrgSettingsKeys.global.readOnlyMode])
      ) {
        newDirtyAudience.broadcast_adminReport_enabled = true;
      }

      const dirtyData = { ...state.dirtyData };
      let dirty = !!action.force;
      for (const key in newDirtyAudience) {
        if (
          newDirtyAudience.hasOwnProperty(key) &&
          JSON.stringify(newDirtyAudience[key]) !== JSON.stringify(state.data[action.audienceId][key])
        ) {
          dirty = true;
          break;
        }
      }

      if (dirty) {
        dirtyData[action.audienceId] = newDirtyAudience;
      } else {
        delete dirtyData[action.audienceId];
      }

      return {
        ...state,
        dirty,
        dirtyData,
        ...(action.confirm ? { confirmRun: null } : {}),
      };
    }
    case DISMISS_CONFIRM_RUN:
      return {
        ...state,
        confirmRun: null,
      };
    case CLEAR:
      return {
        ...state,
        data: null,
        loaded: false,
      };
    case AUDIT_FILTER_TEST:
      return {
        ...state,
        auditFilterTestLoading: true,
      };
    case AUDIT_FILTER_TEST_SUCCESS:
      let result: TestAuditFiltersResponse = action.result;

      return {
        ...state,
        auditFilterTestLoading: false,
        auditFilterTestResult: result,
      };
    case AUDIT_FILTER_TEST_FAIL:
      return {
        ...state,
        auditFilterTestLoading: false,
        auditFilterTestResult: null,
      };
    case SET_AUDIT_FILTER_TEST_CLICKED:
      return {
        ...state,
        auditFilterTestClicked: action.result,
      };
    default:
      return state;
  }
}

export function shouldLoad(globalState) {
  return globalState.settings && !globalState.settings.loading;
}

export function load(audiences?: string[], settingsKeys?: string[]) {
  const params: { audiences?: string; settingsKeys: string } = {
    settingsKeys: !!settingsKeys ? settingsKeys.join(',') : '__*',
  };
  if (!!audiences) {
    params.audiences = audiences.join(',');
  }

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/settings', { params }),
    audiences,
    settingsKeys,
    notifications: {
      fail: 'Failed to load current settings.',
    },
  };
}

export function loadWebpartSetting(webpart?: 'directory' | 'orgchart' | 'profile') {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get(webpart ? `/settings/webparts/${webpart}` : '/settings/webparts'),
    notifications: {
      fail: 'Failed to load current settings.',
    },
  };
}

export function webpartsEnabled(audienceId: string, { settings }: GlobalState) {
  const webpartKeys = [OrgSettingsKeys.webparts.config.directory, OrgSettingsKeys.webparts.config.orgchart];

  if (settings && settings.data && settings.data[audienceId]) {
    if (Object.keys(settings.data[audienceId]).length === 0) {
      return webpartKeys;
    }

    const disabledItems = Object.keys(settings.data[audienceId]).filter(
      x => settings.data[audienceId][x]['enabled'] === false,
    );

    return webpartKeys.filter(x => !disabledItems.includes(x));
  }

  return [];
}

export function save(audienceId: string, data: Partial<OrgSettings>) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client: ApiClient) =>
      client.patch('/settings', {
        data: {
          settings: {
            [audienceId]: data,
          },
        },
      }),
    audienceId,
    notifications: {
      fail: 'Failed to update settings.',
      success: 'Successfully updated settings.',
    },
  };
}

export function testAuditFilters(data: OrgAuditFilter) {
  return {
    types: [AUDIT_FILTER_TEST, AUDIT_FILTER_TEST_SUCCESS, AUDIT_FILTER_TEST_FAIL],
    promise: (client: ApiClient) => {
      return new Promise((resolve, reject) => {
        client
          .post('/settings/test-audit-filter', {
            data: data,
          })
          .then((result: any) => {
            console.log(result.data);

            let forceNotification = {
              type: 'success',
              message: 'Test succeeded.',
              title: 'Success!',
              duration: 5,
            } as ClientNotification;

            if (result.data.success) {
              forceNotification.message = getFrom(result.data)('message')('text').defaultTo('');
            } else {
              forceNotification.title = 'Error!';
              forceNotification.type = 'error';
              forceNotification.message = result.data.message.text;
            }

            result.data.forceNotification = forceNotification;
            resolve(result);
          });
      });
    },
    notifications: {
      fail: '',
      success: '',
    },
  };
}

export function setAuditFilterTestClicked(flag: boolean) {
  return {
    type: SET_AUDIT_FILTER_TEST_CLICKED,
    result: flag,
  };
}

export function cancel() {
  return {
    type: CANCEL,
  };
}

export function edit(audienceId: string, key: keyof OrgSettings, data: any, force = false) {
  return {
    type: EDIT,
    audienceId,
    key,
    data,
    force,
  };
}

export function rejectConfirmRun() {
  return {
    type: DISMISS_CONFIRM_RUN,
  };
}

export function approveConfirmRun(orgId: string) {
  return {
    ...edit(orgId, OrgSettingsKeys.global.mode, 'Run'),
    confirm: true,
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}
