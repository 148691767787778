import { ApiMsGraphUser, ApiOnpremUser, ApiProfileTypes } from '@hyperfish/antrea-api-contracts/src/antreaUser';
import { OrgDetails } from '@hyperfish/antrea-api-contracts/src/org';

import { ApiError } from '../../models/api';
import { SelfState as State } from '../../models/client/';
import { LOGOUT_SUCCESS } from './auth';
import { ApiClient } from '@hyperfish/fishfood';
// import { ApiUser } from '@hyperfish/fishfood/lib/utils/UserUtil';

// const providerMap = {
//   msGraph: 'ms-graph',
//   onPrem: 'onpremises',
//   current: 'current',
// };

const LOAD = 'antrea/self/LOAD';
const LOAD_SUCCESS = 'antrea/self/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/self/LOAD_FAIL';
const LOAD_EXTERNAL = 'antrea/self/LOAD_EXTERNAL';
const LOAD_EXTERNAL_SUCCESS = 'antrea/self/LOAD_EXTERNAL_SUCCESS';
const LOAD_EXTERNAL_FAIL = 'antrea/self/LOAD_EXTERNAL_FAIL';
const SAVE = 'antrea/self/SAVE';
const SAVE_SUCCESS = 'antrea/self/SAVE_SUCCESS';
const SAVE_FAIL = 'antrea/self/SAVE_FAIL';
const EDIT_START = 'antrea/self/EDIT_START';
const EDIT_STOP = 'antrea/self/EDIT_STOP';
const EDIT_FIELD = 'antrea/self/EDIT_FIELD';

export const SAVE_PHOTO_SUCCESS = 'antrea/self/SAVE_PHOTO_SUCCESS';

const EDIT_SECTION_START = 'antrea/self/EDIT_SECTION_START';
const EDIT_SECTION_END = 'antrea/self/EDIT_SECTION_END';
const EDIT_SECTION_FIELD = 'antrea/self/EDIT_SECTION_FIELD';
const CLEAR_SECTION_FIELD = 'antrea/self/CLEAR_SECTION_FIELD';
const SAVE_SECTION = 'antrea/self/SAVE_SECTION';
export const SAVE_SECTION_SUCCESS = 'antrea/self/SAVE_SECTION_SUCCESS';
const SAVE_SECTION_FAIL = 'antrea/self/SAVE_SECTION_FAIL';

export const PHOTO_SECTION_KEY = 'photo';

const initialState = {
  dirtyFields: {},
  editingSection: {},
  loaded: false,
  savingSection: {},
  savingSectionError: {},
  sectionDirtyFields: {},
  loadingExternal: {},
  loadingExternalError: {},
} as State;

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
      };
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        user: null,
        error: action.error,
      };
    case LOAD_EXTERNAL:
      return {
        ...state,
        loadingExternal: {
          ...state.loadingExternal,
          [action.provider]: true,
        },
        loadingExternalError: {
          ...state.loadingExternalError,
          [action.provider]: null,
        },
      };
    case LOAD_EXTERNAL_SUCCESS:
      return {
        ...state,
        loadingExternal: {
          ...state.loadingExternal,
          [action.provider]: false,
        },
        externalUsers: {
          ...state.externalUsers,
          ...(action.provider === 'current' ? action.result : { [action.provider]: action.result }),
        },
      };
    case LOAD_EXTERNAL_FAIL:
      return {
        ...state,
        loadingExternal: {
          ...state.loadingExternal,
          [action.provider]: false,
        },
        loadingExternalError: {
          ...state.loadingExternalError,
          [action.provider]: action.error,
        },
      };
    case SAVE:
      return {
        ...state,
        saving: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        dirtyFields: initialState.dirtyFields,
        editing: false,
        saveError: null,
        saving: false,
        user: action.result,
      };
    case SAVE_FAIL:
      return {
        ...state,
        saving: false,
        saveError: action.error,
      };
    case EDIT_START:
      return {
        ...state,
        dirtyFields: initialState.dirtyFields,
        editing: true,
      };
    case EDIT_STOP:
      return {
        ...state,
        dirtyFields: initialState.dirtyFields,
        editing: false,
      };
    case EDIT_FIELD:
      return {
        ...state,
        dirtyFields: {
          ...state.dirtyFields,
          [action.field]: action.value,
        },
      };

    case EDIT_SECTION_START:
      return {
        ...state,
        editingSection: {
          ...state.editingSection,
          [action.section]: true,
        },
        sectionDirtyFields: {
          ...state.sectionDirtyFields,
          [action.section]: {},
        },
      };
    case EDIT_SECTION_END:
      return {
        ...state,
        editingSection: {
          ...state.editingSection,
          [action.section]: false,
        },
      };
    case EDIT_SECTION_FIELD:
      return {
        ...state,
        sectionDirtyFields: {
          ...state.sectionDirtyFields,
          [action.section]: {
            ...state.sectionDirtyFields[action.section],
            [action.field]: action.value,
          },
        },
      };
    case CLEAR_SECTION_FIELD: {
      const newDirtyFields = { ...state.sectionDirtyFields[action.section] };
      if (newDirtyFields.hasOwnProperty(action.field)) {
        delete newDirtyFields[action.field];
      }
      return {
        ...state,
        sectionDirtyFields: {
          ...state.sectionDirtyFields,
          [action.section]: newDirtyFields,
        },
      };
    }
    case SAVE_SECTION:
      return {
        ...state,
        savingSection: {
          ...state.savingSection,
          [action.section]: true,
        },
        savingSectionError: {
          ...state.savingSectionError,
          [action.section]: null,
        },
      };
    case SAVE_SECTION_SUCCESS:
      return {
        ...state,
        savingSection: {
          ...state.savingSection,
          [action.section]: false,
        },
        sectionDirtyFields: {
          ...state.sectionDirtyFields,
          [action.section]: {},
        },
        externalUsers: {
          ...state.externalUsers,
          [action.provider]: {
            ...state.externalUsers[action.provider],
          },
          relatedEntities: {
            ...state.externalUsers.relatedEntities,
            ...action.result.relatedEntities,
          },
        },
      };
    case SAVE_PHOTO_SUCCESS:
      return {
        ...state,
        savingSection: {
          ...state.savingSection,
          [action.section]: false,
        },
        sectionDirtyFields: {
          ...state.sectionDirtyFields,
          [action.section]: {},
        },
        externalUsers: {
          ...(state.externalUsers as any), // TODO: Remove this shit
          relatedEntities: {
            ...state.externalUsers.relatedEntities,
            changes: {
              ...state.externalUsers.relatedEntities.changes,
              [action.field]: action.result.relatedEntities.change,
            },
            profilePicture: action.result,
          },
        },
      };
    case SAVE_SECTION_FAIL:
      return {
        ...state,
        savingSection: {
          ...state.savingSection,
          [action.section]: false,
        },
        savingSectionError: {
          ...state.savingSectionError,
          [action.section]: action.error,
        },
      };
    default:
      return state;
  }
}

// ACTION CREATORS
export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/users/me'),
    notifications: {
      fail: 'Failed to load current LiveTiles Directory user details.',
    },
  };
}

export function save(data: { [k: string]: any }) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client: ApiClient) => client.patch('/users/me', { data }),
  };
}

export function loadExternal(provider?: keyof ApiProfileTypes) {
  provider = (provider || 'current') as any;
  return {
    types: [LOAD_EXTERNAL, LOAD_EXTERNAL_SUCCESS, LOAD_EXTERNAL_FAIL],
    provider,
    promise: (client: ApiClient) => client.get(`/external-users/current/me`),
    notifications: {
      fail: (e: ApiError) => {
        if (e.status === 404) {
          return;
        }
        if (((e.response || {})['body'] || {}).code === 'AN0022') {
          return;
        }
        return 'Failed to load current user details from directory.';
      },
    },
  };
}

export function editStart() {
  return { type: EDIT_START };
}

export function editStop() {
  return { type: EDIT_STOP };
}

export function editField(field: string, value: any) {
  return {
    type: EDIT_FIELD,
    field,
    value,
  };
}

export function editSectionStart(section: string) {
  return {
    type: EDIT_SECTION_START,
    section,
  };
}

export function editSectionStop(section: string) {
  return {
    type: EDIT_SECTION_END,
    section,
  };
}

export function editSectionField(section: string, field: string, value: any) {
  return {
    type: EDIT_SECTION_FIELD,
    section,
    field,
    value,
  };
}

export function clearSectionField(section: string, field: string) {
  return {
    type: CLEAR_SECTION_FIELD,
    section,
    field,
  };
}

export function saveSection(section: string, profile: object, provider: keyof ApiProfileTypes) {
  const providerFieldMap: { [k in keyof ApiProfileTypes]: string } = {
    msGraph: 'profilePicture',
    onPrem: 'thumbnailphoto',
  };

  if (section === PHOTO_SECTION_KEY) {
    const field = providerFieldMap[provider];
    return {
      types: [SAVE_SECTION, SAVE_PHOTO_SUCCESS, SAVE_SECTION_FAIL],
      section,
      provider,
      field,
      promise: (client: ApiClient) =>
        client.patch(`/external-users/current/me/profile-picture`, {
          data: profile[field],
        }),
      notifications: {
        fail: 'Failed to update profile picture.',
        success: 'Successfully updated profile picture.',
      },
    };
  }

  return {
    types: [SAVE_SECTION, SAVE_SECTION_SUCCESS, SAVE_SECTION_FAIL],
    section,
    provider,
    promise: (client: ApiClient) =>
      client.patch(`/external-users/current/me`, {
        data: { profile },
      }),
    notifications: {
      fail: 'Failed to update profile.',
      success: 'Successfully updated profile.',
    },
  };
}

// UTILITY FUNCTIONS
export function isGraphUserExpanded(user: ApiOnpremUser | ApiMsGraphUser): user is ApiMsGraphUser {
  return user && !!user['id'];
}
export function isPremUserExpanded(user: ApiOnpremUser | ApiMsGraphUser): user is ApiOnpremUser {
  return user && !!user['objectguid'];
}
export function getProviderFromOrg(org: OrgDetails): keyof ApiProfileTypes {
  return org && org.type === 'Online' ? 'msGraph' : 'onPrem';
}
