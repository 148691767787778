import cx from 'classnames';
import React, { EventHandler, CSSProperties, MouseEvent, StatelessComponent } from 'react';

import { Icon } from '../Icon';
import classes from './styles.module.scss';

interface Props {
  className?: string;
  description?: string;
  disabled?: boolean;
  iconName?: string;
  onClick?: EventHandler<MouseEvent<HTMLButtonElement | HTMLAnchorElement>>;
  style?: CSSProperties;
  submit?: boolean;
  target?: string;
  to?: string;
  type?: 'primary';
  size?: 'small' | 'medium' | 'large';
  active?: boolean;
}

export const Button: StatelessComponent<Props> = props => {
  const classMap = {
    [classes.disabled]: props.disabled,
    [classes.active]: props.active,
  };

  return !props.to ? (
    <button
      type={props.submit ? 'submit' : 'button'}
      className={cx(props.className, classes.Button, classes[props.type], classes[props.size], classMap)}
      onClick={!props.disabled ? props.onClick : undefined}
      disabled={props.disabled}
      style={props.style}
    >
      {props.iconName && <Icon name={props.iconName} className={props.iconName === 'loading' ? 'animate-spin' : ''} />}
      {props.children && <span>{props.children}</span>}
    </button>
  ) : (
    <a
      href={props.to}
      className={cx(
        props.className,
        classes.Button,
        classes.anchor,
        classes[props.type],
        classes[props.size],
        classMap,
      )}
      onClick={!props.disabled ? props.onClick : undefined}
      target={props.target}
      style={props.style}
    >
      {props.iconName && <Icon name={props.iconName} />}
      <span>{props.children}</span>
    </a>
  );
};
