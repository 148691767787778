import { AnalyticsReport, AnalyticsReportRequest } from '@hyperfish/antrea-api-contracts/src/analytics';
import moment from 'moment';

const data = {};
const recordCount = 100;
let startStamp;
let endStamp;
let startPoints;
let endPoints;

const properties = [
  'businessPhones',
  'city',
  'company',
  'country',
  'department',
  'jobTitle',
  'mail',
  'manager',
  'mobilePhone',
  'officeLocation',
  'postalCode',
  'profilePicture',
  'state',
  'streetAddress',
  'title',
];

function initialize() {
  if (!startPoints) {
    startPoints = {};
    for (const prop of properties) {
      startPoints[prop] = Math.random() * (recordCount * 0.3);
    }
  }
  if (!endPoints) {
    endPoints = {};
    for (const prop of properties) {
      endPoints[prop] = recordCount - Math.random() * (recordCount * 0.3);
    }
  }
  if (!startStamp) {
    startStamp = moment()
      .subtract(1, 'years')
      .unix();
  }
  if (!endStamp) {
    endStamp = moment().unix();
  }
}

function getPassed(prop: string, timepoint: moment.Moment) {
  const steps = endStamp - startStamp;
  const delta = endPoints[prop] - startPoints[prop];
  const slope = delta / steps;
  return Math.round((timepoint.unix() - startStamp) * slope + startPoints[prop]);
}

export default function generateDemoLines(params: AnalyticsReportRequest, key: string): AnalyticsReport {
  initialize();
  if (!data[key]) {
    const report = {
      averages: [],
      timeframe: {
        start: params.start,
        end: params.end,
      },
    } as AnalyticsReport;

    const timepoint = moment(params.start, moment.ISO_8601);
    const endpoint = moment(params.end, moment.ISO_8601);

    while (endpoint.isAfter(timepoint)) {
      report.averages.push({
        recordCount,
        timepoint: timepoint.toISOString(),
        stats: properties.map(p => ({
          failed: recordCount - getPassed(p, timepoint),
          missing: 0,
          passed: getPassed(p, timepoint),
          path: `/${p}`,
        })),
      });
      timepoint.add(params.width as any, `${params.interval}s`);
    }

    if (timepoint.isAfter(endpoint)) {
      report.averages.push({
        recordCount,
        timepoint: endpoint.toISOString(),
        stats: properties.map(p => ({
          failed: recordCount - getPassed(p, endpoint),
          missing: 0,
          passed: getPassed(p, endpoint),
          path: `/${p}`,
        })),
      });
    }

    data[key] = report;
  }

  return data[key];
}
