import { DeviceDetails } from '@hyperfish/antrea-api-contracts/src/device';
import { TextField, Select, IconButton, FiEdit2, Tooltip, FiEye, FiArrowLeft, Card } from '@hyperfish/fishfood';
import PeoplePicker from '@hyperfish/fishfood/lib/components/PeoplePicker/LegacyPeoplePicker';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  DataTable,
  HyperFieldFactory as fields,
  HyperForm,
  Icon,
  ModalKeys,
  PremiumLock,
  RadioGroup,
  SettingCard,
  Slider,
  TemplatePreview,
} from '../../../components/';
import { SettingsProps } from '../../../layouts/SettingsLayout';
import { OrgSettings, OrgSettingsKeys as keys } from '../../../models/api/';
import {
  clearPreview,
  load as loadBroadcasts,
  loadPreview,
  loadTemplate,
  saveTemplate,
} from '../../../redux/modules/broadcasts';
import { clear as clearUsers, load as loadUsers } from '../../../redux/modules/externalUsers';
import { loadConnections } from '../../../redux/modules/orgs';
import { AZURE_EMAIL, load as loadProviders } from '../../../redux/modules/providers';
import { clearEmailValidation, validateEmail } from '../../../redux/modules/validations';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import StyleUtil from '../../../utils/StyleUtil';
import AtLeastVersion from '../../../utils/VersionUtil';
import EmailContentTable from './components/EmailContentTable';
import { LoadingSplash, Modal, Toggle, Button, FiAlertTriangle, FiLoader } from '@hyperfish/fishfood';
import { parse } from 'query-string';
import { applyDSTforSchedules, applyDSTforSchedulesReverse } from '../../../utils/momentUtil';
import { isEmail } from '../../../utils/UserUtils';
import { HYPERBOT_DEFAULT_EMAIL_ADDRESS } from '../../../config';

interface Option {
  label: string;
  value: string;
}

// import Radium from 'Radium';
const DndTable = DataTable.of({
  address: { label: 'Email', sortable: true },
});

const connector = connect(
  state => ({
    broadcasts: state.broadcasts.data,
    broadcastsError: state.broadcasts.error,
    broadcastsLoading: state.broadcasts.loading,
    connections: state.orgs.connections,
    connectionsLoading: state.orgs.loadingConnections,
    emailError: state.validations.emailError,
    emailResult: state.validations.emailResult,
    emailValidating: state.validations.emailValidating,
    providers: state.providers.data,
    providersLoading: state.providers.loading,
    templateError: state.broadcasts.templateError,
    templateLoading: state.broadcasts.templateLoading,
    templatePreview: state.broadcasts.preview,
    templatePreviewError: state.broadcasts.previewLoadError,
    templatePreviewLoading: state.broadcasts.previewLoading,
    templates: state.broadcasts.templates,
    templateSaveError: state.broadcasts.templateSaveError,
    templateSaving: state.broadcasts.templateSaving,
    users: state.externalUsers.data,
    usersLoading: state.externalUsers.loading,
  }),
  {
    clearEmailValidation,
    clearPreview,
    clearUsers,
    loadBroadcasts,
    loadConnections,
    loadPreview,
    loadProviders,
    loadTemplate,
    loadUsers,
    saveTemplate,
    validateEmail,
  },
);

type Props = typeof connector['props'];
type Template = Props['broadcasts'][0]['templates'][0];

const S = StyleUtil({
  sliderLabel: {
    fontSize: 13,
    fontWeight: 700,
    color: '#000',
    lineHeight: 1,
    marginTop: 0,
    marginBottom: 20,
  },
  // dndSearch: {
  //   width: '100%',
  // },

  channelEdit: {
    marginRight: 8,
  },
  channelEdit_disabled: {
    opacity: 0,
    cursor: 'default',
  },
  templateSection: {
    marginTop: 40,
  },
  templateSectionHeader: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 5,
  },
  templateSectionCopy: {
    marginTop: 0,
    marginBottom: 10,
  },

  signInButton: {
    background: 'none',
    border: '1px solid #9b9b9b',
    borderRadius: 3,
    color: '#4a4a4a',
    fontSize: 12,
    fontWeight: 700,
    height: 32,
    marginLeft: 30,
    width: 246,
    cursor: 'pointer',
  },
  signInButtonImg: {
    verticalAlign: 'middle',
    marginRight: 10,
    height: 14,
    width: 'auto',
    filter: 'grayscale(1)',
  },
  signInButtonText: {
    verticalAlign: 'middle',
  },
});

interface State {
  broadcastId: string;
  dirtySections: { [sectionName: string]: Props['templates']['foo']['foo']['sections'][0] };
  messageId: string;
  previewTitle: string;
  showEmailEditModal: boolean;
  selectedProviderType: string;
  sendgridFromUser: string;
  sendgridFromUserError: boolean;
  templatesOpen: boolean;
  tonePrompt: string;
}

@connector
export default class Hyperbot extends React.Component<Props & SettingsProps, Partial<State>> {
  private userTimeout: number;

  constructor(props) {
    super(props);
    this.state = {
      templatesOpen: false,
      showEmailEditModal: !!getFrom(parse(this.props.location.search))('showEmail').value,
    };
  }

  componentDidMount() {
    const { connectionsLoading, loadBroadcasts, loadConnections, loadScopedSettings, currentAudienceId } = this.props;
    if (!connectionsLoading) {
      loadConnections();
    }
    loadBroadcasts(currentAudienceId);

    loadScopedSettings([
      keys.global.readOnlyMode,
      keys.broadcast.enabled,
      keys.broadcast.botName,
      keys.broadcast.email,
      keys.broadcast.tone,
      keys.broadcast.reminders.frequency,
      keys.broadcast.reminders.limit,
      keys.broadcast.profile.enabled,
      keys.audit.storeProfileData,
      keys.broadcast.profile.runAt,
      keys.broadcast.profile.frequency,
      keys.broadcast.channels.email,
      keys.broadcast.channels.skypeForBusiness,
      keys.broadcast.exclusions,
      keys.inheritance.broadcastTone,
      keys.email.provider,
      keys.email.msGraph.adminConsent,
      keys.email.msGraph.fromUser,
      keys.email.sendGrid.fromUser,
    ]);

    // this.props.router.setRouteLeaveHook(this.props.route, () => {
    //   if (this.props.settingsDirty) {
    //     return 'You have unsaved changes. Are you sure you want to leave?';
    //   }
    // });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props & SettingsProps) {
    if (this.props.currentAudienceId !== nextProps.currentAudienceId) {
      this.props.loadBroadcasts(nextProps.currentAudienceId);
      this.setState({ templatesOpen: false });
    }
  }

  componentWillUnmount() {
    this.props.handleCancel();
  }

  handleTemplateEdit = (broadcast: Props['broadcasts'][0], template: Template) => {
    const {
      loadTemplate,
      currentAudienceId,
      settings: { broadcast_tone },
    } = this.props;
    const messageId = broadcast_tone === 'custom' ? template.id : template.messageType;
    this.setState({ broadcastId: broadcast.id, messageId, dirtySections: {} });
    loadTemplate(broadcast.id, messageId, currentAudienceId, broadcast_tone);
  };

  handleTemplatePreview = (broadcast: Props['broadcasts'][0], template: Template) => {
    const {
      loadPreview,
      currentAudienceId,
      settings: { broadcast_tone },
    } = this.props;
    const messageId = broadcast_tone === 'custom' ? template.id : template.messageType;
    const previewTitle = `${broadcast.displayName} - ${template.displayName}`;
    this.setState({ previewTitle });
    loadPreview(broadcast.id, messageId, currentAudienceId, broadcast_tone);
  };

  loadUsers = (search?: string) => {
    if (this.userTimeout) {
      window.clearTimeout(this.userTimeout);
    }
    if (!search) {
      this.props.clearUsers();
      return search;
    }

    this.userTimeout = window.setTimeout(() => {
      this.props.loadUsers(search, 4);
    }, 500);

    return search;
  };

  getStateFromProvider = () => {
    if (this.props.settings.email_provider == 'MsGraph') return 'msgraph';
    if (this.props.settings.email_sendGrid_fromUser == HYPERBOT_DEFAULT_EMAIL_ADDRESS) return 'sendgrid-hyperbot';
    return 'sendgrid-custom';
  };

  showEmailEditModal = () => {
    if (this.props.settings.email_msGraph_adminConsent === false) {
      this.props.loadProviders(
        getFrom(this.props.currentOrg)('id').defaultTo(null),
        '/settings/hyperbot?showEmail=true',
        true,
      );
    }
    this.props.clearEmailValidation();
    this.setState({
      selectedProviderType: this.getStateFromProvider(),
      sendgridFromUser: this.props.settings.email_sendGrid_fromUser,
    });
    this.setState({ showEmailEditModal: true });
  };

  cancelEmailEditModal = () => {
    const { update, currentOrg } = this.props;
    const cleanSettings = this.props.cleanSettings[currentOrg.id];

    if (cleanSettings != null) {
      [keys.email.provider, keys.email.msGraph.adminConsent, keys.email.msGraph.fromUser].forEach(
        (key: keyof OrgSettings) => update(currentOrg.id, key, cleanSettings[key]),
      );
    }

    this.setState({ showEmailEditModal: false });
  };

  render() {
    const {
      cleanSettings,
      connections,
      connectionsLoading,
      currentAudienceId,
      currentOrg,
      currentOrgLoading,
      isFree,
      isMaster,
      settings,
      settingsLoading,
      showPremiumModal,
      update,
      usersLoading,
      licenseValidation,
    } = this.props;

    const repeatsEveryOptions = [
      { label: 'Never', value: '' },
      { label: 'Month', value: 'P1M' },
      { label: 'Two Months', value: 'P2M' },
      { label: 'Three Months', value: 'P3M' },
      { label: 'Four Months', value: 'P4M' },
      { label: 'Five Months', value: 'P5M' },
      { label: 'Six Months', value: 'P6M' },
      { label: 'Seven Months', value: 'P7M' },
      { label: 'Eight Months', value: 'P8M' },
      { label: 'Nine Months', value: 'P9M' },
      { label: 'Ten Months', value: 'P10M' },
      { label: 'Eleven Months', value: 'P11M' },
      { label: 'Twelve Months', value: 'P12M' },
    ];

    if (!settings || settingsLoading || currentOrgLoading || connectionsLoading) {
      return <LoadingSplash />;
    }

    if (settings.read_only_mode) {
      return (
        <Card>
          <h2>I&apos;m sorry, I&apos;m afraid I can&apos;t do that.</h2>
          <p>Your organization is currently in read-only mode.</p>
          <p>
            While in read-only mode, LiveTiles Directory does not make any updates to your directory, so change hyperbot
            functionality is disabled.
          </p>
        </Card>
      );
    }

    return (
      <div className="view-settings-Hyperbot">
        {
          <div>
            {isMaster && (
              <SettingCard
                title="Hyperbot"
                subTitle="Hyperbot is your personal assistant that does the dirty work of gathering information from users. You control everything with some quick settings."
              >
                <HyperForm
                  fields={[
                    {
                      label: 'Off/On',
                      type: 'toggle',
                      value: settings.broadcast_enabled,
                      onChange: e => update(currentAudienceId, keys.broadcast.enabled, e.target['checked']),
                    },
                  ]}
                />
              </SettingCard>
            )}
            {isMaster && (
              <SettingCard
                title="Name"
                subTitle="Give your Hyperbot a personality with a friendly name. Users will see this name in correspondence."
              >
                <HyperForm
                  onInvalidChange={this.props.handleFormInvalidChange}
                  onMount={this.props.handleFormMount}
                  onUnmount={this.props.handleFormUnmount}
                  showPremiumModal={this.props.showPremiumModal}
                  fields={[
                    fields.text({
                      label: 'Name',
                      type: 'text',
                      required: true,
                      value: settings.broadcast_botName,
                      onChange: e => update(currentAudienceId, keys.broadcast.botName, e.currentTarget.value),
                    }),
                  ]}
                />
              </SettingCard>
            )}
            {isMaster && (
              <SettingCard
                title="Contact Channels"
                subTitle="The contact method(s) that Hyperbot will use to contact users with missing or incorrect information."
              >
                <HyperForm
                  fields={[
                    fields.custom({
                      label: 'Email',
                      id: 'contact_channels_email',
                      customInput: (
                        <div>
                          <IconButton
                            css={undefined}
                            ariaLabel="Edit Channel"
                            icon={<FiEdit2 />}
                            color="accent"
                            onClick={this.showEmailEditModal}
                            style={S.channelEdit}
                          />
                          <Toggle
                            id="contact_channels_email_input"
                            checked={(settings.broadcast_channels_email || '').toLowerCase() === 'active'}
                            onChange={e =>
                              update(
                                currentAudienceId,
                                keys.broadcast.channels.email,
                                e.currentTarget.checked ? 'Active' : 'Inactive',
                              )
                            }
                          />
                        </div>
                      ),
                    }),
                    /*fields.custom({
                      label: 'Skype for Business (Temporarily Disabled)',
                      id: 'contact_channels_skype_for_business',
                      customInput: (
                        <div>
                          <IconButton
                            css={undefined}
                            ariaLabel="Edit Channel"
                            icon={<FiEdit2 />}
                            color="accent"
                            style={{ ...S.channelEdit, ...S.channelEdit_disabled }}
                          />
                          <Tooltip
                            content="For technical reasons, this feature is currently unavailable."
                            placement="top-end"
                          >
                            <Toggle
                              id="contact_channels_skype_for_business_input"
                              checked={(settings.broadcast_channels_skypeForBusiness || '').toLowerCase() === 'active'}
                              disabled
                              // onChange={e =>
                              //   update(
                              //     currentAudienceId,
                              //     keys.broadcast.channels.skypeForBusiness,
                              //     e.currentTarget.checked ? 'Active' : 'Inactive',
                              //   )
                              // }
                            />
                          </Tooltip>
                        </div>
                      ),
                    }),*/
                  ]}
                />
              </SettingCard>
            )}
            <SettingCard
              title="Personality"
              subTitle="The personality you give your Hyperbot will determine the tone of the interactions with users."
              override={isMaster ? null : !settings.inheritance_broadcast_tone}
              onChangeOverride={e => {
                update(currentAudienceId, keys.broadcast.tone, cleanSettings[currentOrg.id].broadcast_tone);
                update(currentAudienceId, keys.inheritance.broadcastTone, !e.currentTarget.checked);
              }}
              groupedCardContent={this.renderEmailTemplates()}
            >
              <Slider
                value={settings.broadcast_tone}
                showPremiumModal={this.props.showPremiumModal}
                onChange={val => {
                  if (settings.broadcast_tone === 'custom') {
                    this.setState({ tonePrompt: val as string });
                  } else {
                    update(currentAudienceId, keys.broadcast.tone, val);
                  }
                }}
                options={[
                  {
                    value: 'fun',
                    label: 'Relaxed',
                    icon: <img src={require('./icons/relaxed-icon.svg')} />,
                    premiumLock: isFree ? ModalKeys.hyperbotPersonality : null,
                  },
                  { value: 'casual', label: 'Standard', icon: <img src={require('./icons/standard-icon.svg')} /> },
                  {
                    value: 'professional',
                    label: 'Formal',
                    icon: <img src={require('./icons/formal-icon.svg')} />,
                    premiumLock: isFree ? ModalKeys.hyperbotPersonality : null,
                  },
                ].concat(
                  !cleanSettings[currentAudienceId] || cleanSettings[currentAudienceId].broadcast_tone !== 'custom'
                    ? []
                    : [{ value: 'custom', label: 'Custom', icon: <img src={require('./icons/custom-icon.svg')} /> }],
                )}
              />
            </SettingCard>
            {isMaster && (
              <SettingCard
                title="Tenacity"
                subTitle="Choose the how frequently Hyperbot contacts users to collect or confirm information regarding Profile validation and Violation reminders. When these settings are changed, the next contact will be calculated off the last time Hyperbot contacted an individual user."
                groupedCardContent={
                  <div>
                    <p style={S.sliderLabel}>
                      Attempts
                      {isFree && (
                        <PremiumLock style={{ marginLeft: 5 }} onClick={() => showPremiumModal(ModalKeys.attempts)} />
                      )}
                    </p>
                    <Slider
                      value={settings.broadcast_reminders_limit}
                      onChange={val => update(currentAudienceId, keys.broadcast.reminders.limit, val)}
                      options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, null].map(value => ({
                        value,
                        label: value === null ? 'Unlimited' : value.toString(),
                      }))}
                      disabled={isFree}
                    />
                  </div>
                }
              >
                <p style={S.sliderLabel}>Frequency</p>
                <Slider
                  value={settings.broadcast_reminders_frequency}
                  onChange={val => update(currentAudienceId, keys.broadcast.reminders.frequency, val)}
                  options={[
                    { value: 'P1D', label: 'Daily', icon: <img src={require('./icons/frequency-more-icon.svg')} /> },
                    {
                      value: 'P2D',
                      label: 'Every other day',
                      icon: <img src={require('./icons/frequency-mid-icon.svg')} />,
                    },
                    { value: 'P1W', label: 'Weekly', icon: <img src={require('./icons/frequency-less-icon.svg')} /> },
                  ]}
                />
              </SettingCard>
            )}
            {isMaster &&
              ((currentOrg && currentOrg.type === 'Online') ||
                (connections || []).filter(c =>
                  AtLeastVersion('1.4.2.0', ((c as DeviceDetails).lastHeartbeat || {})['version']),
                ).length > 0) && (
                <SettingCard
                  title="Profile Validation"
                  subTitle="Profile validation contacts users to ask them to validate existing profile information. Users will be contacted at the configured date and time, with the option of repeating the check at a chosen interval."
                >
                  <HyperForm
                    showPremiumModal={this.props.showPremiumModal}
                    fields={[
                      fields.toggle({
                        label: 'Off/On',
                        value: settings.broadcast_profile_enabled,
                        premiumLock: isFree ? ModalKeys.profileValidation : null,
                        disabled: !settings.audit_storeProfileData || !licenseValidation,
                        hintText: !!settings.audit_storeProfileData ? null : (
                          <span>
                            Please <Link to="/settings/general">enable advanced profile features</Link> to enable
                            profile validation.
                          </span>
                        ),
                        onChange: e => {
                          const checked = e.currentTarget.checked;
                          update(currentAudienceId, keys.broadcast.profile.enabled, checked);
                          if (checked) {
                            update(
                              currentAudienceId,
                              keys.broadcast.profile.runAt,
                              moment()
                                .add(1, 'hour')
                                .toISOString(),
                            );
                          }
                        },
                      }),
                      settings.broadcast_profile_enabled &&
                        fields.dateTime({
                          label: 'Scheduled For',
                          required: true,
                          props: { isValidDate: current => current.isAfter(moment().subtract(1, 'day')) },
                          value: applyDSTforSchedules(moment(settings.broadcast_profile_runAt, moment.ISO_8601)),
                          onChange: value => {
                            let newDate;
                            if (!value || value === '') {
                              newDate = '';
                            } else if (moment.isMoment(value)) {
                              newDate = applyDSTforSchedulesReverse(value).toISOString();
                            } else {
                              return;
                            }
                            update(currentAudienceId, keys.broadcast.profile.runAt, newDate);
                          },
                        }),
                      settings.broadcast_profile_enabled &&
                        fields.dropdown({
                          label: 'Repeats Every',
                          value: repeatsEveryOptions.filter(o => o.value === settings.broadcast_profile_frequency)[0],
                          onChange: v => update(currentAudienceId, keys.broadcast.profile.frequency, v.value),
                          required: true,
                          dropdownOptions: repeatsEveryOptions,
                        }),
                    ]}
                  />
                </SettingCard>
              )}
            {isMaster && (
              <SettingCard
                title="Do Not Disturb"
                subTitle="If you have users who should never be contacted by Hyperbot, add them here."
              >
                <div style={StyleUtil.styles.tables.header_noFlex}>
                  <PeoplePicker
                    isLoading={usersLoading}
                    isMulti={false}
                    value={[] as any}
                    onChange={option => {
                      if (option == null) {
                        return;
                      }

                      const user = this.props.usersById[option.value];
                      const address = getFrom(user)('mail').value;
                      if (address == null) {
                        return;
                      }

                      update(
                        currentAudienceId,
                        keys.broadcast.exclusions,
                        settings.broadcast_exclusions.concat([{ type: 'EMAIL', address }]),
                      );
                    }}
                    clearUsers={this.props.clearUsers}
                    loadUsers={this.props.loadUsers}
                    users={this.props.users}
                    disabledUserIds={
                      this.props.users &&
                      (this.props.users.profileType === 'msGraph'
                        ? this.props.users.profiles
                            .filter(
                              u => (settings.broadcast_exclusions || []).filter(e => e.address === u.mail).length > 0,
                            )
                            .map(u => u.id)
                        : this.props.users.profiles
                            .filter(
                              u => (settings.broadcast_exclusions || []).filter(e => e.address === u.mail).length > 0,
                            )
                            .map(u => u.objectguid))
                    }
                  />
                </div>
                <DndTable
                  noDataMessage="No users"
                  data={
                    settings.broadcast_exclusions &&
                    settings.broadcast_exclusions.map((e, i) => ({
                      id: `${i}_${e.type}_${e.address}`,
                      ...e,
                    }))
                  }
                  onDelete={e => {
                    update(
                      currentAudienceId,
                      keys.broadcast.exclusions,
                      (settings.broadcast_exclusions || []).filter(({ address }) => address !== e.address),
                    );
                  }}
                />
              </SettingCard>
            )}
          </div>
        }
        {!!this.state.broadcastId && !!this.state.messageId && this.renderTemplateEditModal()}
        {!!this.state.previewTitle && this.renderTemplatePreviewModal()}
        {!!this.state.tonePrompt && this.renderTonePromptModal()}
        {!!this.state.showEmailEditModal && this.renderEmailEditModal()}
      </div>
    );
  }

  renderEmailEditModal() {
    const {
      currentAudienceId,
      currentOrg,
      emailError,
      emailValidating,
      providers,
      providersLoading,
      settings,
      settingsLoading,
      update,
    } = this.props;

    if (settingsLoading || !settings) {
      return (
        <Modal onClose={() => this.setState({ showEmailEditModal: false })}>
          <LoadingSplash />
        </Modal>
      );
    }

    const getProviderFromState = () => {
      return this.state.selectedProviderType == 'msgraph' ? 'MsGraph' : 'Sendgrid';
    };

    // const dirtySettings = this.props.dirtySettings[currentOrg.id];
    // const isFromUserDirty = dirtySettings != null && dirtySettings.email_msGraph_fromUser != null;

    const userDropOptions = !this.props.users
      ? settings.email_msGraph_fromUser
        ? [{ value: settings.email_msGraph_fromUser, label: settings.email_msGraph_fromUser }]
        : null
      : this.props.users.profileType === 'msGraph'
      ? this.props.users.profiles.map(u => ({
          value: u.userPrincipalName,
          label: u.userPrincipalName,
        }))
      : this.props.users.profiles.map(u => ({
          value: u.userprincipalname,
          label: u.userprincipalname,
        }));

    return (
      <Modal onClose={this.cancelEmailEditModal}>
        <Modal.Header>Email Options</Modal.Header>
        <RadioGroup
          value={this.state.selectedProviderType}
          onChange={value => {
            this.setState({
              selectedProviderType: value,
              sendgridFromUser: value == 'sendgrid-hyperbot' ? HYPERBOT_DEFAULT_EMAIL_ADDRESS : '',
            });
          }}
          options={[
            { label: 'Send from ' + HYPERBOT_DEFAULT_EMAIL_ADDRESS, value: 'sendgrid-hyperbot' },
            { label: 'Send using Sendgrid from custom email account', value: 'sendgrid-custom' },
            { label: 'Send using MsGraph from existing email account.', value: 'msgraph' },
          ]}
        />
        {getProviderFromState() === 'MsGraph' &&
          (settings.email_msGraph_adminConsent === false ? (
            providersLoading || !providers ? (
              <LoadingSplash />
            ) : (
              providers
                .filter(p => p.name === AZURE_EMAIL)
                .map(provider => (
                  <button
                    key={provider.name}
                    style={S.signInButton}
                    onClick={() =>
                      (location.href = `${provider.href.replace(
                        '/common/',
                        `/${currentOrg.remoteTenant}/`,
                      )}&prompt=consent}`)
                    }
                  >
                    <img
                      style={S.signInButtonImg}
                      src="https://hyperfish.blob.core.windows.net/files/office365.png"
                      alt={provider.name + ' Icon'}
                    />
                    <span style={S.signInButtonText}>SIGN IN WITH OFFICE 365</span>
                  </button>
                ))
            )
          ) : (
            <Select
              isLoading={this.props.usersLoading}
              onInputChange={q => {
                if (q) {
                  this.props.loadUsers(q, 4);
                } else {
                  this.props.clearUsers();
                }
                return q;
              }}
              onFocus={() => this.props.clearUsers()}
              placeholder="Search for User"
              noOptionsMessage={() => {
                if (this.props.usersLoading) {
                  return 'Searching...';
                }
                if (Array.isArray(this.props.users)) {
                  return 'No users found';
                }
                return '';
              }}
              filterOption={() => true}
              value={userDropOptions && userDropOptions.filter(o => o.value === settings.email_msGraph_fromUser)}
              onChange={(o: Option) => {
                update(currentAudienceId, keys.email.msGraph.fromUser, o ? o.value : null);
                if (o !== null) {
                  this.props.validateEmail(o.value as string);
                } else {
                  this.props.clearEmailValidation();
                }
              }}
              className={emailError ? 'Select--invalid' : null}
              // clearable={false}
              options={userDropOptions || []}
            />
          ))}
        {this.state.selectedProviderType == 'sendgrid-custom' && (
          <div>
            <TextField
              value={this.state.sendgridFromUser}
              invalid={false}
              onChange={e => {
                this.setState({ sendgridFromUser: e.currentTarget.value });
                var valid = isEmail(e.currentTarget.value);
                this.setState({ sendgridFromUserError: !valid });
              }}
            />
            <span style={{ fontSize: 'small' }}>
              A few DNS configurations must be done when using Sendgrid with custom email account. You can get more
              information <a href="#">here</a>
            </span>
          </div>
        )}
        <Modal.ButtonContainer>
          <Button size="medium" onClick={this.cancelEmailEditModal}>
            Cancel
          </Button>
          <Button
            variant="solid"
            color="primary"
            size="medium"
            onClick={() => {
              update(currentAudienceId, keys.email.sendGrid.fromUser, this.state.sendgridFromUser);
              update(currentAudienceId, keys.email.provider, getProviderFromState());
              this.setState({ showEmailEditModal: false });
            }}
            icon={
              emailValidating ? (
                <FiLoader className="animate-spin" />
              ) : getProviderFromState() === 'MsGraph' && (!!emailError || !settings.email_msGraph_fromUser) ? (
                <FiAlertTriangle />
              ) : null
            }
            disabled={
              (getProviderFromState() === 'MsGraph' &&
                (emailValidating || !!emailError || !settings.email_msGraph_fromUser)) ||
              (getProviderFromState() === 'Sendgrid' &&
                (!this.state.sendgridFromUser || !!this.state.sendgridFromUserError))
            }
          >
            {emailValidating
              ? 'VALIDATING'
              : (getProviderFromState() === 'Sendgrid' && !!this.state.sendgridFromUserError) ||
                (getProviderFromState() === 'MsGraph' && !!emailError)
              ? 'INVALID ACCOUNT'
              : (getProviderFromState() === 'Sendgrid' && !this.state.sendgridFromUser) ||
                (getProviderFromState() === 'MsGraph' && !settings.email_msGraph_fromUser)
              ? 'ACCOUNT REQUIRED'
              : 'DONE'}
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  renderEmailTemplates() {
    const {
      broadcasts,
      broadcastsError,
      broadcastsLoading,
      cleanSettings,
      currentAudienceId,
      isMaster,
      settings,
      settingsLoading,
      isFree,
      showPremiumModal,
    } = this.props;
    const { templatesOpen } = this.state;

    let content;
    if (!templatesOpen) {
      content = null;
    } else if (broadcastsLoading || settingsLoading || !settings || !cleanSettings[currentAudienceId]) {
      content = <LoadingSplash />;
    } else if (!!broadcastsError) {
      content = <p>We weren&apos;t able to load your templates. Please try again later.</p>;
    } else if (!broadcasts || broadcasts.length === 0) {
      content = <p>We weren&apos;t able to find any templates! Please let us know if this persists.</p>;
    } else {
      const inherited =
        !isMaster &&
        !settings.inheritance_broadcast_tone &&
        cleanSettings[currentAudienceId].inheritance_broadcast_tone;
      content = (
        <div>
          {inherited && (
            <p style={StyleUtil.styles.forms.validationText}>Please save override before editing templates.</p>
          )}
          {broadcasts &&
            broadcasts
              .filter(b => b.templates && b.templates.length > 0)
              .map(b => (
                <EmailContentTable
                  key={b.id}
                  broadcast={b}
                  editDisabled={inherited || isFree}
                  onEdit={this.handleTemplateEdit}
                  onPreview={this.handleTemplatePreview}
                />
              ))}
        </div>
      );
    }

    return (
      <section style={StyleUtil.styles.settings.section}>
        <button
          style={StyleUtil.styles.settings.sectionHeader}
          onClick={() => this.setState(state => ({ templatesOpen: !state.templatesOpen }))}
        >
          <h2 style={StyleUtil.styles.settings.sectionHeaderText}>
            Email Templates
            {isFree && (
              <PremiumLock style={{ marginLeft: 5 }} onClick={() => showPremiumModal(ModalKeys.emailCustomization)} />
            )}
          </h2>
          <span style={StyleUtil.styles.settings.sectionHeaderChevron}>
            <Icon name={templatesOpen ? 'chevron-up' : 'chevron-down'} />
          </span>
        </button>
        {content}
      </section>
    );
  }

  renderTemplateEditModal() {
    const {
      broadcasts,
      clearPreview,
      currentAudienceId,
      loadPreview,
      saveTemplate,
      templateError,
      templateLoading,
      templatePreview,
      templatePreviewLoading,
      templates,
      templateSaving,
      settings,
    } = this.props;
    const { broadcastId, messageId, dirtySections } = this.state;
    const broadcast = broadcasts.filter(({ id }) => id === broadcastId)[0];
    const template = getFrom(templates)(broadcastId)(messageId).value;
    const loading = getFrom(templateLoading)(broadcastId)(messageId).value;
    const error = getFrom(templateError)(broadcastId)(messageId).value;
    const saving = getFrom(templateSaving)(broadcastId)(messageId).value;
    const handleClose = () => {
      clearPreview();
      this.setState({ broadcastId: null, messageId: null, dirtySections: null });
    };

    if (error) {
      return (
        <Modal onClose={handleClose}>
          <Modal.Header>Well, that&apos;s not good...</Modal.Header>
          <p>Failed to load template. This error has been reported to our engineers. Please try again later!</p>
        </Modal>
      );
    }

    if (!template || loading) {
      return (
        <Modal onClose={handleClose}>
          <LoadingSplash />
        </Modal>
      );
    }

    const content = !!templatePreview ? (
      <TemplatePreview templatePreview={templatePreview} />
    ) : (
      template.sections.map(s => (
        <div key={s.name} style={S.templateSection}>
          <h3 style={S.templateSectionHeader}>{s.displayName}</h3>
          <p style={S.templateSectionCopy}>{s.description}</p>
          <TextField
            value={(dirtySections[s.name] || s).value}
            longLines={s.lineCount}
            invalid={(dirtySections[s.name] || s).value.indexOf('<script') > -1}
            onChange={e => {
              const value = e.currentTarget.value;
              this.setState(state => ({
                dirtySections: {
                  ...state.dirtySections,
                  [s.name]: {
                    ...s,
                    ...state.dirtySections[s.name],
                    value,
                  },
                },
              }));
            }}
          />
          {(dirtySections[s.name] || s).value.indexOf('<script') > -1 && (
            <p style={{ color: 'red' }}>HTML Script tags (&lt;script&gt;) are not allowed.</p>
          )}
        </div>
      ))
    );

    return (
      <Modal onClose={handleClose}>
        <Modal.Header>
          {broadcast.displayName} - {template.displayName}
        </Modal.Header>
        {content}
        <Modal.ButtonContainer>
          <Button size="medium" onClick={handleClose} variant="link" color="accent">
            Cancel
          </Button>
          <Button
            size="medium"
            disabled={saving || templatePreviewLoading}
            icon={
              templatePreviewLoading ? (
                <FiLoader className="animate-spin" />
              ) : !!templatePreview ? (
                <FiArrowLeft />
              ) : (
                <FiEye />
              )
            }
            onClick={
              !!templatePreview
                ? () => clearPreview()
                : () => {
                    const sections = Object.keys(dirtySections).map(key => dirtySections[key]);
                    loadPreview(broadcastId, messageId, currentAudienceId, settings.broadcast_tone, { sections });
                  }
            }
          >
            {!!templatePreview ? 'BACK' : 'PREVIEW'}
          </Button>
          <Button
            color="primary"
            variant="solid"
            size="medium"
            disabled={
              saving ||
              templatePreviewLoading ||
              Object.keys(dirtySections).length === 0 ||
              Object.keys(dirtySections)
                .map(key => dirtySections[key])
                .filter(({ value }) => value.indexOf('<script') > -1).length > 0
            }
            icon={saving ? <FiLoader className="animate-spin" /> : undefined}
            onClick={() => {
              const sections = Object.keys(dirtySections).map(key => dirtySections[key]);
              (saveTemplate(broadcastId, messageId, currentAudienceId, settings.broadcast_tone, {
                sections,
              }) as any).then(handleClose);
            }}
          >
            SAVE
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  renderTemplatePreviewModal() {
    const { clearPreview, templatePreviewLoading } = this.props;
    const { previewTitle } = this.state;
    const handleClose = () => {
      clearPreview();
      this.setState({ previewTitle: null });
    };

    return (
      <Modal onClose={handleClose}>
        <Modal.Header>{previewTitle}</Modal.Header>
        {templatePreviewLoading ? <LoadingSplash /> : <TemplatePreview templatePreview={this.props.templatePreview} />}
        <Modal.ButtonContainer>
          <Button size="medium" color="primary" variant="solid" onClick={handleClose}>
            CLOSE
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  renderTonePromptModal() {
    const { currentAudienceId, update, isMaster } = this.props;
    const { tonePrompt } = this.state;
    const handleClose = () => this.setState({ tonePrompt: null });

    return (
      <Modal onClose={handleClose}>
        <Modal.Header>Are you sure?</Modal.Header>
        <p>
          {isMaster
            ? 'If you change the Hyperbot Personality to something other than Custom, the email template edits will be lost.'
            : 'If you change the Hyperbot Personality to something other than Custom, the email template edits for the Collection will be lost.'}
        </p>
        <Modal.ButtonContainer>
          <Button size="medium" style={StyleUtil.styles.modals.spacedBtn} onClick={handleClose}>
            Nevermind
          </Button>
          <Button
            color="primary"
            variant="solid"
            size="medium"
            onClick={() => {
              update(currentAudienceId, keys.broadcast.tone, tonePrompt);
              handleClose();
            }}
          >
            I&apos;M SURE
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }
}
