import { Card, Toggle, ToggleProps } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';
import { Ref } from 'react';

import StyleUtil from '../utils/StyleUtil';

interface Props {
  onChangeOverride?: ToggleProps['onChange'];
  override?: boolean;
  subTitle?: string | React.ReactNode;
  title?: string;
  groupedCardContent?: any;
  cardStyle?: React.CSSProperties;
  sectionRef?: Ref<HTMLElement>;
  isDisabled?: boolean;
  id?: string;
  noCard?: boolean;
}

const styles = StyleUtil({
  container: {
    display: 'flex',
    marginBottom: 40,
  },
  header: {
    width: 250,
    flex: '0 0 250px',
    marginRight: 40,
  },
  h1: {
    fontSize: 30,
    color: StyleUtil.colors.blue,
    margin: '0 0 10px',
  },
  subTitle: {
    fontSize: 13,
    lineHeight: '20px',
    color: StyleUtil.colors.text.dark,
  },
  cardContainer: {
    flex: '1 1',
    position: 'relative',
  },
  card: {
    marginBottom: 0,
    position: 'relative',
  },
  cardExtra: {
    marginBottom: 0,
    marginTop: 5,
    paddingBottom: 15,
    paddingTop: 15,
    position: 'relative',
  },
  disabledOverlay: {
    backgroundColor: 'rgba(255, 255, 255, .6)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  hr: {
    backgroundColor: '#979797',
    border: 'none',
    height: 1,
    width: '100%',
    margin: ' 20px 0',
  },
  overrideContainer: {
    backgroundColor: '#fff',
    borderRadius: '100px',
    padding: '6px 0 6px 20px',
  },
  overrideLabel: {
    fontSize: 13,
    marginRight: 13,
  },
  overrideToggle: {},
});

class SettingCardComponent extends React.Component<Props> {
  render() {
    const {
      cardStyle,
      children,
      groupedCardContent,
      onChangeOverride,
      override,
      sectionRef,
      subTitle,
      title,
      isDisabled,
      id,
      noCard,
    } = this.props;

    return (
      <section id={id} style={styles.container} className="clearfix" ref={sectionRef}>
        <header style={styles.header}>
          <h1 style={styles.h1}>{title}</h1>
          {subTitle && <span style={styles.subTitle}>{subTitle}</span>}
          {override != null && <hr style={styles.hr} />}
          {override != null && (
            <div style={styles.overrideContainer}>
              <span style={styles.overrideLabel}>Override Master Settings</span>
              <Toggle style={styles.overrideToggle} checked={override} onChange={onChangeOverride} />
            </div>
          )}
        </header>
        <div style={styles.cardContainer}>
          {noCard ? (
            children
          ) : (
            <>
              <Card
                style={{
                  ...styles.card,
                  ...cardStyle,
                }}
              >
                {children}
                {(isDisabled || (override != null && !override)) && <div style={styles.disabledOverlay} />}
              </Card>
              {!!groupedCardContent && (
                <Card style={styles.cardExtra}>
                  {groupedCardContent}
                  {(isDisabled || (override != null && !override)) && <div style={styles.disabledOverlay} />}
                </Card>
              )}
            </>
          )}
        </div>
      </section>
    );
  }
}

export const SettingCard = Radium(SettingCardComponent);
