import cx from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

type Props = JSX.IntrinsicElements['th'] & {
  isCheck?: boolean;
  isCenter?: boolean;
};

export const TableHeader: React.StatelessComponent<Props> = ({ isCheck, isCenter, children, ...rest }) => (
  <th
    {...rest}
    className={cx(classes.TableHeader, rest.className, {
      [classes.isCenter]: isCenter,
      [classes.isCheck]: isCheck,
    })}
  >
    {children}
  </th>
);
