// tslint:disable:only-arrow-functions
import 'd3-transition';

import { OrgSettingsGetResponse, OUSearchResult as DirectoryScope } from '@hyperfish/antrea-api-contracts/src/org';
import { Select } from '@hyperfish/fishfood';
import { HierarchyPointLink, HierarchyPointNode, stratify, tree } from 'd3-hierarchy';
import { event as d3CurrentEvent, select, Selection } from 'd3-selection';
import { zoom, ZoomBehavior, zoomIdentity } from 'd3-zoom';
import React from 'react';

import OUValidator from '../../utils/OUValidationUtil';
import StyleUtil from '../../utils/StyleUtil';
import { HyperfishLogo } from '../HyperfishLogo';

import classes from './styles.module.scss';

interface TreeData {
  id: string;
}

interface Tree {
  [DnString: string]: Tree;
}

interface Props {
  scopes: DirectoryScope[];
  settings: OrgSettingsGetResponse['settings']['foo'];
  onInclude: (OU: string) => void;
  onExclude: (OU: string) => void;
  onClear: (OU: string) => void;
}

interface State {
  rootIndex: number;
  roots: { value: number; label: string }[];
  scopes: Props['scopes'];
  collapsedNodes: { [key: string]: boolean };
}

export class DirectoryTree extends React.Component<Props, State> {
  private transitionDuration = 250;
  private scaleExtent: [number, number] = [0.2, 8];
  private nodeSize: [number, number] = [18, 150];
  private zoomButtonSize = 18;
  private boxHeight = 30;
  private boxWidth = 130;

  private node: SVGSVGElement;
  private svg: Selection<SVGSVGElement, any, null, undefined>;
  private validator: OUValidator;
  private zoomGroup: Selection<SVGGElement, TreeData, null, undefined>;
  private linksGroup: Selection<SVGGElement, TreeData, null, undefined>;
  private nodesGroup: Selection<SVGGElement, TreeData, null, undefined>;
  private inclusions: string[];
  private exclusions: string[];
  private zoom: ZoomBehavior<Element, {}>;
  private activeId: string;
  private tooltipGroup: Selection<SVGGElement, {}, null, undefined>;
  private activeCircle: SVGCircleElement;

  constructor(props) {
    super(props);
    this.state = { rootIndex: 0, roots: null, collapsedNodes: {}, scopes: null };
  }

  componentDidMount() {
    this.updateValidator();
    this.createTree();
  }

  componentDidUpdate() {
    this.updateValidator();
    this.updateTree();
    if (this.activeId) {
      this.placeMenu();
    }
  }

  render() {
    return (
      <div className={classes.container} onClick={this.closeMenu} onContextMenu={this.closeMenu}>
        <svg ref={svg => (this.node = svg)} className={classes.svg} width="100%" height="100%">
          <defs>
            <filter id="shadow">
              {React.createElement('feDropShadow', {
                dx: 1,
                dy: 1,
                stdDeviation: 1,
                floodColor: '#d4d4d4',
              } as any)}
            </filter>
            <filter id="textShadow">
              <feFlood floodColor="white" result="flood" />
              <feOffset dx="0" dy="0.2" in="SourceAlpha" result="offset1" />
              <feComposite operator="in" in="flood" in2="offset1" result="shadow1" />
              <feOffset dx="0" dy="-0.2" in="SourceAlpha" result="offset2" />
              <feComposite operator="in" in="flood" in2="offset2" result="shadow2" />
              <feOffset dx="0.2" dy="0" in="SourceAlpha" result="offset3" />
              <feComposite operator="in" in="flood" in2="offset3" result="shadow3" />
              <feOffset dx="-0.2" dy="0" in="SourceAlpha" result="offset4" />
              <feComposite operator="in" in="flood" in2="offset4" result="shadow4" />
              <feMerge>
                <feMergeNode in="shadow1" />
                <feMergeNode in="shadow2" />
                <feMergeNode in="shadow3" />
                <feMergeNode in="shadow4" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <g className="zoom-group" />
          <rect x="0" y="0" fill="#fff" fillOpacity="0.9" stroke="none" width="165" height="130" />
          <g className="legend" transform="translate(20, 65)">
            <g className={classes.node + ' ' + classes.included}>
              <circle r="2.5" className={classes.ouCircle} />
              <text dy="3" x="8" textAnchor="start">
                Included
              </text>
            </g>
            <g className={classes.node + ' ' + classes.excluded} transform="translate(0, 12)">
              <circle r="2.5" className={classes.ouCircle} />
              <text dy="3" x="8" textAnchor="start">
                Excluded
              </text>
            </g>
            <g className={classes.node} transform="translate(0, 24)">
              <circle
                r={2.5 + 1.2 / 2 + 1.5}
                strokeWidth="1.5"
                stroke={StyleUtil.colors.blue}
                style={{ fill: '#fff' }}
              />
              <text dy="3" x="8" textAnchor="start">
                Scope Setting
              </text>
            </g>
            <line x1="8" y1="34" x2="120" y2="34" stroke="#979797" strokeWidth="0.5" shapeRendering="crispEdges" />
            <g className={classes.node} transform="translate(0, 44)">
              <text dy="3" x="8" textAnchor="start">
                Left click to expand / collapse
              </text>
            </g>
            <g className={classes.node} transform="translate(0, 56)">
              <text dy="3" x="8" textAnchor="start">
                Right click to modify settings
              </text>
            </g>
          </g>
          <g className={classes.tooltipGroup}>
            <g className={classes.menu} transform={`translate(0, -${5 + this.boxHeight})`}>
              <g
                className={classes.exclude}
                transform={`translate(-${1.5 * this.boxWidth}, 0)`}
                onClick={this.handleExclude}
              >
                <path
                  className={classes.button}
                  d={[`M ${this.boxWidth} 0`, `V ${this.boxHeight}`, 'H 15', `A 15 15, 0, 0, 1, 15 0`, 'Z'].join(' ')}
                />
                <text x={this.boxWidth / 2} y={this.boxHeight / 2}>
                  Violation Restriction
                </text>
              </g>
              <g
                className={classes.include}
                transform={`translate(-${0.5 * this.boxWidth}, 0)`}
                onClick={this.handleInclude}
              >
                <rect className={classes.button} width={this.boxWidth} height={this.boxHeight} />
                <text x={this.boxWidth / 2} y={this.boxHeight / 2}>
                  Audit Scope
                </text>
              </g>
              <g
                className={classes.clear}
                transform={`translate(${0.5 * this.boxWidth}, 0)`}
                onClick={this.handleClear}
              >
                <path
                  className={classes.button}
                  d={[
                    'M 0 0',
                    `H ${this.boxWidth - 15}`,
                    `A 15 15, 0, 0, 1, ${this.boxWidth - 15} ${this.boxHeight}`,
                    'H 0',
                    'Z',
                  ].join(' ')}
                />
                <text x={this.boxWidth / 2} y={this.boxHeight / 2}>
                  Inherit
                </text>
              </g>
            </g>
          </g>
        </svg>
        <div className={classes.topStuff}>
          <HyperfishLogo blue={true} height="31px" width="144px" className={classes.logo} />
          {this.state.roots && (
            <Select
              options={this.state.roots}
              value={this.state.roots[this.state.rootIndex]}
              isClearable={false}
              isMulti={false}
              onChange={(o: State['roots'][0]) =>
                this.setState({ collapsedNodes: {}, rootIndex: o ? o.value : 0 }, this.createTree)
              }
              className={classes.Select}
            />
          )}
        </div>
        <div className={classes.zoomControlsContainer}>
          <div className={classes.zoomButtonContainer}>
            <div className={classes.zoomButton_reset} onClick={this.handleZoomResetClick}>
              <svg width={this.zoomButtonSize} height={this.zoomButtonSize * (22 / 18)} viewBox="0 0 18 22">
                <g className={classes.zoomButtonG}>
                  <path d="M10.941 6.258a.288.288 0 0 0-.285.29c0 .161.127.291.285.291l6.123.041c.157 0 .285-.13.285-.29l.04-6.3c0-.16-.128-.29-.285-.29a.288.288 0 0 0-.286.29l-.04 5.594A9.365 9.365 0 0 0 9.59 2.487C4.291 2.49-.002 6.86 0 12.247c.002 5.386 4.294 9.75 9.59 9.753 3.542-.005 6.784-2.024 8.4-5.23a.291.291 0 0 0-.199-.358.283.283 0 0 0-.308.108c-1.521 3.007-4.568 4.897-7.893 4.899-4.983 0-9.023-4.108-9.023-9.175 0-5.068 4.04-9.176 9.023-9.176a8.798 8.798 0 0 1 6.785 3.228l-5.428-.038h-.006z" />
                </g>
              </svg>
            </div>
          </div>
          <div className={classes.zoomButtonContainer}>
            <div className={classes.zoomButton} onClick={this.handleZoomInClick}>
              <svg width={this.zoomButtonSize} height={this.zoomButtonSize} viewBox="0 0 18 18">
                <g className={classes.zoomButtonG}>
                  <path d="M15.284 8.534a7.72 7.72 0 0 1-.032.338l-.01.084a3.074 3.074 0 0 0-.003.02l-.002.012a7.647 7.647 0 0 1-.492 1.806l-.005.012a7.588 7.588 0 0 1-.918 1.591l-.032.043a7.498 7.498 0 0 1-.427.502 7.775 7.775 0 0 1-.54.53c-.203.18-.348.295-.54.435a7.61 7.61 0 0 1-1.413.805c-.018.009-.037.016-.056.024l-.053.022a7.598 7.598 0 0 1-1.725.473l-.084.012-.05.007a8.34 8.34 0 0 1-.515.046 7.707 7.707 0 0 1-1.43-.054l-.012-.002a7.256 7.256 0 0 1-.613-.114 7.65 7.65 0 0 1-1.168-.367l-.048-.02a7.601 7.601 0 0 1-1.591-.917l-.013-.01a7.695 7.695 0 0 1-1.526-1.572 7.584 7.584 0 0 1-.795-1.415l-.006-.016a6.55 6.55 0 0 1-.049-.12 7.606 7.606 0 0 1-.45-1.699L.68 8.932.67 8.876a7.584 7.584 0 0 1-.003-1.774l.01-.084.007-.054a7.774 7.774 0 0 1 .222-1.032 7.608 7.608 0 0 1 .26-.754l.021-.05a8.102 8.102 0 0 1 .225-.479 7.582 7.582 0 0 1 .661-1.065l.034-.045a7.748 7.748 0 0 1 1.106-1.163 7.28 7.28 0 0 1 1.167-.81 7.595 7.595 0 0 1 .725-.356l.016-.007.014-.005A7.57 7.57 0 0 1 7.009.697L7.057.69a7.241 7.241 0 0 1 .62-.048A7.717 7.717 0 0 1 8.89.694l.065.008.08.012a7.682 7.682 0 0 1 1.377.341c.253.088.397.148.618.25.44.203.86.451 1.254.734a7.792 7.792 0 0 1 1.477 1.429l.036.047.053.07a7.621 7.621 0 0 1 .824 1.404l.035.079.031.071a7.625 7.625 0 0 1 .5 1.844c.002.004.005.034.007.048l.011.094a7.68 7.68 0 0 1 .026 1.41m-.162-4.04a7.916 7.916 0 0 0-1.532-2.146A8.032 8.032 0 0 0 8.274.027C6.293-.056 4.336.633 2.82 1.903 1.279 3.194.303 5.053.057 7.04a7.94 7.94 0 0 0 1.397 5.511 8.014 8.014 0 0 0 4.91 3.21 8.042 8.042 0 0 0 5.642-.938 8.017 8.017 0 0 0 3.606-4.648c.546-1.88.356-3.922-.49-5.682" />
                  <path d="M13.155 13.596l1.9 1.896 2.115 2.111.017.018a.312.312 0 0 0 .437-.003.311.311 0 0 0-.002-.436l-1.9-1.896-2.115-2.11-.017-.018a.312.312 0 0 0-.437.002.311.311 0 0 0 .002.436M8.262 11.074V4.91a.312.312 0 0 0-.307-.31.311.311 0 0 0-.31.305v6.164c0 .168.137.31.306.31a.311.311 0 0 0 .31-.305" />
                  <path d="M11.04 7.681H4.87a.312.312 0 0 0-.311.306.311.311 0 0 0 .306.31h6.171c.168 0 .31-.137.311-.305a.311.311 0 0 0-.306-.31" />
                </g>
              </svg>
            </div>
            <svg viewBox={`0 0 ${this.zoomButtonSize} 1`} width={this.zoomButtonSize} height="1">
              <line stroke="#9b9b9b" strokeWidth="1" x="0" y="0" x1="0" y1="0" x2={this.zoomButtonSize} y2="0" />
            </svg>
            <div className={classes.zoomButton} onClick={this.handleZoomOutClick}>
              <svg width={this.zoomButtonSize} height={this.zoomButtonSize} viewBox="0 0 18 18">
                <g className={classes.zoomButtonG}>
                  <path d="M15.284 8.534a7.72 7.72 0 0 1-.032.338l-.01.084a3.074 3.074 0 0 0-.003.02l-.002.012a7.647 7.647 0 0 1-.492 1.806l-.005.012a7.588 7.588 0 0 1-.918 1.591l-.032.043a7.498 7.498 0 0 1-.427.502 7.775 7.775 0 0 1-.54.53c-.203.18-.348.295-.54.435a7.61 7.61 0 0 1-1.413.805c-.018.009-.037.016-.056.024l-.053.022a7.598 7.598 0 0 1-1.725.473l-.084.012-.05.007a8.34 8.34 0 0 1-.515.046 7.707 7.707 0 0 1-1.43-.054l-.012-.002a7.256 7.256 0 0 1-.613-.114 7.65 7.65 0 0 1-1.168-.367l-.048-.02a7.601 7.601 0 0 1-1.591-.917l-.013-.01a7.695 7.695 0 0 1-1.526-1.572 7.584 7.584 0 0 1-.795-1.415l-.006-.016a6.55 6.55 0 0 1-.049-.12 7.606 7.606 0 0 1-.45-1.699L.68 8.932.67 8.876a7.584 7.584 0 0 1-.003-1.774l.01-.084.007-.054a7.774 7.774 0 0 1 .222-1.032 7.608 7.608 0 0 1 .26-.754l.021-.05a8.102 8.102 0 0 1 .225-.479 7.582 7.582 0 0 1 .661-1.065l.034-.045a7.748 7.748 0 0 1 1.106-1.163 7.28 7.28 0 0 1 1.167-.81 7.595 7.595 0 0 1 .725-.356l.016-.007.014-.005A7.57 7.57 0 0 1 7.009.697L7.057.69a7.241 7.241 0 0 1 .62-.048A7.717 7.717 0 0 1 8.89.694l.065.008.08.012a7.682 7.682 0 0 1 1.377.341c.253.088.397.148.618.25.44.203.86.451 1.254.734a7.792 7.792 0 0 1 1.477 1.429l.036.047.053.07a7.621 7.621 0 0 1 .824 1.404l.035.079.031.071a7.625 7.625 0 0 1 .5 1.844c.002.004.005.034.007.048l.011.094a7.68 7.68 0 0 1 .026 1.41m-.162-4.04a7.916 7.916 0 0 0-1.532-2.146A8.032 8.032 0 0 0 8.274.027C6.293-.056 4.336.633 2.82 1.903 1.279 3.194.303 5.053.057 7.04a7.94 7.94 0 0 0 1.397 5.511 8.014 8.014 0 0 0 4.91 3.21 8.042 8.042 0 0 0 5.642-.938 8.017 8.017 0 0 0 3.606-4.648c.546-1.88.356-3.922-.49-5.682" />
                  <path d="M13.155 13.596l1.9 1.896 2.115 2.111.017.018a.312.312 0 0 0 .437-.003.311.311 0 0 0-.002-.436l-1.9-1.896-2.115-2.11-.017-.018a.312.312 0 0 0-.437.002.311.311 0 0 0 .002.436M11.04 7.681H4.87a.312.312 0 0 0-.311.306.311.311 0 0 0 .306.31h6.171c.168 0 .31-.137.311-.305a.311.311 0 0 0-.306-.31" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private updateValidator = () => {
    this.inclusions = (this.props.settings.audit_scopes || []).map(({ distinguishedName }) => distinguishedName);
    this.exclusions = (this.props.settings.audit_exclusions || []).map(({ distinguishedName }) => distinguishedName);
    this.validator = new OUValidator(this.inclusions, this.exclusions);
  };

  private handleExclude = () => {
    this.props.onExclude(this.activeId);
  };

  private handleInclude = () => {
    this.props.onInclude(this.activeId);
  };

  private handleClear = () => {
    this.props.onClear(this.activeId);
  };

  private handleZoomResetClick = () => {
    this.zoom.transform(this.svg.transition().duration(this.transitionDuration), zoomIdentity);
  };

  private handleZoomInClick = () => {
    this.zoomClick(1.5);
  };

  private handleZoomOutClick = () => {
    this.zoomClick(1 / 1.5);
  };

  private zoomClick = (amount: number) => {
    this.zoom.scaleBy(this.svg.transition().duration(this.transitionDuration), amount);
  };

  private handleNodeClick = (d: HierarchyPointNode<TreeData>) => {
    this.setState(state => ({
      collapsedNodes: {
        ...state.collapsedNodes,
        [d.data.id]: !state.collapsedNodes[d.data.id],
      },
    }));
  };

  private openMenu = (datum, index, nodes) => {
    d3CurrentEvent.preventDefault();
    d3CurrentEvent.stopPropagation();
    this.closeMenu();
    const el = nodes[index];
    this.activeCircle = el.querySelector('circle');
    this.tooltipGroup.classed(classes.isOpen, true);
    this.activeId = datum.data.id;
    this.placeMenu();
  };

  private closeMenu = () => {
    this.tooltipGroup.classed(classes.isOpen, false);
  };

  // FROM: https://stackoverflow.com/questions/26049488/how-to-get-absolute-coordinates-of-object-inside-a-g-group/37927466#37927466
  private getRelativeXY(svg: SVGSVGElement, element: SVGCircleElement) {
    const p = svg.createSVGPoint();
    const ctm = element.getCTM();
    p.x = 0;
    p.y = 0;
    return p.matrixTransform(ctm);
  }

  private placeMenu = () => {
    if (this.activeCircle == null) {
      return;
    }
    const p = this.getRelativeXY(this.node, this.activeCircle);
    this.tooltipGroup.attr('transform', `translate(${p.x}, ${p.y})`);
    this.tooltipGroup
      .select(`.${classes.menu}`)
      .classed(classes.isIncluded, () => this.inclusions.indexOf(this.activeId) !== -1)
      .classed(classes.isExcluded, () => this.exclusions.indexOf(this.activeId) !== -1)
      .classed(
        classes.isClear,
        () => this.inclusions.indexOf(this.activeId) === -1 && this.exclusions.indexOf(this.activeId) === -1,
      );
  };

  private zoomed = () => {
    this.zoomGroup.attr('transform', d3CurrentEvent.transform);
    this.placeMenu();
  };

  /**
   * Get the TreeData to render the chart from the given scopes
   * @param scopes Scopes to map to TreeData for rendering
   */
  private getData(scopes: Props['scopes']): TreeData[] {
    let tree = this.generateTree(scopes);
    const data = [];

    const roots = Object.keys(tree);

    if (roots.length > 1) {
      if (scopes !== this.state.scopes) {
        this.setState({
          scopes,
          roots: roots.map((label, value) => ({ value, label: label.split('=')[1] })),
        });
      }
      const root = roots[this.state.rootIndex];
      tree = { [root]: tree[root] };
    }

    this.addData(data, tree);

    return data;
  }

  private addData(data: TreeData[], node: Tree, nodeName = '') {
    if (!!nodeName) {
      data.push({
        id: nodeName,
      });
    }

    for (const property in node) {
      if (node.hasOwnProperty(property)) {
        const childName = `${property}${nodeName ? `,${nodeName}` : ''}`;
        this.addData(data, node[property], childName);
      }
    }
  }

  /**
   * Generate a tree object from given scopes
   * @param scopes Scopes to map to tree object
   */
  private generateTree(scopes: Props['scopes']): Tree {
    const tree = {};

    for (const scope of scopes) {
      this.addTreePath(tree, scope.distinguishedName);
    }

    return tree;
  }

  /**
   * Add a given dnString path to the tree object
   * @param tree Tree to add path to
   * @param dnString dnString to populate into the tree
   */
  private addTreePath(tree: Tree, dnString: string) {
    if (!tree) {
      tree = {};
    }
    const branches = dnString.split(',').reverse();
    let activeNode = tree;
    for (const branch of branches) {
      if (activeNode[branch] == null) {
        activeNode[branch] = {};
      }
      activeNode = activeNode[branch];
    }
  }

  private createTree() {
    const { height } = this.node.getBoundingClientRect();

    this.zoom = zoom()
      .scaleExtent(this.scaleExtent)
      .on('zoom', this.zoomed);

    this.svg = select<SVGSVGElement, TreeData>(this.node).call(this.zoom);

    this.zoomGroup = this.svg.select<SVGGElement>('.zoom-group');
    this.tooltipGroup = this.svg.select<SVGGElement>(`.${classes.tooltipGroup}`);

    this.zoomGroup.selectAll('*').remove();

    const container = this.zoomGroup.append<SVGGElement>('g').attr('transform', `translate(40, ${height / 2})`);

    this.linksGroup = container
      .append<SVGGElement>('g')
      .attr('class', 'links')
      .attr('fill', 'none')
      .attr('stroke', '#979797')
      .attr('stroke-width', 1);

    this.nodesGroup = container.append<SVGGElement>('g').attr('class', 'nodes');

    this.updateTree();
  }

  private updateTree() {
    const { scopes } = this.props;

    const root = stratify<TreeData>()
      .parentId((d: any) => {
        const index = d.id.indexOf(',');
        if (index === -1) {
          return;
        }
        return d.id.substring(d.id.indexOf(',') + 1);
      })(this.getData(scopes))
      .sort((a, b) => a.height - b.height || a.id.localeCompare(b.id));

    root.descendants().forEach(d => {
      d.children = this.state.collapsedNodes[d.data.id] ? null : d.children;
    });

    const dataTree = tree<TreeData>().nodeSize(this.nodeSize)(root);

    const node = this.nodesGroup
      .selectAll('.node')
      .data(dataTree.descendants().reverse(), (d: HierarchyPointNode<TreeData>) => d.data.id);

    // Enter new nodes
    const nodeEnter = node
      .enter()
      .append('g')
      .attr('class', 'node ' + classes.node)
      .attr('transform', (d: HierarchyPointNode<TreeData>) => {
        const target = d.parent || d;
        return `translate(${target.y},${target.x})`;
      })
      .on('click', this.handleNodeClick)
      .on('contextmenu', this.openMenu);

    nodeEnter
      .append('circle')
      .attr('class', classes.settingCircle)
      .attr('r', 2.5 + 1.2 / 2 + 1.5)
      .style('stroke-width', 1)
      .style('stroke', StyleUtil.colors.blue)
      .style('fill', '#fff');

    nodeEnter
      .append('circle')
      .attr('class', classes.ouCircle)
      .attr('r', 2.5);

    nodeEnter
      .append('text')
      .attr('dy', 3)
      .attr('x', d => (d.children ? -8 : 8))
      .attr('filter', 'url(#textShadow)')
      .style('text-anchor', d => (d.children ? 'end' : 'start'))
      .text(d => d.id.substring(d.id.indexOf('=') + 1, d.id.indexOf(',') === -1 ? undefined : d.id.indexOf(',')));

    // Transition nodes to new position
    const allNodes = node.merge(nodeEnter);
    allNodes
      .classed(classes.nodeInternal, d => !!d.children)
      // .classed(classes.nodeLeaf, d => !d.children)
      .classed(classes.included, d => this.validator.isIncluded(d.data.id))
      .classed(classes.excluded, d => !this.validator.isIncluded(d.data.id))
      .classed(
        classes.isSetting,
        d => this.inclusions.indexOf(d.data.id) > -1 || this.exclusions.indexOf(d.data.id) > -1,
      )
      .attr('transform', d => `translate(${d.y},${d.x})`);

    // Transition exiting nodes to the parent's new position
    node.exit().remove();

    const link = this.linksGroup
      .selectAll('.link')
      .data(dataTree.links(), (d: HierarchyPointLink<TreeData>) => d.source.data.id + '_' + d.target.data.id);

    // Enter new links
    const linkEnter = link
      .enter()
      .append('path')
      .attr('class', 'link');

    // Transition links to new path
    link.merge(linkEnter).attr('d', d => {
      return `
          M${d.source.y},${d.source.x}
          C${(d.source.y + d.target.y) / 2},${d.source.x} ${(d.source.y + d.target.y) / 2},${d.target.x} ${
        d.target.y
      },${d.target.x}
        `;
    });

    // Transition exiting links
    link.exit().remove();
  }
}
