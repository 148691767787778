import { applyMiddleware, compose, createStore as _createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';

import clientMiddlewareCreator from './middleware/clientMiddleware';
// import pulseMiddlewareCreator from './middleware/pulseMiddleware';
import rootSaga from './modules/sagas';

// tslint:disable:no-namespace
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

export default function createStore({ client, data, reducer }) {
  // Sync dispatched route actions to the history
  const clientMiddleware = clientMiddlewareCreator(client);
  // const pulseMiddleware = pulseMiddlewareCreator(window.rg4js) as any;
  const sagaMiddleware = createSagaMiddleware();

  let finalCreateStore;
  if (process.env.NODE_ENV !== 'production') {
    finalCreateStore = !!window.__REDUX_DEVTOOLS_EXTENSION__
      ? (compose as any)(
          applyMiddleware(clientMiddleware, sagaMiddleware),
          window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        )(_createStore)
      : (compose as any)(applyMiddleware(clientMiddleware, sagaMiddleware))(_createStore);
  } else {
    finalCreateStore = applyMiddleware(clientMiddleware, sagaMiddleware)(_createStore);
  }

  const store = finalCreateStore(reducer, data);

  store.close = () => store.dispatch(END);
  sagaMiddleware.run(rootSaga as any, client);

  return store;
}
