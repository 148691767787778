import { BroadcastResource } from '@hyperfish/antrea-api-contracts/src/broadcast';
import { ButtonContainer, IconButton, FiEdit2, FiEye } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';

import StyleUtil from '../../../../utils/StyleUtil';

interface Props {
  broadcast: BroadcastResource;
  editDisabled: boolean;
  onEdit: (broadcastId: BroadcastResource, template: BroadcastResource['templates'][0]) => void;
  onPreview: (broadcastId: BroadcastResource, template: BroadcastResource['templates'][0]) => void;
}

const tables = StyleUtil.styles.tables;
const S = StyleUtil({
  emailTable: {
    marginTop: 20,
  },
  label: {
    width: 155,
    fontWeight: 700,
  },
});

export const EmailContentTable = ({ broadcast, onEdit, onPreview, editDisabled }: Props) => (
  <table style={[tables.table, tables.table_bordered, S.emailTable] as any}>
    <thead>
      <tr>
        <th style={[tables.td, tables.th, tables.th_grey] as any} colSpan={2}>
          {broadcast.displayName}
        </th>
        <th style={[tables.td, tables.th, tables.td_actions, tables.th_grey] as any}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {broadcast.templates &&
        broadcast.templates.map(t => (
          <tr style={tables.tr} key={t.id}>
            <td style={[tables.td, S.label] as any}>{t.displayName}</td>
            <td style={tables.td}>{t.description}</td>
            <td style={[tables.td, tables.td_actions] as any}>
              <ButtonContainer>
                <IconButton
                  css={null}
                  icon={<FiEdit2 />}
                  ariaLabel="Edit"
                  color="accent"
                  onClick={editDisabled ? undefined : () => onEdit(broadcast, t)}
                  disabled={editDisabled}
                />
                <IconButton
                  css={null}
                  icon={<FiEye />}
                  ariaLabel="Preview"
                  color="accent"
                  onClick={() => onPreview(broadcast, t)}
                />
              </ButtonContainer>
            </td>
          </tr>
        ))}
    </tbody>
  </table>
);

export default Radium(EmailContentTable);
