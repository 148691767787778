import { DeviceDetails } from '@hyperfish/antrea-api-contracts/src/device';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SettingsProps } from '../../../layouts/SettingsLayout';
import { OrgSettingsKeys as keys } from '../../../models/api';
import { clearCode, getCode } from '../../../redux/modules/auth';
import {
  create as createCollection,
  edit as editCollection,
  editCloneFrom as editCollectionCloneFrom,
  editComplete as editCollectionComplete,
  editStart as editCollectionStart,
  loadScopes as loadAllScopes,
  remove as removeCollection,
} from '../../../redux/modules/collections';
import { remove as removeDevice } from '../../../redux/modules/devices';
import { clearScopes, loadConnections, loadScopes } from '../../../redux/modules/orgs';
import { loadConsent as loadProviders } from '../../../redux/modules/providers';
import { approveConfirmRun, rejectConfirmRun } from '../../../redux/modules/settings';
import OUValidator from '../../../utils/OUValidationUtil';
import CollectionGeneral from './components/CollectionGeneral';
import MasterGeneral from './components/MasterGeneral';
import { LoadingSplash, getFrom } from '@hyperfish/fishfood';
import { loadFields, clearFields } from '../../../redux/modules/fieldSchema';

const connector = connect(
  state => ({
    allScopes: state.collections.scopes,
    // allScopes: require('./multiRootScopes.json').results,
    allScopesLoading: state.collections.loadingScopes,
    code: state.auth.code,
    codeDeviceId: state.auth.codeDeviceId,
    confirmRun: state.settings.confirmRun,
    connections: state.orgs.connections,
    connectionsLoadedAt: state.orgs.connectionsLoadedAt,
    connectionsLoading: state.orgs.loadingConnections,
    devicesDeleting: state.devices.deleting,
    devicesDeletingError: state.devices.deleteError,
    dirtyCollectionCloneFrom: state.collections.dirtyCloneFrom,
    gettingCode: state.auth.gettingCode,
    gettingCodeError: state.auth.gettingCodeError,
    loadingScopes: state.orgs.loadingScopes,
    providers: state.providers.data,
    providersLoading: state.providers.loading,
    scopeById: state.collections.scopeById,
    scopes: state.orgs.scopes,
    masterSettings: state.settings.data && state.orgs.current && state.settings.data[state.orgs.current.id],
    fieldSchema: state.fieldSchema,
  }),
  {
    approveConfirmRun,
    clearCode,
    clearScopes,
    createCollection,
    editCollection,
    editCollectionStart,
    editCollectionComplete,
    editCollectionCloneFrom,
    getCode,
    loadConnections,
    loadProviders,
    loadScopes,
    loadAllScopes,
    rejectConfirmRun,
    removeCollection,
    removeDevice,
    loadFields,
    clearFields,
  },
);

export type Props = typeof connector['props'] & SettingsProps;

@connector
@Radium
@(withRouter as any)
export default class General extends React.Component<Props> {
  private connectionPollInterval = 10000;
  private getConnectionInterval: number;
  private ouValidator: OUValidator;
  private ouValidatorIncludes: Props['settings']['audit_scopes'];
  private ouValidatorExcludes: Props['settings']['audit_exclusions'];

  private getValidator = (): OUValidator => {
    const { cleanSettings, currentOrg, settings: mergedSettings, currentAudienceId } = this.props;
    const settings = currentOrg.id === currentAudienceId ? mergedSettings : cleanSettings[currentOrg.id];

    if (!settings) {
      return;
    }

    if (
      !this.ouValidator ||
      this.ouValidatorExcludes !== settings.audit_exclusions ||
      this.ouValidatorIncludes !== settings.audit_scopes
    ) {
      this.ouValidator = new OUValidator(
        (settings.audit_scopes || []).map(({ distinguishedName }) => distinguishedName),
        (settings.audit_exclusions || []).map(({ distinguishedName }) => distinguishedName),
      );
      this.ouValidatorExcludes = settings.audit_exclusions;
      this.ouValidatorIncludes = settings.audit_scopes;
    }

    return this.ouValidator;
  };

  componentDidMount() {
    const {
      connections,
      connectionsLoading,
      loadConnections,
      currentOrg,
      loadAllScopes,
      loadProviders,
      loadScopedSettings,
      allScopesLoading,
      loadFields,
      fieldSchema,
    } = this.props;
    if (!connections && !connectionsLoading) {
      loadConnections();
    }
    if (!allScopesLoading && currentOrg && currentOrg.type !== 'Online') {
      loadAllScopes();
    }
    if (!fieldSchema.loading && currentOrg && currentOrg.type === 'Online') {
      loadFields();
    }
    loadProviders(getFrom(currentOrg)('id').defaultTo(null));

    loadScopedSettings([
      keys.audit.exclusions,
      keys.audit.schedule.dailyFullAuditTime,
      keys.audit.scopes,
      keys.audit.storeProfileData,
      keys.general.supportEmail,
      keys.global.mode,
      keys.broadcast.adminReport.enabled,
      keys.locale.default,
      keys.audit.filters,
      keys.global.readOnlyMode,
      keys.audit.source,
    ]);

    // this.props.router.setRouteLeaveHook(this.props.route, () => {
    //   if (this.props.settingsDirty || this.props.permissionsByAudienceDirtyArray) {
    //     return 'You have unsaved changes. Are you sure you want to leave?';
    //   }
    // });
  }

  private clearConnectionInterval() {
    if (this.getConnectionInterval) {
      window.clearInterval(this.getConnectionInterval);
    }
  }

  componentWillUnmount() {
    this.clearConnectionInterval();
    this.props.handleCancel();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props & SettingsProps) {
    const { loadConnections, connections, code, clearCode } = this.props;

    if (!this.props.gettingCode && nextProps.gettingCode) {
      // We just requested the code. Start polling for new device to be registered
      this.clearConnectionInterval();
      this.getConnectionInterval = window.setInterval(() => {
        loadConnections();
      }, this.connectionPollInterval);
    }
    if (connections && connections.filter((c: DeviceDetails) => !!c.code && c.code === code).length > 0) {
      // Device has just been registered with current code
      clearCode();
    }
    if (this.props.code && !nextProps.code) {
      // Code cleared. Stop polling connections.
      this.clearConnectionInterval();
    }

    if (!this.props.currentOrg && !!nextProps.currentOrg && nextProps.currentOrg.type !== 'Online') {
      this.props.loadAllScopes();
    }
  }

  render() {
    const { connections, currentOrg, isMaster, settings } = this.props;

    if (!settings || this.props.settingsLoading || !currentOrg || !connections) {
      return <LoadingSplash />;
    }

    return isMaster ? (
      <MasterGeneral getValidator={this.getValidator} {...this.props} />
    ) : (
      <CollectionGeneral getValidator={this.getValidator} {...this.props} />
    );
  }
}
