// import Color from 'color';

import colors from '../colors';
import typerFunction from '../typerFunction';

export const settings = typerFunction({
  section: {
    paddingTop: 20,
  },
  sectionHeader: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    padding: '0 50px 0 30px',
    paddingLeft: 30,
    border: 'none',
    background: 'none',
    display: 'block',
    width: '100%',
    textAlign: 'left',
  },
  sectionHeaderText: {
    fontSize: 13,
    lineHeight: '50px',
    fontWeight: 700,
    margin: 0,
  },
  sectionHeaderChevron: {
    color: colors.blue,
    fontSize: 22,
    lineHeight: '50px',
    position: 'absolute',
    right: 30,
    top: 0,
  },
});
