import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, IconButton } from '@hyperfish/fishfood/lib/components/Button';
import { FiMinusCircle, FiPlus } from '@hyperfish/fishfood/lib/components/Icon';
import { Select } from '@hyperfish/fishfood/lib/components/Select';
import { TextField } from '@hyperfish/fishfood/lib/components/TextField';
import { Tooltip } from '@hyperfish/fishfood/lib/components/Tooltip';
import { EmptyMessage, FlexContainer, RemoveContainer } from './LocalizationsComponents';
import { RFC5646_LANGUAGE_TAGS } from '../../../../../utils/rfc5646-language-tags';
import { useDebouncedCallback } from 'use-debounce';
import {
  Action,
  edit as editLocalization,
  getDefaultLocale,
  LocalizationValue,
} from '../../../../../redux/modules/localizations';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';

const connector = connect(
  (state, props) => ({
    audienceId: props.audienceId ? props.audienceId : getFrom(state)('orgs')('current')('id').value,
    defaultLocale: getDefaultLocale(state),
    localizations: getFrom(state)('localizations')('dataByTargetKey').value,
  }),
  {
    editLocalization,
  },
);

export type Props = typeof connector['props'] & LocalizationsProps;

interface Option {
  label: string;
  value: string;
}

interface LocalizationsProps {
  category: 'field_title' | 'group_title';
  defaultTitle: string;
  targetKey: string;
}

const LocalizationsC: FunctionComponent<Props> = ({
  audienceId,
  category,
  defaultLocale = 'en-US',
  defaultTitle = '',
  localizations,
  targetKey,
  editLocalization,
}) => {
  const [values, setValues] = useState<Partial<LocalizationValue>[]>([]);

  const blankItem: Omit<LocalizationValue, 'id' | 'createdAt'> = {
    category,
    audienceId,
    targetKey,
    locale: '',
    value: '',
    action: Action.create,
  };

  const localeOptions: Option[] = useMemo(() => {
    const localesArray = [];
    for (const key in RFC5646_LANGUAGE_TAGS) {
      localesArray.push({ label: RFC5646_LANGUAGE_TAGS[key], value: key });
    }

    return localesArray;
  }, [RFC5646_LANGUAGE_TAGS]);

  const availableLocaleOptions: Option[] = useMemo(() => {
    let options: Option[] = [];
    let locales = Object.keys(RFC5646_LANGUAGE_TAGS)
      .filter(key => key !== defaultLocale)
      .filter(
        key =>
          !getFrom(localizations)(targetKey)
            .defaultTo([])
            .some(v => v.locale.toLowerCase() === key.toLowerCase()),
      );

    for (let index = 0; index < locales.length; index++) {
      options.push({ value: locales[index], label: RFC5646_LANGUAGE_TAGS[locales[index]] });
    }

    return options.sort((a, b) => a.label.localeCompare(b.label));
  }, [defaultLocale, localizations]);

  useEffect(() => {
    if (!localizations || !targetKey) {
      return;
    }
    setValues(localizations[targetKey] || []);
  }, [localizations, targetKey]);

  const onSelectChange = (option, index) => {
    const currentItem = values[index];
    if (currentItem.locale === option.value) {
      return;
    }
    currentItem.locale = option.value;
    currentItem.action = currentItem.hasOwnProperty('action') ? currentItem.action : Action.update;
    editLocalization(targetKey, currentItem, index);
  };

  const onTextChange = (value, index) => {
    const currentItem = values[index];
    if (currentItem.value === value) {
      return;
    }
    currentItem.value = value;
    currentItem.action = currentItem.hasOwnProperty('action') ? currentItem.action : Action.update;
    editLocalization(targetKey, currentItem, index);
  };

  // for unknown reason, we only get the last entered char when using onTextChangeDebounced
  const [onTextChangeDebounced] = useDebouncedCallback((value, index) => {
    onTextChange(value, index);
  }, 500);

  const onRemoveClick = index => {
    const currentItem: Partial<LocalizationValue> = {
      ...values[index],
      action: Action.delete,
    };
    editLocalization(targetKey, currentItem, index);
  };

  const onAddClick = () => {
    editLocalization(targetKey, blankItem, null);
  };

  const getOptionByLocale = (locale: string): Option => {
    if (!locale) {
      return { label: '', value: '' };
    }

    const option: { label: string; value: string } = localeOptions.find(
      ({ value }) => value.toLowerCase() === locale.toLowerCase(),
    );

    return option;
  };

  return (
    <div>
      <FlexContainer>
        <Select isDisabled={true} value={getOptionByLocale(defaultLocale)} />
        <TextField disabled value={defaultTitle} />
        <RemoveContainer>
          <Tooltip content="remove" delay={[300, 0]}>
            <IconButton
              css={null}
              ariaLabel="Remove"
              color="error"
              size="small"
              icon={<FiMinusCircle />}
              disabled={true}
            />
          </Tooltip>
        </RemoveContainer>
      </FlexContainer>
      {values && values.length > 0 ? (
        values.map(({ action, locale, value }, index) => {
          return action === Action.delete ? null : (
            <Fragment key={index}>
              <FlexContainer>
                <Select
                  isDisabled={action === undefined}
                  isRequired={true}
                  options={availableLocaleOptions}
                  value={getOptionByLocale(locale)}
                  onChange={option => onSelectChange(option, index)}
                />
                <TextField value={value} required onChange={e => onTextChange(e.currentTarget.value, index)} />
                <RemoveContainer>
                  <Tooltip content="remove" delay={[300, 0]}>
                    <IconButton
                      css={null}
                      ariaLabel="Remove"
                      color="error"
                      size="small"
                      icon={<FiMinusCircle />}
                      onClick={() => onRemoveClick(index)}
                    />
                  </Tooltip>
                </RemoveContainer>
              </FlexContainer>
            </Fragment>
          );
        })
      ) : (
        <EmptyMessage>{'add a language to get started'}</EmptyMessage>
      )}
      <Button variant="link" color="accent" icon={<FiPlus />} onClick={onAddClick}>
        {'Add a language'}
      </Button>
    </div>
  );
};

export const Localizations = connector(LocalizationsC);
