import {
  ChangeActionEvent,
  ChangeEvent,
  EventDetails,
  EventListDetails,
} from '@hyperfish/antrea-api-contracts/src/events';
import { getFrom } from '@hyperfish/fishfood';
import { toTitleCase } from './textFormatter';
import moment from 'moment';

export const getEventSummary = (event: EventDetails) => {
  if (event.type === 'audit') {
    const isFull = ['external', 'online'].indexOf(event.details.type) > -1;
    return `${isFull ? 'Daily Full Scan' : 'Incremental Scan'} (${(
      event.details.auditRecordCount || 0
    ).toLocaleString()} users)`;
  }
  if (event.type === 'change') {
    return 'Profile Update Created';
  }
  if (event.type === 'changeAction') {
    let blame = '';
    if (['approved', 'rejected'].indexOf(event.details.newStatus) > -1) {
      blame = !!event.details.user.id ? ` by ${event.details.user.displayName}` : ' Automatically';
    }

    return `Profile Update ${toTitleCase(event.details.newStatus)}${blame}`;
  }
  if (event.type === 'permisionsUpdateByAdmin') {
    return 'Permissions Modified';
  }
  if (event.type === 'settingsUpdateByAdmin') {
    return 'Settings Modified';
  }

  return 'Unknown Event';
};

export const getEventChanges = (eventsData: EventListDetails) => {
  const changes = [];

  const getDescription = details => {
    const getDescriptionFromTarget = details => {
      const target = getFrom(details)('target')('displayName').value;
      const user = getFrom(details)('user')('displayName').value;
      const title = getFrom(details)('title').value;
      const type = getFrom(details)('type').value;
      const oldValue = getFrom(details)('oldValue').value || '[No Value]';
      const newValue = getFrom(details)('newValue').value || '[No Value]';
      const value =
        ['ms-graph-user-manager', 'aduser', 'person'].indexOf(details.type) > -1 || type === 'image'
          ? '.'
          : ` from '${oldValue}' to '${newValue}'.`;

      return `${user} changed ${target}'s ${title}${value}`;
    };

    const getDescriptionFromUser = details => {
      const user = getFrom(details)('user')('displayName').value;
      const title = getFrom(details)('title').value;
      const type = getFrom(details)('type').value;
      const oldValue = getFrom(details)('oldValue').value || '[No Value]';
      const newValue = getFrom(details)('newValue').value || '[No Value]';
      const value =
        ['ms-graph-user-manager', 'aduser', 'person'].indexOf(details.type) > -1 || type === 'image'
          ? '.'
          : ` from '${oldValue}' to '${newValue}'.`;

      return `${user} changed their ${title}${value}`;
    };

    return details.target.delegated ? getDescriptionFromTarget(details) : getDescriptionFromUser(details);
  };

  const getUserDisplayName = details => {
    return !!details.target.delegated
      ? getFrom(details)('user')('displayName').value
      : getFrom(details)('target')('displayName').value;
  };

  const getTargetDisplayName = details => {
    return !!details.target.delegated
      ? getFrom(details)('target')('displayName').value
      : getFrom(details)('user')('displayName').value;
  };

  const getPropertyOldValue = details => {
    return ['ms-graph-user-manager', 'aduser', 'person'].indexOf(details.type) <= 0 ? details.oldValue : '[No Value]';
  };

  const getPropertyNewValue = details => {
    return ['ms-graph-user-manager', 'aduser', 'person'].indexOf(details.type) <= 0 ? details.newValue : '[No Value]';
  };

  const formatDateTime = (val: string) => {
    const date = moment(val, moment.ISO_8601);
    return date.format('MM/DD/YYYY h:mma');
  };

  eventsData.events.forEach((e: ChangeEvent | ChangeActionEvent) => {
    const changeDetail =
      e.type === 'change' ? e.details : getFrom(eventsData)('references')('changes')(e.details.change.id).value;

    const change = {
      id: getFrom(changeDetail)('id').value,
      event: getEventSummary(e),
      timestamp: getFrom(changeDetail)('createdAt').value,
      datetime: formatDateTime(getFrom(changeDetail)('createdAt').value),
      status: getFrom(changeDetail)('status').value,
      description: getDescription(changeDetail),
      user: getUserDisplayName(changeDetail),
      target: getTargetDisplayName(changeDetail),
      propertyName: getFrom(changeDetail)('title').value,
      oldValue: getPropertyOldValue(changeDetail),
      newValue: getPropertyNewValue(changeDetail),
    };

    changes.push(change);
  });

  return changes;
};
