import { ResponseApiAudience } from '@hyperfish/antrea-api-contracts/src/audience';
import { OUSearchResult as DirectoryScope } from '@hyperfish/antrea-api-contracts/src/org';

import { CollectionsState as State } from '../../models/client/';
import { ApiClient } from '@hyperfish/fishfood';

const LOAD = 'antrea/collections/LOAD';
const LOAD_SUCCESS = 'antrea/collections/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/collections/LOAD_FAIL';

const CREATE = 'antrea/collections/CREATE';
const CREATE_START = 'antrea/collections/CREATE_START';
const CREATE_SUCCESS = 'antrea/collections/CREATE_SUCCESS';
const CREATE_FAIL = 'antrea/collections/CREATE_FAIL';

const SAVE = 'antrea/collections/SAVE';
const SAVE_SUCCESS = 'antrea/collections/SAVE_SUCCESS';
const SAVE_FAIL = 'antrea/collections/SAVE_FAIL';

const DELETE = 'antrea/collections/DELETE';
const DELETE_SUCCESS = 'antrea/collections/DELETE_SUCCESS';
const DELETE_FAIL = 'antrea/collections/DELETE_FAIL';

const EDIT_START = 'antrea/collections/EDIT_START';
const EDIT = 'antrea/collections/EDIT';
const EDIT_CLONE_FROM = 'antrea/collections/EDIT_CLONE_FROM';
const EDIT_COMPLETE = 'antrea/collections/EDIT_COMPLETE';

const LOAD_DIRECTORY_SCOPES = 'antrea/collections/LOAD_DIRECTORY_SCOPES';
const LOAD_DIRECTORY_SCOPES_SUCCESS = 'antrea/collections/LOAD_DIRECTORY_SCOPES_SUCCESS';
const LOAD_DIRECTORY_SCOPES_FAIL = 'antrea/collections/LOAD_DIRECTORY_SCOPES_FAIL';

// UTILITY FUNCTIONS
function mapToObject(audiences: ResponseApiAudience[]) {
  const o = {};
  for (const audience of audiences) {
    o[audience.id] = audience;
  }
  return o;
}

function mapScopeToObject(scopes: DirectoryScope[]) {
  const o = {};
  for (const scope of scopes) {
    o[scope.distinguishedName] = scope;
  }
  return o;
}

const initialState: State = {
  dataById: {},
  dataIds: [],
  deletingById: {},
  loaded: false,
  relatedEntities: {},
  scopeById: {},
  scopes: [],
} as State;

export default function reducer(state: State = initialState, action): State {
  const { type, result, error, property, value, audienceId, collection, fieldName } = action;

  switch (type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        dataIds: result.audiences.map(audience => audience.id),
        dataById: mapToObject(result.audiences),
        error: null,
        relatedEntities: result.relatedEntities,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        dataById: initialState.dataById,
        error,
      };

    case EDIT_START:
      return {
        ...state,
        collectionDirty: false,
        dirtyCollection: collection,
      };
    case EDIT:
      return {
        ...state,
        dirtyCollection: {
          ...state.dirtyCollection,
          definition: {
            ...state.dirtyCollection.definition,
            [property]: value,
          },
        } as any,
        collectionDirty: true,
      };
    case EDIT_CLONE_FROM:
      return {
        ...state,
        dirtyCloneFrom: action.clone,
      };
    case EDIT_COMPLETE:
      return {
        ...state,
        collectionDirty: false,
        dirtyCollection: null,
        dirtyCloneFrom: null,
      };

    case CREATE_START:
      return {
        ...state,
        collectionDirty: true,
        dirtyCollection: !!fieldName
          ? {
              id: null,
              type: 'Attribute',
              definition: {
                fieldName,
                values: [],
                name: '',
                description: '',
              },
            }
          : {
              id: null,
              type: 'OU',
              definition: {
                DNStrings: [],
                name: '',
                description: '',
              },
            },
      };

    case CREATE:
      return {
        ...state,
        creating: true,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        collectionDirty: false,
        creating: false,
        dataIds: [...state.dataIds, result.audience.id],
        dataById: {
          ...state.dataById,
          [result.audience.id]: result.audience,
        },
        dirtyCollection: null,
        relatedEntities: {
          ...state.relatedEntities,
          audienceDisplay: {
            ...state.relatedEntities.audienceDisplay,
            [result.audience.id]: result.relatedEntities.audienceDisplay[result.audience.id],
          },
        },
      };
    case CREATE_FAIL:
      return {
        ...state,
        collectionDirty: false,
        creating: false,
        dirtyCollection: null,
      };

    case SAVE:
      return {
        ...state,
        saving: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        saving: false,
        collectionDirty: false,
        dataById: {
          ...state.dataById,
          [result.audience.id]: result.audience,
        },
        relatedEntities: {
          ...state.relatedEntities,
          audienceDisplay: {
            ...state.relatedEntities.audienceDisplay,
            [result.audience.id]: result.relatedEntities.audienceDisplay[result.audience.id],
          },
        },
      };
    case SAVE_FAIL:
      return {
        ...state,
        saving: false,
      };

    case DELETE:
      return {
        ...state,
        deletingById: { ...state.deletingById, [audienceId]: true },
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        dataIds: state.dataIds.filter(id => id !== audienceId),
        dataById: { ...state.dataById, [audienceId]: null },
        deletingById: { ...state.deletingById, [audienceId]: null },
      };
    case DELETE_FAIL:
      return {
        ...state,
        deletingById: { ...state.deletingById, [audienceId]: null },
      };

    case LOAD_DIRECTORY_SCOPES:
      return {
        ...state,
        loadingScopes: true,
        loadedScopes: false,
        scopes: null,
      };
    case LOAD_DIRECTORY_SCOPES_SUCCESS:
      return {
        ...state,
        loadingScopes: false,
        loadedScopes: true,
        scopes: result.results,
        scopeById: mapScopeToObject(result.results),
      };
    case LOAD_DIRECTORY_SCOPES_FAIL:
      return {
        ...state,
        loadingScopes: false,
        loadedScopes: false,
      };

    default:
      return state;
  }
}

// ACTION CREATORS
export function editStart(collection: State['dirtyCollection']) {
  return {
    type: EDIT_START,
    collection,
  };
}

export function edit(property: string, value: any) {
  return {
    type: EDIT,
    property,
    value,
  };
}

export function editCloneFrom(clone: string) {
  return {
    type: EDIT_CLONE_FROM,
    clone,
  };
}

export function editComplete() {
  return { type: EDIT_COMPLETE };
}

export function createStart(fieldName?: string) {
  return {
    type: CREATE_START,
    fieldName,
  };
}

export function create(audience, cloneFrom?: string, shouldNotify = true) {
  // tslint-disable-next-line:no-unused-variable;
  const data = {
    audience,
  };

  if (cloneFrom) {
    data['clone'] = cloneFrom;
  }
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    promise: (client: ApiClient) =>
      client.post('/audiences', {
        data,
      }),
    notifications: shouldNotify
      ? {
          success: 'Collection created successfully',
          fail: 'Failed to create collection',
        }
      : null,
  };
}

export function remove(audienceId: string, shouldNotify = true) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    promise: (client: ApiClient) => client.del(`/audiences/${audienceId}`),
    notifications: shouldNotify
      ? {
          success: 'Collection deleted successfully',
          fail: 'Failed to delete collection',
        }
      : null,
    audienceId,
  };
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/audiences'),
    notifications: {
      fail: 'Failed to load collections.',
    },
  };
}

export function save(audience: State['dirtyCollection']) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client: ApiClient) => client.patch(`/audiences/${audience.id}`, { data: { audience } }),
    notifications: {
      success: 'Collection updated successfully',
      fail: 'Failed to update collection.',
    },
  };
}

export function loadScopes(params: { limit?: number } = {}) {
  const paramDefaults = {};
  params = { ...paramDefaults, ...params };
  return {
    types: [LOAD_DIRECTORY_SCOPES, LOAD_DIRECTORY_SCOPES_SUCCESS, LOAD_DIRECTORY_SCOPES_FAIL],
    promise: (client: ApiClient) => client.get('/orgs/current/directory-scopes', { params }),
  };
}
