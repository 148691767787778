import { ModalKeys } from '../';
import { Option, ToggleProps, TextFieldProps, DateTimePickerProps } from '@hyperfish/fishfood';
import { PeoplePickerProps } from '@hyperfish/fishfood/lib/components/PeoplePicker/LegacyPeoplePicker';
import { SelectProps } from '@hyperfish/fishfood/lib/components/Select/Select';

interface HyperFieldBase {
  disabled?: boolean;
  hideLabel?: boolean;
  hideValue?: boolean;
  hintText?: string | JSX.Element;
  id?: string;
  premiumLock?: ModalKeys;
  label: string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  suggestionText?: string;
  type?: string;
  validating?: boolean;
  validationText?: string;
}

interface HyperFieldFocusable {
  autofocus?: boolean;
}

interface HyperFieldBaseT<T extends { props }> extends HyperFieldBase {
  onChange?: T['props']['onChange'];
  props?: T['props'];
  value?: T['props']['value'];
}

export interface HyperFieldText extends HyperFieldBase, HyperFieldFocusable {
  inputType?: 'text' | 'search' | 'tel' | 'url' | 'number' | 'email';
  type?: 'text' | 'number' | 'color';
  onChange?: TextFieldProps['onChange'];
  props?: TextFieldProps;
  value: TextFieldProps['value'];
}

export interface HyperFieldLongText extends HyperFieldBase, HyperFieldFocusable {
  type?: 'longtext';
  lines?: number;
  onChange?: TextFieldProps['onChange'];
  props?: TextFieldProps;
  value: TextFieldProps['value'];
}

export interface HyperFieldToggle extends HyperFieldBase {
  type?: 'toggle';
  onChange?: ToggleProps['onChange'];
  props?: ToggleProps;
  value: ToggleProps['checked'];
}

export interface HyperFieldRadio extends HyperFieldBase {
  onChange: React.InputHTMLAttributes<HTMLInputElement>['onChange'];
  type?: 'radio';
  value: React.InputHTMLAttributes<HTMLInputElement>['checked'];
}

interface HyperFieldSelectBase extends HyperFieldBase, HyperFieldFocusable {
  onChange?: SelectProps<Option>['onChange'];
  props?: SelectProps<Option>;
  value?: SelectProps<Option>['value'];
  dropdownCreatable?: boolean;
  dropdownOptions?: Option[] | {}[];
  dropdownMulti?: boolean;
  // dropdownPromptTextCreator?: Creatable<Option>['props']['promptTextCreator'];
  type?: 'dropdown' | 'autocomplete';
}

export interface HyperFieldSelect extends HyperFieldSelectBase {
  onChange: (option: Option) => void;
}

export interface HyperFieldMultiSelect extends HyperFieldSelectBase {
  dropdownMulti?: true;
  dropdownSearchable?: boolean;
  onChange: (options: Option[], actionMeta?: any) => void;
}

interface HyperFieldPersonBase
  extends HyperFieldBase,
    HyperFieldFocusable,
    Pick<PeoplePickerProps, 'clearUsers'>,
    Pick<PeoplePickerProps, 'loadUsers'>,
    Pick<PeoplePickerProps, 'isLoading'>,
    Pick<PeoplePickerProps, 'onChange'>,
    Pick<PeoplePickerProps, 'users'>,
    Pick<PeoplePickerProps, 'value'> {
  type?: 'person';
  isMulti?: PeoplePickerProps['isMulti'];
  props?: Partial<PeoplePickerProps>;
}

export interface HyperFieldPerson extends HyperFieldPersonBase {
  onChange: (option: Option) => void;
}

export interface HyperFieldMultiPerson extends HyperFieldPersonBase {
  isMulti?: true;
  onChange: (options: Option[]) => void;
}

export interface HyperFieldDateTime extends HyperFieldBase {
  type?: 'date' | 'time' | 'datetime';
  onChange?: DateTimePickerProps['onChange'];
  props?: DateTimePickerProps;
  value: DateTimePickerProps['value'];
}

export interface HyperFieldCustom extends HyperFieldBase {
  customInput: JSX.Element;
  type?: 'custom';
}

export interface HyperFieldImage extends HyperFieldBase {
  dropzoneOptions?: { multiple?: boolean; accept?: string };
  onChange: (imgString: string) => void;
  props?: any;
  type?: 'image';
  value: string;
  alt?: string;
}

export type HyperField =
  | HyperFieldText
  | HyperFieldLongText
  | HyperFieldToggle
  | HyperFieldRadio
  | HyperFieldSelect
  | HyperFieldMultiSelect
  | HyperFieldPerson
  | HyperFieldMultiPerson
  | HyperFieldDateTime
  | HyperFieldCustom
  | HyperFieldImage;

export class HyperFieldFactory {
  static text = (o: HyperFieldText): HyperFieldText => ({ type: 'text', ...o });
  static longText = (o: HyperFieldLongText): HyperFieldLongText => ({
    type: 'longtext',
    ...o,
  });
  static number = (o: HyperFieldText): HyperFieldText => ({
    type: 'number',
    ...o,
  });
  static color = (o: HyperFieldText): HyperFieldText => ({
    type: 'color',
    ...o,
  });
  static toggle = (o: HyperFieldToggle): HyperFieldToggle => ({
    type: 'toggle',
    ...o,
  });
  static radio = (o: HyperFieldRadio): HyperFieldRadio => ({
    type: 'radio',
    ...o,
  });
  static dropdown = (o: HyperFieldSelect): HyperFieldSelect => ({
    type: 'dropdown',
    ...o,
  });
  static dropdownMulti = (o: HyperFieldMultiSelect): HyperFieldMultiSelect => ({
    type: 'dropdown',
    dropdownMulti: true,
    ...o,
  });
  static autocomplete = (o: HyperFieldSelect): HyperFieldSelect => ({
    type: 'autocomplete',
    ...o,
  });
  static autocompleteMulti = (o: HyperFieldMultiSelect): HyperFieldMultiSelect => ({
    type: 'autocomplete',
    dropdownMulti: true,
    ...o,
  });
  static person = (o: HyperFieldPerson): HyperFieldPerson => ({
    type: 'person',
    ...o,
  });
  static personMulti = (o: HyperFieldMultiPerson): HyperFieldMultiPerson => ({
    type: 'person',
    isMulti: true,
    ...o,
  });
  static date = (o: HyperFieldDateTime): HyperFieldDateTime => ({
    type: 'date',
    ...o,
  });
  static time = (o: HyperFieldDateTime): HyperFieldDateTime => ({
    type: 'time',
    ...o,
  });
  static dateTime = (o: HyperFieldDateTime): HyperFieldDateTime => ({
    type: 'datetime',
    ...o,
  });
  static custom = (o: HyperFieldCustom): HyperFieldCustom => ({
    type: 'custom',
    ...o,
  });
  static image = (o: HyperFieldImage): HyperFieldImage => ({
    type: 'image',
    ...o,
  });
}
