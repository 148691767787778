import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  ApiResult,
  DirectoryPartialTreeRequest,
  DirectorySearchRequest,
  RelationshipTreeResponse,
} from '@hyperfish/antrea-api-contracts';
import { Option, Select } from '@hyperfish/fishfood/lib/components/Select';
import { LoadingSplash } from '@hyperfish/fishfood/lib/components/LoadingSplash';
import UserUtil from '@hyperfish/fishfood/lib/utils/UserUtil';
import { getFrom } from '@hyperfish/fishfood/lib/utils/GetUtil';
import {
  EntitySearch,
  RelationshipChart as FFRelationshipChart,
  RelationshipChartData,
  RelationshipChartPerson,
} from '@hyperfish/fishfood/lib/components/RelationshipChart';
import { ApiClientContext } from '@hyperfish/fishfood/lib/utils/ApiClient';
import { loadWebpartSetting as loadSettings } from '../../redux/modules/settings';
import classes from './styles.module.scss';
import { isLoadedCurrent, hasOrgFeature } from '../../redux/modules/orgs';
import { ORG_FEATURE_DIRECTORY_WEB_PARTS } from '../../config';
import { RelationshipTypeResponse } from '@hyperfish/antrea-api-contracts/src/relationship';

const connector = connect(
  state => ({
    orgId: getFrom(state.orgs.current)('id').value,
    selfDetail: getFrom(state.externalUsers.detailById)('me').value,
    selfDetailError: getFrom(state.externalUsers.detailByIdError)('me').value,
    settings: state.settings.data,
    loaded: isLoadedCurrent(state),
    webpartsLicensed: hasOrgFeature(state, ORG_FEATURE_DIRECTORY_WEB_PARTS),
  }),
  {
    loadSettings,
  },
);

type Props = typeof connector['props'] & {
  onRequest?: (request: DirectorySearchRequest) => void;
};

const RELATIONSHIP_DEFAULT = 'DEFAULT';
const RELATIONSHIP_DEFAULT_LABEL = 'Manager (Default Org Relationship)';
const RELATIONSHIP_DEFAULT_COLOR = { r: '81', g: '45', b: '168' };

const RelationshipChartC: React.FunctionComponent<Props> = ({
  orgId,
  selfDetail,
  selfDetailError,
  settings,
  webpartsLicensed,
  loaded,
  loadSettings,
}) => {
  const client = React.useContext(ApiClientContext);
  const [managerId, setManagerId] = React.useState<string>(null);
  const [rootUserId, setRootUserId] = React.useState<string>(null);
  const [error, setError] = React.useState<any>(false);
  const [internalSettings, setInternalSettings] = React.useState<undefined | {}>();
  const [schema, setSchema] = React.useState<ApiResult>(null);
  const [audienceId, setAudienceId] = React.useState<string>(null);
  const [disableDetailModal, setDisableDetailModal] = React.useState<boolean>(false);
  const [rootUserIds, setRootUserIds] = React.useState<string[]>([]);
  const [showLoadingSplash, setShowLoadingSplash] = React.useState<boolean>(true);
  const [relationshipTypeIds, setRelationshipTypeIds] = React.useState<string[]>([RELATIONSHIP_DEFAULT]);
  const [relationshipTypesRoots, setRelationshipTypesRoots] = React.useState<{ [id: string]: string[] }>({});
  const [relationshipTypes, setRelationshipTypes] = React.useState<RelationshipTypeResponse[]>([]);

  const getRootUserIds = (rootUser: {} | Option[]): string[] => {
    if (rootUser == null) {
      return null;
    }

    if (Array.isArray(rootUser) && rootUser.length > 0) {
      return rootUser.map(option => option.value).filter(Boolean);
    }

    if (typeof rootUser === 'object') {
      return [getFrom(rootUser as Option)('value').value].filter(Boolean);
    }

    return null;
  };

  const getRelationshipTypeColor = (id: string, alpha: number = 1): string => {
    let color = RELATIONSHIP_DEFAULT_COLOR;
    if (id != RELATIONSHIP_DEFAULT) {
      color = getFrom(relationshipTypes.find(r => r.id == id))('metaData')('relationshipColor').defaultTo(undefined);
    }
    return `rgba(${color.r},${color.g},${color.b},${alpha})`;
  };

  React.useEffect(() => {
    const getRootUserId = (): string => {
      // set rootId to configured root user (if available)
      if (Array.isArray(rootUserIds) && rootUserIds.filter(Boolean).length > 0) {
        return rootUserIds[0];
      }

      // no root user configured, use highest line manager available
      if (!!managerId) {
        return managerId;
      }

      return rootUserId;
    };

    const userId = getRootUserId();
    setRootUserId(userId);
    if (userId) {
      setRelationshipTypesRoots(old => ({
        ...old,
        [RELATIONSHIP_DEFAULT]: [userId],
      }));
    }
  }, [rootUserIds, managerId]);

  React.useEffect(() => {
    if (settings && settings[audienceId]) {
      const _audienceSettings = getFrom(settings[audienceId])('webparts_config_orgchart').defaultTo(
        getFrom(settings[orgId])('webparts_config_orgchart').defaultTo({}),
      );
      const _rootUserIds = getRootUserIds(getFrom(_audienceSettings as any)('rootUser').defaultTo([])).filter(Boolean);

      setInternalSettings(_audienceSettings);
      setDisableDetailModal(getFrom(_audienceSettings as any)('disableDetailModal').defaultTo(false));
      setRootUserIds(_rootUserIds);
    }
  }, [settings, audienceId]);

  React.useEffect(() => {
    if (rootUserId && internalSettings) {
      setShowLoadingSplash(false);
    }
  }, [rootUserId, internalSettings]);

  React.useEffect(() => {
    if (rootUserIds.length > 0 || !selfDetail) {
      return;
    }

    const entityId = UserUtil.GetUserId(selfDetail.profile);

    client.api.directory
      .tree({ entityId, outputFields: ['id', 'objectguid'] })
      .then(({ data }) =>
        setManagerId(
          data.managers && data.managers.length > 0
            ? UserUtil.GetUserId(data.managers[data.managers.length - 1])
            : entityId,
        ),
      )
      .catch(err => setError(err || true));
  }, [selfDetail, rootUserIds]);

  React.useEffect(() => {
    loadSettings();
    client.api.relationships
      .getTypes('published', 'hierarchical')
      .then(({ data }) => setRelationshipTypes(data))
      .catch(err => setError(err || true));

    client.api.fieldSchemas
      .get()
      .then(({ data }) => setSchema(data))
      .catch(err => setError(err || true));

    client.api.externalUsers
      .getAudience('me')
      .then(({ data }) => setAudienceId(data.audienceId))
      .catch(err => setError(err || true));
  }, []);

  React.useEffect(() => {
    const ids = relationshipTypeIds.filter(id => getFrom(relationshipTypesRoots)(id).defaultTo([]).length === 0);

    ids.forEach(id => {
      if (id == RELATIONSHIP_DEFAULT) {
        if (!rootUserId) return;
        setRelationshipTypesRoots(old => ({
          ...old,
          [id]: [rootUserId],
        }));
        return;
      }

      client.api.relationships
        .getRoots(id)
        .then(({ data }) => {
          setRelationshipTypesRoots(old => ({
            ...old,
            [id]: data.rootEntityIds,
          }));
        })
        .catch(err => setError(err || true));
    });
  }, [relationshipTypeIds]);

  if (loaded && !webpartsLicensed) {
    return <Redirect to={'/self'} />;
  }

  if (selfDetailError || error) {
    return (
      <div className="container">
        <h2>There was a problem loading the relationship chart</h2>
        <p>We were unable to load the data for the relationship chart. Please try again later.</p>
      </div>
    );
  }

  if (showLoadingSplash) {
    return <LoadingSplash />;
  }

  if (getFrom(internalSettings as any)('enabled').defaultTo(true) === false) {
    return <Redirect to={'/self'} />;
  }

  const displayingRootTypes = [
    {
      id: RELATIONSHIP_DEFAULT,
      name: RELATIONSHIP_DEFAULT_LABEL,
    },
  ].concat(getFrom(relationshipTypes).defaultTo([])) as RelationshipTypeResponse[];

  const mapRelationshipNode = (
    entityId: string,
    tree: RelationshipTreeResponse,
    relationshipTypeId: string,
  ): RelationshipChartPerson => {
    const node = (tree.relationshipNodes[entityId] as any) as RelationshipChartPerson;
    return {
      ...node,
      relationshipType: {
        id: relationshipTypeId,
      },
      __hf_directory: {
        directReportCount: node ? tree.relationshipNodes[entityId].directReportCount : 0,
        totalReportCount: 0,
      },
    };
  };

  const getManagers = (entityId: string, tree: RelationshipTreeResponse, relationshipTypeId: string) => {
    const managerId = getFrom(tree.relationshipEdges.find(f => f.entityIdFrom == entityId))('entityIdTo').defaultTo('');
    if (!managerId) return [];

    const manager = mapRelationshipNode(managerId, tree, relationshipTypeId);

    return [manager].concat(getManagers(managerId, tree, relationshipTypeId));
  };

  const handleFetchData = (
    entityId: string,
    relationshipTypeId: string,
    outputFields: string[],
    managerDeepth?: number,
  ): Promise<RelationshipChartData> => {
    if (relationshipTypeId == RELATIONSHIP_DEFAULT) {
      const request: DirectoryPartialTreeRequest = {
        managerDepth: managerDeepth,
        entityId,
        idsOnly: false,
        outputFields: outputFields,
      };

      return client.api.directory.tree(request).then(({ data }) => {
        return {
          target: {
            ...(data.target as RelationshipChartPerson),
            relationshipType: {
              id: relationshipTypeId,
            },
          },
          managers: data.managers.map(m => {
            return {
              ...(m as RelationshipChartPerson),
              relationshipType: {
                id: relationshipTypeId,
              },
            };
          }),
          directReports: data.directReports.map(d => {
            return {
              ...(d as RelationshipChartPerson),
              relationshipType: {
                id: relationshipTypeId,
              },
            };
          }),
        };
      });
    }

    const request = {
      relationshipTypeId: relationshipTypeId,
      entityId: entityId,
      outputFields: outputFields,
      managerDepth: managerDeepth,
    };
    return client.api.relationships.tree(request).then(({ data }) => {
      return {
        target: mapRelationshipNode(entityId, data, relationshipTypeId),
        managers: getManagers(entityId, data, relationshipTypeId),
        directReports: data.relationshipEdges
          .filter(f => f.entityIdTo == entityId)
          .map(e => mapRelationshipNode(e.entityIdFrom, data, relationshipTypeId)),
      } as RelationshipChartData;
    });
  };

  const handleFetchTree = (entitiesSearch: EntitySearch[], outputFields: string[]) => {
    var of = ['__hf_directory', 'id', 'objectguid', 'profilePhotoUrl', 'userprincipalname', ...outputFields];

    var promises: Promise<RelationshipChartData>[] = [];
    entitiesSearch.forEach(es => {
      promises.push(handleFetchData(es.entityId, es.relationshipTypeId, of, es.managerDeepth == 'all' ? undefined : 1));
    });
    return Promise.all(promises).then(values => {
      return values;
    });
  };

  const handleFetchRelationshipTypesByEntityId = (entityId: string): Promise<RelationshipTypeResponse[]> => {
    var promises: Promise<RelationshipTypeResponse[]>[] = [
      client.api.directory.tree({ entityId, outputFields: ['id', 'objectguid'] }).then(({ data }) => {
        const result: RelationshipTypeResponse[] = [];

        if (
          data.managers.some(
            (m: any) => getFrom(m)('id').defaultTo(getFrom(m)('objectguid').defaultTo('')) == rootUserId,
          ) ||
          getFrom(data.target as any)('id').defaultTo(getFrom(data.target as any)('objectguid').defaultTo('')) ==
            rootUserId
        ) {
          result.push({
            id: RELATIONSHIP_DEFAULT,
            name: RELATIONSHIP_DEFAULT_LABEL,
          } as RelationshipTypeResponse);
        }
        return result;
      }),
      client.api.relationships.getTypes('published', 'hierarchical', entityId).then(({ data }) => data),
    ];

    return Promise.all(promises).then(values => {
      return values[0].concat(values[1]);
    });
  };

  const getRootUsersId = () => {
    const entitiesSearch: EntitySearch[] = [];

    relationshipTypeIds.map(id => {
      return getFrom(relationshipTypesRoots)(id)
        .defaultTo([])
        .forEach(entityId => {
          entitiesSearch.push({
            entityId,
            relationshipTypeId: id,
            managerDeepth: 'direct',
          });
        });
    });

    return entitiesSearch;
  };

  return (
    <div className={`container ${classes.container}`}>
      <FFRelationshipChart
        height={600}
        {...internalSettings}
        schema={schema}
        direction="hierarchical"
        audienceId={audienceId}
        masterAudienceId={orgId}
        fetchDetail={
          disableDetailModal
            ? undefined
            : id => client.api.externalUsers.get(id, { cacheok: 1 }).then(({ data }) => data)
        }
        relationshipRoots={getRootUsersId()}
        getEntitySearchUniqueKey={(relationshipTypeId: string, entityId: string) => `${relationshipTypeId}:${entityId}`}
        fetchTree={handleFetchTree}
        showCommIcons={getFrom(internalSettings as any)('showCommIcons').defaultTo(false)}
        mergeRelationshipTrees={getFrom(internalSettings as any)('mergeRelationshipTrees').defaultTo(false)}
        getRelationshipTypeColor={getRelationshipTypeColor}
        relationshipTypes={displayingRootTypes}
        addRelationshipTypeSelected={(relationshipTypeId: string) =>
          setRelationshipTypeIds(old => old.concat(relationshipTypeId))
        }
        removeRelationshipTypeSelected={(relationshipTypeId: string) =>
          setRelationshipTypeIds(old => {
            if (old.filter(id => id != relationshipTypeId).length == 0) return old;

            return old.filter(id => id != relationshipTypeId);
          })
        }
        selectedRelationshipTypes={relationshipTypeIds}
        fetchRelationshipTypesByEntityId={handleFetchRelationshipTypesByEntityId}
      />
    </div>
  );
};

export const RelationshipChart = connector(RelationshipChartC);
