import Radium from 'radium';
import React from 'react';

import StyleUtil from '../utils/StyleUtil';
import { Button } from './Button';
import { Icon } from './Icon';

interface Props {
  items: JSX.Element[];
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  className?: string;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>>;
  type?: 'primary';
  style?: {
    button?: React.CSSProperties;
    dropdownButton?: React.CSSProperties;
    dropdownList?: React.CSSProperties;
  };
  submit?: boolean;
}

interface State {
  dropdownOpen: boolean;
}

const styles = StyleUtil({
  button: {
    borderRadius: '3px 0 0 3px',
    borderRightWidth: 0,
    verticalAlign: 'middle',
  },
  dropdownButton: {
    borderRadius: '0 3px 3px 0',
    borderLeftWidth: '1px',
    minWidth: '20px',
    padding: 0,
    width: '20px',
    verticalAlign: 'middle',
    marginLeft: 0,
  },
});

@Radium
export class DropdownButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    } as State;
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  handleDropdownClick = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
    document.addEventListener('click', this.closeDropdown);
  };

  removeEventListeners() {
    document.removeEventListener('click', this.closeDropdown);
  }

  closeDropdown = () => {
    this.setState({ dropdownOpen: false });
    this.removeEventListeners();
  };

  render() {
    const { style, items, children, ...btnProps } = this.props;
    const { disabled, size, type } = this.props;
    const { dropdownOpen } = this.state;

    return (
      <div className="button-group" style={[{ display: 'inline-block', position: 'relative' }, style || {}] as any}>
        <Button style={styles.button} size={size} {...btnProps}>
          {children}
        </Button>
        <Button
          size={size}
          disabled={disabled}
          type={type}
          style={styles.dropdownButton}
          active={dropdownOpen}
          onClick={this.handleDropdownClick}
        >
          <Icon name="chevron-down" />
        </Button>
        {dropdownOpen && (
          <ul
            className="Select-menu-outer"
            style={[{ position: 'absolute', textAlign: 'left', minWidth: '100%' }] as any}
          >
            {items &&
              items.map(item => {
                return React.cloneElement(item, { className: 'Select-option' });
              })}
          </ul>
        )}
      </div>
    );
  }
}
