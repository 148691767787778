import { AnalyticsReportRequest } from '@hyperfish/antrea-api-contracts/src/analytics';
import { ApiClient } from '@hyperfish/fishfood';

import { AnalyticsState as State, GlobalState } from '../../models/client/';
import generateDemoLines from '../../utils/demoTrendUtil';

const LOAD = 'antrea/analytics/LOAD';
const LOAD_SUCCESS = 'antrea/analytics/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/analytics/LOAD_FAIL';
const CLEAR = 'antrea/analytics/CLEAR';

const initialState: State = {
  loading: {},
  data: {},
  error: {},
};

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: { ...state.loading, [action.key]: true },
        error: { ...state.error, [action.key]: null },
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [action.key]: false },
        data: { ...state.data, [action.key]: action.result },
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: { ...state.loading, [action.key]: false },
        error: { ...state.error, [action.key]: action.error },
      };
    case CLEAR:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
}

export function shouldLoad({ analytics }: GlobalState) {
  return analytics && !analytics.loading;
}

export function load(key: string, data: AnalyticsReportRequest) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    key,
    promise: (client: ApiClient) => client.post('/analytics/audits/reports', { data }),
    notifications: {
      fail: {
        title: 'Failed to load directory analytics.',
        message: 'We are having trouble loading the historical data for your directory.',
      },
    },
  };
}

export function loadDemo(key: string, data: AnalyticsReportRequest) {
  return {
    type: LOAD_SUCCESS,
    key,
    result: generateDemoLines(data, key),
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}
