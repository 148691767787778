import cx from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

type Props = JSX.IntrinsicElements['tr'] & {
  isAlt?: boolean;
  isGroupHead?: boolean;
};

export const TableRow: React.StatelessComponent<Props> = ({ children, isAlt, isGroupHead, ...rest }) => (
  <tr
    {...rest}
    className={cx(classes.TableRow, rest.className, {
      [classes.isAlt]: isAlt,
      [classes.isGroupHead]: isGroupHead,
    })}
  >
    {children}
  </tr>
);
