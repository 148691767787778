import { TemplateRendering } from '@hyperfish/antrea-api-contracts/src/broadcast';
import React from 'react';

import StyleUtil from '../utils/StyleUtil/index';
import { ContentFrame } from './ContentFrame';

interface Props {
  templatePreview: TemplateRendering;
}

const S = StyleUtil({
  templatePreview: {
    border: '1px solid #d4d4d4',
    padding: 11,
  },
  templatePreviewHeaders: {
    fontSize: 10,
    marginBottom: 11,
    color: '#39393a',
  },
  templatePreviewHeaderLabel: {
    fontWeight: 700,
    lineHeight: 1,
    padding: '4px 5px 4px 0',
    textTransform: 'capitalize',
  },
  templatePreviewHeaderValue: {},
  templatePreviewFrame: {
    width: '100%',
    height: 400,
    borderTop: '1px solid #d4d4d4',
  },
});

export const TemplatePreview = ({ templatePreview }: Props) => {
  if (!templatePreview) {
    return null;
  }

  const headerOrder = ['to', 'fromname', 'from', 'replyto', 'subject'];

  const prettyNameMap = {
    fromname: 'From Name',
    replyto: 'Reply To',
  };

  const headers = headerOrder
    .filter(key => !!templatePreview.headers[key])
    .concat(Object.keys(templatePreview.headers).filter(key => headerOrder.indexOf(key) === -1));

  return (
    <div style={S.templatePreview}>
      <table style={S.templatePreviewHeaders}>
        <tbody>
          {headers.map(key => (
            <tr key={key}>
              <td style={S.templatePreviewHeaderLabel}>{prettyNameMap[key] || key}:</td>
              <td style={S.templatePreviewHeaderValue}>{templatePreview.headers[key]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ContentFrame style={S.templatePreviewFrame} content={templatePreview.body} />
    </div>
  );
};
