/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import {
  LoadingSplash,
  FiUserMinus,
  themeColor,
  Modal,
  Form,
  FiPlus,
  Button,
  FiAlertTriangle,
  spacing,
  stringToOption,
  Select,
  Label,
} from '@hyperfish/fishfood';
import styled from 'styled-components';
import * as Yup from 'yup';

import { SettingCard, DataTable } from '../../../components';
import { SettingsProps } from '../../../layouts/SettingsLayout';
import { OrgSettingsKeys as keys } from '../../../models/api';
import { clear as clearUsers, load as loadUsers } from '../../../redux/modules/externalUsers';
import { getAuthCodes, generateNewAuthCode, revokeAuthCode } from '../../../redux/modules/auth';
import { createNotification } from '../../../redux/modules/notifications';
import { DirectoryForm } from '../OrgDirectory/Directory/DirectoryForm';
import { MultiPart } from './components/multipart';
import { WebPartSnippet } from './components/Snippet';
import { GenericToken, OrgSettingTypes } from '@hyperfish/antrea-api-contracts';
import { OrgForm } from '../OrgDirectory/OrgChart/OrgForm';
import { Redirect } from 'react-router-dom';

interface TokenForm {
  name: string;
  purpose: string;
}

const RevokeIcon = styled(FiUserMinus)`
  color: ${themeColor('error')};
`;

const GenerateIcon = styled(FiPlus)`
  color: ${themeColor('accent')};
`;

const GenerateButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: ${spacing(2)};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Description = styled.div`
  font-style: italic;
  margin-bottom: 30px;
  opacity: 0.9;
  font-size: 13px;
  flex: 1;
`;

const TokenTable = DataTable.of({
  name: { label: 'Name', sortable: true },
  purpose: { label: 'Purpose' },
  created_at: {
    label: 'Created',
    sortable: true,
    type: DataTable.types.datetime,
  },
  id: { hidden: true },
  token: { hidden: true },
});

const connector = connect(
  state => ({
    users: state.externalUsers.data,
    usersLoading: state.externalUsers.loading,
    authCodes: state.auth.authCodes,
    purposes: state.auth.availablePurposes,
  }),
  {
    clearUsers,
    loadUsers,
    getAuthCodes,
    generateNewAuthCode,
    revokeAuthCode,
    createNotification,
  },
);

type Props = typeof connector['props'];

interface State {
  tokenForm?: TokenForm;
  tokenFormVisible: boolean;
  revokeToken?: { id: string; name: string };
  generateEmbedSnippet?: 'Profile' | 'Directory' | 'OrgChart';
  directorySettings?: OrgSettingTypes;
  orgchartSettings?: OrgSettingTypes;
  embedToken?: string;
  rootUser?: string;
}

@connector
export class Embed extends React.Component<Props & SettingsProps, Partial<State>> {
  constructor(props) {
    super(props);
    this.state = { tokenFormVisible: false };
  }

  componentDidMount() {
    const { loadScopedSettings, getAuthCodes } = this.props;

    getAuthCodes();
    loadScopedSettings([keys.webparts.config.directory, keys.webparts.config.orgchart]);
  }

  componentWillUnmount() {
    this.props.handleCancel();
  }

  handleCopy(token: string) {
    const { createNotification } = this.props;
    if (document.queryCommandSupported('copy')) {
      const dummyNode = document.createElement('input');
      document.body.appendChild(dummyNode);
      dummyNode.setAttribute('value', token);
      dummyNode.select();
      document.execCommand('copy');
      document.body.removeChild(dummyNode);
      createNotification({ message: 'Token copied to clipboard!' });
    }
  }

  render() {
    const { currentOrgLoading, settings, settingsLoading, licenseWebparts, authCodes } = this.props;

    if (!licenseWebparts) {
      return <Redirect to={'/settings/general'} />;
    }

    const tokens = authCodes && authCodes.filter(x => !x.revoked_at).map(x => ({ ...x }));

    return (
      <div className="view-settings-Hyperbot">
        {!settings || settingsLoading || currentOrgLoading ? (
          <LoadingSplash />
        ) : (
          <div>
            <Description>
              Use snippets to embed the LiveTiles Directory web-parts for your corporate directory and org-chart on
              webpages or intranets outside of SharePoint
            </Description>
            <SettingCard
              title="Authentication Tokens"
              subTitle="Generate authentication tokens to allow code snippets to communicate with LiveTiles Directory."
            >
              <GenerateButtonContainer>
                <Button
                  color="accent"
                  variant="link"
                  icon={<GenerateIcon />}
                  onClick={() => this.setState({ ...this.state, tokenFormVisible: true })}
                  size="small"
                >
                  Add new token
                </Button>
              </GenerateButtonContainer>
              <TokenTable
                dateTimeFormat="ddd, DD MMM YYYY HH:mm:ss"
                defaultSortBy="created_at"
                data={tokens}
                onRowClick={row => this.handleCopy(row.token as string)}
                actions={[
                  {
                    icon: <RevokeIcon />,
                    onAction: item =>
                      this.setState({
                        ...this.state,
                        revokeToken: { id: item.id, name: item.name as string },
                      }),
                  },
                ]}
              />
            </SettingCard>
            <SettingCard
              title="Generate Embeddable Snippet"
              subTitle="Configure and generate an embeddable snippet that can be used outside of a SharePoint site"
            >
              {tokens && tokens.length > 0 ? (
                <ButtonContainer>
                  <Button
                    variant="solid"
                    color="primary"
                    onClick={() => this.setState({ ...this.state, generateEmbedSnippet: 'Directory' })}
                  >
                    Directory
                  </Button>
                  <Button
                    variant="solid"
                    color="primary"
                    onClick={() => this.setState({ ...this.state, generateEmbedSnippet: 'OrgChart' })}
                  >
                    Org chart
                  </Button>
                </ButtonContainer>
              ) : (
                'You need to create a token above before generating a snippet.'
              )}
            </SettingCard>
            {this.state.tokenFormVisible && this.renderTokenFormModal()}
            {this.state.revokeToken && this.renderConfirmRevokeModal()}
            {this.state.generateEmbedSnippet && this.renderSnippetGenerator(tokens)}
          </div>
        )}
      </div>
    );
  }

  renderTokenFormModal() {
    const { generateNewAuthCode, purposes } = this.props;

    return (
      <Modal>
        <Modal.Header>Generate New Token</Modal.Header>
        <Form
          onReset={() => this.setState({ tokenForm: null, tokenFormVisible: false })}
          onSubmit={(values: TokenForm, actions) => {
            generateNewAuthCode(values.name, values.purpose);
            actions.setSubmitting(false);
            this.setState({ tokenForm: null, tokenFormVisible: false });
          }}
          initialValues={null}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Must have a value'),
            purpose: Yup.string().required('Must have a value'),
          })}
        >
          <Form.FieldContainer>
            <Form.Fields.Text label="Name" name="name" required />
            <Form.Fields.Select
              label="Purpose"
              inputProps={{ options: purposes.map(x => stringToOption(x)) }}
              name="purpose"
              required
            />
          </Form.FieldContainer>
        </Form>
      </Modal>
    );
  }

  renderConfirmRevokeModal() {
    const { revokeAuthCode } = this.props;

    return (
      <Modal onClose={() => this.setState({ ...this.state, revokeToken: null })}>
        <Modal.Header>Revoke token</Modal.Header>
        <div>Are you sure you want to revoke token: {this.state.revokeToken.name}?</div>
        <div>This cannot be undone!</div>
        <Modal.ButtonContainer>
          <Button onClick={() => this.setState({ ...this.state, revokeToken: null })}>Cancel</Button>
          <Button
            variant="solid"
            color="error"
            icon={<FiAlertTriangle />}
            onClick={() => {
              revokeAuthCode(this.state.revokeToken.id);
              this.setState({ ...this.state, revokeToken: null });
            }}
          >
            Revoke
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  renderSnippetGenerator(tokens: GenericToken[]) {
    const { generateEmbedSnippet, directorySettings, orgchartSettings, embedToken, rootUser } = this.state;
    const { fieldSchema, currentOrg } = this.props;

    return (
      <Modal onClose={() => this.setState({ ...this.state, generateEmbedSnippet: null })}>
        <Modal.Header>Configure {generateEmbedSnippet} snippet</Modal.Header>
        <MultiPart next="Code" back="Configure">
          <>
            <Label labelText="Choose a token to use in the snippet">
              <Select
                options={tokens.map(token => ({ label: token.name, value: token.token }))}
                onChange={option => this.setState({ ...this.state, embedToken: option.value })}
                placeholder="..."
              />
            </Label>
            {generateEmbedSnippet === 'Directory' && (
              <DirectoryForm
                settings={{ ...directorySettings }}
                schema={fieldSchema}
                currentOrgId={currentOrg.id}
                save={this.props.save}
                collapsable={true}
                onChange={(value: OrgSettingTypes) =>
                  this.setState({ ...this.state, directorySettings: { ...directorySettings, ...value } })
                }
                forSnippet
              />
            )}
            {generateEmbedSnippet === 'OrgChart' && (
              <>
                <OrgForm
                  settings={{ ...orgchartSettings }}
                  schema={fieldSchema}
                  currentOrgId={currentOrg.id}
                  save={this.props.save}
                  collapsable={true}
                  onChange={(value: OrgSettingTypes) =>
                    this.setState({ ...this.state, orgchartSettings: { ...orgchartSettings, ...value } })
                  }
                  settingsFor={'snippet'}
                />
              </>
            )}
          </>
          <WebPartSnippet
            settings={{
              token: embedToken,
              webPart: generateEmbedSnippet,
              config: generateEmbedSnippet === 'Directory' ? directorySettings : orgchartSettings,
              rootUser: { value: rootUser },
            }}
          />
        </MultiPart>
        <Modal.ButtonContainer>
          <Button onClick={() => this.setState({ ...this.state, generateEmbedSnippet: null })}>Cancel</Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }
}

export default Embed;
