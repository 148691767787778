import React, { Fragment } from 'react';
import { ApiField, ApiFieldAudienceConfig, ApiFormat } from '@hyperfish/antrea-api-contracts';
import { DeviceDetails } from '@hyperfish/antrea-api-contracts/src/device';
import { Button } from '@hyperfish/fishfood/lib/components/Button';
import { FiSearch } from '@hyperfish/fishfood/lib/components/Icon';
import { Modal } from '@hyperfish/fishfood/lib/components/Modal';
import { Option, stringToOption } from '@hyperfish/fishfood/lib/components/Select';
import { Toggle } from '@hyperfish/fishfood/lib/components/Toggle';
import { TextField } from '@hyperfish/fishfood/lib/components/TextField';
import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import SchemaUtil, { MonthFormat } from '@hyperfish/fishfood/lib/utils/SchemaUtil';
import { Props } from '../..';
import {
  HyperField,
  HyperFieldFactory as hFields,
  HyperForm,
  Icon,
  ModalKeys,
  Slider,
  Tabs,
} from '../../../../../components';
import { VisionNotes } from '../../../../../models/api';
import AtLeastVersion from '../../../../../utils/VersionUtil';
import classes from './styles.module.scss';
import StyleUtil from '../../../../../utils/StyleUtil';
import { AZURE_APP_NAME } from '../../../../../redux/modules/providers';
import { Warning, WarningContainer } from './FieldModalComponents';
import { Localizations } from '../Localizations';
import { LocalizationValue } from '../../../../../redux/modules/localizations';

let fieldNamesTimeout: number;

interface State {
  activeTab: 'attribute' | 'validation' | 'language';
  bulkOptionString: string;
  activeParentValue: Option;
  disableSaveFieldInUse: boolean;
  disableSaveDisplayNameInvalid: boolean;
  errorMessage: string;
  dateMonthFormat: MonthFormat;
  dateHideYear: boolean;
}

export class FieldModal extends React.Component<Props, State> {
  private uneditableKeys = ['mail'];
  private cachedFieldOptions;

  private get isPhoto() {
    return SchemaUtil.GetFieldIsPhoto(this.props.dirtyField);
  }
  private get isUneditable() {
    const { settings } = this.props;
    return this.uneditableKeys.indexOf(this.props.dirtyField.property) > -1 || settings.read_only_mode;
  }
  private get config() {
    const { dirtyField, currentAudienceId, currentOrg } = this.props;
    return SchemaUtil.GetConfigFromField(dirtyField, currentAudienceId, currentOrg.id);
  }
  private get selectValueDefault(): ApiFieldAudienceConfig['format']['meta']['selectValue'] {
    return {
      caseSensitive: false,
      values: SchemaUtil.GetChoiceOptionsFromConfig(this.config).values,
      multiValue: false,
      delimiter: ',',
      multipleFreetext: false,
    };
  }
  private get isCollectionFormat() {
    const dirtyFormat = this.config.format;
    return getFrom(dirtyFormat.meta)('ui')('usedByCollections').value as boolean;
  }

  private setOptions = (values: string[]) => {
    const dirtyFormat = this.config.format;
    const dependsOn = getFrom(dirtyFormat)('meta')('fieldDependsOn')('dependsOn').value;
    const isDependant = dependsOn != null;

    // Dedupe values
    const seen = {};
    values = (values || [])
      .filter(value => (seen.hasOwnProperty(value) ? false : (seen[value] = true)))
      .map(value => (String(value) || '').trim());

    if (isDependant) {
      const newConfigs = [...getFrom(dirtyFormat)('meta')('fieldDependsOn')('configs').defaultTo([])];

      let added = false;
      for (const config of newConfigs) {
        if (config.dependsValues.indexOf(getFrom(this.state)('activeParentValue')('value').value) > -1) {
          config.fieldValues = values;
          added = true;
        }
      }

      if (!added) {
        newConfigs.push({
          dependsValues: [getFrom(this.state)('activeParentValue')('value').value],
          fieldValues: values,
        });
      }

      this.editFormat('meta.fieldDependsOn.configs', newConfigs);
    } else {
      this.editFormat('meta.selectValue', {
        ...this.selectValueDefault,
        ...dirtyFormat.meta.selectValue,
        values,
      });
    }
    if (dirtyFormat.type === 'string') {
      this.editFormat('constraints', []);
    }
  };

  private getConfig = (fieldId: string): ApiFieldAudienceConfig => {
    const { fieldSchema, currentAudienceId, currentOrg } = this.props;
    const field = SchemaUtil.GetFieldById(fieldSchema, fieldId);
    return SchemaUtil.GetConfigFromField(field, currentAudienceId, currentOrg.id);
  };

  private getCascadeFieldOptions = (): Option[] => {
    const { fieldSchema, dirtyField } = this.props;

    if (!this.cachedFieldOptions) {
      this.cachedFieldOptions = Object.keys(fieldSchema.fields)
        .filter(
          id =>
            id !== dirtyField.id &&
            !fieldSchema.fields[id].internal &&
            SchemaUtil.GetConfigIsChoice(this.getConfig(id)),
        )
        .map(id => ({
          value: id,
          label: this.getConfig(id).ui.title,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }

    return this.cachedFieldOptions;
  };

  private getParentValueOptions = (fieldDependsOn?: string): Option[] => {
    const { fieldSchema, currentAudienceId, currentOrg } = this.props;
    fieldDependsOn = fieldDependsOn || SchemaUtil.GetFieldDependsOnFromConfig(this.config);

    if (fieldDependsOn == null) {
      return [];
    }

    const choiceOptions = SchemaUtil.GetChoiceOptionsFromConfig(
      SchemaUtil.GetConfigFromField(
        SchemaUtil.GetFieldById(fieldSchema, fieldDependsOn),
        currentAudienceId,
        currentOrg.id,
      ),
    );

    const results = [
      ...choiceOptions.values,
      ...choiceOptions.configs.reduce((p, c) => [...p, ...c.fieldValues], []),
    ].map((x: string) => ({ label: x, isDisabled: false, value: x }));

    return results;
  };

  private editFormat = (path: string, val: any) => {
    const { editFieldConfig, currentAudienceId } = this.props;
    editFieldConfig(currentAudienceId, `format.${path}`, val);
  };

  private setConstraint = (prop: string, val: any) => {
    const dirtyFormat = this.config.format;

    for (let i = 0; i < dirtyFormat.constraints.length; i++) {
      if (dirtyFormat.constraints[i].formatConstraint.hasOwnProperty(prop)) {
        if (val) {
          // Update constraint
          this.editFormat(`constraints.${i}.formatConstraint.${prop}`, val);
        } else {
          // Remove constraint
          const newConstraints = dirtyFormat.constraints.slice();
          newConstraints.splice(i, 1);
          this.editFormat('constraints', newConstraints);
        }
        return;
      }
    }

    if (!val) {
      // Constraint does not exist, so does not need to be removed. Do nothing.
      return;
    }

    // Add constraint
    const newConstraints = dirtyFormat.constraints.slice();
    newConstraints.push({
      constraintType: 'Text',
      formatConstraint: {
        type: 'string',
        [prop]: val,
      },
    });

    this.editFormat('constraints', newConstraints);
  };

  private getFieldsDependingOnValue = (dependsOnValue: string) => {
    const { fieldSchema, currentAudienceId, currentOrg } = this.props;

    const isDependantOnValue = (field: ApiField) => {
      const configs = getFrom(SchemaUtil.GetConfigFromField(field, currentAudienceId, currentOrg.id))('format')('meta')(
        'fieldDependsOn',
      )('configs').value;

      return (
        !!configs &&
        configs.filter(config => {
          const { dependsValues } = config;
          return Array.isArray(dependsValues) && dependsValues.filter(value => value === dependsOnValue).length > 0;
        }).length > 0
      );
    };

    return Object.keys(getFrom(fieldSchema)('fields').defaultTo({}))
      .map(id => getFrom(fieldSchema)('fields')(id).value)
      .filter(isDependantOnValue);
  };

  private getLocalizations = (
    localizations: { [key: string]: LocalizationValue[] },
    targetKey: string,
  ): Partial<LocalizationValue>[] => getFrom(localizations)(targetKey).defaultTo([]);

  constructor(props: Props) {
    super(props);
    const parentValueOptions = this.getParentValueOptions();
    this.state = {
      activeTab: 'attribute',
      bulkOptionString: null,
      activeParentValue: parentValueOptions.length > 0 ? parentValueOptions[0] : null,
      disableSaveFieldInUse: !props.dirtyField.id, // props.fieldCreating is undefined. We need in creating: true, Editing: false
      disableSaveDisplayNameInvalid: !props.dirtyField.id, // props.fieldCreating is undefined. We need in creating: true, Editing: false
      errorMessage: null,
      dateMonthFormat: 'short',
      dateHideYear: getFrom(this.config)('format')('meta')('hideyear').value || false,
    };
  }

  public render() {
    const {
      currentAudienceId,
      currentOrg,
      dirtyField,
      fieldCreating,
      fieldDirty,
      fieldSchema,
      fieldUpdating,
      isMaster,
      isFree,
      localizationsDirty,
      localizationsUpdating,
      providers,
      requiresAdminReconsent,
      createField,
      editFieldOverride,
      editFieldStart,
      showPremiumModal,
      updateField,
      updateLocalizations,
    } = this.props;

    const dataByTargetKey = this.getLocalizations(
      getFrom(this.props)('localizations').value,
      getFrom(this.props)('dirtyField')('id').value,
    );

    const cleanConfig =
      dirtyField.id &&
      (fieldSchema.fields[dirtyField.id].audienceConfigs[currentAudienceId] ||
        fieldSchema.fields[dirtyField.id].audienceConfigs[currentOrg.id]);

    const title = dirtyField.id ? `Edit ${cleanConfig.ui.title}` : 'Add New Attribute';

    const cleanOverride =
      !!dirtyField.id && !!getFrom(fieldSchema)('fields')(dirtyField.id)('audienceConfigs')(currentAudienceId).value;
    const override = !!dirtyField.audienceConfigs[currentAudienceId];

    const refreshUrl =
      providers &&
      `${providers
        .filter(p => p.name === AZURE_APP_NAME)[0]
        .href.replace('/common/', `/${currentOrg.remoteTenant}/`)}&prompt=consent`;

    return (
      <Fragment>
        <Modal onClose={() => editFieldStart(null)}>
          <div className={classes.headerContainer}>
            <Modal.Header>{title}</Modal.Header>
            {!isMaster && (
              <div className={classes.fieldOverrideContainer}>
                <label className={classes.fieldOverrideLabel} htmlFor="fieldOverrideToggle">
                  Override Master Settings
                </label>
                <Toggle
                  id="fieldOverrideToggle"
                  checked={override}
                  onChange={e => {
                    const checked = e.currentTarget.checked;
                    editFieldOverride(currentAudienceId, currentOrg.id, checked);
                  }}
                />
              </div>
            )}
          </div>
          <Tabs
            tabs={[
              { label: 'Attribute', key: 'attribute' },
              { label: 'Validation', key: 'validation' },
              { label: 'Languages', key: 'language' },
            ]}
            activeKey={this.state.activeTab}
            onChange={(activeTab: State['activeTab']) => this.setState({ activeTab })}
            className={classes.tabs}
          />
          <HyperForm
            saving={fieldUpdating[dirtyField.id] || fieldCreating || localizationsUpdating}
            disabled={!override}
            disableSave={
              (cleanOverride && !override
                ? false
                : this.state.disableSaveFieldInUse || this.state.disableSaveDisplayNameInvalid) ||
              requiresAdminReconsent
            }
            onCancel={() => editFieldStart(null)}
            dirty={fieldDirty || localizationsDirty}
            showPremiumModal={showPremiumModal}
            onSubmit={e => {
              e.preventDefault();

              if (dirtyField.id) {
                updateField(currentAudienceId, dirtyField);
              } else {
                createField(dirtyField);
              }

              if (localizationsDirty) {
                updateLocalizations(dirtyField.id, dataByTargetKey);
              }
            }}
            fields={this.getFields(this.state.activeTab)}
          />
          {requiresAdminReconsent && (
            <>
              <WarningContainer>
                <Warning />
                Reauthorization is required before you can add an attribute
                <Button
                  style={{ marginLeft: '10px' }}
                  variant="link"
                  color="primary"
                  onClick={() => (location.href = refreshUrl)}
                >
                  Reauthorize
                </Button>
              </WarningContainer>
            </>
          )}
          {this.state.errorMessage && (
            <WarningContainer>
              <Warning />
              {this.state.errorMessage}
              <Button
                style={{ marginLeft: '10px' }}
                variant="link"
                color="primary"
                onClick={() => this.setState({ ...this.state, errorMessage: null })}
              >
                Cancel
              </Button>
            </WarningContainer>
          )}
        </Modal>
        {this.state.bulkOptionString != null && !isFree && this.renderBulkOptionModal()}
      </Fragment>
    );
  }

  private renderBulkOptionModal() {
    const { bulkOptionString } = this.state;
    const newlineChar = '\n';

    const delimiter = getFrom(this.config)('format')('meta')('selectValue')('delimiter').defaultTo(',');
    const multiValue = getFrom(this.config)('format')('meta')('selectValue')('multiValue').value;
    const validationText =
      multiValue && bulkOptionString.indexOf(delimiter) > -1
        ? `Options in a multiple value choice attribute cannot contain the character: ${delimiter}`
        : undefined;

    return (
      <Modal>
        <Modal.Header>Bulk Edit Choice Options</Modal.Header>
        <p>Enter each option on a new line.</p>
        <TextField
          autoFocus={true}
          long={true}
          longLines={10}
          value={bulkOptionString}
          onChange={this.handleBulkChoiceEdit}
          invalid={validationText != null}
        />
        <a
          style={{ marginRight: 15 }}
          onClick={() => {
            this.setState(state => {
              const newString = state.bulkOptionString
                .split(newlineChar)
                .sort((a, b) => a.localeCompare(b))
                .join(newlineChar);
              return { bulkOptionString: newString };
            });
          }}
        >
          <Icon name="arrow-thin-up" />
          <span>Sort Ascending</span>
        </a>
        <a
          onClick={() => {
            this.setState(state => {
              const newString = state.bulkOptionString
                .split(newlineChar)
                .sort((a, b) => b.localeCompare(a))
                .join(newlineChar);
              return { bulkOptionString: newString };
            });
          }}
        >
          <Icon name="arrow-thin-down" />
          <span>Sort Descending</span>
        </a>
        {validationText != null && <p className={classes.invalidMessage}>{validationText}</p>}
        <Modal.ButtonContainer>
          <Button
            size="medium"
            style={StyleUtil.styles.modals.spacedBtn}
            onClick={() => this.setState({ bulkOptionString: null })}
          >
            Cancel
          </Button>
          <Button
            size="medium"
            variant="solid"
            disabled={!this.state.bulkOptionString || validationText != null}
            onClick={() => {
              this.setOptions(this.state.bulkOptionString.split(newlineChar));
              this.setState({ bulkOptionString: null });
            }}
          >
            SAVE
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  private getAttributeFields(): HyperField[] {
    const {
      currentAudienceId,
      connections,
      currentOrg,
      dirtyField,
      editField,
      editFieldConfig,
      fieldNames,
      fieldNamesLoading,
      fieldSchema,
      clearFields,
      searchFields,
      isFree,
      showPremiumModal,
      settings,
    } = this.props;

    const onlineOrg = currentOrg.type === 'Online';

    if (!fieldNamesLoading && !fieldNames && onlineOrg) {
      searchFields();
    }

    const override = !!dirtyField.audienceConfigs[currentAudienceId];

    const isPropertyUsed = (property: string) =>
      Object.keys(fieldSchema.fields)
        .map(id => fieldSchema.fields[id])
        .filter(field => !!field)
        .filter(({ id }) => id !== dirtyField.id)
        .map(({ property: prop }) => prop)
        .indexOf(property) > -1;

    const fieldOptions = fieldNamesLoading
      ? []
      : (fieldNames || [{ property: dirtyField.property }]).map(fieldName => ({
          value: fieldName.property,
          label: fieldName.property,
          disabled: isPropertyUsed(fieldName.property),
          format: fieldName.format,
        }));

    const supportsSearch =
      (!onlineOrg &&
        (connections || []).filter(c =>
          AtLeastVersion('1.4.2.0', ((c as DeviceDetails).lastHeartbeat || {})['version']),
        ).length > 0) ||
      onlineOrg;

    return [
      currentOrg && supportsSearch
        ? hFields.autocomplete({
            label: 'Field',
            hintText: 'Technical name of field in your directory',
            required: true,
            type: 'autocomplete',
            autofocus: !dirtyField.id,
            value: dirtyField.property ? { value: dirtyField.property, label: dirtyField.property } : null,
            readOnly: !!dirtyField.id,
            validationText: isPropertyUsed(dirtyField.property) ? 'This field is already used in an attribute.' : null,
            onChange: (val: typeof fieldOptions[0]) => {
              editField('property', val ? val.value : null);
              if (val && val.format) {
                editField('type', val ? val.format.type : 'string');
                editFieldConfig(currentAudienceId, 'format', val.format);
              }
              const isUsed = isPropertyUsed(val ? val.value : null);
              if (this.state.disableSaveFieldInUse != isUsed) {
                this.setState({ disableSaveFieldInUse: isUsed });
              }
            },
            dropdownOptions: fieldOptions,
            placeholder: onlineOrg ? 'Select field name' : 'Search for field name...',
            props: {
              isLoading: fieldNamesLoading,
              icon: !onlineOrg && <FiSearch />,
              onInputChange: (q?: string) => {
                if (!onlineOrg) {
                  if (fieldNamesTimeout) {
                    window.clearTimeout(fieldNamesTimeout);
                  }
                  if (!q) {
                    clearFields();
                    return q;
                  }

                  fieldNamesTimeout = window.setTimeout(() => {
                    searchFields({ q });
                  }, 500);

                  return q;
                }
                return q;
              },
              noOptionsMessage: inputValue => {
                if (fieldNamesLoading) {
                  return 'Searching...';
                }
                if (inputValue) {
                  return 'No results found';
                }
                return null;
              },
            },
          })
        : hFields.text({
            label: 'Field',
            hintText: 'Technical name of field in your directory',
            required: true,
            autofocus: !dirtyField.id,
            value: dirtyField.property,
            readOnly: !!dirtyField.id,
            validationText: isPropertyUsed(dirtyField.property) ? 'This field is already used in an attribute.' : null,
            onChange: e => {
              editField('property', e.currentTarget.value);
              const isUsed = isPropertyUsed(e.currentTarget.value);
              if (this.state.disableSaveFieldInUse != isUsed) {
                this.setState({ disableSaveFieldInUse: isUsed });
              }
            },
          }),
      !this.isPhoto &&
        hFields.text({
          label: 'Display Name',
          required: true,
          hintText: 'Name your users will see',
          autofocus: override && !!dirtyField.id,
          value: this.config.ui.title || '',
          onChange: e => {
            const str = e.currentTarget.value;
            editFieldConfig(currentAudienceId, 'ui.title', str);
            const invalid = !str;
            if (this.state.disableSaveDisplayNameInvalid != invalid) {
              this.setState({ disableSaveDisplayNameInvalid: invalid });
            }
          },
          premiumLock: isFree ? ModalKeys.attributes : null,
        }),
      hFields.text({
        label: 'Hint Text',
        hintText: 'This is an example of Hint Text',
        value: this.config.ui.hintText || '',
        onChange: e => editFieldConfig(currentAudienceId, 'ui.hintText', e.currentTarget.value),
        premiumLock: isFree && !this.isPhoto ? ModalKeys.attributes : null,
      }),
      hFields.toggle({
        label: 'Hide Value from Webparts',
        value: this.config.ui.hideFromPublic,
        onChange: e => editFieldConfig(currentAudienceId, 'ui.hideFromPublic', e.currentTarget.checked),
        premiumLock: isFree ? ModalKeys.attributes : null,
      }),
      hFields.custom({
        label: 'Form and Hyperbot Options',
        customInput: null,
        hideValue: true,
      }),
      hFields.custom({
        label: 'Form and Hyperbot Slider',
        hideLabel: true,
        customInput: (
          <Slider
            value={`${String(!!this.config.ui.hidden)}_${String(!!this.config.ui.readonly)}_${String(
              !!this.config.notify,
            )}`}
            showPremiumModal={showPremiumModal}
            options={[
              {
                value: 'true_true_false',
                label: 'Hidden',
                icon: (
                  <img
                    data-tip-right={
                      this.isPhoto
                        ? 'Profile Picture cannot currently be hidden from the profile page.'
                        : 'The attribute will not display on the profile page.'
                    }
                    src={require('../../assets/hidden-icon.svg')}
                  />
                ),
                disabled: this.isPhoto,
                premiumLock: isFree ? ModalKeys.attributes : null,
              },
              {
                value: 'false_true_false',
                label: 'Read Only',
                icon: (
                  <img
                    data-tip="The attribute will display on the profile page but will be read only."
                    src={require('../../assets/read-only-icon.svg')}
                  />
                ),
                premiumLock: this.isPhoto && isFree ? ModalKeys.attributes : null,
              },
              {
                value: 'false_false_false',
                label: 'Editable',
                icon: (
                  <img
                    data-tip={
                      this.isUneditable
                        ? 'This field is not editable through LiveTiles Directory.'
                        : 'The attribute will display on the profile page and will be editable.'
                    }
                    src={require('../../assets/edit-icon.svg')}
                  />
                ),
                disabled: this.isUneditable,
                premiumLock: isFree ? ModalKeys.attributes : null,
              },
              {
                value: 'false_false_true',
                label: 'Hyperbot and Editable',
                icon: (
                  <img
                    data-tip-left={
                      this.isUneditable
                        ? 'This field is not editable through LiveTiles Directory.'
                        : 'Hyperbot will contact users about the attribute and it will be editable on the profile page.'
                    }
                    src={require('../../assets/hyperbot-icon.svg')}
                  />
                ),
                disabled: this.isUneditable,
                premiumLock: !this.isPhoto && isFree ? ModalKeys.attributes : null,
              },
            ]}
            onChange={(value: string) => {
              const [hidden, readonly, notify] = value.split('_').map(s => s === 'true');
              editFieldConfig(currentAudienceId, 'ui.hidden', hidden);
              editFieldConfig(currentAudienceId, 'ui.readonly', readonly);
              editFieldConfig(currentAudienceId, 'notify', notify);
            }}
          />
        ),
      }),
    ];
  }

  private getValidationFields(): HyperField[] {
    const { editFieldConfig, currentAudienceId, isFree, dirtyField } = this.props;

    const dirtyFormat = this.config.format;
    type extendedComponent = typeof dirtyFormat.meta.ui.component | 'multipleFreetext';

    const component = SchemaUtil.GetComponentFromConfig(this.config) || 'text';
    const isChoice =
      SchemaUtil.GetConfigIsChoice(this.config) ||
      (getFrom(dirtyFormat)('meta')('ui')('component').value as extendedComponent) === 'multipleFreetext';
    const isText = component === 'text';
    const isLongText = component === 'longtext';
    const externalRequest = getFrom(dirtyFormat)('meta')('externalRequest').value;
    const isExternal = !!externalRequest;
    const isToggle = ['toggle'].indexOf(component) > -1;
    const isArray = dirtyFormat.type === 'array';

    const autocompleteLikeList = ['multipleFreetext'];

    const componentOptions = [
      { label: 'Autocomplete', value: 'autocomplete' },
      { label: 'Dropdown', value: 'dropdown' },
      { label: 'Multiple Freetext', value: 'multipleFreetext' },
      ...(this.isCollectionFormat
        ? []
        : [
            { label: 'Number Input', value: 'number' },
            // {label: 'Radio', value: 'radio'},
            { label: 'Text Input', value: 'text' },
            { label: 'Long Text Input', value: 'longtext' },
            { label: 'Toggle', value: 'toggle' },
            { label: 'Date', value: 'date' },
            { label: 'Date and Time', value: 'datetime' },
          ]),
    ];

    const fields: HyperField[] = this.isUneditable
      ? []
      : [
          hFields.toggle({
            label: 'Must Contain a Value',
            value: this.config.required,
            onChange: e => editFieldConfig(currentAudienceId, 'required', e.currentTarget.checked),
            premiumLock: isFree ? ModalKeys.attributes : null,
          }),
        ];

    if (component.toString() == 'date' || component.toString() == 'datetime') {
      fields.push(
        hFields.autocomplete({
          label: 'Month Format',
          required: true,
          dropdownOptions: [
            { label: 'Long Name', value: 'long' },
            { label: 'Short Name', value: 'short' },
            { label: 'Number', value: 'numeric' },
            { label: '2-Digit', value: '2-digit' },
            { label: 'Narrow', value: 'narrow' },
          ],
          value: dirtyFormat.meta['monthformat'] as MonthFormat,
          onChange: option => {
            let newMonthFormat = option.value as MonthFormat;
            this.setState({ dateMonthFormat: newMonthFormat });
            dirtyFormat.meta['monthformat'] = newMonthFormat;
            this.editFormat('meta', dirtyFormat.meta);
          },
        }),
      );

      fields.push(
        hFields.toggle({
          label: 'Hide Year',
          value: getFrom(dirtyFormat)('meta')('hideyear').value || false,
          onChange: e => {
            this.setState({ dateHideYear: e.currentTarget.checked });
            dirtyFormat.meta['hideyear'] = e.currentTarget.checked;
            this.editFormat('meta', dirtyFormat.meta);
          },
        }),
      );
    }

    if (dirtyFormat.type !== 'string') {
      if (dirtyFormat.type === 'image') {
        return [...fields, ...this.getImageFields()];
      } else if (!isArray) {
        return fields;
      }
    }

    //special handling for buisinessPhones field
    if (isArray && dirtyField.property === 'businessPhones') {
      return fields;
    }

    // setup default array UI config
    if (isArray && getFrom(dirtyFormat)('meta')('ui')('component').value !== 'autocomplete') {
      this.editFormat('meta.ui.component', 'autocomplete');
      this.editFormat('meta.selectValue', {
        ...this.selectValueDefault,
        multiValue: true,
        freeform: true,
        delimiter: undefined,
        caseSensitive: false,
      });
    }

    if (isExternal) {
      return [
        ...fields,
        hFields.text({
          label: 'External Validation Method',
          readOnly: true,
          value: externalRequest.method,
        }),
        hFields.text({
          label: 'External Validation URI',
          readOnly: true,
          value: externalRequest.uri,
        }),
      ];
    }

    return [
      ...fields,
      !isArray &&
        hFields.autocomplete({
          label: 'Render As',
          required: true,
          hintText: !this.isCollectionFormat
            ? 'Input type used on profile update page to allow user to edit'
            : 'Format used for collections can only be Dropdown, Autocomplete or Freeform Autocomplete',
          value: componentOptions.filter(c => c.value === component),
          dropdownOptions: componentOptions,
          onChange: ({ value }) => {
            value = String(value);
            // Clear constraints
            if (dirtyFormat.type === 'string') {
              this.editFormat('constraints', []);
            }

            if (
              ['autocomplete', 'dropdown', ...autocompleteLikeList].indexOf(value) === -1 &&
              !!dirtyFormat.meta.selectValue
            ) {
              // When type is changed from a choice field,
              // clear out selectValue and fieldDependsOn from meta
              const newMeta = {};
              const choiceKeys = ['selectValue', 'fieldDependsOn'];
              Object.keys(dirtyFormat.meta).forEach(
                key => choiceKeys.indexOf[key] === -1 && (newMeta[key] = dirtyFormat.meta[key]),
              );

              this.editFormat('meta', newMeta);
            }

            if (value === 'number') {
              this.setConstraint('pattern', '^(([0-9]*)|(([0-9]*).([0-9]*)))$');
            } else if (value === 'toggle') {
              this.setConstraint('enum', ['true', 'false']);
            } else if (value === 'multipleFreetext') {
              this.editFormat('meta.selectValue', {
                ...this.selectValueDefault,
                multipleFreetext: true,
                multiValue: true,
                freeform: true,
              });
            } else if (['autocomplete', 'dropdown'].indexOf(value) > -1) {
              this.editFormat('meta.selectValue', {
                ...this.selectValueDefault,
                caseSensitive: true,
              });
            }
            this.editFormat('meta.ui.component', value);
          },
          premiumLock: isFree ? ModalKeys.formats : null,
        }),
      isText &&
        hFields.text({
          label: 'Pattern',
          value: this.getConstraint('pattern') || '',
          hintText: (
            <span>
              Valid JavaScript syntax regex (
              <a
                href="https://spacetelescope.github.io/understanding-json-schema/reference/regular_expressions.html#regular-expressions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
              )
            </span>
          ),
          onChange: e => this.setConstraint('pattern', e.target['value']),
          validationText: (() => {
            try {
              new RegExp(this.getConstraint('pattern'));
            } catch (e) {
              return 'Must be a valid regular expression';
            }
          })(), // tslint:disable-line
          premiumLock: isFree ? ModalKeys.formats : null,
        }),
      isLongText &&
        hFields.number({
          label: 'Input Height',
          value: dirtyFormat.meta.ui.height || 2,
          hintText: 'Height of input (in lines of text)',
          onChange: e => this.editFormat('meta.ui.height', e.currentTarget.value),
          premiumLock: isFree ? ModalKeys.formats : null,
        }),
      isToggle &&
        hFields.text({
          label: 'True Value',
          required: true,
          validationText: !(this.getConstraint('enum') || ['', ''])[0] && 'Required field',
          hintText: 'String value stored in directory when toggle is ON',
          value: (this.getConstraint('enum') || ['', ''])[0],
          onChange: e =>
            this.setConstraint('enum', [e.currentTarget.value, (this.getConstraint('enum') || ['', ''])[1]]),
          premiumLock: isFree ? ModalKeys.formats : null,
        }),
      isToggle &&
        hFields.text({
          label: 'False Value',
          required: true,
          validationText: !(this.getConstraint('enum') || ['', ''])[1] && 'Required field',
          hintText: 'String value stored in directory when toggle is OFF',
          value: (this.getConstraint('enum') || ['', ''])[1],
          onChange: e =>
            this.setConstraint('enum', [(this.getConstraint('enum') || ['', ''])[0], e.currentTarget.value]),
          premiumLock: isFree ? ModalKeys.formats : null,
        }),
      ...(isChoice ? this.getChoiceFields() : []),
    ];
  }

  private getLocalizationFields(): HyperField[] {
    const { dirtyField, audienceId, currentAudienceId } = this.props;

    const fieldConfig = SchemaUtil.GetConfigFromField(dirtyField, currentAudienceId, audienceId);
    const targetKey = getFrom(dirtyField)('id').value;
    const defaultTitle = getFrom(fieldConfig)('ui')('title').value;
    const category = 'field_title';

    return [
      hFields.custom({
        label: 'Localizations',
        hideLabel: true,
        customInput: (
          <Localizations
            audienceId={currentAudienceId}
            category={category}
            defaultTitle={defaultTitle}
            targetKey={targetKey}
          />
        ),
      }),
    ];
  }

  private getFields(activeTab: string): HyperField[] {
    switch (activeTab) {
      case 'attribute':
        return this.getAttributeFields();
      case 'validation':
        return this.getValidationFields();
      case 'language':
        return this.getLocalizationFields();
      default:
        return [];
    }
  }

  private getChoiceFields(): HyperField[] {
    const { isFree } = this.props;
    const dirtyFormat = this.config.format;
    const dependsOn = getFrom(dirtyFormat)('meta')('fieldDependsOn')('dependsOn').value;
    const isDependant = dependsOn != null;
    const isMultiValue = getFrom(dirtyFormat)('meta')('selectValue')('multiValue').value;
    const delimiter = getFrom(dirtyFormat)('meta')('selectValue')('delimiter').defaultTo(',');
    const isMultipleFreetext = getFrom(dirtyFormat)('meta')('selectValue')('multipleFreetext').value;
    const isArray = dirtyFormat.type === 'array';

    const choiceOptions = SchemaUtil.GetChoiceOptionsFromConfig(this.config);

    const choiceValues = (isDependant
      ? choiceOptions.configs
          .filter(c => c.dependsValues.indexOf(getFrom(this.state.activeParentValue)('value').value) > -1)
          .reduce((p, c) => [...p, ...c.fieldValues], [])
      : choiceOptions.values
    ).map(stringToOption);

    const allChoiceValues = (choiceOptions.values || []).concat(
      choiceOptions.configs.reduce((p, c) => [...p, ...c.fieldValues], []),
    );

    const optionsValidationText =
      isMultiValue && allChoiceValues.join('').indexOf(delimiter) > -1
        ? `For multiple choice attributes, no option may contain the delimiter character: ${delimiter}`
        : undefined;

    return [
      hFields.toggle({
        label: 'Display As Tag',
        hintText: 'If active, values are displayed as tags. Otherwise as comma-separated list',
        readOnly: this.isCollectionFormat,
        value: getFrom(dirtyFormat)('meta')('ui')('renderAsTags').defaultTo(false) === true,
        onChange: e => {
          this.editFormat('meta.ui.renderAsTags', e.currentTarget.checked);
        },
        premiumLock: isFree ? ModalKeys.formats : null,
      }),
      !isArray &&
        hFields.toggle({
          label: 'Allow Multiple Choices',
          hintText: !this.isCollectionFormat
            ? 'If active, users can choose more than one option'
            : 'Not allowed on format used for collections',
          readOnly: this.isCollectionFormat,
          value: isMultiValue === true,
          onChange: e => {
            this.editFormat('meta.selectValue', {
              ...this.selectValueDefault,
              ...dirtyFormat.meta.selectValue,
              multiValue: e.currentTarget.checked,
            });
            if (dirtyFormat.type === 'string') {
              this.editFormat('constraints', []);
            }
          },
          premiumLock: isFree ? ModalKeys.formats : null,
        }),
      hFields.toggle({
        label: 'Allow Free Typing',
        hintText: !this.isCollectionFormat
          ? 'If active, users can type in any value'
          : 'Not allowed on format used for collections',
        readOnly: this.isCollectionFormat,
        value: getFrom(dirtyFormat)('meta')('selectValue')('freeform').value === true,
        onChange: e => {
          this.editFormat('meta.selectValue', {
            ...this.selectValueDefault,
            ...dirtyFormat.meta.selectValue,
            freeform: e.currentTarget.checked,
          });
          if (dirtyFormat.type === 'string') {
            this.editFormat('constraints', []);
          }
        },
        premiumLock: isFree ? ModalKeys.formats : null,
      }),
      hFields.toggle({
        label: 'Case Sensitive',
        hintText: !this.isCollectionFormat
          ? 'Should values in directory be audited for case sensitivity'
          : 'Not allowed on format used for collections',
        readOnly: this.isCollectionFormat,
        value: getFrom(dirtyFormat)('meta')('selectValue')('caseSensitive').value === true,
        onChange: e => {
          this.editFormat('meta.selectValue', {
            ...this.selectValueDefault,
            ...dirtyFormat.meta.selectValue,
            caseSensitive: e.currentTarget.checked,
          });
          if (dirtyFormat.type === 'string') {
            this.editFormat('constraints', []);
          }
        },
        premiumLock: isFree ? ModalKeys.formats : null,
      }),
      !isMultipleFreetext &&
        hFields.autocomplete({
          label: 'Options Cascade From',
          readOnly: this.isCollectionFormat,
          hintText: !this.isCollectionFormat
            ? 'If set, options will cascade from the chosen attribute. Must be Dropdown or Autcomplete'
            : 'Not allowed on format used for collections',
          onChange: o => {
            this.editFormat('meta', {
              ...dirtyFormat.meta,
              selectValue: {
                ...dirtyFormat.meta.selectValue,
                values: [],
              },
              fieldDependsOn: {
                dependsOn: o == null ? null : o.value,
                configs: [],
              },
            } as ApiFormat['meta']);
            this.setState({
              activeParentValue:
                o == null
                  ? null
                  : this.getParentValueOptions(o.value).length > 0
                  ? this.getParentValueOptions(o.value)[0]
                  : null,
            });
          },
          value:
            getFrom(dirtyFormat)('meta')('fieldDependsOn')('dependsOn').value == null
              ? null
              : this.getCascadeFieldOptions().filter(o => o.value === dirtyFormat.meta.fieldDependsOn.dependsOn),
          dropdownOptions: this.getCascadeFieldOptions(),
          premiumLock: isFree ? ModalKeys.formats : null,
        }),
      isDependant &&
        hFields.autocomplete({
          label: 'Parent Value',
          required: true,
          dropdownOptions: this.getParentValueOptions(),
          value: this.state.activeParentValue,
          onChange: activeParentValue => this.setState({ activeParentValue }),
        }),
      !isMultipleFreetext &&
        (choiceValues.length < 21
          ? hFields.autocompleteMulti({
              label: 'Options',
              value: choiceValues,
              required: !(dirtyFormat.meta.selectValue || {})['freeform'],
              hintText: (
                <span>
                  Press tab or enter to add option (or{' '}
                  <a onClick={() => this.setState({ bulkOptionString: choiceValues.map(v => v.value).join('\n') })}>
                    Bulk Edit
                  </a>
                  )
                </span>
              ),
              placeholder: 'Type an option...',
              dropdownOptions: choiceValues,
              dropdownCreatable: true,
              onChange: (val, actionMeta) => {
                const { action, removedValue } = actionMeta;
                if (action === 'remove-value') {
                  const cascadingFields = this.getFieldsDependingOnValue(removedValue.value);
                  if (cascadingFields.length > 0) {
                    const fieldNames = cascadingFields.map(
                      (field: ApiField) =>
                        getFrom(field)('audienceConfigs')(this.props.currentAudienceId)('ui')('title').value,
                    );
                    const errorMessage = `You are trying to remove a value that '${fieldNames.join(
                      ', ',
                    )}' depends on. Please remove the configuration from '${fieldNames.join(', ')}' first.`;
                    this.setState({ ...this.state, errorMessage });
                    return;
                  }
                }
                this.setOptions(val ? val.map(v => v.value) : null);
              },
              validationText: optionsValidationText,
              props: {
                components: {
                  DropdownIndicator: null,
                },
                isValidNewOption: inputValue => {
                  if (!inputValue) {
                    return false;
                  }
                  if (isMultiValue && inputValue.indexOf(dirtyFormat.meta.selectValue.delimiter) > -1) {
                    return false;
                  }
                  if (
                    getFrom(dirtyFormat.meta)('selectValues')('value')
                      .defaultTo([])
                      .indexOf(inputValue) > -1
                  ) {
                    return false;
                  }
                  return true;
                },
                noOptionsMessage: ({ inputValue }) => (inputValue ? 'Invalid Option' : null),
                // isOptionUnique: ({option: {label}, options}) => {
                //   const caseSensitive = !!(dirtyFormat.meta.selectValue || {})['caseSensitive'];
                //   const newLabel = !caseSensitive ? (label || '').toLowerCase() : label;
                //   for (const option of options) {
                //     const existingLabel = !caseSensitive ? (option.label || '').toLowerCase() : option.label;
                //     if (newLabel === existingLabel) { return false; }
                //   }
                //   return true;
                // }, TODO: This needs to be worked back out
              },
              premiumLock: isFree ? ModalKeys.formats : null,
            })
          : hFields.custom({
              label: 'Choice Options',
              required: true,
              validationText: optionsValidationText,
              customInput: (
                <p>
                  Large number of options. Please{' '}
                  <a onClick={() => this.setState({ bulkOptionString: choiceValues.map(v => v.value).join('\n') })}>
                    Bulk Edit
                  </a>
                  .
                </p>
              ),
            })),
    ];
  }

  private getImageFields(): HyperField[] {
    const { isFree } = this.props;
    const dirtyFormat = this.config.format;

    const defAllowsFlag = (flag: string) => {
      return ((dirtyFormat.meta['ui'] || {}).allowedFlags || []).indexOf(flag) > -1;
    };

    const toggleAllowedFlag = (flag: string) => {
      const newAllowed = ((dirtyFormat.meta['ui'] || {}).allowedFlags || []).slice();
      const index = newAllowed.indexOf(flag);
      if (index !== -1) {
        newAllowed.splice(index, 1);
      } else {
        newAllowed.push(flag);
      }
      this.editFormat('meta.ui.allowedFlags', newAllowed);
    };

    return [
      hFields.toggle({
        label: 'Allow racy content',
        value: defAllowsFlag(VisionNotes.RacyContent),
        onChange: () => toggleAllowedFlag(VisionNotes.RacyContent),
        premiumLock: isFree ? ModalKeys.photoValidation : null,
      }),
      hFields.toggle({
        label: 'Allow adult content',
        value: defAllowsFlag(VisionNotes.AdultContent),
        onChange: () => toggleAllowedFlag(VisionNotes.AdultContent),
        premiumLock: isFree ? ModalKeys.photoValidation : null,
      }),
      hFields.toggle({
        label: 'Allow no faces',
        value: defAllowsFlag(VisionNotes.NoFaces),
        onChange: () => toggleAllowedFlag(VisionNotes.NoFaces),
        premiumLock: isFree ? ModalKeys.photoValidation : null,
      }),
      hFields.toggle({
        label: 'Allow multiple faces',
        value: defAllowsFlag(VisionNotes.TooManyFaces),
        onChange: () => toggleAllowedFlag(VisionNotes.TooManyFaces),
        premiumLock: isFree ? ModalKeys.photoValidation : null,
      }),
      hFields.toggle({
        label: 'Allow cartoon image',
        value: defAllowsFlag(VisionNotes.Cartoon),
        onChange: () => toggleAllowedFlag(VisionNotes.Cartoon),
        premiumLock: isFree ? ModalKeys.photoValidation : null,
      }),
      hFields.toggle({
        label: 'Allow invalid image',
        value: defAllowsFlag(VisionNotes.InvalidImage),
        onChange: () => toggleAllowedFlag(VisionNotes.InvalidImage),
        premiumLock: isFree ? ModalKeys.photoValidation : null,
      }),
    ];
  }

  private getConstraint = (prop: string) => {
    for (const constraint of this.config.format.constraints) {
      if (constraint.formatConstraint.hasOwnProperty(prop)) {
        return constraint.formatConstraint[prop];
      }
    }
    return null;
  };

  private handleBulkChoiceEdit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.setState({ bulkOptionString: e.currentTarget.value });
  };
}
