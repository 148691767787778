import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  blue?: boolean;
}

export const HyperfishLogo = ({ className, blue, ...rest }: Props) => {
  const fill = blue ? '#512DA8' : '#fff';

  return (
    <svg width="100%" height="100%" viewBox="0 0 308 70" className={className} {...rest} fill={fill}>
      <path d="M101.9 53.5H118.1V59.7H94.4V19.2H101.9V53.5Z" />
      <path d="M130.1 24.4C130.4 23.9 130.5 23.3 130.5 22.7C130.5 22 130.4 21.5 130.1 21C129.8 20.5 129.5 20 129.1 19.6C128.7 19.2 128.2 18.9 127.7 18.7C127.2 18.4 126.6 18.3 126 18.3C125.4 18.3 124.8 18.5 124.3 18.7C123.8 18.9 123.3 19.2 122.9 19.6C122.5 20 122.2 20.5 122 21C121.7 21.5 121.6 22.1 121.6 22.7C121.6 23.3 121.8 23.9 122 24.4C122.2 25 122.5 25.4 122.9 25.8C123.3 26.2 123.8 26.5 124.3 26.7C124.8 27 125.4 27.1 126 27.1C126.6 27.1 127.2 26.9 127.7 26.7C128.2 26.5 128.7 26.2 129.1 25.8C129.5 25.4 129.8 24.9 130.1 24.4ZM129.6 59.7V31H122.7V59.7H129.6Z" />
      <path d="M133.5 31H139.2C139.7 31 140.2 31.1 140.5 31.4C140.8 31.6 141.1 32 141.2 32.3L146.7 47.6C147 48.5 147.3 49.4 147.5 50.2C147.7 51.1 147.9 51.9 148.1 52.8C148.3 51.9 148.5 51.1 148.7 50.2C148.9 49.3 149.2 48.5 149.5 47.6L155.2 32.3C155.3 31.9 155.6 31.6 155.9 31.4C156.2 31.2 156.6 31 157.1 31H162.6L151.2 59.7H144.9L133.5 31Z" />
      <path d="M182.4 31.5C180.9 30.9 179.2 30.6 177.4 30.6C175.3 30.6 173.3 31 171.4 31.7C169.7 32.4 168.3 33.4 167.1 34.7C165.9 36 165.1 37.5 164.4 39.2C163.7 40.9 163.4 42.7 163.4 44.7C163.4 47.3 163.8 49.5 164.5 51.4C165.2 53.3 166.2 54.9 167.5 56.2C168.8 57.5 170.3 58.4 172 59.1C173.7 59.8 175.6 60.1 177.6 60.1C178.516 60.1 179.432 60.0161 180.425 59.9251C180.516 59.9168 180.608 59.9084 180.7 59.9C181.8 59.7 182.8 59.5 183.9 59.1C185 58.7 185.9 58.2 186.9 57.6C187.9 56.9 188.7 56.2 189.5 55.3L187.5 52.8C187.2 52.4 186.8 52.2 186.2 52.2C185.8 52.2 185.4 52.3 184.9 52.6L184.9 52.6C184.4 52.9 183.9 53.2 183.3 53.5C182.828 53.8144 182.295 54.0052 181.7 54.2182C181.538 54.2762 181.371 54.3358 181.2 54.4C180.4 54.7 179.5 54.8 178.3 54.8C177.1 54.8 176.1 54.6 175.2 54.3C174.3 53.9 173.5 53.4 172.8 52.7C172.2 52 171.6 51.1 171.2 50C170.8 48.9 170.5 47.7 170.4 46.2H188.2C188.8 46.2 189.3 46.1 189.5 45.7C189.7 45.4 189.8 44.6 189.8 43.6C189.8 41.6 189.5 39.7 188.9 38.1C188.3 36.5 187.4 35.1 186.3 34C185.2 32.9 183.9 32.1 182.4 31.5ZM172.9 37.2C174 36.1 175.6 35.5 177.6 35.5C178.6 35.5 179.5 35.7 180.3 36C181.1 36.3 181.7 36.8 182.2 37.4C182.7 38 183 38.7 183.3 39.5C183.6 40.3 183.7 41.1 183.7 42H170.7C171 40 171.8 38.3 172.9 37.2Z" />
      <path d="M225.5 19.3V25.5H213.3V59.8H205.7V25.5H193.5V19.3H225.5V19.3Z" />
      <path d="M237.4 24.4C237.7 23.9 237.8 23.3 237.8 22.7C237.8 22 237.7 21.5 237.4 21C237.1 20.5 236.8 20 236.4 19.6C236 19.2 235.5 18.9 235 18.7C234.5 18.4 233.9 18.3 233.3 18.3C232.7 18.3 232.1 18.5 231.6 18.7C231.1 18.9 230.6 19.2 230.2 19.6C229.8 20 229.5 20.5 229.3 21C229 21.5 228.9 22.1 228.9 22.7C228.9 23.3 229.1 23.9 229.3 24.4C229.5 25 229.8 25.4 230.2 25.8C230.6 26.2 231.1 26.5 231.6 26.7C232.1 27 232.7 27.1 233.3 27.1C233.9 27.1 234.5 26.9 235 26.7C235.5 26.5 236 26.2 236.4 25.8C236.8 25.4 237.1 24.9 237.4 24.4ZM236.9 59.7V31H230V59.7H236.9Z" />
      <path d="M250.9 18.1V59.7H244V18.1H250.9Z" />
      <path d="M275.1 31.5C273.6 30.9 271.9 30.6 270.1 30.6C267.9 30.6 266 31 264.1 31.7C262.4 32.4 261 33.4 259.8 34.7C258.6 36 257.8 37.5 257.1 39.2C256.4 40.9 256.1 42.7 256.1 44.7C256.1 47.3 256.5 49.5 257.2 51.4C257.9 53.3 258.9 54.9 260.2 56.2C261.5 57.5 263 58.4 264.7 59.1C266.4 59.8 268.3 60.1 270.3 60.1C271.216 60.1 272.132 60.0161 273.125 59.9251C273.216 59.9168 273.308 59.9084 273.4 59.9C274.5 59.7 275.5 59.5 276.6 59.1C277.7 58.7 278.6 58.2 279.6 57.6C280.6 56.9 281.4 56.2 282.2 55.3L280.2 52.8C279.9 52.4 279.5 52.2 278.9 52.2C278.5 52.2 278.1 52.3 277.6 52.6L277.6 52.6C277.1 52.9 276.6 53.2 276 53.5C275.528 53.8144 274.995 54.0052 274.4 54.2182C274.238 54.2762 274.071 54.3358 273.9 54.4C273.1 54.7 272.2 54.8 271 54.8C269.8 54.8 268.8 54.6 267.9 54.3C267 53.9 266.2 53.4 265.5 52.7C264.9 52 264.3 51.1 263.9 50C263.5 48.9 263.2 47.7 263.1 46.2H280.9C281.5 46.2 282 46.1 282.2 45.7C282.4 45.4 282.5 44.6 282.5 43.6C282.5 41.6 282.2 39.7 281.6 38.1C281 36.5 280.1 35.1 279 34C277.9 32.9 276.6 32.1 275.1 31.5ZM265.5 37.2C266.6 36.1 268.2 35.5 270.2 35.5C271.2 35.5 272.1 35.7 272.9 36C273.7 36.3 274.3 36.8 274.8 37.4C275.3 38 275.6 38.7 275.9 39.5C276.2 40.3 276.3 41.1 276.3 42H263.3C263.6 40 264.4 38.3 265.5 37.2Z" />
      <path d="M305.3 36.6C305.1 36.9 304.9 37.1 304.7 37.2C304.5 37.3 304.2 37.4 303.9 37.4C303.6 37.4 303.2 37.3 302.8 37.1C302.4 36.9 302 36.7 301.5 36.5C301 36.3 300.4 36.1 299.8 35.9C299.2 35.7 298.4 35.6 297.5 35.6C296.1 35.6 295.1 35.9 294.3 36.5C293.5 37.1 293.1 37.8 293.1 38.8C293.1 39.4 293.3 39.9 293.7 40.4C294.1 40.8 294.6 41.2 295.3 41.5C296 41.8 296.7 42.1 297.6 42.4C298.5 42.7 299.3 42.9 300.2 43.2C301.1 43.5 301.9 43.8 302.8 44.2C303.7 44.6 304.4 45.1 305.1 45.7C305.8 46.3 306.3 47 306.7 47.8C307.1 48.6 307.3 49.6 307.3 50.8C307.3 52.2 307.1 53.5 306.6 54.6C306.1 55.8 305.4 56.8 304.4 57.6C303.4 58.4 302.2 59.1 300.8 59.6C299.4 60.1 297.7 60.3 295.9 60.3C294.9 60.3 293.9 60.2 293 60C292.1 59.8 291.2 59.6 290.3 59.3C289.4 59 288.6 58.6 287.9 58.2C287.2 57.8 286.5 57.3 286 56.8L287.6 54.2C287.8 53.9 288 53.6 288.3 53.5C288.6 53.4 288.9 53.2 289.4 53.2C289.8 53.2 290.2 53.3 290.6 53.6C291 53.8 291.4 54.1 291.9 54.4C292.4 54.7 293 54.9 293.7 55.2C294.4 55.5 295.2 55.6 296.3 55.6C297.1 55.6 297.8 55.5 298.4 55.3C299 55.1 299.5 54.8 299.9 54.5C300.3 54.2 300.6 53.8 300.7 53.4C300.8 53 301 52.5 301 52.1C301 51.4 300.8 50.9 300.4 50.4C300 50 299.5 49.6 298.8 49.3C298.1 49 297.4 48.7 296.5 48.4C295.6 48.1 294.8 47.9 293.9 47.6C293 47.3 292.1 47 291.3 46.6C290.5 46.2 289.7 45.7 289 45.1C288.3 44.5 287.8 43.7 287.4 42.8C287 41.9 286.8 40.8 286.8 39.6C286.8 38.4 287 37.3 287.5 36.2C288 35.1 288.7 34.2 289.6 33.4C290.5 32.6 291.6 32 293 31.5C294.4 31 295.9 30.8 297.7 30.8C299.7 30.8 301.5 31.1 303.1 31.8C304.7 32.5 306.1 33.3 307.2 34.4L305.3 36.6Z" />
      <path d="M48.5 30.9L74 16.8L65.3 0L9.8 7.7L48.5 30.9Z" />
      <path d="M12.6 52.1L28.1 44.6L40.4 29.5L7.1 9.60001L0 58.3L12.6 52.1Z" />
      <path d="M47 49.2L28.5 47.7L2.60001 60.3L47 69.5V49.2Z" />
      <path d="M50 33.5V69.2L67.5 49.9L74.3 20.1L50 33.5Z" />
    </svg>
  );
};
