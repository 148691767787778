import { Card } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';

import { Button } from '../../components/';
import { OrgSettingsKeys } from '../../models/api';
import { gettingStartedActions as uiActions } from '../../redux/modules/ui';
import StyleUtil from '../../utils/StyleUtil';
import { Props as ParentProps } from './';

const connector = connect(
  state => ({
    edittingSettings: state.ui.gettingStarted.edittingSettings,
  }),
  { ...uiActions },
);

type Props = typeof connector['props'];

const styles = StyleUtil({
  container: {
    color: StyleUtil.colors.text.dark,
    fontSize: 13,
    lineHeight: 1.5,
  },
  header: {
    color: '#512da8',
    fontSize: 30,
    fontWeight: 300,
    marginBottom: 5,
  },
  subHeader: {
    color: StyleUtil.colors.text.dark,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.5,
    marginBottom: 15,
  },
  fieldList: {
    listStyle: 'none',
  },
  fieldListItem: {
    width: '25%',
    display: 'block',
    float: 'left',
  },
  button: {
    float: 'right',
    marginLeft: 10,
    marginTop: 50,
    height: 30,
    minHeight: 30,
  },
});

const cardStyles = StyleUtil({
  container: {
    position: 'relative',
    paddingLeft: 212,
  },
  header: {
    position: 'absolute',
    left: 20,
    top: '50%',
    transform: 'translateY(-50%)',
    borderRight: '2px solid #979797',
    height: 43,
    width: 180,
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    color: '#747474',
    lineHeight: '43px',
  },
  on: {
    borderRadius: '50%',
    color: StyleUtil.colors.text.light,
    display: 'block',
    fontSize: 13,
    fontWeight: 700,
    height: 30,
    right: 10,
    lineHeight: '28px',
    position: 'absolute',
    textAlign: 'center',
    top: (43 - 31) / 2,
    width: 30,
    backgroundColor: StyleUtil.colors.gray,
  },
  onActive: {
    backgroundColor: StyleUtil.colors.blue,
  },
  copy: {
    fontSize: 13,
    lineHeight: 1.5,
    color: StyleUtil.colors.text.dark,
  },
  summary: {
    fontWeight: 700,
  },
});

@connector
@Radium
export class Configure extends React.Component<Partial<Props> & ParentProps, {}> {
  render() {
    const { currentOrg, updateCurrent, settings } = this.props;

    if (!settings) {
      return (
        <Card>
          <h2>Loading...</h2>
        </Card>
      );
    }

    return (
      <div style={styles['container']}>
        <h1 style={styles['header']}>Recommended Settings</h1>
        <p style={styles['subHeader']}>
          These are the recommended settings for LiveTiles Directory. If something is not quite right, select
          &ldquo;Update Settings&rdquo; and you will be able to make adjustments.
        </p>
        <ConfigureCard
          title="Live Monitoring"
          on={settings[OrgSettingsKeys.audit.schedule.realtime]}
          copy="The real time scan analyzes your Active Directory every 15 minutes for changes."
        />
        <ConfigureCard
          title="Approval"
          on={true}
          copy={
            true
              ? ' Changes confirmed by users will be made without requiring approval.'
              : ' Changes made by users will not be made until an admin has approved them.'
          }
        />
        <ConfigureCard
          title="Suggestions"
          on={true}
          copy="In a future version LiveTiles Directory will look for and suggest information to populate their user profile."
        />
        <ConfigureCard
          title="Hyperbot"
          on={settings[OrgSettingsKeys.broadcast.enabled]}
          copy="Hyperbot will attempt to collect missing information and correct inaccurate information from users once a day via email until the information is collected or corrected."
        />
        <p>Hyperbot will target the following fields in the order of worst to best:</p>
        <ul style={styles['fieldList']} className="clearfix">
          {[
            'Phone',
            'Company',
            'Reports To',
            'Country',
            'Mobile',
            'Department',
            'Address',
            'Zip Code',
            'Fax',
            'Manager',
            'City',
            'Profile Picture',
            'Home Phone',
            'Assistant',
            'State',
            'Title',
          ].map(f => (
            <li key={f} style={styles['fieldListItem']}>
              • {f}
            </li>
          ))}
        </ul>
        <div className="clearfix">
          <Button
            type="primary"
            style={styles['button']}
            size="medium"
            onClick={() =>
              updateCurrent({
                settings: {
                  [currentOrg.id]: {
                    [OrgSettingsKeys.onboarding.step]: 'run',
                  },
                },
              })
            }
          >
            CONTINUE
          </Button>
          <Button
            disabled={true}
            type="primary"
            style={styles['button']}
            size="medium"
            onClick={() => this.props.editSettings(true)}
          >
            UPDATE SETTINGS
          </Button>
        </div>
      </div>
    );
  }
}

interface ConfigureCardProps {
  title: string;
  on: boolean;
  copy: string;
}
const ConfigureCard = Radium((props: ConfigureCardProps) => (
  <Card style={cardStyles['container']}>
    <header style={cardStyles['header']}>
      <span style={cardStyles['title']}>{props.title}</span>
      <span
        style={
          {
            ...cardStyles['on'],
            ...(props.on ? cardStyles['onActive'] : {}),
          } as any
        }
      >
        {props.on ? 'ON' : 'OFF'}
      </span>
    </header>
    <p style={cardStyles['copy']}>
      <span style={cardStyles['summary']}>
        {`${props.title} ${props.title.slice(-1) === 's' ? 'are' : 'is'} ${props.on ? 'ON' : 'OFF'}. `}
      </span>
      {props.copy}
    </p>
  </Card>
));
