import cx from 'classnames';
import React from 'react';

import { show as showPremiumModal } from '../../redux/modules/premiumModals';
import { PremiumLock } from '../PremiumLock';
import { ModalKeys } from '../PremiumModal';
import classes from './styles.module.scss';

interface Props {
  options: {
    value: string | number;
    label?: string;
    icon?: any;
    disabled?: boolean;
    premiumLock?: ModalKeys;
  }[];
  value?: string | number;
  onChange?: (value: string | number) => void;
  disabled?: boolean;
  showPremiumModal?: typeof showPremiumModal;
}

interface State {
  translate?: number;
  internalValue?: string | number;
}

export class Slider extends React.Component<Props, State> {
  static lockClass = classes.lock;
  internalValue;
  offsets = {};
  resizeTicking = false;
  startX: number;
  ticking = false;

  constructor(props: Props) {
    super(props);
    this.state = { translate: 0 };
  }

  get options() {
    return this.props.options && this.props.options.filter(o => !!o);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.internalValue !== nextProps.value) {
      this.internalValue = nextProps.value;
      this.setState({ internalValue: nextProps.value });
    }
  }

  componentDidMount() {
    this.internalValue = this.props.value;
    this.setOptionPositions();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    this.removeEventListeners();
    window.removeEventListener('resize', this.handleResize);
  }

  setOptionPositions() {
    const ticks = this.props.options.map(o => ({
      val: String(o.value),
      // eslint-disable-next-line react/no-string-refs
      tick: this.refs[String(o.value)] as HTMLElement,
    }));

    for (const tick of ticks) {
      this.offsets[tick.val] = this.getXOffset(tick.tick);
    }
  }

  getXOffset(el: HTMLElement) {
    if (!el) {
      return 0;
    }
    // http://stackoverflow.com/a/26230989/1589521
    const box = el.getBoundingClientRect(),
      body = document.body,
      docEl = document.documentElement,
      scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft,
      clientLeft = docEl.clientLeft || body.clientLeft || 0,
      left = box.left + scrollLeft - clientLeft;

    return left;
  }

  getNearest(x: number) {
    const options = this.options.filter(o => !o.disabled && !o.premiumLock);
    let nearest, minDist;

    for (const option of options) {
      const val = option.value;
      const offset = this.offsets[String(val)];
      const dist = Math.abs(offset - x);
      if (typeof nearest === 'undefined' || dist < minDist) {
        nearest = val;
        minDist = Math.abs(x - offset);
      }
    }

    return nearest;
  }

  handleResize = () => {
    // TODO: If I set option positions on mousedown, not sure I need to on resize...
    if (!this.resizeTicking) {
      window.requestAnimationFrame(() => {
        this.setOptionPositions();
        this.resizeTicking = false;
      });
    }
    this.resizeTicking = true;
  };

  removeEventListeners = () => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp as any);
  };

  handleMouseMove = (e: MouseEvent) => {
    const internalValue = this.getNearest(e.pageX);
    if (internalValue !== this.internalValue) {
      this.internalValue = internalValue;
      this.setState({ internalValue });
    }
  };

  handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    if (this.props.disabled) {
      return;
    }

    this.setOptionPositions();
    this.startX = e.pageX;

    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseUp = () => {
    if (this.internalValue !== this.props.value) {
      this.props.onChange(this.internalValue);
    }
    this.removeEventListeners();
  };

  render() {
    const { value, onChange, disabled, showPremiumModal } = this.props;
    const options = this.options;
    const { internalValue } = this.state;

    return (
      <div className={cx(classes.container, { [classes.disabled]: disabled })}>
        {options &&
          options.map((o, i) => (
            <button
              className={cx(classes.option, {
                [classes.disabled]: o.disabled || !!o.premiumLock || disabled,
              })}
              key={o.value}
              type="button"
              onClick={
                !!onChange && !o.disabled && !disabled && !o.premiumLock && value !== o.value
                  ? () => onChange(o.value)
                  : undefined
              }
            >
              <div className={classes.iconContainer}>
                {o.icon}
                {o.premiumLock && (
                  <PremiumLock
                    className={classes.lock}
                    tooltipDir={i + 1 > options.length / 2 ? 'left' : 'right'}
                    onClick={!showPremiumModal ? null : () => showPremiumModal(o.premiumLock)}
                  />
                )}
              </div>
              {o.label && <span className={classes.label}>{o.label}</span>}
              <span ref={String(o.value)} className={classes.tick} />
              <img
                src={require('../../assets/images/slider-button.png')}
                alt="Slider"
                className={
                  (typeof internalValue !== 'undefined' ? internalValue : value) === o.value
                    ? classes.slider
                    : classes.hide
                }
                draggable={false}
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
              />
            </button>
          ))}
      </div>
    );
  }
}
