import { Card, Button, ButtonContainer } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';

import { OrgSettingsKeys } from '../../models/api';
import { AZURE_APP_NAME } from '../../redux/modules/providers';
import StyleUtil from '../../utils/StyleUtil';
import { Props } from './';

const styles = StyleUtil({
  container: {
    textAlign: 'center',
  },
  header: {
    fontSize: '2.5rem',
    marginBottom: '1.5rem',
  },
});

@Radium
export class Connect extends React.Component<Props, {}> {
  render() {
    const { updateCurrent, currentOrg, providers } = this.props;

    if (currentOrg && !currentOrg.connected) {
      return (
        <Card style={styles['container']}>
          <h2 style={styles['header']}>Something didn&apos;t work&hellip;</h2>
          <p>We were not able to establish a connection with your Azure Active Directory.</p>
          <p>Please make sure that you are succesfully signing in with an authorized administrator account.</p>
          <br />
          <Button
            color="primary"
            variant="solid"
            onClick={() =>
              (location.href = `${providers.filter(p => p.name === AZURE_APP_NAME)[0].href}&prompt=consent`)
            }
            style={styles['button']}
            size="medium"
          >
            TRY AGAIN
          </Button>
        </Card>
      );
    }

    return (
      <Card style={styles['container']}>
        <h2 style={styles['header']}>
          We found: {currentOrg && currentOrg.name}
          <br />
          Is this the directory you want to analyze?
        </h2>
        <ButtonContainer>
          <Button
            color="primary"
            variant="solid"
            size="medium"
            onClick={() =>
              updateCurrent({
                settings: {
                  [currentOrg.id]: {
                    [OrgSettingsKeys.onboarding.step]: 'analyze',
                  },
                },
              })
            }
            style={{ ...styles['button'], marginRight: 10 }}
          >
            YES, LET&apos;S GO!
          </Button>
          <Button
            onClick={() =>
              (location.href = `${providers.filter(p => p.name === AZURE_APP_NAME)[0].href}&prompt=consent`)
            }
            style={styles['button']}
            size="medium"
          >
            No, log in with different credentials
          </Button>
        </ButtonContainer>
      </Card>
    );
  }
}
