import { UserSearchRequest } from '@hyperfish/antrea-api-contracts';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { GlobalState } from '../../../models/client/';
import { Action, LoadAction } from './actions';
import * as types from './types';
import { ApiClient } from '@hyperfish/fishfood';

type State = GlobalState['violationUsers'];

const initialState = {} as State;

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case types.LOAD:
      return {
        ...state,
        request: action.request,
        loading: true,
      };
    case types.LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.LOAD_SUCCESS:
      return {
        ...state,
        data: action.result,
        loading: false,
        error: null,
      };
    case types.CLEAR:
      return {
        ...state,
        data: initialState.data,
        request: initialState.request,
      };
    default:
      return state;
  }
}

// ACTION CREATORS
export function load(request: UserSearchRequest): LoadAction {
  return {
    type: types.LOAD,
    request,
  };
}

export const clear = () => ({ type: types.CLEAR });

// SAGAS
function* loadUsersSaga(client: ApiClient, action: LoadAction) {
  // Debounce by 500ms
  yield delay(500);

  // tslint:disable-next-line:no-unused-variable
  const { request: data } = action;
  try {
    const result = yield call([client, client.post], `/analytics/violation-search`, { data });
    yield put({ ...action, type: types.LOAD_SUCCESS, result: result.data });
  } catch (error) {
    yield put({ ...action, type: types.LOAD_FAIL, error });
  }
}

export function* loadUsersWatch(client) {
  yield takeLatest(types.LOAD, loadUsersSaga, client);
}
