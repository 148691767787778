import React from 'react';
import styled from 'styled-components';

import { OrgSettingsKeys } from '../../models/api';
import { Props } from './';
import { Card, Button } from '@hyperfish/fishfood';

const CenterCard = styled(Card)`
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
`;

export class Analyze extends React.Component<Props, {}> {
  render() {
    const { updateCurrent, createAudit, currentOrg } = this.props;

    return (
      <CenterCard>
        <h2>LiveTiles Directory is analyzing your Active Directory&hellip;</h2>
        <Button
          color="primary"
          variant="solid"
          size="medium"
          onClick={() => {
            (createAudit(currentOrg.type === 'Online' ? 'online' : 'external') as any).then(() =>
              updateCurrent({
                settings: {
                  [currentOrg.id]: {
                    [OrgSettingsKeys.onboarding.step]: 'complete',
                  },
                },
              }),
            );
          }}
        >
          CONTINUE
        </Button>
      </CenterCard>
    );
  }
}
