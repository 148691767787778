const colors = {
  blue: '#512da8',
  lightBlue: '#b5e2fa',
  gray: '#9b9b9b',
  lightGray: '#d4d4d4',
  otherLightGray: '#d5d5d5',
  green: '#6ae6c6',
  orange: '#f2cb61',
  red: '#f82a2a',

  text: {
    dark: '#3d3a3b',
    light: '#fff',
  },
};

export default colors;
