export const LOAD = 'antrea/field-schema/LOAD';
export const LOAD_SUCCESS = 'antrea/field-schema/LOAD_SUCCESS';
export const LOAD_FAIL = 'antrea/field-schema/LOAD_FAIL';

export const LOAD_FIELDS = 'antrea/field-schema/LOAD_FIELDS';
export const LOAD_FIELDS_SUCCESS = 'antrea/field-schema/LOAD_FIELDS_SUCCESS';
export const LOAD_FIELDS_FAIL = 'antrea/field-schema/LOAD_FIELDS_FAIL';

export const FIELD_EDIT_START = 'antrea/field-schema/FIELD_EDIT_START';
export const FIELD_CREATE_START = 'antrea/field-schema/FIELD_CREATE_START';
export const FIELD_EDIT = 'antrea/field-schema/FIELD_EDIT';
export const FIELD_EDIT_CONFIG = 'antrea/field-schema/FIELD_EDIT_CONFIG';
export const FIELD_EDIT_OVERRIDE = 'antrea/field-schema/FIELD_EDIT_OVERRIDE';
export const FIELD_CREATE = 'antrea/field-schema/FIELD_CREATE';
export const FIELD_CREATE_SUCCESS = 'antrea/field-schema/FIELD_CREATE_SUCCESS';
export const FIELD_CREATE_FAIL = 'antrea/field-schema/FIELD_CREATE_FAIL';
export const FIELD_UPDATE = 'antrea/field-schema/FIELD_UPDATE';
export const FIELD_UPDATE_SUCCESS = 'antrea/field-schema/FIELD_UPDATE_SUCCESS';
export const FIELD_UPDATE_FAIL = 'antrea/field-schema/FIELD_UPDATE_FAIL';
export const FIELD_DELETE_START = 'antrea/field-schema/FIELD_DELETE_START';
export const FIELD_DELETE_CANCEL = 'antrea/field-schema/FIELD_DELETE_CANCEL';
export const FIELD_DELETE = 'antrea/field-schema/FIELD_DELETE';
export const FIELD_DELETE_SUCCESS = 'antrea/field-schema/FIELD_DELETE_SUCCESS';
export const FIELD_DELETE_FAIL = 'antrea/field-schema/FIELD_DELETE_FAIL';

export const GROUP_EDIT_START = 'antrea/field-schema/GROUP_EDIT_START';
export const GROUP_CREATE_START = 'antrea/field-schema/GROUP_CREATE_START';
export const GROUP_EDIT = 'antrea/field-schema/GROUP_EDIT';
export const GROUPS_UPDATE = 'antrea/field-schema/GROUPS_UPDATE';
export const GROUPS_UPDATE_SUCCESS = 'antrea/field-schema/GROUPS_UPDATE_SUCCESS';
export const GROUPS_UPDATE_FAIL = 'antrea/field-schema/GROUPS_UPDATE_FAIL';
export const GROUP_DELETE_START = 'antrea/field-schema/GROUP_DELETE_START';
export const GROUP_DELETE_CANCEL = 'antrea/field-schema/GROUP_DELETE_CANCEL';

export const SEARCH_FIELDS = 'antrea/field-schema/SEARCH_FIELDS';
export const SEARCH_FIELDS_SUCCESS = 'antrea/field-schema/SEARCH_FIELDS_SUCCESS';
export const SEARCH_FIELDS_FAIL = 'antrea/field-schema/SEARCH_FIELDS_FAIL';
export const SEARCH_FIELDS_CLEAR = 'antrea/field-schema/SEARCH_FIELDS_CLEAR';
