import { GlobalState } from '../../../models/client/';
import { EmailAction } from './actions';

import * as types from './types';
import { ApiClient } from '@hyperfish/fishfood';

type State = GlobalState['emails'];

// UTILITY FUNCTIONS
function deepSet(obj: object, { id }, value: any) {
  return {
    ...obj,
    [id]: value,
  };
}

const initialState = {
  detailById: {},
  loadingDetailById: {},
  loadingDetailErrorById: {},
} as State;

export default function reducer(state: State = initialState, action: EmailAction): State {
  switch (action.type) {
    case types.LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.LOAD_SUCCESS:
      return {
        ...state,
        data: action.result.emails,
        loading: false,
      };
    case types.LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.LOAD_DETAIL:
      return {
        ...state,
        loadingDetailById: deepSet(state.loadingDetailById, action, true),
        loadingDetailErrorById: deepSet(state.loadingDetailErrorById, action, null),
      };
    case types.LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetailById: deepSet(state.loadingDetailById, action, false),
        detailById: deepSet(state.detailById, action, action.result),
      };
    case types.LOAD_DETAIL_FAIL:
      return {
        ...state,
        loadingDetailById: deepSet(state.loadingDetailById, action, false),
        loadingDetailErrorById: deepSet(state.loadingDetailErrorById, action, action.error),
      };
    default:
      return state;
  }
}

// Action Creators
export function load(params?: { email: string; limit?: number }) {
  return {
    types: [types.LOAD, types.LOAD_SUCCESS, types.LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/orgs/current/emails', { params }),
    notifications: {
      fail: 'Failed to load emails',
    },
  };
}

export function loadDetail(id: string) {
  return {
    types: [types.LOAD_DETAIL, types.LOAD_DETAIL_SUCCESS, types.LOAD_DETAIL_FAIL],
    promise: (client: ApiClient) => client.get(`/orgs/current/emails/${id}`),
    id,
    notifications: {
      fail: 'Failed to load email detail',
    },
  };
}
