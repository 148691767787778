import cx from 'classnames';
import Radium from 'radium';
import React from 'react';

import { Props, UserSelection } from '../../';
import { Button, Icon, PermissionsTable } from '../../../../components/';
import { OrgSettingsKeys } from '../../../../models/api';
import { AZURE_APP_NAME } from '../../../../redux/modules/providers';
import StyleUtil from '../../../../utils/StyleUtil';
import classes from './styles.module.scss';
import { LoadingSplash, Modal } from '@hyperfish/fishfood';

interface State {
  promptSkip: boolean;
  showSample: boolean;
}

const styles = StyleUtil({
  header: {
    color: StyleUtil.colors.blue,
    fontSize: 30,
    marginBottom: 20,
    marginTop: 60 - 36,
  },
  optionHeader: {
    color: StyleUtil.colors.blue,
    display: 'block',
    fontSize: 28,
    fontWeight: 300,
    marginTop: 20,
  },
  skipLink: {
    display: 'inline-block',
    marginRight: 10,
  },
});

@Radium
export class Select extends React.Component<Props, State> {
  componentDidMount() {
    const { currentOrg, loadPermissions, connected, setUserSelection } = this.props;
    loadPermissions(currentOrg.id);
    if (connected) {
      setUserSelection(UserSelection.all);
    }
  }

  handleNext = async () => {
    const { currentOrg, permissionsByAudienceDirty, savePermissions, setNemoRun, userSelection } = this.props;

    if (userSelection == null) {
      return;
    }

    if (!!permissionsByAudienceDirty[currentOrg.id]) {
      await savePermissions(permissionsByAudienceDirty[currentOrg.id]);
    }

    setNemoRun();
  };

  handleSkip = () => {
    const { currentOrg, updateCurrent, createAudit } = this.props;

    updateCurrent({
      settings: {
        [currentOrg.id]: {
          [OrgSettingsKeys.onboarding.step]: 'complete',
        },
      },
    });
    createAudit(currentOrg.type === 'Online' ? 'online' : 'external');
  };

  render() {
    const { settings, userSelection, setUserSelection } = this.props;

    if (!settings) {
      return <LoadingSplash />;
    }

    return (
      <div className={classes.container}>
        <h1 style={styles.header}>Select who you would like to collect profile pictures from</h1>
        <p className={classes.topCopy}>
          LiveTiles Directory will contact users via email and Skype to collect Profile Pictures.&nbsp;
          <a onClick={() => this.setState({ showSample: true })}>View sample email</a>
        </p>
        <div className={classes.optionsContainer}>
          <a
            onClick={() => setUserSelection(UserSelection.specific)}
            className={cx(classes.option, {
              [classes.active]: userSelection === UserSelection.specific,
              [classes.inactive]: userSelection === UserSelection.all,
            })}
          >
            <span className={classes.optionTitle}>Select Individual Users</span>
            <div className={classes.optionIcon}>
              <img src={require('./assets/pilot-icon.svg')} />
              <span>Pilot Mode</span>
            </div>
          </a>
          <a
            onClick={() => setUserSelection(UserSelection.all)}
            className={cx(classes.option, {
              [classes.active]: userSelection === UserSelection.all,
              [classes.inactive]: userSelection === UserSelection.specific,
            })}
          >
            <span className={classes.optionTitle}>All Users</span>
            <span className={classes.optionCopy}>
              Active Directory Administrator
              <br />
              permissions required.
            </span>
            <div className={classes.optionIcon}>
              <img src={require('./assets/run-icon.svg')} />
              <span>Run Mode</span>
            </div>
          </a>
          {userSelection != null && (
            <div className={classes.optionDetails}>
              {userSelection === UserSelection.specific && this.renderPilotList()}
              {userSelection === UserSelection.all && this.renderRunDetail()}
            </div>
          )}
        </div>
        <Modal.ButtonContainer>
          <a style={styles.skipLink} onClick={() => this.setState({ promptSkip: true })}>
            Skip Onboarding
          </a>
          <Button
            type="primary"
            size="medium"
            onClick={this.handleNext}
            disabled={userSelection == null || (userSelection === UserSelection.all && !this.props.connected)}
          >
            NEXT
          </Button>
        </Modal.ButtonContainer>
        {!!this.state.promptSkip && this.renderSkipModal()}
        {!!this.state.showSample && this.renderEmailModal()}
      </div>
    );
  }

  renderRunDetail() {
    const { providers, currentOrg, connected } = this.props;
    const refreshUrl =
      providers &&
      `${providers
        .filter(p => p.name === AZURE_APP_NAME)[0]
        .href.replace('/common/', `/${currentOrg.remoteTenant}/`)}&prompt=consent`;

    return connected ? (
      <div className={classes.runAllSet}>
        <span>You are all set!</span>
        <span className={classes.runAllSetIcon}>
          <Icon name="thumbs-up" />
        </span>
        <span>Write Access Granted</span>
      </div>
    ) : (
      <div className={classes.runContainer}>
        <p className={classes.runCopy}>
          Run LiveTiles Directory on your whole organization. Only users who are missing a profile picture will be
          contacted.
          <br />
          Please sign in to Office 365 and give administrative consent to the LiveTiles Directory application.
        </p>
        <Button size="small" type="primary" disabled={!refreshUrl} onClick={() => (location.href = refreshUrl)}>
          GRANT WRITE ACCESS
        </Button>
        <div className={classes.runDetail}>
          <div>
            <p className={classes.runCopy}>With this consent LiveTiles Directory will be able to:</p>
            <ul>
              <li>
                Read user profiles to analyze and provide statistics on the completeness of basic profile information
                including: photo, display name, first and last name, mobile phone, business phones, email, manager, job
                title, department, office location, street address, city, state, postal code, and country.
              </li>
              <li>Write approved changes to profile pictures.</li>
            </ul>
            <p className={classes.runCopy}>Here are some things you should know:</p>
            <ol>
              <li>
                You must have an Office 365 Global administrator account to grant LiveTiles Directory these permissions.
              </li>
              <li>You will be asked to consent to read and write for all user&apos;s full profiles.</li>
              <li>At no time will LiveTiles Directory be given your account credentials.</li>
              <li>
                You can remove LiveTiles Directory&apos;s permissions from your Azure AD admin portal at any time.
              </li>
            </ol>
            <a href="https://www.hyperfish.com/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </div>
          <div>
            <img src={require('./assets/write_consent.jpg')} alt="Screenshot of Consent" />
          </div>
        </div>
      </div>
    );
  }

  renderPilotList() {
    const {
      currentOrg,
      editPermissions,
      permissionsByAudience,
      permissionsByAudienceError,
      permissionsByAudienceDirty,
      permissionsByAudienceLoading,
      loadUsers,
      clearUsers,
      users,
      usersLoading,
      usersById,
    } = this.props;

    if (!currentOrg) {
      return;
    }

    const loading = permissionsByAudienceLoading[currentOrg.id];
    const permissions = permissionsByAudienceDirty[currentOrg.id] || permissionsByAudience[currentOrg.id];
    const error = permissionsByAudienceError[currentOrg.id];

    if (loading || !permissions) {
      return <LoadingSplash />;
    } else if (error) {
      return <p>We weren&apos;t able to load your pilot participants. Please try again later.</p>;
    } else {
      return (
        <div>
          <p className={classes.topCopy}>
            Pilot LiveTiles Directory with a small set of users first before opening it up to your whole organization.
            <br />
            Only the users who are missing profile pictures will be contacted.
          </p>
          <PermissionsTable
            onEdit={editPermissions}
            audiencePayload={permissions}
            role="Pilot"
            loadUsers={loadUsers}
            clearUsers={clearUsers}
            users={users}
            usersLoading={usersLoading}
            usersById={usersById}
            // tableClasses={{
            //   tableContainer: classes.permissionsTableContainer,
            // }}
          />
        </div>
      );
    }
  }

  renderSkipModal() {
    const closeModal = () => this.setState({ promptSkip: false });

    return (
      <Modal onClose={closeModal}>
        <div className={classes.skipModalCopy}>
          <Modal.Header>Are you sure you want to skip onboarding?</Modal.Header>
          <p>If you select yes,</p>
          <ol>
            <li>
              <Icon name="chevron-right" />
              You will be taken to the Home page of LiveTiles Directory.
            </li>
            <li>
              <Icon name="chevron-right" />
              LiveTiles Directory will be in Analyze mode.
            </li>
            <li>
              <Icon name="chevron-right" />
              Users will not be contacted until you put LiveTiles Directory into Pilot or Run mode.
            </li>
          </ol>
          <Modal.ButtonContainer>
            <Button size="medium" onClick={closeModal} style={StyleUtil.styles.modals.spacedBtn}>
              NO
            </Button>
            <Button size="medium" type="primary" onClick={this.handleSkip}>
              YES
            </Button>
          </Modal.ButtonContainer>
        </div>
      </Modal>
    );
  }

  renderEmailModal() {
    return (
      <Modal onClose={() => this.setState({ showSample: false })}>
        <img src={require('./assets/SampleEmail.png')} alt="Screenshot of sample email" />
      </Modal>
    );
  }
}
