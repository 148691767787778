import Radium from 'radium';
import React from 'react';

import StyleUtil from '../utils/StyleUtil';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};
interface State {
  id: string;
}

const S = StyleUtil({
  wrapper: {
    cursor: 'pointer',
    display: 'inline-block',
    marginRight: 20,
    position: 'relative',
    verticalAlign: 'middle',
    width: 20,
  },
  label: {
    verticalAlign: 'middle',
  },
  svg: {
    display: 'block',
    margin: '0 auto',
    overflow: 'visible',
  },
  hiddenCheckbox: {
    // visibility: 'hidden',
    cursor: 'pointer',
    height: 20,
    left: '50%',
    margin: 0,
    opacity: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: 20,
  },
});

@Radium
export class Radio extends React.Component<Props, State> {
  static instanceCount = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.id || `Radio-${++this.constructor['instanceCount']}`,
    };
  }

  render() {
    const { checked, name, style, label, ...rest } = this.props;
    const { id } = this.state;

    return (
      <div style={style}>
        <span style={S.wrapper}>
          <label htmlFor={id}>{checked ? this.renderCheck() : this.renderBox()}</label>
          <input type="radio" id={id} name={name || id} style={S.hiddenCheckbox} checked={checked} {...rest} />
        </span>
        {label && (
          <label htmlFor={id} style={S.label}>
            {label}
          </label>
        )}
      </div>
    );
  }

  renderCheck() {
    return (
      <svg style={S.svg} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" stroke="#9b9b9b" fill="#fff" strokeWidth="1" />
        <circle cx="10" cy="10" r="7" strokeWidth="0" fill={StyleUtil.colors.blue} />
      </svg>
    );
  }

  renderBox() {
    return (
      <svg style={S.svg} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" stroke="#9b9b9b" fill="#fff" strokeWidth="1" />
      </svg>
    );
  }
}
