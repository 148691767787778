import { ProvidersState as State } from '../../models/client/';
import { ApiClient } from '@hyperfish/fishfood';

const LOAD = 'antrea/providers/LOAD';
const LOAD_SUCCESS = 'antrea/providers/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/providers/LOAD_FAIL';
const CLEAR = 'antrea/providers/CLEAR';

const initialState: State = {
  loaded: false,
};

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case CLEAR:
      return {
        ...state,
        data: null,
        loaded: false,
      };
    default:
      return state;
  }
}

export const AZURE_NAME = 'Azure AD';
// export const AZURE_ADMIN_NAME = 'Azure AD Admin';
export const AZURE_APP_NAME = 'Azure AD App-Only';
export const AZURE_EMAIL = 'Azure AD Email Admin';

export function isLoaded(globalState) {
  return globalState.providers && globalState.providers.loaded;
}

export function shouldLoad(globalState) {
  return globalState.providers && !globalState.providers.loading;
}

export function loadConsent(orgId: string) {
  const redirectUri = window.location.href;
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) =>
      client.request('GET', '/auth/providers', {
        params: { redirect_uri: redirectUri, org_id: orgId },
      }),
  };
}

export function load(orgId: string, returnUrl?: string, noReturn = false) {
  const origin = window.location.origin
    ? window.location.origin
    : `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`;

  const redirectUri = noReturn
    ? `${origin}${returnUrl}`
    : `${origin}/auth/return${returnUrl ? `?ret=${returnUrl}` : ''}`;

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) =>
      client.request('GET', '/auth/providers', {
        params: { redirect_uri: redirectUri, org_id: orgId },
      }),
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}
