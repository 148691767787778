import { Card } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';

import { Button } from '../../components/';
import { OrgSettingsKeys } from '../../models/api';
import { Props } from './';

@Radium
export class Run extends React.Component<Props, {}> {
  render() {
    const { updateCurrent, currentOrg } = this.props;

    return (
      <Card>
        <h2>Run</h2>
        <p>You&apos;re all set up and ready to go!</p>
        <Button
          type="primary"
          size="medium"
          onClick={() => {
            updateCurrent({
              settings: {
                [currentOrg.id]: {
                  [OrgSettingsKeys.onboarding.step]: 'complete',
                },
              },
            });
          }}
        >
          GO TO DASHBOARD
        </Button>
      </Card>
    );
  }
}
