// export const LOAD_SAGA = 'antrea/externalusers/LOAD_SAGA';
export const LOAD = 'antrea/externalUsers/LOAD';
export const LOAD_SUCCESS = 'antrea/externalUsers/LOAD_SUCCESS';
export const LOAD_FAIL = 'antrea/externalUsers/LOAD_FAIL';
export const CLEAR = 'antrea/externalUsers/CLEAR';

export const LOAD_DETAIL = 'antrea/externalUsers/LOAD_DETAIL';
export const LOAD_DETAIL_SUCCESS = 'antrea/externalUsers/LOAD_DETAIL_SUCCESS';
export const LOAD_DETAIL_FAIL = 'antrea/externalUsers/LOAD_DETAIL_FAIL';

export const SAVE_DETAIL = 'antrea/externalUsers/SAVE_DETAIL';
export const SAVE_DETAIL_SUCCESS = 'antrea/externalUsers/SAVE_DETAIL_SUCCESS';
export const SAVE_DETAIL_FAIL = 'antrea/externalUsers/SAVE_DETAIL_FAIL';

export const SAVE_PHOTO = 'antrea/externalUsers/SAVE_PHOTO';
export const SAVE_PHOTO_SUCCESS = 'antrea/externalUsers/SAVE_PHOTO_SUCCESS';
export const SAVE_PHOTO_FAIL = 'antrea/externalUsers/SAVE_PHOTO_FAIL';

export { SAVE_SECTION_SUCCESS } from '../self';
