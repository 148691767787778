export const LOAD = 'antrea/broadcasts/LOAD';
export const LOAD_SUCCESS = 'antrea/broadcasts/LOAD_SUCCESS';
export const LOAD_FAIL = 'antrea/broadcasts/LOAD_FAIL';

export const LOAD_TEMPLATE = 'antrea/broadcasts/LOAD_TEMPLATE';
export const LOAD_TEMPLATE_SUCCESS = 'antrea/broadcasts/LOAD_TEMPLATE_SUCCESS';
export const LOAD_TEMPLATE_FAIL = 'antrea/broadcasts/LOAD_TEMPLATE_FAIL';

export const PATCH_TEMPLATE = 'antrea/broadcasts/PATCH_TEMPLATE';
export const PATCH_TEMPLATE_SUCCESS = 'antrea/broadcasts/PATCH_TEMPLATE_SUCCESS';
export const PATCH_TEMPLATE_FAIL = 'antrea/broadcasts/PATCH_TEMPLATE_FAIL';

export const LOAD_PREVIEW = 'antrea/broadcasts/LOAD_PREVIEW';
export const LOAD_PREVIEW_SUCCESS = 'antrea/broadcasts/LOAD_PREVIEW_SUCCESS';
export const LOAD_PREVIEW_FAIL = 'antrea/broadcasts/LOAD_PREVIEW_FAIL';

export const CLEAR_PREVIEW = 'antrea/broadcasts/CLEAR_PREVIEW';
