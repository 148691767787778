import Cookies from 'universal-cookie';

import { TRIAL_AUDIT } from '../../config';
import { AuditsState as State, GlobalState } from '../../models/client/';
import { ApiClient } from '@hyperfish/fishfood';

const LOAD = 'antrea/audits/LOAD';
const LOAD_SUCCESS = 'antrea/audits/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/audits/LOAD_FAIL';

const LOAD_DETAIL = 'antrea/audits/LOAD_DETAIL';
const LOAD_DETAIL_SUCCESS = 'antrea/audits/LOAD_DETAIL_SUCCESS';
const LOAD_DETAIL_FAIL = 'antrea/audits/LOAD_DETAIL_FAIL';

const LOAD_LATEST = 'antrea/audits/LOAD_LATEST';
const LOAD_LATEST_SUCCESS = 'antrea/audits/LOAD_LATEST_SUCCESS';
const LOAD_LATEST_FAIL = 'antrea/audits/LOAD_LATEST_FAIL';

const CREATE = 'antrea/audits/CREATE';
const CREATE_SUCCESS = 'antrea/audits/CREATE_SUCCESS';
const CREATE_FAIL = 'antrea/audits/CREATE_FAIL';

const initialState: State = {
  details: {},
  errorDetails: {},
  loadingDetails: {},
  trialAudit: new Cookies().get(TRIAL_AUDIT),
};

export default function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.audits.sort((a, b) => {
          return new Date(b.startTime).valueOf() - new Date(a.startTime).valueOf();
        }),
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error,
      };
    case LOAD_LATEST:
      return {
        ...state,
        loadingLatest: true,
      };
    case LOAD_LATEST_SUCCESS:
      return {
        ...state,
        latest: action.result,
        loadingLatest: false,
        latestError: null,
      };
    case LOAD_LATEST_FAIL:
      return {
        ...state,
        loadingLatest: false,
        latestError: action.error,
      };
    case LOAD_DETAIL:
      return {
        ...state,
        loadingDetails: {
          ...state.loadingDetails,
          [action.id]: true,
        },
      };
    case LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetails: {
          ...state.loadingDetails,
          [action.id]: false,
        },
        errorDetails: {
          ...state.errorDetails,
          [action.id]: null,
        },
        details: {
          ...state.details,
          [action.id]: action.result,
        },
      };
    case LOAD_DETAIL_FAIL:
      return {
        ...state,
        loadingDetails: {
          ...state.loadingDetails,
          [action.id]: false,
        },
        errorDetails: {
          ...state.errorDetails,
          [action.id]: action.error,
        },
        details: {
          ...state.details,
          [action.id]: null,
        },
      };
    case CREATE:
      return {
        ...state,
        creating: true,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        data: [].concat.call(action.result, state.data),
      };
    case CREATE_FAIL:
      return {
        ...state,
        creating: false,
      };
    default:
      return state;
  }
}

export function shouldLoad({ audits }: GlobalState) {
  return audits && !audits.data && !audits.loading;
}

export function shouldLoadDetail({ audits }: GlobalState, id: string) {
  return audits && audits.details && audits.loadingDetails && !audits.details[id] && !audits.loadingDetails[id];
}

export function shouldLoadLatest({ audits }: GlobalState) {
  return audits && !audits.latest && !audits.loadingLatest;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/audits'),
  };
}

export function loadDetail(id: string) {
  return {
    types: [LOAD_DETAIL, LOAD_DETAIL_SUCCESS, LOAD_DETAIL_FAIL],
    id,
    promise: (client: ApiClient) => client.get(`/audits/${id}`),
  };
}

export function loadLatest() {
  return {
    types: [LOAD_LATEST, LOAD_LATEST_SUCCESS, LOAD_LATEST_FAIL],
    promise: (client: ApiClient) => client.get('/audits/latest'),
  };
}

export function create(type?: 'online' | 'external') {
  const data = type && { type };
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    promise: (client: ApiClient) => client.post('/audits', { data }),
  };
}
