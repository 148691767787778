import React, { useEffect, useState } from 'react';
import pretty from 'pretty';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from 'styled-components';
import { spacing, fromTheme, themeColor, getFrom } from '@hyperfish/fishfood';
import { isArray } from 'util';

function parseJwt<T>(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload) as T;
}

const hasValue = (value: any) => {
  if (typeof value === 'string') {
    return value !== '';
  } else if (isArray(value)) {
    return value.length !== 0;
  } else if (typeof value === 'boolean') {
    return true;
  }

  return !!value;
};

const CodeWrapper = styled.div`
  border-radius: ${fromTheme('borderRadius')};
  margin: ${spacing(1)};
  border: 1px solid ${themeColor('lightGray')};
  font-size: 0.9rem;
`;

interface Settings {
  webPart: 'Profile' | 'Directory' | 'OrgChart';
  token: string;
  rootUser?: { value: string };
  config?: {};
}

interface Props {
  settings: Settings;
}

interface OmniToken {
  body: {
    orgId: string;
    env: string;
  };
}

const envMap = {
  'dev-local': 'dev-local',
  'dev-k8s': 'dev',
  prod: 'prod',
  'prod-au': 'prod-au',
  'prod-uk': 'prod-uk',
};

export const WebPartSnippet: React.FC<Props> = ({ settings }) => {
  const [codeString, setCodeString] = useState('');

  const getConfig = settings => {
    const _settings = { ...getFrom(settings)('config').defaultTo({}) };

    const rootUser = getFrom(_settings)('rootUser').value;

    console.log('rootUser', rootUser);
    if (rootUser && Array.isArray(rootUser)) {
      _settings.rootUser = rootUser[0];
    } else if (rootUser) {
      _settings.rootUser = rootUser;
    }

    const customLabels = getFrom(_settings)('customLabels').value;
    if (customLabels) {
      const directorySearch = getFrom(customLabels)('directorySearch').defaultTo([]);
      if (directorySearch.length > 0) {
        const _customLabels = { directorySearch: {} };
        directorySearch.forEach(element => {
          const language = getFrom(element)('language')('name').value;
          const resourceKey = getFrom(element)('label')('name').value;
          const resourceValue = getFrom(element)('value').value;

          _customLabels.directorySearch[language] = _customLabels.directorySearch[language] || {};
          _customLabels.directorySearch[language][resourceKey] = resourceValue;
        });

        _settings['customLabels'] = _customLabels;
      }
    }
    /* const customLabels = getFrom(_settings)('customLabels').value;
    if (customLabels) {
      const directorySearch = getFrom(customLabels)('directorySearch').defaultTo([]);
      if (directorySearch.length > 0) {
        const _customLabels = { directorySearch: {} };
        directorySearch.forEach(element => {
          const language = getFrom(element)('language')('name').value;
          directorySearch
            .filter(element => getFrom(element)('language')('name').value === language)
            .forEach(element => {
              const label = getFrom(element)('label')('name').value;
              const value = getFrom(element)('value').value;
              _customLabels.directorySearch[language] = {
                label,
                value,
              };
            });
        });

        _settings['customLabels'] = _customLabels;
      }
    } */

    delete _settings.directorySearch;

    return _settings;
  };

  const generateCodeString = () => {
    const token = settings.token && parseJwt<OmniToken>(settings.token).body;
    const config = getConfig(settings);

    return `
      <div id="${settings.webPart}"></div>
      <script>
        (function() {
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.onload = function() {
            Hyperfish${settings.webPart}(
              document.getElementById('${settings.webPart}'),
              {
                auth: 'TokenExchange',
                token: '${settings.token}',
                properties: {
                  orgId: '${token && token.orgId}',
                  isOmni: true,
                  ${config['rootUser'] ? `rootUser: { id: '${config['rootUser'].value}'},` : ''}
                  ${Object.keys(config)
                    .filter(x => hasValue(config[x]))
                    .filter(x => x !== 'rootUser')
                    .map(x => `${x}: ${JSON.stringify(config[x])}`)}
                },
              }
            );
          };
          document.head.appendChild(script);
          script.src =
            'https://hyperfish.azureedge.net/webpart/snippets/v2/${settings.webPart.toLowerCase()}.js';
        })();
      </script>
    `;
  };

  useEffect(() => {
    setCodeString(generateCodeString());
  }, [settings]);

  return (
    <CodeWrapper>
      <SyntaxHighlighter language="javascript" style={docco}>
        {pretty(codeString.replace(/\r?\n|\r/g, ''))}
      </SyntaxHighlighter>
    </CodeWrapper>
  );
};
