import React from 'react';

interface Props extends React.HTMLProps<HTMLElement> {
  name: string;
}

export const Icon = ({ name, ...rest }: Props) => (
  <i
    {...rest}
    dangerouslySetInnerHTML={{
      __html: require(`!svg-inline-loader?removeSVGTagAttrs=true!../assets/icons/${name}.svg`),
    }}
  />
);
