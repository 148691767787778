import { PropertyNotes } from '@hyperfish/antrea-api-contracts';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import StyleUtil from '../../../../utils/StyleUtil';
import classes from './styles.module.scss';

interface Props {
  stat: PropertyNotes;
}

export const HealthGauge: React.StatelessComponent<Props> = props => {
  if (!props.stat) {
    return null;
  }

  const data = [
    {
      name: 'Passed',
      value: props.stat.passed,
    },
    {
      name: 'Failed',
      value: props.stat.failed,
    },
    {
      name: 'Missing',
      value: props.stat.missing,
    },
  ];
  // other red? #e35c5d
  const { green } = StyleUtil.colors;
  const colors = [green, '#e35c5d', '#eaeaea'];

  return (
    <div className={classes.container}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            dataKey="value"
            data={data}
            startAngle={180 + 25}
            endAngle={0 - 25}
            innerRadius="80%"
            outerRadius="100%"
            animationDuration={400}
            animationBegin={0}
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={colors[index]} stroke="none" />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
