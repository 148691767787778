export const icons = [
  'alarm',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'anchor',
  'archive',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-thin-down',
  'arrow-thin-left',
  'arrow-thin-right',
  'arrow-thin-up',
  'arrow-up',
  'article',
  'asterisk',
  'backspace',
  'basket',
  'basketball',
  'battery-empty',
  'battery-full',
  'battery-low',
  'battery-medium',
  'bell',
  'blog',
  'bluetooth',
  'bold',
  'bookmark',
  'bookmarks',
  'box',
  'briefcase',
  'brightness-low',
  'brightness-max',
  'brightness-medium',
  'broadcast',
  'browser-upload',
  'browser',
  'brush',
  'calendar',
  'camcorder',
  'camera',
  'card',
  'caret-down',
  'caret-up',
  'cart',
  'checkbox',
  'checklist',
  'checkmark',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clipboard',
  'clock',
  'clockwise',
  'cloud-download',
  'cloud-upload',
  'cloud',
  'code',
  'collections',
  'contract-2',
  'contract',
  'conversation',
  'copy',
  'crop',
  'cross',
  'crosshair',
  'cutlery',
  'device-desktop',
  'device-mobile',
  'device-tablet',
  'direction',
  'disc',
  'document-delete',
  'document-edit',
  'document-new',
  'document-remove',
  'document',
  'dot',
  'dots-2',
  'dots-3',
  'download',
  'duplicate',
  'enter',
  'exit',
  'expand-2',
  'expand',
  'experiment',
  'export',
  'feed',
  'flag',
  'flashlight',
  'folder-open',
  'folder',
  'forward',
  'gaming',
  'gear',
  'graduation',
  'graph-bar',
  'graph-line',
  'graph-pie',
  'headset',
  'heart',
  'help',
  'home',
  'hourglass',
  'inbox',
  'information',
  'italic',
  'jewel',
  'lifting',
  'lightbulb',
  'link-broken',
  'link',
  'list',
  'loading',
  'location',
  'lock-open',
  'lock',
  'mail',
  'map',
  'media-loop',
  'media-next',
  'media-pause',
  'media-play',
  'media-previous',
  'media-record',
  'media-shuffle',
  'media-stop',
  'medical',
  'menu',
  'message',
  'meter',
  'microphone',
  'minus',
  'monitor',
  'move',
  'music',
  'network-1',
  'network-2',
  'network-3',
  'network-4',
  'network-5',
  'pamphlet',
  'paperclip',
  'pencil',
  'phone',
  'photo-group',
  'photo',
  'pill',
  'pin',
  'plus',
  'power',
  'preview',
  'print',
  'pulse',
  'question',
  'reply-all',
  'reply',
  'return',
  'retweet',
  'rocket',
  'scale',
  'search',
  'shopping-bag',
  'skip',
  'stack',
  'star',
  'stopwatch',
  'store',
  'suitcase',
  'swap',
  'tag-delete',
  'tag',
  'tags',
  'thumbs-down',
  'thumbs-up',
  'ticket',
  'time-reverse',
  'to-do',
  'toggles',
  'trash',
  'trophy',
  'upload',
  'user-group',
  'user-id',
  'user',
  'vibrate',
  'view-apps',
  'view-list-large',
  'view-list',
  'view-thumb',
  'volume-full',
  'volume-low',
  'volume-medium',
  'volume-off',
  'wallet',
  'warning',
  'web',
  'weight',
  'wifi',
  'wrong',
  'zoom-in',
  'zoom-out',
];
