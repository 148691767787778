import Radium from 'radium';
import React from 'react';

import { Notification as INotification } from '../models/client';
import { dismissNotification } from '../redux/modules/notifications';
import StyleUtil from '../utils/StyleUtil';
import { Notification } from './Notification';

interface Props {
  notifications: INotification[];
  dismiss: typeof dismissNotification;
}

const S = StyleUtil({
  container: {
    position: 'fixed',
    right: 20,
    top: 18 - 5, // padding - first notification margin
    zIndex: 900,
  },
});

@Radium
export class NotificationContainer extends React.Component<Props, {}> {
  private notificationTimeouts = {};

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.notifications) {
      return;
    }

    for (const notification of nextProps.notifications) {
      if (!notification.duration || notification.duration === 0) {
        continue;
      }
      if (this.notificationTimeouts[notification.id]) {
        continue;
      }
      this.notificationTimeouts[notification.id] = setTimeout(() => {
        this.handleDismiss(notification);
      }, notification.duration * 1000);
    }
  }

  handleDismiss = (notification: INotification) => {
    if (this.notificationTimeouts[notification.id]) {
      clearTimeout(this.notificationTimeouts[notification.id]);
    }

    this.props.dismiss(notification);
  };

  getHandleDismiss(notification: INotification) {
    return () => this.handleDismiss(notification);
  }

  render() {
    const { notifications } = this.props;

    return (
      <aside style={S.container}>
        {!!notifications &&
          notifications.map(n => <Notification key={n.id} dismiss={this.getHandleDismiss(n)} notification={n} />)}
      </aside>
    );
  }
}
