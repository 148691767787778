export const LOAD = 'antrea/permissions/LOAD';
export const LOAD_SUCCESS = 'antrea/permissions/LOAD_SUCCESS';
export const LOAD_FAIL = 'antrea/permissions/LOAD_FAIL';

export const EDIT = 'antrea/permissions/EDIT';

export const SAVE = 'antrea/permissions/SAVE';
export const SAVE_SUCCESS = 'antrea/permissions/SAVE_SUCCESS';
export const SAVE_FAIL = 'antrea/permissions/SAVE_FAIL';

export const CANCEL = 'antrea/permissions/CANCEL';
