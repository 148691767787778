import color from 'color';

import colors from '../colors';
import typerFunction from '../typerFunction';

export const tables = typerFunction({
  header: {
    marginBottom: 15,
    minHeight: 30,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexFlow: 'row-reverse',
  },
  header_noFlex: {
    marginBottom: 15,
    minHeight: 30,
  },
  footer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexFlow: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  footer_noFlex: {
    marginTop: 15,
  },
  headerAction: {
    color: colors.blue,
    cursor: 'pointer',
    fontSize: 18,
    lineHeight: 1,
    marginLeft: 10,
  },
  headerAction_disabled: { textDecoration: 'none' },
  headerActionText: {
    marginLeft: 5,
    textDecoration: 'underline',
    fontSize: 14,
  },
  headerActionText_disabled: {
    color: colors.gray,
    cursor: 'not-allowed',
    textDecoration: 'none',
  },

  table: {
    backgroundColor: '#fff',
    fontSize: 13,
    // tableLayout: 'fixed',
    width: '100%',
  },
  table_bordered: {
    border: `1px solid ${colors.lightGray}`,
  },
  tr: {
    ':hover': {
      backgroundColor: color(colors.lightBlue)
        .alpha(0.3)
        .string(),
    },
  },
  tr_alt: {
    backgroundColor: color(colors.lightBlue)
      .alpha(0.15)
      .string(),
  },
  tr_disabled: {
    color: colors.gray,
  },
  tr_clickable: {
    cursor: 'pointer',
  },
  tr_invalid: {
    backgroundColor: 'rgba(208, 1, 27, .1)',
  },
  tr_group: {
    backgroundColor: colors.lightGray,
    ':hover': {
      backgroundColor: colors.lightGray,
    },
  },
  th: {
    backgroundColor: 'white',
    borderRight: `1px solid ${colors.otherLightGray}`,
    fontWeight: 700,
    lineHeight: 1.23,
    padding: '9px 10px',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
  th_grey: {
    backgroundColor: colors.lightGray,
    borderRight: 'none',
  },
  th_sortable: {
    cursor: 'pointer',
  },
  thText_sortable: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: 20,
  },
  thSortIcon: {
    // display: 'inline-block',
    color: colors.gray,
    fontSize: 9,
    marginLeft: 10,
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  thSortIcon_active: { color: colors.blue },
  td: {
    borderRight: `1px solid ${colors.otherLightGray}`,
    lineHeight: 1.3,
    padding: '9px 10px',
    verticalAlign: 'middle',
  },
  td_center: {
    textAlign: 'center',
  },
  td_nowrap: {
    whiteSpace: 'nowrap',
  },
  td_last: {
    borderRight: 'none',
  },
  td_actions: {
    borderRight: 'none',
    textAlign: 'center',
    width: 102,
  },
  actions_container: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  td_msg: {
    // backgroundColor: color(colors.lightBlue).alpha(.15),
    borderRight: 'none',
    borderTop: `1px solid ${colors.otherLightGray}`,
    textAlign: 'center',
  },
  td_groupFirst: {
    // backgroundColor: '#fff',
  },
  td_check: {
    borderRight: `1px solid ${colors.otherLightGray}`,
    lineHeight: '14px',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: 10 + 14 + 10 + 1,
  },
  td_checkmark: {
    color: colors.green,
    fontSize: 14,
    textAlign: 'center',
  },
  td_chevron: {
    color: colors.blue,
    cursor: 'pointer',
    lineHeight: 1,
    textAlign: 'center',
    width: 64,
    fontSize: 18,
    padding: 0,
  },
  td_detail: {
    borderBottom: '1px solid #bababa',
    borderTop: '1px solid #bababa',
    padding: '20px 82px',
  },

  action_edit: {
    cursor: 'pointer',
    color: colors.blue,
    ':hover': { opacity: 0.7 },
  },
  action_delete: {
    cursor: 'pointer',
    color: colors.red,
    ':hover': { opacity: 0.7 },
  },
  action_disabled: {
    cursor: 'not-allowed',
    color: colors.gray,
    ':hover': { opacity: 1 },
  },
});
