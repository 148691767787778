import { Button, Card } from '@hyperfish/fishfood';
import React from 'react';
import { connect } from 'react-redux';

import classes from './styles.module.scss';

const connector = connect(
  state => ({
    currentOrg: state.orgs.current,
    user: state.self.user,
  }),
  {
    /* No Actions */
  },
);

@connector
export default class Help extends React.Component<typeof connector['props'], {}> {
  render() {
    return (
      <div>
        <Card>
          <div className={classes.container}>
            <h2>Need Help?</h2>
            <p>
              Check out the LiveTiles Directory Help Center to browse the knowledge base or submit a request for
              assistance.
            </p>
            <Button
              size="large"
              variant="solid"
              color="primary"
              onClick={() =>
                window.open('https://support.livetilesglobal.com/hc/en-us/categories/900000231546-Directory')
              }
            >
              OPEN HELP CENTER
            </Button>
          </div>
        </Card>
        <Card>
          <div className={classes.container}>
            <h2>Need a Refresher?</h2>
            <p>Brush up on the features of LiveTiles Directory by taking a quick tour around the app.</p>
            <Button
              size="large"
              variant="solid"
              color="primary"
              onClick={() => (location.href = '/home?appcue=-KtwZagMQb8bwlZxqMw5')}
            >
              START TOUR
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}
