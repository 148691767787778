import React from 'react';
import cx from 'classnames';
import classes from '../styles.module.scss';
import { ColorPicker, getFrom, hexToRgb, rgbToHex } from '@hyperfish/fishfood';

import { SettingCard } from '../../../../components/';
import {
  Button,
  ButtonContainer,
  FiCheck,
  FiEdit2,
  FiPlusCircle,
  FiTrash,
  FiX,
  IconButton,
  Modal,
  TextField,
  Toggle,
} from '@hyperfish/fishfood';
import StyleUtil from '../../../../utils/StyleUtil';
import { RelationshipTypeResponse } from '@hyperfish/antrea-api-contracts/src/relationship';
import { Props } from '../index';
import styled from 'styled-components';

const styles = StyleUtil({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '7px',
    alignItems: 'center',
  },
  formLabel: {
    flexBasis: '200px',
  },
  formInput: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  formFilterSelect: {
    paddingBottom: 25,
  },
  profilePicture: {
    marginBottom: 5,
  },
  spacer: {
    width: '10px',
  },
});

const ColorPreview = styled.span<{ color: string }>`
  border: 1px solid rgb(218, 218, 218);
  border-radius: 3px;
  width: 25px;
  margin-right: 5px;
  height: 25px;
  background-color: ${p => p.color};
`;

interface State {
  relationshipTypeToDelete?: {
    id: string;
    name: string;
  };
  relationshipType?: RelationshipTypeResponse;
  originalRelationshipType?: RelationshipTypeResponse;
}

export default class RelationshipType extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { loadRelationshipTypes, reloadRelationshipTypes } = this.props;
    if (reloadRelationshipTypes) {
      loadRelationshipTypes();
    }
  }

  generateRandomColor = () => {
    return {
      r: Math.floor(Math.random() * 256).toString(),
      g: Math.floor(Math.random() * 256).toString(),
      b: Math.floor(Math.random() * 256).toString(),
    };
  };

  render() {
    const { relationshipTypes } = this.props;

    return (
      <>
        <SettingCard
          title="Types"
          subTitle="These are the types used to create relationships from. Types can be available to users or only for admins."
        >
          <>
            <div>
              <div>
                <ButtonContainer>
                  <Button
                    icon={<FiPlusCircle />}
                    color="accent"
                    variant="link"
                    onClick={() => {
                      let intialColor = this.generateRandomColor();
                      this.setState({
                        relationshipType: {
                          name: '',
                          isHierarchical: true,
                          isOpenToUsers: false,
                          isPublished: false,
                          metaData: { relationshipColor: intialColor },
                        } as RelationshipTypeResponse,
                        originalRelationshipType: {
                          name: '',
                          isHierarchical: true,
                          isOpenToUsers: false,
                          isPublished: false,
                          metaData: { relationshipColor: intialColor },
                        } as RelationshipTypeResponse,
                      });
                    }}
                  >
                    Add
                  </Button>
                </ButtonContainer>
              </div>

              <div className={classes.table}>
                <div className={classes.row}>
                  <div className={cx(classes.tableHeader, classes['name'])}>
                    <p>Name</p>
                  </div>
                  <div className={cx(classes.tableHeader, classes['description'])}>
                    <p>Description</p>
                  </div>
                  <div className={cx(classes.tableHeader, classes['isCheck'], classes.required)}>
                    <p>Configuration</p>
                  </div>
                  <div className={cx(classes.tableHeader, classes['isCenter'], classes.actions)}>
                    <p>Actions</p>
                  </div>
                </div>
                {(relationshipTypes || []).map((item, index) => (
                  <div
                    key={item.id}
                    className={cx(classes.row, {
                      [classes['isAlt']]: index % 2 === 0,
                    })}
                  >
                    <div className={cx(classes.tableCell, classes['name'])}>
                      <ColorPreview
                        color={rgbToHex({
                          red: parseInt(getFrom(item)('metaData')('relationshipColor')('r').defaultTo('0')),
                          green: parseInt(getFrom(item)('metaData')('relationshipColor')('g').defaultTo('0')),
                          blue: parseInt(getFrom(item)('metaData')('relationshipColor')('b').defaultTo('0')),
                        })}
                      />
                      <p className={cx(classes['shortParagraph'])}>{item.name}</p>
                    </div>
                    <div className={cx(classes.tableCell, classes['description'])}>
                      <p className={cx(classes['shortParagraph'])}>
                        {getFrom(item)('metaData')('relationshipDescription').defaultTo('')}
                      </p>
                    </div>
                    <div className={cx(classes.tableCell, classes['isCheck'], classes['isCenter'], classes.required)}>
                      <div style={StyleUtil.styles.tables.actions_container}>
                        <span title="Is it hierarchical?">
                          {item.isHierarchical ? <FiCheck /> : <FiX color="red" />}
                        </span>
                        <span title="Can users add relationships?">
                          {item.isOpenToUsers ? <FiCheck title="Users can add relationships" /> : <FiX color="red" />}
                        </span>
                        <span title="Is it published?">
                          {item.isPublished ? <FiCheck title="Is published" /> : <FiX color="red" />}
                        </span>
                      </div>
                    </div>
                    <div className={cx(classes.tableCell, classes['isCenter'], classes.actions)}>
                      <div style={StyleUtil.styles.tables.actions_container}>
                        <IconButton
                          css={null}
                          ariaLabel="Edit Attribute"
                          color="accent"
                          onClick={() =>
                            this.setState({
                              relationshipType: {
                                id: item.id,
                                name: item.name,
                                isHierarchical: item.isHierarchical,
                                isOpenToUsers: item.isOpenToUsers,
                                isPublished: item.isPublished,
                                metaData: item.metaData,
                              },
                              originalRelationshipType: {
                                id: item.id,
                                name: item.name,
                                isHierarchical: item.isHierarchical,
                                isOpenToUsers: item.isOpenToUsers,
                                isPublished: item.isPublished,
                                metaData: item.metaData,
                              } as RelationshipTypeResponse,
                            })
                          }
                          key={`${item.id}_edit`}
                          icon={<FiEdit2 />}
                          style={{ marginBottom: 0 }}
                        />
                        <IconButton
                          css={null}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              relationshipTypeToDelete: {
                                id: item.id,
                                name: item.name as string,
                              },
                            })
                          }
                          title={'Delete'}
                          ariaLabel="Delete Attribute"
                          icon={<FiTrash />}
                          color="error"
                          style={{ marginBottom: 0 }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        </SettingCard>
        {this.state.relationshipTypeToDelete && this.renderDeleteRelationshipTypeModal()}
        {this.state.relationshipType && this.renderAddOrEditRelationshipTypeModal(!this.state.relationshipType.id)}
      </>
    );
  }

  renderDeleteRelationshipTypeModal() {
    const { deleteRelationshipType } = this.props;

    return (
      <Modal onClose={() => this.setState({ relationshipType: null })}>
        <Modal.Header>Are you sure about this action?</Modal.Header>
        <div>
          <p>
            This will delete all relationships of type <strong>{this.state.relationshipTypeToDelete.name}</strong> and
            the type itself.
          </p>
        </div>
        <Modal.ButtonContainer>
          <Button
            size="small"
            onClick={() => {
              this.setState({ relationshipTypeToDelete: null });
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="solid"
            size="small"
            onClick={() => {
              deleteRelationshipType(this.state.relationshipTypeToDelete.id);
              this.setState({ relationshipTypeToDelete: null });
            }}
          >
            Delete
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }

  renderAddOrEditRelationshipTypeModal(isAdding: boolean) {
    const { relationshipTypes, addRelationshipType, editRelationshipType } = this.props;

    const isDirty = () => {
      return !(
        this.state.originalRelationshipType.name === this.state.relationshipType.name &&
        this.state.originalRelationshipType.isHierarchical === this.state.relationshipType.isHierarchical &&
        this.state.originalRelationshipType.isOpenToUsers === this.state.relationshipType.isOpenToUsers &&
        this.state.originalRelationshipType.isPublished === this.state.relationshipType.isPublished &&
        getFrom(this.state.originalRelationshipType)('metaData')('relationshipDescription').defaultTo('') ===
          getFrom(this.state.relationshipType)('metaData')('relationshipDescription').defaultTo('') &&
        getFrom(this.state.originalRelationshipType)('metaData')('relationshipColor')('r').defaultTo('') ===
          getFrom(this.state.relationshipType)('metaData')('relationshipColor')('r').defaultTo('') &&
        getFrom(this.state.originalRelationshipType)('metaData')('relationshipColor')('g').defaultTo('') ===
          getFrom(this.state.relationshipType)('metaData')('relationshipColor')('g').defaultTo('') &&
        getFrom(this.state.originalRelationshipType)('metaData')('relationshipColor')('b').defaultTo('') ===
          getFrom(this.state.relationshipType)('metaData')('relationshipColor')('b').defaultTo('')
      );
    };

    const validate = () => {
      return (
        this.state.relationshipType.name.trim() &&
        !(relationshipTypes || []).some(
          r =>
            r.name.trim().toLocaleLowerCase() === this.state.relationshipType.name.trim().toLocaleLowerCase() &&
            r.id != this.state.relationshipType.id,
        ) &&
        getFrom(this.state.relationshipType)('metaData')('relationshipDescription')
          .defaultTo('')
          .trim()
      );
    };

    let title = 'Adding relationship type';
    if (!isAdding) {
      title = 'Editing relationship type';
    }

    return (
      <Modal onClose={() => this.setState({ relationshipType: null })}>
        <Modal.Header>{title}</Modal.Header>
        <div style={styles.form}>
          <div style={styles.formRow}>
            <div style={styles.formInput}>
              <TextField
                autoFocus={true}
                type={'text'}
                placeholder={'Name'}
                value={this.state.relationshipType.name}
                onChange={e => {
                  this.setState({
                    relationshipType: {
                      ...this.state.relationshipType,
                      name: e.currentTarget.value,
                    },
                  });
                }}
              />
            </div>
            <div style={styles.spacer}></div>
            <div style={styles.formInput}>
              <TextField
                autoFocus={false}
                type={'text'}
                placeholder={'eg. "reports to".'}
                value={getFrom(this.state.relationshipType)('metaData')('relationshipDescription').defaultTo('')}
                onChange={e => {
                  this.setState({
                    relationshipType: {
                      ...this.state.relationshipType,
                      metaData: {
                        ...this.state.relationshipType.metaData,
                        relationshipDescription: e.currentTarget.value,
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
          <div style={styles.formRow}></div>
          {/* TODO: Uncomment it when the non hierarchical chart feature goes live
            <div style={styles.formRow}>
            <label style={styles.formLabel} title="Determines if this type will generate a hierachical chart">
              Is hierarchical
            </label>
            <div style={styles.formInput}>
              <Toggle
                title="Determines if this type will generate a hierachical chart"
                checked={this.state.relationshipType.isHierarchical}
                onChange={e => {
                  this.setState({
                    relationshipType: {
                      ...this.state.relationshipType,
                      isHierarchical: e.currentTarget.checked,
                    },
                  });
                }}
              />
            </div>
          </div> */}
          {/* TODO: Uncomment it when the non hierarchical chart feature goes live
            <div style={styles.formRow}>
            <label
              style={styles.formLabel}
              title="Determines if users are able to create relationships based on this type"
            >
              {' '}
              Users can add relationships
            </label>
            <div style={styles.formInput}>
              <Toggle
                title="Determines if users are able to create relationships based on this type"
                checked={this.state.relationshipType.isOpenToUsers}
                onChange={e => {
                  this.setState({
                    relationshipType: {
                      ...this.state.relationshipType,
                      isOpenToUsers: e.currentTarget.checked,
                    },
                  });
                }}
              />
            </div>
          </div> */}
          <div style={styles.formRow}>
            <label style={styles.formLabel} title="Determines if this relationship type is available to users">
              {' '}
              Is published
            </label>
            <div style={styles.formInput}>
              <Toggle
                title="Determines if this relationship type is available to users"
                checked={this.state.relationshipType.isPublished}
                onChange={e => {
                  this.setState({
                    relationshipType: {
                      ...this.state.relationshipType,
                      isPublished: e.currentTarget.checked,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div style={styles.formRow}>
            <div style={styles.formLabel}>
              <label title="Links and Cards color">Links and Cards color</label>
            </div>
            <div style={styles.formInput}>
              <ColorPicker
                readOnly={false}
                value={rgbToHex({
                  red: parseInt(
                    getFrom(this.state.relationshipType)('metaData')('relationshipColor')('r').defaultTo('0'),
                  ),
                  green: parseInt(
                    getFrom(this.state.relationshipType)('metaData')('relationshipColor')('g').defaultTo('0'),
                  ),
                  blue: parseInt(
                    getFrom(this.state.relationshipType)('metaData')('relationshipColor')('b').defaultTo('0'),
                  ),
                })}
                onChange={e => {
                  let color = hexToRgb(e.target.value);
                  this.setState({
                    relationshipType: {
                      ...this.state.relationshipType,
                      metaData: {
                        ...this.state.relationshipType.metaData,
                        relationshipColor: {
                          r: color.red.toString(),
                          g: color.green.toString(),
                          b: color.blue.toString(),
                        },
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <Modal.ButtonContainer>
          <Button
            size="small"
            onClick={() => {
              this.setState({ relationshipType: null });
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="solid"
            size="small"
            disabled={!isDirty() || !validate()}
            onClick={() => {
              if (isAdding) {
                addRelationshipType(this.state.relationshipType);
              } else {
                editRelationshipType(this.state.relationshipType.id, this.state.relationshipType);
              }
              this.setState({ relationshipType: null });
            }}
          >
            Save
          </Button>
        </Modal.ButtonContainer>
      </Modal>
    );
  }
}
