// import Color from 'color';

import colors from '../colors';
import typerFunction from '../typerFunction';

export const forms = typerFunction({
  validationText: {
    color: colors.red,
    display: 'block',
    fontSize: 12,
    fontStyle: 'italic',
    lineHeight: 1,
    paddingTop: 5,
  },
});
