import getFrom from '@hyperfish/fishfood/lib/utils/GetUtil';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import {
  DataTable,
  HyperFieldFactory as hFields,
  HyperForm,
  Icon,
  PermissionsTable,
  SettingCard,
  Slider,
} from '../../../components';
import { PremiumLock } from '../../../components/PremiumLock';
import { ModalKeys } from '../../../components/PremiumModal';
import { SettingsProps } from '../../../layouts/SettingsLayout';
import { OrgSettingsKeys as keys } from '../../../models/api';
import { clear as clearUsers, load as loadUsers } from '../../../redux/modules/externalUsers';
import { loadConnections } from '../../../redux/modules/orgs';
import AtLeastVersion from '../../../utils/VersionUtil';
import StyleUtil from '../../../utils/StyleUtil';
import { Button, ButtonContainer } from '@hyperfish/fishfood/lib/components/Button';
import { FiMinus, FiPlus } from '@hyperfish/fishfood/lib/components/Icon';
import { LoadingSplash } from '@hyperfish/fishfood/lib/components/LoadingSplash';
import { Toggle } from '@hyperfish/fishfood/lib/components/Toggle';
import SchemaUtil from '@hyperfish/fishfood/lib/utils/SchemaUtil';
import { applyDSTforSchedules, applyDSTforSchedulesReverse } from '../../../utils/momentUtil';
import { Card } from '@hyperfish/fishfood';

const ApprovalTable = DataTable.of({
  title: { label: 'Display Name', sortable: true },
  property: { label: 'Field', sortable: true },
  autoApprove: { label: 'Auto Approve', center: true, type: DataTable.types.custom, width: 110 },
});

const connector = connect(
  state => ({
    connections: state.orgs.connections,
    connectionsLoading: state.orgs.loadingConnections,
    users: state.externalUsers.data,
    usersById: state.externalUsers.dataById,
    usersLoading: state.externalUsers.loading,
  }),
  { loadUsers, clearUsers, loadConnections },
);

type Props = typeof connector['props'];

@connector
export default class Approval extends React.Component<Props & SettingsProps, {}> {
  constructor(props: Props & SettingsProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { loadScopedSettings, connections, connectionsLoading, loadConnections } = this.props;

    if (!connections && !connectionsLoading) {
      loadConnections();
    }

    loadScopedSettings([
      keys.global.readOnlyMode,
      keys.broadcast.approval.whenToNotifyApprovers,
      keys.broadcast.approval.dailyTimeToNotify,
      keys.attributes.approval.manualApprovalWildcard,
      keys.inheritance.autoApprove,
      keys.inheritance.approverPermissions,
    ]);

    // this.props.router.setRouteLeaveHook(this.props.route, () => {
    //   if (this.props.settingsDirty || this.props.permissionsByAudienceDirtyArray) {
    //     return 'You have unsaved changes. Are you sure you want to leave?';
    //   }
    // });
  }

  componentWillUnmount() {
    this.props.handleCancel();
  }

  handleApprovalOverrideChange = e => {
    const { update, currentAudienceId, cleanSettings, currentOrg } = this.props;
    const inherit = !e.currentTarget.checked;
    if (!inherit) {
      // Turning inheritance off (override on). Need to copy over master data.
      const settingKeys = Object.keys(cleanSettings[currentOrg.id]);
      const approvalRegex = new RegExp(`^${keys.attributes.approval.manualApprovalWildcard.replace('*', '.*')}$`);
      for (const key of settingKeys) {
        if (!approvalRegex.test(key)) {
          continue;
        }
        if (cleanSettings[currentOrg.id][key] !== cleanSettings[currentAudienceId][key]) {
          update(currentAudienceId, key as any, cleanSettings[currentOrg.id][key]);
        }
      }
    }

    update(currentAudienceId, keys.inheritance.autoApprove, inherit);
  };

  render() {
    const {
      currentAudienceId,
      fieldSchema,
      fieldSchemaLoading,
      isMaster,
      permissionsByAudienceLoading,
      settings,
      settingsLoading,
      update,
    } = this.props;

    if (
      !settings ||
      settingsLoading ||
      !fieldSchema ||
      fieldSchemaLoading ||
      permissionsByAudienceLoading[currentAudienceId]
    ) {
      return <LoadingSplash />;
    }

    if (settings.read_only_mode) {
      return (
        <Card>
          <h2>I&apos;m sorry, I&apos;m afraid I can&apos;t do that.</h2>
          <p>Your organization is currently in read-only mode.</p>
          <p>
            While in read-only mode, LiveTiles Directory does not make any updates to your directory, so change approval
            functionality is disabled.
          </p>
        </Card>
      );
    }

    return (
      <div className="view-settings-approval">
        <SettingCard
          title="Auto Approval"
          subTitle="Designate some fields for auto approval."
          override={isMaster ? null : !settings.inheritance_autoApprove}
          onChangeOverride={this.handleApprovalOverrideChange}
          // noPadding={true}
        >
          {this.renderAutoApprovalTable()}
        </SettingCard>
        {isMaster && (
          <SettingCard
            title="Timing"
            subTitle="Determine when you would like approvers to receive notifications of pending approvals."
          >
            <Slider
              value={settings.broadcast_approval_whenToNotifyApprovers}
              onChange={val => update(currentAudienceId, keys.broadcast.approval.whenToNotifyApprovers, val)}
              options={[
                { value: 'never', label: 'Never' },
                { value: 'daily', label: 'Daily' },
                { value: 'immediately', label: 'Immediately' },
              ]}
            />
            {settings.broadcast_approval_whenToNotifyApprovers === 'daily' && (
              <HyperForm
                fields={[
                  hFields.time({
                    label: 'Daily At',
                    value: settings.broadcast_approval_dailyTimeToNotify
                      ? applyDSTforSchedules(moment(settings.broadcast_approval_dailyTimeToNotify, moment.ISO_8601))
                      : null,
                    onChange: value => {
                      if (!moment.isMoment(value)) {
                        return;
                      }
                      update(
                        currentAudienceId,
                        keys.broadcast.approval.dailyTimeToNotify,
                        applyDSTforSchedulesReverse(value).toISOString(),
                      );
                    },
                  }),
                ]}
              />
            )}
          </SettingCard>
        )}
        {this.renderApproversCard()}
      </div>
    );
  }

  renderApproversCard() {
    const {
      connections,
      currentAudienceId,
      currentOrg,
      editPermissions,
      fieldSchema,
      isMaster,
      isFree,
      permissionsByAudienceDirty,
      permissionsByAudience,
      permissionsByAudienceError,
      permissionsByAudienceLoading,
      loadUsers,
      clearUsers,
      users,
      usersById,
      usersLoading,
    } = this.props;

    const permissions = permissionsByAudienceDirty[currentAudienceId] || permissionsByAudience[currentAudienceId];
    const error = permissionsByAudienceError[currentAudienceId];

    let content;
    if (!!error || !permissions) {
      content = (
        <div>
          <p>We were unable to load approvers at this time. Please try again later.</p>
        </div>
      );
    } else {
      content = (
        <PermissionsTable
          fieldSchema={fieldSchema}
          audienceId={currentAudienceId}
          orgId={currentOrg && currentOrg.id}
          onEdit={editPermissions}
          loading={permissionsByAudienceLoading[currentAudienceId]}
          audiencePayload={
            isMaster || !permissions.orgSettings.inheritance_approverPermissions
              ? permissions
              : permissionsByAudience[currentOrg.id]
          }
          role="Approver"
          loadUsers={loadUsers}
          clearUsers={clearUsers}
          users={users}
          usersLoading={usersLoading}
          usersById={usersById}
          isFree={isFree}
          managerDisabled={
            !currentOrg ||
            !connections ||
            (currentOrg.type !== 'Online' &&
              connections.filter(c => AtLeastVersion('2.13.0.0', ((c as any).lastHeartbeat || {})['version']))
                .length === 0)
          }
        />
      );
    }

    return (
      <SettingCard
        title="Approvers"
        subTitle="Approvers receive notifications of pending approvals and approve changes."
        override={isMaster ? null : !getFrom(permissions)('orgSettings')('inheritance_approverPermissions').value}
        onChangeOverride={e => {
          const newVal = !e.currentTarget.checked;
          const newPayload: typeof permissions = JSON.parse(JSON.stringify(permissions));
          newPayload.orgSettings.inheritance_approverPermissions = newVal;
          editPermissions(newPayload);
        }}
      >
        {content}
      </SettingCard>
    );
  }

  renderAutoApprovalTable() {
    const {
      currentAudienceId,
      currentOrg,
      disconnected,
      fieldSchema,
      isFree,
      relatedEntities,
      settings,
      showPremiumModal,
      update,
    } = this.props;

    let fields = [];
    if (
      relatedEntities &&
      relatedEntities.audienceSpecific[currentAudienceId] &&
      relatedEntities.audienceSpecific[currentAudienceId].fields
    ) {
      for (const id in relatedEntities.audienceSpecific[currentAudienceId].fields) {
        if (relatedEntities.audienceSpecific[currentAudienceId].fields.hasOwnProperty(id)) {
          const fieldSchemaField = fieldSchema.fields[id];
          const fieldConfig = SchemaUtil.GetConfigFromField(fieldSchemaField, currentAudienceId, currentOrg.id);
          const isInternal = getFrom(fieldSchemaField)('internal').value || false;
          const isHidden = getFrom(fieldConfig)('ui')('hidden').value || false;
          if (isInternal || isHidden) {
            continue;
          }
          const masterField = relatedEntities.audienceSpecific[currentOrg.id].fields[id];
          const field = relatedEntities.audienceSpecific[currentAudienceId].fields[id];
          const settingKey = keys.attributes.approval.manualApprovalWildcard.replace('*', id);
          fields.push({
            id: field.property,
            property: field.property,
            title: field.title || masterField.title,
            settingKey,
            autoApprove: (
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <Toggle
                  checked={!settings[settingKey]}
                  onChange={e => {
                    update(currentAudienceId, settingKey as any, !e.currentTarget.checked);
                  }}
                  {...(!isFree || !disconnected
                    ? {}
                    : {
                        disabled: true,
                      })}
                />
                {isFree && disconnected && (
                  <Icon
                    name="link-broken"
                    data-tip-left="LiveTiles Directory is not connected to your directory, and cannot write any collected changes. Connect your directory before turning on Auto Approve."
                    onClick={() => this.props.history.push('/settings/general')}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: 'white',
                      border: `1px solid ${StyleUtil.colors.lightGray}`,
                      borderRadius: '50%',
                      color: StyleUtil.colors.red,
                      height: 20,
                      padding: 4,
                      position: 'absolute',
                      right: -7,
                      top: -7,
                      width: 20,
                    }}
                  />
                )}
              </div>
            ),
          });
        }
      }
    }
    if (isFree) {
      fields = fields.filter(f => ['profilePicture', 'thumbnailphoto'].indexOf(f.property) !== -1);
    }

    return (
      <div>
        {(!isFree || !disconnected) && (
          <div style={StyleUtil.styles.tables.header}>
            <ButtonContainer>
              <Button
                size="small"
                icon={<FiMinus />}
                color="accent"
                variant="link"
                onClick={() => fields.forEach(({ settingKey }) => update(currentAudienceId, settingKey, true))}
              >
                Deactivate All
              </Button>
              <Button
                size="small"
                icon={<FiPlus />}
                color="accent"
                variant="link"
                onClick={() => fields.forEach(({ settingKey }) => update(currentAudienceId, settingKey, false))}
              >
                Activate All
              </Button>
            </ButtonContainer>
          </div>
        )}
        <ApprovalTable defaultSortBy={'title'} data={fields} />
        {isFree && (
          <div style={{ textAlign: 'center', marginTop: 20, padding: '0 100px' }}>
            <h2>
              <PremiumLock tooltipDir="left" onClick={() => showPremiumModal(ModalKeys.autoApproval)} />
              <span>Did you know?</span>
            </h2>
            <p>When you upgrade to premium, you can collect and auto approve any attribute in your directory.</p>
          </div>
        )}
      </div>
    );
  }
}
