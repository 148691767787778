import { DirectorySearchSortField } from '@hyperfish/antrea-api-contracts';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SchemaUtil from '@hyperfish/fishfood/lib/utils/SchemaUtil';
import { Button, ButtonGroup, IconButton } from '@hyperfish/fishfood/lib/components/Button';
import { FiMinusCircle, FiPlus } from '@hyperfish/fishfood/lib/components/Icon';
import { Modal, ModalProps } from '@hyperfish/fishfood/lib/components/Modal';
import { Select } from '@hyperfish/fishfood/lib/components/Select';
import { DefaultOptionType } from '@hyperfish/fishfood/lib/components/Select/shared';
import { AdditionalLabel, RemoveContainer, SortContainer, EmptyMessage } from './DirectoryFormModalComponents';

interface DirectoryFormSortModalProps {
  onApply: (newSort: DirectorySearchSortField) => void;
  onClose: ModalProps['onClose'];
  schemaUtil: SchemaUtil;
  initialSort?: DirectorySearchSortField;
}

export const DirectoryFormSortModal: FunctionComponent<DirectoryFormSortModalProps> = ({
  onApply,
  onClose,
  schemaUtil,
  initialSort,
}) => {
  const [sortOptions, setSortOptions] = useState<DefaultOptionType[]>([]);

  const [sortArray, setSort] = useState<{ prop: string; dir: DirectorySearchSortField['foo'] }[]>(null);
  const replaceSort = (index: number, item: typeof sortArray[0]) => {
    const newSort = [...sortArray];
    newSort[index] = item;
    setSort(newSort);
  };

  const { t } = useTranslation('directorySearch');

  useEffect(() => {
    const renderablePropertiesMap = schemaUtil.getRenderableFields().reduce((p, c) => {
      p[c.property] = true;
      return p;
    }, {});
    setSort(
      initialSort
        ? Object.keys(initialSort)
            .filter(prop => renderablePropertiesMap[prop])
            .map(prop => ({ prop, dir: initialSort[prop] }))
        : [
            !!renderablePropertiesMap['displayname'] && {
              prop: 'displayname',
              dir: 'asc' as 'asc',
            },
            !!renderablePropertiesMap['displayName'] && {
              prop: 'displayName',
              dir: 'asc' as 'asc',
            },
          ].filter(Boolean),
    );
  }, [initialSort, schemaUtil]);

  useEffect(() => {
    setSortOptions(
      schemaUtil
        .getRenderableFields()
        .filter(f => f.type === 'string')
        .map(f => ({ value: f.property, label: schemaUtil.getFieldTitle(f) || f.property }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  }, [schemaUtil]);

  if (!sortArray) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <Modal.Header>{t('sortBy')}:</Modal.Header>
      {sortArray && sortArray.length > 0 ? (
        sortArray.map(({ prop, dir }, i) => (
          <Fragment key={i}>
            {i !== 0 && <AdditionalLabel>{t('sortByThen')}:</AdditionalLabel>}
            <SortContainer>
              <Select
                required
                options={sortOptions}
                value={sortOptions.find(o => o.value === prop)}
                onChange={o => replaceSort(i, { prop: o ? o.value : null, dir })}
              />
              <ButtonGroup aria-label="Sort direction">
                <Button
                  aria-label="Ascending"
                  color="accent"
                  variant={dir === 'asc' ? 'solid' : 'outlined'}
                  onClick={() => replaceSort(i, { prop, dir: 'asc' })}
                >
                  {t('sortByDirectionAsc')}
                </Button>
                <Button
                  aria-label="Descending"
                  color="accent"
                  variant={dir === 'desc' ? 'solid' : 'outlined'}
                  onClick={() => replaceSort(i, { prop, dir: 'desc' })}
                >
                  {t('sortByDirectionDesc')}
                </Button>
              </ButtonGroup>

              <RemoveContainer>
                <IconButton
                  css={null}
                  ariaLabel="Remove"
                  color="error"
                  size="small"
                  icon={<FiMinusCircle />}
                  onClick={() => setSort([...sortArray.filter((x, index) => index !== i)])}
                />
              </RemoveContainer>
            </SortContainer>
          </Fragment>
        ))
      ) : (
        <EmptyMessage>{t('sortByEmpty')}</EmptyMessage>
      )}
      <Button
        variant="link"
        color="accent"
        icon={<FiPlus />}
        onClick={() => setSort([...sortArray, { prop: null, dir: 'asc' }])}
      >
        {t('sortByAdd')}
      </Button>
      <Modal.ButtonContainer>
        <Button onClick={onClose}>{t('cancel').toUpperCase()}</Button>
        <Button
          color="primary"
          variant="solid"
          disabled={!Array.isArray(sortArray) || sortArray.length === 0}
          onClick={() => {
            const renderablePropertiesMap = schemaUtil.getRenderableFields().reduce((p, c) => {
              p[c.property] = true;
              return p;
            }, {});
            const newSort: DirectorySearchSortField = sortArray
              .filter(({ prop }) => prop && renderablePropertiesMap[prop])
              .reduce((p, c) => {
                p[c.prop] = c.dir;
                return p;
              }, {});

            onApply(newSort);
          }}
        >
          {t('apply').toUpperCase()}
        </Button>
      </Modal.ButtonContainer>
    </Modal>
  );
};
