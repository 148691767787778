import React from 'react';

import classes from './styles.module.scss';

interface State {
  currentError: any;
  errorInfo: any;
}

export class FishFail extends React.Component<{}, State> {
  static getDerivedStateFromError(currentError) {
    return { currentError };
  }

  constructor(props) {
    super(props);
    this.state = { currentError: null, errorInfo: null };
  }

  componentDidCatch(currentError, errorInfo) {
    this.setState({
      currentError,
      errorInfo,
    });
  }

  render() {
    if (!!this.state.currentError) {
      return (
        <div className={classes.container}>
          <h1>LiveTiles Directory</h1>
          <br />
          <h2>There was an unexpected error...</h2>
          <br />
          <h2>We&apos;ve already let the engineers know.</h2>
          <br />
          <p>
            If you need further assistance, reach out to{' '}
            <a href="mailto:support@livetilesglobal.com">support@livetilesglobal.com</a>
          </p>
          {process.env.NODE_ENV !== 'production' && (
            <>
              <br />
              <br />
              <br />
              <h2>Technical Stuff:</h2>
              <p>{this.state.currentError && this.state.currentError.toString()}</p>
              <p>{this.state.errorInfo && this.state.errorInfo.componentStack}</p>
            </>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
