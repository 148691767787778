import { Button, Card, getFrom, LoadingSplash } from '@hyperfish/fishfood';
import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';

import {
  AZURE_APP_NAME,
  AZURE_NAME,
  clear as clearProviders,
  load as loadProviders,
} from '../../redux/modules/providers';
import { isAuthed } from '../../redux/modules/auth';
import classes from './styles.module.scss';
import { Redirect } from 'react-router-dom';

const connector = connect(
  state => ({
    error: state.providers.error,
    loading: state.providers.loading,
    providers: state.providers.data,
    isAuthed: isAuthed(state),
  }),
  {
    clearProviders,
    loadProviders,
  },
);

const errorCodes = {
  1: 'Failed to log in.',
  2: 'Your organization does not have a LiveTiles Directory account.',
  3: 'Link has expired.',
  4: 'Your trial has expired.',
  5: 'Your login has expired. Please log back in.',
  6: "You attempted to log in to the wrong region. We have redirected you to your organization's region. Please log in again.",
};

@connector
export default class Login extends React.Component<typeof connector['props'], {}> {
  UNSAFE_componentWillMount() {
    this.props.clearProviders();
  }

  componentDidMount() {
    const { loading, loadProviders, location } = this.props;
    const ret = getFrom(location.state)('ret')('pathname').value || parse(location.search).ret;
    if (!loading) {
      loadProviders(null, ret as string);
    }
  }

  render() {
    const { providers, error, loading, location, isAuthed } = this.props;
    const query = parse(location.search);

    if (isAuthed) {
      return <Redirect to="/loginsuccess" />;
    }

    let provider = null;
    if (!query || Object.keys(query).length === 0) {
      // choose the first Azure AD provider to login automatically
      provider = getFrom(providers)
        .defaultTo([])
        .find(p => p.name === AZURE_NAME);
    }

    if (getFrom(query)('admin').value) {
      // choose the first Azure AD app-only provider to login automatically
      provider = getFrom(providers)
        .defaultTo([])
        .find(p => p.name === AZURE_APP_NAME);
    }

    if (
      getFrom(query)('source').value ||
      getFrom(query)('error').value ||
      getFrom(query)('message').value ||
      getFrom(query)('ret').value
    ) {
      // make sure we do not accidentially redirect to provider.href again
      provider = null;
    }

    if (getFrom(provider)('href').value) {
      //redirect to providers login uri
      window.location.href = `${provider.href}`;
      return null;
    }

    return (
      <div className={`container ${classes.Login}`}>
        <Card className={classes.card}>
          <Card.Title>Log In</Card.Title>
          {query.error && <p style={{ color: 'red' }}>{errorCodes[query.error as string] || errorCodes[1]}</p>}
          {loading && <LoadingSplash />}
          {(error || (providers && providers.length === 0)) && (
            <span>
              Failed to load login providers.
              <br />
              Please try again later.
            </span>
          )}
          {providers && providers.length > 0 && (
            <ul>
              {providers
                .filter(p => p.name === AZURE_NAME)
                .map(provider => (
                  <li className={classes.li} key={provider.name}>
                    <Button
                      className={classes.button}
                      size="medium"
                      onClick={() => (window.location.href = `${provider.href}&prompt=login`)}
                      autoFocus
                    >
                      <img
                        className={classes.img}
                        src="https://hyperfish.blob.core.windows.net/files/office365.png"
                        alt={provider.name + ' Icon'}
                      />
                      <span className={classes.name}>Sign in with Office 365</span>
                    </Button>
                  </li>
                ))}
              {query.admin &&
                providers
                  .filter(p => p.name === AZURE_APP_NAME)
                  .map(provider => (
                    <li className={classes.li} key={provider.name}>
                      <Button
                        className={classes.button}
                        size="medium"
                        onClick={() => (window.location.href = `${provider.href}&prompt=login`)}
                      >
                        <img
                          className={classes.img}
                          src="https://hyperfish.blob.core.windows.net/files/office365.png"
                          alt={provider.name + ' Icon'}
                        />
                        <span className={classes.name}>Sign in with Office 365 (Admin)</span>
                      </Button>
                    </li>
                  ))}
            </ul>
          )}
        </Card>
      </div>
    );
  }
}
