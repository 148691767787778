import { ChangesState as State } from '../../models/client/';
import { ApiClient } from '@hyperfish/fishfood';

const LOAD = 'antrea/changes/LOAD';
const LOAD_SUCCESS = 'antrea/changes/LOAD_SUCCESS';
const LOAD_FAIL = 'antrea/changes/LOAD_FAIL';
const BULK_UPDATE = 'antrea/changes/BULK_UPDATE';
const BULK_UPDATE_SUCCESS = 'antrea/changes/BULK_UPDATE_SUCCESS';
const BULK_UPDATE_FAIL = 'antrea/changes/BULK_UPDATE_FAIL';
const APPLY_FILTER = 'antrea/changes/APPLY_FILTER';

const initialState: State = {
  changesById: {},
  loaded: false,
  saving: {},
} as State;

export default function reducer(state: State = initialState, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      const changesById = {};
      action.result.changes.forEach(change => (changesById[change.id] = change));
      return {
        ...state,
        loading: false,
        loaded: true,
        changes: action.result.changes.map(change => change.id),
        relatedEntities: action.result.relatedEntities,
        changesById,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        changes: null,
        changesById: null,
        error: action.error,
      };
    case BULK_UPDATE: {
      const newSaving = { ...state.saving };
      const newChanges = { ...state.changesById };
      action.changes.forEach(c => {
        newSaving[c.id] = true;
        newChanges[c.id].error = null;
      });
      return {
        ...state,
        saving: newSaving,
      };
    }
    case BULK_UPDATE_SUCCESS: {
      const newSaving = { ...state.saving };
      const newChanges = { ...state.changesById };
      action.changes.forEach(c => (newSaving[c.id] = false));
      action.result.changes.forEach(
        c =>
          (newChanges[c.id] = {
            ...state.changesById[c.id],
            ...c,
            status: c.status || state.changesById[c.id].status,
          }),
      );
      return {
        ...state,
        saving: newSaving,
        changesById: newChanges,
      };
    }
    case BULK_UPDATE_FAIL: {
      const newSaving = { ...state.saving };
      action.changes.forEach(c => (newSaving[c.id] = false));
      return {
        ...state,
        saving: newSaving,
      };
    }
    case APPLY_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    default:
      return state;
  }
}

export function load(params?: object) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) => client.get('/changes', { params }),
  };
}

export function bulkUpdate(changes: { id: string; status: string }[]) {
  return {
    types: [BULK_UPDATE, BULK_UPDATE_SUCCESS, BULK_UPDATE_FAIL],
    changes,
    promise: (client: ApiClient) =>
      client.patch('/changes', {
        data: { changes },
      }),
  };
}

export function setFilter(status?: string) {
  return {
    type: APPLY_FILTER,
    filter: status,
  };
}

export const CHANGE_STATUS = {
  APPROVED: 'approved',
  CANCELED: 'canceled',
  FAILED: 'failed',
  PENDING: 'pending',
  PROCESSING: 'processing',
  REJECTED: 'rejected',
};
