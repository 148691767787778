import { Modal } from '@hyperfish/fishfood';
import React from 'react';

import { Icon } from '../../components';
import StyleUtil from '../../utils/StyleUtil';
import classes from './styles.module.scss';

const ValidationSuccess: React.StatelessComponent = () => (
  <Modal onClose={() => (location.href = '/')}>
    <h1 className={classes.header}>Great Work!</h1>
    <span className={classes.bigIconContainer}>
      <Icon style={{ color: StyleUtil.colors.orange }} className={classes.bigIcon} name="thumbs-up" />
    </span>
    <h1 className={classes.header}>Thank you for confirming your profile!</h1>
    <p className={classes.copy}>You can close this window.</p>
  </Modal>
);

export default ValidationSuccess;
