import { ResponseOuApiAudience as Audience } from '@hyperfish/antrea-api-contracts/src/audience';
import React from 'react';

import { Props } from '../../';
import { SettingCard } from '../../../../../components/SettingCard';
import { HyperForm, HyperFieldFactory as hFields } from '../../../../../components/HyperForm';
import OUValidator from '../../../../../utils/OUValidationUtil';
import { DataTable } from '../../../../../components/DataTable';
import StyleUtil from '../../../../../utils/StyleUtil';
import { LoadingSplash, Modal } from '@hyperfish/fishfood';

const CollectionScopesTable = DataTable.of({
  id: { label: 'Name', sortable: true },
  reversedDistinguishedName: { hidden: true },
});

interface State {
  confirmCopyFrom?: Audience;
}

export default class CollectionGeneral extends React.Component<Props & { getValidator: () => OUValidator }, State> {
  get validator() {
    return this.props.getValidator();
  }

  private applyCloneSettings(collection: Audience, cloneFromId: string) {
    const {
      createCollection,
      editCollectionStart,
      editCollectionComplete,
      loadPermissions,
      removeCollection,
      currentAudienceId,
    } = this.props;
    const { id: oldId, ...newCollection } = collection;
    (removeCollection(oldId, false) as any).then(() => {
      (createCollection(newCollection, cloneFromId, false) as any).then(result => {
        // this.setState({dirtyCloneFrom: null});
        loadPermissions(currentAudienceId);
        this.setState({ confirmCopyFrom: null });
        editCollectionComplete();
        editCollectionStart(result.audience);
        this.props.history.push({ pathname: '/settings/general', search: `?audienceId=${result.audience.id}` });
      });
    });
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      allScopes,
      allScopesLoading,
      collections,
      currentOrg,
      dirtyCollection,
      editCollection,
      fieldSchema,
      fieldSchemaLoading,
      settingsLoading,
    } = this.props;

    if (!dirtyCollection || !!allScopesLoading) {
      return <LoadingSplash />;
    }

    const prop = dirtyCollection.type === 'OU' ? 'DNStrings' : 'values';

    const byAttribute =
      !!currentOrg &&
      !!currentOrg.settings[currentOrg.id].collections_attributes &&
      !!currentOrg.settings[currentOrg.id].collections_attributes.enabled &&
      currentOrg.settings[currentOrg.id].collections_attributes.fieldName;

    let byAttributeValues = [];
    if (!!byAttribute) {
      let attribute: typeof fieldSchema['fields']['foo'];
      for (const fieldId in fieldSchema.fields) {
        if (!fieldSchema.fields.hasOwnProperty(fieldId)) {
          continue;
        }
        const field = fieldSchema.fields[fieldId];
        if (field.property === byAttribute) {
          attribute = field;
          break;
        }
      }

      if (!!attribute && attribute.audienceConfigs[currentOrg.id].format) {
        const format = attribute.audienceConfigs[currentOrg.id].format;
        byAttributeValues = ((format.meta || {}).selectValue || {})['values'] || [];
      }
    }

    const organizationUnitsOptions =
      dirtyCollection.type === 'OU'
        ? allScopes
            .filter(({ distinguishedName }) => dirtyCollection.definition.DNStrings.indexOf(distinguishedName) === -1)
            .map(({ distinguishedName, name }) => {
              const disabled =
                (!!this.validator && !this.validator.isIncluded(distinguishedName)) ||
                collections.filter(c => {
                  return (
                    c.type === 'OU' && c.definition.DNStrings.filter(s => distinguishedName.indexOf(s) > -1).length > 0
                  );
                }).length > 0;

              return {
                value: distinguishedName,
                label: `${name} (${distinguishedName})`,
                disabled,
              };
            })
        : byAttributeValues
            .filter(v => dirtyCollection.definition.values.indexOf(v) === -1)
            .map(value => {
              const disabled =
                collections.filter(c => c.type === 'Attribute' && c.definition.values.indexOf(value) > -1).length > 0;

              return {
                value,
                label: value,
                disabled,
              };
            });

    return (
      <div className="view-settings-general">
        {this.state.confirmCopyFrom && this.renderCopySettingsModal()}
        <SettingCard
          title="Collection Details"
          subTitle="Update the name, description, and organizational units for the collection."
        >
          <HyperForm
            onInvalidChange={this.props.handleFormInvalidChange}
            onMount={this.props.handleFormMount}
            onUnmount={this.props.handleFormUnmount}
            fields={[
              hFields.text({
                label: 'Name',
                value: dirtyCollection.definition.name,
                onChange: e => editCollection('name', e.currentTarget.value),
                required: true,
                validationText:
                  collections
                    .filter(collection => collection.type !== 'Global' && collection.id !== dirtyCollection.id)
                    .map(c => c.definition['name'])
                    .indexOf(dirtyCollection.definition.name) > -1
                    ? 'This collection name is already taken'
                    : null,
              }),
              hFields.longText({
                label: 'Description',
                value: dirtyCollection.definition.description,
                onChange: e => editCollection('description', e.currentTarget.value),
              }),
              hFields.autocomplete({
                label: dirtyCollection.type === 'OU' ? 'Organization Units' : 'Attribute Values',
                name: 'organizationUnits',
                required: true,
                dropdownOptions: organizationUnitsOptions,
                // We are doing a cute hack where we tell the form we have a value, but set props.value to nothing
                // We can type it to any because it won't actually be used to render
                value: (dirtyCollection.type === 'OU'
                  ? dirtyCollection.definition.DNStrings
                  : dirtyCollection.definition.values) as any,
                hintText:
                  dirtyCollection.type === 'OU'
                    ? ''
                    : `If the user has the attribute "${byAttribute}" set to these values, add the user to this collection:`,
                props: {
                  value: [],
                  isLoading: settingsLoading || allScopesLoading || fieldSchemaLoading,
                  noOptionsMessage: () =>
                    !!byAttribute
                      ? fieldSchemaLoading
                        ? 'Loading attributes schema...'
                        : 'No values found in format configuration'
                      : settingsLoading || allScopesLoading
                      ? 'Loading organizational units...'
                      : 'No Organizational units found',
                },
                onChange: val =>
                  editCollection(prop, dirtyCollection.definition[prop].slice().concat([val.value as string])),
              }),
              hFields.custom({
                label: '',
                customInput: (
                  <CollectionScopesTable
                    defaultSortBy={dirtyCollection.type === 'OU' ? 'reversedDistinguishedName' : 'id'}
                    noDataMessage="No Organizational Units Selected"
                    onDelete={d => {
                      const index = dirtyCollection.definition[prop].indexOf(d.id);
                      const newValue = dirtyCollection.definition[prop].slice();
                      newValue.splice(index, 1);
                      editCollection(prop, newValue);
                    }}
                    getRowStyle={d => (this.validator.isIncluded(d.id) ? {} : StyleUtil.styles.tables.tr_invalid)}
                    data={dirtyCollection.definition[prop].map(name => ({
                      id: name,
                      reversedDistinguishedName:
                        dirtyCollection.type === 'OU'
                          ? name
                              .split(',')
                              .reverse()
                              .join(',')
                          : '',
                    }))}
                  />
                ),
              }),
            ]}
          />
        </SettingCard>
        {this.renderCopySettings()}
      </div>
    );
  }

  renderCopySettings() {
    const { collections, collectionsById, dirtyCollectionCloneFrom, editCollectionCloneFrom } = this.props;

    const srcSettingsOptions = collections
      .filter(collection => collection.type !== 'Global')
      .map(collection => ({
        label: collection.definition['name'] || '',
        value: collection.id,
      }))
      .sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 0,
      );

    return (
      <SettingCard title="Copy Settings" subTitle="Copy settings from another collection.">
        <HyperForm
          onInvalidChange={this.props.handleFormInvalidChange}
          onMount={this.props.handleFormMount}
          onUnmount={this.props.handleFormUnmount}
          onSubmit={e => {
            e.preventDefault();
            return this.setState({ confirmCopyFrom: collectionsById[dirtyCollectionCloneFrom] as Audience });
          }}
          disableSave={!dirtyCollectionCloneFrom}
          saveText="Copy Settings"
          fields={[
            hFields.dropdown({
              label: 'Copy settings from',
              placeholder: 'Select a collection',
              name: 'srcSettings',
              value: srcSettingsOptions.filter(s => s.value === dirtyCollectionCloneFrom),
              onChange: option => editCollectionCloneFrom(option ? (option.value as string) : null),
              dropdownOptions: srcSettingsOptions,
            }),
          ]}
        />
      </SettingCard>
    );
  }

  renderCopySettingsModal() {
    const { confirmCopyFrom: clone } = this.state;

    return (
      <Modal onClose={() => this.setState({ confirmCopyFrom: null })}>
        <Modal.Header>Are you sure you want to copy settings from {clone.definition.name}?</Modal.Header>
        <p>This will reset all previous settings applied to the current collection and cannot be undone.</p>
        <div>
          <HyperForm
            onSubmit={e => {
              e.preventDefault();
              return this.applyCloneSettings(
                this.props.collectionsById[this.props.currentAudienceId] as Audience,
                clone.id,
              );
            }}
            onCancel={() => this.setState({ confirmCopyFrom: null })}
            disableSave={false}
            fields={[]}
          />
        </div>
      </Modal>
    );
  }
}
