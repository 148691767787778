import { TemplateConfiguration } from '@hyperfish/antrea-api-contracts/src/broadcast';

import { GlobalState } from '../../../models/client/';
import { BroadcastAction } from './actions';
import {
  CLEAR_PREVIEW,
  LOAD,
  LOAD_FAIL,
  LOAD_PREVIEW,
  LOAD_PREVIEW_FAIL,
  LOAD_PREVIEW_SUCCESS,
  LOAD_SUCCESS,
  LOAD_TEMPLATE,
  LOAD_TEMPLATE_FAIL,
  LOAD_TEMPLATE_SUCCESS,
  PATCH_TEMPLATE,
  PATCH_TEMPLATE_FAIL,
  PATCH_TEMPLATE_SUCCESS,
} from './types';
import { ApiClient } from '@hyperfish/fishfood';

type State = GlobalState['broadcasts'];

// UTILITY FUNCTIONS
function deepSet(obj: object, { broadcastId, messageId }, value: any) {
  return {
    ...obj,
    [broadcastId]: {
      ...obj[broadcastId],
      [messageId]: value,
    },
  };
}

const initialState = {
  templateError: {},
  templateLoading: {},
  templates: {},
  templateSaving: {},
  templateSaveError: {},
} as State;

export default function reducer(state: State = initialState, action: BroadcastAction): State {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.broadcasts,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error,
      };
    case LOAD_TEMPLATE:
      return {
        ...state,
        templateError: deepSet(state.templateError, action, null),
        templateLoading: deepSet(state.templateLoading, action, true),
      };
    case LOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateLoading: deepSet(state.templateLoading, action, false),
        templates: deepSet(state.templates, action, action.result),
      };
    case LOAD_TEMPLATE_FAIL:
      return {
        ...state,
        templateError: deepSet(state.templateError, action, action.error),
        templateLoading: deepSet(state.templateLoading, action, false),
      };
    case PATCH_TEMPLATE:
      return {
        ...state,
        templateSaving: deepSet(state.templateSaving, action, true),
        templateSaveError: deepSet(state.templateSaveError, action, null),
      };
    case PATCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateSaving: deepSet(state.templateSaving, action, false),
        templates: deepSet(state.templates, action, action.result),
        data: action.result.relatedEntities.broadcasts || state.data,
      };
    case PATCH_TEMPLATE_FAIL:
      return {
        ...state,
        templateSaving: deepSet(state.templateSaving, action, false),
        templateSaveError: deepSet(state.templateSaveError, action, action.error),
      };
    case LOAD_PREVIEW:
      return {
        ...state,
        previewLoading: true,
        previewLoadError: null,
      };
    case LOAD_PREVIEW_SUCCESS:
      return {
        ...state,
        previewLoading: false,
        preview: action.result,
      };
    case LOAD_PREVIEW_FAIL:
      return {
        ...state,
        previewLoading: false,
        previewLoadError: action.error,
      };
    case CLEAR_PREVIEW:
      return {
        ...state,
        preview: null,
      };
    default:
      return state;
  }
}

export function load(audienceId: string) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client: ApiClient) =>
      client.get('/broadcasts', {
        params: { audienceId },
      }),
  };
}

// tslint:disable-next-line:variable-name
export function loadTemplate(broadcastId: string, messageId: string, audienceId: string, broadcast_tone: string) {
  return {
    types: [LOAD_TEMPLATE, LOAD_TEMPLATE_SUCCESS, LOAD_TEMPLATE_FAIL],
    broadcastId,
    messageId,
    promise: (client: ApiClient) =>
      client.get(`/broadcasts/${broadcastId}/templates/${messageId}`, {
        params: { audienceId, broadcast_tone },
      }),
    notifications: {
      fail: 'Failed to load template',
    },
  };
}

export function loadPreview(
  broadcastId: string,
  messageId: string,
  audienceId: string,
  // tslint:disable-next-line:variable-name
  broadcast_tone: string,
  data?: Partial<TemplateConfiguration>,
) {
  return {
    types: [LOAD_PREVIEW, LOAD_PREVIEW_SUCCESS, LOAD_PREVIEW_FAIL],
    broadcastId,
    messageId,
    promise: (client: ApiClient) =>
      client.post(`/broadcasts/${broadcastId}/templates/${messageId}/render`, {
        data,
        params: { audienceId, broadcast_tone },
      }),
    notifications: {
      fail: 'Failed to load preview',
    },
  };
}

export const clearPreview = () => ({ type: CLEAR_PREVIEW });

export function saveTemplate(
  broadcastId: string,
  messageId: string,
  audienceId: string,
  // tslint:disable-next-line:variable-name
  broadcast_tone: string,
  data: Partial<TemplateConfiguration>,
) {
  return {
    types: [PATCH_TEMPLATE, PATCH_TEMPLATE_SUCCESS, PATCH_TEMPLATE_FAIL],
    broadcastId,
    messageId,
    promise: (client: ApiClient) =>
      client.patch(`/broadcasts/${broadcastId}/templates/${messageId}`, {
        data,
        params: { audienceId, broadcast_tone },
      }),
    notifications: {
      success: 'Template updated successfully',
      fail: 'Failed to update template',
    },
  };
}
